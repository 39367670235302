import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import AsyncSelect from '../react-hook-form-inputs/AsyncSelect';
import { getTestByNameLike, getTestSelectItemSource } from '../../services/tests/testProvider';
import { getByNameLike, getOrgUnitSelectItemSource } from '../../services/orgunit/orgUnitProvider';
import { getUserByNameLike, getUserSelectItemSource } from '../../services/user/userProvider';
import {
  getUserGroupByNameLike,
  getUserGroupSelectItemSource,
} from '../../services/user/userGroupProvider';
import { getExcludeDates } from '../../services/assignmentperiod/assignmentPeriodProvider';
import Notifications from '../../utils/Notifications';
import Select from '../react-hook-form-inputs/Select';
import TextArea from '../react-hook-form-inputs/TextArea';
import DatePicker from '../react-hook-form-inputs/DatePicker';
import Toggle from '../react-hook-form-inputs/Toggle';

function InnerForm(props) {
  const { t, cancel, assignmentPeriodTypes, createAssignmentPeriod } = props;

  const [type, setType] = useState(null);
  const [testId, setTestId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [excludedPeriodsAndDates, setExcludedPeriodsAndDates] = useState(null);

  const schema = Yup.object().shape({
    assignmentPeriodType: Yup.mixed().required(t('message.required_field')),
    test: Yup.mixed().required(t('message.required_field')),
    user: Yup.mixed().when('assignmentPeriodType.value', (value) => {
      if (value === 'User') {
        return Yup.mixed().required(t('message.required_field'));
      }
      return Yup.mixed().nullable();
    }),
    userGroup: Yup.mixed().when('assignmentPeriodType.value', (value) => {
      if (value === 'UserGroup') {
        return Yup.mixed().required(t('message.required_field'));
      }
      return Yup.mixed().nullable();
    }),
    organization: Yup.mixed().when('assignmentPeriodType.value', (value) => {
      if (value === 'Organization') {
        return Yup.mixed().required(t('message.required_field'));
      }
      return Yup.mixed().nullable();
    }),
    showResultToUser: Yup.bool().nullable(),
    startDate: Yup.date().required(t('message.required_field')).nullable(),
    endDate: Yup.date().required(t('message.required_field')).nullable(),
  });

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: null,
      endDate: null,
      test: null,
      organization: null,
      userGroup: null,
      user: null,
      showResultToUser: false,
      assignmentPeriodType: null,
    },
  });
  const { handleSubmit, control } = form;

  const [defaultUsers, setDefaultUsers] = useState([]);
  const [defaultSurveys, setDefaultSurveys] = useState([]);
  const [defaultOrgUnits, setDefaultOrgUnits] = useState([]);
  const [defaultUserGroups, setDefaultUserGroups] = useState([]);

  useEffect(() => {
    const promises = [
      getUserSelectItemSource({ amount: 100, skip: 0 }),
      getTestSelectItemSource({ amount: 100, skip: 0 }),
      getOrgUnitSelectItemSource({ amount: 100, skip: 0 }),
      getUserGroupSelectItemSource({ amount: 100, skip: 0 }),
    ];
    Promise.all(promises)
      .then(([defaultUsers, defaultSurveys, defaultOrgUnits, defaultUserGroups]) => {
        setDefaultUsers(defaultUsers);
        setDefaultSurveys(defaultSurveys);
        setDefaultOrgUnits(defaultOrgUnits);
        setDefaultUserGroups(defaultUserGroups);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  const loadTestOptions = (query, callback) => {
    if (!query) {
      return callback([]);
    }
    getTestByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const loadOrganizationOptions = (query, callback) => {
    if (!query) {
      return callback([]);
    }
    getByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const loadUserOptions = (query, callback) => {
    if (!query) {
      return callback([]);
    }
    getUserByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const loadUserGroupOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getUserGroupByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const _getExcludeDatesForCompany = () => {
    if (!testId) {
      return;
    }
    const settings = {
      type: 'Company',
      testId,
    };
    __getExcludeDates(settings);
  };

  const _getExcludeDates = (value) => {
    if (!testId || !type || !value) {
      return;
    }
    const settings = {
      type,
      testId,
    };

    switch (type) {
      case 'Organization':
        settings.organizationId = value;
        break;
      case 'User':
        settings.userId = value;
        break;
      case 'UserGroup':
        settings.userGroupId = value;
        break;
      default:
        return;
    }
    __getExcludeDates(settings);
  };

  const __getExcludeDates = (settings) => {
    getExcludeDates(settings)
      .then((data) => {
        data.excludeDates = data.excludeDates.map((d) => new Date(moment.utc(d)));
        data.excludePeriods = data.excludePeriods.map((d) => ({
          startDate: new Date(moment.utc(d.startDate)),
          endDate: new Date(moment.utc(d.endDate)),
        }));
        setExcludedPeriodsAndDates(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const getMinDate = () => {
    const { excludePeriods } = excludedPeriodsAndDates;
    const currentDateMoment = new Date(moment.utc());
    if (!excludePeriods || !endDate) {
      return currentDateMoment;
    }
    const endDateMoment = new Date(moment.utc(endDate));
    for (let i = excludePeriods.length - 1; i >= 0; i--) {
      const excludePeriodEndDate = excludePeriods[i].endDate;
      if (excludePeriodEndDate < endDateMoment) {
        if (excludePeriodEndDate < currentDateMoment) {
          return currentDateMoment;
        }
        return excludePeriodEndDate;
      }
    }
    return currentDateMoment;
  };

  const getMaxDate = () => {
    const { excludePeriods } = excludedPeriodsAndDates;
    if (!excludePeriods || !startDate) {
      return null;
    }
    const startDateMoment = new Date(moment.utc(startDate));
    for (let i = 0; i < excludePeriods.length; i++) {
      if (startDateMoment < excludePeriods[i].startDate) {
        return excludePeriods[i].startDate;
      }
    }
    return null;
  };

  const renderSettingFields = () => {
    switch (type) {
      case 'Organization':
        return (
          <Controller
            control={control}
            id="organization"
            name="organization"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <AsyncSelect
                name={name}
                placeholder={t('fields.organization')}
                loadOptions={loadOrganizationOptions}
                label={t('fields.organization')}
                onChange={(object) => {
                  onChange(object);
                  if (object && object.value) {
                    _getExcludeDates(object.value);
                  } else {
                    setExcludedPeriodsAndDates(null);
                  }
                }}
                defaultOptions={defaultOrgUnits}
                form={form}
                isMulti={false}
                isClearable
                onBlur={onBlur}
                value={value}
              />
            )}
          />
        );

      case 'User':
        return (
          <Controller
            control={form.control}
            id="user"
            name="user"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <AsyncSelect
                name={name}
                placeholder={t('fields.user')}
                loadOptions={loadUserOptions}
                label={t('fields.user')}
                onChange={(object) => {
                  onChange(object);
                  if (object && object.value) {
                    _getExcludeDates(object.value);
                  } else {
                    setExcludedPeriodsAndDates(null);
                  }
                }}
                defaultOptions={defaultUsers}
                form={form}
                isMulti={false}
                isClearable
                onBlur={onBlur}
                value={value}
              />
            )}
          />
        );
      case 'UserGroup':
        return (
          <Controller
            control={form.control}
            id="userGroup"
            name="userGroup"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <AsyncSelect
                name={name}
                placeholder={t('fields.user_group')}
                loadOptions={loadUserGroupOptions}
                label={t('fields.user_group')}
                defaultOptions={defaultUserGroups}
                onChange={(object) => {
                  onChange(object);
                  if (object && object.value) {
                    _getExcludeDates(object.value);
                  } else {
                    setExcludedPeriodsAndDates(null);
                  }
                }}
                form={form}
                isMulti={false}
                isClearable
                onBlur={onBlur}
                value={value}
              />
            )}
          />
        );
      default:
        return null;
    }
  };

  const onSubmit = (a) => {
    const params = {
      testId: a.test.value,
      startDate: a.startDate,
      endDate: moment(a.endDate).endOf('day'),
      description: a.description,
      welcomeText: a.welcomeText,
      showResultToUser: a.showResultToUser,
      type: { value: a.assignmentPeriodType.value, label: a.assignmentPeriodType.value },
      settings: {},
    };

    switch (type) {
      case 'Organization':
        if (a.organization && a.organization.value) {
          params.settings.organizationId = a.organization.value;
          params.assignedEntityId = a.organization.value;
        }
        break;
      case 'User':
        if (a.user && a.user.value) {
          params.settings.userId = a.user.value;
          params.assignedEntityId = a.user.value;
        }
        break;
      case 'UserGroup':
        if (a.userGroup && a.userGroup.value) {
          params.settings.userGroupId = a.userGroup.value;
          params.assignedEntityId = a.userGroup.value;
        }
        break;
      default:
        break;
    }
    createAssignmentPeriod(params);
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      {assignmentPeriodTypes && (
        <div className="modal-body">
          <Controller
            control={control}
            id="test"
            name="test"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <AsyncSelect
                name={name}
                placeholder={t('fields.survey')}
                loadOptions={loadTestOptions}
                label={t('fields.survey')}
                defaultOptions={defaultSurveys}
                onChange={(object) => {
                  onChange(object);
                  if (object && object.value) {
                    setTestId(object.value);
                    _getExcludeDates(object.value);
                  } else {
                    setTestId(null);
                    setExcludedPeriodsAndDates(null);
                  }
                }}
                form={form}
                isMulti={false}
                isClearable
                cacheOptions
                onBlur={onBlur}
                value={value}
              />
            )}
          />

          {testId && (
            <Controller
              id="assignmentPeriodType"
              control={control}
              name="assignmentPeriodType"
              defaultValue=""
              render={({ onChange, onBlur, value, name }) => (
                <Select
                  name={name}
                  placeholder={t('fields.assignment_period_type')}
                  label={t('fields.assignment_period_type')}
                  form={{
                    touched: form.touched,
                    errors: form.errors,
                  }}
                  options={assignmentPeriodTypes}
                  onBlur={onBlur}
                  onChange={(object) => {
                    onChange(object);
                    setExcludedPeriodsAndDates(null);
                    if (object && object.value) {
                      setType(object.value);
                      if (object.value === 'Company') {
                        _getExcludeDatesForCompany();
                      }
                    } else {
                      setType(null);
                    }
                  }}
                  value={assignmentPeriodTypes.find((c) => c.value === value)}
                />
              )}
            />
          )}

          {testId && type && renderSettingFields()}
          <Controller
            id="showResultToUser"
            control={control}
            name="showResultToUser"
            defaultValue={false}
            render={({ onChange, onBlur, value, name }) => (
              <Toggle
                name={name}
                checked={value}
                label={t('fields.show_result_to_user')}
                toggleSize={6}
                form={{
                  touched: form.touched,
                  errors: form.errors,
                }}
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.checked)}
                value={value || false}
              />
            )}
          />
          <Controller
            control={control}
            id="description"
            name="description"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <TextArea
                name={name}
                placeholder={t('fields.description')}
                label={t('fields.description')}
                form={form}
                rows={2}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />

          <Controller
            control={control}
            id="welcomeText"
            name="welcomeText"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <TextArea
                name={name}
                placeholder={t('fields.welcome_text')}
                label={t('fields.welcome_text')}
                form={form}
                rows={2}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />

          {excludedPeriodsAndDates != null && excludedPeriodsAndDates.excludeDates != null && (
            <Controller
              control={form.control}
              id="startDate"
              name="startDate"
              defaultValue=""
              render={({ onChange, onBlur, value, name }) => (
                <DatePicker
                  name={name}
                  label={t('fields.start_date')}
                  onChange={(object) => {
                    onChange(object);
                    if (object) {
                      setStartDate(object);
                    } else {
                      setStartDate(null);
                      setEndDate(null);
                    }
                  }}
                  form={form}
                  excludeDates={excludedPeriodsAndDates.excludeDates}
                  onBlur={onBlur}
                  value={value}
                  placeholderText={t('fields.date_format_mm_dd_yyyy')}
                  minDate={getMinDate()}
                  maxDate={endDate}
                  autoComplete="off"
                />
              )}
            />
          )}
          {excludedPeriodsAndDates != null &&
            excludedPeriodsAndDates.excludeDates != null &&
            startDate != null && (
              <Controller
                control={form.control}
                id="endDate"
                name="endDate"
                defaultValue=""
                render={({ onChange, onBlur, value, name }) => (
                  <DatePicker
                    name={name}
                    label={t('fields.end_date')}
                    onChange={(object) => {
                      onChange(object);
                      if (object) {
                        setEndDate(object);
                      } else {
                        setEndDate(null);
                      }
                    }}
                    form={form}
                    excludeDates={excludedPeriodsAndDates.excludeDates}
                    onBlur={onBlur}
                    value={value}
                    placeholderText={t('fields.date_format_mm_dd_yyyy')}
                    minDate={startDate}
                    maxDate={getMaxDate()}
                    autoComplete="off"
                  />
                )}
              />
            )}
        </div>
      )}
      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
        <button type="submit" className="btn fm-btn-primary">
          {t('buttons.create')}
        </button>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  createAssignmentPeriod: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  assignmentPeriodTypes: PropTypes.array.isRequired,
};

export default withTranslation()(InnerForm);
