import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';

function InputComponent(props) {
  const {
    disabled,
    field,
    form: { touched, errors },
    classNames,
    label,
    type,
    setFieldValue,
    onChange,
    required,
    checkedIcon,
    checked,
    icon,
    color,
  } = props;
  const errorMessage =
    field.name &&
    typeof field.name === 'string' &&
    field.name.split('.').reduce((o, i) => (o ? o[i] : false), touched) &&
    field.name.split('.').reduce((o, i) => (o ? o[i] : false), errors);

  return (
    <FormGroup className="form-element input-component">
      <FormControl required={required} error component="fieldset">
        <FormControlLabel
          control={
            <Checkbox
              type={type}
              icon={icon}
              name={field.name}
              color={color}
              checked={checked}
              checkedIcon={checkedIcon}
              disabled={disabled}
              className={classNames}
              defaultValue={field.value ? field.value : ''}
              onChange={(e) => {
                onChange && onChange(e);
                setFieldValue(field.name, field.value || e.target.checked);
                field.onChange.call(this, e);
                return e;
              }}
            />
          }
          className={checked ? 'fm-element-checked' : ''}
          label={label}
        />
        {errorMessage ? <FormHelperText>{errorMessage}</FormHelperText> : null}
      </FormControl>
    </FormGroup>
  );
}

InputComponent.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  classNames: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  checkedIcon: PropTypes.object,
  color: PropTypes.string,
};

export default InputComponent;
