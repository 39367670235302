import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';
import FormeasurePicLogo from '../../styles/img/Logo_Img.svg';
import FormeasureTextLogo from '../../styles/img/Logo_Text.svg';

import { withNewModal } from '../NewModal';
import CaseTrackingFormModal from '../bugtracking/CaseReportFormModal';
import Notifications from '../../utils/Notifications';
import { createCaseReport } from '../../services/bugtracking/CaseReportProvider';
import Can from '../security/Can';
import NotificationBell from './NotificationBell';
import AvatarMenu from './AvatarMenu';

import './styles/top-menu.css';

class TopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowProfileMenu: false,
      caseReportTypes: null,
      caseReportSeverities: null,
    };
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleCloseProfileElement);
  }

  openCaseReportModal = () => {
    const { openModal, t, closeModal } = this.props;

    openModal({
      component: <CaseTrackingFormModal closeModal={closeModal} />,
      header: t('components.create_report_case'),
      icon: faBug,
    });
  };

  handleCloseProfileElement = (event) => {
    const profileEl = document.getElementById('fm-avatar-circle-box');
    if (profileEl && !profileEl.contains(event.target)) {
      this.setState({ isShowProfileMenu: false });
    }
  };

  render() {
    const { collapsed } = this.props;

    return (
      <div className="fm-header">
        <div id="fm-logo">
          <NavLink to="/home" className="flex">
            <img id="fm-main-pic-logo" src={FormeasurePicLogo} alt="Logo" />
            <img
              id="fm-main-text-logo"
              alt="Logo"
              className={cn('animated', 'flipInX', { 'fm-hidden': collapsed })}
              src={FormeasureTextLogo}
            />
          </NavLink>
        </div>

        <div className="fm-header-buttons-container">
          {/* <div className={ 'fm-header-btn' }><FontAwesomeIcon icon={ faSearch }/></div> */}
          {/* <div className={ 'fm-header-btn' }><FontAwesomeIcon icon={ faCalendar }/></div> */}
          {/* <div className={ 'fm-header-btn' }><FontAwesomeIcon icon={ faBookmark }/></div> */}
          <NotificationBell />
          {/*   <div className={ 'fm-header-btn' } onClick={ () => i18n.changeLanguage('en') }>ENGLISH</div>
                    <div className={ 'fm-header-btn' } onClick={ () => i18n.changeLanguage('ru') }>RUSSIAN</div> */}
          <Can
            module="bug-tracking"
            permission="create"
            yes={() => (
              <div className="fm-header-btn cursor-pointer" onClick={this.openCaseReportModal}>
                <FontAwesomeIcon icon={faBug} />
              </div>
            )}
            no={() => null}
          />
          <AvatarMenu />
        </div>
      </div>
    );
  }
}

TopMenu.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNewModal(withTranslation()(TopMenu));
