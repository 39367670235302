/* Global actions. */
export const SIGN_IN = 'sign-in';
export const LOGOUT = 'logout';
export const INIT_MENU = 'initialize-menu';

/* Auth actions. */
export const REGISTER_USER_DETAILS = 'register-user-details';
export const REGISTER_USER_PHOTO = 'register-user-photo';

/* Tests actions. */
export const SET_QUESTION_TYPES = 'set-question-types';
export const SET_TEST_TYPES = 'set-test-types';
export const SET_TEST_DETAILS = 'set-test-details';
export const SET_QUESTIONS = 'set-questions';

/* Employee actions. */
export const SET_EMPLOYEE_INFO = 'set-employee-info';
export const SET_EMPLOYEE_USERS = 'set-employee-users';
export const SET_EMPLOYEE_ORG_UNITS = 'set-employee-org-units';

/* Test Assignment actions. */
export const SET_TEST_ASSIGNMENT_DETAILS = 'set-test-assignment-details';
export const SET_TEST_ASSIGNMENT_USERS = 'set-test-assignment-users';
export const SET_TEST_ASSIGNMENT_TESTS = 'set-test-assignment-tests';
