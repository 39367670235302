import React from 'react';
import PropTypes from 'prop-types';

import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as Yup from 'yup';
import { faBuilding, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { isTenantExist } from '../../services/tenant/tenantProvider';
import MaterialCheckbox from '../react-hook-form-inputs/MaterialCheckbox';
import MaterialInput from '../react-hook-form-inputs/MaterialInput';

function InnerForm(props) {
  const { t, tenant, changeLocation, lastTenant } = props;

  const schema = Yup.lazy((values) =>
    Yup.object().shape({
      tenant: Yup.string()
        .required(t('message.required_field'))
        .test('checkTenantIsExist', t('message.tenant_not_exist'), (value) => {
          if (!value) {
            return null;
          }
          return new Promise((resolve) => {
            isTenantExist({ name: values.tenant })
              .then((data) => {
                resolve(data);
              })
              .catch(() => {
                resolve(false);
              });
          });
        }),
      username: Yup.string().required(t('message.required_field')),
      password: Yup.string().required(t('message.no_password_provided')),
      rememberMe: Yup.boolean().default(false),
    }),
  );

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      tenant: tenant || lastTenant || '',
      rememberMe: true,
    },
  });

  const { handleSubmit } = form;
  const onSubmit = (values) => {
    props.loginHandler(values, (v) => form.reset(v));
  };
  return (
    <>
      <div className="fm-login-text text-center">
        <span>{t('components.welcome_back')}</span> <br />
        <span>{t('message.login_form')}</span>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="m-t fm-auth-form">
        <div className="fm-auth-input-container">
          <div className="fm-element-form">
            <div className="fm-login-icon">
              <FontAwesomeIcon icon={faBuilding} />
            </div>
            <div className="fm-login-input">
              <Controller
                control={form.control}
                name="tenant"
                id="tenant"
                defaultValue=""
                render={({ onChange, onBlur, value, name }) => (
                  <MaterialInput
                    name={name}
                    placeholder={t('fields.tenant')}
                    label={t('fields.tenant')}
                    form={form}
                    type="text"
                    disabled={!!tenant}
                    maxLength="1024"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </div>
          </div>
          <div className="fm-element-form">
            <div className="fm-login-icon">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div className="fm-login-input">
              <Controller
                control={form.control}
                name="username"
                id="username"
                defaultValue=""
                render={({ onChange, onBlur, value, name }) => (
                  <MaterialInput
                    name={name}
                    placeholder={t('fields.username')}
                    label={t('fields.username')}
                    form={form}
                    type="text"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </div>
          </div>
          <div className="fm-element-form">
            <div className="fm-login-icon">
              <FontAwesomeIcon icon={faLock} />
            </div>
            <div className="fm-login-input">
              <Controller
                control={form.control}
                name="password"
                id="password"
                defaultValue=""
                render={({ onChange, onBlur, value, name }) => (
                  <MaterialInput
                    name={name}
                    placeholder={t('fields.password')}
                    label={t('fields.password')}
                    type="password"
                    form={form}
                    autoComplete="password"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="fm-auth-box">
          <div className="fm-check-me">
            <Controller
              id="rememberMe"
              control={form.control}
              name="rememberMe"
              defaultValue={false}
              render={({ onChange, onBlur, value, name }) => (
                <MaterialCheckbox
                  name={name}
                  checked={value}
                  label={t('fields.remember_me')}
                  form={{
                    touched: form.touched,
                    errors: form.errors,
                  }}
                  checkedElementColor="#34B77C"
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.checked)}
                  value={value || false}
                />
              )}
            />
          </div>
        </div>
        <div className="fm-auth-box">
          <button type="submit" className="btn fm-btn-primary block m-b fm-login-btn">
            {t('buttons.login_now')}
          </button>
        </div>
        <div className="fm-auth-box fm-login-link">
          <button
            type="button"
            className="btn btn-link"
            onClick={() => changeLocation('/reset-password')}
          >
            <small>{t('buttons.forgot_password')}</small>
          </button>
        </div>
        {/* <div className="fm-login-footer-btns"> */}
        {/*    <div className="fm-footer-login fm-f-btn-active"> */}
        {/*        <button type="button" */}
        {/*                onClick={ () => changeLocation('/login', values.tenant) }>{ t('buttons.log_in') } */}
        {/*        </button> */}
        {/*    </div> */}
        {/*    <div className="fm-footer-signin"> */}
        {/*        <button type="button" */}
        {/*                onClick={ () => changeLocation('/registration', values.tenant) }>{ t('buttons.sign_in') }</button> */}
        {/*    </div> */}
        {/* </div> */}
      </form>
    </>
  );
}

InnerForm.propTypes = {
  tenant: PropTypes.string,
  changeLocation: PropTypes.func.isRequired,
  lastTenant: PropTypes.string,
};

export default withTranslation()(InnerForm);
