import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import { TwitterPicker } from 'react-color';

import Moment from 'moment/moment';
import { withTranslation } from 'react-i18next';
import Validation from '../../../utils/validation';
import InputComponent from '../../inputs/Input';
import TextareaComponent from '../../inputs/Textarea';
import Can from '../../security/Can';

function InnerForm(props) {
  const { t, handleSubmit, getQuestionClassificationByName, securityModule } = props;

  const [pickerOpen, setPickerOpen] = useState(false);

  const handleColorChange = (c) => {
    props.values.color = c.hex;
    setPickerOpen(false);
  };

  const onTogglePicker = () => {
    setPickerOpen(!pickerOpen);
  };

  const validateName = (query) => {
    if (props.initialValues.name === query) {
      return;
    }
    return getQuestionClassificationByName(query).then((d) =>
      d ? t('message.already_exist') : null,
    );
  };

  return (
    <div className="ibox-content clearfix">
      <Form onSubmit={handleSubmit}>
        <Field
          component={InputComponent}
          placeholder={t('fields.question_tag_name')}
          type="text"
          name="name"
          label={t('fields.question_tag_name')}
          validate={(e) => validateName(e)}
        />
        <Field
          component={InputComponent}
          placeholder={t('fields.color')}
          type="text"
          name="color"
          disabled
          label={t('fields.color')}
          onClick={onTogglePicker}
          style={{
            cursor: 'pointer',
            backgroundColor: props.values.color,
            color: props.values.color ? 'white' : 'inherit',
            fontWeight: props.values.color ? 'bold' : 'inherit',
          }}
        />
        {pickerOpen && (
          <div
            style={{
              position: 'absolute',
              zIndex: 1,
            }}
          >
            <TwitterPicker onChangeComplete={handleColorChange} />
          </div>
        )}
        <Field
          component={TextareaComponent}
          placeholder={t('fields.description')}
          name="description"
          rows={3}
        />
        <Field
          component={InputComponent}
          placeholder={t('fields.created')}
          type="text"
          name="created"
          label="Created"
          disabled
        />
        <Field
          component={InputComponent}
          placeholder={t('fields.updated')}
          type="text"
          name="updated"
          label="Updated"
          disabled
        />

        <Can
          module={securityModule}
          permission="edit"
          yes={() => (
            <button type="submit" className="btn fm-btn-primary pull-right">
              {t('buttons.save_and_close')}
            </button>
          )}
          no={() => null}
        />
      </Form>
    </div>
  );
}

InnerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  getQuestionClassificationByName: PropTypes.func.isRequired,
  updateQuestionClassification: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

const EditQuestionClassificationForm = withFormik({
  mapPropsToValues: (values) => ({
    name: values.name || '',
    color: values.color || '',
    description: values.description || '',
    created: values.created ? Moment(values.created).format('LL') : '',
    updated: values.updated ? Moment(values.updated).format('LL') : '',
  }),
  validate: (values) => {
    const errors = {};
    errors.name = Validation.required(values.name);
    return _.isEmpty(_.filter(errors, (value) => value)) ? {} : errors;
  },
  handleSubmit: (values, props) => {
    const { updateQuestionClassification, id, tenantId } = props.props;
    updateQuestionClassification({
      id,
      tenantId,
      name: values.name,
      color: values.color,
      description: values.description,
    });
  },
})(InnerForm);

export default withTranslation()(EditQuestionClassificationForm);
