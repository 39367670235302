export const getPermissions = () => [
  {
    id: 'everything:everything',
    name: 'permissions.full_access',
    canChoose: false,
    hidden: true,
    description: 'Give access to all platform functionality',
  },
  {
    id: 'current-tenant:everything',
    name: 'permissions.full_tenant_access',
    canChoose: false,
    hidden: true,
    description: 'Give access to all functionality for my tenant',
  },
  {
    id: 'global:home',
    name: 'permissions.home_page',
    canChoose: true,
    hidden: false,
    description: 'Give access to home page',
    permissions: [
      {
        id: 'global:home:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'survey-analytics:everything',
    name: 'permissions.survey_analytics',
    canChoose: true,
    hidden: false,
    description: null,
    permissions: [
      {
        id: 'survey-analytics:dashboard',
        name: 'permissions.dashboard',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'survey-analytics:heatmap',
        name: 'permissions.heatmap',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'survey-analytics:impact',
        name: 'permissions.impact',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'global-tests:everything',
    name: 'permissions.global_templates',
    canChoose: false,
    hidden: false,
    description: 'Global tests(templates), only sa available to create this type of tests',
    permissions: [
      {
        id: 'global-tests:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'global-tests:edit',
        canChoose: false,
        hidden: true,
        name: 'permissions.edit',
        description: null,
      },
      {
        id: 'global-tests:delete',
        name: 'permissions.delete',
        canChoose: false,
        hidden: true,
        description: null,
      },
      {
        id: 'global-tests:create',
        name: 'permissions.create',
        canChoose: false,
        hidden: true,
        description: null,
      },
      {
        id: 'global-tests:copy-to-tenant',
        name: 'permissions.copy_to_tenant',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'global-tests:import',
        name: 'permissions.import',
        canChoose: false,
        hidden: true,
        description: null,
      },
      {
        id: 'global-tests:export',
        name: 'permissions.export',
        canChoose: false,
        hidden: true,
        description: null,
      },
    ],
  },
  {
    id: 'global-question-classification:everything',
    name: 'permissions.tag_templates',
    canChoose: false,
    hidden: false,
    description: 'Global tags access',
    permissions: [
      {
        id: 'global-question-classification:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'global-question-classification:edit',
        name: 'permissions.edit',
        canChoose: false,
        hidden: true,
        description: null,
      },
      {
        id: 'global-question-classification:delete',
        name: 'permissions.delete',
        canChoose: false,
        hidden: true,
        description: null,
      },
      {
        id: 'global-question-classification:create',
        name: 'permissions.create',
        canChoose: false,
        hidden: true,
        description: null,
      },
    ],
  },
  {
    id: 'tests:everything',
    name: 'permissions.surveys',
    canChoose: true,
    hidden: false,
    description: 'Tenant specific surveys',
    permissions: [
      {
        id: 'tests:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'tests:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'tests:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'tests:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'question-classification:everything',
    name: 'permissions.tags',
    canChoose: true,
    hidden: false,
    description: 'Tags access',
    permissions: [
      {
        id: 'question-classification:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'question-classification:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'question-classification:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'question-classification:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'users:everything',
    name: 'permissions.people',
    canChoose: true,
    hidden: false,
    description: 'People Management',
    permissions: [
      {
        id: 'users:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'users:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'users:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'users:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'users:edit-role',
        name: 'permissions.edit_role',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'users:add-role',
        name: 'permissions.add_role',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'users:delete-role',
        name: 'permissions.delete_role',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'user-groups:everything',
    name: 'permissions.user_groups',
    canChoose: true,
    hidden: false,
    description: 'User Group Management',
    permissions: [
      {
        id: 'user-groups:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'user-groups:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'user-groups:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'user-groups:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'user-roles:everything',
    name: 'permissions.user_roles',
    canChoose: true,
    hidden: false,
    description: 'User Roles Management',
    permissions: [
      {
        id: 'user-roles:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'user-roles:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'user-roles:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'user-roles:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'feedback:everything',
    name: 'permissions.feedback',
    canChoose: true,
    hidden: false,
    description: 'Feedback management',
    permissions: [
      {
        id: 'feedback:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'feedback-categories:everything',
    name: 'permissions.feedback_categories',
    canChoose: true,
    hidden: false,
    description: 'Feedback categories management',
    permissions: [
      {
        id: 'feedback-categories:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-categories:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-categories:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-categories:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'org-unit:everything',
    name: 'permissions.organization_unit',
    canChoose: true,
    hidden: false,
    description: 'Organization unit management',
    permissions: [
      {
        id: 'org-unit:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'org-unit:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'org-unit:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'org-unit:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'org-unit-definition:everything',
    name: 'permissions.organization_unit_definition',
    canChoose: true,
    hidden: false,
    description: 'Organization unit definition management',
    permissions: [
      {
        id: 'org-unit-definition:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'org-unit-definition:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'org-unit-definition:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'org-unit-definition:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'employees:everything',
    name: 'permissions.employees',
    canChoose: true,
    hidden: false,
    description: 'Employees management',
    permissions: [
      {
        id: 'employees:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'employees:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'employees:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'employees:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'jobs:everything',
    name: 'permissions.jobs',
    canChoose: true,
    hidden: false,
    description: 'Jobs management',
    permissions: [
      {
        id: 'jobs:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'jobs:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'jobs:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'jobs:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'job-levels:everything',
    name: 'permissions.job_levels',
    canChoose: true,
    hidden: false,
    description: 'Job levels management',
    permissions: [
      {
        id: 'job-levels:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'job-levels:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'job-levels:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'job-levels:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'location:everything',
    name: 'permissions.locations',
    canChoose: true,
    hidden: false,
    description: 'Locations management',
    permissions: [
      {
        id: 'location:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'location:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'location:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'location:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'test-assignments:everything',
    name: 'permissions.survey_assignments',
    canChoose: true,
    hidden: false,
    description: 'Test assignments management',
    permissions: [
      {
        id: 'test-assignments:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'test-assignments:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'test-assignments:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'test-assignments:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'test-assignments:view-personal',
        name: 'permissions.view_personal_results',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'test-assignments:pass',
        name: 'permissions.pass_survey',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'test-assignment-periods:everything',
    name: 'permissions.survey_schedule',
    canChoose: true,
    hidden: false,
    description: 'Survey assignments periods management',
    permissions: [
      {
        id: 'test-assignment-periods:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'test-assignment-periods:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'test-assignment-periods:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'test-assignment-periods:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'test-assignment-periods:view-summary',
        name: 'permissions.view_summary',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'feedback-assignments:everything',
    name: 'permissions.feedback_assignments',
    canChoose: true,
    hidden: false,
    description: 'Feedback assignments management',
    permissions: [
      {
        id: 'feedback-assignments:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-assignments:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-assignments:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-assignments:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-assignments:view-personal-results',
        name: 'permissions.view_personal_results',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-assignments:pass',
        name: 'permissions.leave_feedback',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-assignments:view-subordinate-requests',
        name: 'permissions.view_subordinate_results',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-assignments:all-employees-feedback-requests',
        name: 'permissions.all_employees_feedback_results',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-assignments:view-requests',
        name: 'permissions.view_feedback_requests_my',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-assignments:reject',
        name: 'permissions.reject_feedback',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'feedback-assignment-periods:everything',
    name: 'permissions.feedback_schedules',
    canChoose: true,
    hidden: false,
    description: 'Feedback assignments periods management',
    permissions: [
      {
        id: 'feedback-assignment-periods:view-all',
        name: 'permissions.view_all',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-assignment-periods:edit',
        name: 'permissions.edit',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-assignment-periods:delete',
        name: 'permissions.delete',
        canChoose: true,
        hidden: false,
        description: null,
      },
      {
        id: 'feedback-assignment-periods:create',
        name: 'permissions.create',
        canChoose: true,
        hidden: false,
        description: null,
      },
    ],
  },
  {
    id: 'bug-tracking:everything',
    name: 'permissions.bug_tracking',
    canChoose: false,
    hidden: true,
    description: 'Bug Tracking Management',
    permissions: [
      {
        id: 'bug-tracking:view-all',
        name: 'permissions.view_all',
        canChoose: false,
        hidden: true,
        description: null,
      },
      {
        id: 'bug-tracking:edit',
        name: 'permissions.edit',
        canChoose: false,
        hidden: true,
        description: null,
      },
      {
        id: 'bug-tracking:delete',
        name: 'permissions.delete',
        canChoose: false,
        hidden: true,
        description: null,
      },
      {
        id: 'bug-tracking:create',
        name: 'permissions.create',
        canChoose: false,
        hidden: true,
        description: null,
      },
    ],
  },
  {
    id: 'tenant:everything',
    name: 'permissions.tenant-management',
    canChoose: false,
    hidden: true,
    description: 'Tenant management',
    permissions: [
      {
        id: 'tenant:view-all',
        name: 'permissions.view_all',
        canChoose: false,
        hidden: true,
        description: null,
      },
      {
        id: 'tenant:edit',
        name: 'permissions.edit',
        canChoose: false,
        hidden: true,
        description: null,
      },
      {
        id: 'tenant:delete',
        name: 'permissions.delete',
        canChoose: false,
        hidden: true,
        description: null,
      },
      {
        id: 'tenant:create',
        name: 'permissions.create',
        canChoose: false,
        hidden: true,
        description: null,
      },
    ],
  },
];

export const getPermission = (permission) => {
  const permissions = getPermissions();
  for (let i = 0; i < permissions.length; i += 1) {
    if (permissions[i].id === permission) {
      return permissions[i];
    }
    if (permissions[i].permissions) {
      for (let j = 0; j < permissions[j].permissions.length; j += 1) {
        if (permissions[i].permissions[j].id === permission) {
          return permissions[i].permissions[j];
        }
      }
    }
  }
  return null;
};
