import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import {
  getActivityStatus,
  getLocation,
  updateLocation,
} from '../../services/location/locationProvider';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import EditLocationForm from '../../components/location/EditLocationForm';
import PageNameContainer from '../../components/page/PageNameContainer';

class EditLocationPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      location: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_locations'),
        ref: '/org/locations',
        isActive: false,
      },
      {
        name: t('breadcrumbs.location'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    const { params } = this.props.match;

    const promises = [];

    promises.push(getLocation(params.locationId));
    promises.push(getActivityStatus());

    Promise.all(promises)
      .then(([location, statuses]) => {
        this.setState({
          location,
          isLoading: false,
          statusOptions: statuses,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  updateLocation = (data) => {
    const { t } = this.props;

    this.setState({ isLoading: true });
    updateLocation(data)
      .then((_data) => {
        getLocation(data.id).then((updatedLocation) => {
          this.setState({
            location: updatedLocation,
            isLoading: false,
          });
        });
        Notifications.success(t('message.location_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, location, statusOptions } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.location')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-6">
              {location && location.id && (
                <EditLocationForm
                  location={location}
                  statusOptions={statusOptions}
                  updateLocation={this.updateLocation}
                />
              )}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

EditLocationPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
};

export default withTranslation()(EditLocationPage);
