import dataProvider from '../dataProvider';
import { scale } from '../../constants/endpoints';

export const getScalesList = (params) =>
  dataProvider({
    url: scale.all(),
    params,
    method: 'GET',
  });

export const getScalesByTestId = (testId) =>
  dataProvider({
    url: scale.getByTestId(testId),
    method: 'GET',
  });

export const getScalesByQuestionClassificationId = (questionClassificationId) =>
  dataProvider({
    url: scale.getByQuestionClassificationId(questionClassificationId),
    method: 'GET',
  });

export const getScale = (scaleId) =>
  dataProvider({
    url: scale.getById(scaleId),
    method: 'GET',
  });

export const createScale = (values) =>
  dataProvider({
    url: scale.create(),
    method: 'POST',
    data: values,
  });

export const updateScale = (params) =>
  dataProvider({
    url: scale.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteScale = (scaleId) =>
  dataProvider({
    url: scale.delete(scaleId),
    method: 'DELETE',
  });

export const getScaleSelectItemSource = (params) =>
  dataProvider({
    url: scale.itemSource(),
    method: 'POST',
    data: params,
  });

export const createScales = (data) =>
  dataProvider({
    url: scale.createBatch(),
    method: 'POST',
    data,
  });
