import React, { Component } from 'react';
import './styles/upload.css';
import PropTypes from 'prop-types';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

class UploadDropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fileInputRef = React.createRef();
  }

  openFileDialog = () => {
    if (this.props.disabled) {
      return;
    }
    this.fileInputRef.current.click();
  };

  onFilesAdded = (evt) => {
    if (this.props.disabled) {
      return;
    }
    const { files } = evt.target;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  };

  fileListToArray = (list) => {
    const array = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  };

  onDragOver = (evt) => {
    evt.preventDefault();
    if (this.props.disabled) {
      return;
    }
    this.setState({ hightlight: true });
  };

  onDragLeave = () => {
    this.setState({ hightlight: false });
  };

  onDrop = (event) => {
    event.preventDefault();

    if (this.props.disabled) {
      return;
    }

    const { files } = event.dataTransfer;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  };

  render() {
    const { t, disabled, accept, multiple, cssClass, name } = this.props;
    const { hightlight } = this.state;
    return (
      <div
        className={`fm-dropzone ${cssClass} ${hightlight ? 'fm-dropzone-highlight' : ''}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <FontAwesomeIcon icon={faCloudUploadAlt} className="fm-dropzone-icon" />
        <input
          ref={this.fileInputRef}
          name={name}
          className="fm-upload-file-input"
          type="file"
          accept={accept}
          multiple={multiple}
          onChange={this.onFilesAdded}
        />
        <span>{multiple ? t('components.upload_files') : t('components.upload_file')} </span>
      </div>
    );
  }
}

UploadDropzone.propTypes = {
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
};
export default withTranslation()(UploadDropzone);
