import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import PageNameContainer from '../../components/page/PageNameContainer';
import {
  getCaseReport,
  getCaseReportSeverities,
  getCaseReportStatuses,
  getCaseReportTypes,
  updateCaseReport,
} from '../../services/bugtracking/CaseReportProvider';
import EditCaseReportForm from '../../components/bugtracking/EditCaseReportForm';
import FormTitle from '../../components/form/FormTitle';

class EditCaseReportPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      caseReport: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.bug_tracking'),
        ref: '/case-management/bug-tracking',
        isActive: false,
      },
      {
        name: t('breadcrumbs.case_report'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { location } = this.props.history;
    this.setState({ isLoading: true });

    const promises = [];

    promises.push(getCaseReportStatuses());
    promises.push(getCaseReportSeverities());
    promises.push(getCaseReportTypes());
    promises.push(getCaseReport(location.state.caseReportId));

    Promise.all(promises)
      .then(([statuses, severities, types, caseReport]) => {
        this.setState({
          caseReportStatuses: statuses ? statuses.items : [],
          caseReportSeverities: severities ? severities.items : [],
          caseReportTypes: types ? types.items : [],
          caseReport,
          isLoading: false,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  updateCaseReport = (data) => {
    this.setState({ isLoading: true });
    const { t } = this.props;
    updateCaseReport(data)
      .then((_data) => {
        const { location } = this.props.history;
        getCaseReport(location.state.caseReportId).then((updatedCaseReport) => {
          this.setState({
            caseReport: updatedCaseReport,
            isLoading: false,
          });
        });
        Notifications.success(t('message.case_report_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, caseReport, caseReportStatuses, caseReportTypes, caseReportSeverities } =
      this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('container.case_report')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="ibox">
            <FormTitle name={t('components.case_report')} />
            <div className="ibox-content clearfix">
              {caseReport && caseReport.id ? (
                <EditCaseReportForm
                  caseReport={caseReport}
                  securityModule="bug-tracking"
                  statuses={caseReportStatuses}
                  reportTypes={caseReportTypes}
                  severities={caseReportSeverities}
                  updateCaseReport={this.updateCaseReport}
                />
              ) : (
                <div>
                  <div className="ibox-content">
                    <p className="text-muted text-center">{t('components.content_loading')}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

EditCaseReportPage.propTypes = {
  caseReport: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
};

export default withTranslation()(EditCaseReportPage);
