import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import Can from '../security/Can';
import ReviewersEditList from './ReviewersEditList';
import Modal from '../modal/Modal';
import AddFeedbackReviewersModal from './AddFeedbackReviewersModal';
import { batchUpdateFeedbackAssignmentReviewers } from '../../services/feedbackassignmentreviewer/feedbackAssignmentReviewerProvider';
import Notifications from '../../utils/Notifications';
import { getNotAssignedToFeedbackEmployeeByNameLike } from '../../services/employee/employeeProvider';

class EditFeedbackAssignmentReviewersForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddReviewersModalShown: false,
      criteria: this.props.criteria,
      reviewersMap: new Map(),
    };
    props.reviewers.forEach((reviewer) => {
      this.state.reviewersMap.set(reviewer.reviewerSelectItem.value, reviewer);
    });
  }

  componentDidMount() {
    this.props.addFormSubmitHandler('reviewersForm', {
      submit: this.updateFeedbackAssignmentReviewers,
    });
  }

  openAddReviewersFormModal = () => {
    this.setState({ isAddReviewersModalShown: true });
  };

  closeAddReviewersFormModal = () => {
    this.setState({ isAddReviewersModalShown: false });
  };

  updateFeedbackAssignmentReviewers = () => {
    const { reviewersMap } = this.state;
    const reviewersToSubmit = [];
    if (reviewersMap.size > 0) {
      reviewersMap.forEach((reviewer) => {
        if (reviewer.action) {
          reviewersToSubmit.push({
            object: {
              id: reviewer.id,
              reviewerId: reviewer.reviewerSelectItem ? reviewer.reviewerSelectItem.value : null, // TODO ???
              feedbackAssignmentId: this.props.feedbackAssignment.id,
              tenantId: this.props.feedbackAssignment.id.tenantId,
              isSelfReview: reviewer.isSelfReview,
            },
            action: reviewer.action,
            objectType: reviewer.objectType,
          });
          reviewer.replaceUpdate = true;
        }
      });
      return batchUpdateFeedbackAssignmentReviewers(reviewersToSubmit);
    }
  };

  loadReviewerOptions = (query, callback) => {
    const { reviewersMap } = this.state;
    const { feedbackAssignment } = this.props;
    getNotAssignedToFeedbackEmployeeByNameLike(feedbackAssignment.id, query)
      .then((foundOptions) => {
        let options = [];
        if (reviewersMap.size > 0) {
          foundOptions.forEach((option) => {
            if (!reviewersMap.has(option.value)) {
              options.push(option);
            }
          });
        } else {
          options = foundOptions;
        }
        callback && callback(options);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  addReviewers = (newReviewers) => {
    const { reviewersMap } = this.state;
    newReviewers.forEach((newReviewer) => {
      const key = newReviewer.value;
      if (!reviewersMap.has(key)) {
        reviewersMap.set(key, {
          selfReview: false,
          action: 'create',
          reviewerSelectItem: newReviewer,
          isHidden: true,
          assignmentStatus: 'New',
        });
      }
    });
    this.setState({ reviewersMap });
  };

  removeReviewer = (reviewerToRemove) => {
    const { reviewersMap } = this.state;
    const key = reviewerToRemove.reviewerSelectItem.value;
    const existingReviewer = reviewersMap.get(key);
    if (existingReviewer) {
      if (existingReviewer.id) {
        existingReviewer.action = 'delete';
        reviewersMap.set(key, existingReviewer);
      } else {
        reviewersMap.delete(key);
      }
    }
    this.setState({ reviewersMap });
  };

  updateReviewer = (updated) => {
    const { reviewersMap } = this.state;
    const key = updated.reviewerSelectItem.value;
    const existingReviewer = reviewersMap.get(key);
    if (existingReviewer) {
      reviewersMap.set(key, updated);
    }
    this.setState({ reviewersMap });
  };

  getSortedCriteria = (values) => values.sort((a, b) => (a.isMain && !b.isMain ? -1 : 0));

  render() {
    const { reviewersMap, criteria, isAddReviewersModalShown } = this.state;
    const { t, feedbackAssignment } = this.props;

    return (
      <>
        <div className="ibox-content clearfix">
          {criteria && (
            <ReviewersEditList
              reviewers={Array.from(reviewersMap.values())}
              criteria={this.getSortedCriteria(criteria)}
              updateReviewer={this.updateReviewer}
              removeReviewer={this.removeReviewer}
            />
          )}
        </div>
        <Can
          module="feedback-assignments"
          permission="edit"
          yes={() => (
            <div className="ibox-content fm-btn-box">
              <div className="btn-group pull-right">
                <button
                  type="button"
                  className="fm-btn-primary btn-sm"
                  onClick={this.openAddReviewersFormModal}
                >
                  {t('buttons.add_reviewers')}
                </button>
              </div>
            </div>
          )}
          no={() => null}
        />
        <Modal
          header={t('components.add_reviewers')}
          isOpened={isAddReviewersModalShown}
          icon={faUser}
          onClose={this.closeAddReviewersFormModal}
        >
          <AddFeedbackReviewersModal
            loadReviewerOptions={this.loadReviewerOptions}
            feedbackAssignmentId={feedbackAssignment.id}
            handleReviewersSubmit={this.addReviewers}
            cancel={this.closeAddReviewersFormModal}
          />
        </Modal>
      </>
    );
  }
}

EditFeedbackAssignmentReviewersForm.propTypes = {
  updateFeedbackAssignmentReviewers: PropTypes.func,
  reviewers: PropTypes.array.isRequired,
  addFormSubmitHandler: PropTypes.func.isRequired,
  feedbackAssignment: PropTypes.object.isRequired,
  criteria: PropTypes.array.isRequired,
};

export default withTranslation()(EditFeedbackAssignmentReviewersForm);
