import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Loader from '../../components/loader/Loader';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';
import Notifications from '../../utils/Notifications';
import { getCaseReportPagination } from '../../services/bugtracking/CaseReportProvider';
import AllCaseReportsTable from '../../components/bugtracking/AllCaseReportsTable';

class AllCaseReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isModalShown: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.bug_tracking'),
        ref: null,
        isActive: true,
      },
    ];
  };

  fetchCaseReportList = (amount, skip, sorting, filters, callback) => {
    getCaseReportPagination({
      amount,
      skip,
      sortings: sorting,
      filters,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { isLoading, tableRef } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.bug_tracking')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle name={t('components.bug_tracking')} />
            <AllCaseReportsTable
              fetchCaseReportList={this.fetchCaseReportList}
              setTableReference={this.setTableReference}
              tableReference={tableRef}
            />
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default withTranslation()(AllCaseReports);
