import React, { useEffect, useState } from 'react';
import Menu from 'rc-menu';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBars, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import 'rc-menu/assets/index.css';
import './styles/left-menu.css';
import classnames from 'classnames';

function LeftMenu(props) {
  const { t, menuStructure, triggerCollapsed } = props;

  const [keys, setKeys] = useState({ selected: [], opened: [] });

  const [collapsed, setCollapsed] = useState(false);
  const [rootSubmenuKeys, setRootSubmenuKeys] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1240) {
        setCollapsedValue(true);
      } else if (window.innerWidth > 1240) {
        setCollapsedValue(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (menuStructure && menuStructure.length > 0) {
      const rootElements = [];
      menuStructure.forEach((el, index) => {
        rootElements.push(el.itemId);
      });
      setRootSubmenuKeys(rootElements);
    }
  }, [menuStructure]);

  const onOpenChange = (_keys) => {
    const latestOpenKey = _keys.find((key) => keys.opened.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setKeys({ selected: [], opened: _keys });
    } else {
      setKeys({ selected: [], opened: latestOpenKey ? [latestOpenKey] : [] });
    }
  };
  const handleClick = (e) => {
    let openedArray = [];
    if (rootSubmenuKeys.indexOf(e.key) === -1) {
      openedArray = keys.opened;
    }
    setKeys({ opened: openedArray, selected: [e.key] });
  };
  const setCollapsedValue = (value) => {
    setCollapsed(value);
    triggerCollapsed(value);
  };
  const toggleMenu = () => {
    setCollapsedValue(!collapsed);
  };

  const renderLevel = (item) =>
    item.isShown &&
    (!item.children ? (
      <Menu.Item key={item.itemId}>
        <NavLink to={item.link || '#'}>
          {item.icon ? (
            <div className="fm-menu-item-icon">
              <FontAwesomeIcon icon={item.icon} />
            </div>
          ) : null}
          <span className="fm-menu-item-label">{t(item.label)}</span>
        </NavLink>
      </Menu.Item>
    ) : (
      <Menu.SubMenu
        key={item.itemId}
        title={
          <>
            {item.icon ? (
              <div className="fm-menu-item-icon">
                <FontAwesomeIcon icon={item.icon} />
              </div>
            ) : null}
            <span className="fm-menu-item-label">{t(item.label)}</span>
          </>
        }
        expandIcon={<FontAwesomeIcon className="rc-menu-submenu-arrow" icon={faChevronRight} />}
      >
        {item.children.map((child, key) => renderLevel(child, key))}
      </Menu.SubMenu>
    ));

  return (
    <nav
      className={classnames('navbar-default', 'navbar-static-side', { 'mini-navbar': collapsed })}
    >
      <div className="fm-collapse-sidebar-btn" onClick={toggleMenu}>
        <FontAwesomeIcon icon={collapsed ? faBars : faArrowLeft} id="fm-collapse-menu-btn" />
      </div>
      <div className="sidebar-collapse">
        <Menu
          onClick={handleClick}
          inlineIndent={10}
          triggerSubMenuAction="click"
          openKeys={keys.opened}
          onOpenChange={onOpenChange}
          inlineCollapsed={collapsed}
          selectedKeys={keys.selected}
          mode="inline"
        >
          {menuStructure &&
            !!menuStructure.length &&
            menuStructure.map((item, index) => renderLevel(item, index))}
        </Menu>
      </div>
    </nav>
  );
}
LeftMenu.propTypes = {
  menuStructure: PropTypes.array,
  account: PropTypes.object,
};

const mapStateToProps = (state) => ({
  menuStructure: state.menu.items,
  account: state.account,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(LeftMenu)));
