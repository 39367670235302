import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Dashboard from '../../containers/dashboard/Dashboard';
import Heatmap from '../../containers/dashboard/Heatmap';
import Impact from '../../containers/dashboard/Impact';

import { AUTH_ROUTES } from '../../constants/routes';

class SurveyAnalyticRoutes extends Component {
  render() {
    const { match } = this.props;
    const { SURVEY_ANALYTICS } = AUTH_ROUTES;
    return (
      <div>
        <Route exact path={`${match.url}${SURVEY_ANALYTICS.DASHBOARD}`} component={Dashboard} />
        <Route exact path={`${match.url}${SURVEY_ANALYTICS.HEATMAP}`} component={Heatmap} />
        <Route exact path={`${match.url}${SURVEY_ANALYTICS.IMPACT}`} component={Impact} />
      </div>
    );
  }
}

SurveyAnalyticRoutes.propTypes = {
  match: PropTypes.object,
};

export default SurveyAnalyticRoutes;
