import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { LOGIN } from '../../constants/routes';
import FormeasureLogo from '../../styles/img/logo_formeasure@2x.png';

function InnerForm(props) {
  const { t, type, history, showLoginLink, showResetPasswordLink } = props;

  let message;

  switch (type) {
    case 'DONE':
      message = t('message.reset_password_finished');
      break;
    case 'EXPIRED':
      message = t('message.reset_password_token_not_valid');
      break;
    case 'REQUESTED':
      message = t('message.reset_password_requested');
      break;
    default:
      message = t('message.unsupported_operation');
  }

  return (
    <div className="wrapper-content">
      <div className="middle-box text-center">
        <div className="ibox fm-password-reset-requested-box">
          <div className="fm-auth-logo text-center">
            <img src={FormeasureLogo} />
          </div>
          <div className="fm-line-2" />
          <h3>{t('components.password_reset')}</h3>
          <div className="fm-password-reset-message">{message}</div>
          <div className="fm-login-link">
            {showResetPasswordLink && (
              <button
                type="button"
                className="btn btn-link"
                onClick={() => history.push('/reset-password')}
              >
                <small>{t('buttons.reset_password')}</small>
              </button>
            )}
            {showLoginLink && (
              <button type="button" className="btn btn-link" onClick={() => history.push(LOGIN)}>
                <small>{t('buttons.back_to_sign_in')}</small>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

InnerForm.defaultProps = {
  showLoginLink: true,
  showResetPasswordLink: false,
};

InnerForm.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default withTranslation()(withRouter(InnerForm));
