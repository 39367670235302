import React, { Component } from 'react';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import Loader from '../../components/loader/Loader';
import Notifications from '../../utils/Notifications';
import Modal from '../../components/modal/Modal';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';
import {
  createFeedbackAssignmentPeriod,
  getFeedbackAssignmentPeriodPagination,
  getFeedbackAssignmentPeriodTypes,
} from '../../services/feedbackassignmentperiod/feedbackAssignmentPeriodProvider';
import AllFeedbackAssignmentPeriodTable from '../../components/feedbackassignmentperiod/AllFeedbackAssignmentPeriodTable';
import CreateFeedbackAssignmentPeriodModal from '../../components/feedbackassignmentperiod/CreateFeedbackAssignmentPeriodModal';

class AllFeedbackAssignmentPeriods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isModalShown: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_feedback_assignment_periods'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    getFeedbackAssignmentPeriodTypes()
      .then((data) => {
        this.setState({ feedbackAssignmentPeriodTypes: data.items });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  fetchFeedbackAssignmentPeriodList = (amount, skip, sorting, filters, callback) => {
    getFeedbackAssignmentPeriodPagination({
      amount,
      skip,
      sortings: sorting,
      filters,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  openCreateFeedbackAssignmentPeriodModal = () => {
    this.setState({ isModalShown: true });
  };

  closeModal = () => {
    this.setState({ isModalShown: false });
  };

  createFeedbackAssignmentPeriod = (values) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
      isModalShown: false,
    });
    createFeedbackAssignmentPeriod(values)
      .then((data) => {
        this.state.tableRef.updateTableData(false);
        this.setState({ isLoading: false });
        Notifications.success(t('message.feedback_assignment_period_created'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { isLoading, isModalShown, tableRef, feedbackAssignmentPeriodTypes } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.all_feedback_assignment_periods')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.all_feedback_assignment_periods')}
              buttons={[
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.openCreateFeedbackAssignmentPeriodModal,
                  name: t('buttons.create'),
                  icon: faPlus,
                  securityModule: 'feedback-assignment-periods',
                  securityPermission: 'create',
                },
              ]}
            />
            <AllFeedbackAssignmentPeriodTable
              fetchFeedbackAssignmentPeriodList={this.fetchFeedbackAssignmentPeriodList}
              setTableReference={this.setTableReference}
              tableReference={tableRef}
            />
          </div>
        </div>
        <Modal
          header={t('components.create_feedback_assignment_period')}
          isOpened={isModalShown}
          icon={faFileAlt}
          onClose={this.closeModal}
        >
          {feedbackAssignmentPeriodTypes && (
            <CreateFeedbackAssignmentPeriodModal
              feedbackAssignmentPeriodTypes={feedbackAssignmentPeriodTypes}
              createFeedbackAssignmentPeriod={this.createFeedbackAssignmentPeriod}
              cancel={this.closeModal}
            />
          )}
        </Modal>
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default withTranslation()(AllFeedbackAssignmentPeriods);
