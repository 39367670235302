import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Loader from '../../../components/loader/Loader';
import PageNameContainer from '../../../components/page/PageNameContainer';
import UserAssignmentsComponent from '../../../components/testassignment/UserAssignmentsComponent';

class UserTestAssignments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isModalShown: false,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.take_survey'),
        ref: null,
        isActive: true,
      },
    ];
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { isLoading } = this.state;
    const { t } = this.props;

    return (
      <>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.take_survey')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <UserAssignmentsComponent />
        </div>
        {isLoading && <Loader />}
      </>
    );
  }
}

UserTestAssignments.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(UserTestAssignments);
