import React, { Component } from 'react';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import { withTranslation } from 'react-i18next';
import EngagementOverview from './overview/EngagementWidget';
import ENPSWidget from './overview/ENPSWidget';
import DiversityInclusionWidget from './overview/DiversityInclusionWidget';
import PulseWidget from './overview/PulseWidget';
import './styles/dashboard-overview.css';

import { getTestIdByName } from '../../services/tests/testProvider';
import Notifications from '../../utils/Notifications';
import FORMEASURE_LOGO from '../../styles/img/logo_formeasure.png';
import NoDataForm from '../form/NoDataForm';

class DashboardOverview extends Component {
  constructor(props) {
    super(props);
    // test ids
    this.state = {
      engagementId: null,
      ENPSId: null,
      pulseId: null,
      diversityId: null,
    };
  }

  componentDidMount() {
    const promises = [];
    promises.push(getTestIdByName('Engagement'));
    promises.push(getTestIdByName('eNPS'));
    promises.push(getTestIdByName('Pulse'));
    promises.push(getTestIdByName('Diversity & Inclusion'));
    Promise.all(promises)
      .then(([engagementId, ENPSId, pulseId, diversityId]) => {
        this.setState({
          engagementId: engagementId.id,
          ENPSId: ENPSId.id,
          pulseId: pulseId.id,
          diversityId: diversityId.id,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { engagementId, ENPSId, pulseId, diversityId } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div>{engagementId && <EngagementOverview />}</div>
        <div>{ENPSId && <ENPSWidget />}</div>
        <div>{pulseId && <PulseWidget />}</div>
        <div>{diversityId && <DiversityInclusionWidget />}</div>
        {!engagementId && !ENPSId && !pulseId && !diversityId && (
          <div className="ibox">
            <div className="ibox-content clearfix fm-ibox-content">
              <div className="fm-dashboard-logo-box">
                <img src={FORMEASURE_LOGO} />
              </div>
              <div className="fm-dashboard-title-box">
                <NoDataForm label={t('message.no_data_for_dashboard_widgets')} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

DashboardOverview.propTypes = {};
export default withTranslation()(DashboardOverview);
