import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import { getFeedbackByNameLike } from '../../services/feedback/feedbackProvider';
import { getUserByNameLike } from '../../services/user/userProvider';
import Notifications from '../../utils/Notifications';
import AsyncSelect from '../react-hook-form-inputs/AsyncSelect';

function InnerForm(props) {
  const { t, cancel, createFeedbackAssignment } = props;
  const schema = Yup.object().shape({
    feedback: Yup.mixed().required(t('message.required_field')),
    reviewee: Yup.mixed().required(t('message.required_field')),
  });

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      reviewee: null,
      feedback: null,
    },
  });
  const { handleSubmit } = form;

  const loadOptionsFeedback = (query, callback) => {
    if (!query) {
      return;
    }
    getFeedbackByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const loadUserOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getUserByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const onSubmit = (data) => {
    createFeedbackAssignment({
      revieweeId: data.reviewee.value,
      feedbackId: data.feedback.value,
    });
  };
  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <div className="modal-body">
        <Controller
          control={form.control}
          id="feedback"
          name="feedback"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <AsyncSelect
              name={name}
              placeholder={t('fields.feedback')}
              loadOptions={loadOptionsFeedback}
              label={t('fields.feedback')}
              onChange={onChange}
              form={form}
              isMulti={false}
              isClearable
              cacheOptions
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <Controller
          control={form.control}
          id="reviewee"
          name="reviewee"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <AsyncSelect
              name={name}
              placeholder={t('fields.reviewee')}
              loadOptions={loadUserOptions}
              label={t('fields.reviewee')}
              onChange={onChange}
              form={form}
              isMulti={false}
              isClearable
              cacheOptions
              onBlur={onBlur}
              value={value}
            />
          )}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
        <button type="submit" className="btn fm-btn-primary">
          {t('buttons.create')}
        </button>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  createFeedbackAssignment: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
