import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {
  createTest,
  getTestById,
  getTestQuestions,
  getTestTypes,
  updateTest,
} from '../../../services/tests/testProvider';
import { getQuestionTypes } from '../../../services/tests/questionProvider';
import {
  getQuestionClassificationByNameLike,
  getQuestionClassificationSelectItemSource,
} from '../../../services/questionclassification/questionClassificationProvider';

import FormTitle from '../../../components/form/FormTitle';
import PageNameContainer from '../../../components/page/PageNameContainer';
import SurveyCommonInformationForm from '../../../components/tests/surveywidget/SurveyCommonInformationForm';
import SurveyQuestionForm from '../../../components/tests/surveywidget/SurveyQuestionForm';
import SurveyScalesForm from '../../../components/tests/surveywidget/SurveyScalesForm';
import SurveySummaryForm from '../../../components/tests/surveywidget/SurveySummaryForm';
import Notifications from '../../../utils/Notifications';
import Can from '../../../components/security/Can';
import Loader from '../../../components/loader/Loader';

import '../styles/tenant-tests.scss';
import { getScalesByTestId } from '../../../services/scale/scaleProvider';
import { AUTH_ROUTES } from '../../../constants/routes';

class ViewEditTenantSurveyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showScrollTop: false,
      forms: [],
      editSubPageNumber: 1,
      currentForm: null,
      surveyTypes: [],
      questionTypes: [],
      objects: [],
      SweetAlertModal: withReactContent(Swal),
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.my_survey_templates'),
        ref: '/tests/all',
        isActive: false,
      },
      {
        name: t('breadcrumbs.survey_template'),
        ref: null,
        isActive: true,
      },
    ];
  };

  handleScroll = () => {
    document.documentElement.scrollTop > 0 || document.body.scrollTop > 0
      ? this.setState({ showScrollTop: true })
      : this.setState({ showScrollTop: false });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.getSurveyInformation(this.props.match.params.testId);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.scrollTo(0, 0);
    }
  };

  getSurveyInformation = (surveyId) => {
    this.setState({ isLoading: true });
    if (!surveyId) {
      const promises = [getTestTypes(), getQuestionTypes()];
      Promise.all(promises)
        .then(([_surveyTypes, _questionTypes]) => {
          this.setState({
            isLoading: false,
            objects: {
              survey: {},
              scales: [],
              questions: [],
            },
            surveyTypes: _surveyTypes ? _surveyTypes.items : [],
            questionTypes: _questionTypes ? _questionTypes.items : [],
          });
        })
        .catch((error) => {
          Notifications.error(error);
          this.setState({
            isLoading: false,
          });
        });
      return;
    }
    const promises = [
      getTestById(surveyId),
      getTestTypes(),
      getQuestionTypes(),
      getTestQuestions(surveyId),
      getScalesByTestId(surveyId),
    ];

    Promise.all(promises)
      .then(([_survey, _surveyTypes, _questionTypes, _questions, _scales]) => {
        this.setState({
          surveyTypes: _surveyTypes ? _surveyTypes.items : [],
          questionTypes: _questionTypes ? _questionTypes.items : [],
          objects: {
            survey: _survey
              ? {
                  ..._survey,
                  testType: {
                    value: _survey.testType,
                    label: _survey.testType,
                  },
                  testQuestionClassification:
                    _survey.testQuestionClassification.length > 0
                      ? _survey.testQuestionClassification.map((v) => ({
                          ...v,
                          key: v.key || v.id,
                          value: v.value || v.id,
                          label: v.label || v.name,
                          color: v.color,
                        }))
                      : [],
                }
              : {},
            scales: _scales
              ? _.map(_scales, (scale) => ({
                  ...scale,
                  rangeFrom: scale.rangeFrom || 0,
                  rangeTo: scale.rangeTo || 0,
                  description: scale.description || '',
                  descriptionForUser: scale.descriptionForUser || '',
                  recommendation: scale.recommendation || '',
                  color: scale.color || '',
                  isHidden: true,
                  action: '',
                  questionClassification: scale.questionClassification
                    ? {
                        ...scale.questionClassification,
                        key: scale.questionClassification.key || scale.questionClassification.id,
                        value:
                          scale.questionClassification.value || scale.questionClassification.id,
                        label:
                          scale.questionClassification.label || scale.questionClassification.name,
                        color: scale.questionClassification.color,
                      }
                    : {},
                }))
              : [],
            questions: _questions
              ? _.map(_questions, (question) => ({
                  ...question,
                  action: '',
                  isHidden: true,
                  questionType: _questionTypes
                    ? _.find(_questionTypes.items, (q) => q.value === question.questionType)
                    : 'SingleChoice', // Single choice type by default
                  answers: _.map(question.answers, (answer) => ({
                    ...answer,
                    action: '',
                  })),
                }))
              : [],
          },
          isLoading: false,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  setForm = (name, form) => {
    const { forms } = this.state;
    forms[name] = form;
    const me = this;

    me.setState({ currentForm: name });
  };

  populateObjects = (removeErrors) => {
    const { forms, objects, currentForm } = this.state;
    const values = forms[currentForm].getValues();
    const { errors } = forms[currentForm];
    const { t } = this.props;

    switch (currentForm) {
      case 'questions':
        if (!removeErrors) {
          objects.questions = values.questions ? values.questions : [];
        } else if (errors.questions) {
          const indexes = [];
          _.forEach(errors.questions, (q, index) => {
            if (q) {
              indexes.push(index);
            }
          });
          objects.questions = [];
          _.forEach(values.questions, (q, index) => {
            if (!indexes.includes(index)) {
              objects.questions.push(q);
            }
          });
        }
        _.forEach(objects.questions, (q) => {
          q.questionClassifications = JSON.parse(q.questionClassifications);
        });
        break;
      case 'scales':
        if (!removeErrors) {
          objects.scales = values.scales ? values.scales : [];
        } else if (errors.scales) {
          const indexes = [];
          _.forEach(errors.scales, (s, index) => {
            if (s) {
              indexes.push(index);
            }
          });
          objects.scales = [];
          _.forEach(values.scales, (s, index) => {
            if (!indexes.includes(index)) {
              objects.scales.push(s);
            }
          });
        }
        _.forEach(objects.scales, (s) => {
          if (s.questionClassification) {
            s.questionClassification = JSON.parse(s.questionClassification);
          }
        });
        objects.survey = {
          ...objects.survey,
          testQuestionClassification: objects.survey.testQuestionClassification
            ? _.map(objects.survey.testQuestionClassification, (tag) => {
                if (_.has(values, ['survey', 'testQuestionClassification'])) {
                  const _tag = values.survey.testQuestionClassification.find((o) => {
                    if (isNaN(o.value) && isNaN(tag.value)) {
                      return o.value === tag.value && o.label === tag.label;
                    }
                    return parseInt(o.value) === parseInt(tag.value) && o.label === tag.label;
                  });
                  if (_tag) {
                    return {
                      ...tag,
                      ..._tag,
                    };
                  }
                }
                return tag;
              })
            : [],
        };
        break;
      case 'summary':
        break;
      case 'survey':
        objects.survey = {
          ...objects.survey,
          ...values,
          testQuestionClassification: values.testQuestionClassification
            ? _.map(values.testQuestionClassification, (tag) => {
                const _tag = objects.survey.testQuestionClassification?.find(
                  (o) => parseInt(o.id) === parseInt(tag.id) && o.name === tag.name,
                );
                if (!!tag.value || !!tag.label) {
                  return {
                    ..._tag,
                    ...tag,
                    id: Number.isInteger(+tag.value) ? tag.value : null,
                    name: tag.label,
                  };
                }
                return { ..._tag, ...tag };
              })
            : [],
        };
        break;
      default:
        throw t('message.form_not_implemented');
    }
  };

  showValidationWarning = (onSubmit, removeErrors) => {
    const { t } = this.props;
    const { SweetAlertModal } = this.state;
    SweetAlertModal.fire({
      title: t('message.are_you_sure'),
      showConfirmButton: true,
      showCancelButton: true,
      text: t('message.navigation_with_not_valid_fields'),
      confirmButtonColor: '#1ab394',
      confirmButtonText: t('message.yes_navigate'),
      cancelButtonText: t('buttons.cancel'),
      showCloseButton: true,
      icon: 'warning',
    }).then((data) => {
      if (data.isConfirmed) {
        onSubmit(removeErrors);
      }
    });
  };

  onClickPrevious = () => {
    const { forms, editSubPageNumber, currentForm } = this.state;
    const me = this;

    if (!forms[currentForm]) {
      me.setState({ editSubPageNumber: editSubPageNumber - 1 });
      return;
    }
    const navigate = (removeErrors) => {
      if (editSubPageNumber > 1) {
        me.populateObjects(removeErrors);
        me.setState({ editSubPageNumber: editSubPageNumber - 1 });
      }
    };

    forms[currentForm].trigger().then((isValid) => {
      if (!isValid) {
        this.showValidationWarning(navigate, true);
      } else {
        navigate(false);
      }
    });
  };

  onClickNext = () => {
    const { forms, editSubPageNumber, currentForm } = this.state;
    const me = this;
    if (!forms[currentForm]) {
      me.setState({ editSubPageNumber: editSubPageNumber + 1 });
      return;
    }
    const navigate = (removeErrors) => {
      if (editSubPageNumber < 4) {
        me.populateObjects(removeErrors);
        me.setState({ editSubPageNumber: editSubPageNumber + 1 });
      }
    };
    forms[currentForm].trigger().then((isValid) => {
      if (!isValid && currentForm === 'survey') {
        return;
      }
      if (!isValid) {
        this.showValidationWarning(navigate, true);
      } else {
        navigate(false);
      }
    });
  };

  onClickProgressPoint = (number) => {
    const { forms, currentForm } = this.state;
    const me = this;
    if (!forms[currentForm]) {
      me.setState({ editSubPageNumber: number });
      return;
    }
    const navigate = (removeErrors) => {
      if (number < 5 && number > 0) {
        me.populateObjects(removeErrors);
        me.setState({ editSubPageNumber: number });
      }
    };

    forms[currentForm].trigger().then((isValid) => {
      if (!isValid && currentForm === 'survey') {
        return;
      }
      if (!isValid) {
        this.showValidationWarning(navigate, true);
      } else {
        navigate(false);
      }
    });
  };

  onClickSave = () => {
    const { objects } = this.state;
    const { survey } = objects;
    const { questions } = objects;
    const { scales } = objects;

    this.setState({
      isLoading: true,
    });

    const preparedQuestions = _.map(questions, (q) => {
      if (q.action !== 'delete' && q.action !== 'create') {
        _.forEach(q.answers, (a) => {
          if (a.action === 'create' || a.action === 'update' || a.action === 'delete') {
            q.action = 'update';
            return false;
          }
        });
      }
      if (q.action === '') {
        return;
      }
      return {
        action: q.action,
        objectType: q.objectType,
        object: {
          id: q.id,
          testId: survey.id,
          tenantId: survey.tenantId,
          question: q.question,
          answerRangeFrom: q.answerRangeFrom,
          answerRangeTo: q.answerRangeTo,
          questionType: q.questionType.value,
          description: q.description,
          sortOrder: q.sortOrder,
          questionClassifications: q.questionClassifications, // ?
          createdByUserId: q.createdByUserId,
          batchAnswers: _.map(q.answers, (a) => {
            if (a.action === '') {
              return;
            }
            return {
              action: a.action,
              objectType: a.objectType,
              object: {
                id: a.id,
                tenantId: survey.tenantId,
                questionId: q.id,
                answer: a.answer,
                rate: a.rate,
                createdByUserId: a.createdByUserId,
                isCorrect: a.isCorrect,
                sortOrder: a.sortOrder,
              },
            };
          }).filter((a) => a != null),
        },
      };
    }).filter((q) => q != null);

    const preparedScales = _.map(scales, (s) => {
      if (s.action === '') {
        return;
      }
      return {
        action: s.action,
        objectType: s.objectType,
        object: {
          id: s.id,
          testId: survey.id,
          questionClassification: s.questionClassification,
          rangeTo: s.rangeTo,
          rangeFrom: s.rangeFrom,
          label: s.label,
          color: s.color,
          isReverseScaling: s.isReverseScaling,
          description: s.description,
          descriptionForUser: s.descriptionForUser,
          recommendation: s.recommendation,
          sortOrder: s.sortOrder,
        },
      };
    }).filter((s) => s != null);

    const preparedSurvey = {
      ...survey,
      testType: survey.testType.value,
      batchQuestions: preparedQuestions,
      batchScales: preparedScales,
    };
    const { history, t, match } = this.props;

    if (survey.id) {
      updateTest(preparedSurvey.id, preparedSurvey)
        .then(() => {
          this.getSurveyInformation(match.params.testId);
          Notifications.success(t('message.survey_template_updated'));
        })
        .catch((error) => {
          Notifications.error(error);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      createTest(preparedSurvey)
        .then(() => {
          this.setState(
            {
              isLoading: false,
            },
            () => {
              history.push(`${AUTH_ROUTES.TESTS}/all`, {});
              Notifications.success(t('message.survey_template_created'));
            },
          );
        })
        .catch((error) => {
          Notifications.error(error);
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  onClickCancel = () => {
    const { history } = this.props;
    history.push(`${AUTH_ROUTES.TESTS}`, {});
  };

  render() {
    const { isLoading, showScrollTop, editSubPageNumber, objects, surveyTypes, questionTypes } =
      this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.survey_template')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content fm-survey-wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="ibox">
                <FormTitle
                  name={
                    objects.survey && objects.survey.title
                      ? objects.survey.title
                      : t('components.create_new_survey_template')
                  }
                />
                <div className="fm-survey-wizard-progress-bar ibox-content">
                  <div className="fm-survey-wizard-progress-container">
                    <div className="fm-survey-wizard-progress-bar-empty" />
                    <div
                      className="fm-survey-wizard-progress-bar-fill"
                      style={{ width: `${(editSubPageNumber - 1) * 33.333}%` }}
                    />
                  </div>
                  <div className="fm-survey-wizard-progress-bar-points-container">
                    <div className="fm-survey-wizard-progress-bar-point">
                      <div
                        className={`fm-survey-wizard-progress-bar-point-number ${
                          editSubPageNumber >= 1 ? 'fm-activated-point' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(1)}
                      >
                        1
                      </div>
                      <div
                        className={`fm-survey-wizard-progress-bar-point-label ${
                          editSubPageNumber === 1 ? 'fm-activated-label' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(1)}
                      >
                        <span>{t('components.common_information')}</span>
                      </div>
                    </div>
                    <div className="fm-survey-wizard-progress-bar-point">
                      <div
                        className={`fm-survey-wizard-progress-bar-point-number ${
                          editSubPageNumber >= 2 ? 'fm-activated-point' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(2)}
                      >
                        2
                      </div>
                      <div
                        className={`fm-survey-wizard-progress-bar-point-label ${
                          editSubPageNumber === 2 ? 'fm-activated-label' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(2)}
                      >
                        <span>{t('components.questions')}</span>
                      </div>
                    </div>
                    <div className="fm-survey-wizard-progress-bar-point">
                      <div
                        className={`fm-survey-wizard-progress-bar-point-number ${
                          editSubPageNumber >= 3 ? 'fm-activated-point' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(3)}
                      >
                        3
                      </div>
                      <div
                        className={`fm-survey-wizard-progress-bar-point-label ${
                          editSubPageNumber === 3 ? 'fm-activated-label' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(3)}
                      >
                        <span>{t('components.scales')}</span>
                      </div>
                    </div>
                    <div className="fm-survey-wizard-progress-bar-point">
                      <div
                        className={`fm-survey-wizard-progress-bar-point-number ${
                          editSubPageNumber >= 4 ? 'fm-activated-point' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(4)}
                      >
                        4
                      </div>
                      <div
                        className={`fm-survey-wizard-progress-bar-point-label ${
                          editSubPageNumber === 4 ? 'fm-activated-label' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(4)}
                      >
                        <span>{t('components.summary')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {objects.survey && editSubPageNumber === 1 && (
                  <SurveyCommonInformationForm
                    setForm={this.setForm}
                    surveyTypes={surveyTypes}
                    survey={objects.survey}
                    getQuestionClassificationDefault={getQuestionClassificationSelectItemSource}
                    getQuestionClassification={getQuestionClassificationByNameLike}
                  />
                )}
                {objects.survey && editSubPageNumber === 2 && (
                  <SurveyQuestionForm
                    setForm={this.setForm}
                    survey={objects.survey}
                    questionTypes={questionTypes}
                    questions={objects.questions}
                  />
                )}
                {objects.survey && editSubPageNumber === 3 && (
                  <SurveyScalesForm
                    setForm={this.setForm}
                    survey={objects.survey}
                    scales={objects.scales}
                  />
                )}
                {objects.survey && editSubPageNumber === 4 && (
                  <SurveySummaryForm
                    setForm={this.setForm}
                    survey={objects.survey}
                    scales={objects.scales}
                    questions={objects.questions}
                  />
                )}
                <div className="ibox-content fm-btn-box">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="btn-group pull-left">
                        <button
                          onClick={this.onClickPrevious}
                          type="button"
                          disabled={editSubPageNumber === 1}
                          className={`btn fm-btn-secondary ${
                            editSubPageNumber === 1 ? 'fm-btn-disabled' : ''
                          }`}
                        >
                          {t('buttons.previous')}
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="btn-group pull-right">
                        <button
                          onClick={this.onClickCancel}
                          type="button"
                          className="btn fm-btn-third"
                        >
                          {t('buttons.cancel')}
                        </button>
                        {editSubPageNumber === 4 ? (
                          <Can
                            module="tests"
                            permission="edit"
                            yes={() => (
                              <button
                                onClick={this.onClickSave}
                                type="button"
                                className="fm-btn-primary"
                              >
                                {t('buttons.save')}
                              </button>
                            )}
                            no={() => null}
                          />
                        ) : (
                          <button
                            onClick={this.onClickNext}
                            type="button"
                            className="fm-btn-primary"
                          >
                            {t('buttons.next')}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
        {showScrollTop && (
          <button
            type="button"
            onClick={(e) => {
              this.scrollToTop(e);
            }}
            className="fa fa-chevron-up animated fadeIn"
            id="fm-scroll-questions-top"
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        )}
      </div>
    );
  }
}

ViewEditTenantSurveyPage.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withTranslation()(withRouter(ViewEditTenantSurveyPage));
