import React, { Component } from 'react';
import DashboardWidget from '../DashboardWidget';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import { getDashbortChartsData } from '../../../services/analytics/analyticsProvider';
import Notifications from '../../../utils/Notifications';
import { withTranslation } from 'react-i18next';

class ENPSWidget extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      name: t('widgets.eNPS'),
      systemName: 'eNPS',
      loaded: false,
    };
  }

  componentDidMount() {
    const { name } = this.state;
    getDashbortChartsData(name)
      .then((data) => {
        if (!data) {
          return;
        }
        this.setState({
          benchmark: data.benchmark,
          score: data.average,
          participation: data.participation,
          loaded: true,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { name, participation, score, benchmark, loaded, systemName } = this.state;

    return (
      <div className="ibox">
        {loaded && (
          <DashboardWidget
            name={name}
            systemName={systemName}
            score={score}
            benchmark={benchmark}
            participation={participation}
          />
        )}
      </div>
    );
  }
}

ENPSWidget.propTypes = {};
export default withTranslation()(ENPSWidget);
