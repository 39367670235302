import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { compose } from 'recompose';
import _ from 'lodash';

import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import PassingQuestion from './PassingQuestion';
import Notifications from '../../../utils/Notifications';
import { getQuestion } from '../../../services/tests/questionProvider';
import {
  answerQuestion,
  getQuestionAnswerResultByAssignmentQuestion,
} from '../../../services/questionanswerresult/questionAnswerResultProvider';
import PassingProgressBar from './PassingProgressBar';
import StartPassingTestForm from './StartPassingTestForm';
import EndPassingTestForm from './EndPassingTestForm';
import ContentLoader from '../../loader/ContentLoader';
import UserAssignmentResults from './UserAssignmentResults';
import MaterialHtmlTooltip from '../../ui-elements/MaterialHtmlTooltip';

class PassingTestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      userAnswer: {},
      question: null,
      questionAnswerResults: null,
      animation: 'fadeIn',
    };
    props.setValues({
      ...props.values,
      saveQuestionResult: this.saveQuestionResult,
    });
  }

  changeQuestion(questionIds, step) {
    const { testAssignment } = this.props;

    const questionId = questionIds[step].id;
    const assignmentId = testAssignment.id;
    const promises = [
      getQuestion(questionId),
      getQuestionAnswerResultByAssignmentQuestion(assignmentId, questionId),
    ];
    Promise.all(promises)
      .then(([question, questionAnswerResults]) => {
        this.setState({
          question,
          questionAnswerResults,
        });
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }

  componentDidUpdate(prevProps) {
    const { questionIds, testAssignment } = this.props;
    if (testAssignment !== prevProps.testAssignment || questionIds !== prevProps.questionIds) {
      if (!testAssignment || !questionIds) {
        return;
      }
      if (testAssignment.status === 'Finished' || testAssignment.status === 'Expired') {
        // we don't need to get question/answer data for resulting screen
        return;
      }
      if (
        testAssignment.lastAnsweredQuestionId &&
        questionIds.map((v) => v.id).indexOf(testAssignment.lastAnsweredQuestionId) > -1
      ) {
        let _step = questionIds.map((v) => v.id).indexOf(testAssignment.lastAnsweredQuestionId);
        if (questionIds.length > _step + 1) {
          _step++;
        }
        this.setState(
          {
            step: _step,
          },
          () => this.changeQuestion(questionIds, this.state.step),
        );
      } else {
        this.changeQuestion(questionIds, this.state.step);
      }
    }
  }

  handleChangeUserAnswer = (questionId, answerResult) => {
    const { userAnswer } = this.state;
    userAnswer[questionId] = answerResult;
    this.setState({ userAnswer });
  };

  saveQuestionResult = () => {
    const { userAnswer } = this.state;
    const promises = [];
    if (!_.isEmpty(userAnswer)) {
      for (const prop in userAnswer) {
        promises.push(answerQuestion(userAnswer[prop]));
      }
      return Promise.all(promises);
    }
    return Promise.resolve();
  };

  nextStep = () => {
    this.setState({
      question: null,
    });
    const { questionIds } = this.props;
    this.saveQuestionResult()
      .then((d) => {
        this.setState(
          {
            userAnswer: {},
            step: this.state.step + 1,
            question: null,
            questionAnswerResults: null,
          },
          () => {
            this.changeQuestion(questionIds, this.state.step);
          },
        );
      })
      .catch((e) => {
        Notifications.error(e);
      });
  };

  prevStep = () => {
    this.setState({
      question: null,
    });
    const { questionIds } = this.props;

    this.saveQuestionResult()
      .then((d) => {
        this.setState(
          {
            userAnswer: {},
            step: this.state.step - 1,
            questionAnswerResults: null,
          },
          () => {
            this.changeQuestion(questionIds, this.state.step);
          },
        );
      })
      .catch((e) => {
        Notifications.error(e);
      });
  };

  render() {
    const {
      t,
      handleSubmit,
      questionIds,
      testAssignment,
      setFieldValue,
      changeTestAssignmentStatus,
    } = this.props;
    const { step, question, questionAnswerResults, userAnswer, animation } = this.state;
    const questionCount = questionIds && questionIds.length;

    const showNext =
      question &&
      (userAnswer[question.id] ||
        questionAnswerResults.filter((v) => v.questionId === question.id).length > 0);

    return (
      <>
        {testAssignment ? (
          testAssignment.status === 'New' ? (
            <StartPassingTestForm
              testAssignment={testAssignment}
              changeTestAssignmentStatus={changeTestAssignmentStatus}
            />
          ) : testAssignment.status === 'InProgress' || testAssignment.status === 'Draft' ? (
            <Form onSubmit={handleSubmit} className="ibox">
              <>
                <div className="ibox-title fm-question-title">
                  {testAssignment.testDescription && (
                    <div className="fm-survey-instructions">
                      <MaterialHtmlTooltip
                        title={testAssignment.testDescription}
                        placement="left"
                        arrow
                      >
                        <InfoOutlined />
                      </MaterialHtmlTooltip>
                    </div>
                  )}
                  <div className="fm-question-box">
                    {question ? question.question : <ContentLoader visible />}
                  </div>
                  <div className="fm-counter-box">
                    {`${step + 1} ${t('components.of')} ${questionCount}`}
                  </div>
                </div>
                <div className="ibox-content clearfix fm-passing-form ">
                  {question && testAssignment && (
                    <PassingQuestion
                      question={question}
                      testAssignment={testAssignment}
                      animation={animation}
                      populateUserAnswer={this.handleChangeUserAnswer}
                      questionAnswerResults={questionAnswerResults}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {question && (
                    <div className="text-center animated fadeIn">
                      {step > 0 ? (
                        <button
                          type="button"
                          className="btn fm-btn-primary"
                          onClick={this.prevStep}
                        >
                          {t('buttons.BACK')}
                        </button>
                      ) : null}
                      {questionCount === step + 1 ? (
                        <button
                          type="submit"
                          key={`submit-${testAssignment.id}`}
                          disabled={!showNext}
                          className="btn fm-btn-primary"
                        >
                          {t('buttons.SUBMIT')}
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled={!showNext}
                          className="btn fm-btn-primary"
                          onClick={this.nextStep}
                        >
                          {t('buttons.NEXT')}
                          <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                      )}
                    </div>
                  )}
                  <div className="fm-passing-progress-bar">
                    <PassingProgressBar
                      id="fm-passing-progressbar"
                      progress={(step / questionCount) * 100}
                    />
                  </div>
                </div>
              </>
            </Form>
          ) : testAssignment && testAssignment.showResultToUser ? (
            <UserAssignmentResults testAssignment={testAssignment} />
          ) : (
            <EndPassingTestForm />
          )
        ) : (
          <div className="ibox ">
            <div className="ibox-content d-flex min-vh-80 align-items-center fm-round-border-form">
              <ContentLoader visible />
            </div>
          </div>
        )}
      </>
    );
  }
}

PassingTestForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  questionIds: PropTypes.array.isRequired,
  testAssignment: PropTypes.object,
  changeTestAssignmentStatus: PropTypes.func.isRequired,
};

export default withTranslation()(
  compose(
    withFormik({
      enableReinitialize: true,
      mapPropsToValues: () => ({}),
      handleSubmit: (values, o) => {
        const { props } = o;
        values
          .saveQuestionResult()
          .then(() => props.changeTestAssignmentStatus('Finished'))
          .catch((error) => {
            Notifications.error(error);
          });
      },
    }),
  )(PassingTestForm),
);
