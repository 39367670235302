import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

class NotFound extends PureComponent {
  render() {
    const { t } = this.props;
    return (
      <div className="middle-box text-center animated fadeInDown ng-scope">
        <h1>404</h1>
        <h3 className="font-bold">{t('components.page_not_found')}</h3>

        <div className="error-desc">{t('message.page_not_found')}</div>
      </div>
    );
  }
}

export default withTranslation()(NotFound);
