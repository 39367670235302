import React from 'react';
import { withFormik } from 'formik';
import '../styles/questions.css';
import Slider from '@mui/material/Slider';

function InnerForm(props) {
  const { question, testAssignment, populateUserAnswer, questionAnswerResults, animation } = props;

  const buildAnswer = (e, question, value) => {
    const action =
      !questionAnswerResults || questionAnswerResults.length <= 0 ? 'create' : 'update';
    let questionAnswerId = null;
    if (action === 'update') {
      questionAnswerId = {
        id: questionAnswerResults[0].id,
        tenantId: questionAnswerResults[0].tenantId,
      };
    }
    populateUserAnswer(question.id, [
      {
        object: {
          ...questionAnswerId,
          questionId: question.id,
          testAssignmentId: testAssignment.id,
          dimension: value,
        },
        action,
        objectType: 'Platform:QuestionAnswerResult',
      },
    ]);
  };

  const min = question.answerRangeFrom;
  const max = question.answerRangeTo;

  const marks = [];
  for (let i = min; i <= max; i++) {
    marks.push({ value: i, label: i });
  }
  let defaultValue = 0;
  if (questionAnswerResults && questionAnswerResults.length > 0) {
    defaultValue = questionAnswerResults[0].dimension;
  }

  return (
    <div className={`fm-answer-container animated ${animation}`} style={{ height: '400px' }}>
      <Slider
        valueLabelDisplay="auto"
        step={1}
        sx={{
          color: '#34B77C',
          '& .MuiSlider-thumb': {
            '&:hover, &.Mui-focusVisible': {
              boxShadow: `0px 0px 0px 8px rgb(52, 183, 124, .16)`,
            },
            '&.Mui-active': {
              boxShadow: `0px 0px 0px 14px rgb(52, 183, 124, .16)`,
            },
          },
        }}
        onChange={(e, value) => {
          buildAnswer(e, question, value);
        }}
        defaultValue={defaultValue}
        marks={marks}
        size="medium"
        getAriaValueText={(v) => v}
        min={question.answerRangeFrom}
        max={question.answerRangeTo}
      />
    </div>
  );
}

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ questionAnswerResults, question }) => {},
})(InnerForm);
