const FileUtil = {
  getFileExtension: (filename) => filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2),
  getFileName: (fullName) => {
    if (fullName && typeof fullName === 'string') {
      const startIndex =
        fullName.indexOf('\\') >= 0 ? fullName.lastIndexOf('\\') : fullName.lastIndexOf('/');
      let filename = fullName.substring(startIndex);

      if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1);
      }

      return filename;
    }

    return null;
  },
};
export default FileUtil;
