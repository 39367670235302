import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AllAssignmentPeriods from '../../containers/assignmentperiod/AllAssignmentPeriods';
import EditAssignmentPeriodPage from '../../containers/assignmentperiod/EditAssignmentPeriodPage';
import AssignmentPeriodSummaryPage from '../../containers/assignmentperiod/AssignmentPeriodSummaryPage';

import { ACTION, ASSIGNMENT_PERIOD_ID, AUTH_ROUTES } from '../../constants/routes';

class AssignmentPeriodRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllAssignmentPeriods} />
        <Route
          path={`${match.url}${ASSIGNMENT_PERIOD_ID}${ACTION}`}
          render={({ match }) => <AssignmentPeriodInnerRouting match={match} />}
        />
      </div>
    );
  }
}

AssignmentPeriodRoutes.propTypes = {
  match: PropTypes.object,
};

export default AssignmentPeriodRoutes;

class AssignmentPeriodInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let assignmentPeriodRouting = (
      <Route
        exact
        path={`${AUTH_ROUTES.ASSIGNMENT_PERIODS}${ASSIGNMENT_PERIOD_ID}`}
        component={AllAssignmentPeriods}
      />
    );

    if (match.params.action && match.params.action === 'summary') {
      assignmentPeriodRouting = (
        <Route
          path={`${AUTH_ROUTES.ASSIGNMENT_PERIODS}${ASSIGNMENT_PERIOD_ID}/summary`}
          component={AssignmentPeriodSummaryPage}
        />
      );
    }

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.ASSIGNMENT_PERIODS}${ASSIGNMENT_PERIOD_ID}/edit`}
          component={EditAssignmentPeriodPage}
        />
        {assignmentPeriodRouting}
      </div>
    );
  }
}

AssignmentPeriodInnerRouting.propTypes = {
  match: PropTypes.object,
};
