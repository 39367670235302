import React, { Component } from 'react';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import Can from '../security/Can';

class TestAssignmentActionsRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.original.id,
      status: props.original.status,
      securityModule: props.column.securityModule,
    };

    this.onDeleteItem = props.column.onDeleteItem;
    this.onViewItem = props.column.onViewItem;
    this.onPassItem = props.column.onPassTest;

    this.canDelete = props.column.canDelete ? props.column.canDelete : false;
    this.canView = props.column.canView ? props.column.canView : false;
    this.canPassTest = props.column.canPassTest ? props.column.canPassTest : false;
  }

  deleteItem = () => {
    this.onDeleteItem(this.state.id);
  };

  viewItem = () => {
    this.onViewItem(this.state.id);
  };

  passTest = () => {
    this.onPassItem(this.state.id);
  };

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
    if (props.original.status !== prevState.status) {
      this.setState({ status: props.original.status });
    }
  }

  render() {
    const { status, securityModule } = this.state;
    const { t } = this.props;
    let passingLabel;
    switch (status) {
      case 'InProgress':
        passingLabel = t('components.continue');
        break;
      case 'New':
        passingLabel = t('components.start');
        break;
      case 'Finished':
        passingLabel = t('components.show_result');
        break;
      default:
        passingLabel = t('components.start');
    }

    return (
      <div className="fm-btn-group">
        {this.canView ? (
          <Can
            module={securityModule}
            permission="edit"
            yes={() => (
              <button
                className="btn fm-table-btn fm-btn-xs-edit"
                onClick={this.viewItem}
                title={t('components.edit')}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            )}
            no={() => null}
          />
        ) : null}
        {this.canDelete ? (
          <Can
            module={securityModule}
            permission="delete"
            yes={() => (
              <button
                className="btn fm-table-btn fm-btn-xs-delete"
                onClick={this.deleteItem}
                title={t('components.delete')}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            )}
            no={() => null}
          />
        ) : null}

        {this.canPassTest ? (
          <Can
            module="feedback-assignments"
            permission="pass"
            yes={() => (
              <button
                className="btn fm-table-btn fm-btn-xs-edit"
                title={passingLabel}
                onClick={this.passTest}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            )}
            no={() => null}
          />
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(TestAssignmentActionsRenderer);
