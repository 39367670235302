import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import Can from '../security/Can';
import Notifications from '../../utils/Notifications';
import { checkPermission } from '../../utils/SecurityUtil';
import Input from '../react-hook-form-inputs/Input';
import AsyncSelect from '../react-hook-form-inputs/AsyncSelect';
import {
  getOrgUnitDefByNameLike,
  getOrgUnitDefSelectItemSource,
} from '../../services/orgunitdef/orgUnitDefProvider';
import {
  getOrgUnitExcludeCircularDependency,
  getOrgUnitExcludeCircularDependencyLike,
} from '../../services/orgunit/orgUnitProvider';
import { getUserByNameLike, getUserSelectItemSource } from '../../services/user/userProvider';

function InnerForm(props) {
  const { t, orgUnit, updateOrgUnit } = props;

  const schema = Yup.object().shape({
    name: Yup.string().required(t('message.field_required')),
    shortName: Yup.string().required(t('message.field_required')),
    orgUnitType: Yup.mixed().required(t('message.field_required')),
  });

  const orgUnitValues = {
    name: orgUnit.name,
    shortName: orgUnit.shortName,
    orgUnitType: {
      value: orgUnit.orgStructureDefId,
      label: orgUnit.orgStructureDefName,
    },
    manager: orgUnit.orgStructureUnitManager
      ? {
          value: orgUnit.orgStructureUnitManager.id,
          label: orgUnit.orgStructureUnitManager.displayString,
        }
      : null,
    parentOrgUnit: orgUnit.parent
      ? {
          value: orgUnit.parent.id,
          label: orgUnit.parent.name,
        }
      : null,
    created: orgUnit.created ? moment.utc(orgUnit.created).format('LLL') : '',
    updated: orgUnit.updated ? moment.utc(orgUnit.updated).format('LLL') : '',
  };

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => orgUnitValues, [props.orgUnit]),
  });

  const { handleSubmit, control, watch, reset } = form;

  const [defaultUserOptions, setDefaultUserOptions] = useState([]);
  const [defaultOrganizationUnitTypeOptions, setDefaultOrganizationUnitTypeOptions] = useState([]);
  const [defaultOrganizationOptions, setDefaultOrganizationOptions] = useState([]);
  useEffect(() => {
    const promises = [];
    promises.push(getUserSelectItemSource({ amount: 100, skip: 0 }));
    promises.push(
      getOrgUnitExcludeCircularDependency({ amount: 10, skip: 0, orgUnitId: orgUnit.id }),
    );
    promises.push(getOrgUnitDefSelectItemSource({ amount: 100, skip: 0 }));

    Promise.all(promises)
      .then(
        ([defaultUserOptions, defaultOrganizationOptions, defaultOrganizationUnitTypeOptions]) => {
          setDefaultUserOptions(defaultUserOptions);
          setDefaultOrganizationOptions(defaultOrganizationOptions);
          setDefaultOrganizationUnitTypeOptions(defaultOrganizationUnitTypeOptions);
        },
      )
      .catch((error) => Notifications.error(error));
  }, []);
  useEffect(() => {
    reset(orgUnitValues);
  }, [props.orgUnit]);

  const watchers = watch(['manager', 'parentOrgUnit', 'orgUnitType']);

  const orgUnitDefLink = `/org/org-unit-def/${
    watchers.orgUnitType ? watchers.orgUnitType.value : null
  }/edit`;
  const managerLink = `/users/${watchers.manager ? watchers.manager.value : null}/edit`;
  const parentLink = `/org/org-unit/${
    watchers.parentOrgUnit ? watchers.parentOrgUnit.value : null
  }/edit`;

  const loadUserOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getUserByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  const loadOrganizationUnitTypeOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getOrgUnitDefByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  const loadOrganizationOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getOrgUnitExcludeCircularDependencyLike(orgUnit.id, query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  const { permissions } = props.account;
  const hasAccessViewType = checkPermission('org-unit-definition', permissions, 'edit');
  const onSubmit = (data) => {
    updateOrgUnit({
      ...orgUnit,
      name: data.name,
      shortName: data.shortName,
      orgStructureDefId: data.orgUnitType ? data.orgUnitType.value : null,
      parentId: data.parentOrgUnit ? data.parentOrgUnit.value : null,
      orgStructureUnitManagerId: data.manager ? data.manager.value : null,
    });
  };
  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Controller
        control={control}
        id="name"
        name="name"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <Input
            name={name}
            placeholder={t('fields.organization_unit_name')}
            label={t('fields.organization_unit_name')}
            form={form}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        control={control}
        id="shortName"
        name="shortName"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <Input
            name={name}
            placeholder={t('fields.organization_unit_short_name')}
            label={t('fields.organization_unit_short_name')}
            form={form}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        control={control}
        id="orgUnitType"
        name="orgUnitType"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <AsyncSelect
            name={name}
            placeholder={t('fields.type')}
            loadOptions={loadOrganizationUnitTypeOptions}
            label={
              hasAccessViewType && watchers.orgUnitType ? (
                <NavLink to={orgUnitDefLink}>{t('fields.type')}</NavLink>
              ) : (
                t('fields.type')
              )
            }
            defaultOptions={defaultOrganizationUnitTypeOptions}
            onChange={onChange}
            form={form}
            isMulti={false}
            isClearable
            cacheOptions
            onBlur={onBlur}
            value={value}
          />
        )}
      />
      <Controller
        control={control}
        id="manager"
        name="manager"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <AsyncSelect
            name={name}
            placeholder={t('fields.manager')}
            loadOptions={loadUserOptions}
            label={
              hasAccessViewType && watchers.manager ? (
                <NavLink to={managerLink}>{t('fields.manager')}</NavLink>
              ) : (
                t('fields.manager')
              )
            }
            defaultOptions={defaultUserOptions}
            onChange={onChange}
            form={form}
            isMulti={false}
            isClearable
            cacheOptions
            onBlur={onBlur}
            value={value}
          />
        )}
      />
      <Controller
        control={control}
        id="parentOrgUnit"
        name="parentOrgUnit"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <AsyncSelect
            name={name}
            placeholder={t('fields.parent_organization')}
            loadOptions={loadOrganizationOptions}
            label={
              hasAccessViewType && watchers.parentOrgUnit ? (
                <NavLink to={parentLink}>{t('fields.parent_organization')}</NavLink>
              ) : (
                t('fields.parent_organization')
              )
            }
            defaultOptions={defaultOrganizationOptions}
            onChange={onChange}
            form={form}
            isMulti={false}
            isClearable
            cacheOptions
            onBlur={onBlur}
            value={value}
          />
        )}
      />
      <Controller
        control={form.control}
        id="created"
        name="created"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <Input
            name={name}
            label={t('fields.created')}
            onChange={onChange}
            form={form}
            onBlur={onBlur}
            value={value}
            disabled
            placeholder={t('fields.date_format_mm_dd_yyyy')}
          />
        )}
      />
      <Controller
        control={form.control}
        id="updated"
        name="updated"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <Input
            name={name}
            label={t('fields.updated')}
            onChange={onChange}
            form={form}
            onBlur={onBlur}
            value={value}
            disabled
            placeholder={t('fields.date_format_mm_dd_yyyy')}
          />
        )}
      />
      <Can
        module="org-unit"
        permission="edit"
        yes={() => (
          <button type="submit" className="btn fm-btn-primary block pull-right">
            {t('buttons.save')}
          </button>
        )}
        no={() => null}
      />
    </form>
  );
}

InnerForm.propTypes = {
  orgUnit: PropTypes.object,
  updateOrgUnit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withTranslation()(connect(mapStateToProps)(InnerForm));
