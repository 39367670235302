import dataProvider from '../dataProvider';
import { questiontemplate, testtemplate } from '../../constants/endpoints';

export const getTestTemplateList = (params) =>
  dataProvider({
    url: testtemplate.all(),
    params,
    method: 'GET',
  });

export const getTestTemplateDetails = (itemId) =>
  dataProvider({
    url: testtemplate.getById(itemId),
    method: 'GET',
  });

export const getTestTemplateById = (itemId) =>
  dataProvider({
    url: testtemplate.getById(itemId),
    method: 'GET',
  });

export const deleteTestTemplate = (itemId) =>
  dataProvider({
    url: testtemplate.delete(itemId),
    method: 'DELETE',
  });

export const createTestTemplate = (data) =>
  dataProvider({
    url: testtemplate.create(),
    method: 'POST',
    data,
  });

export const updateTestTemplate = (testTemplateId, data) =>
  dataProvider({
    url: testtemplate.update(testTemplateId),
    method: 'PUT',
    data,
  });

export const getTestTemplateQuestions = (testId) =>
  dataProvider({
    url: questiontemplate.getByTest(testId),
    method: 'GET',
  });

export const getTestTemplateQuestionIds = (testId) =>
  dataProvider({
    url: questiontemplate.getIdsByTest(testId),
    method: 'GET',
  });

export const getTestTemplateTypes = () =>
  dataProvider({
    url: testtemplate.types(),
    method: 'GET',
  });

export const getTestTemplateSelectItemSource = (params) =>
  dataProvider({
    url: testtemplate.itemSource(),
    data: params,
    method: 'POST',
  });

export const copyToTenant = (params) =>
  dataProvider({
    url: testtemplate.copyToTenant(),
    data: params,
    method: 'POST',
  });

export const importTestTemplate = (params) =>
  dataProvider({
    url: testtemplate.importTestTemplate(),
    data: params,
    method: 'POST',
  });

export const validateTestTemplateImportFile = (params) =>
  dataProvider({
    url: testtemplate.validateTestTemplateImportFile(),
    data: params,
    method: 'POST',
  });

export const getTestTemplateExpectedImportRows = (params) =>
  dataProvider({
    url: testtemplate.getTestTemplateExpectedImportRows(),
    data: params,
    method: 'GET',
  });

export const exportTestTemplate = (testId) =>
  dataProvider({
    responseType: 'arraybuffer',
    url: testtemplate.exportTestTemplate(testId),
  });
