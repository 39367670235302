import React, { Component } from 'react';
import './styles/assignment-period-summary.css';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faComments } from '@fortawesome/free-solid-svg-icons';

class AssignmentPeriodQuestionBoxMultiple extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { question, index, isUserResult } = this.props;
    const { answersMapping } = question;
    return (
      <div className="fm-ap-question-box" key={`ap-question-multiple-${index}`}>
        <div className="fm-ap-question">
          <div className="fm-ap-question-number">
            <div>Q{index + 1}:</div>
            <div>{question.question}</div>
          </div>
          {question.comments.length > 0 && (
            <div className="fm-ap-question-response-box">
              <div className="fm-ap-question-response-info">
                <span className="fm-ap-question-response-number">{question.comments.length}</span>
                <FontAwesomeIcon icon={faComments} />
              </div>
            </div>
          )}
        </div>
        <div className="fm-ap-question-answers">
          <div className="fm-ap-question-legend-box">
            {answersMapping &&
              Object.keys(answersMapping).map((label, _index) => (
                <div className="fm-ap-question-legend-row" key={`fm-ap-label-${_index}`}>
                  <div className="fm-ap-question-legend-key">
                    <span>{label}</span>
                  </div>

                  {isUserResult ? (
                    <div className="fm-ap-question-legend-value">
                      {answersMapping[label] !== 0 ? (
                        <span className="checked">
                          <FontAwesomeIcon icon={faCheck} />
                        </span>
                      ) : null}
                    </div>
                  ) : (
                    <>
                      <div className="fm-ap-question-legend-value">
                        {parseFloat(answersMapping[label] * 100).toFixed(1)}%
                      </div>
                      <div className="fm-ap-question-legend-progress">
                        <div className="fm-ap-question-progress-bar-box">
                          <div
                            className="fm-ap-question-progress"
                            style={{
                              width: `${answersMapping[label] * 100}%`,
                              backgroundColor: '#34B77C',
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

AssignmentPeriodQuestionBoxMultiple.propTypes = {};
export default withTranslation()(AssignmentPeriodQuestionBoxMultiple);
