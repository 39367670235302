import dataProvider from '../dataProvider';
import { account, auth, user } from '../../constants/endpoints';

export const login = (data) => {
  const { tenant, ..._data } = data;

  return dataProvider(
    {
      url: auth.login(),
      method: 'POST',
      data: _data,
    },
    { tenant },
  );
};

export const verify = (data) =>
  dataProvider({
    url: user.verify(),
    method: 'GET',
    params: data,
    paramsSerializer(params) {
      const keys = Object.keys(params);
      return `${keys[0]}=${params[keys[0]]}`;
    },
  });

export const getUserDetails = () =>
  dataProvider({
    url: auth.me(),
    method: 'GET',
  });

export const registration = (data) => {
  const { tenant, ..._data } = data;

  return dataProvider(
    {
      url: auth.registration(),
      method: 'POST',
      data: _data,
    },
    { tenant },
  );
};

export const refreshToken = (token) =>
  dataProvider(
    {
      url: auth.refreshToken(),
      refreshTokenAction: true,
      method: 'GET',
    },
    { token },
  );

export const hasEmail = (params) =>
  dataProvider({
    url: account.checkEmail(),
    params,
    method: 'GET',
  });

export const hasUsername = (params) =>
  dataProvider({
    url: account.checkUsername(),
    params,
    method: 'GET',
  });
