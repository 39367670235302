import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import * as regularIcons from '@fortawesome/free-regular-svg-icons';
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import MaterialHtmlTooltip from '../../ui-elements/MaterialHtmlTooltip';
import MaterialRadio from '../../react-hook-form-inputs/MaterialRadio';

function InnerForm(props) {
  const { setCriteriaDescription, hasError, answer, onClickComment, readOnly, criterionIndex } =
    props;
  const [criterion, setCriterion] = useState(props.criterion);
  const [isConfigured, setIsConfigured] = useState(false);
  const [feedbackCriteriaDescriptions, setFeedbackCriteriaDescriptions] = useState(null);

  useEffect(() => {
    const feedbackCriteriaDescriptions = new Map();
    for (let i = 0; i < criterion.feedbackCriteriaDescriptions.length; i++) {
      feedbackCriteriaDescriptions.set(
        criterion.feedbackCriteriaDescriptions[i].id,
        criterion.feedbackCriteriaDescriptions[i],
      );
    }
    setIsConfigured(true);
    setFeedbackCriteriaDescriptions(feedbackCriteriaDescriptions);
  }, []);

  const { control, touched, errors } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: `criteria[${criterionIndex}].feedbackCriteriaDescriptions`,
    keyName: 'answerKeyId',
  });

  const changeAnswer = (e, descriptionId) => {
    setCriteriaDescription(criterion.id, feedbackCriteriaDescriptions.get(descriptionId));
  };

  const isChecked = (value) =>
    typeof value === 'string' ? JSON.parse(value.toLowerCase()) : value;

  return (
    <div
      className={classnames('fm-feedback-criteria-answer', {
        'fm-feedback-criteria-error': hasError,
      })}
    >
      <MaterialHtmlTooltip
        className="fm-feedback-question-box"
        title={criterion.description ? criterion.description : criterion.name}
        arrow
      >
        <div>
          <div className="fm-feedback-criteria-name">
            {hasError && (
              <div className="fm-icon">
                <WarningAmberRoundedIcon />
              </div>
            )}
            {criterion.name}
          </div>
          <div
            className="fm-comment-wrapper"
            onClick={() => onClickComment(answer.feedbackCriteriaId)}
          >
            <div className="fm-button-circle">
              <FontAwesomeIcon
                icon={answer.comment ? solidIcons.faComment : regularIcons.faComment}
              />
            </div>
          </div>
        </div>
      </MaterialHtmlTooltip>
      <div className="fm-feedback-descriptions-box">
        {isConfigured && fields.length > 0
          ? fields.map((description, index) => (
              <MaterialHtmlTooltip
                title={description.description ? description.description : description.label}
                arrow
                key={`fm-fb-criteria-answer_${description.id}`}
              >
                <div className="fm-fb-criteria-answer">
                  <Controller
                    control={control}
                    name={`criterion[${criterionIndex}].feedbackCriteriaDescriptions`}
                    defaultValue={false}
                    defaultChecked={false}
                    render={({ onChange, onBlur, value, name }) => (
                      <MaterialRadio
                        name={name}
                        form={{ touched, errors }}
                        label={description.label}
                        checked={isChecked(answer.value) === description.value}
                        checkedElementColor={description.color}
                        elementColor={description.color}
                        onBlur={onBlur}
                        onChange={(event) => {
                          changeAnswer(event, description.id);
                          onChange(event.target.checked);
                        }}
                        disabled={readOnly}
                        value={isChecked(answer.value) === description.value}
                      />
                    )}
                  />
                </div>
              </MaterialHtmlTooltip>
            ))
          : null}
      </div>
    </div>
  );
}

InnerForm.propTypes = {
  criterion: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  setCriteriaDescription: PropTypes.func.isRequired,
  onClickComment: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  hasError: PropTypes.bool,
};

export default InnerForm;
