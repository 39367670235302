import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getFeedbackAssignmentRequestByReviewerIdPagination } from '../../services/feedbackassignmentreviewer/feedbackAssignmentReviewerProvider';

import Loader from '../../components/loader/Loader';
import Notifications from '../../utils/Notifications';

import UserFeedbackAssignmentRequestsTable from '../../components/feedbackassignments/UserFeedbackAssignmentRequestsTable';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';

class UserFeedbackAssignmentRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.my_feedback_requests'),
        ref: null,
        isActive: true,
      },
    ];
  };

  fetchFeedbackAssignmentRequestList = (amount, skip, sorting, filters, callback) => {
    const { account } = this.props;
    getFeedbackAssignmentRequestByReviewerIdPagination({
      amount,
      skip,
      sortings: sorting,
      filters,
      userId: account.userId,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { tableRef, isLoading } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.my_feedback_requests')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>

        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle name={t('components.my_feedback_requests')} />
            <UserFeedbackAssignmentRequestsTable
              fetchFeedbackAssignmentRequestList={this.fetchFeedbackAssignmentRequestList}
              setTableReference={this.setTableReference}
              tableReference={tableRef}
            />
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withTranslation()(connect(mapStateToProps)(UserFeedbackAssignmentRequests));
