import React, { Component } from 'react';

import { faPlus, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Loader from '../../../components/loader/Loader';
import Notifications from '../../../utils/Notifications';
import { AUTH_ROUTES } from '../../../constants/routes';
import PageNameContainer from '../../../components/page/PageNameContainer';
import FormTitle from '../../../components/form/FormTitle';
import Modal from '../../../components/modal/Modal';
import {
  createQuestionClassificationTemplate,
  deleteQuestionClassificationTemplate,
  getQuestionClassificationTemplateByName,
  getQuestionClassificationTemplatesPagination,
} from '../../../services/questionclassificationtemplate/questionClassificationTemplateProvider';
import AllQuestionClassificationsTable from '../../../components/tests/questionclassification/AllQuestionClassificationsTable';
import CreateQuestionClassificationModal from '../../../components/tests/questionclassification/CreateQuestionClassificationModal';

class AllQuestionClassificationTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isModalShown: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_tag_templates'),
        ref: null,
        isActive: true,
      },
    ];
  };

  getQuestionClassificationTemplatesPagination = (amount, skip, sorting, filters, callback) => {
    getQuestionClassificationTemplatesPagination({
      amount,
      skip,
      sortings: sorting,
      filters,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  openCreateQuestionClassificationModal = () => {
    this.setState({ isModalShown: true });
  };

  closeModal = () => {
    this.setState({ isModalShown: false });
  };

  createQuestionClassificationTemplate = (values) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
      isModalShown: false,
    });
    createQuestionClassificationTemplate(values)
      .then(() => {
        this.state.tableRef.updateTableData(false);
        this.setState({ isLoading: false });
        Notifications.success(t('message.tag_template_created'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { isLoading, isModalShown, tableRef } = this.state;
    const { GLOBAL_TEST } = AUTH_ROUTES;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.all_tag_templates')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.all_tag_templates')}
              buttons={[
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.openCreateQuestionClassificationModal,
                  name: t('buttons.create'),
                  icon: faPlus,
                  securityModule: 'global-question-classification',
                  securityPermission: 'create',
                },
              ]}
            />
            <AllQuestionClassificationsTable
              fetchQuestionClassificationList={this.getQuestionClassificationTemplatesPagination}
              deleteQuestionClassification={deleteQuestionClassificationTemplate}
              objectLinkBase={GLOBAL_TEST.GLOBAL_QUESTION_CLASSIFICATION}
              isTemplate
              canDelete
              canView
              securityModule="global-question-classification"
              setTableReference={this.setTableReference}
              tableReference={tableRef}
            />
          </div>
        </div>
        <Modal
          header={t('components.create_tag_template')}
          isOpened={isModalShown}
          icon={faSitemap}
          onClose={this.closeModal}
        >
          <CreateQuestionClassificationModal
            createQuestionClassification={this.createQuestionClassificationTemplate}
            cancel={this.closeModal}
            getQuestionClassificationByName={getQuestionClassificationTemplateByName}
          />
        </Modal>
        {isLoading && <Loader />}
      </div>
    );
  }
}

AllQuestionClassificationTemplates.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AllQuestionClassificationTemplates);
