import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import AsyncSelectComponent from '../react-hook-form-inputs/AsyncSelect';
import DatePicker from '../react-hook-form-inputs/DatePicker';
import Notifications from '../../utils/Notifications';
import { getUserSelectItemSource } from '../../services/user/userProvider';
import { getOrgUnitSelectItemSource } from '../../services/orgunit/orgUnitProvider';
import { getEmployeeJobLevelSelectItemSource } from '../../services/employeejoblevel/employeeJobLevelProvider';
import { getLocationSelectItemSource } from '../../services/location/locationProvider';
import { getEmployeeJobSelectItemSource } from '../../services/employeejob/employeeJobProvider';

function InnerForm(props) {
  const {
    t,
    cancel,
    createEmployee,
    getUsers,
    getOrgUnits,
    getEmployeeJobs,
    getEmployeeJobLevels,
    getLocations,
  } = props;

  const schema = Yup.lazy(() =>
    Yup.object().shape({
      user: Yup.object().nullable().required(t('message.user_required')),
      orgUnit: Yup.object().nullable().required(t('message.organization_required')),
      hireDate: Yup.string().required(t('message.hire_date_required')),
    }),
  );

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const [defaultUsers, setDefaultUsers] = useState([]);
  const [defaultOrgUnits, setDefaultOrgUnits] = useState([]);
  const [defaultJobs, setDefaultJobs] = useState([]);
  const [defaultJobLevels, setDefaultJobLevels] = useState([]);
  const [defaultLocations, setDefaultLocations] = useState([]);

  const promises = [
    getUserSelectItemSource({ amount: 100, skip: 0 }),
    getOrgUnitSelectItemSource({ amount: 100, skip: 0 }),
    getEmployeeJobSelectItemSource({ amount: 100, skip: 0 }),
    getEmployeeJobLevelSelectItemSource({ amount: 100, skip: 0 }),
    getLocationSelectItemSource({ amount: 100, skip: 0 }),
  ];
  useEffect(() => {
    Promise.all(promises)
      .then(([defaultUsers, defaultOrgUnits, defaultJobs, defaultJobLevels, defaultLocations]) => {
        setDefaultUsers(defaultUsers);
        setDefaultOrgUnits(defaultOrgUnits);
        setDefaultJobs(defaultJobs);
        setDefaultJobLevels(defaultJobLevels);
        setDefaultLocations(defaultLocations);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  const loadUserOptions = (query, callback) => {
    getUsers(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const loadOrganizationOptions = (query, callback) => {
    getOrgUnits(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const loadEmployeeJobOptions = (query, callback) => {
    getEmployeeJobs(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const loadEmployeeJobLevelOptions = (query, callback) => {
    getEmployeeJobLevels(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const loadLocationOptions = (query, callback) => {
    getLocations(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const { handleSubmit } = form;

  const onSubmit = (values) => {
    values.userId = values.user.value;
    values.orgStructureUnitId = values.orgUnit.value;
    values.hireDate = new Date(moment.utc(values.hireDate));
    if (values.employeeJob) {
      values.employeeJobId = values.employeeJob.value;
    }
    if (values.employeeJobLevel) {
      values.employeeJobLevelId = values.employeeJobLevel.value;
    }
    if (values.location) {
      values.locationId = values.location.value;
    }

    delete values.user;
    delete values.orgUnit;
    delete values.employeeJob;
    delete values.employeeJobLevel;
    delete values.location;

    createEmployee(values);
  };

  return (
    <div>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="modal-body">
          <Controller
            control={form.control}
            name="user"
            defaultValue=""
            render={({ onChange, onBlur, name, value }) => (
              <AsyncSelectComponent
                form={form}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={t('fields.user')}
                label={t('fields.user')}
                loadOptions={loadUserOptions}
                isMulti={false}
                isClearable
                defaultOptions={defaultUsers}
              />
            )}
          />
          <Controller
            control={form.control}
            name="orgUnit"
            defaultValue=""
            render={({ onChange, onBlur, name, value }) => (
              <AsyncSelectComponent
                form={form}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={t('fields.organization_unit')}
                label={t('fields.organization_unit')}
                loadOptions={loadOrganizationOptions}
                isMulti={false}
                isClearable
                defaultOptions={defaultOrgUnits}
              />
            )}
          />
          <Controller
            control={form.control}
            name="employeeJob"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <AsyncSelectComponent
                form={form}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={t('fields.job')}
                label={t('fields.job')}
                loadOptions={loadEmployeeJobOptions}
                isMulti={false}
                isClearable
                defaultOptions={defaultJobs}
                cacheOptions
              />
            )}
          />
          <Controller
            control={form.control}
            name="employeeJobLevel"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <AsyncSelectComponent
                form={form}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={t('fields.job_level')}
                label={t('fields.job_level')}
                loadOptions={loadEmployeeJobLevelOptions}
                isMulti={false}
                isClearable
                defaultOptions={defaultJobLevels}
                cacheOptions
              />
            )}
          />
          <Controller
            control={form.control}
            name="location"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <AsyncSelectComponent
                form={form}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={t('fields.location')}
                name="location"
                label={t('fields.location')}
                loadOptions={loadLocationOptions}
                isMulti={false}
                isClearable
                defaultOptions={defaultLocations}
                cacheOptions
              />
            )}
          />
          <Controller
            control={form.control}
            name="hireDate"
            defaultValue=""
            render={({ onChange, value, name }) => (
              <DatePicker
                form={form}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={t('fields.hire_date')}
                type="text"
                label={t('fields.hire_date')}
                placeholderText={t('fields.date_format_mm_dd_yyyy')}
                autoComplete="off"
              />
            )}
          />
        </div>

        <div className="modal-footer">
          <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
            {t('buttons.cancel')}
          </button>
          <button type="submit" className="btn fm-btn-primary">
            {t('buttons.create')}
          </button>
        </div>
      </form>
    </div>
  );
}

InnerForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  createEmployee: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getOrgUnits: PropTypes.func.isRequired,
  getEmployeeJobs: PropTypes.func.isRequired,
  getEmployeeJobLevels: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
