import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import { getNotEmployedUsers, getNotEmployedUsersLike } from '../../services/user/userProvider';
import AsyncSelectComponent from '../react-hook-form-inputs/AsyncSelect';
import Notifications from '../../utils/Notifications';
import DatePicker from '../react-hook-form-inputs/DatePicker';

function InnerForm(props) {
  const { t, cancel, addEmployeesToOrgUnit } = props;

  const schema = Yup.object().shape({
    users: Yup.array().of(Yup.object()).required(t('message.one_user_required')).nullable(),
    hireDate: Yup.date().required(t('message.hire_date_required')).nullable(),
  });

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      employees: null,
      hireDate: null,
    },
  });

  const [defaultUsers, setDefaultUsers] = useState([]);
  useEffect(() => {
    getNotEmployedUsers({ amount: 100, skip: 0 })
      .then((defaultUsers) => {
        setDefaultUsers(defaultUsers);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  const { handleSubmit } = form;
  const loadOptionsUser = (query, callback) => {
    if (!query) {
      return;
    }
    getNotEmployedUsersLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };
  const onSubmit = (values) => {
    addEmployeesToOrgUnit({
      users: values.users,
      hireDate: values.hireDate.getTime(),
    });
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <div className="modal-body">
        <Controller
          control={form.control}
          name="users"
          defaultValue=""
          render={({ onChange, onBlur, name, value }) => (
            <AsyncSelectComponent
              form={form}
              name={name}
              loadOptions={loadOptionsUser}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              type="text"
              isMulti
              defaultOptions={defaultUsers}
              placeholder={t('fields.users')}
              label={t('fields.users')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="hireDate"
          render={({ onChange, value, name }) => (
            <DatePicker
              form={form}
              name={name}
              value={value}
              onChange={onChange}
              placeholder={t('fields.hire_date')}
              type="text"
              label={t('fields.hire_date')}
              placeholderText={t('fields.date_format_mm_dd_yyyy')}
              autoComplete="off"
            />
          )}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
        <button type="submit" className="btn fm-btn-primary">
          {t('buttons.create')}
        </button>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  addEmployeesToOrgUnit: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
