import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { deleteTest, getTestList } from '../../../services/tests/testProvider';
import PreviewTest from '../../../components/tests/PreviewSurvey';
import Loader from '../../../components/loader/Loader';
import Notifications from '../../../utils/Notifications';
import { AUTH_ROUTES, CREATE } from '../../../constants/routes';
import PageNameContainer from '../../../components/page/PageNameContainer';
import FormTitle from '../../../components/form/FormTitle';
import { getTestAssignmentsCount } from '../../../services/testassignment/testAssignmentProvider';
import ContentLoader from '../../../components/loader/ContentLoader';
import NoDataForm from '../../../components/form/NoDataForm';

class AllTenantSurveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      testList: null,
      SweetAlertModal: withReactContent(Swal),
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.my_survey_templates'),
        ref: null,
        isActive: true,
      },
    ];
  };

  fetchTestList() {
    getTestList({
      amount: 9999,
      skip: 0,
    })
      .then((data) => {
        this.setState({
          testList: data,
        });
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }

  componentDidMount() {
    this.fetchTestList();
  }

  confirmDeleteItem = (itemId) => {
    const { t } = this.props;

    this.setState({
      isLoading: true,
    });
    deleteTest(itemId)
      .then(() => {
        this.fetchTestList();
        this.setState({
          isLoading: false,
        });
        Notifications.success(t('message.survey_deleted'));
        document.activeElement?.blur();
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  onDeleteItem = (itemId) => {
    const { t } = this.props;

    const { SweetAlertModal } = this.state;
    getTestAssignmentsCount(itemId)
      .then(
        (assignmentsCount) =>
          new Promise(() => {
            SweetAlertModal.fire({
              title: t('message.are_you_sure'),
              showConfirmButton: true,
              showCancelButton: true,
              text:
                assignmentsCount > 0
                  ? t('message.you_are_about_to_delete_survey_with_assignments', {
                      count: assignmentsCount,
                    })
                  : t('message.you_are_about_to_delete_survey_template'),
              confirmButtonColor: '#1ab394',
              confirmButtonText: t('message.yes_delete_it'),
              cancelButtonText: t('buttons.cancel'),
              showCloseButton: true,
              icon: 'warning',
            }).then((data) => {
              if (data.isConfirmed) {
                this.confirmDeleteItem(itemId);
              }
            });
          }),
      )
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  showCreateTest = () => {
    this.props.history.push(`${AUTH_ROUTES.TESTS}${CREATE}`);
  };

  render() {
    const { testList, isLoading } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.all_survey_templates')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.all_survey_templates', {
                length: testList ? testList.length : 0,
              })}
              buttons={[
                {
                  size: 'btn-xs',
                  icon: faPlus,
                  importance: 'fm-btn-primary',
                  onClick: this.showCreateTest,
                  name: t('buttons.create'),
                  securityModule: 'tests',
                  securityPermission: 'create',
                },
              ]}
            />
            <div className="ibox-content">
              {testList ? (
                testList.length > 0 ? (
                  testList.map((item, index) => (
                    <PreviewTest
                      key={`preview-test-${index}`}
                      test={item}
                      deleteItem={this.onDeleteItem}
                      linkPrefix={AUTH_ROUTES.TESTS}
                      sucurityModule="tests"
                    />
                  ))
                ) : (
                  <NoDataForm />
                )
              ) : (
                <ContentLoader visible />
              )}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

AllTenantSurveys.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withTranslation()(AllTenantSurveys);
