import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import { Form } from 'react-bootstrap';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import './styles/user.css';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import SelectComponent from '../inputs/Select';
import InputComponent from '../inputs/Input';
import MaterialCheckbox from '../inputs/MaterialCheckbox';

function InnerForm(props) {
  const { t, handleSubmit, values, languageOptions, setFieldValue } = props;
  const [isInit, setIsInit] = useState(false);
  const [userRef] = useState(React.createRef());

  if (!isInit) {
    props.addValidation(() => props.validateForm().then((result) => result));
    props.addSubmition(props.handleSubmit.bind(this));
    props.setUserRef(userRef);
    setIsInit(true);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        component={InputComponent}
        placeholder={t('fields.username')}
        type="text"
        name="username"
        label={t('fields.username')}
        disabled
      />
      <Field
        component={InputComponent}
        placeholder={t('fields.last_name')}
        type="text"
        name="lastName"
        label={t('fields.last_name')}
      />
      <Field
        component={InputComponent}
        placeholder={t('fields.first_name')}
        type="text"
        name="firstName"
        label={t('fields.first_name')}
      />
      <Field
        component={InputComponent}
        placeholder={t('fields.middle_name')}
        type="text"
        name="middleName"
        label={t('fields.middle_name')}
      />
      <Field
        component={InputComponent}
        placeholder={t('fields.email')}
        type="email"
        name="email"
        label={t('fields.email')}
      />
      <Field
        component={InputComponent}
        placeholder={t('fields.phone')}
        type="text"
        name="phone"
        label={t('fields.phone')}
      />
      <Field
        component={SelectComponent}
        placeholder={t('fields.language')}
        type="text"
        name="language"
        setFieldValue={setFieldValue}
        options={languageOptions}
        label={t('fields.language')}
      />
      <Field
        component={MaterialCheckbox}
        type="checkbox"
        name="verified"
        color="primary"
        checked={values.verified}
        icon={<CircleUnchecked />}
        checkedIcon={<CircleCheckedFilled />}
        setFieldValue={setFieldValue}
        label={t('fields.verified')}
      />
    </Form>
  );
}

InnerForm.propTypes = {
  user: PropTypes.object,
  updateUser: PropTypes.func.isRequired,
  setUserRef: PropTypes.func.isRequired,
  addValidation: PropTypes.func.isRequired,
  addSubmition: PropTypes.func.isRequired,
};

const EditUserForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    const { user, languageOptions } = values;
    let lang = null;
    if (languageOptions && user.language) {
      lang = languageOptions.find((element) => element.value === user.language);
    }
    return {
      firstName: user && user.firstName ? user.firstName : '',
      middleName: user && user.middleName ? user.middleName : '',
      lastName: user && user.lastName ? user.lastName : '',
      phone: user.phone || '',
      username: user && user.username ? user.username : '',
      email: user && user.email ? user.email : '',
      verified: user ? user.verified : false,
      language: lang,
    };
  },
  validationSchema: (props) => {
    const { t } = props;
    return Yup.object().shape({
      email: Yup.string().required(t('message.required_field')).email(t('message.required_field')),
      username: Yup.string().required(t('message.required_field')),
      firstName: Yup.string().required(t('message.required_field')),
      lastName: Yup.string().required(t('message.required_field')),
    });
  },
  handleSubmit: (values, props) => {
    props.props.updateUser(values);
  },
})(InnerForm);

export default withTranslation()(EditUserForm);
