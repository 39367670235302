import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getOrgUnitDef, updateOrgUnitDef } from '../../services/orgunitdef/orgUnitDefProvider';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import EditOrgUnitDefDetailsForm from '../../components/orgunitdef/EditOrgUnitDefForm';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';

class EditOrgUnitDefPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      orgUnitDef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_organization_unit_types'),
        ref: '/org/org-unit-def',
        isActive: false,
      },
      {
        name: t('breadcrumbs.organization_unit_type'),
        ref: null,
        isActive: true,
      },
    ];
  };

  orgUnitSetState = (data) => {
    this.setState({
      isLoading: false,
      orgUnitDef: {
        ...data,
      },
    });
  };

  componentDidMount() {
    const { params } = this.props.match;

    this.setState({ isLoading: true });
    getOrgUnitDef(params.organizationUnitDefId)
      .then((data) => {
        this.orgUnitSetState(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  updateOrgUnitDef = (data) => {
    const { t } = this.props;
    updateOrgUnitDef(data)
      .then(() => {
        this.orgUnitSetState(data);
        Notifications.success(t('message.organization_unit_definition_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, orgUnitDef } = this.state;
    const { t } = this.props;

    return (
      <>
        <div className="row wrapper">
          <div className="col-lg-6">
            <PageNameContainer
              name={t('containers.organization_unit_type')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="col-lg-6">
            <div className="ibox">
              <FormTitle
                name={t('components.organization_unit_type', {
                  name: orgUnitDef && orgUnitDef.name,
                })}
              />
              <div className="ibox-content clearfix">
                {orgUnitDef && (
                  <EditOrgUnitDefDetailsForm
                    orgUnitDef={orgUnitDef}
                    updateOrgUnitDef={this.updateOrgUnitDef}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </>
    );
  }
}

EditOrgUnitDefPage.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      organizationUnitDefId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  initialValues: state.orgUnitDef,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(EditOrgUnitDefPage)));
