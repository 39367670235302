import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PageNameContainer from '../../../components/page/PageNameContainer';

class AllPurchaseTests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      testList: [],
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_survey_sales'),
        ref: null,
        isActive: true,
      },
    ];
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.all_survey_sales')}
              breadcrumbs={this.getBreadcrumbs}
            />
          </div>
        </div>
      </div>
    );
  }
}

AllPurchaseTests.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AllPurchaseTests);
