import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AllFeedbackAssignmentPeriods from '../../containers/feedbackassignmentperiod/AllFeedbackAssignmentPeriods';
import EditFeedbackAssignmentPeriodPage from '../../containers/feedbackassignmentperiod/EditFeedbackAssignmentPeriodPage';
import { ACTION, AUTH_ROUTES, FEEDBACK_ASSIGNMENT_PERIOD_ID } from '../../constants/routes';

class FeedbackAssignmentPeriodRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllFeedbackAssignmentPeriods} />
        <Route
          path={`${match.url}${FEEDBACK_ASSIGNMENT_PERIOD_ID}${ACTION}`}
          render={({ match }) => <FeedbackAssignmentPeriodInnerRouting match={match} />}
        />
      </div>
    );
  }
}

FeedbackAssignmentPeriodRoutes.propTypes = {
  match: PropTypes.object,
};

export default FeedbackAssignmentPeriodRoutes;

class FeedbackAssignmentPeriodInnerRouting extends Component {
  render() {
    const feedbackAssignmentPeriodRouting = (
      <Route
        exact
        path={`${AUTH_ROUTES.FEEDBACK_ASSIGNMENT_PERIODS}${FEEDBACK_ASSIGNMENT_PERIOD_ID}`}
        component={AllFeedbackAssignmentPeriods}
      />
    );

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.FEEDBACK_ASSIGNMENT_PERIODS}${FEEDBACK_ASSIGNMENT_PERIOD_ID}/edit`}
          component={EditFeedbackAssignmentPeriodPage}
        />
        {feedbackAssignmentPeriodRouting}
      </div>
    );
  }
}

FeedbackAssignmentPeriodInnerRouting.propTypes = {
  match: PropTypes.object,
};
