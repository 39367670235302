import React from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUserPhoto } from '../services/user/userProvider';
import './styles/user-photo-util.css';
import UrlUtil from './UrlUtil';

const UserPhotoUtil = {
  getPhotoByUserId(userId, setPhoto) {
    getUserPhoto(userId)
      .then((data) => {
        if (data.photoLink) {
          const src = UrlUtil.buildImageSrcLink(data.photoLink);
          const image = src ? (
            <img alt="user" className="img-circle fm-avatar" src={src} />
          ) : (
            <div className="fm-user-empty-photo fm-user-photo-adj">
              <FontAwesomeIcon icon={faUser} />
            </div>
          );
          setPhoto(image);
        } else {
          setPhoto(
            <div className="fm-user-empty-photo fm-user-photo-adj">
              <FontAwesomeIcon icon={faUser} />
            </div>,
          );
        }
      })
      .catch(() => {
        setPhoto(
          <div className="fm-user-empty-photo fm-user-photo-adj">
            <FontAwesomeIcon icon={faUser} />
          </div>,
        );
      });
  },
  renderUserPhotoByLink: (link, handleAvatarClick) =>
    link ? (
      <img
        alt="user"
        className="img-circle fm-avatar"
        onClick={handleAvatarClick || null}
        src={UrlUtil.buildImageSrcLink(link)}
      />
    ) : (
      <div className="fm-user-empty-photo" onClick={handleAvatarClick || null}>
        <FontAwesomeIcon icon={faUser} className="fm-user-img" />
      </div>
    ),
};

export default UserPhotoUtil;
