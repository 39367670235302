import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import TextArea from '../react-hook-form-inputs/TextArea';
import Input from '../react-hook-form-inputs/Input';

function InnerForm(props) {
  const form = useForm({ mode: 'onSubmit' });
  const { t, createUserRole, cancel } = props;
  const { handleSubmit } = form;
  const onSubmit = (values) => {
    createUserRole(values);
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <div className="modal-body">
        <Controller
          control={form.control}
          name="name"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
              placeholder={t('fields.role_name')}
              form={form}
              rows={4}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />

        <Controller
          control={form.control}
          name="description"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <TextArea
              placeholder={t('fields.description')}
              form={form}
              rows={4}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
        <button
          type="button"
          onClick={handleSubmit((data) => onSubmit(data))}
          className="btn fm-btn-primary"
        >
          {t('buttons.create')}
        </button>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  createUserRole: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

export default withTranslation()(InnerForm);
