import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import CriteriaReviewerResult from './CriteriaReviewerResult';

function ReviewersEditList(props) {
  const { t, reviewers, criteria, updateReviewer, removeReviewer } = props;

  const hasActiveReviewers = (reviewers) => {
    if (!reviewers) {
      return false;
    }
    if (reviewers.length === 0) {
      return false;
    }
    let hasActiveReviewer = false;
    _.forEach(reviewers, (s) => {
      if (s.action !== 'delete') {
        hasActiveReviewer = true;
        return false;
      }
    });
    return hasActiveReviewer;
  };

  return (
    <>
      {hasActiveReviewers(reviewers) ? (
        reviewers.map((reviewer) => (
          <ReviewerRenderer
            key={reviewer.reviewerSelectItem.value}
            t={t}
            reviewer={reviewer}
            criteria={criteria}
            updateReviewer={updateReviewer}
            removeReviewer={removeReviewer}
          />
        ))
      ) : (
        <p className="text-muted text-center">Empty List</p>
      )}
    </>
  );
}

ReviewersEditList.propTypes = {
  reviewers: PropTypes.array.isRequired,
  criteria: PropTypes.array,
  updateReviewer: PropTypes.func.isRequired,
  removeReviewer: PropTypes.func.isRequired,
};

function ReviewerRenderer(props) {
  const { t, reviewer, criteria, updateReviewer, removeReviewer } = props;
  return (
    reviewer.action !== 'delete' && (
      <>
        <div className="fm-feedback-assignment-reviewer">
          <div className="-left">
            <div className="fm-reviewer-text-field">{reviewer.reviewerSelectItem.label}</div>
            {reviewer.assignmentStatus && (
              <div className="fm-cl-tag">
                <span className="fm-badge">{t(`list_of_value.${reviewer.assignmentStatus}`)}</span>
              </div>
            )}
          </div>
          <div className="-right">
            {reviewer.assignmentStatus !== 'New' && (
              <div className="fm-fa-wrapper">
                {reviewer.isHidden ? (
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    onClick={() => {
                      reviewer.isHidden = false;
                      updateReviewer(reviewer);
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    onClick={() => {
                      reviewer.isHidden = true;
                      updateReviewer(reviewer);
                    }}
                  />
                )}
              </div>
            )}
            {reviewer.selfReview ? null : (
              <div className="fm-fa-wrapper">
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  onClick={() => {
                    removeReviewer(reviewer);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {!reviewer.isHidden ? (
          <CriteriaReviewerResult criteria={criteria} reviewerId={reviewer.id} />
        ) : null}
      </>
    )
  );
}

ReviewerRenderer.propTypes = {
  reviewer: PropTypes.object.isRequired,
  criteria: PropTypes.array,
  updateReviewer: PropTypes.func.isRequired,
  removeReviewer: PropTypes.func.isRequired,
};

export default withTranslation()(ReviewersEditList);
