import React, { Component } from 'react';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import './styles/dashboard-overview.css';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class PositiveNegativeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      positiveProc: 0,
      negativeProc: 0,
      neutralProc: 0,
    };
  }

  componentDidMount() {
    const { nnp } = this.props;
    const { positive, negative, neutral } = nnp;
    if (!(positive == null || negative == null || neutral == null)) {
      const total = positive + negative + neutral;
      const positiveProc = total === 0 ? 0 : ((positive / total) * 100).toFixed(0);
      const negativeProc = total === 0 ? 0 : ((negative / total) * 100).toFixed(0);
      const neutralProc = total === 0 ? 0 : 100 - positiveProc - negativeProc;
      const allZero = positiveProc === 0 && negativeProc === 0 && neutralProc === 0;
      this.setState({
        positiveProc,
        negativeProc,
        neutralProc,
        allZero,
      });
      setTimeout(() => {
        if (document.getElementById('fm-positive-line')) {
          document.getElementById('fm-positive-line').style.width = `${positiveProc}%`;
          document.getElementById('fm-negative-line').style.width = `${negativeProc}%`;
        }
      }, 10);
    }
  }

  render() {
    const { positiveProc, negativeProc, neutralProc, allZero } = this.state;
    const { t } = this.props;
    return (
      <div className="fm-positive-negative-container">
        <div className="fm-pnn-line-box">
          <div className="fm-pnn-line">
            <div id="fm-positive-line" className="fm-positive-line" style={{ width: `${0}%` }} />
            <div id="fm-negative-line" className="fm-negative-line" style={{ width: `${0}%` }} />
          </div>
        </div>
        <div className="fm-pnn-legend">
          <div className="fm-legend-box">
            <span className="fm-legend-dot fm-pnn-negative" />
            <span className="fm-pnn-label">{t('widgets.negative')}</span>
            <span className="fm-pnn-value">{allZero ? 'N/A' : `${negativeProc}%`}</span>
          </div>
          <div className="fm-legend-box">
            <span className="fm-legend-dot fm-pnn-neutral" />
            <span className="fm-pnn-label">{t('widgets.neutral')}</span>
            <span className="fm-pnn-value">{allZero ? 'N/A' : `${neutralProc}%`}</span>
          </div>
          <div className="fm-legend-box">
            <span className="fm-legend-dot fm-pnn-positive" />
            <span className="fm-pnn-label">{t('widgets.positive')}</span>
            <span className="fm-pnn-value">{allZero ? 'N/A' : `${positiveProc}%`}</span>
          </div>
        </div>
      </div>
    );
  }
}

PositiveNegativeComponent.propTypes = {
  nnp: PropTypes.object.isRequired,
};
export default withTranslation()(PositiveNegativeComponent);
