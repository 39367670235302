import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

class MembersEditList extends Component {
  hasActiveMembers = (members) => {
    if (!members) {
      return false;
    }
    if (members.length === 0) {
      return false;
    }
    let hasActiveMember = false;
    _.forEach(members, (s) => {
      if (s.action !== 'delete') {
        hasActiveMember = true;
      }
    });
    return hasActiveMember;
  };

  render() {
    const { members, removeMember } = this.props;

    return (
      <div>
        {this.hasActiveMembers(members) ? (
          members.map((member) => (
            <MemberRenderer key={member.userId} member={member} removeMember={removeMember} />
          ))
        ) : (
          <p className="text-muted text-center">Empty List</p>
        )}
      </div>
    );
  }
}

MembersEditList.propTypes = {
  members: PropTypes.array.isRequired,
  removeMember: PropTypes.func.isRequired,
};

export default MembersEditList;

class MemberRenderer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { member, removeMember } = this.props;
    return (
      member.action !== 'delete' && (
        <div className="row fm-feedback-assignment-reviewer">
          <div className="col-md-10">
            <div className="fm-reviewer-text-field">{member.displayString}</div>
          </div>
          <div className="col-md-2">
            <div className="row">
              <div className="col-md-6">
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  onClick={() => {
                    removeMember(member);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

MemberRenderer.propTypes = {
  member: PropTypes.object.isRequired,
  criteria: PropTypes.array,
  removeMember: PropTypes.func.isRequired,
};
