import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

class ServerError extends PureComponent {
  render() {
    const { t } = this.props;
    return (
      <div className="middle-box text-center animated fadeInDown">
        <h1>500</h1>
        <h3 className="font-bold">{t('components.internal_server_error')}</h3>
        <div className="error-desc">{t('message.internal_server_error')}</div>
      </div>
    );
  }
}

export default withTranslation()(ServerError);
