export const ROOT = '/';
export const LOGIN = '/login';
export const RESET_PASSWORD_REQUESTED = '/reset-password-requested';
export const RESET_PASSWORD_LINK_EXPIRED = '/reset-password-link-expired';
export const LOGIN_TENANT = '/login/:tenant?';
export const RESET_PASSWORD_WITH_TOKEN = '/reset-password/:resetPasswordToken?';
export const RESET_PASSWORD_DONE = '/reset-password-done';
export const REGISTRATION = 'registration';
export const REGISTRATION_TENANT = 'registration/:tenant?';
export const VERIFICATION = '/account-confirm/:verificationToken';
export const NOT_VERIFIED = '/not-verified/:accountName';

/* Common routes */
export const AUTH_ROUTES = {
  DASHBOARD: '/dashboard',
  HOME: '/home',
  TESTS: '/tests',
  FEEDBACKS: '/feedback',
  GLOBAL_TESTS: '/global-tests',
  TENANT_MANAGEMENT: '/tenant-management/tenants',
  TEST_ASSIGNMENTS: '/test-assignments',
  ASSIGNMENT_PERIODS: '/assignment-periods',
  FEEDBACK_ASSIGNMENT_PERIODS: '/feedback-assignment-periods',
  PROFILE: '/profile',
  USER_TEST_ASSIGNMENTS: '/test-assignments/user',
  USERS: '/users',
  USER_GROUPS: '/user-groups',
  USER_ROLES: '/user-roles',
  SURVEY_ANALYTICS: {
    MAIN: '/survey-analytics',
    DASHBOARD: '/dashboard',
    HEATMAP: '/heatmap',
    IMPACT: '/impact',
  },

  GLOBAL_TEST: {
    GLOBAL_QUESTION_CLASSIFICATION: '/global-test-settings/question-classifications',
  },
  TEST: {
    QUESTION_CLASSIFICATION: '/test-settings/question-classifications',
  },
  FEEDBACK: {
    CATEGORIES: '/feedback-settings/categories',
    ASSIGNMENTS: '/feedback-assignments',
    ASSIGNMENT_REQUEST: '/feedback-assignments/requests',
    MANAGER_SUBORDINATE_ASSIGNMENT_REQUESTS: '/feedback-assignments/manager-subordinate-requests',
    ALL_EMPLOYEES_ASSIGNMENT_REQUESTS: '/feedback-assignments/all-employees-requests',
    MANAGER_SUMMARY: '/feedback-assignments/manager-summary',
    USER_PROFILE: '/feedback-assignments/user-profile',
  },
  ORG: {
    ORG_UNIT: '/org/org-unit',
    ORG_UNIT_DEF: '/org/org-unit-def',
    EMPLOYEES: '/org/employee',
    EMPLOYEE_JOBS: '/org/employee-jobs',
    EMPLOYEE_JOB_LEVELS: '/org/employee-job-levels',
    LOCATIONS: '/org/locations',
  },
  CASE_MANAGEMENT: {
    BUG_TRACKING: '/case-management/bug-tracking',
  },
};
export const SERVISE_ROUTES = {
  403: '/403',
  404: '/404',
  500: '/500',
};
// export const GUEST_ROUTES = `/:point(${ REGISTRATION }|${ LOGIN }|accountConfirm|not-verified)`;
export const GUEST_ROUTES = `/:point(${LOGIN}|account-confirm|not-verified|reset-password|reset-password-requested|reset-password-done|reset-password-link-expired)`;
export const CREATE = '/create';
export const ALL = '/all';
export const USER = '/user';

export const USER_ID = '/:userId';
export const USER_GROUP_ID = '/:userGroupId';
export const USER_ROLE_ID = '/:userRoleId';
export const ACTION = '/:action';

/* Organization Unit routes. */
export const ORG_UNIT_ID = '/:organizationUnitId';
export const ORG_UNIT_DEF_ID = '/:organizationUnitDefId';

/* Global test routes */
export const GLOBAL_TEST_ID = '/:globalTestId';
export const CREATE_GLOBAL_TEST = '/:globalTestId(create)';
export const GLOBAL_QUESTION_CLASSIFICATION_ID = '/:globalQuestionClassificationId';

/* Employee routes. */
export const EMPLOYEE_ID = '/:employeeId';

/* Assignment period routes. */
export const ASSIGNMENT_PERIOD_ID = '/:assignmentPeriodId';

/* Tenant routes. */
export const TENANT_ID = '/:tenantId';

/* Feedback Assignment period routes. */
export const FEEDBACK_ASSIGNMENT_PERIOD_ID = '/:feedbackAssignmentPeriodId';

/* Feedback routes. */
export const FEEDBACK_CATEGORY_ID = '/:feedbackCategoryId';
export const FEEDBACK_ID = '/:feedbackId';
/* Test routes */
export const TEST_ID = '/:testId';
export const AVAILABLE = '/available';
export const QUESTION_CLASSIFICATION_ID = '/:questionClassificationId';

/* Test assignment routes. */
export const TEST_ASSIGNMENT_ID = '/:testAssignmentId';

/* Feedback assignmnent routes. */
export const FEEDBACK_ASSIGNMENT_ID = '/:feedbackAssignmentId';
export const FEEDBACK_ASSIGNMENT_REVIEWER_ID = '/:feedbackAssignmentReviewerId';

export const EMPLOYEE_JOB_ID = '/:employeeJobId';
export const EMPLOYEE_JOB_LEVEL_ID = '/:employeeJobLevelId';
export const LOCATION_ID = '/:locationId';

/* Case Management routes. */
export const CASE_ID = '/:caseId';
