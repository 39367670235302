import dataProvider from '../dataProvider';

import { location } from '../../constants/endpoints';

export const getLocationList = (params) =>
  dataProvider({
    url: location.all(),
    params,
    method: 'GET',
  });

export const getLocation = (locationId) =>
  dataProvider({
    url: location.getById(locationId),
    method: 'GET',
  });

export const createLocation = (params) =>
  dataProvider({
    url: location.create(),
    data: params,
    method: 'POST',
  });

export const updateLocation = (params) =>
  dataProvider({
    url: location.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteLocation = (locationId) =>
  dataProvider({
    url: location.delete(locationId),
    method: 'DELETE',
  });

export const getLocationPagination = (params) =>
  dataProvider({
    url: location.pagination(),
    data: params,
    method: 'POST',
  });

export const getLocationByNameLike = (name) =>
  dataProvider({
    url: location.getByNameLike(name),
    method: 'GET',
  });

export const getActivityStatus = () =>
  dataProvider({
    url: location.getActivityStatus(),
    method: 'GET',
  });

export const getLocationSelectItemSource = (params) =>
  dataProvider({
    url: location.itemSource(),
    data: params,
    method: 'POST',
  });
