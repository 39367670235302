import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import Input from '../react-hook-form-inputs/Input';
import MaterialCheckbox from '../react-hook-form-inputs/MaterialCheckbox';

function InnerForm(props) {
  const { t, cancel, createTenant } = props;

  const schema = Yup.object().shape({
    name: Yup.string().required(t('message.organization_name_required')),
    shortName: Yup.string().required(t('message.organization_short_name_required')),
  });

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = form;

  const onSubmit = (values) => {
    createTenant(values);
  };

  return (
    <div>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="modal-body">
          <Controller
            control={form.control}
            name="name"
            defaultValue=""
            render={({ onChange, onBlur, name, value }) => (
              <Input
                form={form}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type="text"
                placeholder={t('fields.tenant_name')}
                label={t('fields.tenant_name')}
              />
            )}
          />
          <Controller
            control={form.control}
            name="shortName"
            defaultValue=""
            render={({ onChange, onBlur, name, value }) => (
              <Input
                form={form}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type="text"
                placeholder={t('fields.tenant_short_name')}
                label={t('fields.tenant_short_name')}
              />
            )}
          />
          <Controller
            id="createAdmin"
            control={form.control}
            name="createAdmin"
            defaultValue
            render={({ onChange, onBlur, value, name }) => (
              <MaterialCheckbox
                name={name}
                checked={value}
                label={t('fields.create_admin')}
                form={form}
                checkedElementColor="#34B77C"
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.checked)}
                value={value || false}
              />
            )}
          />
        </div>
        <div className="modal-footer">
          <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
            {t('buttons.cancel')}
          </button>
          <button type="submit" className="btn fm-btn-primary">
            {t('buttons.create')}
          </button>
        </div>
      </form>
    </div>
  );
}

InnerForm.propTypes = {
  t: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  createTenant: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
