import dataProvider from '../dataProvider';
import { tenant } from '../../constants/endpoints';

export const isTenantExist = (params) =>
  dataProvider({
    url: tenant.isExist(),
    params,
    method: 'GET',
  });

export const updateTenant = (params) =>
  dataProvider({
    url: tenant.update(params.id),
    data: params,
    method: 'PUT',
  });

export const getTenant = (tenantId) =>
  dataProvider({
    url: tenant.getById(tenantId),
    method: 'GET',
  });

export const getTenantListPagination = (params) =>
  dataProvider({
    url: tenant.pagination(),
    data: params,
    method: 'POST',
  });

export const createTenant = (values) =>
  dataProvider({
    url: tenant.create(),
    method: 'POST',
    data: values,
  });

export const deleteTenant = (itemId) =>
  dataProvider({
    url: tenant.delete(itemId),
    method: 'DELETE',
  });
export const initialTenantSetup = (values) =>
  dataProvider({
    url: tenant.initialTenantSetup(),
    method: 'POST',
    data: values,
  });
