import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classnames from 'classnames';
import _ from 'lodash';

import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import './styles/select.css';
import { withTranslation } from 'react-i18next';

function SelectComponent(props) {
  let {
    t,
    label,
    name,
    form: { touched, errors },
    options,
    classNames,
    labelKeyName,
    idKeyName,
    onChange,
    isClearable,
    isSearchable,
    defaultValue,
  } = props;

  options = _.map(options, (option) => ({
    value: option[idKeyName],
    label: option[labelKeyName],
  }));

  const error = _.get(errors, name);
  return (
    <FormGroup className={`form-element ${classNames || ''}`}>
      {label && <ControlLabel htmlFor={name}>{label}</ControlLabel>}
      <Select
        {...props}
        name={name}
        id={name}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        options={options}
        simpleValue
        noOptionsMessage={() => t('fields.no_options')}
        isClearable={isClearable || false}
        isSearchable={isSearchable || true}
        defaultValue={defaultValue}
        onChange={(values) => {
          onChange && onChange(values);
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#34b77cb5',
            primary25: '#34b77c29',
            primary50: '#34b77c73',
          },
        })}
        styles={{
          control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
            borderColor:
              error && error.message ? '#ff0000' : state.isFocused ? '#1ab394' : '#e5e6e7',
            '&:hover': {
              borderColor:
                error && error.message ? '#ff0000' : state.isFocused ? '#1ab394' : '#e5e6e7',
            },
          }),
        }}
        className={classnames(classNames, 'select-fld', { 'has-error': error && error.message })}
      />
      {error && error.message && (
        <HelpBlock className="error-text animated fadeIn fm-error-req">{error.message}</HelpBlock>
      )}
    </FormGroup>
  );
}

SelectComponent.propTypes = {
  form: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classNames: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func,
  labelKeyName: PropTypes.string,
  idKeyName: PropTypes.string,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  defaultValue: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
};

SelectComponent.defaultProps = {
  labelKeyName: 'label',
  idKeyName: 'value',
};

export default withTranslation()(SelectComponent);
