import React, { useState } from 'react';
import PropTypes from 'prop-types';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import './styles/pass-feedback.css';
import { Controller } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import TextArea from '../../react-hook-form-inputs/TextArea';
import MaterialRadio from '../../react-hook-form-inputs/MaterialRadio';

function InnerForm(props) {
  const { t, hasError, readOnly, color, index, criteria, form } = props;
  const [commentRows, setCommentRows] = useState(1);

  const onFocusComment = () => {
    setCommentRows(4);
  };

  const onBlurComment = () => {
    setCommentRows(1);
  };

  const criteriaDescriptionError = form.errors[`fb-criteria-description-${criteria.id}`];

  return (
    <div
      className={`fm-feedback-criteria-answer-small ${
        criteriaDescriptionError ? 'fm-validation-error' : ''
      }`}
    >
      <div className="fm-cs-criteria-name-box">
        <div className="fm-cs-criteria-id" style={{ backgroundColor: color }}>
          <span>{criteria.letter}</span>
        </div>
        <div className="fm-cs-criteria-name" style={hasError ? { color: 'red' } : {}}>
          {criteria.name}
        </div>
      </div>
      <div className="fm-feedback-descriptions-box" name={`fb-criteria-description-${index}`}>
        {criteria.feedbackCriteriaDescriptions &&
        criteria.feedbackCriteriaDescriptions.length > 0 ? (
          <Controller
            control={form.control}
            name={`fb-criteria-description-${criteria.id}`}
            defaultValue={null}
            rules={{ required: criteria.isMain }}
            render={({ onChange, onBlur, value }) =>
              criteria.feedbackCriteriaDescriptions.map((description, index) => (
                <div
                  className="fm-fb-criteria-answer-small"
                  key={`fm-fb-criteria-answer${description.id}`}
                >
                  <MaterialRadio
                    name={`fb-criteria-description-${criteria.id}`}
                    elementColor={description.color}
                    checked={value == description.id}
                    label={description.label}
                    disabled={readOnly}
                    form={{
                      touched: form.touched,
                      errors: form.errors,
                    }}
                    labelPlacement="end"
                    onFocus={onFocusComment}
                    onBlur={(e) => {
                      onBlur(e);
                    }}
                    onChange={onChange}
                    value={description.id}
                  />
                </div>
              ))
            }
          />
        ) : null}
      </div>
      <div className="fm-feedback-criteria-comment-field">
        <Controller
          control={form.control}
          name={`fb-criteria-comment-${criteria.id}`}
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <TextArea
              placeholder={t('components.add_comment')}
              id={`criteria-${index}-comment`}
              form={form}
              onFocus={onFocusComment}
              onBlur={(e) => {
                onBlur(e);
                onBlurComment();
              }}
              rows={commentRows}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
    </div>
  );
}

InnerForm.propTypes = {
  criteria: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

export default withTranslation()(InnerForm);
