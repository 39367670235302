import React from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';

import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import InputComponent from '../inputs/Input';
import { hasEmail, hasUsername } from '../../services/account/authProvider';
import Validation from '../../utils/validation';

function InnerForm(props) {
  const { handleSubmit, cancel, t } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <div className="modal-body">
        <Field
          component={InputComponent}
          placeholder={t('fields.email')}
          type="email"
          name="email"
          label={t('fields.email')}
        />

        <Field
          component={InputComponent}
          placeholder={t('fields.username')}
          type="text"
          name="username"
          label={t('fields.username')}
        />

        <Field
          component={InputComponent}
          placeholder={t('fields.last_name')}
          type="text"
          name="lastName"
          label={t('fields.last_name')}
        />

        <Field
          component={InputComponent}
          placeholder={t('fields.first_name')}
          type="text"
          name="firstName"
          label={t('fields.first_name')}
        />

        <Field
          component={InputComponent}
          placeholder={t('fields.middle_name')}
          type="text"
          name="middleName"
          label={t('fields.middle_name')}
        />

        <Field
          component={InputComponent}
          placeholder={t('fields.phone')}
          type="text"
          name="phone"
          label={t('fields.phone')}
        />

        <Field
          component={InputComponent}
          id="password"
          name="password"
          placeholder={t('fields.password')}
          label={t('fields.password')}
          type="password"
          autoComplete="password"
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
        <button type="submit" className="btn fm-btn-primary">
          {t('buttons.create')}
        </button>
      </div>
    </Form>
  );
}

InnerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

const CreateUserModal = withFormik({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    middleName: '',
    phone: '',
    email: '',
    username: '',
    password: '',
  }),
  validationSchema: (props) => {
    const { t } = props;
    return Yup.lazy((values) => {
      if (!props.tenant) {
        return null;
      }

      return Yup.object().shape({
        firstName: Yup.string().required(t('message.required_field')),
        lastName: Yup.string().required(t('message.required_field')),
        password: Yup.string()
          .required(t('message.no_password_provided'))
          .matches(Validation.makePasswordRegExp(8), t('message.weak_password')),
        username: Yup.string()
          .required(t('message.required_field'))
          .test('checkDuplicateUsername', t('message.username_already_in_use'), (value) => {
            if (!value) {
              return null;
            }
            return new Promise((resolve, reject) => {
              hasUsername({
                tenant: props.tenant,
                username: value,
              })
                .then((hasUsername) => {
                  resolve(!hasUsername);
                })
                .catch(() => {
                  resolve(false);
                });
            });
          }),
        email: Yup.string()
          .email(t('message.invalid_email'))
          .required(t('message.required_field'))
          .test('checkDuplicateEmail', t('message.email_already_in_use'), (value) => {
            if (!value) {
              return null;
            }
            return new Promise((resolve, reject) => {
              hasEmail({
                tenant: props.tenant,
                email: value,
              })
                .then((hasEmail) => {
                  resolve(!hasEmail);
                })
                .catch(() => {
                  resolve(false);
                });
            });
          }),
      });
    });
  },
  handleSubmit: (values, props) => {
    const user = {
      phone: values.phone,

      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      username: values.username,
      email: values.email,
      password: values.password,
    };

    props.props.createUser(user);
  },
})(InnerForm);

const mapStateToProps = (state) => ({
  tenant: state.account ? state.account.tenant : null,
});

const mapDispatchToProps = () => ({});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CreateUserModal));
