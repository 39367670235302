import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Chart } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import chroma from 'chroma-js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import FormTitle from '../form/FormTitle';
import {
  getAssignmentPeriodReviewees,
  getSummary,
  getSummaryByUserId,
} from '../../services/assignmentperiod/assignmentPeriodProvider';
import {
  getSummaryQuestionStatistic,
  getSummaryQuestionStatisticByUserId,
} from '../../services/analytics/analyticsProvider';
import { getTestAssignmentByAssignmentPeriodIdAndUserId } from '../../services/testassignment/testAssignmentProvider';
import './styles/assignment-period-summary.css';
import Notifications from '../../utils/Notifications';
import AssignmentPeriodQuestionBoxMultiple from './AssignmentPeriodQuestionBoxMultiple';
import AssignmentPeriodQuestionBoxSingle from './AssignmentPeriodQuestionBoxSingle';
import AssignmentPeriodQuestionBoxPulse from './AssignmentPeriodQuestionBoxPulse';
import ContentLoader from '../loader/ContentLoader';
import Select from '../react-hook-form-inputs/Select';
import NoDataForm from '../form/NoDataForm';
import SurveyAssignmentResult from '../tests/passing/SurveyAssignmentResult';
import SurveyAssignmentRecommendations from './SurveyAssignmentRecommendations';

class AssignmentPeriodSummaryForm extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      summary: null,
      chartData: [],
      recommendations: [],
      animationComplete: false,
      questions: null,
      defaultRevieweeOption: { value: 'all', label: t('components.all') },
      options: {
        scales: {
          y: {
            grid: {
              display: false,
            },
            ticks: {
              font: {
                family: 'FiraSans',
                size: 16,
              },
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'nearest',
            bodyColor: '#575757',
            backgroundColor: '#fff',
            borderWidth: 1,
            titleColor: '#575757',
            titleFond: 'bold',
            xAlign: 'center',
            padding: 5,
          },
          datalabels: {
            formatter: () => null,
          },
          legend: {
            display: false,
          },
        },
        indexAxis: 'y',
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
      },
      summaryLoading: true,
      questionLoading: false,
      scaleDataAvailable: true,
      questionDataAvailable: true,
    };
  }

  getBarColor = (value) => {
    const min = 1;
    const max = 5;
    const colors = ['#C34501', '#F9960A', '#48BE89'];
    if (!value) {
      return '#F5F5F5';
    }
    const step = (max - min) / (colors.length - 1);
    return colors[Math.round((value - min) / step)];
  };

  componentDidMount() {
    Chart.register(ChartDataLabels);
    const { assignmentPeriod } = this.props;
    const { defaultRevieweeOption } = this.state;
    this.loadSummaryForAllReviewees();
    getAssignmentPeriodReviewees(assignmentPeriod.id)
      .then((data) => {
        this.setState({ reviewees: [defaultRevieweeOption, ...data.items] });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ questionLoading: false });
      });
  }

  componentWillUnmount() {
    Chart.unregister(ChartDataLabels);
  }

  resetData() {
    this.setState({
      summaryLoading: true,
      questionLoading: true,
      summary: null,
      chartData: null,
      questions: null,
      resultTestAssignmentId: null,
    });
  }

  populateSummary(summary, testAssignmentId) {
    const cd = summary.classificationStatistic;
    const isDataAvailable = cd.dataAvailable;
    if (cd) {
      _.forEach(cd.datasets, (ds, i) => {
        const color = ds.color ? ds.color : 'red';
        ds.hoverBackgroundColor = chroma(color).alpha(0.3).css();
        ds.backgroundColor = chroma(color).alpha(0.3).css();
        ds.borderColor = color;
        ds.data = ds.data ? ds.data.map((v) => (v ? v.toFixed(1) : v)) : [];
      });
    }
    const recommendations = summary.recommendations
      ? Object.keys(summary.recommendations).map((key) => ({
          key,
          value: summary.recommendations[key],
        }))
      : [];
    const hasValues = recommendations.filter((o) => o.value).length > 0;

    this.setState({
      resultTestAssignmentId: testAssignmentId,
      summary,
      recommendations: hasValues ? recommendations : [],
      chartData: cd,
      scaleDataAvailable: isDataAvailable,
      summaryLoading: false,
    });
  }

  loadSummaryForAllReviewees() {
    // TODO move to promise all
    const { assignmentPeriod } = this.props;
    this.resetData();
    getSummary(assignmentPeriod.id)
      .then((summary) => {
        this.populateSummary(summary, null);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ summaryLoading: false });
      });
    getSummaryQuestionStatistic(assignmentPeriod.id)
      .then((questions) => {
        this.setState({
          questions,
          questionLoading: false,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ questionLoading: false });
      });
  }

  loadSummaryByUserId(userId) {
    const { assignmentPeriod } = this.props;
    this.resetData();
    const promises = [];
    promises.push(getSummaryByUserId(assignmentPeriod.id, userId));
    promises.push(getTestAssignmentByAssignmentPeriodIdAndUserId(assignmentPeriod.id, userId));
    Promise.all(promises)
      .then(([summary, testAssignment]) => {
        this.populateSummary(summary, testAssignment.id);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ summaryLoading: false });
      });

    getSummaryQuestionStatisticByUserId(assignmentPeriod.id, userId)
      .then((questions) => {
        let questionDataAvailable = false;
        for (const q of questions) {
          if (q.dataAvailable) {
            questionDataAvailable = true;
            break;
          }
        }

        this.setState({
          questions,
          questionDataAvailable,
          questionLoading: false,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ questionLoading: false });
      });
  }

  renderQuestion = (question, index, isUserResult) => {
    const qType = question.questionType;
    if (!qType) {
      return;
    }
    switch (qType) {
      case 'SingleChoice':
        return (
          <AssignmentPeriodQuestionBoxSingle
            question={question}
            index={index}
            isUserResult={isUserResult}
          />
        );
      case 'MultipleChoice':
        return (
          <AssignmentPeriodQuestionBoxMultiple
            question={question}
            index={index}
            isUserResult={isUserResult}
          />
        );
      case 'Pulse':
        return (
          <AssignmentPeriodQuestionBoxPulse
            question={question}
            index={index}
            isUserResult={isUserResult}
          />
        );
    }
  };

  onChangeRevieweeSelect = (data) => {
    this.setState({
      defaultRevieweeOption: data,
    });
    if (data.value === 'all') {
      this.loadSummaryForAllReviewees();
    } else if (data.value) {
      this.loadSummaryByUserId(data.value);
    }
  };

  render() {
    const { assignmentPeriod, t } = this.props;
    const {
      summary,
      chartData,
      recommendations,
      questions,
      options,
      summaryLoading,
      questionLoading,
      reviewees,
      defaultRevieweeOption,
      scaleDataAvailable,
      questionDataAvailable,
      resultTestAssignmentId,
    } = this.state;
    let marginStyleClass = 'fm-good';

    if (summary && _.isNumber(summary.marginOfError)) {
      if (summary.marginOfError > 10) {
        marginStyleClass = 'fm-alert';
      } else if (summary.marginOfError <= 10 && summary.marginOfError >= 5) {
        marginStyleClass = 'fm-warning';
      }
    } else {
      marginStyleClass = 'fm-warning';
    }

    const marginValue =
      summary && _.isNumber(summary.marginOfError)
        ? `${summary.marginOfError.toFixed(1) === '0.0' ? 0 : summary.marginOfError.toFixed(1)}%`
        : 'N/A';

    return (
      <div className="col-sm-12">
        <div className="ibox animated fadeIn">
          <FormTitle name={`${assignmentPeriod.test.title}`} />
          <div className="ibox-content clearfix fm-box-ap">
            <div className="fm-ap-description-box">
              <span>{assignmentPeriod.test.description}</span>
            </div>

            {summary && (
              <div className="fm-ap-stats-container">
                <div className="fm-ap-stats-box">
                  <div className="fm-ap-stat-name">{t('components.questions')}</div>
                  <div className="fm-ap-stat-value">{summary.numberOfQuestions}</div>
                </div>
                <div className="fm-ap-stats-box">
                  <div className="fm-ap-stat-name">{t('components.assignments')}</div>
                  <div className="fm-ap-stat-value">{summary.numberOfAssignments}</div>
                </div>
                <div className="fm-ap-stats-box">
                  <div className="fm-ap-stat-name">{t('components.responses')}</div>
                  <div className="fm-ap-stat-value">{summary.numberOfResponses}</div>
                </div>
                <div className="fm-ap-stats-box">
                  <div className="fm-ap-stat-name">{t('components.response_rate')}</div>
                  <div className={`fm-ap-stat-value ${summary.responseRate ? '' : 'fm-warning'}`}>
                    {summary.responseRate
                      ? `${parseFloat(summary.responseRate).toFixed(1)}%`
                      : 'N/A'}
                  </div>
                </div>
                {!!summary.numberOfAssignments && summary.numberOfAssignments > 100 && (
                  <div className="fm-ap-stats-box">
                    <div className="fm-ap-stat-name">{t('components.margin_error')}</div>
                    <div className={`fm-ap-stat-value ${marginStyleClass}`}>{marginValue}</div>
                  </div>
                )}
              </div>
            )}
            {summary && (
              <div className="fm-ap-classifications-container">
                <div className="fm-ap-classifications-header">
                  <span className="fm-ap-classifications-header-text">
                    {!resultTestAssignmentId && t('components.tag_statistic')}
                  </span>
                  <div className="fm-ap-classifications-settings">
                    {!questionLoading && !summaryLoading && reviewees && reviewees.length > 0 && (
                      <Select
                        name="reviewees"
                        onChange={this.onChangeRevieweeSelect}
                        label={t('components.employees')}
                        defaultValue={defaultRevieweeOption}
                        form={{ touched: {}, errors: {} }}
                        options={reviewees}
                      />
                    )}
                    <span>
                      <FontAwesomeIcon icon={faCog} />
                    </span>
                  </div>
                </div>
                <div className="fm-ap-classifications-graph">
                  {!resultTestAssignmentId &&
                    scaleDataAvailable &&
                    chartData &&
                    chartData.labels &&
                    chartData.labels.length > 0 && (
                      <>
                        <Bar
                          data={chartData}
                          height={16 * chartData.labels.length + 6}
                          options={options}
                          type="bar"
                        />
                        {recommendations && recommendations.length > 0 && (
                          <SurveyAssignmentRecommendations recommendations={recommendations} />
                        )}
                      </>
                    )}
                </div>
                {resultTestAssignmentId && (
                  <SurveyAssignmentResult
                    testAssignmentId={resultTestAssignmentId}
                    recommendations={recommendations}
                    type="HR"
                  />
                )}
                {questions && questionDataAvailable && (
                  <div className="fm-ap-questions-container">
                    {questions.map((question, index) => (
                      <div key={index}>
                        {this.renderQuestion(question, index, !!resultTestAssignmentId)}
                      </div>
                    ))}
                  </div>
                )}
                {!scaleDataAvailable && !questionDataAvailable && (
                  <NoDataForm label={t('components.no_data_available_yet')} />
                )}

                <ContentLoader visible={questionLoading} />
              </div>
            )}
            <ContentLoader visible={summaryLoading} />
          </div>
        </div>
      </div>
    );
  }
}

AssignmentPeriodSummaryForm.propTypes = {
  assignmentPeriod: PropTypes.object,
};

export default withTranslation()(AssignmentPeriodSummaryForm);
