import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { AUTH_ROUTES, USER_ROLE_ID } from '../../constants/routes';
import AllUserRoles from '../../containers/user/AllUserRoles';
import EditUserRolePage from '../../containers/user/EditUserRolePage';

class UserRoleRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllUserRoles} />
        <Route
          path={`${match.url}${USER_ROLE_ID}`}
          render={({ match }) => <UserRolesInnerRouting match={match} />}
        />
      </div>
    );
  }
}

UserRoleRoutes.propTypes = {
  match: PropTypes.object,
};

export default UserRoleRoutes;

class UserRolesInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let userRoleRouting;
    if (match.params.userRoleId) {
      switch (match.params.userRoleId) {
        default:
          userRoleRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.USER_ROLES}${USER_ROLE_ID}`}
              component={AllUserRoles}
            />
          );
      }
    }

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.USER_ROLES}${USER_ROLE_ID}/edit`}
          component={EditUserRolePage}
        />
        {userRoleRouting}
      </div>
    );
  }
}

UserRolesInnerRouting.propTypes = {
  match: PropTypes.object,
};
