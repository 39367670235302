import dataProvider from '../dataProvider';

import { orgunitdef } from '../../constants/endpoints';

export const getOrgUnitDefList = (params) =>
  dataProvider({
    url: orgunitdef.all(),
    params,
    method: 'GET',
  });

export const pagination = (params) =>
  dataProvider({
    url: orgunitdef.pagination(),
    params,
    method: 'GET',
  });

export const getOrgUnitDef = (orgunitId) =>
  dataProvider({
    url: orgunitdef.getById(orgunitId),
    method: 'GET',
  });

export const getOrgUnitDefByNameLike = (query) =>
  dataProvider({
    url: orgunitdef.getByNameLike(query),
    method: 'GET',
  });

export const createOrgUnitDef = (params) =>
  dataProvider({
    url: orgunitdef.create(),
    data: params,
    method: 'POST',
  });

export const updateOrgUnitDef = (params) =>
  dataProvider({
    url: orgunitdef.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteOrgUnitDef = (orgunitId) =>
  dataProvider({
    url: orgunitdef.delete(orgunitId),
    method: 'DELETE',
  });

export const getOrgUnitDefSelectItemSource = (params) =>
  dataProvider({
    url: orgunitdef.itemSource(),
    method: 'POST',
    data: params,
  });

export const getOrgUnitDefListPagination = (params) =>
  dataProvider({
    url: orgunitdef.pagination(),
    data: params,
    method: 'POST',
  });
