import React from 'react';
import SingleChoice from './answers/SingleChoice';
import MultipleChoice from './answers/MultipleChoice';
import Pulse from './answers/Pulse';

function InnerForm(props) {
  const {
    testType,
    questionType,
    index,
    form,
    isHidden,
    calcSortOrder,
    answersArray,
    changeQuestion,
  } = props;
  let element = null;

  if (questionType) {
    switch (questionType) {
      case 'MultipleChoice':
        element = (
          <MultipleChoice
            form={form}
            answersArray={answersArray}
            isHidden={isHidden}
            testType={testType}
            qiu={`questions[${index}]`}
            calcSortOrder={calcSortOrder}
          />
        );
        break;
      case 'SingleChoice':
        element = (
          <SingleChoice
            form={form}
            answersArray={answersArray}
            isHidden={isHidden}
            testType={testType}
            qiu={`questions[${index}]`}
            calcSortOrder={calcSortOrder}
          />
        );
        break;
      case 'Pulse':
        element = (
          <Pulse
            form={form}
            questionIndex={index}
            changeQuestion={changeQuestion}
            isHidden={isHidden}
            qiu={`questions[${index}]`}
          />
        );
        break;
    }
  }

  return <div className="fm-question-answers-container">{element}</div>;
}

InnerForm.propTypes = {};

export default InnerForm;
