import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import Can from '../security/Can';
import Modal from '../modal/Modal';
import Notifications from '../../utils/Notifications';
import AddUserGroupMembersModal from './AddUserGroupMembersModal';
import { getUserByNameLike, getUserSelectItemSource } from '../../services/user/userProvider';
import MembersEditList from './MembersEditList';
import { batchUpdateUserGroupUsers } from '../../services/user/userGroupProvider';

class EditUserGroupUsersForm extends Component {
  constructor(props) {
    super(props);
    this.isMemebersMapMustBeUpdated = false;
    this.state = {
      isAddMembersModalShown: false,
      membersMap: new Map(),
      preloadedUserOptions: [],
    };
    this.state.membersMap.clear();
    this.props.groupMembers.forEach((member) => {
      this.state.membersMap.set(member.userId, member);
    });
  }

  componentDidMount() {
    this.props.addSubmition(this.updateUserGroup);
    this.preloadUserOptions();
  }

  openAddMembersFormModal = () => {
    this.setState({ isAddMembersModalShown: true });
  };

  closeAddMemberFormModal = () => {
    this.setState({ isAddMembersModalShown: false });
  };

  updateUserGroup = () => {
    const { membersMap } = this.state;
    const { t } = this.props;

    const membersToSubmit = [];
    if (membersMap.size > 0) {
      membersMap.forEach((member) => {
        if (member.action) {
          membersToSubmit.push({
            object: {
              id: member.id,
              userId: member.userId,
              userGroupId: this.props.userGroup.id,
              tenantId: this.props.userGroup.tenantId,
            },
            action: member.action,
            objectType: member.objectType,
          });
        }
      });
      if (membersToSubmit.length <= 0) {
        return;
      }
      batchUpdateUserGroupUsers(membersToSubmit)
        .then(() => {
          Notifications.success(t('message.user_group_members_updated'));
          this.isMemebersMapMustBeUpdated = true;
        })
        .catch((error) => {
          Notifications.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  preloadUserOptions = () => {
    const { membersMap } = this.state;

    getUserSelectItemSource({ amount: 100, skip: 0 })
      .then((foundOptions) => {
        if (membersMap.size > 0) {
          const filteredOptions = [];
          foundOptions.forEach((option) => {
            const key = parseInt(option.value);
            const existingMember = membersMap.get(key);
            if (!existingMember || existingMember.action === 'delete') {
              filteredOptions.push(option);
            }
          });
          this.setState({ preloadedUserOptions: filteredOptions });
        } else {
          this.setState({ preloadedUserOptions: foundOptions });
        }
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  loadUserOptions = (query, callback) => {
    const { membersMap } = this.state;
    getUserByNameLike(query)
      .then((foundOptions) => {
        if (membersMap.size > 0) {
          const filteredOptions = [];
          foundOptions.forEach((option) => {
            const key = parseInt(option.value);
            const existingMember = membersMap.get(key);
            if (!existingMember || existingMember.action === 'delete') {
              filteredOptions.push(option);
            }
          });
          callback && callback(filteredOptions);
        } else {
          callback && callback(foundOptions);
        }
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  addMembers = (newMembers) => {
    const { membersMap } = this.state;
    newMembers.forEach((newMember) => {
      const key = parseInt(newMember.value);
      if (membersMap.has(key)) {
        const existingMember = membersMap.get(key);
        if (existingMember.action === 'delete') {
          if (existingMember.id) {
            existingMember.action = null;
          } else {
            existingMember.action = 'create';
          }
          membersMap.set(key, existingMember);
        }
      } else {
        membersMap.set(key, {
          userId: key,
          displayString: newMember.label,
          action: 'create',
        });
      }
    });
    this.setState({ membersMap });
  };

  removeMember = (memberToRemove) => {
    const { membersMap } = this.state;
    const key = memberToRemove.userId;
    const existingMember = membersMap.get(key);
    if (existingMember) {
      if (existingMember.id) {
        existingMember.action = 'delete';
        membersMap.set(key, existingMember);
      } else {
        membersMap.delete(key);
      }
    }
    this.setState({ membersMap });
  };

  render() {
    const { membersMap, isAddMembersModalShown, preloadedUserOptions } = this.state;

    if (this.isMemebersMapMustBeUpdated) {
      this.props.groupMembers.forEach((member) => {
        this.state.membersMap.set(member.userId, member);
      });
      this.isMemebersMapMustBeUpdated = false;
    }

    const { t } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-sm-12">
            <MembersEditList
              members={Array.from(membersMap.values())}
              removeMember={this.removeMember}
            />
            <Can
              module="feedback-assignments"
              permission="edit"
              yes={() => (
                <button
                  type="button"
                  className="btn btn-secondary btn-sm pull-right"
                  onClick={this.openAddMembersFormModal}
                >
                  {t('buttons.add_group_members')}
                </button>
              )}
              no={() => null}
            />
          </div>
        </div>
        <Modal
          header={t('components.adding_group_members')}
          isOpened={isAddMembersModalShown}
          icon={faUser}
          onClose={this.closeAddMemberFormModal}
        >
          <AddUserGroupMembersModal
            loadUserOptions={this.loadUserOptions}
            defaultUserOptions={preloadedUserOptions}
            handleMembersSubmit={this.addMembers}
            cancel={this.closeAddMemberFormModal}
          />
        </Modal>
      </>
    );
  }
}

EditUserGroupUsersForm.propTypes = {
  groupMembers: PropTypes.array.isRequired,
  addSubmition: PropTypes.func.isRequired,
  userGroup: PropTypes.object.isRequired,
};

export default withTranslation()(EditUserGroupUsersForm);
