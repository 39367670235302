import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { AUTH_ROUTES, USER_ID } from '../../constants/routes';

import AllUsers from '../../containers/user/AllUsers';
import EditUserPage from '../../containers/user/EditUserPage';

class UserRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllUsers} />
        <Route
          path={`${match.url}${USER_ID}`}
          render={({ match }) => <UsersInnerRouting match={match} />}
        />
      </div>
    );
  }
}

UserRoutes.propTypes = {
  match: PropTypes.object,
};

export default UserRoutes;

class UsersInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let userRouting;
    if (match.params.userId) {
      switch (match.params.userId) {
        default:
          userRouting = (
            <Route exact path={`${AUTH_ROUTES.USERS}${USER_ID}`} component={AllUsers} />
          );
      }
    }

    return (
      <div>
        <Route exact path={`${AUTH_ROUTES.USERS}${USER_ID}/edit`} component={EditUserPage} />
        {userRouting}
      </div>
    );
  }
}

UsersInnerRouting.propTypes = {
  match: PropTypes.object,
};
