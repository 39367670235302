import React, { Component } from 'react';

import ImpactSettingsForm from './ImpactSettingsForm';
import './styles/impact.css';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import Notifications from '../../utils/Notifications';
import StringRenderer from '../tables/StringRenderer';
import DoubleRenderer from '../tables/DoubleRenderer';
import ImpactStrenthLevelRenderer from './ImpactStrengthRenderer';
import { withTranslation } from 'react-i18next';
import { calculateImpact } from '../../services/impact/impactProvider.js';
import ImpactDirectionRenderer from './ImpactDirectionRenderer';
import ImpactSignificanceRenderer from './ImpactSignificanceRenderer';
import NoDataForm from '../form/NoDataForm';
import ContentLoader from '../loader/ContentLoader';

class ImpactWidget extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    const significancePriority = [
      'VeryHighSignificanceLevel',
      'HighSignificanceLevel',
      'LowSignificanceLevel',
    ];

    this.state = {
      data: null,
      columns: [
        {
          Cell: ImpactSignificanceRenderer,
          accessor: 'significanceLevel',
          id: 'significanceLevel',
          sortable: false,
          sortMethod: (a, b) => significancePriority.indexOf(b) - significancePriority.indexOf(a),
          filterable: false,
          width: 50,
          show: true,
        },
        {
          Header: t('tables.factor#', { number: 1 }),
          Cell: StringRenderer,
          accessor: 'factorX',
          sortable: true,
          filterable: false,
        },
        {
          Header: t('tables.factor#', { number: 2 }),
          Cell: StringRenderer,
          accessor: 'factorY',
          sortable: true,
          filterable: false,
        },
        {
          Header: t('tables.correlation_coefficient'),
          id: 'correlationCoefficient',
          accessor: 'correlationCoefficient',
          sortable: true,
          filterable: false,
          Cell: DoubleRenderer,
          precision: 2,
          width: 200,
        },
        {
          Header: t('tables.direction'),
          id: 'direction',
          accessor: 'correlationCoefficient',
          sortable: true,
          filterable: false,
          Cell: ImpactDirectionRenderer,
          precision: 2,
          width: 130,
        },
        {
          Header: t('tables.strength_level'),
          Cell: ImpactStrenthLevelRenderer,
          accessor: 'strengthLevel',
          sortable: true,
          filterable: false,
          width: 130,
        },
      ],
      defaultSorted: [
        {
          id: 'factor',
          desc: true,
        },
      ],
      tableRef: null,
      classifications: [],
      factors: {},
      isLoading: false,
    };
  }

  onChangeTest = (k, v) => {
    const { factors } = this.state;
    factors[k] = v;
    if (factors.surveyX && factors.surveyY) {
      this.setState({ isLoading: true });
      calculateImpact(factors.surveyX, factors.surveyY)
        .then((data) => {
          this.setState({
            data,
            isLoading: false,
          });
        })
        .catch((error) => {
          Notifications.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  render() {
    const { isLoading, classifications, columns, defaultSorted, data } = this.state;
    const { setTableReference, tableReference } = this.props;

    return (
      <div className="row">
        <div className="col col-lg-3 col-md-12">
          <div className="fm-impact-settings-box">
            <ImpactSettingsForm
              classifications={classifications}
              onChangeTest={this.onChangeTest}
            />
          </div>
        </div>
        <div className="col col-lg-9 col-md-12">
          {data && (
            <ReactTable
              data={data}
              pageSize={data.length}
              minRows={1}
              maxRows={500}
              showPagination={false}
              columns={columns}
              className="-striped -highlight"
              ref={(elementRef) => {
                this.tableRef = elementRef;
                if (!tableReference && elementRef) {
                  setTableReference(elementRef);
                }
              }}
              sorted={[
                {
                  id: 'significanceLevel',
                  desc: true,
                },
              ]}
            />
          )}
          {!data && !isLoading && <NoDataForm label="" paddingBottom={50} paddingTop={50} />}
          {isLoading && <ContentLoader visible />}
        </div>
      </div>
    );
  }
}

ImpactWidget.propTypes = {
  setTableReference: PropTypes.func.isRequired,
  tableReference: PropTypes.object,
};

export default withTranslation()(ImpactWidget);
