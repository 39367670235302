import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import Input from '../react-hook-form-inputs/Input';
import Notifications from '../../utils/Notifications';

function InnerForm(props) {
  const { t, cancel, tenantInfo } = props;

  const form = useForm({
    mode: 'onBlur',
    defaultValues: {
      username: tenantInfo.username,
      adminPassword: tenantInfo.adminPassword,
    },
  });
  const usernameCopyToClipboard = () => {
    navigator.clipboard.writeText(tenantInfo.username).then(() => {
      Notifications.success(t('message.copied_to_clipboard'));
    });
  };

  const passwordCopyToClipboard = () => {
    navigator.clipboard.writeText(tenantInfo.adminPassword).then(() => {
      Notifications.success(t('message.copied_to_clipboard'));
    });
  };

  return (
    <form>
      <div className="modal-body">
        <Controller
          control={form.control}
          name="username"
          defaultValue=""
          render={({ onChange, onBlur, name, value }) => (
            <Input
              form={form}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              type="text"
              readOnly
              rightButton={{
                onClick: (e) => usernameCopyToClipboard(e),
                label: <FontAwesomeIcon icon={faCopy} />,
              }}
            />
          )}
        />
        <Controller
          control={form.control}
          name="adminPassword"
          defaultValue=""
          render={({ onChange, onBlur, name, value }) => (
            <Input
              form={form}
              name={name}
              value={value}
              onChange={onChange}
              classNames="single-field"
              onBlur={onBlur}
              type="text"
              readOnly
              rightButton={{
                onClick: (e) => passwordCopyToClipboard(e),
                label: <FontAwesomeIcon icon={faCopy} />,
              }}
            />
          )}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  t: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  tenantInfo: PropTypes.shape({
    username: PropTypes.string.isRequired,
    adminPassword: PropTypes.string.isRequired,
  }).isRequired,
};

export default withTranslation()(InnerForm);
