const AbstractEnpoint = (type) => ({
  all: () => `${type}`,
  pagination: () => `${type}/pagination`,
  getById: (itemId) => `${type}/${itemId}`,
  create: () => `${type}`,
  delete: (itemId) => `${type}/${itemId}`,
  update: (itemId) => `${type}/${itemId}`,
  patch: (itemId) => `${type}/${itemId}`,
  itemSource: () => `${type}/select-item-source`,
});
export const analytics = {
  heatmap: (strategy) => `heatmap/${strategy}`,
  fetchMyPoolHappinessLevel: () => `statistics/happiness-level`,
  heatmapAdditionalRow: (strategy) => `heatmap/heatmap-addition-row/${strategy}`,
  getHeatmapTypes: () => `heatmap/types`,
  getMonthAverageScore: (classification) =>
    `statistics/widgets/month-average-score/${classification}`,
  getNegativeNeutralPositive: (classification) => `statistics/widgets/nnp/${classification}`,
  getDashboardChartsInfo: (classification) => `statistics/widgets/info/${classification}`,
  summaryQuestion: (assignmentPeriodId) =>
    `/statistics/summary-question/assignment-period/${assignmentPeriodId}`,
  summaryQuestionByUserId: (assignmentPeriodId, userId) =>
    `/statistics/summary-question/assignment-period/${assignmentPeriodId}/user/${userId}`,
  classificationStatComparing: (testAssignmentId) =>
    `/statistics/classifications/test-assignment/${testAssignmentId}`,
  getInsightsData: () => '/statistics/insights',
};

export const user = {
  ...AbstractEnpoint('users'),
  verify: () => 'users/verify',
  registration: () => `users/registration`,
  getPhotoById: (userId) => `users/photo/${userId}`,
  updateUserPhoto: (userId) => `users/photo/${userId}`,
  getNotEmployedUsers: () => 'users/select-item-source-no-employment',
  getNotEmployedUsersLike: (query) => `users/not-employed/name-like/${encodeURIComponent(query)}`,
  getByName: (name) => `users/name/${name}`,
  getByNameLike: (query) => `users/name-like/${encodeURIComponent(query)}`,
  getLanguageSelectItems: () => `users/language-options`,
  changePassword: () => `users/change-password`,
  resetPasswordRequest: () => `users/reset-password-request`,
  validatePasswordResetToken: () => `users/validate-password-reset-token`,
  resetPassword: () => `users/reset-password`,
  changeLanguage: () => `users/change-language`,
};

export const userrole = {
  ...AbstractEnpoint('user-role'),
  deleteUserRole: (roleId) => `user-role/${roleId}`,
  deleteUserRoleUser: (roleId, userId) => `user-role/${roleId}/user/${userId}`,
  getByUserId: (userId) => `user-role/user/${userId}`,
  getUserRolesNotAppliedForUser: (userId, query) =>
    `user-role/not-applied-for-user/${userId}/${encodeURIComponent(query)}`,
  addUserRolesToUser: (userId) => `user-role/add-to-user/${userId}`,
  saveRoleWithPermissions: (roleId) => `user-role/permissions/${roleId}`,
};

export const userrolepermission = {
  ...AbstractEnpoint('user-role-permission'),
  getByUserRoleId: (userRoleId) => `user-role-permission/user-role/${userRoleId}`,
};

export const notificationItems = {
  ...AbstractEnpoint('notification-item'),
  getByUserId: (userId) => `notification-item/user/${userId}`,
};

export const account = {
  ...AbstractEnpoint('account'),
  checkEmail: () => `account/check-email`,
  checkUsername: () => `account/check-username`,
};

export const usergroupuser = {
  ...AbstractEnpoint('user-group-user'),
  batchUpdate: () => `user-group-user/batch`,
  getByUserGroupId: (groupId) => `user-group-user/group/${groupId}`,
};
export const usergroup = {
  ...AbstractEnpoint('user-group'),
  getByNameLike: (query) => `user-group/name-like/${encodeURIComponent(query)}`,
};

export const orgunit = {
  ...AbstractEnpoint('org-structure-unit'),
  paginationWithHeadcount: () => `org-structure-unit/pagination-with-headcount`,
  deleteEmployeeFromOrgUnit: (employeeId) => `org-structure-unit/employee/${employeeId}`,
  getOrgUnitEmployeesListPagination: (orgUnitId) => `org-structure-unit/${orgUnitId}/employees`,
  getByNameLike: (query) => `org-structure-unit/name-like/${encodeURIComponent(query)}`,
  getOrgUnitExcludeCircularDependencyLike: () =>
    `org-structure-unit/exclude-circular-dependency/like`,
  getOrgUnitExcludeCircularDependency: () => `org-structure-unit/exclude-circular-dependency`,
};

export const feedback = {
  ...AbstractEnpoint('feedback'),
  getByName: (name) => `feedback/name/${name}`,
  getByNameLike: (query) => `feedback/name-like/${encodeURIComponent(query)}`,
};

export const assignmentperiod = {
  ...AbstractEnpoint('assignment-period'),
  types: () => `assignment-period/types`,
  getExcludeDates: () => `assignment-period/exclude-dates`,
  getSummary: (assignmentPeriodId) => `assignment-period/summary/${assignmentPeriodId}`,
  getSummaryByUserId: (assignmentPeriodId, userId) =>
    `assignment-period/summary/${assignmentPeriodId}/user/${userId}`,
  getAssignmentPeriodReviewees: (assignmentId) => `assignment-period/reviewee/${assignmentId}`,
};

export const feedbackcategory = {
  ...AbstractEnpoint('feedbackcategory'),
  getByName: (name) => `feedbackcategory/name/${name}`,
  getByNameLike: (query) => `feedbackcategory/name-like/${encodeURIComponent(query)}`,
};

export const feedbackassignment = {
  ...AbstractEnpoint('feedback-assignment'),
  getLastFeedbackAssignmentByUserId: (userId) => `feedback-assignment/last/user/${userId}`,
  getLastFinishedFeedbackAssignmentByUserId: (userId) =>
    `feedback-assignment/last-finished/user/${userId}`,
  getFeedbackAssignmentManagerSummary: (feedbackAssignmentId) =>
    `feedback-assignment/manager-summary/${feedbackAssignmentId}`,
  getFeedbackAssignmentUserSummary: (feedbackAssignmentId) =>
    `feedback-assignment/user-summary/${feedbackAssignmentId}`,
  getFeedbackAssignmentsListByUserId: (userId) => `feedback-assignment/user/${userId}`,
  countEmployeeAssignments: () => `feedback-assignment/count-user-assignments`,
};

export const feedbackassignmentperiod = {
  ...AbstractEnpoint('feedback-assignment-period'),
  types: () => `feedback-assignment-period/types`,
  getExcludeDates: () => `feedback-assignment-period/exclude-dates`,
};
export const feedbackcriteriaanswer = {
  ...AbstractEnpoint('feedback-criteria-answer'),
  createBatch: () => `feedback-criteria-answer/batch`,
  getFeedbackCriteriaAnswersByReviewerId: (reviewerId) =>
    `feedback-criteria-answer/reviewer/${reviewerId}`,
  getAnswersByFeedbackAssignmentIdUserId: (feedbackAssingmnetId, userId) =>
    `feedback-criteria-answer/feedback-assignment/${feedbackAssingmnetId}/user/${userId}`,
};

export const feedbackassignmentreviewer = {
  ...AbstractEnpoint('feedback-assignment-reviewer'),
  rejectFeedbackAssignmentReviewer: (feedbackAssignmentId) =>
    `feedback-assignment-reviewer/reject/${feedbackAssignmentId}`,
  getByFeedbackAssignmentId: (feedbackAssignmentId) =>
    `feedback-assignment-reviewer/feedback-assignment/${feedbackAssignmentId}`,
  batchUpdate: () => `feedback-assignment-reviewer/batch`,
  paginationByReviewerId: (reviewerId) =>
    `feedback-assignment-reviewer/pagination/reviewer/${reviewerId}`,
  paginationFeedbackAssignmentProfilesByManagerId: (managerId) =>
    `feedback-assignment-reviewer/pagination/profiles/manager/${managerId}`,
  paginationAllEmployeesFeedbackAssignmentProfiles: () =>
    `feedback-assignment-reviewer/pagination/profiles/all`,
  getByFeedbackAssignmentIdUserId: (feedbackAssingmnetId, userId) =>
    `feedback-assignment-reviewer/assignment-feedback/${feedbackAssingmnetId}/reviewer/${userId}`,
  getActiveAssignmentReviewerIds: (feedbackAssignmentReviewerId) =>
    `feedback-assignment-reviewer/active/${feedbackAssignmentReviewerId}`,
};

export const feedbackcriteria = {
  ...AbstractEnpoint('feedbackcriteria'),
  createBatch: () => `feedbackcriteria/batch`,
  getByFeedbackId: (feedbackId) => `feedbackcriteria/feedback/${feedbackId}`,
  getIdsByFeedbackId: (feedbackId) => `feedbackcriteria/ids/feedback/${feedbackId}`,
};

export const orgunitdef = {
  ...AbstractEnpoint('orgstructuredef'),
  getByNameLike: (query) => `orgstructuredef/name-like/${encodeURIComponent(query)}`,
};

export const ongoingfeedback = {
  ...AbstractEnpoint('ongoing-feedback'),
  countAdvice: (userId) => `ongoing-feedback/advice-count/${userId}`,
  countPraise: (userId) => `ongoing-feedback/praise-count/${userId}`,
  getUserTips: (userId) => `ongoing-feedback/user-tips/${userId}`,
  getUserPraise: (userId) => `ongoing-feedback/user-praise/${userId}`,
};
export const ongoingfeedbackskill = {
  ...AbstractEnpoint('ongoing-feedback-skill'),
  getByNameLike: (query) => `ongoing-feedback-skill/name-like/${encodeURIComponent(query)}`,
};

export const demorequest = {
  ...AbstractEnpoint('demo-request'),
};

export const testassignment = {
  ...AbstractEnpoint('test-assignment'),
  user: () => 'test-assignment/user',
  paginationByUser: (userId) => `test-assignment/pagination/user/${userId}`,
  getTestAssignmentDetailsByUserId: (userId) => `test-assignment/info/user/${userId}`,
  getTestAssignmentDetails: (testId) => `test-assignment/details/${testId}`,
  countEmployeeAssignments: () => `test-assignment/count/user`,
  countTestAssignments: (testId) => `test-assignment/count/test/${testId}`,
  getUserResults: (testAssignmentId) => `test-assignment/user-results/${testAssignmentId}`,
  getTestAssignmentByAssignmentPeriodIdAndUserId: (testAssignmentPeriodId, userId) =>
    `test-assignment/period/${testAssignmentPeriodId}/user/${userId}`,
};

export const employee = {
  ...AbstractEnpoint('employee'),
  getMyTeamEmployeesPagination: (userId) => `employee/pagination/teammates/${userId}`,
  getMyTeamEmployeesManagerPagination: (userId) => `employee/pagination/subordinates/${userId}`,
  checkIsManager: (userId) => `employee/is-manager/${userId}`,
  getByUserId: (userId) => `employee/user/${userId}`,
  addEmployeesToOrgUnit: (orgUnitId) => `employee/add-to-org-unit/${orgUnitId}`,
  getNotAssignedToFeedbackByNameLike: (feedbackAssignmentId, query) =>
    `employee/not-assigned-to-feedback/name-like/${feedbackAssignmentId}/${encodeURIComponent(
      query,
    )}`,
  getNotAssignedToFeedbackEmployee: (feedbackAssignmentId) =>
    `employee/not-assigned-to-feedback/${feedbackAssignmentId}`,
  getFeedbackAssignmentReviewersAsEmployees: (feedbackAssignmentId) =>
    `employee/feedback-assignment/${feedbackAssignmentId}/reviewers`,
  importEmployee: () => `employee/import`,
  validateEmployeeImportFile: () => `employee/import/validate`,
  getEmployeeExpectedImportRows: () => `employee/import/expected-rows`,
};
export const test = {
  ...AbstractEnpoint('test'),
  types: () => `test/types`,
  getTestByNameLike: (query) => `test/test-like/${encodeURIComponent(query)}`,
  getTestIdByName: (name) => `test/id-by-name/${name}`,
};

export const testtemplate = {
  ...AbstractEnpoint('testtemplate'),
  types: () => `testtemplate/types`,
  copyToTenant: () => `testtemplate/clone-to-tenant`,
  importTestTemplate: () => `testtemplate/import`,
  validateTestTemplateImportFile: () => `testtemplate/import/validate`,
  getTestTemplateExpectedImportRows: () => `testtemplate/import/expected-rows`,
  exportTestTemplate: (testId) => `testtemplate/export/${testId}`,
};

export const question = {
  ...AbstractEnpoint('question'),
  createBatch: () => `question/batch`,
  types: () => `question/types`,
  getByTest: (testId) => `question/test/${testId}`,
  getIdsByTest: (testId) => `question/ids/test/${testId}`,
};

export const scale = {
  ...AbstractEnpoint('scale'),
  getByTestId: (testId) => `scale/test/${testId}`,
  createBatch: () => `scale/batch`,
  getByQuestionClassificationId: (questionClassificationId) =>
    `scale/questionclassification/${questionClassificationId}`,
};

export const scaletemplate = {
  ...AbstractEnpoint('scaletemplate'),
  getByTestId: (testId) => `scaletemplate/test/${testId}`,
  createBatch: () => `scaletemplate/batch`,
  getByQuestionClassificationId: (questionClassificationId) =>
    `scaletemplate/questionclassification/${questionClassificationId}`,
};

export const tenant = {
  ...AbstractEnpoint('tenant'),
  isExist: () => `tenant/check-name`,
  initialTenantSetup: () => `tenant/initial-setup`,
};
export const questionanswerresult = {
  ...AbstractEnpoint('questionanswerresult'),
  answerQuestion: () => `questionanswerresult/answerquestion`,
  batchUpdate: () => `questionanswerresult/batch`,
  getByAssignment: (assignmentId) => `questionanswerresult/assignment/${assignmentId}`,
  getByAnswer: (answerId) => `questionanswerresult/answer/${answerId}`,
  getByQuestion: (questionId) => `questionanswerresult/question/${questionId}`,
  getByAssignmentQuestion: (assignmentId, questionId) =>
    `questionanswerresult/aq/${assignmentId}/${questionId}`,
  getByAssignmentQuestionAnswer: (assignmentId, questionId, answerId) =>
    `questionanswerresult/aqa/${assignmentId}/${questionId}/${answerId}`,
};

export const questionclassification = {
  ...AbstractEnpoint('questionclassification'),
  getByTestId: (testId) => `questionclassification/test/${testId}`,
  getByQuestionId: (questionId) => `questionclassification/question/${questionId}`,
  getByName: (name) => `questionclassification/name/${name}`,
  getByNameLike: (query) => `questionclassification/name-like/${encodeURIComponent(query)}`,
};

export const questionclassificationtemplate = {
  ...AbstractEnpoint('questionclassificationtemplate'),
  getByTestId: (testId) => `questionclassificationtemplate/test/${testId}`,
  getByQuestionId: (questionId) => `questionclassificationtemplate/question/${questionId}`,
  getByName: (name) => `questionclassificationtemplate/name/${name}`,
  getByNameLike: (query) => `questionclassificationtemplate/name-like/${encodeURIComponent(query)}`,
};

export const questiontemplate = {
  ...AbstractEnpoint('questiontemplate'),
  createBatch: () => `questiontemplate/batch`,
  types: () => `questiontemplate/types`,
  getByTest: (testId) => `questiontemplate/test/${testId}`,
  getIdsByTest: (testId) => `questiontemplate/ids/test/${testId}`,
};
export const employeejob = {
  ...AbstractEnpoint('employee-job'),
  getByNameLike: (query) => `employee-job/name-like/${encodeURIComponent(query)}`,
  getActivityStatus: () => `location/activity-status`,
};
export const employeejoblevel = {
  ...AbstractEnpoint('employee-job-level'),
  getByNameLike: (query) => `employee-job-level/name-like/${encodeURIComponent(query)}`,
  getActivityStatus: () => `location/activity-status`,
};
export const location = {
  ...AbstractEnpoint('location'),
  getByNameLike: (query) => `location/name-like/${encodeURIComponent(query)}`,
  getActivityStatus: () => `location/activity-status`,
};

export const auth = {
  login: () => `login`,
  me: () => `me`,
  refreshToken: () => 'api/auth/token',
};

export const profile = {
  profile: (itemId) => `me/profile/${itemId}`,
};

export const impact = {
  impact: () => `impact/calculate`,
};

export const caseReport = {
  ...AbstractEnpoint('casereport'),
  statuses: () => 'casereport/statuses',
  types: () => 'casereport/types',
  severities: () => 'casereport/severities',
};
