import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import AsyncCreatable from 'react-select/async-creatable';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import './styles/select.css';
import chroma from 'chroma-js';
import { withTranslation } from 'react-i18next';

function AsyncSelectComponent({
  t,
  name,
  label,
  form: { touched, errors },
  options,
  classNames,
  field,
  labelKeyName,
  idKeyName,
  onChange,
  loadOptions,
  isMulti,
  debounceTimeout,
  ...props
}) {
  options = _.map(options, (option) => ({
    value: option[idKeyName],
    label: option[labelKeyName],
  }));

  const error = _.get(errors, name);

  if (loadOptions) {
    loadOptions = _.debounce(loadOptions, debounceTimeout || 200);
  }

  return (
    <FormGroup
      className={classnames('form-element', classNames, { 'has-error': error && error.message })}
    >
      {label && <ControlLabel>{label}</ControlLabel>}

      <AsyncCreatable
        {...props}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        options={options}
        noOptionsMessage={() => t('fields.no_options')}
        loadOptions={loadOptions}
        isMulti={isMulti}
        onChange={(values) => {
          onChange && onChange(values);
        }}
        formatCreateLabel={(userInput) => `${t('fields.create')} "${userInput}"`}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#34b77cb5',
            primary25: '#34b77c29',
            primary50: '#34b77c73',
          },
        })}
        styles={{
          control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
            borderColor:
              error && error.message ? '#ff0000' : state.isFocused ? '#1ab394' : '#e5e6e7',
            '&:hover': {
              borderColor:
                error && error.message ? '#ff0000' : state.isFocused ? '#1ab394' : '#e5e6e7',
            },
          }),
          multiValue: (styles, { data }) => {
            const color = chroma(data.color ? data.color : '#1ab394');
            return {
              ...styles,
              backgroundColor: color.alpha(0.1).css(),
            };
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color ? data.color : '#1ab394',
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color ? data.color : '#1ab394',
            ':hover': {
              backgroundColor: data.color ? data.color : '#1ab394',
              color: 'white',
            },
          }),
        }}
        className={classnames(classNames, 'select-fld', { 'has-error': error && error.message })}
      />
      {error && error.message && (
        <HelpBlock className="error-text animated fadeIn fm-error-req">{error.message}</HelpBlock>
      )}
    </FormGroup>
  );
}

AsyncSelectComponent.propTypes = {
  form: PropTypes.object.isRequired,
  options: PropTypes.array,
  label: PropTypes.string,
  classNames: PropTypes.string,
  meta: PropTypes.object,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func,
  labelKeyName: PropTypes.string,
  idKeyName: PropTypes.string,
  loadOptions: PropTypes.func,
  multi: PropTypes.bool,
  debounceTimeout: PropTypes.number,
};

AsyncSelectComponent.defaultProps = {
  labelKeyName: 'label',
  idKeyName: 'value',
};

export default withTranslation()(AsyncSelectComponent);
