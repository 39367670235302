import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

class AccessDenied extends PureComponent {
  render() {
    const { t } = this.props;
    return (
      <div className="middle-box text-center animated fadeInDown ng-scope">
        <h1>403</h1>
        <h3 className="font-bold">{t('components.access_denied')}</h3>

        <div className="error-desc">{t('message.access_denied')}</div>
      </div>
    );
  }
}

export default withTranslation()(AccessDenied);
