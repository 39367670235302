import {
  SET_EMPLOYEE_INFO,
  SET_EMPLOYEE_ORG_UNITS,
  SET_EMPLOYEE_USERS,
} from '../../constants/actions';

export const setEmployeeDetails = (data) => ({
  type: SET_EMPLOYEE_INFO,
  employee: data.employee,
});

export const setUsers = (data) => ({
  type: SET_EMPLOYEE_USERS,
  users: data.users,
});

export const setOrgUnits = (data) => ({
  type: SET_EMPLOYEE_ORG_UNITS,
  orgUnits: data.orgUnits,
});
