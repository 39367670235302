import React, { Component } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Can from '../security/Can';

class FormTitle extends Component {
  render() {
    const { name, buttons, tools } = this.props;
    return (
      <div className="ibox-title">
        {name ? (
          <span className="fm-form-name">{name}</span>
        ) : (
          <div className="animation fm-box-placeholder">
            <h1 className="text-title" />
          </div>
        )}
        <div className="">
          {buttons &&
            _.map(buttons, (btn, key) =>
              !(btn.securityModule || btn.securityPermission) ? (
                <button
                  key={`form-title-btn${key}`}
                  className={`btn ${btn.importance ? `${btn.importance} ` : ''}${
                    btn.size ? `${btn.size} ` : ''
                  }`}
                  onClick={btn.onClick}
                >
                  {btn.icon ? <FontAwesomeIcon icon={btn.icon} /> : null}
                  {btn.name}
                </button>
              ) : (
                <Can
                  key={`form-title-btn${key}`}
                  module={btn.securityModule}
                  permission={btn.securityPermission}
                  isProtected={btn.isProtected}
                  yes={() => (
                    <button
                      className={`btn ${btn.importance ? `${btn.importance} ` : ''}${
                        btn.size ? `${btn.size} ` : ''
                      }`}
                      onClick={btn.onClick}
                      title={btn.title}
                    >
                      {btn.icon ? <FontAwesomeIcon icon={btn.icon} /> : null}
                      {btn.name}
                    </button>
                  )}
                  no={() => null}
                />
              ),
            )}
          {tools}
        </div>
      </div>
    );
  }
}

FormTitle.propTypes = {
  name: PropTypes.string,
  buttons: PropTypes.array,
  tools: PropTypes.object,
};
export default FormTitle;
