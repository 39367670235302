import React, { Component } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class ImageCropperModal extends Component {
  render() {
    const { t, closeModal, handleSubmit, image, extension } = this.props;
    const src = extension ? `data:image/${extension};base64,${image}` : image;

    return (
      <>
        <Cropper
          className="cropper=image"
          src={src}
          style={{
            height: 400,
            width: '75%',
          }}
          aspectRatio={1}
          guides={false}
          ref="cropper"
          responsive
          restore={false}
          center
          highlight
          background
          autoCrop
          movable
          rotatable
          scalable
          zoomable={false}
          preview=".preview-image"
        />
        <div className="preview-cicrle">
          <div className="preview-image circle" />
        </div>
        <div className="preview-square">
          <div className="preview-image" />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn fm-btn-primary"
            onClick={(e) => {
              const { cropper } = this.refs;
              const canvas = cropper.getCroppedCanvas();
              canvas.toBlob((blob) => {
                const reader = new FileReader();
                reader.onload = function (event) {
                  const { result } = event.target;
                  cropper.cropper.destroy();
                  handleSubmit(btoa(result), extension);
                };
                reader.readAsBinaryString(blob);
              });
            }}
          >
            {t('components.crop')}
          </button>
          <button
            type="button"
            className="btn"
            onClick={() => {
              this.refs.cropper.cropper.destroy();
              closeModal();
            }}
          >
            {t('buttons.cancel')}
          </button>
        </div>
      </>
    );
  }
}

ImageCropperModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  extension: PropTypes.string.isRequired,
};

export default withTranslation()(ImageCropperModal);
