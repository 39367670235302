import { toast } from 'react-toastify';
import _ from 'lodash';

const toastSettings = (options) => ({ ...options, autoClose: options.autoClose || 5000 });

const getMessages = (values) => {
  const messages = [];
  if (values.messages) {
    messages.push(...values.messages);
  } else if (values.message) {
    messages.push(values.message);
  } else {
    messages.push(values);
  }
  return messages;
};

export const showError = (values, options) => {
  if (!values) {
    return false;
  }

  return _.map(getMessages(values), (value) => {
    toast.error(value, toastSettings({ options }));
  });
};

export const showSuccess = (values, options) => {
  if (!values) {
    return false;
  }

  return _.map(getMessages(values), (value) => {
    toast.success(value, toastSettings({ options }));
  });
};

export const showInfo = (values, options) => {
  if (!values) {
    return false;
  }

  return _.map(getMessages(values), (value) => {
    toast.info(value, toastSettings({ options }));
  });
};

export const showWarning = (values, options) => {
  if (!values) {
    return false;
  }

  return _.map(getMessages(values), (value) => {
    toast.warning(value, toastSettings({ options }));
  });
};

const Notifications = {
  error: showError,
  success: showSuccess,
  info: showInfo,
  warning: showWarning,
};

export default Notifications;
