import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faChevronDown,
  faChevronRight,
  faEllipsisH,
  faExclamationTriangle,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { faClone, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import Input from '../react-hook-form-inputs/Input';
import TextArea from '../react-hook-form-inputs/TextArea';
import MaterialCheckbox from '../react-hook-form-inputs/MaterialCheckbox';
import FeedbackCriteriaDescriptionForm from './FeedbackCriteriaDescriptionForm';
import Can from '../security/Can';

function FeedbackCriteriaForm(props) {
  const { t, setForm, feedback, criteria } = props;
  const [render, setRender] = useState(false);
  const [activeCriterionMenu, setActiveCriterionMenu] = useState(-1);
  const [criterionDescriptionArray, setCriterionDescriptionArray] = useState([]);

  const handleClickOutside = (event) => {
    if (
      event.target.classList.contains('fm-controls-more') ||
      event.target.classList.contains('fa-ellipsis-h')
    ) {
      return;
    }
    const hide =
      !event.target ||
      (!event.target.classList.contains('fm-more-menu-item') &&
        !event.target.closest('.fm-controls-more-menu'));

    if (hide) {
      setActiveCriterionMenu(-1);
    }
  };

  useEffect(() => {
    setForm('criteria', form);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const validationSchema = Yup.object().shape({
    criteria: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(t('message.required_field')),
          feedbackCriteriaDescriptions: Yup.array()
            .of(
              Yup.object().shape({
                label: Yup.string().required(t('message.required_field')),
              }),
            )
            .min(2, t('message.at_least_two_criterion_description_required')),
        }),
      )
      .min(1, t('message.at_least_one_criterion_required')),
  });

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: { criteria },
  });
  const { register, control, setValue, getValues, watch } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'criteria',
    keyName: 'criterionKeyId',
  });
  const _criteria = watch('criteria');
  const calcSortOrder = (objects) => {
    let nextOrder = 0;
    _.forEach(objects, (o) => {
      if (o && nextOrder < o.sortOrder) {
        nextOrder = o.sortOrder;
      }
    });
    nextOrder++;
    return nextOrder;
  };

  const addCriterion = (event) => {
    const newFeedbackCriterion = {
      tenantId: feedback.tenantId,
      feedbackId: feedback.id,
      name: '',
      description: '',
      systemType: 'Platform:FeedbackCriterion',
      isMain: true,
      sortOrder: calcSortOrder(fields),
      feedbackCriteriaDescriptions: [
        {
          value: 1,
          label: t('message.strongly_disagree'),
          color: 'red',
          action: 'create',
        },
        {
          value: 2,
          label: t('message.disagree'),
          color: 'orange',
          action: 'create',
        },
        {
          value: 3,
          label: t('message.neutral'),
          color: 'green',
          action: 'create',
        },
        {
          value: 4,
          label: t('message.agree'),
          color: 'blue',
          action: 'create',
        },
        {
          value: 5,
          label: t('message.strongly_agree'),
          color: 'purple',
          action: 'create',
        },
      ],
      action: 'create',
      isHidden: false,
    };
    append(newFeedbackCriterion);
    setValue(
      `criteria[${fields.length}].feedbackCriteriaDescriptions`,
      newFeedbackCriterion.feedbackCriteriaDescriptions,
      {
        shouldValidate: true,
      },
    );
    setCriterionDescriptionArray(newFeedbackCriterion.feedbackCriteriaDescriptions);
  };

  const setUpdateAction = (field, index) => {
    if (!fields[index].action) {
      fields[index].action = field.id ? 'update' : 'create';
    }
  };

  const toggleCriterion = (event, index) => {
    setValue(`criteria[${index}].isHidden`, !fields[index].isHidden);
    fields[index].isHidden = !fields[index].isHidden;
    setRender(!render);
  };

  const removeCriterion = (event, index) => {
    event.stopPropagation();
    if (fields[index].action !== 'delete' && fields[index].id) {
      setValue(`criteria[${index}].action`, 'delete');
      fields[index].action = 'delete';
    } else {
      remove(index);
    }
    setActiveCriterionMenu(-1);
    setRender(!render);
  };

  const copyCriterion = (event, index) => {
    const values = getValues();
    const newCriterion = _.cloneDeep(values.criteria ? values.criteria[index] : {});

    append({
      ...newCriterion,
      id: null,
      action: 'create',
      isHidden: false,
      sortOrder: calcSortOrder(fields),
    });
    setActiveCriterionMenu(-1);
    setCriterionDescriptionArray(newCriterion.feedbackCriteriaDescriptions);
  };

  let criterionCounter = 0;
  return (
    <>
      <div className="fm-wizard-element-title-container">
        <div className="fm-wizard-element-title">{t('components.criterion')}</div>
      </div>
      <div className="ibox-content clearfix fm-wizard-element-content-box">
        <FormProvider {...form} className="row">
          <form className="fm-feedback-criteria">
            {fields.map((field, index) => {
              if (!field) {
                return;
              }

              if (field.action !== 'delete') {
                criterionCounter++;
              }

              return (
                <div
                  className={`fm-criterion-container ${
                    field.action === 'delete' ? 'fm-display-none' : ''
                  }`}
                  key={`criteria[${index}].criterionKeyId`}
                >
                  <div className="fm-criterion-dnd-control">
                    <span className="fm-criterion-drag-n-drop-control">
                      <FontAwesomeIcon icon={faBars} />
                    </span>
                  </div>
                  <div className="fm-criterion-info">
                    <input
                      type="hidden"
                      name={`criteria[${index}].id`}
                      ref={register()}
                      defaultValue={field.id}
                    />
                    <input
                      type="hidden"
                      name={`criteria[${index}].tenantId`}
                      ref={register()}
                      defaultValue={field.tenantId}
                    />
                    <input
                      type="hidden"
                      name={`criteria[${index}].action`}
                      ref={register()}
                      defaultValue={field.action}
                    />
                    <input
                      type="hidden"
                      name={`criteria[${index}].sortOrder`}
                      ref={register()}
                      defaultValue={field.sortOrder}
                    />
                    <input
                      type="hidden"
                      name={`criteria[${index}].feedbackId`}
                      ref={register()}
                      defaultValue={field.feedbackId}
                    />
                    <input
                      type="hidden"
                      name={`criteria[${index}].isHidden`}
                      ref={register()}
                      defaultValue={field.isHidden}
                    />
                    <Controller
                      control={form.control}
                      name={`criteria[${index}].name`}
                      defaultValue={field.name}
                      render={({ onChange, onBlur, value, name }) => (
                        <Input
                          name={name}
                          placeholder={t('fields.name')}
                          form={form}
                          maxLength="512"
                          onBlur={(event) => {
                            fields[index].name = value;
                            onBlur(event);
                          }}
                          onChange={(event) => {
                            setUpdateAction(field, index);
                            onChange(event);
                          }}
                          value={value}
                          classNames={field.isHidden ? 'fm-display-none' : ''}
                        />
                      )}
                    />
                    <Controller
                      control={form.control}
                      name={`criteria[${index}].description`}
                      defaultValue={field.description}
                      render={({ onChange, onBlur, value, name }) => (
                        <TextArea
                          name={name}
                          placeholder={t('fields.description')}
                          form={form}
                          rows={2}
                          maxLength="1024"
                          onBlur={(event) => {
                            fields[index].question = value;
                            onBlur(event);
                          }}
                          onChange={(event) => {
                            setUpdateAction(field, index);
                            onChange(event);
                          }}
                          value={value}
                          classNames={field.isHidden ? 'fm-display-none' : ''}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={`criteria[${index}].isMain`}
                      defaultValue={field.isMain}
                      render={({ onChange, onBlur, value, name }) => (
                        <MaterialCheckbox
                          name={name}
                          checked={
                            typeof value === 'string' ? JSON.parse(value.toLowerCase()) : value
                          }
                          form={form}
                          label={t('fields.is_main')}
                          checkedElementColor="#34B77C"
                          onBlur={onBlur}
                          onChange={(e) => {
                            setUpdateAction(field, index);
                            onChange(e.target.checked);
                          }}
                          fieldSetClassNames={
                            field.isHidden || field.action === 'delete' ? 'fm-display-none' : ''
                          }
                          value={
                            typeof value === 'string' ? JSON.parse(value.toLowerCase()) : value
                          }
                        />
                      )}
                    />
                    {!field.isHidden ? null : (
                      <>
                        {field && field.name && field.name.length > 0 ? (
                          <>
                            <div
                              className="fm-criterion-text"
                              onClick={(event) => {
                                event.stopPropagation();
                                toggleCriterion(event, index);
                              }}
                            >
                              {field.name}
                            </div>
                            <div className="fm-cl-tag">
                              <span
                                className="fm-badge"
                                title={
                                  field.isMain ? t('components.main') : t('components.additional')
                                }
                              >
                                {_criteria[index].isMain
                                  ? t('components.main_letter')
                                  : t('components.additional_letter')}
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className="alert alert-warning fm-feedback-text">
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                            {t('components.empty')}
                          </div>
                        )}
                      </>
                    )}
                    {field.feedbackCriteriaDescriptions && (
                      <FeedbackCriteriaDescriptionForm
                        form={form}
                        calcSortOrder={calcSortOrder}
                        feedbackCriteriaDescriptions={criterionDescriptionArray}
                        index={index}
                        criterion={field}
                        isHidden={field.isHidden}
                      />
                    )}
                  </div>
                  <div className="fm-criterion-controls">
                    <div
                      className="fm-criterion-control-visible"
                      onClick={(event) => toggleCriterion(event, index)}
                    >
                      <FontAwesomeIcon icon={field.isHidden ? faChevronRight : faChevronDown} />
                    </div>
                    <div className="fm-criterion-control-separator" />
                    <div
                      className="fm-controls-more"
                      onClick={(event) => {
                        event.stopPropagation();
                        setActiveCriterionMenu(activeCriterionMenu === index ? -1 : index);
                      }}
                    >
                      <FontAwesomeIcon icon={faEllipsisH} />
                    </div>
                    <div
                      className={`fm-controls-more-menu animated fadeIn ${
                        activeCriterionMenu === index ? 'show' : ''
                      }`}
                    >
                      <div
                        className="fm-more-menu-item"
                        onClick={(event) => removeCriterion(event, index)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                        {t('components.delete')}
                      </div>
                      <div
                        className="fm-more-menu-item"
                        onClick={(event) => copyCriterion(event, index)}
                      >
                        <FontAwesomeIcon icon={faClone} />
                        {t('components.copy')}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="fm-add-criterion-container">
              <Can
                module="feedback"
                permission="edit"
                yes={() => (
                  <div className="fm-add-criterion-box" onClick={(event) => addCriterion(event)}>
                    <div className="fm-add-criterion-image">
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                    <div className="fm-add-criterion-label">{t('components.add_criterion')}</div>
                  </div>
                )}
                no={() => null}
              />
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
}

export default withTranslation()(FeedbackCriteriaForm);
