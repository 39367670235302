import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import _ from 'lodash';
import { faKey, faTimes, faUser, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import FileUtil from '../../utils/FileUtil';
import UrlUtil from '../../utils/UrlUtil';
import UserProfileForm from '../../components/user/UserProfileForm';
import { getLanguageSelectItems, getUserById, updateUser } from '../../services/user/userProvider';
import { setUserDetails } from '../../services/account/authActions';
import { initializeMenu } from '../../services/menu/menuActions';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import './styles/profile.css';
import ImageCropperModal from '../../components/cropper/ImageCropperModal';
import Modal from '../../components/modal/Modal';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';
import ChangePasswordModal from '../../components/user/ChangePasswordModal';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      extension: '',
      image: null,
      user: null,
      isModalShown: false,
      overritenImage: false,
      isChangePasswordModalShown: false,
    };
    this.avatarUploaderRef = React.createRef();
    this.submitionArray = [];
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.profile'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { account } = this.props;

    this.setState({ isLoading: true });
    const promises = [];
    promises.push(getLanguageSelectItems());
    if (account) {
      promises.push(getUserById(account.userId));
    }

    Promise.all(promises)
      .then(([langData, accountData]) => {
        this.setState({
          image: accountData && accountData ? accountData.profileImage : null,
          extension: accountData && accountData ? accountData.profileImageExtension : '',
          user: accountData,
          isLoading: false,
          languageOptions: langData,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  handleAvatarClick = () => {
    this.avatarUploaderRef.current.click();
  };

  handleAvatarChange = (e) => {
    const { t } = this.props;

    const { target } = e.nativeEvent;

    const { files } = target;
    const file = files[0];
    if (!files || !file) {
      return;
    }
    if (files[0].size > 1024 * 1024 * 10) {
      Notifications.error(t('message.file_too_big'));
      return;
    }

    const reader = new FileReader();

    const me = this;
    reader.onload = function (readerEvt) {
      const fileName = FileUtil.getFileName(target.value);
      const ext = FileUtil.getFileExtension(fileName);
      const base64Image = btoa(readerEvt.target.result);
      me.setState({
        isModalShown: true,
        image: base64Image,
        extension: ext,
      });
    };
    reader.readAsBinaryString(file);
  };

  deleteProfileImage = () => {
    const { user } = this.state;
    user.profileImageLink = null;
    this.setState({
      image: null,
      extension: null,
      user,
    });
  };

  handleSubmitModal = (image) => {
    const { extension } = this.state;
    this.setState({
      isModalShown: false,
      image,
      extension,
      overritenImage: true,
    });
    this.setState({ isModalShown: false });
    this.avatarUploaderRef.current.value = '';
  };

  closeModal = () => {
    this.setState({ isModalShown: false });
  };

  addSubmition = (submition) => {
    this.submitionArray.push(submition);
  };

  handleSubmitForm = () => {
    _.forEach(this.submitionArray, (s) => s());
  };

  openChangePasswordModal = () => {
    this.setState({ isChangePasswordModalShown: true });
  };

  closeChangePasswordModal = () => {
    this.setState({ isChangePasswordModalShown: false });
  };

  updateUser = (values) => {
    this.setState({ isLoading: true });

    const { image, extension, user } = this.state;
    const { dispatchUserDetails, account, t, i18n } = this.props;

    user.phone = values.phone;
    user.language = values.language ? values.language.value : null;

    user.username = values.username;
    user.verified = values.verified;
    user.email = values.email;

    user.firstName = values.firstName;
    user.lastName = values.lastName;
    user.middleName = values.middleName;
    user.profileImage = image;
    user.profileImageExtension = extension;

    const me = this;
    updateUser(user.id, user)
      .then(() => {
        const promises = [];
        promises.push(getLanguageSelectItems());
        promises.push(getUserById(user.id));

        Promise.all(promises)
          .then(([langData, accountData]) => {
            me.setState({
              user: accountData,
              isLoading: false,
              languageOptions: langData,
            });
            if (account.userId === user.id) {
              dispatchUserDetails({
                ...account,
                user,
              });
              if (user && user.language) {
                moment.locale(user.language);
              }
            }
            i18n.changeLanguage(accountData.language);
          })
          .catch((error) => {
            Notifications.error(error);
            this.setState({ isLoading: false });
          });
        Notifications.success(t('message.profile_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      user,
      isLoading,
      image,
      isModalShown,
      isChangePasswordModalShown,
      extension,
      languageOptions,
      overritenImage,
    } = this.state;
    const imageLink = user && user ? user.profileImageLink : null;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer name={t('containers.profile')} breadcrumbs={this.getBreadcrumbs()} />
          </div>
        </div>
        {user && (
          <div className="wrapper-content">
            <div className="row">
              <div className="col-md-4">
                <div className="ibox float-e-margins">
                  <FormTitle name={t('components.photo')} />
                  <div>
                    <div className="ibox-content no-padding border-left-right image-container">
                      {imageLink && !overritenImage ? (
                        <img
                          className="img-responsive fm-profile-img"
                          src={UrlUtil.buildImageSrcLink(imageLink)}
                          id="avatar-image"
                          onClick={this.handleAvatarClick}
                          alt={t('components.photo')}
                        />
                      ) : image && extension ? (
                        <img
                          className="img-responsive fm-profile-img"
                          src={`data:image/${extension};base64,${image}`}
                          id="avatar-image"
                          onClick={this.handleAvatarClick}
                          alt={t('components.photo')}
                        />
                      ) : (
                        <div className="fm-profile-photo-container">
                          <div
                            onClick={this.handleAvatarClick}
                            id="avatar-image"
                            className="img-responsive fm-user-profile-empty-photo fm-profile-img"
                          >
                            <FontAwesomeIcon icon={faUser} />
                          </div>
                        </div>
                      )}
                      <input
                        type="file"
                        name="profilePicture"
                        id="profilePicture"
                        ref={this.avatarUploaderRef}
                        style={{ display: 'none' }}
                        onChange={this.handleAvatarChange}
                      />
                      {(imageLink || (image && extension)) && (
                        <div
                          className="fm-delete-btn"
                          title={t('components.delete_photo')}
                          onClick={this.deleteProfileImage}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </div>
                      )}
                    </div>
                    <Modal
                      header={t('components.crop_image')}
                      isOpened={isModalShown}
                      icon={faUserAlt}
                      key="crop-image-modal"
                      onClose={this.closeModal}
                    >
                      {image && (
                        <ImageCropperModal
                          handleSubmit={this.handleSubmitModal}
                          image={image}
                          closeModal={this.closeModal}
                          extension={extension}
                        />
                      )}
                    </Modal>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="ibox float-e-margins">
                  <FormTitle name={t('components.user_info')} />
                  <div className="ibox-content clearfix">
                    <UserProfileForm
                      user={user}
                      languageOptions={languageOptions}
                      updateUserProfile={this.updateUser}
                      setUserProfileRef={this.setUserProfileRef}
                      addSubmition={this.addSubmition}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Modal
          header={t('components.change_password')}
          isOpened={isChangePasswordModalShown}
          icon={faKey}
          key="change-password"
          onClose={this.closeChangePasswordModal}
        >
          <ChangePasswordModal cancel={this.closeChangePasswordModal} />
        </Modal>
        <div className="footer fixed">
          <div className="btn-group pull-right" role="group">
            <button
              onClick={this.openChangePasswordModal}
              type="button"
              className="btn fm-btn-primary btn-sm"
            >
              {t('buttons.change_password')}
            </button>
            <button
              onClick={this.handleSubmitForm}
              type="button"
              className="btn fm-btn-primary btn-sm"
            >
              {t('buttons.save')}
            </button>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

Profile.propTypes = {
  account: PropTypes.object,
  dispatchSetMenuStructure: PropTypes.func,
  dispatchUserDetails: PropTypes.func,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetMenuStructure: (role) => {
    dispatch(initializeMenu({ permissions: role }));
  },
  dispatchUserDetails: (userDetails) => {
    dispatch(setUserDetails({ userDetails }));
  },
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile)));
