import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/home.css';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';

import { withNewModal } from '../NewModal';
import Notifications from '../../utils/Notifications';
import {
  checkIsManager,
  getMyTeamEmployeesManagerPagination,
  getMyTeamEmployeesPagination,
} from '../../services/employee/employeeProvider';
import PaginationTable from '../tables/PaginationTable';
import StringRenderer from '../tables/StringRenderer';
import UserRenderer from '../tables/UserRenderer';
import ButtonRenderer from '../tables/ButtonRenderer';
import OngoingFeedbackModal from './OngoingFeedbackModal';

class TeamHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isManager: false,
      defaultSorted: [
        {
          id: 'created',
          desc: true,
        },
      ],
      SweetAlertModal: withReactContent(Swal),
      paginationPageSize: 10,
      minRows: 3,
      columns: [],
    };
  }



  componentDidMount() {
    const { account, t } = this.props;
    checkIsManager(account.userId)
      .then((data) => {
        if (data) {
          this.setState({
            isManager: data,
            fetchData: (amount, skip, sorting, filters, callback) => {
              getMyTeamEmployeesManagerPagination(
                {
                  amount,
                  skip,
                  sortings: sorting,
                  filters,
                },
                account.userId,
              )
                .then((data) => {
                  callback(data);
                })
                .catch((error) => {
                  Notifications.error(error);
                  this.setState({ isLoading: false });
                });
            },
            columns: [
              {
                Header: t('tables.team_member'),
                id: 'userInfo',
                accessor: (d) => ({
                  user: d.user ? d.user : '',
                  employeeJob: d.employeeJob ? d.employeeJob : '',
                  userKeyPath: 'user',
                  employeeJobKeyPath: 'employeeJob',
                  employeeJobLevelKeyPath: 'employeeJobLevel',
                }),
                Cell: UserRenderer,
                filterable: false,
              },
              {
                Header: t('tables.location'),
                id: 'location',
                accessor: 'location.name',
                Cell: StringRenderer,
                filterable: false,
              },
              {
                id: 'actions',
                Cell: ButtonRenderer,
                sortable: false,
                filterable: false,
                objectKeyPath: 'employee',
                onClick: this.onClickLeaveFeedback,
                label: t('tables.leave_feedback'),
                icon: faComment,
              },
            ],
          });
        } else {
          this.setState({
            isManager: data,
            fetchData: (amount, skip, sorting, filters, callback) => {
              getMyTeamEmployeesPagination(
                {
                  amount,
                  skip,
                  sortings: sorting,
                  filters,
                },
                account.userId,
              )
                .then((data) => {
                  callback(data);
                })
                .catch((error) => {
                  Notifications.error(error);
                  this.setState({ isLoading: false });
                });
            },
            // Team member
            // Job
            // Job Level
            // Location
            // Feedback (button to give ongoing feedback)
            columns: [
              {
                Header: t('tables.team_member'),
                id: 'userInfo',
                accessor: (d) => ({
                  user: d.user,
                  employeeJob: d ? d.employeeJob : '',
                  userKeyPath: 'user',
                  employeeJobKeyPath: 'employeeJob',
                  employeeJobLevelKeyPath: 'employeeJobLevel',
                }),
                Cell: UserRenderer,
                sortable: true,
                filterable: false,
              },
              {
                Header: t('tables.location'),
                accessor: 'location.name',
                id: 'location',
                Cell: StringRenderer,
                sortable: true,
                filterable: false,
              },
              {
                id: 'actions',
                Cell: ButtonRenderer,
                sortable: false,
                filterable: false,
                objectKeyPath: 'employee',
                onClick: this.onClickLeaveFeedback,
                label: t('tables.leave_feedback'),
                icon: faComment,
              },
            ],
          });
        }
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }

  onClickLeaveFeedback = (ongoingFeedbackTarget) => {
    const { openModal, closeModal, account } = this.props;

    openModal({
      component: <OngoingFeedbackModal account={account} ongoingFeedbackTarget={ongoingFeedbackTarget} closeModal={closeModal} />,
    })
  };

  render() {
    const {
      defaultSorted,
      paginationPageSize,
      minRows,
      fetchData,
      columns,
    } = this.state;

    return (
      <div className="fm-home-team-table">
        {fetchData && columns && (
          <PaginationTable
            minRows={minRows}
            defaultSorted={defaultSorted}
            defaultPageSize={paginationPageSize}
            columns={columns}
            getDataFromServer={fetchData}
            className="-striped -highlight"
          />
        )}
      </div>
    );
  }
}

TeamHome.propTypes = {};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withNewModal(withTranslation()(connect(mapStateToProps)(TeamHome)));
