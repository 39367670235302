import React from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import Moment from 'moment';

import { withTranslation } from 'react-i18next';
import InputComponent from '../inputs/Input';
import TextAreaComponent from '../inputs/Textarea';
import FormTitle from '../form/FormTitle';
import Validation from '../../utils/validation';
import Can from '../security/Can';
import SelectComponent from '../inputs/Select';

function InnerForm(props) {
  const { t, handleSubmit, setFieldValue, statusOptions } = props;
  return (
    <div className="ibox">
      <FormTitle name="Info" />
      <div className="ibox-content clearfix">
        <Form onSubmit={handleSubmit}>
          <Field
            component={InputComponent}
            placeholder={t('fields.location_name')}
            type="text"
            name="name"
            label={t('fields.location_name')}
          />
          <Field
            component={TextAreaComponent}
            placeholder={t('fields.description')}
            type="text"
            name="description"
            label={t('fields.description')}
          />
          <Field
            component={InputComponent}
            placeholder={t('fields.country')}
            type="text"
            name="country"
            label={t('fields.country')}
          />
          <Field
            component={InputComponent}
            placeholder={t('fields.city')}
            type="text"
            name="city"
            label={t('fields.city')}
          />
          <Field
            component={InputComponent}
            placeholder={t('fields.address_line_1')}
            type="text"
            name="addressLine1"
            label={t('fields.address_line_1')}
          />
          <Field
            component={InputComponent}
            placeholder={t('fields.address_line_2')}
            type="text"
            name="addressLine2"
            label={t('fields.address_line_2')}
          />
          <Field
            component={InputComponent}
            placeholder={t('fields.address_line_3')}
            type="text"
            name="addressLine3"
            label={t('fields.address_line_3')}
          />
          <Field
            component={InputComponent}
            placeholder={t('fields.postal_code')}
            type="text"
            name="postalCode"
            label={t('fields.postal_code')}
          />
          <Field
            component={InputComponent}
            placeholder={t('fields.time_zone')}
            type="text"
            name="timeZone"
            label={t('fields.time_zone')}
          />
          <Field
            component={SelectComponent}
            placeholder={t('fields.status')}
            type="text"
            name="activityStatus"
            options={statusOptions}
            setFieldValue={setFieldValue}
            label={t('fields.status')}
          />
          <Field
            component={InputComponent}
            placeholder={t('fields.created')}
            type="text"
            name="created"
            label={t('fields.created')}
            disabled
          />
          <Field
            component={InputComponent}
            placeholder={t('fields.updated')}
            type="text"
            name="updated"
            label={t('fields.updated')}
            disabled
          />
          <Can
            module="location"
            permission="edit"
            yes={() => (
              <button type="submit" className="btn fm-btn-primary block pull-right">
                {t('buttons.save')}
              </button>
            )}
            no={() => null}
          />
        </Form>
      </div>
    </div>
  );
}

InnerForm.propTypes = {
  location: PropTypes.object,
  updateLocation: PropTypes.func.isRequired,
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const EditOrgUnitDetailsForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    const { location, statusOptions } = values;

    return {
      addressLine1: location.addressLine1 ? location.addressLine1 : '',
      addressLine2: location.addressLine2 ? location.addressLine2 : '',
      addressLine3: location.addressLine3 ? location.addressLine3 : '',
      city: location.city ? location.city : '',
      country: location.country ? location.country : '',
      description: location.description ? location.description : '',
      name: location.name ? location.name : '',
      postalCode: location.postalCode ? location.postalCode : '',
      activityStatus: location.activityStatus
        ? statusOptions.find((el) => el.value === location.activityStatus)
        : '',
      created: location.created ? Moment(location.created).format('LL') : '',
      updated: location.updated ? Moment(location.updated).format('LL') : '',
    };
  },
  validate: (values) => {
    const errors = {};
    errors.name = Validation.required(values.name);
    errors.activityStatus = Validation.required(values.activityStatus.value);

    return _.isEmpty(_.filter(errors, (value) => value)) ? {} : errors;
  },
  handleSubmit: (values, _props) => {
    const { props } = _props;
    const valsToSave = {
      ...values,
      activityStatus: values.activityStatus ? values.activityStatus.value : null,
      id: props.location.id,
    };
    props.updateLocation(valsToSave);
  },
})(InnerForm);

export default withTranslation()(EditOrgUnitDetailsForm);
