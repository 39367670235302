import React, { Component } from 'react';
import TextareaComponent from '../../inputs/Textarea';
import { Field, withFormik } from 'formik';
import '../styles/questions.css';
import { withTranslation } from 'react-i18next';

class TextQuestion extends Component {
  buildAnswer = (e, question) => {
    const { testAssignment, populateUserAnswer, questionAnswerResults } = this.props;
    const action =
      !questionAnswerResults || questionAnswerResults.length <= 0 ? 'create' : 'update';
    let questionAnswerId = null;
    if (action === 'update') {
      questionAnswerId = {
        id: questionAnswerResults[0].id,
        tenantId: questionAnswerResults[0].tenantId,
      };
    }
    populateUserAnswer(question.id, [
      {
        object: {
          ...questionAnswerId,
          questionId: question.id,
          testAssignmentId: testAssignment.id,
          answerText: e.target.value,
        },
        action,
        objectType: 'Platform:QuestionAnswerResult',
      },
    ]);
  };

  render() {
    const { t, question, handleChange, animation } = this.props;
    return (
      <div className={`fm-answer-container clearfix animated ${animation}`}>
        <Field
          component={TextareaComponent}
          placeholder={t('fields.write_your_answer')}
          type="text"
          rows={3}
          name={`question-${question.id}-answerText`}
          onChange={(e) => {
            this.buildAnswer(e, question);
            handleChange(e);
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ questionAnswerResults, question }) => {
      const result = {};
      if (questionAnswerResults && questionAnswerResults.length > 0) {
        result[`question-${question.id}-answerText`] = questionAnswerResults[0].answerText;
      } else {
        result[`question-${question.id}-answerText`] = '';
      }
      return result;
    },
  })(TextQuestion),
);
