import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AllEmployeeJobLevels from '../../containers/employeejoblevel/AllEmployeeJobLevels';
import EditEmployeeJobLevel from '../../containers/employeejoblevel/EditEmployeeJobLevel';

import { AUTH_ROUTES, EMPLOYEE_JOB_LEVEL_ID } from '../../constants/routes';

class EmployeeJobLevelRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllEmployeeJobLevels} />
        <Route
          path={`${match.url}${EMPLOYEE_JOB_LEVEL_ID}`}
          render={({ match }) => <EmployeeJobLevelInnerRouting match={match} />}
        />
      </div>
    );
  }
}

EmployeeJobLevelRoutes.propTypes = {
  match: PropTypes.object,
};

export default EmployeeJobLevelRoutes;

class EmployeeJobLevelInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let employeeJobLevelRouting;
    if (match.params.employeeJobLevelId) {
      switch (match.params.employeeJobLevelId) {
        default:
          employeeJobLevelRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.ORG.EMPLOYEE_JOB_LEVELS}${EMPLOYEE_JOB_LEVEL_ID}`}
              component={AllEmployeeJobLevels}
            />
          );
      }
    }

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.ORG.EMPLOYEE_JOB_LEVELS}${EMPLOYEE_JOB_LEVEL_ID}/edit`}
          component={EditEmployeeJobLevel}
        />
        {employeeJobLevelRouting}
      </div>
    );
  }
}

EmployeeJobLevelInnerRouting.propTypes = {
  match: PropTypes.object,
};
