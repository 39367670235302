import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Notifications from '../../utils/Notifications';

export default function TextWithCopyToClipboard({ text, containerClassName, textClassName, ...props }) {
  const { t } = useTranslation();

  const copyTextToClipboard = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
      Notifications.success(t('message.copied_to_clipboard'));
    }
  };

  return (
      <span {...props} onClick={copyTextToClipboard} className={`cursor-pointer ${containerClassName}`}>
        <span className={textClassName}>{text}</span><FontAwesomeIcon icon={faCopy} />
      </span>
  );
}

TextWithCopyToClipboard.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

TextWithCopyToClipboard.defaultProps = {
  className: '',
};
