import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { withTranslation } from 'react-i18next';
import EditDeleteActionsRenderer from '../tables/EditDeleteActionsRenderer';
import { deleteUserRole } from '../../services/user/userRoleProvider';

import './styles/user-group.css';
import Notifications from '../../utils/Notifications';
import { AUTH_ROUTES } from '../../constants/routes';
import PaginationTable from '../tables/PaginationTable';
import StringRenderer from '../tables/StringRenderer';

class AllUserRolesTable extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      columns: [
        {
          Header: t('tables.role_name'),
          Cell: StringRenderer,
          accessor: 'name',
        },
        {
          Header: t('tables.description'),
          Cell: StringRenderer,
          accessor: 'description',
        },
        {
          Header: t('tables.created'),
          Cell: StringRenderer,
          id: 'created',
          accessor: (d) => Moment(d.created).format('LL'),
        },
        {
          Header: t('tables.updated'),
          Cell: StringRenderer,
          id: 'updated',
          accessor: (d) => (d.updated ? Moment(d.updated).format('LL') : ''),
        },
        {
          id: 'actions',
          Cell: EditDeleteActionsRenderer,
          sortable: false,
          filterable: false,
          onDeleteItem: this.onDeleteItem,
          onViewItem: this.onViewItem,
          securityModule: 'user-roles',
        },
      ],
      defaultSorted: [
        {
          id: 'created',
          desc: true,
        },
      ],
      SweetAlertModal: withReactContent(Swal),
      paginationPageSize: 10,
      minRows: 3,
      tableRef: null,
    };
  }

  onDeleteItem = (itemId) => {
    const { SweetAlertModal } = this.state;
    const { t } = this.props;
    return new Promise((resolve, reject) => {
      SweetAlertModal.fire({
        title: t('message.are_you_sure'),
        showConfirmButton: true,
        showCancelButton: true,
        text: t('message.you_are_about_to_delete_user_role'),
        confirmButtonColor: '#1ab394',
        confirmButtonText: t('message.yes_delete_it'),
        cancelButtonText: t('buttons.cancel'),
        showCloseButton: true,
        icon: 'warning',
      }).then((data) => {
        if (data.isConfirmed) {
          this.confirmDeleteItem(itemId);
        }
      });
    });
  };

  onViewItem = (itemId) => {
    const { USER_ROLES } = AUTH_ROUTES;
    const { history } = this.props;
    const link = `${USER_ROLES}/${itemId}/edit`;

    history.push(link, { userRoleId: itemId });
  };

  confirmDeleteItem = (itemId) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
    });
    deleteUserRole(itemId)
      .then(() => {
        this.tableRef.updateTableData(true);
        this.setState({ isLoading: false });
        Notifications.success(t('message.user_role_deleted'));
        document.activeElement?.blur();
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const { paginationPageSize, minRows, columns, defaultSorted } = this.state;
    const { tableReference, setTableReference, fetchUserRoleList } = this.props;
    return (
      <div>
        <div>
          <PaginationTable
            ref={(elementRef) => {
              this.tableRef = elementRef;
              if (!tableReference && elementRef) {
                setTableReference(elementRef);
              }
            }}
            minRows={minRows}
            defaultSorted={defaultSorted}
            defaultPageSize={paginationPageSize}
            columns={columns}
            getDataFromServer={fetchUserRoleList}
            className="-striped -highlight"
          />
        </div>
      </div>
    );
  }
}

AllUserRolesTable.propTypes = {
  fetchUserRoleList: PropTypes.func.isRequired,
  setTableReference: PropTypes.func.isRequired,
  tableReference: PropTypes.object,
};

export default withTranslation()(withRouter(AllUserRolesTable));
