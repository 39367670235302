import dataProvider from '../dataProvider';
import { profile, user } from '../../constants/endpoints';

export const getUserProfile = (userId) =>
  dataProvider({
    url: profile.profile(userId),
    method: 'GET',
  });

export const createUser = (values) =>
  dataProvider({
    url: user.create(),
    method: 'POST',
    data: values,
  });

export const updateUserProfile = (userId, data) =>
  dataProvider({
    url: profile.profile(userId),
    data,
    method: 'PUT',
  });

export const getUserList = (params) =>
  dataProvider({
    url: user.all(),
    params,
    method: 'GET',
  });

export const getUserSelectItemSource = (params) =>
  dataProvider({
    url: user.itemSource(),
    data: params,
    method: 'POST',
  });

export const getNotEmployedUsersLike = (query) =>
  dataProvider({
    url: user.getNotEmployedUsersLike(query),
    method: 'GET',
  });

export const getNotEmployedUsers = (params) =>
  dataProvider({
    url: user.getNotEmployedUsers(),
    params,
    method: 'GET',
  });

export const getUserByName = (name) =>
  dataProvider({
    url: user.getByName(name),
    method: 'GET',
  });

export const getUserByNameLike = (name) =>
  dataProvider({
    url: user.getByNameLike(name),
    method: 'GET',
  });

export const registration = (values) =>
  dataProvider({
    url: user.registration(),
    method: 'POST',
    data: values,
  });

export const getUserPhoto = (userId) =>
  dataProvider({
    url: user.getPhotoById(userId),
    method: 'GET',
  });

export const updateUserPhoto = (userId, photo) =>
  dataProvider({
    url: user.updateUserPhoto(userId),
    data: photo,
    method: 'PUT',
  });

export const getUserListPagination = (params) =>
  dataProvider({
    url: user.pagination(),
    data: params,
    method: 'POST',
  });

export const getUserById = (itemId) =>
  dataProvider({
    url: user.getById(itemId),
    method: 'GET',
  });

export const deleteUser = (itemId) =>
  dataProvider({
    url: user.delete(itemId),
    method: 'DELETE',
  });

export const updateUser = (userId, data) =>
  dataProvider({
    url: user.update(userId),
    method: 'PUT',
    data,
  });

export const getLanguageSelectItems = () =>
  dataProvider({
    url: user.getLanguageSelectItems(),
    method: 'GET',
  });

export const changePassword = (params) =>
  dataProvider({
    url: user.changePassword(),
    data: params,
    method: 'POST',
  });

export const resetPasswordRequest = (params) =>
  dataProvider({
    url: user.resetPasswordRequest(),
    data: params,
    method: 'POST',
  });

export const validatePasswordResetToken = (data) =>
  dataProvider({
    url: user.validatePasswordResetToken(),
    method: 'GET',
    params: data,
    paramsSerializer(params) {
      const keys = Object.keys(params);
      return `${keys[0]}=${params[keys[0]]}`;
    },
  });

export const resetPassword = (params) =>
  dataProvider({
    url: user.resetPassword(),
    data: params,
    method: 'POST',
  });

export const changeLanguage = (language) =>
  dataProvider({
    url: user.changeLanguage(),
    params: { language },
    method: 'PUT',
  });
