import React, { Component } from 'react';

class PositiveSmile extends Component {
  render() {
    return (
      <div>
        <svg
          width="24px"
          height="25px"
          viewBox="0 0 24 25"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Desktop-HD" transform="translate(-619.000000, -1027.000000)" fill="#6BCAB3">
              <g id="Group-19-Copy" transform="translate(269.000000, 677.666667)">
                <g id="Solid/check-circle" transform="translate(350.000000, 350.000000)">
                  <path
                    d="M24,12 C24,18.6274355 18.6274355,24 12,24 C5.37256452,24 0,18.6274355 0,12 C0,5.37256452 5.37256452,0 12,0 C18.6274355,0 24,5.37256452 24,12 Z M7.4005277,15.4123571 C8.93368513,15.804119 10.4668426,16 12,16 C13.5331574,16 15.0663149,15.804119 16.5994723,15.4123571 C17.2001657,15.2588468 17.8115588,15.6213769 17.9650528,16.2220745 C17.9882596,16.312894 18,16.4062625 18,16.5 L18,16.8873861 C17.9999728,17.5642496 17.5466589,18.1572552 16.893502,18.3348233 C15.2623347,18.7782744 13.6311673,19 12,19 C10.3688341,19 8.73766821,18.7782748 7.10650231,18.3348245 C6.45332562,18.1572628 6,17.5642419 6,16.8873609 L6,16.5 C6,15.8800016 6.502608,15.3773936 7.12260639,15.3773936 C7.21634393,15.3773936 7.30971241,15.389134 7.4005277,15.4123571 Z M8,11 C9.1045695,11 10,10.1045695 10,9 C10,7.8954305 9.1045695,7 8,7 C6.8954305,7 6,7.8954305 6,9 C6,10.1045695 6.8954305,11 8,11 Z M16,11 C17.1045695,11 18,10.1045695 18,9 C18,7.8954305 17.1045695,7 16,7 C14.8954305,7 14,7.8954305 14,9 C14,10.1045695 14.8954305,11 16,11 Z"
                    id="Mask"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

export default PositiveSmile;
