import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';
import EditTenantForm from '../../components/tenant/EditTenantForm';
import { getTenant, updateTenant } from '../../services/tenant/tenantProvider';

class EditTenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      tenant: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.tenants'),
        ref: '/tenant-management/tenants',
        isActive: false,
      },
      {
        name: t('breadcrumbs.tenant'),
        ref: null,
        isActive: true,
      },
    ];
  };

  tenantSetState = (data) => {
    this.setState({
      isLoading: false,
      tenant: {
        ...data,
      },
    });
  };

  componentDidMount() {
    const { params } = this.props.match;

    this.setState({ isLoading: true });
    getTenant(params.tenantId)
      .then((data) => {
        this.tenantSetState(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  updateTenant = (data) => {
    const { t } = this.props;
    updateTenant(data)
      .then(() => {
        this.tenantSetState(data);
        Notifications.success(t('message.tenant_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, tenant } = this.state;
    const { t } = this.props;

    return (
      <>
        <div className="row wrapper">
          <div className="col-lg-6">
            <PageNameContainer name={t('containers.tenant')} breadcrumbs={this.getBreadcrumbs()} />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="col-lg-6">
            <div className="ibox">
              <FormTitle name={t('components.tenant')} />
              <div className="ibox-content clearfix">
                {tenant && <EditTenantForm tenant={tenant} updateTenant={this.updateTenant} />}
              </div>
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </>
    );
  }
}

EditTenant.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      tenantId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default withTranslation()(withRouter(EditTenant));
