import React, { Component } from 'react';

import { faPlus, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  createOrgUnitDef,
  getOrgUnitDefListPagination,
} from '../../services/orgunitdef/orgUnitDefProvider';
import AllOrgUnitDefTable from '../../components/orgunitdef/AllOrgUnitDefTable';
import Loader from '../../components/loader/Loader';
import Notifications from '../../utils/Notifications';
import Modal from '../../components/modal/Modal';
import CreateOrgUnitDefModal from '../../components/orgunitdef/CreateOrgUnitDefModal';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';

class AllOrgUnitDefs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isModalShown: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_organization_unit_types'),
        ref: null,
        isActive: true,
      },
    ];
  };

  fetchOrgUnitDefList = (amount, skip, sorting, filters, callback) => {
    getOrgUnitDefListPagination({
      amount,
      skip,
      sortings: sorting,
      filters,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  openCreateOrgUnitModal = () => {
    this.setState({ isModalShown: true });
  };

  closeModal = () => {
    this.setState({ isModalShown: false });
  };

  createOrgUnit = (values) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
      isModalShown: false,
    });
    createOrgUnitDef(values)
      .then(() => {
        this.state.tableRef.updateTableData(false);
        this.setState({ isLoading: false });
        Notifications.success(t('message.organization_unit_type_created', { name: values.name }));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { isLoading, isModalShown, tableRef } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.all_organization_unit_types')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.all_organization_unit_types')}
              buttons={[
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.openCreateOrgUnitModal,
                  name: t('buttons.create'),
                  icon: faPlus,
                  securityModule: 'org-unit-definition',
                  securityPermission: 'create',
                },
              ]}
            />
            <AllOrgUnitDefTable
              fetchOrgUnitDefList={this.fetchOrgUnitDefList}
              setTableReference={this.setTableReference}
              tableReference={tableRef}
            />
          </div>
        </div>
        <Modal
          header={t('components.create_organization_unit_type')}
          isOpened={isModalShown}
          icon={faSitemap}
          onClose={this.closeModal}
        >
          <CreateOrgUnitDefModal createOrgUnit={this.createOrgUnit} cancel={this.closeModal} />
        </Modal>
        {isLoading && <Loader />}
      </div>
    );
  }
}

AllOrgUnitDefs.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AllOrgUnitDefs);
