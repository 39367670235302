import dataProvider from '../dataProvider';
import { question } from '../../constants/endpoints';

export const getQuestion = (itemId) =>
  dataProvider({
    url: question.getById(itemId),
    method: 'GET',
  });

export const deleteQuestion = (itemId) =>
  dataProvider({
    url: question.delete(itemId),
    method: 'DELETE',
  });

export const createQuestion = (data) =>
  dataProvider({
    url: question.create(),
    method: 'POST',
    data,
  });

export const updateQuestion = (testId, data) =>
  dataProvider({
    url: question.update(testId),
    method: 'PUT',
    data,
  });
export const createQuestions = (data) =>
  dataProvider({
    url: question.createBatch(),
    method: 'POST',
    data,
  });

export const getQuestionTypes = () =>
  dataProvider({
    url: question.types(),
    method: 'GET',
  });
