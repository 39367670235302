import dataProvider from '../dataProvider';

import { feedbackcategory } from '../../constants/endpoints';

export const getFeedbackCategoryList = (params) =>
  dataProvider({
    url: feedbackcategory.all(),
    params,
    method: 'GET',
  });

export const getFeedbackCategory = (feedbackCategoryId) =>
  dataProvider({
    url: feedbackcategory.getById(feedbackCategoryId),
    method: 'GET',
  });

export const createFeedbackCategory = (params) =>
  dataProvider({
    url: feedbackcategory.create(),
    data: params,
    method: 'POST',
  });

export const updateFeedbackCategory = (params) =>
  dataProvider({
    url: feedbackcategory.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteFeedbackCategory = (feedbackCategoryId) =>
  dataProvider({
    url: feedbackcategory.delete(feedbackCategoryId),
    method: 'DELETE',
  });

export const getFeedbackCategoryByName = (name) =>
  dataProvider({
    url: feedbackcategory.getByName(name),
    method: 'GET',
  });

export const getFeedbackCategoryByNameLike = (query) =>
  dataProvider({
    url: feedbackcategory.getByNameLike(query),
    method: 'GET',
  });

export const getFeedbackCategoryPagination = (params) =>
  dataProvider({
    url: feedbackcategory.pagination(),
    data: params,
    method: 'POST',
  });

export const getFeedbackCategorySelectItemSource = (params) =>
  dataProvider({
    url: feedbackcategory.itemSource(),
    data: params,
    method: 'POST',
  });
