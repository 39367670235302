import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormeasureHeatmapWidget from '../../components/analytics/FormeasureHeatmapWidget';
import FormTitle from '../../components/form/FormTitle';
import PageNameContainer from '../../components/page/PageNameContainer';
import './styles/dashboard.css';
import Can from '../../components/security/Can';
import { withTranslation } from 'react-i18next';

class Heatmap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.heatmap'),
        ref: null,
        isActive: true,
      },
    ];
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer name={t('containers.heatmap')} breadcrumbs={this.getBreadcrumbs()} />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="row">
            <div className="col-lg-12">
              <Can
                module="survey-analytics"
                permission="heatmap"
                yes={() => (
                  <div className="ibox">
                    <FormTitle name={t('containers.heatmap')} />
                    <div className="ibox-content fm-no-padding">
                      <FormeasureHeatmapWidget />
                    </div>
                  </div>
                )}
                no={() => null}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Heatmap.propTypes = {
  account: PropTypes.object,
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(Heatmap)));
