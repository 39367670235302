import React, { Component } from 'react';
import UserPhotoUtil from '../../utils/UserPhotoUtil';
import './styles/employee.css';

class EmployeeDisplay extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { employee, onClick } = this.props;
    return (
      <div
        className={`fm-employee-display-info-container ${
          onClick && employee.id ? 'fm-clickable' : ''
        }`}
        onClick={onClick ? () => onClick(employee.id) : null}
      >
        {employee && employee.user && (
          <div
            title={employee.user ? employee.user.displayString : ''}
            className="fm-employee-display-user-avatar-small-box"
          >
            {UserPhotoUtil.renderUserPhotoByLink(employee.user.profileImageLink)}
          </div>
        )}
        {employee && (
          <div className="fm-employee-display-user-info">
            {employee.user && (
              <span className="fm-employee-display-username" title={employee.user.displayString}>
                {employee.user.displayString}
              </span>
            )}
            {employee.employeeJob && (
              <span
                className="fm-employee-display-user-job"
                title={employee.employeeJob.displayString}
              >
                {employee.employeeJob.displayString}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default EmployeeDisplay;
