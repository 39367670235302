import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';

import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faCloudUploadAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  copyToTenant,
  deleteTestTemplate,
  getTestTemplateList,
  importTestTemplate,
  getTestTemplateExpectedImportRows,
} from '../../../services/tests/testTemplateProvider';
import PreviewTest from '../../../components/tests/PreviewSurvey';
import Loader from '../../../components/loader/Loader';
import Notifications from '../../../utils/Notifications';
import { AUTH_ROUTES, CREATE } from '../../../constants/routes';
import PageNameContainer from '../../../components/page/PageNameContainer';
import FormTitle from '../../../components/form/FormTitle';
import Modal from '../../../components/modal/Modal';
import LinkUtil from '../../../utils/LinkUtil';
import ContentLoader from '../../../components/loader/ContentLoader';
import NoDataForm from '../../../components/form/NoDataForm';
import ImportModal from '../../../components/imports/ImportModal';

class AllGlobalSurveys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      testList: null,
      isOpenImportTest: false,
      SweetAlertModal: withReactContent(Swal),
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_global_templates'),
        ref: null,
        isActive: true,
      },
    ];
  };

  closeModal = () => {
    this.setState({ isOpenImportTest: false });
  };

  fetchTestList() {
    getTestTemplateList({
      amount: 999,
      skip: 0,
    })
      .then((data) => {
        this.setState({
          testList: data,
        });
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }

  componentDidMount() {
    this.fetchTestList();
  }

  confirmDeleteItem = (itemId) => {
    const { t } = this.props;

    this.setState({
      isLoading: true,
    });
    deleteTestTemplate(itemId)
      .then(() => {
        this.fetchTestList();
        this.setState({
          isLoading: false,
        });
        Notifications.success(t('message.global_template_removed'));
        document.activeElement?.blur();
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  onDeleteItem = (itemId) => {
    const { t } = this.props;
    const { SweetAlertModal } = this.state;
    return new Promise(() => {
      SweetAlertModal.fire({
        title: t('message.are_you_sure'),
        showConfirmButton: true,
        showCancelButton: true,
        text: t('message.are_you_about_to_delete_global_template'),
        confirmButtonColor: '#1ab394',
        confirmButtonText: t('message.yes_delete_it'),
        cancelButtonText: t('buttons.cancel'),
        showCloseButton: true,
        icon: 'warning',
      }).then((data) => {
        if (data.isConfirmed) {
          this.confirmDeleteItem(itemId);
        }
      });
    });
  };

  showCreateTest = () => {
    this.props.history.push(`${AUTH_ROUTES.GLOBAL_TESTS}${CREATE}`);
  };

  showImportTest = () => {
    this.setState({ isOpenImportTest: true });
  };

  afterImportCallback = () => {
    const { t } = this.props;
    this.fetchTestList();
    this.setState({
      isLoading: false,
      isOpenImportTest: false,
    });
    Notifications.success(t('message.global_template_created'));
  };

  onImportError = (error) => {
    Notifications.error(error);
    this.setState({
      isLoading: false,
    });
  };

  onClickCopy = (id) => {
    const { t } = this.props;
    copyToTenant({ id })
      .then((surveyId) => {
        Notifications.success(
          LinkUtil.buildSurveyLink(
            t('message.survey_template_copied'),
            surveyId,
            t('message.click_me'),
          ),
        );
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const { testList, isLoading, isOpenImportTest } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.all_global_templates')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.all_global_templates', {
                length: testList ? testList.length : 0,
              })}
              buttons={[
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.showCreateTest,
                  name: t('buttons.create'),
                  icon: faPlus,
                  securityModule: 'global-tests',
                  securityPermission: 'create',
                  isProtected: true,
                },
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.showImportTest,
                  name: t('buttons.import'),
                  securityModule: 'global-tests',
                  securityPermission: 'import',
                  isProtected: true,
                },
              ]}
            />
            <div className="ibox-content">
              {testList ? (
                testList.length > 0 ? (
                  testList.map((item, index) => (
                    <PreviewTest
                      key={index}
                      test={item}
                      deleteItem={this.onDeleteItem}
                      linkPrefix={AUTH_ROUTES.GLOBAL_TESTS}
                      sucurityModule="global-tests"
                      isProtected
                      buttons={[
                        {
                          onClick: () => {
                            this.onClickCopy(item.id);
                          },
                          name: t('buttons.copy_to_tenant'),
                          tooltip: t('tooltip.copy_to_tenant_surveys'),
                          icon: faCopy,
                          securityModule: 'global-tests',
                          securityPermission: 'copy-to-tenant',
                        },
                      ]}
                    />
                  ))
                ) : (
                  <NoDataForm />
                )
              ) : (
                <ContentLoader visible />
              )}
            </div>
          </div>
        </div>
        <Modal
          header={t('components.import_global_template')}
          isOpened={isOpenImportTest}
          icon={faCloudUploadAlt}
          onClose={this.closeModal}
        >
          <ImportModal
            performImport={importTestTemplate}
            expectedRowsCall={getTestTemplateExpectedImportRows}
            afterImport={this.afterImportCallback}
            onError={this.onImportError}
            cancel={this.closeModal}
          />
        </Modal>
        {isLoading && <Loader />}
      </div>
    );
  }
}

AllGlobalSurveys.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withTranslation()(AllGlobalSurveys);
