import React, { Component } from 'react';

class NotVerified extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div />;
  }
}

export default NotVerified;
