import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { LOGIN } from '../constants/routes';
import RequestDemoForm from '../components/demo/RequestDemoForm';
import FormeasureHeaderLogo from '../styles/img/landing/header-logo.svg';
import ActPointImage from '../styles/img/landing/act.svg';
import AskPointImage from '../styles/img/landing/ask.svg';
import CheckboxImage from '../styles/img/landing/checkbox.svg';
import ContinuousPointImage from '../styles/img/landing/continuous.svg';
import EasyPointImage from '../styles/img/landing/easy.svg';
import ExactPointImage from '../styles/img/landing/exact.svg';
import FlexiblePointImage from '../styles/img/landing/flexible.svg';
import HolisticPointImage from '../styles/img/landing/holistic.svg';
import MeasurePointImage from '../styles/img/landing/measure.svg';
import TransparentPointImage from '../styles/img/landing/transparent.svg';
import BottomLogoImage from '../styles/img/landing/bottom-logo.svg';
import LandingSubsciptionForm from '../components/landing/LandingSubsciptionForm';
import Carousel from '../components/carousel/Сarousel';

import carouselImage_1 from '../styles/img/landing/carousel/Screenshot_1.png';
import carouselImage_2 from '../styles/img/landing/carousel/Screenshot_2.png';
import carouselImage_3 from '../styles/img/landing/carousel/Screenshot_3.png';
import carouselImage_4 from '../styles/img/landing/carousel/Screenshot_4.png';
import carouselImage_5 from '../styles/img/landing/carousel/Screenshot_5.png';
import carouselImage_6 from '../styles/img/landing/carousel/Screenshot_6.png';

import './styles/landing.css';
import Modal from '../components/modal/Modal';
import { ToastContainer } from 'react-toastify';
import { faLaptop } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRequestDemoModalOpen: false,
      type: null,
    };
  }

  scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  openModalRequestDemo = (type) => {
    this.setState({ isRequestDemoModalOpen: true, type });
  };

  closeModal = () => {
    this.setState({ isRequestDemoModalOpen: false, type: null });
  };

  render() {
    const { isRequestDemoModalOpen, type } = this.state;
    const { t } = this.props;
    return (
      <div className="fm-landing-container">
        <div className="fm-landing-main-box">
          <video
            id="fm-video-wrapper"
            autoPlay
            loop
            wmode="transparent"
            className="fm-landing-image-background"
          >
            <source
              src="https://formeasure-s3-media-bucket.s3.eu-central-1.amazonaws.com/static/title_video.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <Modal
            header={t('component.request_demo')}
            icon={faLaptop}
            isOpened={isRequestDemoModalOpen}
            onClose={this.closeModal}
          >
            <RequestDemoForm requestType={type} onClose={this.closeModal} />
          </Modal>
          {/* <img src={ BackgroundImage } className={ 'fm-landing-image-background' }/> */}
          <div className="fm-landing-header-box">
            <div className="fm-landing-header">
              <div className="fm-landing-logo">
                <img src={FormeasureHeaderLogo} />
              </div>
              <div className="fm-landing-button-group">
                <div
                  className="fm-landing-header-button"
                  onClick={() => {
                    this.scrollToElement('fm-features');
                  }}
                >
                  {t('component.features')}
                </div>
                <div
                  className="fm-landing-header-button"
                  onClick={() => {
                    this.scrollToElement('fm-features');
                  }}
                >
                  {t('component.solution')}
                </div>
                <div
                  className="fm-landing-header-button"
                  onClick={() => {
                    this.scrollToElement('fm-pricing');
                  }}
                >
                  {t('component.pricing')}
                </div>
                <div
                  className="fm-landing-header-button fm-btn-highlighted"
                  onClick={() => {
                    this.scrollToElement('fm-subscriptions');
                  }}
                >
                  {t('component.try_it_free')}
                </div>
                <div className="fm-landing-header-button fm-log-in-btn">
                  <Link className="fm-btn-landing" to={LOGIN}>
                    {t('component.log_in')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="fm-landing-bottom-box">
            <div className="fm-main-message">
              <span className="fm-green-words">{t('component.landing.main.measure')}</span>
              {t('component.landing.main.what_really_matters')}
            </div>
            {/* <div className={'fm-additional-message'}> */}
            {/* Our website and its third-party tools use cookies for analytics and to improve site */}
            {/* performance. */}
            {/* You agree to our use of cookies by closing this banner and/or continuing to use our site. */}
            {/* Visit */}
            {/* Cookie Policy to understand how to change your cookie settings. */}
            {/* </div> */}
            <div className="fm-marketing-buttons-group">
              <div
                className="fm-landing-header-button fm-btn-highlighted"
                onClick={() => {
                  this.scrollToElement('fm-subscriptions');
                }}
              >
                {t('component.try_it_free')}
              </div>
              <div
                className="fm-landing-header-button fm-green-words"
                onClick={() => {
                  this.scrollToElement('fm-pricing');
                }}
              >
                {t('component.request_demo')}
              </div>
            </div>
            <div className="fm-landing-small-text">{t('component.30_days_money_back')}</div>
          </div>
        </div>
        <div className="fm-landing-description-box" id="fm-features">
          <div className="fm-landing-description-part">
            <div className="fm-landing-description-breadcrumb">
              {t('component.landing.employee_management')}
            </div>
            <div className="fm-landing-description-header">
              {t('component.landing.people_platform')}
            </div>
            <div className="fm-landing-description-text">
              <p>{t('component.landing.people_platform.text_1')}</p>
              <p>{t('component.landing.people_platform.text_2')}</p>
            </div>
            <div className="fm-landing-points-group">
              <div className="fm-landing-point">
                <div className="fm-landing-point-img">
                  <img src={HolisticPointImage} />
                </div>
                <div className="fm-landing-point-text">
                  <div className="fm-landing-point-label">{t('component.landing.holistic')}</div>
                  <div className="fm-landing-point-description">
                    {t('component.landing.holistic.description')}
                  </div>
                </div>
              </div>
              <div className="fm-landing-point">
                <div className="fm-landing-point-img">
                  <img src={FlexiblePointImage} />
                </div>
                <div className="fm-landing-point-text">
                  <div className="fm-landing-point-label">{t('component.landing.flexible')}</div>
                  <div className="fm-landing-point-description">
                    {t('component.landing.flexible.description')}
                  </div>
                </div>
              </div>
              <div className="fm-landing-point">
                <div className="fm-landing-point-img">
                  <img src={EasyPointImage} />
                </div>
                <div className="fm-landing-point-text">
                  <div className="fm-landing-point-label">{t('component.landing.easy')}</div>
                  <div className="fm-landing-point-description">
                    {t('component.landing.easy.description')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fm-landing-description-part">
            <div className="fm-landing-carousel">
              <Carousel settings={{}} images={[carouselImage_1, carouselImage_2]} />
            </div>
          </div>
        </div>
        <div className="fm-landing-description-box">
          <div className="fm-landing-description-part">
            <div className="fm-landing-carousel">
              <Carousel settings={{}} images={[carouselImage_3, carouselImage_4]} />
            </div>
          </div>
          <div className="fm-landing-description-part">
            <div className="fm-landing-description-breadcrumb">
              {t('component.landing.employee_engagement')}
            </div>
            <div className="fm-landing-description-header">
              {t('component.landing.experience_and_engagement')}
            </div>
            <div className="fm-landing-description-text">
              <p>{t('component.landing.experience_and_engagement.description')}</p>
            </div>
            <div className="fm-landing-points-group">
              <div className="fm-landing-point">
                <div className="fm-landing-point-img">
                  <img src={AskPointImage} />
                </div>
                <div className="fm-landing-point-text">
                  <div className="fm-landing-point-label">{t('component.landing.ask')}</div>
                  <div className="fm-landing-point-description">
                    {t('component.landing.ask.description')}
                  </div>
                </div>
              </div>
              <div className="fm-landing-point">
                <div className="fm-landing-point-img">
                  <img src={MeasurePointImage} />
                </div>
                <div className="fm-landing-point-text">
                  <div className="fm-landing-point-label">{t('component.landing.measure')}</div>
                  <div className="fm-landing-point-description">
                    {t('component.landing.measure.description')}
                  </div>
                </div>
              </div>
              <div className="fm-landing-point">
                <div className="fm-landing-point-img">
                  <img src={ActPointImage} />
                </div>
                <div className="fm-landing-point-text">
                  <div className="fm-landing-point-label">{t('component.landing.act')}</div>
                  <div className="fm-landing-point-description">
                    {t('component.landing.act.description')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fm-landing-description-box">
          <div className="fm-landing-description-part">
            <div className="fm-landing-description-breadcrumb">
              {t('component.landing.employee_growth')}
            </div>
            <div className="fm-landing-description-header">
              {t('component.landing.performance')}
            </div>
            <div className="fm-landing-description-text">
              <p>{t('component.landing.performance.description')}</p>
            </div>
            <div className="fm-landing-points-group">
              <div className="fm-landing-point">
                <div className="fm-landing-point-img">
                  <img src={TransparentPointImage} />
                </div>
                <div className="fm-landing-point-text">
                  <div className="fm-landing-point-label">{t('component.landing.transparent')}</div>
                  <div className="fm-landing-point-description">
                    {t('component.landing.transparent.description')}
                  </div>
                </div>
              </div>
              <div className="fm-landing-point">
                <div className="fm-landing-point-img">
                  <img src={ContinuousPointImage} />
                </div>
                <div className="fm-landing-point-text">
                  <div className="fm-landing-point-label">{t('component.landing.continuous')}</div>
                  <div className="fm-landing-point-description">
                    {t('component.landing.continuous.description')}
                  </div>
                </div>
              </div>
              <div className="fm-landing-point">
                <div className="fm-landing-point-img">
                  <img src={ExactPointImage} />
                </div>
                <div className="fm-landing-point-text">
                  <div className="fm-landing-point-label">{t('component.landing.accuracy')}</div>
                  <div className="fm-landing-point-description">
                    {t('component.landing.accuracy.description')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fm-landing-description-part">
            <div className="fm-landing-carousel">
              <Carousel settings={{}} images={[carouselImage_5, carouselImage_6]} />
            </div>
          </div>
        </div>
        <div className="fm-landing-pricing-container" id="fm-pricing">
          <div className="fm-landing-pricing-description">
            <div className="fm-landing-pricing-small-text">
              {t('component.landing.find_a_plan')}
            </div>
            <h2 className="fm-landing-pricing-header">
              {t('component.landing.plans_and_pricing')}
            </h2>
            <div className="fm-landing-pricing-text">
              {t('component.landing.grow_better_with_the_right_plan')}
            </div>
          </div>
          <div className="fm-landing-pricing-plan-box">
            <div className="fm-landing-pricing-plan">
              <div className="fm-pricing-plan-name"> {t('component.landing.standard')}</div>
              <div className="fm-pricing-plan-value-box">
                <div className="fm-pricing-plan-value">$ 5.00</div>
                <div className="fm-pricing-plan-value-description">
                  {t('component.landing.per_month_per_user')}
                </div>
              </div>
              <div className="fm-pricing-plan-description">
                <div className="fm-pricing-plan-description-point">
                  <div className="fm-pricing-plan-checkbox">
                    <img src={CheckboxImage} />
                  </div>
                  <div className="fm-pricing-plan-description-text">
                    {t('component.landing.standard.plan.label1')}
                  </div>
                </div>
                <div className="fm-pricing-plan-description-point">
                  <div className="fm-pricing-plan-checkbox">
                    <img src={CheckboxImage} />
                  </div>
                  <div className="fm-pricing-plan-description-text">
                    {t('component.landing.standard.plan.label2')}
                  </div>
                </div>
                <div
                  className="fm-pricing-plan-choose-btn"
                  onClick={() => {
                    this.openModalRequestDemo('STANDARD');
                  }}
                >
                  <span>{t('component.landing.button.get_started')}</span>
                </div>
              </div>
            </div>

            <div className="fm-landing-pricing-plan fm-second-plan">
              <div className="fm-pricing-plan-name">{t('component.landing.enterprise')}</div>
              <div className="fm-pricing-plan-value-box">
                <div className="fm-pricing-plan-value">{t('component.landing.custom')}</div>
              </div>
              <div className="fm-pricing-plan-description">
                <div className="fm-pricing-plan-description-point">
                  <div className="fm-pricing-plan-checkbox">
                    <img src={CheckboxImage} />
                  </div>
                  <div className="fm-pricing-plan-description-text">
                    {t('component.landing.custom.plan.label1')}
                  </div>
                </div>
                <div className="fm-pricing-plan-description-point">
                  <div className="fm-pricing-plan-checkbox">
                    <img src={CheckboxImage} />
                  </div>
                  <div className="fm-pricing-plan-description-text">
                    {t('component.landing.custom.plan.label2')}
                  </div>
                </div>
                <div className="fm-pricing-plan-description-point">
                  <div className="fm-pricing-plan-checkbox">
                    <img src={CheckboxImage} />
                  </div>
                  <div className="fm-pricing-plan-description-text">
                    {t('component.landing.custom.plan.label3')}
                  </div>
                </div>
                <div
                  className="fm-pricing-plan-choose-btn"
                  onClick={() => {
                    this.openModalRequestDemo('CUSTOM');
                  }}
                >
                  <span>{t('component.landing.button.get_started')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fm-landing-subscription-container" id="fm-subscriptions">
          <div className="fm-landing-subscription-description">
            <div className="fm-landing-subscription-small-text">
              {t('component.landing.subscribe')}
            </div>
            <h2 className="fm-landing-subscription-header">
              {t('component.landing.be_the_first_to_know')}
            </h2>
          </div>
          <LandingSubsciptionForm />
        </div>
        <div className="fm-landing-footer-logo">
          <img src={BottomLogoImage} />
        </div>
        <div className="fm-landing-footer">
          <span>(c) Copyright 2020</span> <span className="fm-green-words">formeasure</span>
        </div>
        <ToastContainer theme="colored" />
      </div>
    );
  }
}

export default withTranslation()(Landing);
