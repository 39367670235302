import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { getEmployee, updateEmployee } from '../../services/employee/employeeProvider';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import EditEmployeeDetailsForm from '../../components/employee/EditEmployeeDetailsForm';
import { getNotEmployedUsersLike } from '../../services/user/userProvider';
import { getByNameLike } from '../../services/orgunit/orgUnitProvider';
import { setEmployeeDetails } from '../../services/employee/employeeActions';
import PageNameContainer from '../../components/page/PageNameContainer';
import { getEmployeeJobByNameLike } from '../../services/employeejob/employeeJobProvider';
import { getLocationByNameLike } from '../../services/location/locationProvider';
import { getEmployeeJobLevelByNameLike } from '../../services/employeejoblevel/employeeJobLevelProvider';

class EditEmployeePage extends Component {
  constructor(props) {
    super(props);
    const { dispatchEmployeeDetails } = this.props;

    this.state = {
      isLoading: false,
    };
    dispatchEmployeeDetails({
      id: '',
      userId: '',
      orgStructureUnitId: '',
      tenantId: '',
      username: '',
      firstName: '',
      lastName: '',
      orgStructureUnitName: '',
      created: '',
      updated: '',
    });
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_employees'),
        ref: '/org/employee',
        isActive: false,
      },
      {
        name: t('breadcrumbs.employee'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { location } = this.props.history;
    const { dispatchEmployeeDetails } = this.props;
    this.setState({ isLoading: true });
    getEmployee(location.state.employeeId)
      .then((employee) => {
        dispatchEmployeeDetails(employee);
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  updateEmployee = (data) => {
    const { t } = this.props;
    this.setState({ isLoading: true });
    updateEmployee(data)
      .then(() => {
        const { location } = this.props.history;
        const { dispatchEmployeeDetails } = this.props;
        getEmployee(location.state.employeeId).then((updatedEmployee) => {
          dispatchEmployeeDetails(updatedEmployee);
          this.setState({ isLoading: false });
        });
        Notifications.success(t('message.employee_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading } = this.state;
    const { employee, t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.employees')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-6">
              {employee && employee.id && (
                <EditEmployeeDetailsForm
                  employee={employee}
                  updateEmployee={this.updateEmployee}
                  getEmployeeJobs={getEmployeeJobByNameLike}
                  getEmployeeJobLevels={getEmployeeJobLevelByNameLike}
                  getLocations={getLocationByNameLike}
                  getUsers={getNotEmployedUsersLike}
                  getOrgUnits={getByNameLike}
                />
              )}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

EditEmployeePage.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        employeeId: PropTypes.number.isRequired,
      }),
    }).isRequired,
  }).isRequired,
  employee: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  dispatchEmployeeDetails: PropTypes.func,
  dispatchUsers: PropTypes.func,
  dispatchOrgUnits: PropTypes.func,
};

const mapStateToProps = (state) => ({
  employee: state.employee.employee,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchEmployeeDetails: (employee) => {
    dispatch(setEmployeeDetails({ employee }));
  },
});

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditEmployeePage)),
);
