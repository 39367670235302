import React, { Component } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { Avatar } from '@mui/material';
import ArrayUtil from '../../utils/ArrayUtil';
import AvatarGroup from '../ui-elements/AvatarGroup';
import StringUtil from '../../utils/StringUtil';
import UrlUtil from '../../utils/UrlUtil';

class SubordinateReviewersRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.original.id,
      reviewers: props.row.reviewers,
    };
    this.onAddReviewer = props.column.onAddReviewer;
  }

  componentDidUpdate(props, prevState, ss) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
    if (!ArrayUtil.isEqual(props.row.reviewers, prevState.reviewers)) {
      this.setState({ reviewers: props.row.reviewers });
    }
  }

  onClickBtn = () => {
    const { id } = this.state;
    const { reviewers } = this.state;
    this.onAddReviewer(id, reviewers);
  };

  render() {
    const { t, value } = this.props;
    const { reviewers } = this.state;

    return (
      <div className="fm-user-list-preview">
        {reviewers && reviewers.length > 0 && (
          <AvatarGroup
            max={5}
            sx={{
              '& .MuiAvatar-root': { width: 36, height: 36, fontSize: 16 },
            }}
          >
            {reviewers.map((r, index) => (
              <Avatar
                title={r.displayString}
                sx={{
                  width: 36,
                  height: 36,
                  fontSize: 16,
                  bgcolor: StringUtil.stringToColor(r.displayString),
                }}
                src={UrlUtil.buildImageSrcLink(r.profileImageLink)}
                key={`fm-avatar-${r.id}-${index}`}
              >
                {StringUtil.getFirstLetters(r.displayString)}
              </Avatar>
            ))}
          </AvatarGroup>
        )}
        <div onClick={this.onClickBtn} className="fm-add-reviewer-box">
          <div className="fm-add-circle-btn">
            <FontAwesomeIcon icon={faPlus} />
          </div>
          {value.length === 0 && (
            <div className="fm-add-reviewer-label">
              <span>{t('components.add_reviewer')}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(SubordinateReviewersRenderer);
