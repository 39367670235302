import _ from 'lodash';

const regexs = {
  password: /(?=.*)/i,
  email: /^(?!\.)^["'A-Z0-9._%+-]*(["'A-Z0-9_%+-])@(\w+[\w+-]+\.)*[\w+-]+(\.(A-Z0-9){2,4})?$/i,
};

const Validation = {
  isValueDefined: (value) =>
    !(typeof value === 'undefined' || value === null || value === undefined),
  required: (value) => {
    if (_.isNumber(value)) {
      return undefined;
    }
    if (_.isDate(value)) {
      return undefined;
    }

    return value && ((_.isString(value) && value.trim()) || value === true)
      ? undefined
      : 'Required';
  },
  maxLength: (value, maxLength) =>
    value && value.length > maxLength
      ? `The value is too long (max length: ${maxLength})`
      : undefined,
  email: (value) => (value && !regexs.email.test(value) ? 'Invalid email address' : false),
  password: (value) => (value && !regexs.password.test(value) ? 'Invalid password' : undefined),
  makePasswordRegExp: (min = '', max = '') => {
    const patterns = {
      numeric: '0-9',
      special: '!@#$%^&*',
      latin_lower: 'a-z',
      latin_upper: 'A-Z',
    };
    let regexString = '';
    const rules = [];
    const range = `{${min},${max}}`; // Разрешённый диапазон для длины строки
    for (const rule in patterns) {
      // Обрабатываем входящий массив из ВСЕХ правил для строки
      if (Object.getOwnPropertyDescriptor(patterns, rule)) {
        rules.push(patterns[rule]); // Запоминаем правила
        // Формируем последовательность из шаблонов `(?=.*[%s])`
        // Она проверит обязательное присутствие всех символов из входящего набора
        regexString += `(?=.*[${patterns[rule]}])`;
      }
    }
    // Добавляем в хвост набор из ВСЕХ разрешённых символов и разрешённую длину строки
    regexString += `[${rules.join('')}]${range}`;
    // Собираем всё в одно регулярное выражение
    return new RegExp(regexString, 'g');
  },
};

export default Validation;
