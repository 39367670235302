import React, { Component } from 'react';

import { withRouter } from 'react-router';
import { Radar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { faAngleDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faComments } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  getFeedbackAssignmentManagerSummary,
  getFeedbackAssignmentsListByUserId,
} from '../../services/feedbackassignment/feedbackAssignmentProvider';
import { getFeedbackAssignmentReviewerByFeedbackAssignmentIdUserId } from '../../services/feedbackassignmentreviewer/feedbackAssignmentReviewerProvider';
import { getAnswersByFeedbackAssignmentIdUserId } from '../../services/feedbackcriteriaanswer/feedbackCriteriaAnswerProvider';
import { getFeedbackCriteriaByFeedbackId } from '../../services/feedbackcriteria/feedbackCriteriaProvider';
import StringIdGenerator from '../../utils/StringIdGenerator';
import Notifications from '../../utils/Notifications';
import FeedbackAssignmentSummaryCommentsModal from './FeedbackAssignmentSummaryCommentsModal';
import ManagerPassingForm from './managerpassing/ManagerPassingForm';
import Modal from '../modal/Modal';
import { AUTH_ROUTES } from '../../constants/routes';
import EmployeeInfoHeader from '../employee/EmployeeInfoHeader';
import ContentLoader from '../loader/ContentLoader';

class FeedbackAssignmentSummaryForm extends Component {
  constructor(props) {
    super(props);
    const colors = [
      'rgba(244,86,78,0.25)',
      'rgba(249,150,10,0.25)',
      'rgba(16,192,249,0.25)',
      'rgba(143,116,84,0.25)',
      'rgba(95,244,78,0.25)',
      'rgba(10,249,173,0.25)',
      'rgba(16,85,249,0.25)',
      'rgba(143,84,139,0.25)',
    ];

    const radarOptions = {
      responsive: true,
      scales: {
        r: {
          grid: {
            circular: true,
          },
          suggestedMax: 5,
          beginAtZero: true,
          ticks: {
            stepSize: 1,
            display: true,
          },
          pointLabels: {
            font: {
              size: 14,
            },
            color: [
              'rgba(244,86,78,1)',
              'rgba(249,150,10,1)',
              'rgba(16,192,249,1)',
              'rgba(143,116,84,1)',
              'rgba(95,244,78,1)',
              'rgba(10,249,173,1)',
              'rgba(16,85,249,1)',
              'rgba(143,84,139,1)',
            ],
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          callbacks: {
            label(context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.r !== null) {
                label += new Intl.NumberFormat('en-US', { maximumFractionDigits: 1 }).format(
                  context.parsed.r,
                );
              }
              return label;
            },
            title: (context) => context[0].label,
          },
        },
      },
    };

    this.state = {
      colors,
      radarOptions,
      isCommentsOpen: false,
      answerErrors: [],
      isFeedbackAssignmentOptionsOpen: false,
    };
  }

  handleHideFeedbackAssignmentOptions = (event) => {
    event.stopPropagation();
    if (
      event.target.classList.contains('fm-fb-assignment-box') ||
      event.target.closest('.fm-fb-assignment-box')
    ) {
      return;
    }
    this.setState({ isFeedbackAssignmentOptionsOpen: false });
  };

  componentDidMount() {
    const { location } = this.props;
    const { state } = location;
    this.refreshPage(state && state.isManagerSummaryShow);
    document.addEventListener('mousedown', this.handleHideFeedbackAssignmentOptions);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleHideFeedbackAssignmentOptions);
  }

  openCommentsPopup = (comments) => {
    this.setState({
      criteriaComments: comments,
      isCommentsOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      criteriaComments: null,
      isCommentsOpen: false,
    });
  };

  openManagerSummaryBox = () => {
    this.setState({ isManagerSummaryShow: true });
  };

  closeManagerSummaryBox = () => {
    this.setState({ isManagerSummaryShow: false });
  };

  refreshPageCloseModal = () => {
    this.refreshPage(true);
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.feedbackAssignment, prevProps.feedbackAssignment)) {
      this.refreshPage(false);
    }
  }

  refreshPage = (isManagerSummaryShow) => {
    this.setState({ isLoading: true });

    const { feedbackAssignment, managerId } = this.props;
    const { radarOptions } = this.state;

    if (!feedbackAssignment) {
      this.setState({ isLoading: false });
      return;
    }

    const charGen = new StringIdGenerator();
    const promises = [];
    promises.push(getFeedbackCriteriaByFeedbackId(feedbackAssignment.feedbackId));
    promises.push(getFeedbackAssignmentManagerSummary(feedbackAssignment.id));
    promises.push(getAnswersByFeedbackAssignmentIdUserId(feedbackAssignment.id, managerId));
    promises.push(
      getFeedbackAssignmentReviewerByFeedbackAssignmentIdUserId(feedbackAssignment.id, managerId),
    );
    promises.push(getFeedbackAssignmentsListByUserId(feedbackAssignment.revieweeId));

    Promise.all(promises)
      .then(([feedbackCriteria, dataStats, answers, reviewer, userFeedbackAssignments]) => {
        let self = 0;
        let selfCounter = 0;
        let average = 0;
        let averageCounter = 0;
        const labels = [];
        const managerData = [];
        const averageData = [];
        const selfData = [];
        const datasets = [];
        const comments = [];
        if (dataStats) {
          let { min } = dataStats[0];
          let { max } = dataStats[0];
          for (let i = 0; i < dataStats.length; i++) {
            const comment = {
              size: 0,
              selfComment: null,
              managerComment: null,
              comments: [],
            };

            if (min > dataStats[0].min) {
              min = dataStats[0].min;
            }

            if (max < dataStats[0].max) {
              min = dataStats[0].max;
            }

            dataStats[i].letter = charGen.next();
            labels.push(dataStats[i].letter);
            // statistic
            if (dataStats[i].avgScore) {
              average += dataStats[i].avgScore;
              averageCounter++;
              averageData.push(parseFloat(dataStats[i].avgScore).toFixed(1));
            }
            if (dataStats[i].managerScore) {
              average += dataStats[i].managerScore;
              averageCounter++;
              managerData.push(parseFloat(dataStats[i].managerScore).toFixed(1));
            }
            if (dataStats[i].selfScore) {
              self += dataStats[i].selfScore;
              selfCounter++;
              selfData.push(parseFloat(dataStats[i].selfScore).toFixed(1));
            }
            // comments
            if (dataStats[i].managerComment) {
              comment.size++;
              comment.managerComment = dataStats[i].managerComment;
            }
            if (dataStats[i].selfComment) {
              comment.size++;
              comment.selfComment = dataStats[i].selfComment;
            }

            if (dataStats[i].comments && dataStats[i].comments.length > 0) {
              comment.size += dataStats[i].comments.length;
              comment.comments.push(dataStats[i].comments);
            }
            comments.push(comment);
          }
          if (!min) {
            min = 0;
          }
          if (!max) {
            max = 5;
          }

          radarOptions.scales.r.suggestedMax = max;
          if (min < 0) {
            radarOptions.scales.r.min = min;
            radarOptions.scales.r.beginAtZero = false;
          }
        }

        if (averageData.length > 0) {
          datasets.push({
            label: 'Overall',
            backgroundColor: 'rgba(52,183,124,0.2)',
            borderColor: '#34B77C',
            pointBackgroundColor: '#34B77C',
            pointStyle: 'circle',
            data: averageData,
          });
        }
        if (selfData.length > 0) {
          datasets.push({
            label: 'Self',
            borderDash: [5, 5],
            backgroundColor: 'rgba(249,150,10,0.2)',
            borderColor: '#F9960A',
            pointStyle: 'circle',
            pointBackgroundColor: '#F9960A',
            data: selfData,
          });
        }
        if (managerData.length > 0) {
          datasets.push({
            label: 'Manager',
            backgroundColor: 'rgb(143,116,84,0.2)',
            borderColor: '#8F7454',
            pointStyle: 'circle',
            pointBackgroundColor: '#8F7454',
            data: managerData,
          });
        }

        const hasMain = false;
        const hasAdditional = false;
        const feedbackAnswers = [];

        _.forEach(feedbackCriteria, (value, index) => {
          let a = answers.find((v) => v.feedbackCriteriaId === value.id);

          if (!a) {
            a = {
              tenantId: value.tenantId,
              feedbackCriteriaId: value.id,
              feedbackCriteriaDescriptionId: null,
              feedbackAssignmentReviewerId: managerId,
              value: 0,
              comment: null,
              action: 'create',
            };
          }
          value.letter = dataStats[index].letter;
          a.isMain = value.isMain;
          feedbackAnswers.push(a);
        });

        this.setState({
          summary: dataStats,
          selfAverage: selfCounter > 0 ? (self / selfCounter).toFixed(1) : 'N/A',
          average: averageCounter > 0 ? (average / averageCounter).toFixed(1) : 'N/A',
          radarData: {
            labels,
            datasets,
          },
          comments,
          hasMain,
          hasAdditional,
          isManagerSummaryShow: !!isManagerSummaryShow,
          criteria: feedbackCriteria,
          feedbackAnswers,
          reviewer,
          userFeedbackAssignments,
          isLoading: false,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { t, employee, feedbackAssignment, managerId } = this.props;
    const {
      isFeedbackAssignmentOptionsOpen,
      userFeedbackAssignments,
      summary,
      selfAverage,
      average,
      criteria,
      colors,
      radarOptions,
      radarData,
      comments,
      isCommentsOpen,
      criteriaComments,
      isManagerSummaryShow,
      answerErrors,
      feedbackAnswers,
      reviewer,
      isLoading,
    } = this.state;

    let preparedUserFeedbackAssignments = [];
    if (userFeedbackAssignments) {
      preparedUserFeedbackAssignments = userFeedbackAssignments.filter(
        (a) => feedbackAssignment.id !== a.id,
      );
    }

    return (
      <div className="fm-fb-summary">
        {employee && <EmployeeInfoHeader employee={employee} />}
        <div className="fm-fb-summary-results">
          <div className="fm-fb-scaled-results">
            <div className="fm-scaled-result-label">{t('components.scaled_results')}</div>
            <div className="fm-scaled-result-chart">
              <div className="chart-box">
                {radarData && <Radar type="radar" options={radarOptions} data={radarData} />}
              </div>
            </div>
          </div>
          <div className="fm-fb-summary-container">
            <div className="fm-overall-summary-box">
              <div
                className={`fm-fb-assignment-box ${
                  preparedUserFeedbackAssignments && preparedUserFeedbackAssignments.length > 0
                    ? 'fb-pointer'
                    : ''
                }`}
                onClick={(event) => {
                  if (feedbackAssignment) {
                    event.stopPropagation();
                    this.setState({
                      isFeedbackAssignmentOptionsOpen: !this.state.isFeedbackAssignmentOptionsOpen,
                    });
                  }
                }}
              >
                {feedbackAssignment && (
                  <div>
                    <span>{feedbackAssignment.feedback.name}</span>
                    <div className="fm-fb-assignment-deadline">
                      {moment.utc(feedbackAssignment.deadline).format('LL')}
                    </div>
                  </div>
                )}
                {preparedUserFeedbackAssignments && preparedUserFeedbackAssignments.length > 0 && (
                  <>
                    <FontAwesomeIcon icon={faAngleDown} />
                    {isFeedbackAssignmentOptionsOpen && (
                      <div className="fm-fb-assignments-options">
                        {userFeedbackAssignments
                          .filter((a) => feedbackAssignment.id !== a.id)
                          .map((a) => (
                            <div
                              className="fm-fb-assignment-option"
                              key={`feedback-assignment-${a.id}`}
                              onClick={() => {
                                const { history } = this.props;
                                const { FEEDBACK } = AUTH_ROUTES;
                                const link = `${FEEDBACK.MANAGER_SUMMARY}/${a.id}`;
                                history.push(link, { feedbackAssignmentId: a.id });
                              }}
                            >
                              <div>{a.feedbackName}</div>
                              <div className="fm-fb-assignment-deadline">
                                {moment.utc(a.deadline).format('LL')}
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="fm-fb-overall-score-box">
                <div>
                  <div className="fm-fb-overall-score-box-value">{average}</div>
                  <div className="fm-fb-overall-score-box-label">
                    {t('components.overall_score')}
                  </div>
                </div>
              </div>
              <div className="fm-fb-self-score-box">
                <div>
                  <div className="fm-fb-self-score-box-value">{selfAverage}</div>
                  <div className="fm-fb-self-score-box-label">{t('components.self_average')}</div>
                </div>
              </div>
              <div className="fm-fb-one-2-one-box">
                <FontAwesomeIcon icon={faComments} />
                <span>{t('components.create_1_to_1_meeting')}</span>
              </div>
            </div>
            <div className="fm-criteria-summary-box">
              <div className="fm-criteria-header">
                <div className="fm-criteria-summary-criteria">{t('components.criteria')}</div>
                <div className="fm-criteria-summary-score fm-criteria-summary-overall-score">
                  {t('components.overall')}
                </div>
                <div className="fm-criteria-summary-score fm-criteria-summary-self-score">
                  {t('components.self')}
                </div>
                <div className="fm-criteria-summary-score">
                  {reviewer && reviewer.assignmentStatus !== 'Finished' ? (
                    <div
                      className="fm-criteria-summary-add-manager-review"
                      onClick={this.openManagerSummaryBox}
                    >
                      <div className="fm-add-manager-summary-btn">
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                      <div className="fm-add-manager-summary-label">
                        {reviewer && reviewer.assignmentStatus === 'Draft'
                          ? t('components.edit')
                          : t('components.add')}{' '}
                        {t('components.summary')}
                      </div>
                    </div>
                  ) : (
                    <div className="fm-criteria-summary-add-manager-review fm-criteria-summary-manager-score">
                      <div className="fm-add-manager-summary-label">
                        {t('components.manger_summary')}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {summary &&
                summary.map((criteria, index) => (
                  <div key={index} className="fm-criteria-row">
                    <div className="fm-cs-criteria-name-box">
                      <div
                        className="fm-cs-criteria-id"
                        style={{ backgroundColor: colors[index % colors.length] }}
                      >
                        <span>{criteria.letter}</span>
                      </div>
                      <div className="fm-cs-criteria-name">{criteria.criteria}</div>
                      {comments && comments[index] && comments[index].size > 0 && (
                        <div
                          className="fm-criteria-comments"
                          onClick={() => {
                            this.openCommentsPopup(comments[index]);
                          }}
                        >
                          <FontAwesomeIcon icon={faComment} />
                        </div>
                      )}
                    </div>
                    <div className="fm-cs-criteria-average">
                      {criteria.avgScore ? criteria.avgScore.toFixed(1) : '-'}
                    </div>
                    <div className="fm-cs-criteria-self">
                      {criteria.selfScore ? criteria.selfScore.toFixed(1) : '-'}
                    </div>
                    <div className="fm-cs-criteria-manager">
                      {criteria.managerScore ? criteria.managerScore.toFixed(1) : '-'}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {!employee && <p className="text-muted text-center">{t('message.no_data')}</p>}
        {!summary && isLoading && <ContentLoader visible />}
        {isManagerSummaryShow && feedbackAnswers && criteria && (
          <ManagerPassingForm
            employee={employee}
            feedbackAssignment={feedbackAssignment}
            colors={colors}
            criteria={criteria}
            answerErrors={answerErrors}
            feedbackAnswers={feedbackAnswers}
            managerId={managerId}
            reviewer={reviewer}
            closeManagerSummaryBox={this.closeManagerSummaryBox}
            refreshPage={this.refreshPageCloseModal}
          />
        )}
        <Modal
          header={t('components.comments')}
          isOpened={isCommentsOpen}
          icon={faComments}
          onClose={this.closeModal}
        >
          <FeedbackAssignmentSummaryCommentsModal
            comments={criteriaComments}
            cancel={this.closeModal}
          />
        </Modal>
      </div>
    );
  }
}

FeedbackAssignmentSummaryForm.propTypes = {
  feedbackAssignment: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
};

export default withTranslation()(withRouter(FeedbackAssignmentSummaryForm));
