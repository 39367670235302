import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Notifications from '../../../utils/Notifications';
import { getTestById } from '../../../services/tests/testProvider';
import FormTitle from '../../form/FormTitle';
import { getEmployeeByUserId } from '../../../services/employee/employeeProvider';
import EmployeeInfoHeader from '../../employee/EmployeeInfoHeader';
import SurveyAssignmentResult from './SurveyAssignmentResult';

function InnerForm(props) {
  const { testAssignment } = props;

  const [survey, setSurvey] = useState({});
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    const promises = [
      getTestById(testAssignment.testId),
      getEmployeeByUserId(testAssignment.userId),
    ];

    Promise.all(promises)
      .then(([test, employee]) => {
        setSurvey(test);
        setEmployee(employee);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  return (
    <div className="ibox">
      <FormTitle name={survey.displayString} />
      <div className="ibox-content clearfix fm-no-padding">
        {employee && <EmployeeInfoHeader employee={employee} />}
        <SurveyAssignmentResult testAssignmentId={testAssignment.id} type="User" />
      </div>
    </div>
  );
}

InnerForm.propTypes = {
  testAssignment: PropTypes.object.isRequired,
};
export default withTranslation()(InnerForm);
