import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './styles/orgunitdef-table.css';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import { withTranslation } from 'react-i18next';
import Input from '../react-hook-form-inputs/Input';
import TextArea from '../react-hook-form-inputs/TextArea';
import MaterialCheckbox from '../react-hook-form-inputs/MaterialCheckbox';
import Can from '../security/Can';

function InnerForm(props) {
  const { t, orgUnitDef, updateOrgUnitDef } = props;

  const schema = Yup.object().shape({
    name: Yup.string().required(t('message.organization_unit_type_name_required')),
  });

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: orgUnitDef.name,
      description: orgUnitDef.description,
      sortOrder: orgUnitDef.sortOrder,
      visible: orgUnitDef.visible,
      created: orgUnitDef.created ? moment.utc(orgUnitDef.created).format('LLL') : '',
      updated: orgUnitDef.updated ? moment.utc(orgUnitDef.updated).format('LLL') : '',
    },
  });
  const { handleSubmit, control } = form;

  const onSubmit = (data) => {
    updateOrgUnitDef({
      ...orgUnitDef,
      name: data.name,
      description: data.description,
      sortOrder: data.sortOrder,
      visible: data.visible,
    });
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Controller
        control={control}
        id="name"
        name="name"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <Input
            name={name}
            placeholder={t('fields.organization_unit_type_name')}
            label={t('fields.organization_unit_type_name')}
            form={form}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        control={control}
        id="description"
        name="description"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <TextArea
            name={name}
            placeholder={t('fields.description')}
            label={t('fields.description')}
            form={form}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        control={control}
        id="sortOrder"
        name="sortOrder"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <Input
            name={name}
            placeholder={t('fields.sort_order')}
            label={t('fields.sort_order')}
            type="number"
            form={form}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        id="visible"
        control={control}
        name="visible"
        defaultValue={false}
        render={({ onChange, onBlur, value, name }) => (
          <MaterialCheckbox
            name={name}
            checked={value}
            label={t('fields.visible')}
            form={form}
            checkedElementColor="#34B77C"
            icon={<CircleUnchecked />}
            checkedIcon={<CircleCheckedFilled />}
            onBlur={onBlur}
            onChange={(e) => onChange(e.target.checked)}
            value={value || false}
          />
        )}
      />
      <Controller
        control={form.control}
        id="created"
        name="created"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <Input
            name={name}
            label={t('fields.created')}
            onChange={onChange}
            form={form}
            onBlur={onBlur}
            value={value}
            disabled
            placeholder={t('fields.date_format_mm_dd_yyyy')}
          />
        )}
      />
      <Controller
        control={form.control}
        id="updated"
        name="updated"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <Input
            name={name}
            label={t('fields.updated')}
            onChange={onChange}
            form={form}
            onBlur={onBlur}
            value={value}
            disabled
            placeholder={t('fields.date_format_mm_dd_yyyy')}
          />
        )}
      />
      <Can
        module="org-unit-definition"
        permission="edit"
        yes={() => (
          <button type="submit" className="btn fm-btn-primary block pull-right">
            {t('buttons.save')}
          </button>
        )}
        no={() => null}
      />
    </form>
  );
}

InnerForm.propTypes = {
  orgUnit: PropTypes.object,
  updateOrgUnitDef: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
