import {
  SET_TEST_ASSIGNMENT_DETAILS,
  SET_TEST_ASSIGNMENT_TESTS,
  SET_TEST_ASSIGNMENT_USERS,
} from '../../constants/actions';

export const setTestAssignmentDetails = (data) => ({
  type: SET_TEST_ASSIGNMENT_DETAILS,
  testAssignment: data.testAssignment,
});

export const setUsers = (data) => ({
  type: SET_TEST_ASSIGNMENT_USERS,
  users: data.users,
});

export const setTests = (data) => ({
  type: SET_TEST_ASSIGNMENT_TESTS,
  tests: data.tests,
});
