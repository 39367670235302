const StringUtil = {
  separateCapital: (string, separator) =>
    string ? string.replace(/([A-Z])/g, separator ? `${separator}$1` : ' $1').trim() : '',
  getFirstLetters: (string) =>
    string
      ? string
          .split(' ')
          .map((n) => n[0])
          .join('')
      : '',
  stringToColor: (string) => {
    let hash = 0;
    /* eslint-disable no-bitwise */
    for (let i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (let i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  },
};

export default StringUtil;
