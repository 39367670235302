import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { faChevronUp, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import fileDownload from 'js-file-download';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {
  createTestTemplate,
  exportTestTemplate,
  getTestTemplateById,
  getTestTemplateQuestions,
  getTestTemplateTypes,
  updateTestTemplate,
} from '../../../services/tests/testTemplateProvider';
import { getQuestionTemplateTypes } from '../../../services/tests/questionTemplateProvider';
import {
  getQuestionClassificationTemplateByNameLike,
  getQuestionClassificationTemplateSelectItemSource,
} from '../../../services/questionclassificationtemplate/questionClassificationTemplateProvider';

import FormTitle from '../../../components/form/FormTitle';
import PageNameContainer from '../../../components/page/PageNameContainer';
import SurveyCommonInformationForm from '../../../components/tests/surveywidget/SurveyCommonInformationForm';
import SurveyQuestionForm from '../../../components/tests/surveywidget/SurveyQuestionForm';
import SurveyScalesForm from '../../../components/tests/surveywidget/SurveyScalesForm';
import SurveySummaryForm from '../../../components/tests/surveywidget/SurveySummaryForm';
import Notifications from '../../../utils/Notifications';
import Can from '../../../components/security/Can';
import Loader from '../../../components/loader/Loader';

import '../styles/tenant-tests.scss';
import { getScaleTemplatesByTestId } from '../../../services/scale/scaleTemplateProvider';
import { AUTH_ROUTES } from '../../../constants/routes';

class ViewEditGlobalSurveyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showScrollTop: false,
      forms: [],
      editSubPageNumber: 1,
      currentForm: null,
      surveyTypes: [],
      questionTypes: [],
      objects: [],
      SweetAlertModal: withReactContent(Swal),
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_global_templates'),
        ref: '/global-tests/all',
        isActive: false,
      },
      {
        name: t('breadcrumbs.global_survey_template'),
        ref: null,
        isActive: true,
      },
    ];
  };

  handleScroll = () => {
    document.documentElement.scrollTop > 0 || document.body.scrollTop > 0
      ? this.setState({ showScrollTop: true })
      : this.setState({ showScrollTop: false });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.getSurveyInformation(this.props.match.params.globalTestId);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.scrollTo(0, 0);
    }
  };

  showValidationWarning = (onSubmit, removeErrors) => {
    const { t } = this.props;
    const { SweetAlertModal } = this.state;
    SweetAlertModal.fire({
      title: t('message.are_you_sure'),
      showConfirmButton: true,
      showCancelButton: true,
      text: t('message.navigation_with_not_valid_fields'),
      confirmButtonColor: '#1ab394',
      confirmButtonText: t('message.yes_navigate'),
      cancelButtonText: t('buttons.cancel'),
      showCloseButton: true,
      icon: 'warning',
    }).then((data) => {
      if (data.isConfirmed) {
        onSubmit(removeErrors);
      }
    });
  };

  getSurveyInformation = (surveyId) => {
    if (!surveyId) {
      const promises = [getTestTemplateTypes(), getQuestionTemplateTypes()];
      this.setState({ isLoading: true });
      Promise.all(promises)
        .then(([_surveyTypes, _questionTypes]) => {
          this.setState({
            isLoading: false,
            objects: {
              survey: {},
              scales: [],
              questions: [],
            },
            surveyTypes: _surveyTypes ? _surveyTypes.items : [],
            questionTypes: _questionTypes ? _questionTypes.items : [],
          });
        })
        .catch((error) => {
          Notifications.error(error);
          this.setState({
            isLoading: false,
            testDetails: null,
          });
        });
    } else {
      const promises = [
        getTestTemplateById(surveyId),
        getTestTemplateTypes(),
        getQuestionTemplateTypes(),
        getTestTemplateQuestions(surveyId),
        getScaleTemplatesByTestId(surveyId),
      ];

      this.setState({ isLoading: true });
      Promise.all(promises)
        .then(([_survey, _surveyTypes, _questionTypes, _questions, _scales]) => {
          const surveyType = _surveyTypes.items.filter((v) => _survey.testType === v.value);
          this.setState({
            surveyTypes: _surveyTypes ? _surveyTypes.items : [],
            questionTypes: _questionTypes ? _questionTypes.items : [],
            objects: {
              survey: _survey
                ? {
                    ..._survey,
                    testType: {
                      value: surveyType[0].value,
                      label: surveyType[0].label,
                    },
                    testQuestionClassification:
                      _survey.testQuestionClassification.length > 0
                        ? _survey.testQuestionClassification.map((v) => ({
                            ...v,
                            key: v.key || v.id,
                            value: v.value || v.id,
                            label: v.label || v.name,
                            color: v.color,
                          }))
                        : [],
                  }
                : {},
              scales: _scales
                ? _.map(_scales, (scale) => ({
                    ...scale,
                    rangeFrom: scale.rangeFrom || 0,
                    rangeTo: scale.rangeTo || 0,
                    description: scale.description || '',
                    descriptionForUser: scale.descriptionForUser || '',
                    color: scale.color || '',
                    isHidden: true,
                    action: '',
                    questionClassification: scale.questionClassification
                      ? {
                          ...scale.questionClassification,
                          key: scale.questionClassification.key || scale.questionClassification.id,
                          value:
                            scale.questionClassification.value || scale.questionClassification.id,
                          label:
                            scale.questionClassification.label || scale.questionClassification.name,
                          color: scale.questionClassification.color,
                        }
                      : {},
                  }))
                : [],
              questions: _questions
                ? _.map(_questions, (question) => ({
                    ...question,
                    action: '',
                    isHidden: true,
                    questionType: _questionTypes
                      ? _.find(_questionTypes.items, (q) => q.value === question.questionType)
                      : 'SingleChoice', // Single choice type by default
                    answers: _.map(question.answers, (answer) => ({
                      ...answer,
                      action: '',
                    })),
                  }))
                : [],
            },
            isLoading: false,
          });
        })
        .catch((error) => {
          Notifications.error(error);
          this.setState({
            isLoading: false,
            testDetails: null,
          });
        });
    }
  };

  setForm = (name, form) => {
    const { forms } = this.state;
    forms[name] = form;
    const me = this;

    me.setState({ currentForm: name });
  };

  populateObjects = (removeErrors) => {
    const { t } = this.props;
    const { forms, objects, currentForm } = this.state;
    const values = forms[currentForm].getValues();
    const { errors } = forms[currentForm];

    switch (currentForm) {
      case 'questions':
        if (!removeErrors) {
          objects.questions = values.questions ? values.questions : [];
        } else if (errors.questions) {
          const indexes = [];
          _.forEach(errors.questions, (q, index) => {
            if (q) {
              indexes.push(index);
            }
          });
          objects.questions = [];
          _.forEach(values.questions, (q, index) => {
            if (!indexes.includes(index)) {
              objects.questions.push(q);
            }
          });
        }
        _.forEach(objects.questions, (q) => {
          q.questionClassifications = JSON.parse(q.questionClassifications);
        });
        break;
      case 'scales':
        if (!removeErrors) {
          objects.scales = values.scales ? values.scales : [];
        } else if (errors.scales) {
          const indexes = [];
          _.forEach(errors.scales, (s, index) => {
            if (s) {
              indexes.push(index);
            }
          });
          objects.scales = [];
          _.forEach(values.scales, (s, index) => {
            if (!indexes.includes(index)) {
              objects.scales.push(s);
            }
          });
        }
        _.forEach(objects.scales, (s) => {
          if (s.questionClassification) {
            s.questionClassification = JSON.parse(s.questionClassification);
          }
        });
        objects.survey = {
          ...objects.survey,
          testQuestionClassification: objects.survey.testQuestionClassification
            ? _.map(objects.survey.testQuestionClassification, (tag) => {
                if (_.has(values, ['survey', 'testQuestionClassification'])) {
                  const _tag = values.survey.testQuestionClassification.find((o) => {
                    if (isNaN(o.value) && isNaN(tag.value)) {
                      return o.value === tag.value && o.label === tag.label;
                    }
                    return parseInt(o.value) === parseInt(tag.value) && o.label === tag.label;
                  });
                  if (_tag) {
                    return {
                      ...tag,
                      ..._tag,
                    };
                  }
                }
                return tag;
              })
            : [],
        };
        break;
      case 'summary':
        break;
      case 'survey':
        objects.survey = {
          ...objects.survey,
          ...values,
          testQuestionClassification: values.testQuestionClassification
            ? _.map(values.testQuestionClassification, (tag) => {
                const _tag =
                  objects.survey.testQuestionClassification &&
                  objects.survey.testQuestionClassification.find(
                    (o) => parseInt(o.id) === parseInt(tag.id) && o.name === tag.name,
                  );
                if (!!tag.value || !!tag.label) {
                  return {
                    ..._tag,
                    ...tag,
                    id: Number.isInteger(+tag.value) ? tag.value : null,
                    name: tag.label,
                  };
                }
                return { ..._tag, ...tag };
              })
            : [],
        };
        break;
      default:
        throw t('message.form_not_implemented');
    }
  };

  onClickPrevious = () => {
    const { forms, editSubPageNumber, currentForm } = this.state;
    const me = this;

    if (!forms[currentForm]) {
      me.setState({ editSubPageNumber: editSubPageNumber - 1 });
      return;
    }
    const navigate = (removeErrors) => {
      if (editSubPageNumber > 1) {
        me.populateObjects(removeErrors);
        me.setState({ editSubPageNumber: editSubPageNumber - 1 });
      }
    };

    forms[currentForm].trigger().then((isValid) => {
      if (!isValid) {
        this.showValidationWarning(navigate, true);
      } else {
        navigate(false);
      }
    });
  };

  onClickNext = () => {
    const { forms, editSubPageNumber, currentForm } = this.state;
    const me = this;
    if (!forms[currentForm]) {
      me.setState({ editSubPageNumber: editSubPageNumber + 1 });
      return;
    }
    const navigate = (removeErrors) => {
      if (editSubPageNumber < 4) {
        me.populateObjects(removeErrors);
        me.setState({ editSubPageNumber: editSubPageNumber + 1 });
      }
    };
    forms[currentForm].trigger().then((isValid) => {
      if (!isValid && currentForm === 'survey') {
        return;
      }
      if (!isValid) {
        this.showValidationWarning(navigate, true);
      } else {
        navigate(false);
      }
    });
  };

  onClickProgressPoint = (number) => {
    const { forms, currentForm } = this.state;
    const me = this;
    if (!forms[currentForm]) {
      me.setState({ editSubPageNumber: number });
      return;
    }
    const navigate = (removeErrors) => {
      if (number < 5 && number > 0) {
        me.populateObjects(removeErrors);
        me.setState({ editSubPageNumber: number });
      }
    };

    forms[currentForm].trigger().then((isValid) => {
      if (!isValid && currentForm === 'survey') {
        return;
      }
      if (!isValid) {
        this.showValidationWarning(navigate, true);
      } else {
        navigate(false);
      }
    });
  };

  onClickSave = () => {
    const { objects } = this.state;
    const { survey } = objects;
    const { questions } = objects;
    const { scales } = objects;

    this.setState({
      isLoading: true,
    });

    const preparedQuestions = _.map(questions, (q) => {
      if (q.action !== 'delete' && q.action !== 'create') {
        _.forEach(q.answers, (a) => {
          if (a.action === 'create' || a.action === 'update' || a.action === 'delete') {
            q.action = 'update';
            return false;
          }
        });
      }
      if (q.action === '') {
        return;
      }
      return {
        action: q.action,
        objectType: q.objectType,
        object: {
          id: q.id,
          testId: survey.id,
          tenantId: survey.tenantId,
          answerRangeFrom: q.answerRangeFrom,
          answerRangeTo: q.answerRangeTo,
          question: q.question,
          questionType: q.questionType.value,
          description: q.description,
          sortOrder: q.sortOrder,
          questionClassifications: q.questionClassifications,
          createdByUserId: q.createdByUserId,
          batchAnswers: _.map(q.answers, (a) => {
            if (a.action === '') {
              return;
            }
            return {
              action: a.action,
              objectType: a.objectType,
              object: {
                id: a.id,
                tenantId: survey.tenantId,
                questionId: q.id,
                answer: a.answer,
                rate: a.rate,
                createdByUserId: a.createdByUserId,
                isCorrect: a.isCorrect,
                sortOrder: a.sortOrder,
              },
            };
          }).filter((a) => a != null),
        },
      };
    }).filter((q) => q != null);

    const preparedScales = _.map(scales, (s) => {
      if (s.action === '') {
        return;
      }
      return {
        action: s.action,
        objectType: s.objectType,
        object: {
          id: s.id,
          testId: survey.id,
          questionClassification: s.questionClassification,
          rangeTo: s.rangeTo,
          rangeFrom: s.rangeFrom,
          isReverseScaling: s.isReverseScaling,
          color: s.color,
          label: s.label,
          description: s.description,
          descriptionForUser: s.descriptionForUser,
          sortOrder: s.sortOrder,
        },
      };
    }).filter((s) => s != null);

    const preparedSurvey = {
      ...survey,
      testType: survey.testType.value,
      batchQuestions: preparedQuestions,
      batchScales: preparedScales,
    };
    const { t, match } = this.props;
    if (survey.id) {
      updateTestTemplate(preparedSurvey.id, preparedSurvey)
        .then(() => {
          this.getSurveyInformation(match.params.globalTestId);
          Notifications.success(t('message.global_survey_template_updated'));
        })
        .catch((error) => {
          Notifications.error(error);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      createTestTemplate(preparedSurvey)
        .then(() => {
          const { history } = this.props;
          this.setState(
            {
              isLoading: false,
            },
            () => {
              history.push(`${AUTH_ROUTES.GLOBAL_TESTS}/all`, {});
              Notifications.success(t('message.global_survey_template_created'));
            },
          );
        })
        .catch((error) => {
          Notifications.error(error);
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  onClickCancel = () => {
    const { history } = this.props;
    history.push(`${AUTH_ROUTES.GLOBAL_TESTS}`);
  };

  onClickExport = () => {
    const { objects } = this.state;
    exportTestTemplate(objects.survey.id)
      .then((data) => {
        fileDownload(data, `TestTemplate_${objects.survey.title}.xlsx`);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  render() {
    const { isLoading, showScrollTop, editSubPageNumber, objects, surveyTypes, questionTypes } =
      this.state;
    const { t } = this.props;

    return (
      <>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.global_survey_template')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content fm-survey-wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="ibox">
                <FormTitle
                  name={
                    objects.survey && objects.survey.title
                      ? objects.survey.title
                      : t('components.create_new_global_survey_template')
                  }
                  buttons={[
                    {
                      size: 'btn-xs',
                      importance: 'fm-btn-third fm-btn-square',
                      onClick: this.onClickExport,
                      title: t('buttons.export'),
                      icon: faFileExcel,
                      securityModule: 'global-tests',
                      securityPermission: 'export',
                    },
                  ]}
                />
                <div className="fm-survey-wizard-progress-bar ibox-content">
                  <div className="fm-survey-wizard-progress-container">
                    <div className="fm-survey-wizard-progress-bar-empty" />
                    <div
                      className="fm-survey-wizard-progress-bar-fill"
                      style={{ width: `${(editSubPageNumber - 1) * 33.333}%` }}
                    />
                  </div>
                  <div className="fm-survey-wizard-progress-bar-points-container">
                    <div className="fm-survey-wizard-progress-bar-point">
                      <div
                        className={`fm-survey-wizard-progress-bar-point-number ${
                          editSubPageNumber >= 1 ? 'fm-activated-point' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(1)}
                      >
                        1
                      </div>
                      <div
                        className={`fm-survey-wizard-progress-bar-point-label ${
                          editSubPageNumber === 1 ? 'fm-activated-label' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(1)}
                      >
                        <span>{t('components.common_information')}</span>
                      </div>
                    </div>
                    <div className="fm-survey-wizard-progress-bar-point">
                      <div
                        className={`fm-survey-wizard-progress-bar-point-number ${
                          editSubPageNumber >= 2 ? 'fm-activated-point' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(2)}
                      >
                        2
                      </div>
                      <div
                        className={`fm-survey-wizard-progress-bar-point-label ${
                          editSubPageNumber === 2 ? 'fm-activated-label' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(2)}
                      >
                        <span>{t('components.questions')}</span>
                      </div>
                    </div>
                    <div className="fm-survey-wizard-progress-bar-point">
                      <div
                        className={`fm-survey-wizard-progress-bar-point-number ${
                          editSubPageNumber >= 3 ? 'fm-activated-point' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(3)}
                      >
                        3
                      </div>
                      <div
                        className={`fm-survey-wizard-progress-bar-point-label ${
                          editSubPageNumber === 3 ? 'fm-activated-label' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(3)}
                      >
                        <span>{t('components.scales')}</span>
                      </div>
                    </div>
                    <div className="fm-survey-wizard-progress-bar-point">
                      <div
                        className={`fm-survey-wizard-progress-bar-point-number ${
                          editSubPageNumber >= 4 ? 'fm-activated-point' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(4)}
                      >
                        4
                      </div>
                      <div
                        className={`fm-survey-wizard-progress-bar-point-label ${
                          editSubPageNumber === 4 ? 'fm-activated-label' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(4)}
                      >
                        <span>{t('components.summary')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {objects.survey && editSubPageNumber === 1 && (
                  <SurveyCommonInformationForm
                    setForm={this.setForm}
                    surveyTypes={surveyTypes}
                    survey={objects.survey}
                    getQuestionClassificationDefault={
                      getQuestionClassificationTemplateSelectItemSource
                    }
                    getQuestionClassification={getQuestionClassificationTemplateByNameLike}
                  />
                )}
                {objects.survey && editSubPageNumber === 2 && (
                  <SurveyQuestionForm
                    setForm={this.setForm}
                    survey={objects.survey}
                    questionTypes={questionTypes}
                    questions={objects.questions}
                  />
                )}
                {objects.survey && editSubPageNumber === 3 && (
                  <SurveyScalesForm
                    setForm={this.setForm}
                    survey={objects.survey}
                    scales={objects.scales}
                  />
                )}
                {objects.survey && editSubPageNumber === 4 && (
                  <SurveySummaryForm
                    setForm={this.setForm}
                    survey={objects.survey}
                    scales={objects.scales}
                    questions={objects.questions}
                  />
                )}
                <div className="ibox-content fm-btn-box">
                  <div className="btn-group pull-left">
                    <button
                      onClick={this.onClickPrevious}
                      type="button"
                      disabled={editSubPageNumber === 1}
                      className={`fm-btn-secondary ${
                        editSubPageNumber === 1 ? 'fm-btn-disabled' : ''
                      }`}
                    >
                      {t('buttons.previous')}
                    </button>
                  </div>
                  <div className="btn-group pull-right">
                    <button onClick={this.onClickCancel} type="button" className="btn fm-btn-third">
                      {t('buttons.cancel')}
                    </button>
                    {editSubPageNumber === 4 ? (
                      <Can
                        module="tests"
                        permission="edit"
                        yes={() => (
                          <button
                            onClick={this.onClickSave}
                            type="button"
                            className="fm-btn-primary"
                          >
                            {t('buttons.save')}
                          </button>
                        )}
                        no={() => null}
                      />
                    ) : (
                      <button onClick={this.onClickNext} type="button" className="fm-btn-primary">
                        {t('buttons.next')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
        {showScrollTop && (
          <button
            type="button"
            onClick={(e) => {
              this.scrollToTop(e);
            }}
            className="animated fadeIn"
            id="fm-scroll-questions-top"
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        )}
      </>
    );
  }
}

ViewEditGlobalSurveyPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withRouter(ViewEditGlobalSurveyPage));
