import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AllQuestionClassifications from '../../containers/tests/questionclassification/AllQuestionClassifications';
import EditQuestionClassificationPage from '../../containers/tests/questionclassification/EditQuestionClassification';

import { AUTH_ROUTES, QUESTION_CLASSIFICATION_ID } from '../../constants/routes';

class QuestionClassificationRoutes extends Component {
  render() {
    const { match } = this.props;

    return (
      <div>
        <Route exact path={`${match.url}`} component={AllQuestionClassifications} />
        <Route
          path={`${match.url}${QUESTION_CLASSIFICATION_ID}`}
          render={({ match }) => <QuestionClassificationInnerRouting match={match} />}
        />
      </div>
    );
  }
}

QuestionClassificationRoutes.propTypes = {
  match: PropTypes.object,
};

export default QuestionClassificationRoutes;

class QuestionClassificationInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let questionClassificationRouting;
    if (match.params.questionClassificationId) {
      switch (match.params.questionClassificationId) {
        default:
          questionClassificationRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.TEST.QUESTION_CLASSIFICATION}${QUESTION_CLASSIFICATION_ID}`}
              component={AllQuestionClassifications}
            />
          );
      }
    }

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.TEST.QUESTION_CLASSIFICATION}${QUESTION_CLASSIFICATION_ID}/edit`}
          component={EditQuestionClassificationPage}
        />
        {questionClassificationRouting}
      </div>
    );
  }
}

QuestionClassificationInnerRouting.propTypes = {
  match: PropTypes.object,
};
