import React, { Component } from 'react';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import { withTranslation } from 'react-i18next';
import DashboardWidget from '../DashboardWidget';
import { getDashbortChartsData } from '../../../services/analytics/analyticsProvider';
import Notifications from '../../../utils/Notifications';

class DiversityInclusionWidget extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      name: t('widgets.diversity_and_inclusion'),
      systemName: 'diversity-and-inclusion',
      loaded: false,
      // ,
      // participation: {
      //     labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      //     data: [50, 68, 46, 89, 78, 34],
      //     dynamic: 90
      //
      // },
      // score: {
      //     labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      //     data: [3.2, 3.9, 3.5, 3.4, 3.6, 3.4],
      //     suggestion: "A transformative triple‑camera system that adds tons of capability without complexity. An unprecedented leap in battery life. And chip that doubles down on machine learning and pushes the boundaries of what a smartphone can do."
      // },
      // benchmark: {
      //     labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      //     data: [0, 0, -0.1, 0.079, 0.18, 0.21],
      //     dynamic: -0.2
      // }
    };
  }

  componentDidMount() {
    const { name } = this.state;

    getDashbortChartsData(name)
      .then((data) => {
        if (!data) {
          return;
        }
        this.setState({
          benchmark: data.benchmark,
          score: data.average,
          participation: data.participation,
          loaded: true,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { name, participation, score, benchmark, loaded, systemName } = this.state;

    return (
      <div className="ibox">
        {loaded && (
          <DashboardWidget
            name={name}
            systemName={systemName}
            score={score}
            benchmark={benchmark}
            participation={participation}
          />
        )}
      </div>
    );
  }
}

DiversityInclusionWidget.propTypes = {};
export default withTranslation()(DiversityInclusionWidget);
