import dataProvider from '../dataProvider';

import { feedbackassignmentreviewer } from '../../constants/endpoints';

export const getFeedbackAssignmentReviewerList = (params) =>
  dataProvider({
    url: feedbackassignmentreviewer.all(),
    params,
    method: 'GET',
  });

export const getFeedbackAssignmentReviewer = (feedbackAssignmentReviewerId) =>
  dataProvider({
    url: feedbackassignmentreviewer.getById(feedbackAssignmentReviewerId),
    method: 'GET',
  });

export const createFeedbackAssignmentReviewer = (params) =>
  dataProvider({
    url: feedbackassignmentreviewer.create(),
    data: params,
    method: 'POST',
  });

export const updateFeedbackAssignmentReviewer = (params) =>
  dataProvider({
    url: feedbackassignmentreviewer.update(params.id),
    data: params,
    method: 'PUT',
  });

export const batchUpdateFeedbackAssignmentReviewers = (reviewers) =>
  dataProvider({
    url: feedbackassignmentreviewer.batchUpdate(),
    data: reviewers,
    method: 'POST',
  });

export const deleteFeedbackAssignmentReviewer = (feedbackAssignmentReviewerId) =>
  dataProvider({
    url: feedbackassignmentreviewer.delete(feedbackAssignmentReviewerId),
    method: 'DELETE',
  });

export const getFeedbackAssignmentReviewerItemSource = (params) =>
  dataProvider({
    url: feedbackassignmentreviewer.itemSource(),
    params,
    method: 'GET',
  });

export const rejectFeedbackAssignmentReviewer = (reviewerId) =>
  dataProvider({
    url: feedbackassignmentreviewer.rejectFeedbackAssignmentReviewer(reviewerId),
    method: 'POST',
  });

export const getFeedbackAssignmentReviewersByAssignmentId = (feedbackAssignmentId) =>
  dataProvider({
    url: feedbackassignmentreviewer.getByFeedbackAssignmentId(feedbackAssignmentId),
    method: 'GET',
  });

export const getFeedbackAssignmentProfilesByManagerIdPagination = (params) =>
  dataProvider({
    url: feedbackassignmentreviewer.paginationFeedbackAssignmentProfilesByManagerId(
      params.managerId,
    ),
    data: params,
    method: 'POST',
  });

export const getAllEmployeesFeedbackAssignmentProfilesPagination = (params) =>
  dataProvider({
    url: feedbackassignmentreviewer.paginationAllEmployeesFeedbackAssignmentProfiles(),
    data: params,
    method: 'POST',
  });

export const getActiveAssignmentReviewerIds = (feedbackAssignmentReviewerId) =>
  dataProvider({
    url: feedbackassignmentreviewer.getActiveAssignmentReviewerIds(feedbackAssignmentReviewerId),
    method: 'GET',
  });

export const getFeedbackAssignmentRequestByReviewerIdPagination = (params) =>
  dataProvider({
    url: feedbackassignmentreviewer.paginationByReviewerId(params.userId),
    data: params,
    method: 'POST',
  });

export const getFeedbackAssignmentReviewerByFeedbackAssignmentIdUserId = (
  feedbackAssignmentId,
  userId,
) =>
  dataProvider({
    url: feedbackassignmentreviewer.getByFeedbackAssignmentIdUserId(feedbackAssignmentId, userId),
    method: 'GET',
  });
