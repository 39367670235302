import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { getTestQuestionIds } from '../../../services/tests/testProvider';
import {
  getTestAssignmentDetails,
  patchTestAssignment,
} from '../../../services/testassignment/testAssignmentProvider';
import Notifications from '../../../utils/Notifications';
import PageNameContainer from '../../../components/page/PageNameContainer';
import PassingTestForm from '../../../components/tests/passing/PassingTestForm';

class PassingTestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      testAssignment: null,
      questionIds: [],
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.take_survey'),
        ref: '/test-assignments/user',
        isActive: false,
      },
      {
        name: t('breadcrumbs.test_passing'),
        ref: null,
        isActive: true,
      },
    ];
  };

  changeTestAssignmentStatus = (status) => {
    const { testAssignment } = this.state;
    testAssignment.status = status;

    patchTestAssignment(testAssignment.id, [
      {
        op: 'replace',
        path: '/status',
        value: status,
      },
    ])
      .then(() => {
        this.setState({ testAssignment });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  componentDidMount() {
    getTestAssignmentDetails(this.props.match.params.testAssignmentId)
      .then((testAssignment) => {
        getTestQuestionIds(testAssignment.testId)
          .then((questionIds) => {
            this.setState({
              isLoading: false,
              questionIds,
              testAssignment,
            });
          })
          .catch((error) => {
            Notifications.error(error);
            this.setState({ isLoading: false });
          });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { testAssignment, questionIds } = this.state;

    return (
      <div className="fm-passing-page">
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={testAssignment ? testAssignment.testTitle : ''}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content row min-vh-80">
          <div className="col-lg-12">
            <PassingTestForm
              questionIds={questionIds}
              testAssignment={testAssignment}
              changeTestAssignmentStatus={this.changeTestAssignmentStatus}
            />
          </div>
        </div>
      </div>
    );
  }
}

PassingTestPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PassingTestPage);
