import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { getUserDetails, login } from '../../services/account/authProvider';
import { changeLanguage, registration } from '../../services/user/userProvider';
import { loginAction, setUserDetails } from '../../services/account/authActions';
import { initializeMenu } from '../../services/menu/menuActions';
import { AUTH_ROUTES, LOGIN } from '../../constants/routes';
import AuthForm from '../../components/auth/AuthForm';
import Loader from '../../components/loader/Loader';
import Notifications from '../../utils/Notifications';

import './styles/auth.css';

class AuthContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    const { t } = this.props;
    const locState = this.props.history.location.state;
    const locationChanged = this.props.location.pathname === '/login';
    if (locationChanged && locState && locState.isRegistrationSuccessful) {
      Notifications.success(t('message.user_registered'));
      this.props.history.replace('/login', { isRegistrationSuccessful: false });
    }
  }

  loginHandler = (authData, errorCallback) => {
    const { dispatchLogin, dispatchSetMenuStructure, dispatchUserDetails, history, i18n } =
      this.props;
    const { tenant } = authData;

    const { rememberMe } = authData;

    this.setState({ isLoading: true });

    login(authData)
      .then((data) => {
        dispatchLogin(data.accessToken, data.refreshToken, tenant, data.accessTokenExpirationDate);

        getUserDetails()
          .then((userData) => {
            dispatchUserDetails(userData);
            dispatchSetMenuStructure(userData.permissions);
            if (userData.user && userData.user.language) {
              i18n.changeLanguage(userData.user.language);
              moment.locale(userData.user.language);
            } else {
              const _language = window.navigator
                ? window.navigator.language ||
                  window.navigator.systemLanguage ||
                  window.navigator.userLanguage
                : 'ru';
              changeLanguage(_language.substr(0, 2).toLowerCase()).then(null);
            }
            history.push(AUTH_ROUTES.HOME);
            localStorage.setItem('rememberMe', rememberMe);
            if (tenant) {
              localStorage.setItem('lastTenant', tenant);
            }
          })
          .catch((error) => {
            Notifications.error(error);
            errorCallback && errorCallback();
            this.setState({ isLoading: false });
          });
      })
      .catch((error) => {
        Notifications.error(error);
        errorCallback && errorCallback();
        this.setState({ isLoading: false });
      });
  };

  registrationHandler = (values, errorCallback) => {
    const { history } = this.props;

    registration(values)
      .then(() => {
        const loginLink = `${LOGIN}${values.tenant ? `/${values.tenant}` : ''}`;
        history.push(loginLink, { isRegistrationSuccessful: true });
      })
      .catch((error) => {
        Notifications.error(error);
        errorCallback && errorCallback();
      });
  };

  render() {
    const { isLoading } = this.state;
    const { match } = this.props;
    const { params, url } = match;

    return (
      <div className="fm-auth-page">
        <AuthForm
          loginHandler={this.loginHandler}
          registrationHandler={this.registrationHandler}
          authState={url}
          tenant={params.tenant}
          lastTenant={localStorage.getItem('lastTenant')}
        />
        {isLoading && <Loader />}
        <ToastContainer theme="colored" />
      </div>
    );
  }
}

AuthContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  dispatchLogin: PropTypes.func,
  dispatchSetMenuStructure: PropTypes.func,
  dispatchUserDetails: PropTypes.func,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => ({
  dispatchLogin: (accessToken, refreshToken, tenant, accessTokenExpirationDate) => {
    dispatch(
      loginAction({
        accessToken,
        refreshToken,
        tenant,
        accessTokenExpirationDate,
      }),
    );
  },
  dispatchSetMenuStructure: (permissions) => {
    dispatch(initializeMenu({ permissions }));
  },
  dispatchUserDetails: (userDetails) => {
    dispatch(setUserDetails({ userDetails }));
  },
});

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthContainer)),
);
