import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faStar } from '@fortawesome/free-regular-svg-icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import OngoingFeedbackTipsModal from './OngoingFeedbackTipsModal';
import OngoingFeedbackPraiseModal from './OngoingFeedbackPraiseModal';
import { useNewModal } from '../NewModal';

function InnerForm({ countPraise, countAdvice, account }) {
  const { t } = useTranslation();
  const { openModal, closeModal } = useNewModal();

  const openOngoingFeedbackTipsModalOpen = useCallback(() => {
    openModal({ component: <OngoingFeedbackTipsModal account={account} closeModal={closeModal} /> });
  }, []);

  const openOngoingFeedbackPraiseModalOpen = useCallback(() => {
    openModal({ component: <OngoingFeedbackPraiseModal account={account} closeModal={closeModal} /> });
  }, []);

  return (
    <div className="row wrapper">
      <div className="col-lg-12">
        <div className="fm-home-feedback-widget">
          <div className="fm-home-feedback" onClick={openOngoingFeedbackPraiseModalOpen}>
            <div className="fm-home-widget-header">
              <div className="fm-home-feedback-circle">
                <FontAwesomeIcon size="2x" icon={faStar} />
              </div>
              <span>{countPraise}</span>
            </div>
            <div className="fm-home-widget-advice">
              <span>{t('dashboard.praise_received_in_the_last_180_days')}</span>
            </div>
          </div>
          <div className="fm-home-feedback" onClick={openOngoingFeedbackTipsModalOpen}>
            <div className="fm-home-widget-header">
              <div className="fm-home-feedback-circle">
                <FontAwesomeIcon size="2x" icon={faComments} />
              </div>
              <span>{countAdvice}</span>
            </div>
            <div className="fm-home-widget-advice">
              <span>{t('dashboard.tips_received_in_the_last_180_days')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InnerForm.propTypes = {
  countPraise: PropTypes.number,
  countAdvice: PropTypes.number,
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default connect(mapStateToProps)(InnerForm);
