import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Moment from 'moment';
import { withTranslation } from 'react-i18next';
import EditDeleteActionsRenderer from '../tables/EditDeleteActionsRenderer';

import Notifications from '../../utils/Notifications';
import { AUTH_ROUTES } from '../../constants/routes';
import PaginationTable from '../tables/PaginationTable';
import StringRenderer from '../tables/StringRenderer';
import { deleteCaseReport } from '../../services/bugtracking/CaseReportProvider';
import UserPreviewRenderer from '../tables/UserPreviewRenderer';
import StringUtil from '../../utils/StringUtil';

class AllCaseReportsTable extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.state = {
      columns: [
        {
          Header: t('tables.title'),
          Cell: StringRenderer,
          accessor: 'title',
        },
        {
          Header: t('tables.created_by'),
          id: 'user',
          accessor: (d) => d.userPreview,
          Cell: UserPreviewRenderer,
          filterable: false,
        },
        {
          Header: t('tables.description'),
          Cell: StringRenderer,
          accessor: 'description',
        },
        {
          Header: t('tables.status'),
          id: 'status',
          Cell: StringRenderer,
          accessor: (d) => StringUtil.separateCapital(d.status, ' '),
        },
        {
          Header: t('tables.severity'),
          Cell: StringRenderer,
          accessor: 'severity',
        },
        {
          Header: t('tables.report_type'),
          Cell: StringRenderer,
          accessor: 'reportType',
        },
        {
          Header: t('tables.created'),
          id: 'created',
          Cell: StringRenderer,
          accessor: (d) => Moment(d.created).format('LL'),
        },
        {
          Header: t('tables.updated'),
          id: 'updated',
          Cell: StringRenderer,
          accessor: (d) => (d.updated ? Moment(d.updated).format('LL') : ''),
        },
        {
          id: 'actions',
          Cell: EditDeleteActionsRenderer,
          sortable: false,
          filterable: false,
          onDeleteItem: this.onDeleteItem,
          onViewItem: this.onViewItem,
          securityModule: 'bug-tracking',
        },
      ],
      defaultSorted: [
        {
          id: 'created',
          desc: true,
        },
      ],
      SweetAlertModal: withReactContent(Swal),
      paginationPageSize: 10,
      minRows: 3,
      tableRef: null,
    };
  }

  onDeleteItem = (itemId) => {
    const { SweetAlertModal } = this.state;
    const { t } = this.props;
    return new Promise((resolve, reject) => {
      SweetAlertModal.fire({
        title: t('message.are_you_sure'),
        showConfirmButton: true,
        showCancelButton: true,
        text: t('message.you_are_about_to_delete_case_report'),
        confirmButtonColor: '#1ab394',
        confirmButtonText: t('message.yes_delete_it'),
        cancelButtonText: t('buttons.cancel'),
        showCloseButton: true,
        icon: 'warning',
      }).then((data) => {
        if (data.isConfirmed) {
          this.confirmDeleteItem(itemId);
        }
      });
    });
  };

  onViewItem = (itemId) => {
    const { CASE_MANAGEMENT } = AUTH_ROUTES;
    const { history } = this.props;
    const link = `${CASE_MANAGEMENT.BUG_TRACKING}/${itemId}/edit`;

    history.push(link, { caseReportId: itemId });
  };

  confirmDeleteItem = (itemId) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
    });
    deleteCaseReport(itemId)
      .then(() => {
        this.tableRef.updateTableData(true);
        this.setState({ isLoading: false });
        Notifications.success(t('message.case_report_deleted'));
        document.activeElement?.blur();
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const { paginationPageSize, minRows, columns, defaultSorted } = this.state;
    const { tableReference, setTableReference, fetchCaseReportList } = this.props;
    return (
      <div>
        <div>
          <PaginationTable
            ref={(elementRef) => {
              this.tableRef = elementRef;
              if (!tableReference && elementRef) {
                setTableReference(elementRef);
              }
            }}
            minRows={minRows}
            defaultSorted={defaultSorted}
            defaultPageSize={paginationPageSize}
            columns={columns}
            getDataFromServer={fetchCaseReportList}
            className="-striped -highlight"
          />
        </div>
      </div>
    );
  }
}

AllCaseReportsTable.propTypes = {
  fetchCaseReportList: PropTypes.func.isRequired,
  setTableReference: PropTypes.func.isRequired,
  tableReference: PropTypes.object,
};

export default withTranslation()(withRouter(AllCaseReportsTable));
