import { createStore } from 'redux';
import reducers from './reducers';

function reduxStore(initialState) {
  return createStore(
    reducers,
    initialState,
    window.devToolsExtension && window.devToolsExtension(),
  );
}

export default reduxStore;
