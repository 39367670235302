import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faPen } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import Can from '../security/Can';

class AssignmentPeriodActionsRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = { id: props.original.id };
    this.onDeleteItem = props.column.onDeleteItem;
    this.onViewItem = props.column.onViewItem;
    this.onViewSummary = props.column.onViewSummary;
  }

  deleteItem = () => {
    this.onDeleteItem(this.state.id);
  };

  viewItem = () => {
    this.onViewItem(this.state.id);
  };

  viewSummary = () => {
    this.onViewSummary(this.state.id);
  };

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  render() {
    const { original, t } = this.props;
    const { responseRate } = original;

    return (
      <div className="fm-btn-group">
        <Can
          module="test-assignment-periods"
          permission="edit"
          yes={() => (
            <button
              className="btn fm-table-btn fm-btn-xs-edit"
              onClick={this.viewItem}
              title={t('buttons.edit')}
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
          )}
          no={() => null}
        />
        <Can
          module="test-assignment-periods"
          permission="delete"
          yes={() => (
            <button
              className="btn fm-table-btn fm-btn-xs-delete"
              onClick={this.deleteItem}
              title={t('buttons.delete')}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          )}
          no={() => null}
        />
        {!!responseRate && responseRate > 0 && (
          <Can
            module="test-assignment-periods"
            permission="view-summary"
            yes={() => (
              <button
                className="btn fm-table-btn fm-btn-xs-summary"
                onClick={this.viewSummary}
                title={t('buttons.summary')}
              >
                <FontAwesomeIcon icon={faChartLine} />
              </button>
            )}
            no={() => null}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(AssignmentPeriodActionsRenderer);
