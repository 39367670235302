import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../feedbacks/styles/feedback-table.css';
import Moment from 'moment';
import { withTranslation } from 'react-i18next';
import PaginationTable from '../tables/PaginationTable';
import LabelLinkRenderer from '../tables/LabelLinkRenderer';
import UserRenderer from '../tables/UserRenderer';
import FeedbackRequestTypeRenderer from './FeedbackRequestTypeRenderer';
import AssignmentStatusRenderer from './AssignmentStatusRenderer';
import { AUTH_ROUTES } from '../../constants/routes';
import StringRenderer from '../tables/StringRenderer';
import FeedbackAssignmentRequestRenderer from './FeedbackAssignmentRequestRenderer';
import Notifications from '../../utils/Notifications';
import { rejectFeedbackAssignmentReviewer } from '../../services/feedbackassignmentreviewer/feedbackAssignmentReviewerProvider';

class UserFeedbackAssignmentRequestsTable extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      columns: [
        {
          Header: t('tables.feedback'),
          id: 'feedbackDisplayName',
          Cell: LabelLinkRenderer,
          filterable: false,
          accessor: (d) =>
            d.feedbackAssignment && d.feedbackAssignment.feedback
              ? d.feedbackAssignment.feedback.name
              : '',
          canUseLink: (original) => this.canUseLink(original),
          onClickLink: this.onPassItem,
          securityModule: 'feedback-assignments',
          securityPermission: 'pass',
        },
        {
          Header: t('tables.type'),
          id: 'feedbackRequestType',
          accessor: (d) => d,
          Cell: FeedbackRequestTypeRenderer,
          width: 200,
          filterable: false,
        },
        {
          Header: t('tables.status'),
          id: 'assignmentStatus',
          accessor: (d) => d.assignmentStatus,
          Cell: AssignmentStatusRenderer,
          width: 150,
          filterable: false,
        },
        {
          Header: t('tables.deadline'),
          id: 'deadline',
          accessor: (d) =>
            d.feedbackAssignment && d.feedbackAssignment.deadline
              ? Moment(d.feedbackAssignment.deadline).format('LL')
              : '',
          Cell: StringRenderer,
          filterable: false,
        },
        {
          Header: t('tables.asked_by'),
          id: 'askedBy',
          accessor: () => ({
            userKeyPath: 'createdByUser',
            employeeJobKeyPath: 'createdByUserEmployee.employeeJob',
          }),
          Cell: UserRenderer,
          filterable: false,
        },
        {
          Header: t('tables.reviewee'),
          id: 'reviewee',
          accessor: (d) => ({
            userKeyPath: 'feedbackAssignment.reviewee',
            employeeJobKeyPath: 'feedbackAssignment.revieweeEmployee.employeeJob',
          }),
          Cell: UserRenderer,
          filterable: false,
        },
        {
          Header: t('tables.organization_unit'),
          id: 'revieweeOrgUnit',
          accessor: (d) =>
            d.feedbackAssignment && d.feedbackAssignment.revieweeEmployee
              ? d.feedbackAssignment.revieweeEmployee.orgStructureUnitName
              : '',
          Cell: StringRenderer,
          filterable: false,
          sortable: false,
        },
        {
          id: 'actions',
          accessor: (d) => d,
          Cell: FeedbackAssignmentRequestRenderer,
          sortable: false,
          filterable: false,
          onRejectFeedbackAssignment: this.rejectFeedbackAssignment,
        },
      ],
      paginationPageSize: 10,
      minRows: 3,
      tableRef: null,
      defaultSorted: [
        {
          id: 'assignmentStatus',
          asc: true,
          cases: [
            { literal: 'New', sortOrder: 0 },
            { literal: 'InProgress', sortOrder: 1 },
            { literal: 'Draft', sortOrder: 2 },
            { literal: 'Finished', sortOrder: 3 },
            { literal: 'Expired', sortOrder: 4 },
            { literal: 'Rejected', sortOrder: 5 },
          ],
        },
      ],
    };
  }

  canUseLink = (original) => !(original && original.assignmentStatus === 'Rejected');

  rejectFeedbackAssignment = (reviewerId) => {
    const { t } = this.props;
    rejectFeedbackAssignmentReviewer(reviewerId)
      .then((isUpdated) => {
        this.tableRef.updateTableData(true);
        this.setState({ isLoading: false });
        Notifications.success(t('message.feedback_assignment_rejected'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  onPassItem = (itemId) => {
    const { FEEDBACK } = AUTH_ROUTES;
    const { history } = this.props;
    const link = `${FEEDBACK.ASSIGNMENTS}/${itemId}/pass`;
    history.push(link, {
      feedbackAssignmentReviewerId: itemId,
      action: 'pass',
    });
  };

  render() {
    const { paginationPageSize, minRows, columns, defaultSorted } = this.state;
    const { tableReference, setTableReference, fetchFeedbackAssignmentRequestList } = this.props;

    return (
      <PaginationTable
        minRows={minRows}
        defaultSorted={defaultSorted}
        defaultPageSize={paginationPageSize}
        columns={columns}
        getDataFromServer={fetchFeedbackAssignmentRequestList}
        className="-striped -highlight"
        rowHeight={100}
        ref={(elementRef) => {
          this.tableRef = elementRef;
          if (!tableReference && elementRef) {
            setTableReference(elementRef);
          }
        }}
      />
    );
  }
}

UserFeedbackAssignmentRequestsTable.propTypes = {
  fetchFeedbackAssignmentRequestList: PropTypes.func.isRequired,
  setTableReference: PropTypes.func.isRequired,
  tableReference: PropTypes.object,
};

export default withTranslation()(withRouter(UserFeedbackAssignmentRequestsTable));
