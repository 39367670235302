import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import AsyncSelect from '../react-hook-form-inputs/AsyncSelect';
import Notifications from '../../utils/Notifications';
import {
  getUserRoleSelectItemSource,
  getUserRolesNotAppliedForUser,
} from '../../services/user/userRoleProvider';

function InnerForm(props) {
  const form = useForm({ mode: 'onSubmit' });
  const { t, addUserRole, cancel, user } = props;
  const { handleSubmit } = form;

  const onSubmit = (values) => {
    addUserRole(values.name);
  };

  const [defaultUserRoles, setDefaultUserRoles] = useState([]);

  useEffect(() => {
    getUserRoleSelectItemSource({ amount: 100, skip: 0 })
      .then((defaultUserRoles) => {
        setDefaultUserRoles(defaultUserRoles);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  const loadUserRoleOptions = (query, callback) => {
    getUserRolesNotAppliedForUser(user.id, query)
      .then((data) => {
        callback && callback(data ? data.items : []);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <div className="modal-body">
        <Controller
          control={form.control}
          name="name"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <AsyncSelect
              placeholder={t('fields.choose_roles')}
              form={form}
              defaultOptions={defaultUserRoles}
              loadOptions={loadUserRoleOptions}
              isMulti
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
        <button
          type="button"
          onClick={handleSubmit((data) => {
            onSubmit(data);
            cancel();
          })}
          className="btn fm-btn-primary"
        >
          {t('buttons.add')}
        </button>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  addUserRole: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  user: PropTypes.object.isRequired,
};

export default withTranslation()(InnerForm);
