import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../react-hook-form-inputs/Input';
import TextArea from '../react-hook-form-inputs/TextArea';
import DatePicker from '../react-hook-form-inputs/DatePicker';
import { updateFeedbackAssignment } from '../../services/feedbackassignment/feedbackAssignmentProvider';
import DateUtil from '../../utils/DateUtil';

function InnerForm(props) {
  const { t, addFormSubmitHandler, feedbackAssignment } = props;

  const schema = Yup.object().shape({
    feedback: Yup.object().shape({
      id: Yup.number().required(t('message.required_field')),
    }),
    reviewee: Yup.object().shape({
      id: Yup.number().required(t('message.required_field')),
    }),
    comments: Yup.string(),
  });

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      id: feedbackAssignment.id ? feedbackAssignment.id : '',
      feedbackId: feedbackAssignment.feedback ? feedbackAssignment.feedback.id : '',
      feedback: {
        displayString: feedbackAssignment.feedback ? feedbackAssignment.feedback.name : '',
      },
      revieweeId: feedbackAssignment.revieweeEmployee.user
        ? feedbackAssignment.revieweeEmployee.user.id
        : '',
      reviewee: {
        displayString: feedbackAssignment.revieweeDisplayName
          ? feedbackAssignment.revieweeDisplayName
          : '',
      },
      feedbackAssignmentPeriodId: feedbackAssignment.feedbackAssignmentPeriodId
        ? feedbackAssignment.feedbackAssignmentPeriodId
        : '',
      createdByUserId: feedbackAssignment.createdByUserId ? feedbackAssignment.createdByUserId : '',
      comments: feedbackAssignment.comments ? feedbackAssignment.comments : '',
      deadline: feedbackAssignment.deadline ? DateUtil.get(feedbackAssignment.deadline) : '',
      created: feedbackAssignment.created ? DateUtil.get(feedbackAssignment.created) : '',
      updated: feedbackAssignment.updated ? DateUtil.get(feedbackAssignment.updated) : '',
    },
  });

  const { handleSubmit, register, trigger, getValues } = form;

  useEffect(() => {
    addFormSubmitHandler('assignmentForm', { submit: onSubmit, validate: trigger });
  }, []);

  const onSubmit = () => {
    const values = getValues();
    delete values.feedback;
    delete values.reviewee;
    return updateFeedbackAssignment(values);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="id" ref={register()} />
      <input type="hidden" name="feedbackAssignmentPeriodId" ref={register()} />
      <input type="hidden" name="feedbackId" ref={register()} />
      <input type="hidden" name="revieweeId" ref={register()} />
      <input type="hidden" name="createdByUserId" ref={register()} />
      <input type="hidden" name="created" ref={register()} />
      <input type="hidden" name="updated" ref={register()} />
      <Controller
        control={form.control}
        name="feedback.displayString"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <Input
            placeholder={t('fields.feedback')}
            label={t('fields.feedback')}
            name={name}
            form={form}
            disabled
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        control={form.control}
        name="reviewee.displayString"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <Input
            placeholder={t('fields.reviewee')}
            label={t('fields.reviewee')}
            name={name}
            form={form}
            disabled
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />

      <Controller
        control={form.control}
        name="comments"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <TextArea
            placeholder={t('fields.comments')}
            label={t('fields.comments')}
            name={name}
            form={form}
            rows={3}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />

      <Controller
        control={form.control}
        name="deadline"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <DatePicker
            name={name}
            label={t('fields.deadline')}
            onChange={onChange}
            form={form}
            onBlur={onBlur}
            value={value}
            disabled
            placeholderText={t('fields.date_format_mm_dd_yyyy')}
            autoComplete="off"
          />
        )}
      />
    </form>
  );
}

InnerForm.propTypes = {
  addFormSubmitHandler: PropTypes.func.isRequired,
  feedbackAssignment: PropTypes.object.isRequired,
};

export default withTranslation()(InnerForm);
