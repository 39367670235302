import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import {
  getFeedbackCategory,
  getFeedbackCategoryByName,
  updateFeedbackCategory,
} from '../../services/feedbackcategory/feedbackCategoryProvider';
import EditFeedbackCategoryForm from '../../components/feedbackcategories/EditFeedbackCategoryForm';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import PageNameContainer from '../../components/page/PageNameContainer';

class EditFeedbackCategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      feedbackCategory: {
        id: '',
        tenantId: '',
        name: '',
        description: '',
        created: '',
        updated: '',
      },
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_feedback_categories'),
        ref: '/feedback-settings/categories',
        isActive: false,
      },
      {
        name: t('breadcrumbs.feedback_category'),
        ref: null,
        isActive: true,
      },
    ];
  };

  feedbackCategorySetState = (data) => {
    this.setState({
      isLoading: false,
      feedbackCategory: {
        id: data ? data.id : '',
        tenantId: data ? data.tenantId : '',
        name: data ? data.name : '',
        description: data ? data.description : '',
        created: data ? data.created : '',
        updated: data ? data.updated : '',
      },
    });
  };

  componentDidMount() {
    const { params } = this.props.match;
    this.setState({ isLoading: true });
    Promise.all([getFeedbackCategory(params.feedbackCategoryId)])
      .then(([feedbackCategory]) => {
        this.setState({
          isLoading: false,
          feedbackCategory: {
            id: feedbackCategory ? feedbackCategory.id : '',
            tenantId: feedbackCategory ? feedbackCategory.tenantId : '',
            name: feedbackCategory ? feedbackCategory.name : '',
            description: feedbackCategory ? feedbackCategory.description : '',
            created: feedbackCategory ? feedbackCategory.created : '',
            updated: feedbackCategory ? feedbackCategory.updated : '',
          },
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  updateFeedbackCategory = (data) => {
    const { t } = this.props;
    updateFeedbackCategory(data)
      .then((_data) => {
        this.feedbackCategorySetState({
          ...this.state.feedbackCategory,
          ...data,
        });
        Notifications.success(t('message.feedback_category_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, feedbackCategory } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.feedback_category')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="row">
            <div className="col-md-6">
              {feedbackCategory && feedbackCategory.id && (
                <EditFeedbackCategoryForm
                  feedbackCategory={feedbackCategory}
                  getFeedbackCategoryByName={getFeedbackCategoryByName}
                  updateFeedbackCategory={this.updateFeedbackCategory}
                />
              )}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

EditFeedbackCategoryPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
};

export default withTranslation()(EditFeedbackCategoryPage);
