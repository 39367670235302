import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Moment from 'moment';

import { deleteEmployee } from '../../services/employee/employeeProvider';
import Notifications from '../../utils/Notifications';
import { AUTH_ROUTES } from '../../constants/routes';
import PaginationTable from '../tables/PaginationTable';
import EditDeleteActionsRenderer from '../tables/EditDeleteActionsRenderer';
import StringRenderer from '../tables/StringRenderer';
import UserRendererWithTooltip from '../tables/UserRendererWithTooltip';

import './styles/employee-table.css';

function AllEmployeeTable({ fetchEmployeeList, history, tableReference, setTableReference }) {
  const { t } = useTranslation();
  const [loading, setIsLoading] = useState(false);
  const [tableRef, setTableRef] = useState(null);

  const onViewItem = (itemId) => {
    const { ORG } = AUTH_ROUTES;
    const link = `${ORG.EMPLOYEES}/${itemId}/edit`;
    history.push(link, { employeeId: itemId });
  };

  const confirmDeleteItem = (itemId) => {
    setIsLoading(true);
    deleteEmployee(itemId)
      .then(() => {
        tableRef.updateTableData(true);
        setIsLoading(false);
        Notifications.success(t('message.employee_deleted'));
        document.activeElement?.blur();
      })
      .catch((error) => {
        Notifications.error(error);
        setIsLoading(false);
      });
  };

  const onDeleteItem = (itemId) => {
    const SweetAlertModal = withReactContent(Swal);

    return new Promise((resolve, reject) => {
      SweetAlertModal.fire({
        title: t('message.are_you_sure'),
        showConfirmButton: true,
        showCancelButton: true,
        text: t('message.you_are_about_to_delete_employee'),
        confirmButtonColor: '#1ab394',
        confirmButtonText: t('message.yes_delete_it'),
        cancelButtonText: t('buttons.cancel'),
        showCloseButton: true,
        icon: 'warning',
      }).then((data) => {
        if (data.isConfirmed) {
          confirmDeleteItem(itemId);
        }
      });
    });
  };

  const tableConfg = {
    columns: [
      {
        Header: t('tables.employee_info'),
        id: 'userInfo',
        accessor: (d) => ({
          user: d.user ? d.user : '',
          employeeJob: d.employeeJob ? d.employeeJob : '',
          userKeyPath: 'user',
          employeeJobKeyPath: 'employeeJob',
          employeeJobLevelKeyPath: 'employeeJobLevel',
        }),
        Cell: UserRendererWithTooltip,
        filterable: false,
      },
      {
        Header: t('tables.organization_unit'),
        Cell: StringRenderer,
        accessor: 'orgStructureUnitName',
        filterable: false,
      },
      {
        id: 'location',
        Header: t('tables.location'),
        Cell: StringRenderer,
        accessor: (d) => (d.location ? d.location.displayString : ''),
        filterable: false,
      },
      {
        id: 'hireDate',
        Header: t('tables.hire_date'),
        Cell: StringRenderer,
        accessor: (d) => Moment(d.hireDate).format('LL'),
      },
      {
        id: 'actions',
        Cell: EditDeleteActionsRenderer,
        sortable: false,
        filterable: false,
        onDeleteItem,
        onViewItem,
        securityModule: 'employees',
      },
    ],
    defaultSorted: [
      {
        id: 'created',
        desc: true,
      },
    ],
    paginationPageSize: 10,
    minRows: 3,
  };

  return (
    <PaginationTable
      ref={(elementRef) => {
        setTableRef(elementRef);
        if (!tableReference && elementRef) {
          setTableReference(elementRef);
        }
      }}
      minRows={tableConfg.minRows}
      defaultSorted={tableConfg.defaultSorted}
      defaultPageSize={tableConfg.paginationPageSize}
      columns={tableConfg.columns}
      getDataFromServer={fetchEmployeeList}
      className="-striped -highlight"
    />
  );
}

AllEmployeeTable.propTypes = {
  fetchEmployeeList: PropTypes.func.isRequired,
};

export default withRouter(AllEmployeeTable);
