import dataProvider from '../dataProvider';

import { feedback } from '../../constants/endpoints';

export const getFeedbackList = (params) =>
  dataProvider({
    url: feedback.all(),
    params,
    method: 'GET',
  });

export const getFeedbackById = (feedbackId) =>
  dataProvider({
    url: feedback.getById(feedbackId),
    method: 'GET',
  });

export const createFeedback = (params) =>
  dataProvider({
    url: feedback.create(),
    data: params,
    method: 'POST',
  });

export const updateFeedback = (params) =>
  dataProvider({
    url: feedback.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteFeedback = (feedbackId) =>
  dataProvider({
    url: feedback.delete(feedbackId),
    method: 'DELETE',
  });

export const getFeedbackByName = (name) =>
  dataProvider({
    url: feedback.getByName(name),
    method: 'GET',
  });

export const getFeedbackByNameLike = (query) =>
  dataProvider({
    url: feedback.getByNameLike(query),
    method: 'GET',
  });

export const getFeedbackSelectItemSource = (params) =>
  dataProvider({
    url: feedback.itemSource(),
    data: params,
    method: 'POST',
  });

export const getFeedbackPagination = (params) =>
  dataProvider({
    url: feedback.pagination(),
    data: params,
    method: 'POST',
  });
