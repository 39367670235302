import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import _ from 'lodash';

function Input({ name, form: { touched, errors }, classNames, label, rightButton, ...props }) {
  const error = _.get(errors, name);

  return (
    <FormGroup
      className={classnames(
        'form-element',
        'input-component',
        classNames,
        { 'has-error': error && error.message },
        { 'input-group': rightButton },
      )}
    >
      {label && <ControlLabel htmlFor={name}>{label}</ControlLabel>}
      <FormControl name={name} id={name} {...props} />
      {rightButton && (
        <Button onClick={rightButton.onClick} variant="outline-secondary">
          {rightButton.label}
        </Button>
      )}
      {error && error.message && (
        <HelpBlock className="error-text animated fadeIn fm-error-req">{error.message}</HelpBlock>
      )}
    </FormGroup>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  classNames: PropTypes.string,
};

export default Input;
