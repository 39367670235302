import React, { Component } from 'react';
import './loader.css';
import Spinner from 'react-spinner-material';

class Loader extends Component {
  render() {
    return (
      <div id="preloader">
        <div className="sk-spinner sk-spinner-fading-circle current-spinner">
          <Spinner size={20} className="-fm-table-spinner" color="#34B77C" width={2} visible />
        </div>
      </div>
    );
  }
}

export default Loader;
