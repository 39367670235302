import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class FeedbackAssignmentSummaryCommentsModal extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  render() {
    const { t, cancel, comments } = this.props;
    return (
      <div className="fm-feedback-assignment-comments">
        <div className="modal-body">
          <div className="fm-comments-container ">
            {comments.managerComment && (
              <div className="fm-comment-box">
                <div className="fm-comment-header">
                  <span>{t('components.manager')}</span>
                </div>
                <div className="fm-comment-body">{comments.managerComment}</div>
              </div>
            )}
            {comments.selfComment && (
              <div className="fm-comment-box">
                <div className="fm-comment-header">
                  <span>{t('components.manager')}</span>
                </div>
                <div className="fm-comment-body">{comments.selfComment}</div>
              </div>
            )}
            {comments.comments &&
              comments.comments.length > 0 &&
              comments.comments.map((c, index) => (
                <div key={index} className="fm-comment-box">
                  <div className="fm-comment-header">
                    <span>{`${t('components.employee')} ${index + 1}`}</span>
                  </div>
                  <div className="fm-comment-body">{c}</div>
                </div>
              ))}
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
            {t('buttons.cancel')}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FeedbackAssignmentSummaryCommentsModal);
