import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import translation_EN from './i18n/en.json';
import translation_RU from './i18n/ru.json';
import 'moment/locale/ru';

const language = () => {
  const availableLanguage = ['en', 'ru'];
  let _language = window.navigator
    ? window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage
    : 'ru';
  _language = _language.substr(0, 2).toLowerCase();

  if (!availableLanguage.includes(_language)) {
    _language = 'en';
  }
  if (_language) {
    moment.locale(_language);
  }

  return (
    i18n
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        fallbackLng: _language,
        debug: false,
        saveMissing: true,

        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
          bindI18n: 'languageChanged editorSaved',
        },
        resources: {
          en: {
            translation: translation_EN,
          },
          ru: {
            translation: translation_RU,
          },
        },
      })
  );
};

export default language();
