import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { faPlus, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import Loader from '../../../components/loader/Loader';
import Notifications from '../../../utils/Notifications';
import Modal from '../../../components/modal/Modal';
import {
  createQuestionClassification,
  deleteQuestionClassification,
  getQuestionClassificationByName,
  getQuestionClassificationPagination,
} from '../../../services/questionclassification/questionClassificationProvider';
import AllQuestionClassificationsTable from '../../../components/tests/questionclassification/AllQuestionClassificationsTable';
import CreateQuestionClassificationModal from '../../../components/tests/questionclassification/CreateQuestionClassificationModal';
import { AUTH_ROUTES } from '../../../constants/routes';
import PageNameContainer from '../../../components/page/PageNameContainer';
import FormTitle from '../../../components/form/FormTitle';

class AllQuestionClassifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isModalShown: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_question_tags'),
        ref: null,
        isActive: true,
      },
    ];
  };

  fetchQuestionClassificationList = (amount, skip, sorting, filters, callback) => {
    getQuestionClassificationPagination({
      amount,
      skip,
      sortings: sorting,
      filters,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  openCreateQuestionClassificationModal = () => {
    this.setState({ isModalShown: true });
  };

  closeModal = () => {
    this.setState({ isModalShown: false });
  };

  createQuestionClassification = (values) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
      isModalShown: false,
    });
    createQuestionClassification(values)
      .then(() => {
        this.state.tableRef.updateTableData(false);
        this.setState({ isLoading: false });
        Notifications.success(t('message.question_tag_created'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { isLoading, isModalShown, tableRef } = this.state;
    const { TEST } = AUTH_ROUTES;
    const { t } = this.props;

    return (
      <>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.all_question_tags')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.all_question_tags')}
              buttons={[
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.openCreateQuestionClassificationModal,
                  name: t('buttons.create'),
                  icon: faPlus,
                  securityModule: 'question-classification',
                  securityPermission: 'create',
                },
              ]}
            />
            <AllQuestionClassificationsTable
              fetchQuestionClassificationList={this.fetchQuestionClassificationList}
              deleteQuestionClassification={deleteQuestionClassification}
              objectLinkBase={TEST.QUESTION_CLASSIFICATION}
              isTemplate={false}
              canDelete
              canView
              securityModule="question-classification"
              setTableReference={this.setTableReference}
              tableReference={tableRef}
            />
          </div>
        </div>
        <Modal
          header={t('components.create_question_tag')}
          isOpened={isModalShown}
          icon={faSitemap}
          onClose={this.closeModal}
        >
          <CreateQuestionClassificationModal
            createQuestionClassification={this.createQuestionClassification}
            cancel={this.closeModal}
            getQuestionClassificationByName={getQuestionClassificationByName}
          />
        </Modal>
        {isLoading && <Loader />}
      </>
    );
  }
}

AllQuestionClassifications.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AllQuestionClassifications);
