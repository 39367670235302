import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './styles/preview-test.css';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileImage, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import UrlUtil from '../../utils/UrlUtil';
import Can from '../security/Can';

class PreviewSurvey extends Component {
  onDeleteItem = () => {
    const { test, deleteItem } = this.props;
    deleteItem(test.id);
  };

  render() {
    const { t, test, linkPrefix, buttons, sucurityModule, isProtected } = this.props;
    const testImageLink = test ? test.testImageLink : null;

    return (
      <div className="fm-preview-test">
        <table>
          <tbody>
            <tr>
              <td className="fm-test-preview-image">
                <div className="fm-test-image-wrapper">
                  <div className="fm-test-image-box">
                    {testImageLink ? (
                      <img
                        src={UrlUtil.buildImageSrcLink(testImageLink)}
                        id="test-image"
                        alt={t('components.survey_image')}
                      />
                    ) : test.testImage && test.testImageExtension ? (
                      <img
                        src={`data:image/${test.testImageExtension};base64,${test.testImage}`}
                        id="test-image"
                        alt={t('components.survey_image')}
                      />
                    ) : (
                      <div className="img-responsive fm-test-img">
                        <FontAwesomeIcon icon={faFileImage} />
                      </div>
                    )}
                  </div>
                </div>
              </td>

              <td className="fm-test-preview-main" valign="top">
                <div className="fm-text-info-wrapper">
                  <h2>
                    <Can
                      module={sucurityModule}
                      permission="edit"
                      isProtected={isProtected}
                      yes={() => (
                        <NavLink
                          className="fm-test-preview-title"
                          to={`${linkPrefix}/${test.id}/edit`}
                        >
                          {test.title}
                        </NavLink>
                      )}
                      no={() => <div className="fm-test-preview-title">{test.title}</div>}
                    />
                  </h2>
                  <div>
                    <span className="fm-test-preview-description">{test.description}</span>
                  </div>
                  {test.testQuestionClassification &&
                    test.testQuestionClassification.length > 0 && (
                      <div>
                        {test.testQuestionClassification.map((c, index) => (
                          <div key={index} className="fm-cl-tag">
                            <span
                              className="badge badge-primary"
                              style={{ backgroundColor: c.color }}
                            >
                              {c.name}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              </td>

              <td className="fm-test-preview-buttons">
                <div className="fm-btn-group">
                  <Can
                    module={sucurityModule}
                    permission="edit"
                    isProtected={isProtected}
                    yes={() => (
                      <NavLink
                        className="btn fm-table-btn fm-btn-xs-edit"
                        title={t('buttons.edit')}
                        to={`${linkPrefix}/${test.id}/edit`}
                      >
                        <FontAwesomeIcon icon={faPencil} />
                      </NavLink>
                    )}
                    no={() => null}
                  />
                  {buttons &&
                    _.map(buttons, (btn, k) =>
                      !(btn.securityModule || btn.securityPermission) ? (
                        <button className="btn" onClick={btn.onClick} key={k}>
                          <FontAwesomeIcon icon={btn.icon} /> {btn.name}
                        </button>
                      ) : (
                        <Can
                          key={k}
                          module={btn.securityModule}
                          permission={btn.securityPermission}
                          yes={() => (
                            <button
                              className="btn fm-table-btn fm-btn-xs-edit"
                              onClick={btn.onClick}
                              title={btn.name}
                              key={k}
                            >
                              <FontAwesomeIcon icon={btn.icon} />
                            </button>
                          )}
                          no={() => null}
                        />
                      ),
                    )}
                  <Can
                    module={sucurityModule}
                    permission="delete"
                    isProtected={isProtected}
                    yes={() => (
                      <button
                        className="btn fm-table-btn fm-btn-xs-delete"
                        title={t('buttons.delete')}
                        onClick={this.onDeleteItem}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    )}
                    no={() => null}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

PreviewSurvey.defaultProps = {
  isProtected: false,
};

PreviewSurvey.propTypes = {
  t: PropTypes.func.isRequired,
  isProtected: PropTypes.bool.isRequired,
  test: PropTypes.object,
  deleteItem: PropTypes.func.isRequired,
  linkPrefix: PropTypes.string,
  buttons: PropTypes.array,
};

export default withTranslation()(PreviewSurvey);
