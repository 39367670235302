import dataProvider from '../dataProvider';
import { orgunit } from '../../constants/endpoints';

export const getOrgUnitList = (params) =>
  dataProvider({
    url: orgunit.all(),
    params,
    method: 'GET',
  });

export const getOrgUnit = (orgId) =>
  dataProvider({
    url: orgunit.getById(orgId),
    method: 'GET',
  });

export const createOrgUnit = (values) =>
  dataProvider({
    url: orgunit.create(),
    method: 'POST',
    data: values,
  });

export const updateOrgUnit = (params) =>
  dataProvider({
    url: orgunit.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteOrgUnit = (orgunitId) =>
  dataProvider({
    url: orgunit.delete(orgunitId),
    method: 'DELETE',
  });

export const getOrgUnitSelectItemSource = (params) =>
  dataProvider({
    url: orgunit.itemSource(),
    method: 'POST',
    data: params,
  });

export const getOrgUnitListPagination = (params) =>
  dataProvider({
    url: orgunit.pagination(),
    data: params,
    method: 'POST',
  });

export const getOrgUnitListPaginationWithHeadcount = (params) =>
  dataProvider({
    url: orgunit.paginationWithHeadcount(),
    data: params,
    method: 'POST',
  });

export const getByNameLike = (query) =>
  dataProvider({
    url: orgunit.getByNameLike(query),
    method: 'GET',
  });

export const getOrgUnitEmployeesListPagination = (params, orgUnitId) =>
  dataProvider({
    url: orgunit.getOrgUnitEmployeesListPagination(orgUnitId),
    data: params,
    method: 'POST',
  });
export const deleteEmployeeFromOrgUnit = (employeeId) =>
  dataProvider({
    url: orgunit.deleteEmployeeFromOrgUnit(employeeId),
    method: 'DELETE',
  });

export const getOrgUnitExcludeCircularDependency = (params) =>
  dataProvider({
    url: orgunit.getOrgUnitExcludeCircularDependency(),
    data: params,
    method: 'POST',
  });

export const getOrgUnitExcludeCircularDependencyLike = (params) =>
  dataProvider({
    url: orgunit.getOrgUnitExcludeCircularDependencyLike(),
    data: params,
    method: 'POST',
  });
