import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import Loader from '../../components/loader/Loader';
import PassFeedbackAssignmentForm from '../../components/feedbackassignments/passing/PassFeedbackAssignmentForm';
import './styles/pass-assignment-container.css';
import Notifications from '../../utils/Notifications';
import {
  getActiveAssignmentReviewerIds,
  getFeedbackAssignmentReviewer,
  updateFeedbackAssignmentReviewer,
} from '../../services/feedbackassignmentreviewer/feedbackAssignmentReviewerProvider';
import PageNameContainer from '../../components/page/PageNameContainer';
import { AUTH_ROUTES } from '../../constants/routes';

class PassFeedbackAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showScrollTop: false,
      isLoading: false,
      feedbackAssignmentReviewer: null,
      feedbackAnswers: [],
      answerErrors: {},
      activeAssignmentsIds: [],
      currentFeedbackAssignment: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.my_requests'),
        ref: '/feedback-assignments/requests',
        isActive: false,
      },
      {
        name: t('breadcrumbs.feedback_request'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    const { feedbackAssignmentReviewerId } = this.props.location.state;
    this.populateFormData(feedbackAssignmentReviewerId);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  populateFormData = (feedbackAssignmentReviewerId) => {
    this.setState({ isLoading: true });

    getFeedbackAssignmentReviewer(feedbackAssignmentReviewerId)
      .then((feedbackAssignmentReviewer) => {
        getActiveAssignmentReviewerIds(feedbackAssignmentReviewer.reviewerId).then(
          (activeAssignmentsIds) => {
            const indexOfCurrent = activeAssignmentsIds.indexOf(
              parseInt(feedbackAssignmentReviewerId),
            );
            if (indexOfCurrent > -1) {
              activeAssignmentsIds.splice(indexOfCurrent, 1);
            }
            activeAssignmentsIds.unshift(parseInt(feedbackAssignmentReviewerId));

            this.setState({
              feedbackAssignmentReviewerId,
              feedbackAssignmentReviewer,
              isLoading: false,
              hasNext: activeAssignmentsIds.length > 1,
              hasPrevious: false,
              activeAssignmentsIds,
              currentFeedbackAssignmentIndex: 0,
            });
          },
        );
      })
      .catch((err) => {
        Notifications.error(err);
        this.setState({ isLoading: false });
      });
  };

  openFeedbackAssignmentPassing(newIndex) {
    const { history } = this.props;
    const { activeAssignmentsIds } = this.state;
    history.push(`${AUTH_ROUTES.FEEDBACK.ASSIGNMENTS}/${activeAssignmentsIds[newIndex]}/pass`, {
      feedbackAssignmentReviewerId: activeAssignmentsIds[newIndex],
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.state &&
      prevProps.location.state &&
      !_.isEqual(
        this.props.location.state.feedbackAssignmentReviewerId,
        prevProps.location.state.feedbackAssignmentReviewerId,
      )
    ) {
      this.populateFormData(this.props.location.state.feedbackAssignmentReviewerId);
    }
  }

  setFeedbackAnswers = (feedbackAnswers) => {
    this.setState({ feedbackAnswers });
  };

  updateReviewer = (reviewer, message) => {
    updateFeedbackAssignmentReviewer(reviewer)
      .then(() => {
        const { feedbackAssignmentReviewerId } = this.props.location.state;
        this.populateFormData(feedbackAssignmentReviewerId);
        this.setState({ isLoading: false, answerErrors: {} });
        Notifications.success(message);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  collectAnswers = () => {
    const { feedbackAnswers } = this.state;
    const criteriaAnswersBatch = [];
    _.forEach(feedbackAnswers, (value) => {
      if (value.action) {
        const answer = {
          object: value,
          objectType: 'Platform:FeedbackAssignmentReviewer',
          action: value.action,
        };
        criteriaAnswersBatch.push(answer);
      }
    });
    return criteriaAnswersBatch;
  };

  onClickCancel = () => {
    const { history } = this.props;
    history.push(`${AUTH_ROUTES.FEEDBACK.ASSIGNMENT_REQUEST}`, {});
  };

  onClickPrevious = () => {
    const { currentFeedbackAssignmentIndex } = this.state;
    this.openFeedbackAssignmentPassing(currentFeedbackAssignmentIndex - 1);
  };

  onClickNext = () => {
    const { currentFeedbackAssignmentIndex } = this.state;
    this.openFeedbackAssignmentPassing(currentFeedbackAssignmentIndex + 1);
  };

  onClickSaveDraft = () => {
    const { t } = this.props;

    const { feedbackAssignmentReviewer } = this.state;
    this.setState({ answerErrors: [] });

    feedbackAssignmentReviewer.assignmentStatus = 'Draft';
    feedbackAssignmentReviewer.criteriaAnswersBatch = this.collectAnswers();
    this.updateReviewer(feedbackAssignmentReviewer, t('message.feedback_saved_draft'));
  };

  onClickSave = () => {
    const { t } = this.props;
    const { feedbackAnswers, feedbackAssignmentReviewer } = this.state;
    const answerErrors = {};
    _.forEach(feedbackAnswers, (value, index) => {
      if (value.isMain && !value.feedbackCriteriaDescriptionId) {
        answerErrors[index] = true;
      }
    });
    if (!_.isEmpty(answerErrors)) {
      Notifications.warning(t('message.cant_save_until_required_criteria_populated'));
      this.setState({ answerErrors });
    } else {
      feedbackAssignmentReviewer.assignmentStatus = 'Finished';
      feedbackAssignmentReviewer.criteriaAnswersBatch = this.collectAnswers();
      this.updateReviewer(feedbackAssignmentReviewer, t('message.your_feedback_saved'));
    }
  };

  handleScroll = () => {
    document.documentElement.scrollTop > 0 || document.body.scrollTop > 0
      ? this.setState({ showScrollTop: true })
      : this.setState({ showScrollTop: false });
  };

  scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.scrollTo(0, 0);
    }
  };

  render() {
    const {
      showScrollTop,
      isLoading,
      answerErrors,
      feedbackAnswers,
      feedbackAssignmentReviewer,
      hasNext,
      hasPrevious,
    } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.feedback_request')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          {feedbackAssignmentReviewer && (
            <PassFeedbackAssignmentForm
              feedbackAssignmentReviewer={feedbackAssignmentReviewer}
              setFeedbackAnswers={this.setFeedbackAnswers}
              feedbackAnswers={feedbackAnswers}
              answerErrors={answerErrors}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              onClickCancel={this.onClickCancel}
              onClickPrevious={this.onClickPrevious}
              onClickNext={this.onClickNext}
              onClickSave={this.onClickSave}
              onClickSaveDraft={this.onClickSaveDraft}
            />
          )}
        </div>
        {showScrollTop && (
          <button
            type="button"
            onClick={(e) => {
              this.scrollToTop(e);
            }}
            className="animated fadeIn"
            id="fm-scroll-questions-top"
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        )}
        {isLoading && <Loader />}
      </div>
    );
  }
}

PassFeedbackAssignment.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
};

export default withTranslation()(withRouter(PassFeedbackAssignment));
