import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Moment from 'moment';

import './styles/assignment-period-table.css';
import { withTranslation } from 'react-i18next';
import Notifications from '../../utils/Notifications';
import { AUTH_ROUTES } from '../../constants/routes';

import PaginationTable from '../tables/PaginationTable';
import AssignmentPeriodActionsRenderer from './AssignmentPeriodActionsRenderer';

import { deleteAssignmentPeriod } from '../../services/assignmentperiod/assignmentPeriodProvider';
import StringRenderer from '../tables/StringRenderer';
import StringUtil from '../../utils/StringUtil';

class AllAssignmentPeriodTable extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      columns: [
        {
          Header: t('tables.survey'),
          accessor: 'test.displayString',
          Cell: StringRenderer,
          filterable: false,
          id: 'testName',
        },
        {
          Header: t('tables.type'),
          Cell: StringRenderer,
          filterable: false,
          id: 'type',
          accessor: (d) => (
            <span>
              {StringUtil.separateCapital(d.type.label)} (<b>{d.targetDisplayName}</b>)
            </span>
          ),
        },
        {
          Header: t('tables.start_date'),
          Cell: StringRenderer,
          maxWidth: 200,
          id: 'startDate',
          accessor: (d) => Moment(d.startDate).format('LL'),
        },
        {
          Header: t('tables.end_date'),
          Cell: StringRenderer,
          maxWidth: 200,
          id: 'endDate',
          accessor: (d) => Moment(d.endDate).format('LL'),
        },
        {
          Header: t('tables.response_rate'),
          Cell: StringRenderer,
          maxWidth: 200,
          id: 'responseRate',
          accessor: (d) => `${d.responseRate ? parseFloat(d.responseRate).toFixed(1) : '0'}%`,
        },
        {
          id: 'actions',
          Cell: AssignmentPeriodActionsRenderer,
          sortable: false,
          filterable: false,
          onDeleteItem: this.onDeleteItem,
          onViewItem: this.onViewItem,
          onViewSummary: this.onViewSummary,
        },
      ],
      defaultSorted: [
        {
          id: 'created',
          desc: true,
        },
      ],
      SweetAlertModal: withReactContent(Swal),
      paginationPageSize: 10,
      minRows: 3,
      tableRef: null,
    };
  }

  onDeleteItem = (itemId) => {
    const { SweetAlertModal } = this.state;
    const { t } = this.props;

    return new Promise((resolve, reject) => {
      SweetAlertModal.fire({
        title: t('message.are_you_sure'),
        showConfirmButton: true,
        showCancelButton: true,
        text: t('message.you_are_about_to_delete_survey_assignment'),
        confirmButtonColor: '#1ab394',
        confirmButtonText: t('message.yes_delete_it'),
        showCloseButton: true,
        icon: 'warning',
      }).then((data) => {
        if (data.isConfirmed) {
          this.confirmDeleteItem(itemId);
        }
      });
    });
  };

  onViewItem = (itemId) => {
    const { ASSIGNMENT_PERIODS } = AUTH_ROUTES;
    const { history } = this.props;
    const link = `${ASSIGNMENT_PERIODS}/${itemId}/edit`;

    history.push(link, { assignmentPeriodId: itemId });
  };

  onViewSummary = (itemId) => {
    const { ASSIGNMENT_PERIODS } = AUTH_ROUTES;
    const { history } = this.props;
    const link = `${ASSIGNMENT_PERIODS}/${itemId}/summary`;

    history.push(link, { assignmentPeriodId: itemId });
  };

  confirmDeleteItem = (itemId) => {
    const { t } = this.props;
    this.setState({ isLoading: true });
    deleteAssignmentPeriod(itemId)
      .then(() => {
        this.tableRef.updateTableData(true);
        this.setState({ isLoading: false });
        Notifications.success(t('message.survey_assignment_deleted'));
        document.activeElement?.blur();
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const { tableReference, setTableReference, fetchAssignmentPeriodList } = this.props;
    const { paginationPageSize, minRows, columns, defaultSorted } = this.state;
    return (
      <div>
        <div>
          <PaginationTable
            ref={(elementRef) => {
              this.tableRef = elementRef;
              if (!tableReference && elementRef) {
                setTableReference(elementRef);
              }
            }}
            minRows={minRows}
            defaultSorted={defaultSorted}
            defaultPageSize={paginationPageSize}
            columns={columns}
            getDataFromServer={fetchAssignmentPeriodList}
            className="-striped -highlight"
          />
        </div>
      </div>
    );
  }
}

AllAssignmentPeriodTable.propTypes = {
  fetchAssignmentPeriodList: PropTypes.func.isRequired,
  setTableReference: PropTypes.func.isRequired,
  tableReference: PropTypes.object,
};

export default withTranslation()(withRouter(AllAssignmentPeriodTable));
