import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';
import chroma from 'chroma-js';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import './styles/feedback-form.scss';
import { withTranslation } from 'react-i18next';

class FeedbackCriteria extends Component {
  constructor(props) {
    super(props);
    this.state = { feedbackCriteriaDescriptions: this.props.feedbackCriteriaDescriptions };
  }

  componentDidMount() {
    const { feedbackCriteriaDescriptions } = this.props;
    const marks = {};
    marks[0] = {
      label: 'N/A',
    };
    const defaultValue = [0];
    const dotStyles = [
      {
        backgroundColor: '#e7e7e7',
        borderColor: chroma('#e7e7e7').alpha(0.1).css(),
      },
    ];
    feedbackCriteriaDescriptions.forEach((descr) => {
      const v = parseInt(descr.value);
      marks[v] = {
        label: descr.label,
        style: {},
      };
      defaultValue.push(parseInt(descr.value));

      const { color } = descr;
      dotStyles.push({
        backgroundColor: color,
        borderColor: chroma(color).alpha(0.1).css(),
      });
    });
    this.setState({
      marks,
      defaultValue,
      dotStyles,
    });
  }

  onChange = (value) => {
    const { feedbackCriteriaDescriptions } = this.props;
    if (feedbackCriteriaDescriptions[value - 1]) {
      const { color } = feedbackCriteriaDescriptions[value - 1];
      this.setState({
        trackStyle: {
          backgroundColor: color,
          borderColor: chroma(color).alpha(0.1).css(),
        },
      });
    } else {
      this.setState({
        trackStyle: {
          backgroundColor: '#e9e9e9',
          borderColor: '#e9e9e9',
        },
      });
    }
  };

  render() {
    const { marks, dotStyles, defaultValue } = this.state;
    return (
      <div className="fm-feedback-slider">
        <div
          style={{
            width: '90%',
            margin: 20,
            marginTop: 10,
            display: 'inline-block',
          }}
        >
          {defaultValue && (
            <Range
              disabled
              marks={marks}
              min={0}
              max={defaultValue.length - 1}
              defaultValue={defaultValue}
              handleStyle={dotStyles}
            />
          )}
        </div>
      </div>
    );
  }
}

FeedbackCriteria.propTypes = {
  feedbackCriteriaDescriptions: PropTypes.array.isRequired,
};

export default withTranslation()(FeedbackCriteria);
