import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Input from '../react-hook-form-inputs/Input';
import TextArea from '../react-hook-form-inputs/TextArea';
import Can from '../security/Can';
import SelectComponent from '../react-hook-form-inputs/Select';
import StringUtil from '../../utils/StringUtil';
import UrlUtil from '../../utils/UrlUtil';

function InnerForm(props) {
  const { t, caseReport, statuses, reportTypes, severities, securityModule, updateCaseReport } =
    props;

  const schema = Yup.lazy(() =>
    Yup.object().shape({
      title: Yup.string().required(t('message.title_required')),
      description: Yup.string().required(t('message.describe_case')),
    }),
  );

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = form;
  const onSubmit = (values) => {
    caseReport.title = values.title;
    caseReport.status = values.status != null ? values.status.value : caseReport.status;
    caseReport.reportType =
      values.reportType != null ? values.reportType.value : caseReport.reportType;
    caseReport.severity = values.severity != null ? values.severity.value : caseReport.severity;
    caseReport.description = values.description;

    updateCaseReport(caseReport);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={form.control}
        name="title"
        defaultValue={caseReport.title}
        render={({ onChange, onBlur, value }) => (
          <Input
            placeholder={t('fields.title')}
            form={form}
            label={t('fields.title')}
            rows={4}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        control={form.control}
        name="status"
        render={({ onChange }) => (
          <SelectComponent
            form={form}
            label={t('fields.status')}
            defaultValue={{
              label: StringUtil.separateCapital(caseReport.status, ' '),
              value: caseReport.status,
            }}
            options={statuses}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={form.control}
        name="reportType"
        render={({ onChange }) => (
          <SelectComponent
            form={form}
            label={t('fields.report_type')}
            defaultValue={{
              label: caseReport.reportType,
              value: caseReport.reportType,
            }}
            options={reportTypes}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={form.control}
        name="severity"
        render={({ onChange }) => (
          <SelectComponent
            form={form}
            label={t('fields.severity')}
            defaultValue={{
              label: caseReport.severity,
              value: caseReport.severity,
            }}
            options={severities}
            onChange={onChange}
          />
        )}
      />
      <Controller
        control={form.control}
        name="description"
        defaultValue={caseReport.description}
        render={({ onChange, onBlur, value }) => (
          <TextArea
            placeholder={t('fields.description')}
            form={form}
            label={t('fields.description')}
            rows={4}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <div className="w-full h-auto my-4">
        <img
          alt="Bug report attachment"
          src={UrlUtil.buildImageSrcLink(caseReport.attachments?.[0].fileLink)}
        />
      </div>
      <Can
        module={securityModule}
        permission="edit"
        yes={() => (
          <button type="submit" className="btn fm-btn-primary block full-width m-b">
            {t('buttons.save')}
          </button>
        )}
        no={() => null}
      />
    </form>
  );
}

InnerForm.propTypes = {
  caseReport: PropTypes.object.isRequired,
  statuses: PropTypes.array.isRequired,
  reportTypes: PropTypes.array.isRequired,
  severities: PropTypes.array.isRequired,
  updateCaseReport: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
