import React, { PureComponent } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { GUEST_ROUTES, ROOT } from '../constants/routes';
import App from '../containers/App';
import Landing from '../containers/Landing';

import GuestRoutes from './GuestRoutes';
import AuthRoutes from './AuthRoutes';

class Routing extends PureComponent {
  render() {
    return (
      <Router basename={ROOT}>
        <App>
          <Switch>
            <Route exact path={ROOT} component={Landing} />
            <GuestRoutes path={GUEST_ROUTES} />
            <AuthRoutes path={ROOT} />
          </Switch>
        </App>
      </Router>
    );
  }
}

export default Routing;
