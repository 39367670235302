import dataProvider from '../dataProvider';
import { testassignment } from '../../constants/endpoints';

export const getTestAssignmentsList = (params) =>
  dataProvider({
    url: testassignment.all(),
    params,
    method: 'GET',
  });

export const getTestAssignment = (testAssignmentId) =>
  dataProvider({
    url: testassignment.getById(testAssignmentId),
    method: 'GET',
  });

export const createTestAssignment = (values) =>
  dataProvider({
    url: testassignment.create(),
    method: 'POST',
    data: values,
  });

export const updateTestAssignment = (params) =>
  dataProvider({
    url: testassignment.update(params.id),
    data: params,
    method: 'PUT',
  });

export const patchTestAssignment = (id, params) =>
  dataProvider({
    url: testassignment.patch(id),
    data: params,
    method: 'PATCH',
  });

export const deleteTestAssignment = (testAssignmentId) =>
  dataProvider({
    url: testassignment.delete(testAssignmentId),
    method: 'DELETE',
  });

export const getTestAssignmentSelectItemSource = (params) =>
  dataProvider({
    url: testassignment.itemSource(),
    method: 'POST',
    data: params,
  });

export const getUserTestAssignmentsList = (params) =>
  dataProvider({
    url: testassignment.user(),
    params,
    method: 'GET',
  });

export const getTestAssignmentPagination = (params) =>
  dataProvider({
    url: testassignment.pagination(),
    data: params,
    method: 'POST',
  });

export const getTestAssignmentDetails = (testAssignmentId) =>
  dataProvider({
    url: testassignment.getTestAssignmentDetails(testAssignmentId),
    method: 'GET',
  });

export const getTestAssignmentPaginationByUser = (params) =>
  dataProvider({
    url: testassignment.paginationByUser(params.userId),
    data: params,
    method: 'POST',
  });

export const getTestAssignmentUserScreenInfo = (userId, params) =>
  dataProvider({
    url: testassignment.getTestAssignmentDetailsByUserId(userId),
    params,
    method: 'GET',
  });

export const getEmployeeSurveyAssignmentsCount = () =>
  dataProvider({
    url: testassignment.countEmployeeAssignments(),
    method: 'GET',
  });

export const getTestAssignmentsCount = (testId) =>
  dataProvider({
    url: testassignment.countTestAssignments(testId),
    method: 'GET',
  });

export const getUserResults = (testAssignmentId) =>
  dataProvider({
    url: testassignment.getUserResults(testAssignmentId),
    method: 'GET',
  });

export const getTestAssignmentByAssignmentPeriodIdAndUserId = (assignmentPeriodId, userId) =>
  dataProvider({
    url: testassignment.getTestAssignmentByAssignmentPeriodIdAndUserId(assignmentPeriodId, userId),
    method: 'GET',
  });
