import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import account from './services/account/reducer';
import tests from './services/tests/reducer';
import menu from './services/menu/reducer';
import employee from './services/employee/reducer';
import testAssignment from './services/testassignment/reducer';

const combined = combineReducers({
  routing: routerReducer,
  account,
  menu,
  tests,
  employee,
  testAssignment,
});

export default combined;
