import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { checkPermission } from '../../utils/SecurityUtil';

function Can(props) {
  return checkPermission(
    props.module,
    props.account.permissions,
    props.permission,
    props.isProtected,
  )
    ? props.yes()
    : props.no();
}

Can.defaultProps = {
  yes: () => null,
  no: () => null,
  isProtected: false,
};

Can.propTypes = {
  isProtected: PropTypes.bool.isRequired,
  module: PropTypes.string.isRequired,
  permission: PropTypes.string.isRequired,
  data: PropTypes.object,
};

export default connect((state) => ({
  account: state.account,
}))(Can);
