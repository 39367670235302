import React from 'react';
import PropTypes from 'prop-types';
import './styles/pass-feedback.css';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import Notifications from '../../../utils/Notifications';
import { batchUpdateFeedbackAssignmentReviewers } from '../../../services/feedbackassignmentreviewer/feedbackAssignmentReviewerProvider';
import FeedbackCriteriaSmall from './FeedbackCriteriaSmall';

function InnerForm(props) {
  const {
    t,
    criteria,
    employee,
    feedbackAnswers,
    colors,
    closeManagerSummaryBox,
    managerId,
    feedbackAssignment,
    refreshPage,
    reviewer,
  } = props;

  const initValues = {};
  _.forEach(feedbackAnswers, (v, index) => {
    initValues[`fb-criteria-comment-${v.feedbackCriteriaId}`] = v.comment ? v.comment : '';
    initValues[`fb-criteria-description-${v.feedbackCriteriaId}`] = v.feedbackCriteriaDescriptionId;
  });

  const { user, employeeJob } = employee;
  const form = useForm({
    mode: 'onSubmit',
    defaultValues: initValues,
  });
  const { register, handleSubmit, errors, formState, control } = form;
  const feedbackCriteriaDescriptionMap = new Map();

  for (let i = 0; i < criteria.length; i++) {
    for (let j = 0; j < criteria[i].feedbackCriteriaDescriptions.length; j++) {
      feedbackCriteriaDescriptionMap.set(
        criteria[i].feedbackCriteriaDescriptions[j].id,
        criteria[i].feedbackCriteriaDescriptions[j],
      );
    }
  }

  const onSubmit = (data, a) => {
    const answers = feedbackAnswers.map((v) => {
      const criteriaDescriptionId = data[`fb-criteria-description-${v.feedbackCriteriaId}`];
      const feedbackCriteriaDescription = feedbackCriteriaDescriptionMap.get(
        JSON.parse(criteriaDescriptionId),
      );

      return {
        object: {
          id: v.id,
          tenantId: v.tenantId,
          feedbackCriteriaId: v.feedbackCriteriaId,
          feedbackCriteriaDescriptionId: feedbackCriteriaDescription
            ? feedbackCriteriaDescription.id
            : null,
          feedbackAssignmentReviewerId: reviewer ? reviewer.id : null,
          value: feedbackCriteriaDescription ? feedbackCriteriaDescription.value : null,
          comment: data[`fb-criteria-comment-${v.feedbackCriteriaId}`],
        },
        action: v.id ? 'update' : 'create',
        objectType: 'Platform:FeedbackCriteriaAnswer',
      };
    });

    const _reviewer = {
      action: reviewer && reviewer.id ? 'update' : 'create',
      objectType: 'Platform:FeedbackAssignmentReviewer',
      object: {
        id: reviewer && reviewer.id ? reviewer.id : null,
        tenantId: feedbackAssignment.tenantId, // or set if exist
        reviewerId: managerId,
        feedbackAssignmentId: feedbackAssignment.id,
        createdByUser: feedbackAssignment.createdByUser,
        createdByUserId: feedbackAssignment.createdByUserId,
        assignmentStatus: a,
        isManagerReview: true,
        isSelfReview: employee.user.id === managerId,
        criteriaAnswersBatch: answers,
      },
    };

    batchUpdateFeedbackAssignmentReviewers([_reviewer])
      .then((data) => {
        refreshPage();
        Notifications.success(t('message.manager_summary_added'));
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="fm-manager-summary-box">
      <div className="fm-manager-summary-header">
        <div className="fm-ms-employee-info">
          <span className="fm-user-info-name">{user.displayString}</span>
          {employeeJob && <span className="fm-user-info-job">{employeeJob.displayString}</span>}
        </div>
        <div className="fm-ms-cancel-btn" onClick={closeManagerSummaryBox}>
          <FontAwesomeIcon icon={faTimesCircle} />
          <span>{t('buttons.cancel')}</span>
        </div>
      </div>
      <div className="fm-manager-summary-body">
        {criteria &&
          criteria.map((cri, index) => (
            <FeedbackCriteriaSmall
              key={`fm-fb-additional-criteria-${index}`}
              criteria={cri}
              answer={feedbackAnswers[index]}
              color={colors[index]}
              index={index}
              form={{
                register,
                handleSubmit,
                errors,
                formState,
                control,
              }}
              readOnly={reviewer && reviewer.assignmentStatus === 'Finished'}
            />
          ))}
      </div>
      <div className="fm-manager-summary-footer">
        <div className="fm-manager-summary-btns">
          {!(reviewer && reviewer.assignmentStatus === 'Finished') && (
            <>
              <button
                type="button"
                className="btn btn-sm fm-submit-manager-summary"
                onClick={handleSubmit((data) => onSubmit(data, 'Finished'))}
              >
                <FontAwesomeIcon icon={faCheck} />
                {t('buttons.save')}
              </button>
              <button
                type="button"
                className="btn btn-sm fm-draft-manager-summary"
                onClick={handleSubmit((data) => onSubmit(data, 'Draft'))}
              >
                <FontAwesomeIcon icon={faPen} />
                {t('buttons.draft')}
              </button>
            </>
          )}
          <button className="btn btn-sm fm-cancel-manager-summary" onClick={closeManagerSummaryBox}>
            <FontAwesomeIcon icon={faTimes} />
            {t('buttons.cancel')}
          </button>
        </div>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  reviewer: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  criteria: PropTypes.array.isRequired,
  feedbackAnswers: PropTypes.array.isRequired,
  answerErrors: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
  managerId: PropTypes.number.isRequired,
  feedbackAssignment: PropTypes.object.isRequired,
};

export default withTranslation()(InnerForm);
