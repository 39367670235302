import React from 'react';
import Cow from '../../styles/img/NoDataCow.svg';
import './styles/form.css';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class NoDataForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, label, paddingTop, paddingBottom } = this.props;
    return (
      <div
        className="fm-no-data-component"
        style={{
          paddingTop: paddingTop && paddingTop,
          paddingBottom: paddingBottom && paddingBottom,
        }}
      >
        <div>
          <img id="fm-no-data-image" src={Cow} />
        </div>
        <div>
          <span>{label !== undefined ? label : t('components.no_data_present')} </span>
        </div>
      </div>
    );
  }
}

NoDataForm.propTypes = {
  label: PropTypes.string,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
};

export default withTranslation()(NoDataForm);
