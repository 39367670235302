import dataProvider from '../dataProvider';
import { questionclassificationtemplate } from '../../constants/endpoints';

export const getQuestionClassificationTemplateList = (params) =>
  dataProvider({
    url: questionclassificationtemplate.all(),
    params,
    method: 'GET',
  });

export const getQuestionClassificationTemplate = (questionClassificationId) =>
  dataProvider({
    url: questionclassificationtemplate.getById(questionClassificationId),
    method: 'GET',
  });

export const getQuestionClassificationTemplateByTestId = (testId) =>
  dataProvider({
    url: questionclassificationtemplate.getByTestId(testId),
    method: 'GET',
  });

export const getQuestionClassificationTemplateByQuestionId = (questionId) =>
  dataProvider({
    url: questionclassificationtemplate.getByQuestionId(questionId),
    method: 'GET',
  });

export const getQuestionClassificationTemplateByName = (name) =>
  dataProvider({
    url: questionclassificationtemplate.getByName(name),
    method: 'GET',
  });

export const getQuestionClassificationTemplateByNameLike = (query) =>
  dataProvider({
    url: questionclassificationtemplate.getByNameLike(query),
    method: 'GET',
  });

export const createQuestionClassificationTemplate = (values) =>
  dataProvider({
    url: questionclassificationtemplate.create(),
    method: 'POST',
    data: values,
  });

export const updateQuestionClassificationTemplate = (params) =>
  dataProvider({
    url: questionclassificationtemplate.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteQuestionClassificationTemplate = (questionClassificationId) =>
  dataProvider({
    url: questionclassificationtemplate.delete(questionClassificationId),
    method: 'DELETE',
  });

export const getQuestionClassificationTemplateSelectItemSource = (params) =>
  dataProvider({
    url: questionclassificationtemplate.itemSource(),
    method: 'POST',
    data: params,
  });
export const getQuestionClassificationTemplatesPagination = (params) =>
  dataProvider({
    url: questionclassificationtemplate.pagination(),
    data: params,
    method: 'POST',
  });
