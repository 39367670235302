import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import AsyncSelectComponent from '../react-hook-form-inputs/AsyncSelect';
import SelectComponent from '../react-hook-form-inputs/Select';
import { getTestByNameLike, getTestSelectItemSource } from '../../services/tests/testProvider';
import Notifications from '../../utils/Notifications';

function InnerForm(props) {
  const { strategies, onChangeStrategy, onChangeTest, t } = props;

  const loadOptionsTests = (query, callback) => {
    getTestByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const [defaultSurveys, setDefaultSurveys] = useState([]);

  useEffect(() => {
    const promises = [getTestSelectItemSource({ amount: 100, skip: 0 })];
    Promise.all(promises)
      .then(([defaultSurveys]) => {
        setDefaultSurveys(defaultSurveys);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  const form = useForm();
  const { control } = form;

  return (
    <form>
      <Controller
        control={control}
        name="test"
        defaultValue=""
        render={({ onChange, onBlur, name, value }) => (
          <AsyncSelectComponent
            form={form}
            name={name}
            loadOptions={loadOptionsTests}
            value={value}
            onChange={(object) => {
              onChangeTest(object);
              onChange(object);
            }}
            onBlur={onBlur}
            type="text"
            isMulti={false}
            defaultOptions={defaultSurveys}
            isClearable
            cacheOptions
            placeholder={t('fields.survey')}
            label={t('fields.survey')}
          />
        )}
      />
      {strategies && (
        <Controller
          control={control}
          name="strategies"
          defaultValue=""
          render={({ onChange }) => (
            <SelectComponent
              form={form}
              label={t('fields.type')}
              placeholder={t('fields.type')}
              options={strategies}
              onChange={(object) => {
                onChangeStrategy(object);
                onChange(object);
              }}
            />
          )}
        />
      )}
    </form>
  );
}

InnerForm.propTypes = {
  onChangeStrategy: PropTypes.func.isRequired,
  onChangeTest: PropTypes.func.isRequired,
  strategies: PropTypes.array.isRequired,
};

export default withTranslation()(InnerForm);
