import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormTitle from '../../components/form/FormTitle';
import PageNameContainer from '../../components/page/PageNameContainer';
import ImpactWidget from '../../components/impact/ImpactWidget';
import './styles/dashboard.css';
import Can from '../../components/security/Can';
import { withTranslation } from 'react-i18next';

class Impact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.impact'),
        ref: null,
        isActive: true,
      },
    ];
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { tableRef } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer name={t('containers.impact')} breadcrumbs={this.getBreadcrumbs()} />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="row">
            <div className="col-lg-12">
              <Can
                module="survey-analytics"
                permission="impact"
                yes={() => (
                  <div className="ibox">
                    <FormTitle name={t('components.impact')} />
                    <div className="ibox-content fm-impact-page">
                      <ImpactWidget
                        setTableReference={this.setTableReference}
                        tableReference={tableRef}
                      />
                    </div>
                  </div>
                )}
                no={() => null}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Impact.propTypes = {
  account: PropTypes.object,
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(Impact)));
