import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import parsePhoneNumber from 'libphonenumber-js';
import Input from '../react-hook-form-inputs/Input';
import Phone from '../react-hook-form-inputs/Phone';
import Select from '../react-hook-form-inputs/Select';

import 'yup-phone-lite';

function InnerForm(props) {
  const { t, updateUserProfile, addSubmition, languageOptions, user } = props;

  let lang = null;
  if (languageOptions && user.language) {
    lang = languageOptions.find((element) => element.value === user.language);
  }

  const [countryCode, setCountryCode] = useState('');

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(t('message.required_field')).email(t('message.invalid_email')),
    firstName: Yup.string()
      .matches(/^[\wА-я]+$/, t('message.must_be_only_letters'))
      .max(100, 'message.field_value_too_long'),
    middleName: Yup.string().max(100, 'message.field_value_too_long'),
    lastName: Yup.string()
      .matches(/^[\wА-я]+$/, t('message.must_be_only_letters'))
      .max(100, 'message.field_value_too_long'),
    phone: Yup.string().phone(countryCode, t('message.wrong_phone_format')),
  });
  const form = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: user && user.firstName ? user.firstName : '',
      middleName: user && user.middleName ? user.middleName : '',
      lastName: user && user.lastName ? user.lastName : '',
      phone: user.phone || null,
      username: user && user.username ? user.username : '',
      email: user && user.email ? user.email : '',
      verified: user && user.verified ? user.verified : false,
      language: lang,
    },
  });
  const { control, handleSubmit, register } = form;

  const onSubmit = (values) => {
    updateUserProfile(values);
  };
  useEffect(() => {
    if (addSubmition) {
      addSubmition(handleSubmit(onSubmit));
    }
    const phoneNumber = parsePhoneNumber(`+${user.phone}`);
    if (phoneNumber) {
      setCountryCode(phoneNumber.country);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Controller
        control={control}
        name="lastName"
        render={({ onChange, onBlur, value, name }) => (
          <Input
            name={name}
            form={form}
            label={t('fields.last_name')}
            placeholder={t('fields.last_name')}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            type="text"
          />
        )}
      />
      <Controller
        control={control}
        name="firstName"
        render={({ onChange, onBlur, value, name }) => (
          <Input
            name={name}
            form={form}
            label={t('fields.first_name')}
            placeholder={t('fields.first_name')}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            type="text"
          />
        )}
      />
      <Controller
        control={control}
        name="middleName"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <Input
            name={name}
            form={form}
            label={t('fields.middle_name')}
            placeholder={t('fields.middle_name')}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            type="text"
          />
        )}
      />
      <Controller
        control={control}
        name="email"
        render={({ onChange, onBlur, value, name }) => (
          <Input
            name={name}
            form={form}
            label={t('fields.email')}
            placeholder={t('fields.email')}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            type="email"
          />
        )}
      />
      <Controller
        control={control}
        name="phone"
        render={({ onChange, onFocus, onBlur, value, name }) => (
          <Phone
            name={name}
            form={form}
            label={t('fields.phone')}
            placeholder={t('fields.phone')}
            onBlur={onBlur}
            onFocus={(value, data, event, formattedValue) => {
              onFocus && onFocus(value, data, event, formattedValue);
              data && data.countryCode && setCountryCode(data.countryCode.toUpperCase());
            }}
            onChange={(value, data, event, formattedValue) => {
              onChange(value, data, event, formattedValue);
              setCountryCode(data.countryCode?.toUpperCase());
            }}
            value={value}
            type="phone"
          />
        )}
      />

      <Controller
        id="language"
        control={control}
        name="language"
        render={({ onChange, onBlur, value, name }) => (
          <Select
            name={name}
            placeholder={t('fields.language')}
            label={t('fields.language')}
            form={form}
            options={languageOptions}
            onBlur={onBlur}
            cacheOptions={false}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <input type="hidden" name="verified" ref={register()} />
    </form>
  );
}

InnerForm.propTypes = {
  t: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  addSubmition: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
