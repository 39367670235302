import dataProvider from '../dataProvider';
import { question, test } from '../../constants/endpoints';

export const getTestList = (params) =>
  dataProvider({
    url: test.all(),
    params,
    method: 'GET',
  });

export const getTestById = (itemId) =>
  dataProvider({
    url: test.getById(itemId),
    method: 'GET',
  });

export const deleteTest = (itemId) =>
  dataProvider({
    url: test.delete(itemId),
    method: 'DELETE',
  });

export const createTest = (data) =>
  dataProvider({
    url: test.create(),
    method: 'POST',
    data,
  });

export const updateTest = (testId, data) =>
  dataProvider({
    url: test.update(testId),
    method: 'PUT',
    data,
  });

export const getTestQuestions = (testId) =>
  dataProvider({
    url: question.getByTest(testId),
    method: 'GET',
  });

export const getTestQuestionIds = (testId) =>
  dataProvider({
    url: question.getIdsByTest(testId),
    method: 'GET',
  });

export const getTestTypes = () =>
  dataProvider({
    url: test.types(),
    method: 'GET',
  });

export const getTestSelectItemSource = (params) =>
  dataProvider({
    url: test.itemSource(),
    data: params,
    method: 'POST',
  });

export const getTestByNameLike = (query, params) =>
  dataProvider({
    url: test.getTestByNameLike(query),
    data: params,
    method: 'POST',
  });

export const getTestIdByName = (name) =>
  dataProvider({
    url: test.getTestIdByName(name),
    method: 'GET',
  });
