import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './styles/Modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

function Modal({ t, isOpened, header, icon, children, onClose }) {
  const handleKeyPress = (event) => {
    if (event.keyCode === 27) onClose?.();
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      document.body.classList.remove('modal-open');
    };
  }, []);

  useEffect(() => {
    if (isOpened) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isOpened]);

  const handleClose = () => {
    document.body.classList.remove('modal-open');
    onClose?.();
  };

  return (
    isOpened && (
      <div className="modal inmodal in">
        <div className="modal-dialog fm-dialog-crop" tabIndex="-1">
          <div className="modal-content animated fadeIn">
            <div className="modal-header">
              {icon && (
                <div className="fm-icon-wrapper">
                  <FontAwesomeIcon icon={icon} />
                </div>
              )}
              <h4 className="modal-title">{header}</h4>
              <button
                type="button"
                className="swal2-close"
                onClick={handleClose}
                data-dismiss="modal"
                aria-label={t('buttons.close')}
              >
                ×
              </button>
            </div>
            {children}
          </div>
        </div>
        <div className="modal-backdrop in" onClick={handleClose} />
      </div>
    )
  );
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpened: PropTypes.bool,
  header: PropTypes.node,
  icon: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Modal.defaultProps = {
  isOpened: false,
  header: null,
  icon: null,
  children: null,
};

export default withTranslation()(Modal);
export { Modal };
