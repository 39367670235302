import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Notifications from '../../utils/Notifications';
import { getFeedbackAssignmentPeriod } from '../../services/feedbackassignmentperiod/feedbackAssignmentPeriodProvider';
import { getFeedbackAssignmentReviewersAsEmployees } from '../../services/employee/employeeProvider';
import EmployeeDisplay from '../employee/EmployeeDisplay';
import ContentLoader from '../loader/ContentLoader';

function FeedbackSummaryReviewersForm(props) {
  const { t, feedbackAssignment } = props;

  const [dates, setDates] = useState(null);
  const [reviewers, setReviewers] = useState(null);
  useEffect(() => {
    const today = moment();
    getFeedbackAssignmentPeriod(feedbackAssignment.feedbackAssignmentPeriodId)
      .then((feedbackAssignmentPeriod) => {
        const _startDate = moment(
          feedbackAssignmentPeriod
            ? feedbackAssignmentPeriod.startDate
            : feedbackAssignment.created,
        );
        const _endDate = moment(
          feedbackAssignmentPeriod ? feedbackAssignmentPeriod.endDate : feedbackAssignment.deadline,
        );
        const _totalNumDays = _endDate.diff(_startDate, 'days');
        const _pastNumDays = today.isAfter(_endDate)
          ? _totalNumDays
          : today.diff(_startDate, 'days');

        setDates({
          startDate: _startDate,
          endDate: _endDate,
          totalNumDays: _totalNumDays,
          pastNumDays: _pastNumDays,
          showAddReviewerBtn: !today.isAfter(_endDate),
        });
      })
      .catch((error) => {
        Notifications.error(error);
      });

    getFeedbackAssignmentReviewersAsEmployees(feedbackAssignment.id)
      .then((reviewers) => {
        setReviewers(reviewers);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  return (
    <div className="fm-reviewers-container">
      <div className="fm-feedback-assignment-reviewers-header">
        {t('components.current_assignment')}
      </div>
      {dates && reviewers && (
        <div className="fm-feedback-assignment-reviewers-box">
          <div className="fm-feedback-assignment-reviewers-wrapper">
            <div className="fm-feedback-assignment-row">
              <div className="fm-feedback-assignment-name">{feedbackAssignment.feedback.name}</div>
              <div className="fm-feedback-assignment-progress">
                {t('components.progress')}:{' '}
                {((100 / dates.totalNumDays) * dates.pastNumDays).toFixed(0)}%
              </div>
            </div>
            <div className="fm-feedback-assignment-date-range">
              <div className="fm-progress-bar-empty" />
              <div
                className="fm-progress-bar-filled"
                style={{ width: `${(100 / dates.totalNumDays) * dates.pastNumDays}%` }}
              />
              <div
                className="fm-progress-bar-dot-state"
                style={{ marginLeft: `${(100 / dates.totalNumDays) * dates.pastNumDays}%` }}
              />
            </div>
            <div className="fm-feedback-assignment-row">
              {dates.startDate && (
                <div className="fm-feedback-assignment-start-date">
                  {dates.startDate.format('LL')}
                </div>
              )}
              {dates.endDate && (
                <div className="fm-feedback-assignment-end-date">{dates.endDate.format('LL')}</div>
              )}
            </div>
            <div className="fm-feedback-assignment-reviewers">
              <div className="fm-feedback-assignment-reviewers-label">
                {t('components.reviewers')}
              </div>
              <div className="fm-feedback-assignment-reviewers-list">
                {reviewers &&
                  reviewers.map((reviewer, index) => (
                    <EmployeeDisplay key={`reviewer-${index}`} employee={reviewer} />
                  ))}
              </div>
              {dates.showAddReviewerBtn && (
                <div onClick={this.addReviewer} className="fm-feedback-assignment-add-reviewer-box">
                  <div className="fm-add-circle-btn">
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                  <div className="fm-add-reviewer-label">
                    <span>{t('components.add_reviewer')}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {(!dates || !reviewers) && <ContentLoader visible />}
    </div>
  );
}

FeedbackSummaryReviewersForm.propTypes = {
  feedbackAssignment: PropTypes.object.isRequired,
};

export default withTranslation()(FeedbackSummaryReviewersForm);
