import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import FormTitle from '../form/FormTitle';
import DatePicker from '../react-hook-form-inputs/DatePicker';
import { getExcludeDates } from '../../services/feedbackassignmentperiod/feedbackAssignmentPeriodProvider';
import Notifications from '../../utils/Notifications';
import Can from '../security/Can';
import TextArea from '../react-hook-form-inputs/TextArea';

function InnerForm(props) {
  const { t, updateFeedbackAssignmentPeriod, feedbackAssignmentPeriod } = props;

  const [startDate, setStartDate] = useState(
    feedbackAssignmentPeriod.startDate
      ? new Date(moment.utc(feedbackAssignmentPeriod.startDate))
      : '',
  );
  const [endDate, setEndDate] = useState(
    feedbackAssignmentPeriod.endDate ? new Date(moment.utc(feedbackAssignmentPeriod.endDate)) : '',
  );
  const [excludedPeriodsAndDates, setExcludedPeriodsAndDates] = useState(null);

  const schema = Yup.object().shape({
    startDate: Yup.date().required(t('message.required_field')),
    endDate: Yup.date().required(t('message.required_field')),
  });
  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      startDate: feedbackAssignmentPeriod.startDate
        ? new Date(moment.utc(feedbackAssignmentPeriod.startDate))
        : '',
      endDate: feedbackAssignmentPeriod.endDate
        ? new Date(moment.utc(feedbackAssignmentPeriod.endDate))
        : '',
      description: feedbackAssignmentPeriod.description ? feedbackAssignmentPeriod.description : '',
    },
  });
  const { handleSubmit, control } = form;

  const getMinDate = () => {
    const { excludePeriods } = excludedPeriodsAndDates;
    const currentDateMoment = new Date(moment.utc());
    if (!excludePeriods || !endDate) {
      return currentDateMoment;
    }
    const endDateMoment = new Date(moment.utc(endDate));
    for (let i = excludePeriods.length - 1; i >= 0; i--) {
      const excludePeriodEndDate = excludePeriods[i].endDate;
      if (excludePeriodEndDate < endDateMoment) {
        if (excludePeriodEndDate < currentDateMoment) {
          return currentDateMoment;
        }
        return excludePeriodEndDate;
      }
    }
    return currentDateMoment;
  };

  const getMaxDate = () => {
    const { excludePeriods } = excludedPeriodsAndDates;
    if (!excludePeriods || !startDate) {
      return null;
    }
    const startDateMoment = new Date(moment.utc(startDate));
    for (let i = 0; i < excludePeriods.length; i++) {
      if (startDateMoment < excludePeriods[i].startDate) {
        return excludePeriods[i].startDate;
      }
    }
    return null;
  };

  const _getExcludeDates = () => {
    const settings = {
      ...feedbackAssignmentPeriod.settings,
      type: feedbackAssignmentPeriod.type.value,
      feedbackId: feedbackAssignmentPeriod.feedbackId.toString(),
    };
    __getExcludeDates(settings);
  };

  const __getExcludeDates = (settings) => {
    getExcludeDates(settings)
      .then((data) => {
        const startDateMoment = new Date(moment.utc(feedbackAssignmentPeriod.startDate));
        const endDateMoment = new Date(moment.utc(feedbackAssignmentPeriod.endDate));
        data.excludeDates = data.excludeDates.map((d) => {
          const excludeDate = new Date(moment.utc(d));
          if (excludeDate < startDateMoment || excludeDate > endDateMoment) {
            return excludeDate;
          }
        });
        data.excludePeriods = data.excludePeriods
          .map((d) => {
            const excludePeriodStartDateMoment = new Date(moment.utc(d.startDate));
            const excludePeriodEndDateMoment = new Date(moment.utc(d.endDate));
            if (
              excludePeriodStartDateMoment.getTime() !== startDateMoment.getTime() &&
              excludePeriodEndDateMoment.getTime() !== endDateMoment.getTime()
            ) {
              return {
                startDate: excludePeriodStartDateMoment,
                endDate: excludePeriodEndDateMoment,
              };
            }
          })
          .filter((d) => d !== undefined);
        setExcludedPeriodsAndDates(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const onSubmit = (data) => {
    const params = {
      ...feedbackAssignmentPeriod,
      startDate: data.startDate,
      endDate: data.endDate,
      description: data.description,
    };
    updateFeedbackAssignmentPeriod(params);
  };

  feedbackAssignmentPeriod && !excludedPeriodsAndDates && _getExcludeDates();

  return (
    <div className="ibox">
      <FormTitle name={t('components.settings')} />
      <div className="ibox-content clearfix">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <Controller
            control={control}
            id="description"
            name="description"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <TextArea
                name={name}
                placeholder={t('fields.description')}
                label={t('fields.description')}
                form={form}
                rows={2}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
          {excludedPeriodsAndDates != null && excludedPeriodsAndDates.excludeDates != null && (
            <Controller
              control={form.control}
              id="startDate"
              name="startDate"
              defaultValue=""
              render={({ onChange, onBlur, value, name }) => (
                <DatePicker
                  name={name}
                  label={t('fields.start_date')}
                  onChange={(object) => {
                    onChange(object);
                    if (object) {
                      setStartDate(object);
                    } else {
                      setStartDate(null);
                      setEndDate(null);
                    }
                  }}
                  form={form}
                  excludeDates={excludedPeriodsAndDates.excludeDates}
                  onBlur={onBlur}
                  value={value}
                  placeholderText={t('fields.date_format_mm_dd_yyyy')}
                  minDate={getMinDate()}
                  maxDate={endDate}
                  autoComplete="off"
                />
              )}
            />
          )}
          {excludedPeriodsAndDates != null && excludedPeriodsAndDates.excludeDates != null && (
            <Controller
              control={form.control}
              id="endDate"
              name="endDate"
              defaultValue=""
              render={({ onChange, onBlur, value, name }) => (
                <DatePicker
                  name={name}
                  label={t('fields.end_date')}
                  onChange={(object) => {
                    onChange(object);
                    if (object) {
                      setEndDate(object);
                    } else {
                      setEndDate(null);
                    }
                  }}
                  form={form}
                  excludeDates={excludedPeriodsAndDates.excludeDates}
                  onBlur={onBlur}
                  value={value}
                  placeholderText={t('fields.date_format_mm_dd_yyyy')}
                  minDate={startDate}
                  maxDate={getMaxDate()}
                  autoComplete="off"
                />
              )}
            />
          )}
          <Can
            module="feedback-assignment-periods"
            permission="edit"
            yes={() => (
              <button type="submit" className="btn fm-btn-primary block pull-right">
                {t('buttons.create')}
              </button>
            )}
            no={() => null}
          />
        </form>
      </div>
    </div>
  );
}

InnerForm.propTypes = {
  feedbackAssignmentPeriod: PropTypes.object,
  updateFeedbackAssignmentPeriod: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
