import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { faBuilding, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { isTenantExist } from '../../services/tenant/tenantProvider';
import { resetPasswordRequest } from '../../services/user/userProvider';
import { RESET_PASSWORD_REQUESTED } from '../../constants/routes';
import Notifications from '../../utils/Notifications';
import MaterialInput from '../react-hook-form-inputs/MaterialInput';

function InnerForm(props) {
  const { t, tenant, history, changeLocation } = props;

  const schema = Yup.lazy((values) =>
    Yup.object().shape({
      tenant: Yup.string()
        .required(t('message.required_field'))
        .test('checkTenantIsExist', t('message.tenant_not_exist'), (value) => {
          if (!value) {
            return null;
          }
          return new Promise((resolve, reject) => {
            isTenantExist({ name: values.tenant })
              .then((data) => {
                resolve(data);
              })
              .catch(() => {
                resolve(false);
              });
          });
        }),
      loginInfo: Yup.string().required(t('message.required_field')),
    }),
  );

  const requestResetPassword = (values, errorCallback) => {
    resetPasswordRequest(values)
      .then((response) => {
        if (response) {
          history.push(RESET_PASSWORD_REQUESTED);
        } else {
          Notifications.error(t('message.not_able_to_reset_password'));
        }
      })
      .catch((error) => {
        Notifications.error(error);
        errorCallback && errorCallback();
      });
  };

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: { tenant },
  });

  const { handleSubmit } = form;
  const onSubmit = (values) => {
    requestResetPassword(values, (v) => form.reset({ tenant: '', loginInfo: '' }));
  };
  const values = form.getValues();

  return (
    <div>
      <div className="fm-login-text text-center">
        <span>{t('message.forgot_your_password')}</span> <br />
        <span>{t('message.enter_email_or_login')}</span>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="m-t fm-reset-password-form">
        <div className="fm-reset-password-input-container">
          <div className="fm-element-form">
            <div className="fm-reset-password-icon">
              <FontAwesomeIcon icon={faBuilding} />
            </div>
            <div className="fm-reset-password-input">
              <Controller
                control={form.control}
                name="tenant"
                id="tenant"
                defaultValue=""
                render={({ onChange, onBlur, value, name }) => (
                  <MaterialInput
                    name={name}
                    placeholder={t('fields.tenant')}
                    label={t('fields.tenant')}
                    form={form}
                    type="text"
                    onBlur={onBlur}
                    onChange={onChange}
                    autoComplete="tenant"
                    value={value}
                  />
                )}
              />
            </div>
          </div>
          <div className="fm-element-form">
            <div className="fm-reset-password-icon">
              <FontAwesomeIcon icon={faMailBulk} />
            </div>
            <div className="fm-reset-password-input">
              <Controller
                control={form.control}
                name="loginInfo"
                id="loginInfo"
                defaultValue=""
                render={({ onChange, onBlur, value, name }) => (
                  <MaterialInput
                    name={name}
                    placeholder={t('fields.email_or_login')}
                    label={t('fields.email_or_login')}
                    form={form}
                    type="text"
                    maxLength="1024"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="fm-auth-box">
          <button type="submit" className="btn fm-btn-primary block m-b fm-login-btn">
            {t('buttons.continue')}
          </button>
        </div>
        <div className="fm-auth-box fm-login-link">
          <button type="button" className="btn btn-link" onClick={() => changeLocation('/login')}>
            <small>{t('buttons.back_to_sign_in')}</small>
          </button>
        </div>
      </form>
    </div>
  );
}

InnerForm.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  changeLocation: PropTypes.func.isRequired,
};

export default withTranslation()(withRouter(InnerForm));
