import dataProvider from '../dataProvider';

import { ongoingfeedback, ongoingfeedbackskill } from '../../constants/endpoints';

export const getOngoingFeedbackList = (params) =>
  dataProvider({
    url: ongoingfeedback.all(),
    params,
    method: 'GET',
  });

export const getOngoingFeedback = (ongoingFeedbackId) =>
  dataProvider({
    url: ongoingfeedback.getById(ongoingFeedbackId),
    method: 'GET',
  });

export const updateOngoingFeedback = (params) =>
  dataProvider({
    url: ongoingfeedback.update(params.id),
    data: params,
    method: 'PUT',
  });

export const createOngoingFeedback = (params) =>
  dataProvider({
    url: ongoingfeedback.create(),
    data: params,
    method: 'POST',
  });

export const deleteOngoingFeedback = (ongoingFeedbackId) =>
  dataProvider({
    url: ongoingfeedback.delete(ongoingFeedbackId),
    method: 'DELETE',
  });

export const getOngoingFeedbacksByNameLike = (name) =>
  dataProvider({
    url: ongoingfeedbackskill.getByNameLike(name),
    method: 'GET',
  });

export const countAdvice = (userId) =>
  dataProvider({
    url: ongoingfeedback.countAdvice(userId),
    method: 'GET',
  });

export const countPraise = (userId) =>
  dataProvider({
    url: ongoingfeedback.countPraise(userId),
    method: 'GET',
  });

export const getUserTips = (userId) =>
  dataProvider({
    url: ongoingfeedback.getUserTips(userId),
    method: 'GET',
  });
export const getUserPraise = (userId) =>
  dataProvider({
    url: ongoingfeedback.getUserPraise(userId),
    method: 'GET',
  });
