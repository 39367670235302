import dataProvider from '../dataProvider';

import { employeejob } from '../../constants/endpoints';

export const getEmployeeJobList = (params) =>
  dataProvider({
    url: employeejob.all(),
    params,
    method: 'GET',
  });

export const getEmployeeJob = (employeeJobId) =>
  dataProvider({
    url: employeejob.getById(employeeJobId),
    method: 'GET',
  });

export const createEmployeeJob = (params) =>
  dataProvider({
    url: employeejob.create(),
    data: params,
    method: 'POST',
  });

export const updateEmployeeJob = (params) =>
  dataProvider({
    url: employeejob.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteEmployeeJob = (employeeJobId) =>
  dataProvider({
    url: employeejob.delete(employeeJobId),
    method: 'DELETE',
  });

export const getEmployeeJobPagination = (params) =>
  dataProvider({
    url: employeejob.pagination(),
    data: params,
    method: 'POST',
  });

export const getEmployeeJobByNameLike = (name) =>
  dataProvider({
    url: employeejob.getByNameLike(name),
    method: 'GET',
  });

export const getActivityStatus = () =>
  dataProvider({
    url: employeejob.getActivityStatus(),
    method: 'GET',
  });

export const getEmployeeJobSelectItemSource = (params) =>
  dataProvider({
    url: employeejob.itemSource(),
    data: params,
    method: 'POST',
  });
