import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import Input from '../react-hook-form-inputs/Input';
import TextArea from '../react-hook-form-inputs/TextArea';

function InnerForm(props) {
  const { t, cancel, createOrgUnit } = props;

  const schema = Yup.object().shape({
    name: Yup.string().required(t('message.organization_unit_type_name_required')),
  });

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const { handleSubmit } = form;

  const onSubmit = (values) => {
    createOrgUnit({
      name: values.name,
      description: values.description,
    });
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <div className="modal-body">
        <Controller
          control={form.control}
          name="name"
          defaultValue=""
          render={({ onChange, onBlur, name, value }) => (
            <Input
              form={form}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              type="text"
              placeholder={t('fields.organization_unit_type_name')}
              label={t('fields.organization_unit_type_name')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="description"
          defaultValue=""
          render={({ onChange, onBlur, name, value }) => (
            <TextArea
              form={form}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              type="text"
              placeholder={t('fields.description')}
              label={t('fields.description')}
            />
          )}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
        <button type="submit" className="btn fm-btn-primary">
          {t('buttons.create')}
        </button>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  createOrgUnit: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
