import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function InnerForm(props) {
  const { t, recommendations } = props;
  const [hidden, setHidden] = useState(true);

  const collapseRecommendation = () => {
    setHidden(!hidden);
  };

  return (
    <div className="fm-survey-assignment-recommendations-container">
      <div className="fm-survey-assignment-recommendation-title" onClick={collapseRecommendation}>
        <div className="fm-survey-assignment-recommendation-title-text">
          {t('components.recommendation')}
        </div>
        <div className="-collapse-btn">
          <FontAwesomeIcon icon={hidden ? faChevronRight : faChevronDown} />
        </div>
      </div>
      {!hidden && (
        <div className="fm-recommendations-box">
          {recommendations &&
            recommendations.map((item) => (
              <div className="fm-recommendation" key={item.key}>
                <div className="-label">{item.key}</div>
                <div className="-description">{item.value}</div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

InnerForm.propTypes = {
  t: PropTypes.func.isRequired,
  recommendations: PropTypes.array.isRequired,
};
export default withTranslation()(InnerForm);
