import React, { Component } from 'react';

import { connect } from 'react-redux';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  batchUpdateFeedbackAssignmentReviewers,
  getAllEmployeesFeedbackAssignmentProfilesPagination,
  getFeedbackAssignmentProfilesByManagerIdPagination,
  getFeedbackAssignmentReviewerList,
} from '../../services/feedbackassignmentreviewer/feedbackAssignmentReviewerProvider';
import Loader from '../../components/loader/Loader';
import Notifications from '../../utils/Notifications';
import ManagerSubordinateFeedbackAssignmentRequestsTable from '../../components/feedbackassignments/EmployeesFeedbackAssignmentRequestsTable';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';
import Modal from '../../components/modal/Modal';
import AddFeedbackReviewersModal from '../../components/feedbackassignments/AddFeedbackReviewersModal';
import { getNotAssignedToFeedbackEmployeeByNameLike } from '../../services/employee/employeeProvider';
import EmployeesFeedbackAssignmentRequestsTable from '../../components/feedbackassignments/EmployeesFeedbackAssignmentRequestsTable';

class AllEmployeesFeedbackAssignmentRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      tableRef: null,
      isModalShown: false,
      reviewers: [],
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_employees_feedback_requests'),
        ref: null,
        isActive: true,
      },
    ];
  };

  getAllEmployeesFeedbackAssignmentProfilesPagination = (
    amount,
    skip,
    sorting,
    filters,
    callback,
  ) => {
    getAllEmployeesFeedbackAssignmentProfilesPagination({
      amount,
      skip,
      sortings: sorting,
      filters,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  openAddReviewerModal = (feedbackAssignmentId, reviewers) => {
    this.setState({
      isModalShown: true,
      feedbackAssignmentId,
      reviewers,
    });
  };

  closeModal = () => {
    this.setState({ isModalShown: false });
  };

  loadReviewerOptions = (query, callback) => {
    getNotAssignedToFeedbackEmployeeByNameLike(this.state.feedbackAssignmentId, query)
      .then((foundOptions) => {
        callback && callback(foundOptions);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  addReviewerHandleSubmit = (newReviewers) => {
    const { t } = this.props;
    const { feedbackAssignmentId } = this.state;
    const submitReviewersData = [];
    newReviewers.forEach((newReviewer) => {
      submitReviewersData.push({
        object: {
          reviewerId: newReviewer.value,
          feedbackAssignmentId,
          selfReview: false,
          assignmentStatus: 'New',
        },
        action: 'create',
        objectType: 'Platform:FeedbackAssignmentReviewer',
      });
    });

    batchUpdateFeedbackAssignmentReviewers(submitReviewersData)
      .then(() => {
        this.state.tableRef.updateTableData(false);
        Notifications.success(t('message.feedback_assignment_reviewer_added'));
        this.setState({
          isLoading: false,
          isModalShown: false,
          feedbackAssignmentId: null,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { tableRef, isLoading, isModalShown, feedbackAssignmentId } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.all_employees_feedback_requests')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>

        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle name={t('components.all_employees_feedback_requests')} />
            <EmployeesFeedbackAssignmentRequestsTable
              fetchFeedbackAssignmentList={this.getAllEmployeesFeedbackAssignmentProfilesPagination}
              setTableReference={this.setTableReference}
              openAddReviewerModal={this.openAddReviewerModal}
              tableReference={tableRef}
            />
          </div>
        </div>
        <Modal
          header={t('components.add_reviewers')}
          isOpened={isModalShown}
          icon={faUser}
          onClose={this.closeModal}
        >
          <AddFeedbackReviewersModal
            loadReviewerOptions={this.loadReviewerOptions}
            handleReviewersSubmit={this.addReviewerHandleSubmit}
            feedbackAssignmentId={feedbackAssignmentId}
            cancel={this.closeModal}
          />
        </Modal>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withTranslation()(connect(mapStateToProps)(AllEmployeesFeedbackAssignmentRequests));
