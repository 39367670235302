import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { verify } from '../../services/account/authProvider';
import { LOGIN } from '../../constants/routes';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import Approve from '../../components/svg/Approve';
import FormeasureLogo from '../../styles/img/logo_formeasure@2x.png';
import Reject from '../../components/svg/Reject';

class Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isVerified: false,
      tenant: '',
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const { verificationToken } = this.props.match.params;
    if (!verificationToken) {
      this.setState({ isLoading: false });
    }
    verify({ verificationToken })
      .then((data) => {
        this.setState({
          isLoading: false,
          isVerified: true,
          tenant: data.tenant,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { isLoading, isVerified } = this.state;
    const toLoginLink = `${LOGIN}${this.state.tenant ? `/${this.state.tenant}` : ''}`;
    // const toRegistrationLink = `${ ROOT }${ REGISTRATION }`;
    const { t } = this.props;
    return (
      <div className="wrapper-content">
        <div className="middle-box text-center">
          <div className="ibox fm-verification-box">
            <div className="fm-auth-logo text-center">
              <img src={FormeasureLogo} />
            </div>
            <h3>{t('components.account_verification')}</h3>
            {isLoading ? (
              <div>
                <p>{t('components.verification_in_progress')}</p>
              </div>
            ) : isVerified ? (
              <>
                <div className="fm-verification-image-status">
                  <Approve />
                </div>
                <p className="success fm-verification-label">{t('components.verified')}!</p>
                <div className="fm-verification-button">
                  <Link className="btn" to={toLoginLink}>
                    {t('link.login')}
                  </Link>
                </div>
              </>
            ) : (
              <>
                <div className="fm-verification-image-status">
                  <Reject />
                </div>
                <p className="error fm-verification-label">{t('components.not_verified')}!</p>
                <div className="fm-verification-button">
                  {/* <Link className="btn fm-btn-edit" */}
                  {/*      to={ toRegistrationLink }>{ t('link.create_account') }</Link> */}
                  <Link className="btn" to={toLoginLink}>
                    {t('link.login')}
                  </Link>
                </div>
              </>
            )}
          </div>
          <ToastContainer theme="colored" />
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default withTranslation()(Verification);
