import React, { Component } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSitemap, faTimes, faUser, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import FormTitle from '../../components/form/FormTitle';
import PageNameContainer from '../../components/page/PageNameContainer';
import EditUserForm from '../../components/user/EditUserForm';
import UserRolesTable from '../../components/user/UserRolesTable';
import AddUserRoleModal from '../../components/user/AddUserRoleModal';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import ImageCropperModal from '../../components/cropper/ImageCropperModal';
import Modal from '../../components/modal/Modal';
import { getLanguageSelectItems, getUserById, updateUser } from '../../services/user/userProvider';
import { addUserRolesToUser, getUserRoleByUserId } from '../../services/user/userRoleProvider';
import FileUtil from '../../utils/FileUtil';
import UrlUtil from '../../utils/UrlUtil';
import Can from '../../components/security/Can';
import { checkPermission } from '../../utils/SecurityUtil';
import { setUserDetails } from '../../services/account/authActions';

class EditUserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      extension: '',
      image: null,
      user: null,
      isModalShown: false,
      isModalRolesShown: false,
      overritenImage: false,
    };
    this.avatarUploaderRef = React.createRef();
    this.validationArray = [];
    this.submitionArray = [];
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_users'),
        ref: '/users',
        isActive: false,
      },
      {
        name: t('breadcrumbs.user'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { params } = this.props.match;
    this.setState({ isLoading: true });
    const promises = [];
    promises.push(getLanguageSelectItems());
    promises.push(getUserById(params.userId));
    Promise.all(promises)
      .then(([langData, userData]) => {
        this.setState({
          image: userData && userData ? userData.profileImage : null,
          extension: userData && userData ? userData.profileImageExtension : '',
          user: userData,
          isLoading: false,
          languageOptions: langData,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  handleAvatarClick = () => {
    const { t, account } = this.props;
    const { permissions } = account;
    if (checkPermission('users', permissions, 'edit')) {
      this.avatarUploaderRef.current.click();
    } else {
      Notifications.warning(t('message.dont_have_edit_permission'));
    }
  };

  handleAvatarChange = (e) => {
    const { t } = this.props;
    const { target } = e.nativeEvent;
    const { files } = target;
    const file = files[0];
    if (!files || !file) {
      return;
    }
    if (files[0].size > 1024 * 1024 * 10) {
      Notifications.error(t('message.file_too_big'));
      return;
    }

    const reader = new FileReader();

    reader.onload = (readerEvt) => {
      this.setState({
        isModalShown: true,
        image: btoa(readerEvt.target.result),
        extension: FileUtil.getFileExtension(FileUtil.getFileName(target.value)),
      });
    };
    reader.readAsBinaryString(file);
  };

  deleteProfileImage = () => {
    const { user } = this.state;
    user.profileImageLink = null;
    this.setState({
      image: null,
      extension: null,
      user,
    });
  };

  handleSubmitModal = (image) => {
    const { extension } = this.state;
    this.setState({
      isModalShown: false,
      image,
      extension,
      overritenImage: true,
    });
    this.setState({ isModalShown: false });
    this.avatarUploaderRef.current.value = '';
  };

  closeModal = () => {
    this.setState({ isModalShown: false });
  };

  addValidation = (validation) => {
    this.validationArray.push(validation);
  };

  addSubmition = (submition) => {
    this.submitionArray.push(submition);
  };

  handleSubmitForm = () => {
    const fArray = [];
    _.forEach(this.validationArray, (func) => {
      fArray.push(func());
    });

    Promise.all(fArray).then((arr) => {
      let hasError = false;
      _.forEach(arr, (result) => {
        if (!_.isEmpty(result)) {
          hasError = true;
        }
      });
      if (!hasError) {
        _.forEach(this.submitionArray, (s) => s());
      }
    });
  };

  setUserRef = (userProfileRef) => {
    this.setState({ userProfileRef });
  };

  updateUser = (values) => {
    this.setState({ isLoading: true });

    const { image, extension, user } = this.state;
    const { dispatchUserDetails, account, t, i18n } = this.props;

    user.phone = values.phone;
    user.language = values.language ? values.language.value : null;

    user.username = values.username;
    user.verified = values.verified;
    user.email = values.email;

    user.firstName = values.firstName;
    user.lastName = values.lastName;
    user.middleName = values.middleName;
    user.profileImage = image;
    user.profileImageExtension = extension;

    const me = this;

    updateUser(user.id, user)
      .then(() => {
        const promises = [];
        promises.push(getLanguageSelectItems());
        promises.push(getUserById(user.id));
        Promise.all(promises)
          .then(([langData, userData]) => {
            me.setState({
              user: userData,
              isLoading: false,
              languageOptions: langData,
            });

            if (account.userId === user.id) {
              i18n.changeLanguage(userData.language);
              dispatchUserDetails({
                ...account,
                user,
              });
            }
          })
          .catch((error) => {
            Notifications.error(error);
            this.setState({ isLoading: false });
          });
        Notifications.success(t('message.user_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  getUserRoles = (amount, skip, sorting, filters, callback) => {
    const { user } = this.state;
    getUserRoleByUserId(user.id)
      .then((data) => {
        callback({
          size: data.length,
          skip: 0,
          totalSize: data.length,
          items: data,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  openAddUserRoleModal = () => {
    this.setState({ isModalRolesShown: true });
  };

  closeModalRoles = () => {
    this.setState({ isModalRolesShown: false });
  };

  addUserRole = (values) => {
    const { user } = this.state;
    const idList = values ? values.map((v) => v.value) : [];

    addUserRolesToUser(user.id, idList)
      .then(() => {
        this.state.tableRef.updateTableData(false);
        this.setState({ isModalShown: false });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const {
      user,
      isLoading,
      image,
      isModalShown,
      extension,
      tableRef,
      languageOptions,
      isModalRolesShown,
      overritenImage,
    } = this.state;
    const { t } = this.props;
    const imageLink = user && user ? user.profileImageLink : null;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer name={t('containers.user')} breadcrumbs={this.getBreadcrumbs()} />
          </div>
        </div>

        {user && (
          <div className="wrapper-content">
            <div className="row">
              <div className="col-md-4">
                <div className="ibox float-e-margins">
                  <FormTitle name={t('components.photo')} />

                  <div className="ibox-content no-padding border-left-right image-container">
                    {imageLink && !overritenImage ? (
                      <img
                        className="img-responsive fm-profile-img"
                        src={UrlUtil.buildImageSrcLink(imageLink)}
                        id="avatar-image"
                        onClick={this.handleAvatarClick}
                        alt={t('components.photo')}
                      />
                    ) : image && extension ? (
                      <img
                        className="img-responsive fm-profile-img"
                        src={`data:image/${extension};base64,${image}`}
                        id="avatar-image"
                        onClick={this.handleAvatarClick}
                        alt={t('components.photo')}
                      />
                    ) : (
                      <div className="fm-profile-photo-container">
                        <div
                          onClick={this.handleAvatarClick}
                          id="avatar-image"
                          className="img-responsive fm-user-profile-empty-photo fm-profile-img"
                        >
                          <FontAwesomeIcon icon={faUser} />
                        </div>
                      </div>
                    )}
                    <input
                      type="file"
                      name="profilePicture"
                      id="profilePicture"
                      ref={this.avatarUploaderRef}
                      style={{ display: 'none' }}
                      onChange={this.handleAvatarChange}
                    />
                    <div
                      className="fm-delete-btn"
                      title={t('components.delete_photo')}
                      onClick={this.deleteProfileImage}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>
                  <Modal
                    header={t('components.crop_image')}
                    isOpened={isModalShown}
                    icon={faUserAlt}
                    onClose={this.closeModal}
                  >
                    {image && (
                      <ImageCropperModal
                        handleSubmit={this.handleSubmitModal}
                        image={image}
                        closeModal={this.closeModal}
                        extension={extension}
                      />
                    )}
                  </Modal>
                </div>
                <div className="ibox float-e-margins">
                  <FormTitle
                    name={t('components.user_roles')}
                    buttons={[
                      {
                        size: 'btn-xs',
                        importance: 'fm-btn-primary',
                        onClick: this.openAddUserRoleModal,
                        name: t('buttons.add_role'),
                        icon: faPlus,
                        securityModule: 'users',
                        securityPermission: 'add-role',
                      },
                    ]}
                  />
                  <div className="ibox-content clearfix fm-no-padding">
                    <UserRolesTable
                      user={user}
                      fetchUserRoles={this.getUserRoles}
                      setTableReference={this.setTableReference}
                      tableReference={tableRef}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="ibox">
                  <FormTitle name={t('components.user_info')} />
                  <div className="ibox-content clearfix">
                    <EditUserForm
                      user={user}
                      languageOptions={languageOptions}
                      updateUser={this.updateUser}
                      setUserRef={this.setUserRef}
                      addValidation={this.addValidation}
                      addSubmition={this.addSubmition}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="footer fixed">
              <Can
                module="users"
                permission="edit"
                yes={() => (
                  <div className="btn-group pull-right" role="group">
                    <button
                      onClick={this.handleSubmitForm}
                      type="button"
                      className="btn fm-btn-primary btn-sm"
                    >
                      {t('buttons.save')}
                    </button>
                  </div>
                )}
                no={() => null}
              />
            </div>
            <Modal
              header={t('components.add_user_role')}
              isOpened={isModalRolesShown}
              icon={faSitemap}
              onClose={this.closeModalRoles}
            >
              <AddUserRoleModal
                user={user}
                addUserRole={this.addUserRole}
                cancel={this.closeModalRoles}
              />
            </Modal>
            {isLoading && <Loader />}
          </div>
        )}
      </div>
    );
  }
}

EditUserPage.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchUserDetails: PropTypes.func.isRequired,
  account: PropTypes.shape({
    userId: PropTypes.number.isRequired,
    permissions: PropTypes.array.isRequired,
  }).isRequired,
  i18n: PropTypes.shape({
    changeLanguage: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUserDetails: (userDetails) => {
    dispatch(setUserDetails({ userDetails }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditUserPage)),
);
