import React from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import Moment from 'moment';

import { withTranslation } from 'react-i18next';
import InputComponent from '../inputs/Input';
import TextAreaComponent from '../inputs/Textarea';
import FormTitle from '../form/FormTitle';

import Validation from '../../utils/validation';

import SelectComponent from '../inputs/Select';
import Can from '../security/Can';

function InnerForm(props) {
  const { t, handleSubmit, setFieldValue, statusOptions } = props;
  return (
    <div className="ibox">
      <FormTitle name={t('components.common_information')} />
      <div className="ibox-content clearfix">
        <Form onSubmit={handleSubmit}>
          <Field
            component={InputComponent}
            placeholder={t('fields.job_name')}
            type="text"
            name="name"
            label={t('fields.job_name')}
          />
          <Field
            component={TextAreaComponent}
            placeholder={t('fields.description')}
            type="text"
            name="description"
            label={t('fields.description')}
          />
          <Field
            component={SelectComponent}
            placeholder={t('fields.status')}
            type="text"
            name="activityStatus"
            options={statusOptions}
            setFieldValue={setFieldValue}
            label={t('fields.status')}
          />
          <Field
            component={InputComponent}
            placeholder={t('fields.created')}
            type="text"
            name="created"
            label={t('fields.created')}
            disabled
          />
          <Field
            component={InputComponent}
            placeholder={t('fields.updated')}
            type="text"
            name="updated"
            label={t('fields.updated')}
            disabled
          />
          <Can
            module="jobs"
            permission="edit"
            yes={() => (
              <button type="submit" className="btn fm-btn-primary pull-right block">
                {t('buttons.save')}
              </button>
            )}
            no={() => null}
          />
        </Form>
      </div>
    </div>
  );
}

InnerForm.propTypes = {
  employeeJob: PropTypes.object,
  updateEmployeeJob: PropTypes.func.isRequired,
  statusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const EditOrgUnitDetailsForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: (values) => {
    const { employeeJob, statusOptions } = values;
    return {
      ...employeeJob,
      description: employeeJob.description ? employeeJob.description : '',
      activityStatus: employeeJob.activityStatus
        ? statusOptions.find((el) => el.value === employeeJob.activityStatus)
        : '',
      created: employeeJob.created ? Moment(employeeJob.created).format('LL') : '',
      updated: employeeJob.updated ? Moment(employeeJob.updated).format('LL') : '',
    };
  },
  validate: (values) => {
    const errors = {};
    errors.name = Validation.required(values.name);
    errors.activityStatus = Validation.required(values.activityStatus.value);
    return _.isEmpty(_.filter(errors, (value) => value)) ? {} : errors;
  },
  handleSubmit: (values, props) => {
    const valsToSave = {
      ...values,
      activityStatus: values.activityStatus ? values.activityStatus.value : null,
    };
    props.props.updateEmployeeJob(valsToSave);
  },
})(InnerForm);

export default withTranslation()(EditOrgUnitDetailsForm);
