import React, { Component } from 'react';

import { faPlus, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  createTestAssignment,
  getTestAssignmentPagination,
} from '../../../services/testassignment/testAssignmentProvider';
import AllTestAssignmentsTable from '../../../components/testassignment/AllTestAssignmentsTable';
import Loader from '../../../components/loader/Loader';
import Notifications from '../../../utils/Notifications';
import Modal from '../../../components/modal/Modal';
import CreateTestAssignmentModal from '../../../components/testassignment/CreateTestAssignmentModal';
import FormTitle from '../../../components/form/FormTitle';
import PageNameContainer from '../../../components/page/PageNameContainer';

class AllTestAssignments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isModalShown: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_survey_assignments'),
        ref: null,
        isActive: true,
      },
    ];
  };

  fetchTestAssignmentList = (amount, skip, sorting, filters, callback) => {
    getTestAssignmentPagination({
      amount,
      skip,
      sortings: sorting,
      filters,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  openCreateTestAssignmentModal = () => {
    this.setState({ isModalShown: true });
  };

  closeModal = () => {
    this.setState({ isModalShown: false });
  };

  createTestAssignment = (values) => {
    const { t } = this.props;

    this.setState({
      isLoading: true,
      isModalShown: false,
    });
    createTestAssignment(values)
      .then(() => {
        this.state.tableRef.updateTableData(false);
        this.setState({ isLoading: false });
        Notifications.success(t('message.survey_assignment_created'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { tableRef, isLoading, isModalShown } = this.state;
    const { t } = this.props;

    return (
      <>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.all_survey_assignments')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.all_survey_assignments')}
              buttons={[
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.openCreateTestAssignmentModal,
                  name: t('buttons.create'),
                  icon: faPlus,
                  securityModule: 'test-assignments',
                  securityPermission: 'create',
                },
              ]}
            />
            <AllTestAssignmentsTable
              fetchTestAssignmentList={this.fetchTestAssignmentList}
              setTableReference={this.setTableReference}
              tableReference={tableRef}
              canDelete
              canView
              canPassTest={false}
            />
          </div>
        </div>
        <Modal
          header={t('components.create_survey_assignment')}
          isOpened={isModalShown}
          icon={faSitemap}
          onClose={this.closeModal}
        >
          <CreateTestAssignmentModal
            createTestAssignment={this.createTestAssignment}
            cancel={this.closeModal}
          />
        </Modal>
        {isLoading && <Loader />}
      </>
    );
  }
}

AllTestAssignments.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AllTestAssignments);
