import { mapValues, isObject } from 'lodash';
import colors from '../constants/colors';

const ObjectUtil = {
  getRandomProperty: (obj) => {
    const keys = Object.keys(obj);
    return obj[keys[(keys.length * Math.random()) << 0]];
  },
  getRandomFromArray: (arr) => arr[Math.floor(Math.random() * arr.length)],
  getRandomColor: () => ObjectUtil.getRandomFromArray(ObjectUtil.getRandomProperty(colors)),
  mapValuesDeep: (v, callback) =>
    isObject(v) ? mapValues(v, (v) => ObjectUtil.mapValuesDeep(v, callback)) : callback(v),
  exists: (value) => typeof value !== 'undefined' && value !== null && value !== '',
};

export default ObjectUtil;
