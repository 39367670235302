import React, { Component } from 'react';
import { Field, withFormik } from 'formik';
import '../styles/questions.css';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import MaterialCheckbox from '../../inputs/MaterialCheckbox';

class MultipleChoiceQuestion extends Component {
  constructor(props) {
    super(props);

    const { questionAnswerResults } = this.props;
    this.state = {
      answerResults: questionAnswerResults.map((v) => v.answerId),
      qarInitState: questionAnswerResults.map((v) => v.answerId),
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const oldQuestionAnswerResults = this.props.questionAnswerResults;
    const newQuestionAnswerResults = newProps.questionAnswerResults;

    if (!_.isEqual(oldQuestionAnswerResults, newQuestionAnswerResults)) {
      this.setState({
        qarInitState: newQuestionAnswerResults.map((v) => v.answerId),
        answerResults: newQuestionAnswerResults.map((v) => v.answerId),
      });
    }
  }

  buildAnswer = (e, question, answer) => {
    const { testAssignment, populateUserAnswer, questionAnswerResults } = this.props;
    const { target } = e;

    if (target.checked) {
      !this.state.answerResults.includes(answer.id) && this.state.answerResults.push(answer.id);
    } else {
      this.state.answerResults.splice(this.state.answerResults.indexOf(answer.id), 1);
    }
    const result = [];
    questionAnswerResults.forEach((value) => {
      if (!this.state.answerResults.includes(value.answerId)) {
        result.push({
          object: {
            id: value.id,
          },
          action: 'delete',
          objectType: 'Platform:QuestionAnswerResult',
        });
      }
    });

    this.state.answerResults.forEach((value) => {
      if (!this.state.qarInitState.includes(value)) {
        result.push({
          object: {
            answerId: value,
            questionId: question.id,
            testAssignmentId: testAssignment.id,
          },
          action: 'create',
          objectType: 'Platform:QuestionAnswerResult',
        });
      }
    });
    populateUserAnswer(answer.questionId, result);
  };

  render() {
    const { t, question, setFieldValue, handleChange, values, animation } = this.props;
    return (
      <div className={`animated ${animation}`}>
        {question && question.id ? (
          <div className="fm-answer-container clearfix">
            {question.answers && question.answers.length > 0
              ? question.answers.map((answer) => (
                  <div className="i-checks" key={answer.id}>
                    <Field
                      component={MaterialCheckbox}
                      type="checkbox"
                      name={`question-${question.id}-answer-${answer.id}`}
                      color="primary"
                      checked={!!values[`question-${question.id}-answer-${answer.id}`]}
                      setFieldValue={setFieldValue}
                      onChange={(e) => {
                        this.buildAnswer(e, question, answer);
                        handleChange(e);
                      }}
                      label={answer.answer}
                    />
                  </div>
                ))
              : null}
          </div>
        ) : (
          <h3>{t('components.loading')}</h3>
        )}
      </div>
    );
  }
}

MultipleChoiceQuestion.propTypes = {
  populateUserAnswer: PropTypes.func.isRequired,
};

export default withTranslation()(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ questionAnswerResults, question }) => {
      if (questionAnswerResults && questionAnswerResults.length > 0) {
        const result = {};

        question.answers.forEach((v) => {
          result[`question-${question.id}-answer-${v.id}`] = false;
        });
        questionAnswerResults.forEach((v) => {
          result[`question-${question.id}-answer-${v.answerId}`] = true;
        });
        return result;
      }
      return {};
    },
  })(MultipleChoiceQuestion),
);
