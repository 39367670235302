import React, { Component } from 'react';

class NegativeSmile extends Component {
  render() {
    return (
      <div>
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Desktop-HD" transform="translate(-619.000000, -1534.000000)" fill="#F88781">
              <g id="Group-19-Copy-2" transform="translate(269.000000, 1184.333333)">
                <g id="Solid/check-circle" transform="translate(350.000000, 349.000000)">
                  <path
                    d="M24,12.6666667 C24,19.2941022 18.6274355,24.6666667 12,24.6666667 C5.37256452,24.6666667 0,19.2941022 0,12.6666667 C0,6.03923118 5.37256452,0.666666667 12,0.666666667 C18.6274355,0.666666667 24,6.03923118 24,12.6666667 Z M7.4005277,19.2543096 C8.93368513,18.8625476 10.4668426,18.6666667 12,18.6666667 C13.5331574,18.6666667 15.0663149,18.8625476 16.5994723,19.2543096 C17.2001657,19.4078199 17.8115588,19.0452897 17.9650528,18.4445921 C17.9882596,18.3537727 18,18.2604042 18,18.1666667 L18,17.7792806 C17.9999728,17.102417 17.5466589,16.5094114 16.893502,16.3318434 C15.2623347,15.8883922 13.6311673,15.6666667 12,15.6666667 C10.3688341,15.6666667 8.73766821,15.8883918 7.10650231,16.3318422 C6.45332562,16.5094039 6,17.1024247 6,17.7793058 L6,18.1666667 C6,18.7866651 6.502608,19.2892731 7.12260639,19.2892731 C7.21634393,19.2892731 7.30971241,19.2775326 7.4005277,19.2543096 Z M8,11.6666667 C9.1045695,11.6666667 10,10.7712362 10,9.66666667 C10,8.56209717 9.1045695,7.66666667 8,7.66666667 C6.8954305,7.66666667 6,8.56209717 6,9.66666667 C6,10.7712362 6.8954305,11.6666667 8,11.6666667 Z M16,11.6666667 C17.1045695,11.6666667 18,10.7712362 18,9.66666667 C18,8.56209717 17.1045695,7.66666667 16,7.66666667 C14.8954305,7.66666667 14,8.56209717 14,9.66666667 C14,10.7712362 14.8954305,11.6666667 16,11.6666667 Z"
                    id="Mask"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

export default NegativeSmile;
