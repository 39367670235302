import dataProvider from '../dataProvider';

import { impact } from '../../constants/endpoints';

export const calculateImpact = (surveyXId, surveyYId) =>
  dataProvider({
    url: impact.impact(),
    params: {
      surveyXId,
      surveyYId,
    },
    method: 'GET',
  });
