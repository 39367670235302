import React, { Component } from 'react';

class StringRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = { id: props.original.id };
    this.onClick = props.column.onClickLink;
  }

  onClickLink = () => {
    if (this.onClick) {
      this.onClick(this.state.id, this.props.row);
    }
  };

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  render() {
    const { value } = this.props;
    return (
      <div className="fm-table-string" onClick={this.onClickLink}>
        <span title={value}>{value}</span>
      </div>
    );
  }
}

export default StringRenderer;
