import React, { Component } from 'react';
import _ from 'lodash';
import UserPhotoUtil from '../../utils/UserPhotoUtil';

class UserRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.original.id,
      userKeyPath: props.value.userKeyPath,
      employeeJobKeyPath: props.value.employeeJobKeyPath,
      employeeJobLevelKeyPath: props.value.employeeJobLevelKeyPath,
      userInfo: {
        user: _.get(props.original, props.value.userKeyPath),
        employeeJob: _.get(props.original, props.value.employeeJobKeyPath),
        employeeJobLevel: _.get(props.original, props.value.employeeJobLevelKeyPath),
      },
    };
  }

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({
        id: props.original.id,
        userInfo: {
          user: _.get(props.original, prevState.userKeyPath),
          employeeJob: _.get(props.original, prevState.employeeJobKeyPath),
          employeeJobLevel: _.get(props.original, prevState.employeeJobLevelKeyPath),
        },
      });
    }
  }

  render() {
    const { userInfo } = this.state;
    return (
      <div className="fm-table-user-info-container">
        {userInfo && userInfo.user && (
          <div className="fm-table-user-avatar-small-box">
            {UserPhotoUtil.renderUserPhotoByLink(userInfo.user.profileImageLink)}
          </div>
        )}
        {userInfo && (
          <div className="fm-table-user-info">
            {userInfo.user && (
              <span className="fm-table-username" title={userInfo.user.displayString}>
                {userInfo.user.displayString}
              </span>
            )}

            {userInfo.employeeJob && (
              <span
                className="fm-table-user-job"
                title={userInfo.employeeJob ? userInfo.employeeJob.name : ''}
              >
                {`${userInfo.employeeJobLevel ? userInfo.employeeJobLevel.displayString : ''} ${
                  userInfo.employeeJob ? userInfo.employeeJob.displayString : ''
                }`}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default UserRenderer;
