import dataProvider from '../dataProvider';
import { usergroup, usergroupuser } from '../../constants/endpoints';

export const getUserGroupList = (params) =>
  dataProvider({
    url: usergroup.all(),
    params,
    method: 'GET',
  });

export const getUserGroupByNameLike = (name) =>
  dataProvider({
    url: usergroup.getByNameLike(name),
    method: 'GET',
  });

export const getUserGroup = (userGroupId) =>
  dataProvider({
    url: usergroup.getById(userGroupId),
    method: 'GET',
  });

export const createUserGroup = (values) =>
  dataProvider({
    url: usergroup.create(),
    method: 'POST',
    data: values,
  });

export const updateUserGroup = (params) =>
  dataProvider({
    url: usergroup.update(params.id),
    data: params,
    method: 'PUT',
  });

export const getUserGroupSelectItemSource = (params) =>
  dataProvider({
    url: usergroup.itemSource(),
    method: 'POST',
    data: params,
  });

export const getUserGroupListPagination = (params) =>
  dataProvider({
    url: usergroup.pagination(),
    data: params,
    method: 'POST',
  });

export const deleteUserGroup = (itemId) =>
  dataProvider({
    url: usergroup.delete(itemId),
    method: 'DELETE',
  });

export const batchUpdateUserGroupUsers = (members) =>
  dataProvider({
    url: usergroupuser.batchUpdate(),
    data: members,
    method: 'POST',
  });

export const getUserGroupUsers = (id) =>
  dataProvider({
    url: usergroupuser.getByUserGroupId(id),
    method: 'GET',
  });
