import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Rate from 'rc-rate/es';

import { getUserPraise } from '../../services/ongoingfeedbacks/ongoingFeedbackProvider';
import Notifications from '../../utils/Notifications';
import ContentLoader from '../loader/ContentLoader';

import './styles/home.css';

export default function OngoingFeedbackPraiseModal({ closeModal, account }) {
  const { t } = useTranslation();
  const [praise, setPraise] = useState(null);

  useEffect(() => {
    getUserPraise(account.userId)
      .then((data) => {
        setPraise(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  let color = '#34B77C';
  if (praise < 4 && praise > 2.5) {
    color = '#E3B886';
  } else if (praise < 2.5) {
    color = '#F4564E';
  }

  return (
    <>
      {praise ? (
        <div className="fm-ongoing-feedback-praise">
          <Rate value={praise} />
          <span className="fm-ongoing-feedback-praise-value" style={{ color }}>
            ({praise?.toFixed(2) ?? 0})
          </span>
        </div>
      ) : (
        <ContentLoader visible />
      )}
      <div className="modal-footer">
        <button type="button" className="btn" onClick={closeModal}>
          {t('buttons.ok')}
        </button>
      </div>
    </>
  );
}

OngoingFeedbackPraiseModal.propTypes = {
  account: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};
