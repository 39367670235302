import React from 'react';
import { withTranslation } from 'react-i18next';
import Cow from '../../styles/img/NoDataCow.svg';

class NoDataTableComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="fm-table-no-data">
        <div>
          <img id="fm-table-image" src={Cow} />
        </div>
        <div>
          <span>{t('tables.no_data_present')}</span>
        </div>
      </div>
    );
  }
}

NoDataTableComponent.propTypes = {};

export default withTranslation()(NoDataTableComponent);
