import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AllCaseReports from '../../containers/bugtracking/AllCaseReports';
import { AUTH_ROUTES, CASE_ID } from '../../constants/routes';
import EditCaseReportPage from '../../containers/bugtracking/EditCaseReportPage';

class CaseTrackingRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllCaseReports} />
        <Route
          path={`${match.url}${CASE_ID}`}
          render={({ match }) => <CaseTrackingInnerRouting match={match} />}
        />
      </div>
    );
  }
}

CaseTrackingRoutes.propTypes = {
  match: PropTypes.object,
};

export default CaseTrackingRoutes;

class CaseTrackingInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let caseTrackingRouting;
    if (match.params.caseReportId) {
      switch (match.params.caseReportId) {
        default:
          caseTrackingRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.CASE_MANAGEMENT.BUG_TRACKING}${CASE_ID}`}
              component={AllCaseReports}
            />
          );
      }
    }

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.CASE_MANAGEMENT.BUG_TRACKING}${CASE_ID}/edit`}
          component={EditCaseReportPage}
        />
        {caseTrackingRouting}
      </div>
    );
  }
}

CaseTrackingInnerRouting.propTypes = {
  match: PropTypes.object,
};
