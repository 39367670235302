import React, { Component } from 'react';
import './styles/test-passing.css';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import EndPassingTestImage from '../../../styles/img/food.svg';
import { AUTH_ROUTES } from '../../../constants/routes';

class EndPassingTestForm extends Component {
  redirectToUserTestAssignments = () => {
    const { USER_TEST_ASSIGNMENTS } = AUTH_ROUTES;
    const { history } = this.props;
    const link = `${USER_TEST_ASSIGNMENTS}`;

    history.push(link, {});
  };

  render() {
    const { t } = this.props;
    return (
      <div className="ibox">
        <div className="ibox-content clearfix fm-round-border-form fm-end-passing-form">
          <div className="fm-image-center">
            <img src={EndPassingTestImage} />
          </div>
          <div className="fm-test-box text-center">
            <div className="fm-test-title">
              <span className="fm-test-name">{t('components.thank_you')}</span>
            </div>
            <div className="fm-test-description">
              <p>{t('message.stay_tuned_to_be_up_to_date_with_the_latest_news')}</p>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn fm-end-passing-btn"
                onClick={() => {
                  this.redirectToUserTestAssignments();
                }}
              >
                {t('buttons.close')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EndPassingTestForm.propTypes = {};
export default withTranslation()(withRouter(EndPassingTestForm));
