import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import FormTitle from '../form/FormTitle';
import './styles/testassignment-table.css';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import DatePicker from '../react-hook-form-inputs/DatePicker';
import TextArea from '../react-hook-form-inputs/TextArea';
import AsyncSelect from '../react-hook-form-inputs/AsyncSelect';
import Can from '../security/Can';
import { getTestByNameLike, getTestSelectItemSource } from '../../services/tests/testProvider';
import Notifications from '../../utils/Notifications';
import { getUserByNameLike, getUserSelectItemSource } from '../../services/user/userProvider';
import Toggle from '../react-hook-form-inputs/Toggle';

function InnerForm(props) {
  const { t, testAssignment, updateTestAssignment } = props;

  const schema = Yup.object().shape({
    deadline: Yup.date().required(t('message.required_field')).nullable(),
    test: Yup.mixed().required(t('message.required_field')),
    user: Yup.mixed().required(t('message.required_field')),
  });

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      description: testAssignment.description ? testAssignment.description : '',
      user: {
        value: testAssignment.userId,
        label: testAssignment.userDisplayString,
      },
      test: {
        value: testAssignment.test.id,
        label: testAssignment.test.displayString,
      },
      showResultToUser: testAssignment.showResultToUser,
      deadline: testAssignment.deadline ? new Date(moment.utc(testAssignment.deadline)) : null,
    },
  });
  const { handleSubmit, control } = form;

  const [defaultUsers, setDefaultUsers] = useState([]);
  const [defaultSurveys, setDefaultSurveys] = useState([]);

  useEffect(() => {
    const promises = [
      getUserSelectItemSource({ amount: 100, skip: 0 }),
      getTestSelectItemSource({ amount: 100, skip: 0 }),
    ];
    Promise.all(promises)
      .then(([defaultUsers, defaultSurveys]) => {
        setDefaultUsers(defaultUsers);
        setDefaultSurveys(defaultSurveys);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  const onSubmit = (data) => {
    const params = {
      ...testAssignment,
      userId: data.user ? data.user.value : null,
      testId: data.test ? data.test.value : null,
      deadline: data.deadline,
      showResultToUser: data.showResultToUser,
      description: data.description,
    };
    updateTestAssignment(params);
  };

  const loadTestOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getTestByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const loadUserOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getUserByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  return (
    <div className="ibox">
      <FormTitle name="Test Assignment" />
      <div className="ibox-content clearfix">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <Controller
            control={control}
            id="description"
            name="description"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <TextArea
                name={name}
                placeholder={t('fields.description')}
                label={t('fields.description')}
                form={form}
                rows={2}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            control={form.control}
            id="user"
            name="user"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <AsyncSelect
                name={name}
                placeholder={t('fields.user')}
                loadOptions={loadUserOptions}
                label={t('fields.user')}
                defaultOptions={defaultUsers}
                onChange={onChange}
                form={form}
                isMulti={false}
                isClearable
                cacheOptions
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <Controller
            id="showResultToUser"
            control={control}
            name="showResultToUser"
            defaultValue={false}
            render={({ onChange, onBlur, value, name }) => (
              <Toggle
                name={name}
                checked={value}
                label={t('fields.show_result_to_user')}
                toggleSize={6}
                form={{
                  touched: form.touched,
                  errors: form.errors,
                }}
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.checked)}
                value={value || false}
              />
            )}
          />
          <Controller
            control={control}
            id="test"
            name="test"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <AsyncSelect
                name={name}
                placeholder={t('fields.survey')}
                loadOptions={loadTestOptions}
                label={t('fields.survey')}
                defaultOptions={defaultSurveys}
                onChange={onChange}
                form={form}
                isMulti={false}
                isClearable
                cacheOptions
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <Controller
            control={form.control}
            id="deadline"
            name="deadline"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <DatePicker
                name={name}
                label={t('fields.deadline')}
                onChange={onChange}
                form={form}
                onBlur={onBlur}
                value={value}
                placeholderText={t('fields.date_format_mm_dd_yyyy')}
                autoComplete="off"
              />
            )}
          />
          <Can
            module="feedback-assignment"
            permission="edit"
            yes={() => (
              <button type="submit" className="btn fm-btn-primary block pull-right">
                {' '}
                {t('buttons.save')}
              </button>
            )}
            no={() => null}
          />
        </form>
      </div>
    </div>
  );
}

InnerForm.propTypes = {
  testAssignment: PropTypes.object.isRequired,
  updateTestAssignment: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
