import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Input from '../react-hook-form-inputs/Input';
import TextArea from '../react-hook-form-inputs/TextArea';
import AsyncSelectCreatable from '../react-hook-form-inputs/AsyncSelectCreatable';
import {
  getFeedbackCategoryByNameLike,
  getFeedbackCategorySelectItemSource,
} from '../../services/feedbackcategory/feedbackCategoryProvider';

function FeedbackCommonInformationForm(props) {
  const { t, setForm, feedback } = props;
  const [defaultFeedbackCategoryOptions, setDefaultFeedbackCategoryOptions] = useState([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('message.required_field')),
    description: Yup.string().nullable(),
    category: Yup.mixed().nullable(),
  });

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: feedback.name,
      description: feedback.description,
      category: feedback.category
        ? {
            value: feedback.category.id,
            label: feedback.category.name,
          }
        : null,
    },
  });

  useEffect(() => {
    setForm('feedback', form);
    getFeedbackCategorySelectItemSource({ amount: 100, skip: 0 })
      .then((data) => {
        setDefaultFeedbackCategoryOptions(data);
      })
      .catch((error) => {
        Notification.error(error);
      });
  }, []);

  const { control } = form;

  const loadCategoryOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getFeedbackCategoryByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notification.error(error);
      });
  };

  return (
    <>
      <div className="fm-wizard-element-title-container">
        <div className="fm-wizard-element-title">{t('components.common_information')}</div>
      </div>
      <div className="ibox-content clearfix fm-wizard-element-content-box">
        <form className="fm-common-info-feedback">
          <Controller
            control={control}
            name="name"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <Input
                name={name}
                placeholder={t('fields.name')}
                label={t('fields.name')}
                form={form}
                rows={5}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="description"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <TextArea
                name="description"
                placeholder={t('fields.description')}
                label={t('fields.description')}
                form={form}
                rows={3}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name="category"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <AsyncSelectCreatable
                placeholder={t('fields.category')}
                label={t('fields.category')}
                form={form}
                isClearable
                defaultOptions={defaultFeedbackCategoryOptions}
                loadOptions={loadCategoryOptions}
                isMulti={false}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </form>
      </div>
    </>
  );
}

FeedbackCommonInformationForm.propTypes = {
  feedback: PropTypes.object.isRequired,
};

export default withTranslation()(FeedbackCommonInformationForm);
