import React from 'react';
import './styles/auth-form.css';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faRetweet } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import Notifications from '../../utils/Notifications';
import { LOGIN, RESET_PASSWORD_DONE } from '../../constants/routes';
import MaterialInput from '../react-hook-form-inputs/MaterialInput';
import { resetPassword } from '../../services/user/userProvider';
import Validation from '../../utils/validation';

function InnerForm(props) {
  const { t, changeLocation, passwordResetToken } = props;

  const schema = Yup.object().shape({
    newPassword: Yup.string()
      .required(t('message.no_password_provided'))
      .matches(Validation.makePasswordRegExp(8), t('message.weak_password')),
    confirmPassword: Yup.string()
      .required(t('message.no_password_provided'))
      .oneOf([Yup.ref('newPassword'), null], t('message.passwords_must_match')),
  });
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      resetPasswordToken: passwordResetToken,
      newPassword: '',
      confirmPassword: '',
    },
  });
  const { handleSubmit, control, register } = form;

  const onSubmit = (values) => {
    resetPassword(values)
      .then((isOk) => {
        changeLocation(RESET_PASSWORD_DONE);
      })
      .catch((error) => {
        Notifications.error(t('message.not_able_to_reset_password'));
      });
  };

  return (
    <>
      <div className="fm-login-text text-center">
        <span>{t('message.reset_password')}</span> <br />
        <span>{t('message.enter_new_password')}</span>
      </div>
      <form onSubmit={handleSubmit((data) => onSubmit(data))} className="m-t fm-auth-form">
        <div className="fm-auth-input-container">
          <input
            type="hidden"
            name="resetPasswordToken"
            ref={register()}
            defaultValue={passwordResetToken}
          />
          <div className="fm-element-form">
            <div className="fm-login-icon">
              <FontAwesomeIcon icon={faLock} />
            </div>
            <div className="fm-login-input">
              <Controller
                control={control}
                name="newPassword"
                id="newPassword"
                render={({ onChange, onBlur, value, name }) => (
                  <MaterialInput
                    name={name}
                    form={form}
                    label={t('fields.new_password')}
                    placeholder={t('fields.new_password')}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    type="password"
                    autoComplete="password"
                  />
                )}
              />
            </div>
          </div>
          <div className="fm-element-form">
            <div className="fm-login-icon">
              <FontAwesomeIcon icon={faRetweet} />
            </div>
            <div className="fm-login-input">
              <Controller
                control={control}
                name="confirmPassword"
                id="confirmPassword"
                render={({ onChange, onBlur, value, name }) => (
                  <MaterialInput
                    name={name}
                    form={form}
                    label={t('fields.confirm_password')}
                    placeholder={t('fields.confirm_password')}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                    type="password"
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="fm-auth-box">
          <button type="submit" className="btn fm-btn-primary block m-b fm-login-btn">
            {t('buttons.reset_password')}
          </button>
        </div>
        <div className="fm-auth-box fm-login-link">
          <button type="button" className="btn btn-link" onClick={() => changeLocation(LOGIN)}>
            <small>{t('buttons.back_to_sign_in')}</small>
          </button>
        </div>
      </form>
    </>
  );
}

InnerForm.propTypes = {
  t: PropTypes.func.isRequired,
  changeLocation: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
