import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

class PageNameContainer extends Component {
  render() {
    const { name, breadcrumbs } = this.props;

    return (
      <div className="fm-name-container">
        <ol className="breadcrumb">
          {breadcrumbs &&
            _.map(breadcrumbs, (b, k) => (
              <li key={k} className={`breadcrumb-item${b.isActive ? ' active' : ''}`}>
                {b.ref ? (
                  <NavLink to={b.ref}>{b.name}</NavLink>
                ) : (
                  <span className={b.isActive ? 'fm-breadcrumb-active' : ''}>{b.name}</span>
                )}
              </li>
            ))}
        </ol>
        {/* <span className={ 'fm-page-name' }>{ name }</span> */}
      </div>
    );
  }
}

PageNameContainer.propTypes = {
  name: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.array,
};

export default PageNameContainer;
