import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import '../styles/validation-results.scss';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import NoDataComponent from '../../tables/NoDataTableComponent';
import StringRenderer from '../../tables/StringRenderer';

class ValidationResultTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.buildDataForContent(),
      columns: this.buildColumnsForContent(),
      expanded: {},
    };
  }

  buildDataForContent() {
    const { isRowIdRequired } = this.props;
    const data = [];
    this.props.content.map((validationResultRecord) => {
      const dataRow = {};
      Object.keys(validationResultRecord.row).map((key) => {
        dataRow[key] = validationResultRecord.row[key];
      });
      this.buildSubRows(dataRow, validationResultRecord.cellValidationErrors);

      if (isRowIdRequired) {
        dataRow['#'] = validationResultRecord.rowNumber + 1;
      }
      data.push(dataRow);
    });
    return data;
  }

  buildSubRows(row, cellErrors) {
    const subRows = [];
    if (cellErrors.ERROR) {
      cellErrors.ERROR.map((celError) => subRows.push(celError));
    }
    if (cellErrors.WARN) {
      cellErrors.WARN.map((celError) => subRows.push(celError));
    }
    row.subRows = subRows;
  }

  buildColumnsForContent() {
    const { t, content, columnTitlePrefix, isRowIdRequired } = this.props;
    const columns = [];

    columns.push(this.createExpandColumn(t));

    const columnNames = Object.keys(content[0].row);
    if (isRowIdRequired) {
      columnNames.unshift('#');
    }
    columnNames.map((columnName) => {
      const columnObject = {
        Header: columnTitlePrefix ? t(columnTitlePrefix + columnName) : columnName,
        Cell: StringRenderer,
        accessor: columnName,
        sortable: false,
        filterable: false,
        width: columnName === '#' && isRowIdRequired ? '60' : '140',
      };
      columns.push(columnObject);
    });

    return columns;
  }

  createExpandColumn() {
    const { t } = this.props;
    return {
      expander: true,
      Header: () => <div> {t('validation.result.column.expand')} </div>,
      width: 30,
      Expander: ({ isExpanded, ...rest }) =>
        !rest.original.subRows || rest.original.subRows.length === 0 ? null : (
          <div className="validation-result-expand-row-icon">
            <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />
          </div>
        ),
    };
  }

  buildSubRow = (row) =>
    row.original.subRows && row.original.subRows.length > 0 ? (
      <div className={`validation-result-error-list ${row.index % 2 === 0 ? '-odd' : '-even'}`}>
        <ul>
          {row.original.subRows.map((cellError, i) => (
            <li key={i}>{cellError.message}</li>
          ))}
        </ul>
      </div>
    ) : (
      <div className="hidden" />
    );

  render() {
    const { containerClass } = this.props;
    const { columns, data } = this.state;

    return (
      <div className={`validation-result-table-container ${containerClass || ''}`}>
        {data && (
          <ReactTable
            ref={(refReactTable) => {
              this.refReactTable = refReactTable;
            }}
            data={data}
            pageSize={data.length}
            minRows={1}
            maxRows={500}
            showPagination={false}
            columns={columns}
            className="-striped -highlight"
            NoDataComponent={NoDataComponent}
            onExpandedChange={(expanded) => {
              this.setState({ expanded });
            }}
            SubComponent={this.buildSubRow}
          />
        )}
      </div>
    );
  }
}

ValidationResultTable.propTypes = {
  content: PropTypes.array,
  containerClass: PropTypes.string,
  columnTitlePrefix: PropTypes.string,
  isRowIdRequired: PropTypes.bool,
};

export default withTranslation()(ValidationResultTable);
