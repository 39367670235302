import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AllEmployeeJobs from '../../containers/employeejob/AllEmployeeJobs';
import EditEmployeeJobPage from '../../containers/employeejob/EditEmployeeJobPage';

import { AUTH_ROUTES, EMPLOYEE_JOB_ID } from '../../constants/routes';

class EmployeeJobRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllEmployeeJobs} />
        <Route
          path={`${match.url}${EMPLOYEE_JOB_ID}`}
          render={({ match }) => <EmployeeJobInnerRouting match={match} />}
        />
      </div>
    );
  }
}

EmployeeJobRoutes.propTypes = {
  match: PropTypes.object,
};

export default EmployeeJobRoutes;

class EmployeeJobInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let employeeJobRouting;
    if (match.params.employeeJobId) {
      switch (match.params.employeeJobId) {
        default:
          employeeJobRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.ORG.EMPLOYEE_JOBS}${EMPLOYEE_JOB_ID}`}
              component={AllEmployeeJobs}
            />
          );
      }
    }

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.ORG.EMPLOYEE_JOBS}${EMPLOYEE_JOB_ID}/edit`}
          component={EditEmployeeJobPage}
        />
        {employeeJobRouting}
      </div>
    );
  }
}

EmployeeJobInnerRouting.propTypes = {
  match: PropTypes.object,
};
