import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { Chart, registerables } from 'chart.js';
import { ROOT } from '../constants/routes';
import { getUserDetails } from '../services/account/authProvider';
import { logoutAction, setUserDetails } from '../services/account/authActions';
import { initializeMenu } from '../services/menu/menuActions';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    this.getUserDetails();
    Chart.register(...registerables);
    Chart.defaults.font.family = 'FiraSans';
  }

  componentWillUnmount() {
    Chart.unregister(...registerables);
  }

  logout = () => {
    const { dispatchLogout, history } = this.props;
    dispatchLogout();
    history.push(ROOT);
  };

  getUserDetails = () => {
    const { account, dispatchSetUser, dispatchSetMenuStructure, dispatchLogout, i18n } = this.props;

    if (account.accessToken) {
      getUserDetails()
        .then((data) => {
          if (data.user && data.user.language) {
            i18n.changeLanguage(data.user.language);
            moment.locale(data.user.language);
          }
          dispatchSetUser(data);
          dispatchSetMenuStructure(data.permissions);
          this.setState({ isLoading: false });
        })
        .catch(() => {
          this.logout();
        });
    } else {
      dispatchLogout();
    }
  };

  render() {
    const { children } = this.props;

    return children;
  }
}

App.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.any,
  account: PropTypes.object,
  dispatchLogout: PropTypes.func,
  dispatchSetUser: PropTypes.func,
  dispatchSetMenuStructure: PropTypes.func,
};

const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLogout: () => {
    dispatch(logoutAction());
  },
  dispatchSetUser: (data) => {
    dispatch(setUserDetails({ userDetails: data }));
  },
  dispatchSetMenuStructure: (permissions) => {
    dispatch(initializeMenu({ permissions }));
  },
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));
