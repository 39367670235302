import dataProvider from '../dataProvider';
import { userrole, userrolepermission } from '../../constants/endpoints';

export const createUserRole = (values) =>
  dataProvider({
    url: userrole.create(),
    method: 'POST',
    data: values,
  });

export const updateUserRole = (userId, data) =>
  dataProvider({
    url: userrole.update(userId),
    data,
    method: 'PUT',
  });

export const getUserRoleByUserId = (userId) =>
  dataProvider({
    url: userrole.getByUserId(userId),
    method: 'GET',
  });

export const getUserRoleList = (params) =>
  dataProvider({
    url: userrole.all(),
    params,
    method: 'GET',
  });

export const getUserRoleSelectItemSource = (params) =>
  dataProvider({
    url: userrole.itemSource(),
    data: params,
    method: 'POST',
  });

export const getUserRoleListPagination = (params) =>
  dataProvider({
    url: userrole.pagination(),
    data: params,
    method: 'POST',
  });

export const addUserRolesToUser = (userId, params) =>
  dataProvider({
    url: userrole.addUserRolesToUser(userId),
    data: params,
    method: 'POST',
  });

export const getUserRoleById = (itemId) =>
  dataProvider({
    url: userrole.getById(itemId),
    method: 'GET',
  });

export const getUserRolePermissionsByUserRoleId = (userRoleId) =>
  dataProvider({
    url: userrolepermission.getByUserRoleId(userRoleId),
    method: 'GET',
  });
export const deleteUserRole = (roleId) =>
  dataProvider({
    url: userrole.deleteUserRole(roleId),
    method: 'DELETE',
  });

export const deleteUserRoleUser = (roleId, userId) =>
  dataProvider({
    url: userrole.deleteUserRoleUser(roleId, userId),
    method: 'DELETE',
  });

export const getUserRolesNotAppliedForUser = (userId, query) =>
  dataProvider({
    url: userrole.getUserRolesNotAppliedForUser(userId, query),
    method: 'GET',
  });

export const saveRoleWithPermissions = (params) =>
  dataProvider({
    url: userrole.saveRoleWithPermissions(params.id),
    data: params,
    method: 'POST',
  });
