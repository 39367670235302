import React, { Component } from 'react';
import './styles/upload.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

class UploadFileContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { files, removeFile } = this.props;
    return (
      <div className="fm-upload-files row align-items-center">
        {files &&
          files.map((file) => (
            <div key={file.name} className="fm-upload-row">
              <span className="fm-upload-filename">{file.name}</span>
              {removeFile && (
                <span
                  onClick={() => {
                    removeFile(file.name);
                  }}
                  className="fm-upload-delete"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              )}
            </div>
          ))}
      </div>
    );
  }
}

UploadFileContainer.propTypes = {
  files: PropTypes.array.isRequired,
  removeFile: PropTypes.func,
};
export default UploadFileContainer;
