import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserSecret } from '@fortawesome/free-solid-svg-icons';

import { getUserTips } from '../../services/ongoingfeedbacks/ongoingFeedbackProvider';
import Notifications from '../../utils/Notifications';
import UrlUtil from '../../utils/UrlUtil';
import NoDataForm from '../form/NoDataForm';

import './styles/home.css';

export default function OngoingFeedbackTipsModal({ closeModal, account }) {
  const { t } = useTranslation();
  const [tips, setTips] = useState([]);

  useEffect(() => {
    getUserTips(account.userId)
      .then((data) => {
        setTips(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  if (tips?.length === 0) {
    return (
      <div>
        <NoDataForm
          label={`${t('components.no_data_present_yet')} :(`}
          paddingTop={0}
          paddingBottom={0}
        />
      </div>
    );
  }

  return (
    <>
      <div className="fm-ongoing-feedback-tips">
        {tips.map(({ reviewer, id, feedbackSkillComments }) => (
          <div key={id} className="fm-ongoing-feedback-tip">
            <div className="fm-ongoing-feedback-header">
              <div className="fm-user-info">
                <div className="fm-user-photo">
                  {!reviewer && <FontAwesomeIcon icon={faUserSecret} size="xs" />}
                  {reviewer &&
                    (reviewer.profileImageLink ? (
                      <img src={UrlUtil.buildImageSrcLink(reviewer.profileImageLink)} />
                    ) : (
                      <div className="fm-user-empty-photo">
                        <FontAwesomeIcon icon={faUser} className="fm-user-img" />
                      </div>
                    ))}
                </div>
                <div className="fm-user-full-name">
                  {reviewer ? reviewer.displayString : t('components.anonymous')}
                </div>
              </div>
              <div className="fm-tip-time-ago">{moment(new Date(tip.created)).fromNow()}</div>
            </div>

            <div className="fm-ongoing-feedback-advice-wrapper">
              <div className="fm-ongoing-feedback-advice">
                {feedbackSkillComments?.map((skill) => (
                  <div key={skill.id} className="fm-ongoing-feedback-skill-comment-box">
                    {skill.ongoingFeedbackSkill && (
                      <div className="fm-ongoing-feedback-skill">
                        {skill.ongoingFeedbackSkill.name}
                      </div>
                    )}
                    <div className="fm-ongoing-feedback-skill-comment">{skill.comment}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="modal-footer">
        <button type="button" className="btn" onClick={closeModal}>
          {t('buttons.ok')}
        </button>
      </div>
    </>
  );
}

OngoingFeedbackTipsModal.propTypes = {
  account: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};
