import dataProvider from '../dataProvider';
import { questiontemplate } from '../../constants/endpoints';

export const getQuestionTemplate = (itemId) =>
  dataProvider({
    url: questiontemplate.getById(itemId),
    method: 'GET',
  });

export const deleteQuestionTemplate = (itemId) =>
  dataProvider({
    url: questiontemplate.delete(itemId),
    method: 'DELETE',
  });

export const createQuestionTemplate = (data) =>
  dataProvider({
    url: questiontemplate.create(),
    method: 'POST',
    data,
  });

export const updateQuestionTemplate = (testId, data) =>
  dataProvider({
    url: questiontemplate.update(testId),
    method: 'PUT',
    data,
  });
export const createQuestionTemplates = (data) =>
  dataProvider({
    url: questiontemplate.createBatch(),
    method: 'POST',
    data,
  });

export const getQuestionTemplateTypes = () =>
  dataProvider({
    url: questiontemplate.types(),
    method: 'GET',
  });
