import dataProvider from '../dataProvider';
import { caseReport } from '../../constants/endpoints';

export const getCaseReportList = (params) =>
  dataProvider({
    url: caseReport.all(),
    params,
    method: 'GET',
  });

export const getCaseReport = (caseReportId) =>
  dataProvider({
    url: caseReport.getById(caseReportId),
    method: 'GET',
  });

export const updateCaseReport = (params) =>
  dataProvider({
    url: caseReport.update(params.id),
    data: params,
    method: 'PUT',
  });

export const createCaseReport = (params) =>
  dataProvider({
    url: caseReport.create(),
    data: params,
    method: 'POST',
  });

export const deleteCaseReport = (caseReportId) =>
  dataProvider({
    url: caseReport.delete(caseReportId),
    method: 'DELETE',
  });

export const getCaseReportPagination = (params) =>
  dataProvider({
    url: caseReport.pagination(),
    data: params,
    method: 'POST',
  });

export const getCaseReportStatuses = () =>
  dataProvider({
    url: caseReport.statuses(),
    method: 'GET',
  });

export const getCaseReportSeverities = () =>
  dataProvider({
    url: caseReport.severities(),
    method: 'GET',
  });

export const getCaseReportTypes = () =>
  dataProvider({
    url: caseReport.types(),
    method: 'GET',
  });
