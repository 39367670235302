import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Checkbox, FormGroup, HelpBlock } from 'react-bootstrap';
import './styles/toggle.css';
import _ from 'lodash';
import { HelpOutline } from '@material-ui/icons';
import MaterialHtmlTooltip from '../ui-elements/MaterialHtmlTooltip';

function ToggleComponent({
  name,
  form: { touched, errors },
  classNames,
  setFieldValue,
  onChange,
  label,
  toggleStyle,
  toggleSize,
  labelSide,
  helperMessage,
  ...props
}) {
  const error = _.get(errors, name);

  return (
    <FormGroup
      className={classnames('form-element', 'flag-component', classNames, {
        'has-error': error && error.message,
      })}
    >
      {labelSide && labelSide === 'left' && (
        <span style={{ paddingRight: 10 }} className="fm-toggle-control-label">
          {label}
        </span>
      )}
      <Checkbox
        {...props}
        id={name}
        className={`fm-toggle-control${toggleStyle || ' mt-ios'}`}
        onChange={(e) => {
          onChange && onChange(e);
        }}
        inline
      >
        <label
          className="fm-toggle-control"
          htmlFor={name}
          style={{ fontSize: toggleSize || 10 }}
        />
        {(!labelSide || labelSide === 'right') && (
          <span style={{ paddingLeft: 10 }} className="fm-toggle-control-label">
            {label}
          </span>
        )}
      </Checkbox>
      {helperMessage && (
        <div className="fm-toggle-control">
          <MaterialHtmlTooltip title={helperMessage} arrow>
            <HelpOutline />
          </MaterialHtmlTooltip>
        </div>
      )}
      {error && error.message && (
        <HelpBlock className="error-text animated fadeIn fm-error-req">{error.message}</HelpBlock>
      )}
    </FormGroup>
  );
}

ToggleComponent.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  classNames: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

export default ToggleComponent;
