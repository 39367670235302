import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AllFeedbackAssignments from '../../containers/feedbacks/AllFeedbackAssignments';
import EditFeedbackAssignmentPage from '../../containers/feedbacks/EditFeedbackAssignmentPage';
import UserFeedbackAssignmentRequests from '../../containers/feedbacks/UserFeedbackAssignmentRequests';
import PassFeedbackAssignment from '../../containers/feedbacks/PassFeedbackAssignment';
import ManagerSubordinateFeedbackAssignmentRequests from '../../containers/feedbacks/ManagerSubordinateFeedbackAssignmentRequests';
import ManagerSubordinateFeedbackAssignmentSummary from '../../containers/feedbacks/ManagerSubordinateFeedbackAssignmentSummary';
import {
  AUTH_ROUTES,
  FEEDBACK_ASSIGNMENT_ID,
  FEEDBACK_ASSIGNMENT_REVIEWER_ID,
} from '../../constants/routes';
import AllEmployeesFeedbackAssignmentRequests from '../../containers/feedbacks/AllEmployeesFeedbackAssignmentRequests';

class FeedbackAssignmentRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllFeedbackAssignments} />
        <Route
          path={`${match.url}${FEEDBACK_ASSIGNMENT_ID}`}
          render={({ match }) => <FeedbackAssignmentInnerRouting match={match} />}
        />
      </div>
    );
  }
}

FeedbackAssignmentRoutes.propTypes = {
  match: PropTypes.object,
};

export default FeedbackAssignmentRoutes;

class FeedbackAssignmentInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let feedbackAssignmentRouting;
    if (match.params.feedbackAssignmentId) {
      switch (match.params.feedbackAssignmentId) {
        case 'requests':
          feedbackAssignmentRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.FEEDBACK.ASSIGNMENT_REQUEST}`}
              component={UserFeedbackAssignmentRequests}
            />
          );
          break;
        case 'manager-subordinate-requests':
          feedbackAssignmentRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.FEEDBACK.MANAGER_SUBORDINATE_ASSIGNMENT_REQUESTS}`}
              component={ManagerSubordinateFeedbackAssignmentRequests}
            />
          );
          break;
        case 'manager-summary':
          feedbackAssignmentRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.FEEDBACK.MANAGER_SUMMARY}${FEEDBACK_ASSIGNMENT_ID}`}
              component={ManagerSubordinateFeedbackAssignmentSummary}
            />
          );
          break;
        case 'all-employees-requests':
          feedbackAssignmentRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.FEEDBACK.ALL_EMPLOYEES_ASSIGNMENT_REQUESTS}`}
              component={AllEmployeesFeedbackAssignmentRequests}
            />
          );
          break;
        case 'all':
          feedbackAssignmentRouting = (
            <Route path={`${match.url}`} component={AllFeedbackAssignments} />
          );
          break;
        default:
          feedbackAssignmentRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.FEEDBACK.ASSIGNMENTS}${FEEDBACK_ASSIGNMENT_REVIEWER_ID}/pass`}
              component={PassFeedbackAssignment}
            />
          );
      }
    }

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.FEEDBACK.ASSIGNMENTS}${FEEDBACK_ASSIGNMENT_ID}/edit`}
          component={EditFeedbackAssignmentPage}
        />
        {feedbackAssignmentRouting}
      </div>
    );
  }
}

FeedbackAssignmentInnerRouting.propTypes = {
  match: PropTypes.object,
};
