import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import SelectComponent from '../react-hook-form-inputs/Select';
import Input from '../react-hook-form-inputs/Input';
import AsyncSelectComponent from '../react-hook-form-inputs/AsyncSelect';
import Notifications from '../../utils/Notifications';
import { getUserSelectItemSource } from '../../services/user/userProvider';
import { getOrgUnitSelectItemSource } from '../../services/orgunit/orgUnitProvider';

function InnerForm(props) {
  const { t, cancel, orgUnitTypes, createOrganization, getUsers, getOrgUnits } = props;

  const schema = Yup.object().shape({
    name: Yup.string().required(t('message.organization_name_required')),
    shortName: Yup.string().required(t('message.organization_short_name_required')),
    type: Yup.object().required(t('message.type')),
  });

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const loadUserOptions = (query, callback) => {
    getUsers(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const loadOrganizationOptions = (query, callback) => {
    getOrgUnits(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const { handleSubmit } = form;

  const [defaultUsers, setDefaultUsers] = useState([]);
  const [defaultOrgUnits, setDefaultOrgUnits] = useState([]);

  useEffect(() => {
    const promises = [
      getUserSelectItemSource({ amount: 100, skip: 0 }),
      getOrgUnitSelectItemSource({ amount: 100, skip: 0 }),
    ];
    Promise.all(promises)
      .then(([defaultUsers, defaultOrgUnits]) => {
        setDefaultUsers(defaultUsers);
        setDefaultOrgUnits(defaultOrgUnits);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  const onSubmit = (values) => {
    values.orgStructureDefId = values.type.value;
    if (values.manager) {
      values.orgStructureUnitManagerId = values.manager.value;
    }
    if (values.parentOrgUnit) {
      values.parentId = values.parentOrgUnit.value;
    }

    delete values.type;
    delete values.manager;
    delete values.parentOrgUnit;

    createOrganization(values);
  };

  return (
    <div>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <div className="modal-body">
          <Controller
            control={form.control}
            name="name"
            defaultValue=""
            render={({ onChange, onBlur, name, value }) => (
              <Input
                form={form}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type="text"
                placeholder={t('fields.organization_unit_name')}
                label={t('fields.organization_unit_name')}
              />
            )}
          />
          <Controller
            control={form.control}
            name="shortName"
            defaultValue=""
            render={({ onChange, onBlur, name, value }) => (
              <Input
                form={form}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                type="text"
                placeholder={t('fields.organization_unit_short_name')}
                label={t('fields.organization_unit_short_name')}
              />
            )}
          />
          <Controller
            control={form.control}
            name="type"
            defaultValue=""
            render={({ onChange, value, name }) => (
              <SelectComponent
                form={form}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={t('fields.select')}
                label={t('fields.type')}
                options={orgUnitTypes}
              />
            )}
          />
          <Controller
            control={form.control}
            name="manager"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <AsyncSelectComponent
                form={form}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={t('fields.manager')}
                label={t('fields.manager')}
                loadOptions={loadUserOptions}
                isMulti={false}
                isClearable
                defaultOptions={defaultUsers}
                cacheOptions
              />
            )}
          />
          <Controller
            control={form.control}
            name="parentOrgUnit"
            defaultValue=""
            render={({ onChange, onBlur, name, value }) => (
              <AsyncSelectComponent
                form={form}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={t('fields.parent_organization')}
                label={t('fields.parent_organization')}
                loadOptions={loadOrganizationOptions}
                isMulti={false}
                isClearable
                defaultOptions={defaultOrgUnits}
                cacheOptions
              />
            )}
          />
        </div>
        <div className="modal-footer">
          <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
            {t('buttons.cancel')}
          </button>
          <button type="submit" className="btn fm-btn-primary">
            {t('buttons.create')}
          </button>
        </div>
      </form>
    </div>
  );
}

InnerForm.propTypes = {
  t: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  createOrganization: PropTypes.func.isRequired,
  orgUnitTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  getUsers: PropTypes.func.isRequired,
  getOrgUnits: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
