import React, { Component } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/carousel.css';

class Carousel extends Component {
  render() {
    const { settings, images } = this.props;

    const _settings = {
      dots: true,
      infinite: true,
      arrows: false,
      autoplay: true,
      centerMode: true,
      fade: true,
      speed: 800,
      slidesToShow: 1,
      initialSlide: 0,
      ...settings,
    };
    return (
      <Slider {..._settings} className="fm-carousel">
        {images &&
          images.map((obj, index) => (
            <div className="fm-carousel-item" key={`fm-carousel-item-${index}`}>
              <img src={obj} />
            </div>
          ))}
      </Slider>
    );
  }
}

Carousel.propTypes = {
  settings: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
};

export default Carousel;
