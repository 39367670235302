import React from 'react';
import Spinner from 'react-spinner-material';
import './styles/tables.scss';

export default class TableLoader extends React.Component {
  render() {
    return this.props.loading ? (
      <div className="-loading -active">
        <div className="-loading-inner">
          <Spinner size={20} className="-fm-table-spinner" color="#34B77C" width={2} visible />
        </div>
      </div>
    ) : null;
  }
}
