import React from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Validation from '../../utils/validation';
import InputComponent from '../inputs/Input';
import TextAreaComponent from '../inputs/Textarea';

function InnerForm(props) {
  const { t, handleSubmit, cancel, getEmployeeJobLevelByName } = props;

  const validateName = (query) => {
    if (query) {
      return getEmployeeJobLevelByName(query).then((d) =>
        d && d.length > 0 ? t('message.already_exist') : null,
      );
    }
    return null;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="modal-body">
        <Field
          component={InputComponent}
          placeholder={t('fields.job_level_name')}
          type="text"
          name="name"
          label={t('fields.job_level_name')}
          validate={(e) => validateName(e)}
        />
        <Field
          component={TextAreaComponent}
          placeholder={t('fields.description')}
          type="text"
          name="description"
          label={t('fields.description')}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
        <button type="submit" className="btn fm-btn-primary">
          {t('buttons.create')}
        </button>
      </div>
    </Form>
  );
}

InnerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  createEmployeeJobLevel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  getEmployeeJobLevelByName: PropTypes.func.isRequired,
};

const CreateEmployeeJobLevelModal = withFormik({
  mapPropsToValues: () => ({
    name: '',
    description: '',
  }),
  validate: (values) => {
    const errors = {};
    errors.name = Validation.required(values.name);

    return _.isEmpty(_.filter(errors, (value) => value)) ? {} : errors;
  },
  handleSubmit: (values, props) => {
    props.props.createEmployeeJobLevel(values);
  },
})(InnerForm);

export default withTranslation()(CreateEmployeeJobLevelModal);
