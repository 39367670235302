import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SignalWifi0BarRoundedIcon from '@mui/icons-material/SignalWifi0BarRounded';
import SignalWifi1BarRoundedIcon from '@material-ui/icons/SignalWifi1BarRounded';
import SignalWifi2BarRoundedIcon from '@mui/icons-material/SignalWifi2BarRounded';
import SignalWifi3BarRoundedIcon from '@material-ui/icons/SignalWifi3BarRounded';
import SignalWifi4BarRoundedIcon from '@material-ui/icons/SignalWifi4BarRounded';

class ImpactStrengthRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.original.id,
    };
  }

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  getIcon = (strengthLevel) => {
    switch (strengthLevel) {
      case 'VeryStrong':
        return <SignalWifi4BarRoundedIcon />;
      case 'Strong':
        return <SignalWifi3BarRoundedIcon />;
      case 'Moderate':
        return <SignalWifi2BarRoundedIcon />;
      case 'Weak':
        return <SignalWifi1BarRoundedIcon />;
      case 'VeryWeak':
        return <SignalWifi0BarRoundedIcon />;
      default:
        return <SignalWifi0BarRoundedIcon />;
    }
  };

  getColor = (strengthLevel) => {
    switch (strengthLevel) {
      case 'VeryStrong':
        return '#34B77C';
      case 'Strong':
        return '#85D4B0';
      case 'Moderate':
        return '#FFE183';
      case 'Weak':
        return '#FFA000';
      case 'VeryWeak':
        return '#F4564E';
      default:
        return 'inherit';
    }
  };

  getTitle = (value) => {
    const { t } = this.props;
    switch (value) {
      case 'VeryStrong':
        return t('components.impact.very-strong');
      case 'Strong':
        return t('components.impact.strong');
      case 'Moderate':
        return t('components.impact.moderate');
      case 'Weak':
        return t('components.impact.weak');
      case 'VeryWeak':
        return t('components.impact.very-weak');
      default:
        return t('components.nan');
    }
  };

  render() {
    const { t, value } = this.props;

    return (
      <>
        {value !== 'NaN' ? (
          <div
            className="fm-table-icon"
            title={this.getTitle(value)}
            style={{ color: this.getColor(value), opacity: 0.6 }}
          >
            {this.getIcon(value)}
          </div>
        ) : (
          <div className="fm-table-string">{t('components.nan')}</div>
        )}
      </>
    );
  }
}

ImpactStrengthRenderer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ImpactStrengthRenderer);
