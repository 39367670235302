import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import TextArea from '../../react-hook-form-inputs/TextArea';

function InnerForm(props) {
  const { t, cancel, readOnly, comment, addComment } = props;

  const form = useForm({
    defaultValues: {
      comment: comment || '',
    },
  });

  const { handleSubmit, control } = form;
  const onSubmit = (values) => {
    addComment(props.feedbackCriteriaId, values.comment);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <Controller
          control={control}
          id="comment"
          name="comment"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <TextArea
              name={name}
              placeholder={t('fields.comment')}
              label={t('fields.comment')}
              form={form}
              rows={2}
              onBlur={onBlur}
              onChange={onChange}
              readOnly={readOnly}
              value={value}
            />
          )}
        />
      </div>
      {readOnly ? (
        <div className="modal-footer">
          <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
            {t('buttons.ok')}
          </button>
        </div>
      ) : (
        <div className="modal-footer">
          <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
            {t('buttons.cancel')}
          </button>
          <button type="submit" className="btn fm-btn-primary">
            {t('buttons.save')}
          </button>
        </div>
      )}
    </form>
  );
}

InnerForm.propTypes = {
  cancel: PropTypes.func,
  addComment: PropTypes.func.isRequired,
  comment: PropTypes.string,
  feedbackCriteriaId: PropTypes.number,
  readOnly: PropTypes.bool,
};

export default withTranslation()(InnerForm);
