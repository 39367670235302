import dataProvider from '../dataProvider';

import { analytics } from '../../constants/endpoints';

export const getHeatmapAnalytics = (strategy, params) =>
  dataProvider({
    url: analytics.heatmap(strategy),
    data: params,
    method: 'POST',
  });

export const getHeatmapStrategies = () =>
  dataProvider({
    url: analytics.getHeatmapTypes(),
    method: 'GET',
  });

export const getMonthAverageScore = (classification) =>
  dataProvider({
    url: analytics.getMonthAverageScore(classification),
    method: 'GET',
  });

export const getNegativeNeutralPositive = (classification) =>
  dataProvider({
    url: analytics.getNegativeNeutralPositive(classification),
    method: 'GET',
  });

export const getDashbortChartsData = (classification) =>
  dataProvider({
    url: analytics.getDashboardChartsInfo(classification),
    method: 'GET',
  });

export const getHeatmapAnalyticsAdditionalRow = (strategy, params) =>
  dataProvider({
    url: analytics.heatmapAdditionalRow(strategy),
    data: params,
    method: 'POST',
  });

export const getSummaryQuestionStatistic = (assignmentPeriodId) =>
  dataProvider({
    url: analytics.summaryQuestion(assignmentPeriodId),
    method: 'GET',
  });

export const getSummaryQuestionStatisticByUserId = (assignmentPeriodId, userId) =>
  dataProvider({
    url: analytics.summaryQuestionByUserId(assignmentPeriodId, userId),
    method: 'GET',
  });

export const getClassificationComparingStatistic = (testAssignmentId) =>
  dataProvider({
    url: analytics.classificationStatComparing(testAssignmentId),
    method: 'GET',
  });

/**
 * The happiness index feature has been removed in context of FRM-311
 * But the entrypoint is are left for any case.
 */
export const fetchMyPoolHappinessLevel = (params) =>
  dataProvider({
    url: analytics.fetchMyPoolHappinessLevel(),
    data: params,
    method: 'POST',
  });

export const getInsightsData = () =>
  dataProvider({
    url: analytics.getInsightsData(),
    method: 'GET',
  });
