import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import _ from 'lodash';

function MaterialRadio(props) {
  const {
    disabled,
    form: { touched, errors },
    classNames,
    label,
    required,
    checkedIcon,
    checked,
    icon,
    root,
    color,
    onChange,
    labelPlacement,
    elementColor,
    checkboxHoverColor,
    checkedElementColor,
    name,
    value,
  } = props;

  const error = _.get(errors, name);

  const useStyles = makeStyles({
    root: {
      color: elementColor || 'rgba(0, 0, 0, 0.54)',
      '&:hover': {
        backgroundColor: checkboxHoverColor || 'rgba(0, 0, 0, 0.08)',
      },
    },
    checked: {
      '&$checked': {
        color: checkedElementColor || 'rgba(0, 0, 0, 0.54)',
      },
    },
  });
  const classes = useStyles();

  const icons = {};
  if (icon) {
    icons.icon = icon;
  }
  if (checkedIcon) {
    icons.checkedIcon = checkedIcon;
  }

  return (
    <FormControl required={required} error={error ? error.message : undefined} component="fieldset">
      <FormGroup>
        <FormControlLabel
          labelPlacement={labelPlacement}
          className={checked ? 'fm-element-checked' : ''}
          label={label}
          control={
            <Radio
              {...icons}
              root={root}
              className={classNames}
              name={name}
              value={value}
              color={color}
              checked={checked}
              disabled={disabled}
              classes={{
                root: classes.root,
                checked: classes.checked,
              }}
              error={error ? error.message : undefined}
              onChange={(e) => {
                onChange && onChange(e);
              }}
            />
          }
        />
      </FormGroup>
      {error ? (
        <FormHelperText className="error-text animated fadeIn">{error.message}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

MaterialRadio.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  classNames: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  checkedIcon: PropTypes.object,
  color: PropTypes.string,
};

export default MaterialRadio;
