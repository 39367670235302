import React, { Component } from 'react';
import Can from '../security/Can';

class LabelLinkRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.original.id,
      securityModule: props.column.securityModule,
      securityPermission: props.column.securityPermission,
      onClick: props.column.onClickLink,
      canUseLink: props.column.canUseLink,
    };
  }

  onClickLink = () => {
    const { onClick, canUseLink } = this.state;
    const { original } = this.props;

    if (canUseLink && !canUseLink(original)) {
      return;
    }
    if (onClick) {
      onClick(this.state.id);
    }
  };

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  render() {
    const { value } = this.props;
    const { securityModule, securityPermission } = this.state;

    return (
      <div className="fm-table-label-link">
        <Can
          module={securityModule}
          permission={securityPermission}
          yes={() => <span onClick={this.onClickLink}>{value}</span>}
          no={() => <span>{value}</span>}
        />
      </div>
    );
  }
}

export default LabelLinkRenderer;
