import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { withTranslation } from 'react-i18next';
import Input from '../../../react-hook-form-inputs/Input';

function Pulse(props) {
  const { t, qiu, isHidden, questionIndex, changeQuestion } = props;

  const form = useFormContext();
  const { control, watch } = form;

  const watchers = watch([`${qiu}.answerRangeFrom`, `${qiu}.answerRangeTo`]);
  const answerRangeFrom = parseInt(watchers[`${qiu}.answerRangeFrom`]);
  const answerRangeTo = parseInt(watchers[`${qiu}.answerRangeTo`]);

  return (
    <>
      {!isHidden && (
        <div className="fm-fields-line">
          <Controller
            control={control}
            name={`${qiu}.answerRangeFrom`}
            defaultValue={isNaN(answerRangeFrom) ? 1 : answerRangeFrom}
            render={({ onChange, onBlur, value, name }) => (
              <Input
                name={name}
                form={form}
                label={t('fields.from')}
                type="number"
                max={isNaN(answerRangeTo) ? null : answerRangeTo - 1}
                onBlur={(e) => {
                  let v = parseInt(value);
                  if (v >= answerRangeTo) {
                    v = answerRangeTo - 1;
                    e.target.value = v;
                    changeQuestion(questionIndex, 'answerRangeFrom', v, e, onBlur);
                  }
                }}
                onChange={(e) => {
                  let v = parseInt(value);
                  if (v >= answerRangeTo) {
                    v = answerRangeTo - 1;
                  }
                  changeQuestion(questionIndex, 'answerRangeFrom', v, e, onChange);
                }}
                classNames={isHidden ? 'fm-display-none' : ''}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            name={`${qiu}.answerRangeTo`}
            defaultValue={isNaN(answerRangeTo) ? 10 : answerRangeTo}
            render={({ onChange, onBlur, value, name }) => (
              <Input
                name={name}
                form={form}
                label={t('fields.to')}
                type="number"
                min={isNaN(answerRangeFrom) ? null : answerRangeFrom + 1}
                onBlur={(e) => {
                  let v = parseInt(value);
                  if (v <= answerRangeFrom) {
                    v = answerRangeFrom + 1;
                    e.target.value = v;
                    changeQuestion(questionIndex, 'answerRangeTo', v, e, onBlur);
                  }
                }}
                onChange={(e) => {
                  let v = parseInt(value);
                  if (v <= answerRangeFrom) {
                    v = answerRangeFrom + 1;
                  }
                  changeQuestion(questionIndex, 'answerRangeTo', v, e, onChange);
                }}
                classNames={isHidden ? 'fm-display-none' : ''}
                value={value}
              />
            )}
          />
        </div>
      )}
    </>
  );
}

export default withTranslation()(Pulse);
