import React from 'react';
import { AUTH_ROUTES } from '../constants/routes';

const LinkUtil = {
  buildSurveyLink: (message, surveyId, link) => (
    <>
      <span>{message}</span>
      <br />
      <a href={AUTH_ROUTES.TESTS + (surveyId ? `/${surveyId}/edit` : '')}>{link}</a>
    </>
  ),
  buildTestAssignmentSummaryLink: (message, objectId, linkMessage) => (
    <>
      {message && (
        <>
          <span>{message}</span>
          <br />
        </>
      )}
      <a href={`${AUTH_ROUTES.ASSIGNMENT_PERIODS}/${objectId}/summary`}>{linkMessage}</a>
    </>
  ),
};
export default LinkUtil;
