import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import Moment from 'moment';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import Validation from '../../utils/validation';
import InputComponent from '../inputs/Input';
import TextAreaComponent from '../inputs/Textarea';

function InnerForm(props) {
  const { t, handleSubmit, setUserGroupRef, addValidation, addSubmition } = props;

  const [isInit, setIsInit] = useState(false);
  const [userGroupRef] = useState(React.createRef());

  if (!isInit) {
    addValidation(() => props.validateForm().then((result) => result));
    addSubmition(handleSubmit.bind(this));
    setUserGroupRef(userGroupRef);
    setIsInit(true);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        component={InputComponent}
        placeholder={t('fields.name')}
        type="text"
        name="name"
        label={t('fields.name')}
      />
      <Field
        component={TextAreaComponent}
        placeholder={t('fields.description')}
        type="text"
        name="description"
        label={t('fields.description')}
      />
      <Field
        component={InputComponent}
        placeholder={t('fields.created')}
        type="text"
        name="created"
        label={t('fields.created')}
        disabled
      />
      <Field
        component={InputComponent}
        placeholder={t('fields.updated')}
        type="text"
        name="updated"
        label={t('fields.updated')}
        disabled
      />
    </Form>
  );
}

InnerForm.propTypes = {
  userGroup: PropTypes.object.isRequired,
  updateUserGroup: PropTypes.func.isRequired,
  setUserGroupRef: PropTypes.func.isRequired,
  addValidation: PropTypes.func.isRequired,
  addSubmition: PropTypes.func.isRequired,
};

const EditUserGroupForm = withFormik({
  mapPropsToValues: (values) => {
    const { userGroup } = values;
    return {
      name: userGroup.name ? userGroup.name : '',
      description: userGroup.description ? userGroup.description : '',
      created: userGroup.created ? Moment(userGroup.created).format('LL') : '',
      updated: userGroup.updated ? Moment(userGroup.updated).format('LL') : '',
    };
  },
  enableReinitialize: true,
  validate: (values) => {
    const errors = {};
    errors.name = Validation.required(values.name);
    return _.isEmpty(_.filter(errors, (value) => value)) ? {} : errors;
  },
  validationSchema: (props) => {
    const { t } = props;
    return Yup.object().shape({
      name: Yup.string().required(t('message.required_field')),
    });
  },
  handleSubmit: (values, props) => {
    props.props.updateUserGroup(values);
  },
})(InnerForm);

export default withTranslation()(EditUserGroupForm);
