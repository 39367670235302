import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import FormTitle from '../form/FormTitle';
import Can from '../security/Can';
import Input from '../react-hook-form-inputs/Input';
import TextArea from '../react-hook-form-inputs/TextArea';

function InnerForm(props) {
  const { t, getFeedbackCategoryByName, updateFeedbackCategory, feedbackCategory } = props;

  const initialName = feedbackCategory.name;
  const schema = Yup.lazy((values) =>
    Yup.object().shape({
      name: Yup.string()
        .required(t('message.required_field'))
        .test('checkIfExists', t('message.already_exist'), (value) => {
          if (!value || initialName == value) {
            return true;
          }
          return new Promise((resolve, reject) => {
            getFeedbackCategoryByName(value)
              .then((data) => {
                resolve(!data);
              })
              .catch(() => {
                resolve(false);
              });
          });
        }),
      description: Yup.string(),
      created: Yup.date(),
      updated: Yup.date(),
    }),
  );

  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: feedbackCategory.name ? feedbackCategory.name : '',
      description: feedbackCategory.description ? feedbackCategory.description : '',
      created: feedbackCategory.created
        ? moment(new Date(moment.utc(feedbackCategory.created))).format('LL')
        : '',
      updated: feedbackCategory.updated
        ? moment(new Date(moment.utc(feedbackCategory.updated))).format('LL')
        : '',
    },
  });
  const { handleSubmit } = form;
  const onSubmit = (data) => {
    updateFeedbackCategory({
      id: feedbackCategory.id,
      name: data.name,
      description: data.description,
      tenantId: feedbackCategory.tenantId,
    });
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <div className="ibox">
        <FormTitle name="General Information" />
        <div className="ibox-content clearfix">
          <Controller
            control={form.control}
            name="name"
            id="name"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <Input
                name={name}
                placeholder={t('fields.name')}
                label={t('fields.name')}
                form={form}
                type="text"
                maxLength="256"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            control={form.control}
            name="description"
            id="description"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <TextArea
                name={name}
                placeholder={t('fields.description')}
                label={t('fields.description')}
                form={form}
                type="text"
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            control={form.control}
            name="created"
            id="created"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <Input
                name={name}
                placeholder={t('fields.created')}
                label={t('fields.created')}
                form={form}
                type="text"
                disabled
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            control={form.control}
            name="updated"
            id="updated"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <Input
                name={name}
                placeholder={t('fields.updated')}
                label={t('fields.updated')}
                form={form}
                type="text"
                disabled
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Can
            module="feedback-categories"
            permission="edit"
            yes={() => (
              <button type="submit" className="btn fm-btn-primary block pull-right">
                {t('buttons.save')}
              </button>
            )}
            no={() => null}
          />
        </div>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  updateFeedbackCategory: PropTypes.func.isRequired,
  getFeedbackCategoryByName: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
