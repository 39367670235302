import React, { Component } from 'react';

import { faPlus, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  createFeedbackCategory,
  getFeedbackCategoryByName,
  getFeedbackCategoryPagination,
} from '../../services/feedbackcategory/feedbackCategoryProvider';
import Loader from '../../components/loader/Loader';
import Notifications from '../../utils/Notifications';
import Modal from '../../components/modal/Modal';

import AllFeedbackCategoriesTable from '../../components/feedbackcategories/AllFeedbackCategoriesTable';
import CreateFeedbackCategoryModal from '../../components/feedbackcategories/CreateFeedbackCategoryModal';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';

class AllFeedbackCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isModalShown: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_feedback_categories'),
        ref: null,
        isActive: true,
      },
    ];
  };

  fetchFeedbackCategoryList = (amount, skip, sorting, filters, callback) => {
    getFeedbackCategoryPagination({
      amount,
      skip,
      sortings: sorting,
      filters,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  openCreateFeedbackCategoryModal = () => {
    this.setState({ isModalShown: true });
  };

  closeModal = () => {
    this.setState({ isModalShown: false });
  };

  createFeedbackCategory = (values) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
      isModalShown: false,
    });
    createFeedbackCategory(values)
      .then((data) => {
        this.state.tableRef.updateTableData(false);
        this.setState({ isLoading: false });
        Notifications.success(t('message.feedback_category_created', { name: values.name }));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { tableRef, isLoading, isModalShown } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.all_feedback_categories')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>

        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.all_feedback_categories')}
              buttons={[
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.openCreateFeedbackCategoryModal,
                  name: t('buttons.create'),
                  icon: faPlus,
                  securityModule: 'feedback-categories',
                  securityPermission: 'create',
                },
              ]}
            />
            <AllFeedbackCategoriesTable
              fetchFeedbackCategoryList={this.fetchFeedbackCategoryList}
              setTableReference={this.setTableReference}
              tableReference={tableRef}
            />
          </div>
        </div>
        <Modal
          header={t('components.create_feedback_category')}
          isOpened={isModalShown}
          icon={faSitemap}
          onClose={this.closeModal}
        >
          <CreateFeedbackCategoryModal
            getFeedbackCategoryByName={getFeedbackCategoryByName}
            createFeedbackCategory={this.createFeedbackCategory}
            cancel={this.closeModal}
          />
        </Modal>
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default withTranslation()(AllFeedbackCategories);
