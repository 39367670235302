import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class DoubleRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = { id: props.original.id };
    this.precision = props.column.precision;
  }

  onClickLink = () => {
    if (this.onClick) {
      this.onClick(this.state.id);
    }
  };

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  render() {
    const { t, value, precision } = this.props;
    const _precision = precision || 2;
    const val = value && !isNaN(value) ? value.toFixed(_precision) : t('components.nan');
    return (
      <div className="fm-table-string">
        <span title={val}>{val}</span>
      </div>
    );
  }
}

DoubleRenderer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(DoubleRenderer);
