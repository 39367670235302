import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { DevSupport } from '@react-buddy/ide-toolbox';
import reduxStore from './store';
import Routing from './routing/index';
import registerServiceWorker from './registerServiceWorker';
import './i18n';
import { ComponentPreviews, useInitial } from './dev';
import './dependencies';
import NewModalProvider from './components/NewModal/Provider';
import NewModaRoot from './components/NewModal/Root';

const store = reduxStore({});

ReactDOM.render(
  <Provider store={store}>
    <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
      <NewModalProvider>
        <>
          <Routing />
          <NewModaRoot />
        </>
      </NewModalProvider>
    </DevSupport>
  </Provider>,
  document.getElementById('root'),
);
registerServiceWorker();
