import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './styles/auth-form.css';
import { withRouter } from 'react-router-dom';
import RequestResetPasswordComponent from './RequestResetPassword';
import ResetPasswordFormComponent from './ResetPasswordFormComponent';

import { validatePasswordResetToken } from '../../services/user/userProvider';
import Notifications from '../../utils/Notifications';
import { RESET_PASSWORD_LINK_EXPIRED } from '../../constants/routes';

function InnerForm(props) {
  const { changeLocation, history } = props;

  const { params } = props.match;
  const [isPasswordResetTokenValid, setPasswordResetTokenValid] = useState(null);

  useEffect(() => {
    if (params.resetPasswordToken) {
      validatePasswordResetToken({ passwordResetToken: params.resetPasswordToken })
        .then((isValid) => {
          if (!isValid) {
            history.push(RESET_PASSWORD_LINK_EXPIRED);
          } else {
            setPasswordResetTokenValid(isValid);
          }
        })
        .catch((error) => {
          Notifications.error(error);
        });
    } else {
      setPasswordResetTokenValid(null);
    }
  }, [params.resetPasswordToken]);

  return (
    <>
      {isPasswordResetTokenValid === null && (
        <RequestResetPasswordComponent changeLocation={changeLocation} />
      )}
      {isPasswordResetTokenValid === true && (
        <ResetPasswordFormComponent
          passwordResetToken={params.resetPasswordToken}
          changeLocation={changeLocation}
        />
      )}
    </>
  );
}

InnerForm.propTypes = {
  changeLocation: PropTypes.func.isRequired,
  authState: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      resetPasswordToken: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withTranslation()(withRouter(InnerForm));
