import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Input from '../react-hook-form-inputs/Input';
import TextArea from '../react-hook-form-inputs/TextArea';
import SelectComponent from '../react-hook-form-inputs/Select';
import {
  getCaseReportSeverities,
  getCaseReportTypes,
  createCaseReport,
} from '../../services/bugtracking/CaseReportProvider';
import Notifications from '../../utils/Notifications';
import FileUtil from '../../utils/FileUtil';

export default function InnerForm({ closeModal }) {
  const { t } = useTranslation();
  const [severities, setSeverities] = useState([]);
  const [reportTypes, setReportTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [attachment, setAttachment] = useState(null);

  useEffect(() => {
    const promises = [];

    promises.push(getCaseReportSeverities());
    promises.push(getCaseReportTypes());

    Promise.all(promises)
      .then(([severities, types]) => {
        setSeverities(severities ? severities.items : []);
        setReportTypes(types ? types.items : []);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, [setSeverities, setReportTypes]);

  const schema = Yup.lazy(() =>
    Yup.object().shape({
      title: Yup.string().required(t('message.title_required')),
      description: Yup.string().required(t('message.describe_case')),
    }),
  );

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    const attachments = attachment && [attachment];

    const mappedValues = {
      ...values,
      reportType: values.reportType != null ? values.reportType.value : reportTypes[0].value,
      severity: values.severity != null ? values.severity.value : severities[0].value,
      attachments,
    };

    createCaseReport(mappedValues)
      .then(() => {
        closeModal();
        setLoading(false);
        Notifications.success(t('message.case_report_created'));
      })
      .catch((error) => {
        setLoading(false);
        Notifications.error(error);
      });
  };

  const handleAttachmentChange = (e) => {
    const {
      target: { files },
      target,
    } = e.nativeEvent;
    const [file] = files;
    if (!files || !file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = (readerEvt) => {
      const fileName = FileUtil.getFileName(target.value);
      setAttachment({
        fileName,
        extension: FileUtil.getFileExtension(fileName),
        base64: btoa(readerEvt.target.result),
      });
    };
    reader.readAsBinaryString(file);
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className="modal-body">
        <Controller
          control={form.control}
          name="title"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <Input
              name={name}
              placeholder={t('fields.title')}
              form={form}
              label={t('fields.title')}
              rows={4}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
        {reportTypes != null && reportTypes.length > 0 && (
          <Controller
            control={form.control}
            name="reportType"
            render={({ onChange, onBlur, value, name }) => (
              <SelectComponent
                name={name}
                nBlur={onBlur}
                value={value}
                form={form}
                defaultValue={reportTypes[0]}
                label={t('fields.report_type')}
                options={reportTypes}
                onChange={onChange}
              />
            )}
          />
        )}
        {severities != null && severities.length > 0 && (
          <Controller
            control={form.control}
            name="severity"
            render={({ onChange, onBlur, value, name }) => (
              <SelectComponent
                name={name}
                nBlur={onBlur}
                value={value}
                form={form}
                defaultValue={severities[0]}
                label={t('fields.severity')}
                options={severities}
                onChange={onChange}
              />
            )}
          />
        )}
        <Controller
          control={form.control}
          name="description"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <TextArea
              name={name}
              placeholder={t('fields.description')}
              form={form}
              label={t('fields.description')}
              rows={4}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <div className="mb-3">
          <label className="block mb-2 text-sm font-medium text-gray-900">
            {t('fields.attach_screenshot')}
          </label>
          <input
            ref={form.register}
            onChange={handleAttachmentChange}
            className="block w-full text-gray-900 border border-gray-300 cursor-pointer  focus:outline-none"
            name="attachments"
            type="file"
            accept="image/*"
          />
        </div>
      </div>
      <div className="flex items-center gap-2 justify-end p-4 border-t border-solid border-slate-200 rounded-b bg-white">
        <button
          className="text-gray-500 border rounded shadow border-solid border-gray-500 background-transparent font-bold capitalize px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={closeModal}
        >
          {t('buttons.cancel')}
        </button>
        <button
          className={cn(
            'bg-emerald-500 text-white active:bg-emerald-600 font-bold capitalize text-sm px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150',
            {
              loading: 'bg-gray-500',
            },
          )}
          type="submit"
          disabled={loading}
        >
          {t('buttons.send_report')}
        </button>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
