import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AUTH_ROUTES } from '../../constants/routes';

function InnerForm(props) {
  const goToSurveyPassing = () => {
    const { history } = props;
    history.push(AUTH_ROUTES.USER_TEST_ASSIGNMENTS);
  };

  const goToFeedbackRequests = () => {
    const { history } = props;
    history.push(AUTH_ROUTES.FEEDBACK.ASSIGNMENT_REQUEST);
  };
  const { t } = useTranslation();
  const { employeeSurveyAssignmentsCount, employeeFeedbackAssignmentsCount } = props;

  // Survey Widget Rules:
  // By default: You haven’t taken any surveys yet. На человека ни разу не асайнили сурвей
  let surveyBackgroundColor = 'fm-neutral-bg';
  let surveyMessage = t('dashboard.you_have_not_taken_surveys');

  if (employeeSurveyAssignmentsCount) {
    const { passedSurveyAssignment } = employeeSurveyAssignmentsCount;
    const { expiringSurveyAssignment } = employeeSurveyAssignmentsCount;
    const { assignedSurveyAssignment } = employeeSurveyAssignmentsCount;
    const { inProgressSurveyAssignment } = employeeSurveyAssignmentsCount;

    if (expiringSurveyAssignment > 0) {
      // Message: You have N expiring surveys to take! Expiring Survey:  End date - Current date <= 1 weekКоличество активных Expiring Survey Assignments = N, N > 0
      surveyBackgroundColor = 'fm-alert-bg';
      surveyMessage = t('dashboard.you_have_expiring_surveys_to_take', {
        surveysNumber: expiringSurveyAssignment,
      });
    } else if (inProgressSurveyAssignment > 0) {
      surveyBackgroundColor = 'fm-alert-bg';
      surveyMessage = t('dashboard.you_have_pending_surveys_to_take', {
        surveysNumber: inProgressSurveyAssignment,
      });
    } else if (assignedSurveyAssignment > 0) {
      // Message: You have taken all survey. Thank you!Количество пройденных сурвей асайментов > 0Количество активных сурвей асайментов = 0 Количество просроченных сурвей асайментов = 0
      surveyBackgroundColor = 'fm-warning-bg';
      surveyMessage = t('dashboard.you_have_surveys_to_take', {
        surveysNumber: assignedSurveyAssignment,
      });
    } else if (passedSurveyAssignment > 0) {
      // Message: You have N surveys to take. Expiring Survey amount = 0 Количество активных Survey Assignments = N, N > 0
      surveyBackgroundColor = 'fm-good-bg';
      surveyMessage = t('dashboard.you_finished_all_surveys');
    }
  }

  // Feedback widget rules
  // By default: You haven’t written any reviews yet. Человек ни разу не заполнял performance request
  let feedbackBackgroundColor = 'fm-neutral-bg';
  let feedbackMessage = t('dashboard.you_have_not_written_reviews');

  if (employeeFeedbackAssignmentsCount) {
    const { passedFeedbackAssignment } = employeeFeedbackAssignmentsCount;
    const { expiringFeedbackAssignment } = employeeFeedbackAssignmentsCount;
    const { assignedFeedbackAssignment } = employeeFeedbackAssignmentsCount;
    if (expiringFeedbackAssignment > 0) {
      // Message: N teammates need your review this week!
      // Expiring performance request:  End date - Current
      // date <= 1 week Количество Expiring performance request = N, N > 0
      feedbackBackgroundColor = 'fm-alert-bg';
      feedbackMessage = t('dashboard.you_have_expiring_feedback_to_take', {
        feedbackNumber: expiringFeedbackAssignment,
      });
    } else if (assignedFeedbackAssignment > 0) {
      // Message: N teammates are waiting for review.
      // Expiring performance request: = 0
      // Количество активных performance request = N, N > 0
      feedbackBackgroundColor = 'fm-warning-bg';
      feedbackMessage = t('dashboard.you_have_feedback_requests', {
        feedbackNumber: assignedFeedbackAssignment,
      });
    } else if (passedFeedbackAssignment > 0) {
      // Message: You have written all reviews. Great job!
      // Количество заполненных performance requests > 0
      // Количество текущих performance requests= 0
      feedbackBackgroundColor = 'fm-good-bg';
      feedbackMessage = t('dashboard.you_finished_all_reviews');
    }
  }

  {
    return (
      <div className="row wrapper">
        <div className="col-lg-6">
          <div className={`fm-home-surveys ${surveyBackgroundColor}`} onClick={goToSurveyPassing}>
            <div className="fm-home-widget-header">
              <FontAwesomeIcon size="5x" icon={faCalendarAlt} />
              <span>{t('dashboard.surveys')}</span>
            </div>
            <div className="fm-home-widget-advice">
              <span>{surveyMessage}</span>
            </div>
          </div>
        </div>
        <div className="col-lg-6" onClick={goToFeedbackRequests}>
          <div className={`fm-home-requests  ${feedbackBackgroundColor}`}>
            <div className="fm-home-widget-header">
              <FontAwesomeIcon size="5x" icon={faTasks} />
              <span>{t('dashboard.feedback_requests')}</span>
            </div>
            <div className="fm-home-widget-advice">
              <span>{feedbackMessage}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

InnerForm.propTypes = {};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withRouter(connect(mapStateToProps)(InnerForm));
