import dataProvider from '../dataProvider';
import { demorequest } from '../../constants/endpoints';

export const getRequestDemoList = (params) =>
  dataProvider({
    url: demorequest.all(),
    params,
    method: 'GET',
  });

export const getRequestDemo = (requestDemoId) =>
  dataProvider({
    url: demorequest.getById(requestDemoId),
    method: 'GET',
  });

export const createRequestDemo = (values) =>
  dataProvider({
    url: demorequest.create(),
    method: 'POST',
    data: values,
  });

export const updateRequestDemo = (params) =>
  dataProvider({
    url: demorequest.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteRequestDemo = (requestDemoId) =>
  dataProvider({
    url: demorequest.delete(requestDemoId),
    method: 'DELETE',
  });
