import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles';

function InputComponent(props) {
  const {
    disabled,
    field,
    form: { touched, errors },
    classNames,
    label,
    setFieldValue,
    onChange,
    required,
    checkedIcon,
    checked,
    icon,
    root,
    color,
    labelPlacement,
    checkboxColor,
    checkboxHoverColor,
  } = props;
  const errorMessage =
    field &&
    field.name &&
    typeof field.name === 'string' &&
    field.name.split('.').reduce((o, i) => (o ? o[i] : false), touched) &&
    field.name.split('.').reduce((o, i) => (o ? o[i] : false), errors);

  const useStyles = makeStyles({
    root: {
      color: checkboxColor || 'rgba(0, 0, 0, 0.54)',
      '&:hover': {
        backgroundColor: checkboxHoverColor || 'rgba(0, 0, 0, 0.08)',
      },
    },
    checked: {
      '&$checked': {
        color: checkboxColor || 'rgba(0, 0, 0, 0.54)',
      },
    },
  });
  const classes = useStyles();

  const icons = {};
  if (icon) {
    icons.icon = icon;
  }
  if (checkedIcon) {
    icons.checkedIcon = checkedIcon;
  }

  return (
    <FormControl required={required} error component="fieldset">
      <FormGroup>
        <FormControlLabel
          control={
            <Radio
              {...icons}
              root={root}
              className={classNames}
              name={field.name}
              value={field.value}
              color={color}
              checked={checked}
              disabled={disabled}
              classes={{
                root: classes.root,
                checked: classes.checked,
              }}
              defaultValue={field.value ? field.value : ''}
              onChange={(e) => {
                onChange && onChange(e);
                setFieldValue(field.name, field.value || e.target.checked);
                field.onChange.call(this, e);

                return e;
              }}
            />
          }
          labelPlacement={labelPlacement}
          className={checked ? 'fm-element-checked' : ''}
          label={label}
        />
      </FormGroup>
      {errorMessage ? <FormHelperText>{errorMessage}</FormHelperText> : null}
    </FormControl>
  );
}

InputComponent.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  classNames: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  checkedIcon: PropTypes.object,
  color: PropTypes.string,
};

export default InputComponent;
