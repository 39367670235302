import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AllFeedbacks from '../../containers/feedbacks/AllFeedbacks';
import ViewEditFeedbackPage from '../../containers/feedbacks/ViewEditFeedbackPage';

import { AUTH_ROUTES, FEEDBACK_ID } from '../../constants/routes';

class FeedbackRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllFeedbacks} />
        <Route
          path={`${match.url}${FEEDBACK_ID}`}
          render={({ match }) => <FeedbackInnerRouting match={match} />}
        />
      </div>
    );
  }
}

FeedbackRoutes.propTypes = {
  match: PropTypes.object,
};

export default FeedbackRoutes;

class FeedbackInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let feedbackRouting;
    if (match.params.feedbackId) {
      switch (match.params.feedbackId) {
        case 'create':
          feedbackRouting = <Route path={`${match.url}`} component={ViewEditFeedbackPage} />;
          break;
        case 'all':
          feedbackRouting = <Route path={`${match.url}`} component={AllFeedbacks} />;
          break;
        default:
          feedbackRouting = (
            <Route exact path={`${AUTH_ROUTES.FEEDBACKS}${FEEDBACK_ID}`} component={AllFeedbacks} />
          );
      }
    }
    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.FEEDBACKS}${FEEDBACK_ID}/edit`}
          component={ViewEditFeedbackPage}
        />
        {feedbackRouting}
      </div>
    );
  }
}

FeedbackInnerRouting.propTypes = {
  match: PropTypes.object,
};
