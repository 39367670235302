import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from '@material-ui/core/styles/index';
import _ from 'lodash';
import FormHelperText from '@material-ui/core/FormHelperText';
import { HelpOutline } from '@material-ui/icons';
import classnames from 'classnames';
import MaterialHtmlTooltip from '../ui-elements/MaterialHtmlTooltip';
import './styles/checkbox.css';

function InputComponent(props) {
  const {
    name,
    value,
    disabled,
    form: { touched, errors },
    classNames,
    label,
    onChange,
    required,
    checkedIcon,
    checked,
    icon,
    color,
    elementColor,
    checkboxHoverColor,
    checkedElementColor,
    fieldSetClassNames,
    helperMessage,
    helperMessagePosition,
    controlClassNames,
  } = props;

  const error = _.get(errors, name);

  const useStyles = makeStyles({
    root: {
      color: elementColor || 'rgba(0, 0, 0, 0.54)',
      '&:hover': {
        backgroundColor: checkboxHoverColor || 'rgba(0, 0, 0, 0.08)',
      },
    },
    checked: {
      '&$checked': {
        color: checkedElementColor || 'rgba(0, 0, 0, 0.54)',
      },
    },
  });

  const classes = useStyles();

  return (
    <FormControl
      required={required}
      error={error ? error.message : undefined}
      component="fieldset"
      className={classnames(fieldSetClassNames, controlClassNames, 'form-element', {
        'fm-checkbox-help-msg': helperMessage,
      })}
    >
      <FormGroup>
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              icon={icon}
              name={name}
              color={color}
              checked={checked}
              checkedIcon={checkedIcon}
              disabled={disabled}
              className={classNames}
              onChange={(e) => {
                onChange && onChange(e);
              }}
              error={error ? error.message : undefined}
              classes={{
                root: classes.root,
                checked: classes.checked,
              }}
              value={value}
            />
          }
        />
        {helperMessage && (
          <div className="fm-toggle-control">
            <MaterialHtmlTooltip
              title={helperMessage}
              placement={helperMessagePosition || 'bottom'}
              arrow
            >
              <HelpOutline />
            </MaterialHtmlTooltip>
          </div>
        )}
      </FormGroup>
      {error ? (
        <FormHelperText className="error-text animated fadeIn">{error.message}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

InputComponent.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  classNames: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.object,
  checkedIcon: PropTypes.object,
  color: PropTypes.string,
};

export default InputComponent;
