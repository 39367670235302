import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Loader from '../../components/loader/Loader';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';
import Notifications from '../../utils/Notifications';
import { getEmployeeByUserId } from '../../services/employee/employeeProvider';
import {
  getFeedbackAssignment,
  getLastFinishedFeedbackAssignmentByUserId,
} from '../../services/feedbackassignment/feedbackAssignmentProvider';
import UserFeedbackAssignmentSummaryForm from '../../components/feedbackassignments/UserFeedbackAssignmentSummaryForm';
import NoDataForm from '../../components/form/NoDataForm';

class UserFeedbackAssignmentProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.my_feedback_profile'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { account } = this.props;

    this.setState({ isLoading: true });
    const promises = [];
    promises.push(getLastFinishedFeedbackAssignmentByUserId(account.userId));
    promises.push(getEmployeeByUserId(account.userId));
    Promise.all(promises)
      .then(([feedbackAssignment, employee]) => {
        this.setState({
          isLoading: false,
          feedbackAssignment,
          employee,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  refreshFeedbackAssignment = (feedbackAssignmentId) => {
    this.setState({ isLoading: true });

    getFeedbackAssignment(feedbackAssignmentId)
      .then((feedbackAssignment) => {
        this.setState({
          isLoading: false,
          feedbackAssignment,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.location !== nextProps.location && nextProps.location.state) {
      this.refreshFeedbackAssignment(nextProps.location.state.feedbackAssignmentId);
    }
  }

  render() {
    const { isLoading, employee, feedbackAssignment } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.my_feedback_profile')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>

        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle name={t('components.my_feedback_profile')} />
            {feedbackAssignment && employee ? (
              <UserFeedbackAssignmentSummaryForm
                feedbackAssignment={feedbackAssignment}
                employee={employee}
              />
            ) : (
              <NoDataForm label={t('components.no_information_yet')} />
            )}
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withTranslation()(connect(mapStateToProps)(UserFeedbackAssignmentProfile));
