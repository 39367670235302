import {
  LOGOUT,
  REGISTER_USER_DETAILS,
  REGISTER_USER_PHOTO,
  SIGN_IN,
} from '../../constants/actions';

const accountData = JSON.parse(localStorage.getItem('account'));
const initialState = {
  accessToken: accountData ? accountData.accessToken : null,
  refreshToken: accountData ? accountData.refreshToken : null,
};

export default function account(state = initialState, action) {
  switch (action.type) {
    case SIGN_IN:
      return {
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
      };
    case REGISTER_USER_DETAILS: {
      const { userDetails } = action;
      const { user, employeeJob, employeeJobLevel, location } = userDetails;
      return {
        ...state,
        location: location
          ? typeof location === 'object'
            ? location.displayString
            : location
          : '',
        employeeJob: employeeJob
          ? typeof employeeJob === 'object'
            ? employeeJob.displayString
            : employeeJob
          : '',
        employeeJobLevel: employeeJobLevel
          ? typeof employeeJobLevel === 'object'
            ? employeeJobLevel.displayString
            : employeeJobLevel
          : '',
        language: user.language,
        tenant: userDetails.tenant || '',
        permissions: userDetails.permissions || '',
        firstName: (user && user.firstName) || '',
        lastName: (user && user.lastName) || '',
        middleName: (user && user.middleName) || '',
        username: user.username || '',
        userId: userDetails.userId || '',
        email: user.email || '',
        role: userDetails.roles || '',
        imageLink: (user && user.profileImageLink) || '',
      };
    }
    case REGISTER_USER_PHOTO:
      return {
        ...state,
        imageLink: action.userPhoto.imageLink,
      };
    case LOGOUT:
      return {
        tenant: null,
        accessToken: null,
        refreshToken: null,
        id: null,
        username: null,
        mainRole: null,
        firstName: null,
        lastName: null,
        middleName: null,
        email: null,
        role: null,
        imageLink: null,
        location: null,
        employeeJob: null,
        employeeJobLevel: null,
      };
    default:
      return state || null;
  }
}
