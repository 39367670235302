import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Input from '../react-hook-form-inputs/Input';
import Notifications from '../../utils/Notifications';
import { changePassword } from '../../services/user/userProvider';
import Validation from '../../utils/validation';

function InnerForm(props) {
  const { t, cancel } = props;

  const schema = Yup.object().shape({
    oldPassword: Yup.string().isRequired,
    newPassword: Yup.string()
      .required(t('message.no_password_provided'))
      .matches(Validation.makePasswordRegExp(8), t('message.weak_password')),
    confirmPassword: Yup.string()
      .required(t('message.no_password_provided'))
      .oneOf([Yup.ref('newPassword'), null], t('message.passwords_must_match')),
  });
  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });
  const { handleSubmit, control, setError } = form;

  const onSubmit = (values) => {
    changePasswordRequest(values);
  };

  const changePasswordRequest = (values) => {
    changePassword(values)
      .then((isOk) => {
        if (isOk) {
          Notifications.success(t('message.password_updated'));
          cancel();
        }
      })
      .catch((error) => {
        if (error.fieldsErrors) {
          for (const field in error.fieldsErrors) {
            setError(field, {
              shouldFocus: true,
              type: 'server',
              message: error.fieldsErrors[field],
            });
          }
        } else {
          Notifications.error(error);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <div className="modal-body">
        <Controller
          control={control}
          name="oldPassword"
          render={({ onChange, onBlur, value, name }) => (
            <Input
              name={name}
              form={form}
              label={t('fields.old_password')}
              placeholder={t('fields.old_password')}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              type="password"
            />
          )}
        />
        <Controller
          control={control}
          name="newPassword"
          render={({ onChange, onBlur, value, name }) => (
            <Input
              name={name}
              form={form}
              label={t('fields.new_password')}
              placeholder={t('fields.new_password')}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              type="password"
            />
          )}
        />
        <Controller
          control={control}
          name="confirmPassword"
          render={({ onChange, onBlur, value, name }) => (
            <Input
              name={name}
              form={form}
              label={t('fields.confirm_password')}
              placeholder={t('fields.confirm_password')}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              type="password"
            />
          )}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
        <button
          type="button"
          onClick={handleSubmit((data) => {
            onSubmit(data);
          })}
          className="btn fm-btn-primary"
        >
          {t('buttons.change_password')}
        </button>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  cancel: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
