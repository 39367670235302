import dataProvider from '../dataProvider';
import { scaletemplate } from '../../constants/endpoints';

export const getScaleTemplatesList = (params) =>
  dataProvider({
    url: scaletemplate.all(),
    params,
    method: 'GET',
  });

export const getScaleTemplatesByTestId = (testId) =>
  dataProvider({
    url: scaletemplate.getByTestId(testId),
    method: 'GET',
  });

export const getScaleTemplatesByQuestionClassificationId = (questionClassificationId) =>
  dataProvider({
    url: scaletemplate.getByQuestionClassificationId(questionClassificationId),
    method: 'GET',
  });

export const getScaleTemplate = (scaleId) =>
  dataProvider({
    url: scaletemplate.getById(scaleId),
    method: 'GET',
  });

export const createScaleTemplate = (values) =>
  dataProvider({
    url: scaletemplate.create(),
    method: 'POST',
    data: values,
  });

export const updateScaleTemplate = (params) =>
  dataProvider({
    url: scaletemplate.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteScaleTemplate = (scaleId) =>
  dataProvider({
    url: scaletemplate.delete(scaleId),
    method: 'DELETE',
  });

export const getScaleTemplateSelectItemSource = (params) =>
  dataProvider({
    url: scaletemplate.itemSource(),
    method: 'GET',
    params,
  });

export const createScaleTemplates = (data) =>
  dataProvider({
    url: scaletemplate.createBatch(),
    method: 'POST',
    data,
  });
