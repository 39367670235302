import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AllEmployees from '../../containers/employees/AllEmployees';
import EditEmployeePage from '../../containers/employees/EditEmployeePage';

import { AUTH_ROUTES, EMPLOYEE_ID } from '../../constants/routes';

class EmployeeRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllEmployees} />
        <Route
          path={`${match.url}${EMPLOYEE_ID}`}
          render={({ match }) => <EmployeeInnerRouting match={match} />}
        />
      </div>
    );
  }
}

EmployeeRoutes.propTypes = {
  match: PropTypes.object,
};

export default EmployeeRoutes;

class EmployeeInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let employeeRouting;
    if (match.params.employeeId) {
      switch (match.params.employeeId) {
        default:
          employeeRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.ORG.EMPLOYEES}${EMPLOYEE_ID}`}
              component={AllEmployees}
            />
          );
      }
    }

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.ORG.EMPLOYEES}${EMPLOYEE_ID}/edit`}
          component={EditEmployeePage}
        />
        {employeeRouting}
      </div>
    );
  }
}

EmployeeInnerRouting.propTypes = {
  match: PropTypes.object,
};
