import dataProvider from '../dataProvider';

import { feedbackassignment } from '../../constants/endpoints';

export const getFeedbackAssignmentList = (params) =>
  dataProvider({
    url: feedbackassignment.all(),
    params,
    method: 'GET',
  });

export const getFeedbackAssignment = (feedbackAssignmentId) =>
  dataProvider({
    url: feedbackassignment.getById(feedbackAssignmentId),
    method: 'GET',
  });

export const createFeedbackAssignment = (params) =>
  dataProvider({
    url: feedbackassignment.create(),
    data: params,
    method: 'POST',
  });

export const updateFeedbackAssignment = (params) =>
  dataProvider({
    url: feedbackassignment.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteFeedbackAssignment = (feedbackAssignmentId) =>
  dataProvider({
    url: feedbackassignment.delete(feedbackAssignmentId),
    method: 'DELETE',
  });

export const getFeedbackAssignmentItemSource = (params) =>
  dataProvider({
    url: feedbackassignment.itemSource(),
    data: params,
    method: 'POST',
  });
export const getFeedbackAssignmentPagination = (params) =>
  dataProvider({
    url: feedbackassignment.pagination(),
    data: params,
    method: 'POST',
  });

export const getFeedbackAssignmentManagerSummary = (feedbackAssignmentId) =>
  dataProvider({
    url: feedbackassignment.getFeedbackAssignmentManagerSummary(feedbackAssignmentId),
    method: 'GET',
  });

export const getFeedbackAssignmentUserSummary = (feedbackAssignmentId) =>
  dataProvider({
    url: feedbackassignment.getFeedbackAssignmentUserSummary(feedbackAssignmentId),
    method: 'GET',
  });

export const getFeedbackAssignmentsListByUserId = (userId) =>
  dataProvider({
    url: feedbackassignment.getFeedbackAssignmentsListByUserId(userId),
    method: 'GET',
  });
export const getLastFeedbackAssignmentByUserId = (userId) =>
  dataProvider({
    url: feedbackassignment.getLastFeedbackAssignmentByUserId(userId),
    method: 'GET',
  });
export const getLastFinishedFeedbackAssignmentByUserId = (userId) =>
  dataProvider({
    url: feedbackassignment.getLastFinishedFeedbackAssignmentByUserId(userId),
    method: 'GET',
  });

export const getEmployeeFeedbackAssignmentsCount = () =>
  dataProvider({
    url: feedbackassignment.countEmployeeAssignments(),
    method: 'GET',
  });
