import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PageNameContainer from '../../components/page/PageNameContainer';
import Loader from '../../components/loader/Loader';
import FormTitle from '../../components/form/FormTitle';
import Notifications from '../../utils/Notifications';
import EditUserRoleForm from '../../components/user/EditUserRoleForm';
import {
  getUserRoleById,
  getUserRolePermissionsByUserRoleId,
} from '../../services/user/userRoleProvider';

class EditUserRolePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      groupMembers: [],
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_user_roles'),
        ref: '/user-roles',
        isActive: false,
      },
      {
        name: t('breadcrumbs.user_role'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { params } = this.props.match;
    this.setState({ isLoading: true });
    Promise.all([
      getUserRoleById(params.userRoleId),
      getUserRolePermissionsByUserRoleId(params.userRoleId),
    ])
      .then(([userRole, userRolePermissions]) => {
        const permissions = {};

        if (userRolePermissions) {
          for (let i = 0; i < userRolePermissions.length; i++) {
            permissions[userRolePermissions[i].name] = true;
          }
        }

        this.setState({
          userRole,
          userRolePermissions: permissions,
          isLoading: false,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { isLoading, userRole, userRolePermissions } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.user_role')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox float-e-margins col-md-12">
            <FormTitle name={t('components.user_role')} />
            <div className="ibox-content">
              {userRole && (
                <EditUserRoleForm userRole={userRole} userRolePermissions={userRolePermissions} />
              )}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </div>
    );
  }
}

EditUserRolePage.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userRoleId: PropTypes.string.isRequired,
    }),
  }),
};
export default withTranslation()(withRouter(EditUserRolePage));
