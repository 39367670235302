import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class ActivityStatusRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = { id: props.original.id };
  }

  onClickLink = () => {
    this.onClick(this.state.id);
  };

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  render() {
    const { t, value } = this.props;
    return (
      <div className="fm-table-string">
        {value === 'Active' && t('components.active')}
        {value === 'Inactive' && t('components.inactive')}
      </div>
    );
  }
}

export default withTranslation()(ActivityStatusRenderer);
