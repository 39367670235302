import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import Input from '../react-hook-form-inputs/Input';
import Can from '../security/Can';

function InnerForm(props) {
  const { t, tenant, updateTenant } = props;

  const schema = Yup.object().shape({
    name: Yup.string().required(t('message.field_required')),
    shortName: Yup.string().required(t('message.field_required')),
  });

  const tenantValues = {
    name: tenant.name,
    shortName: tenant.shortName,
    created: tenant.created ? moment.utc(tenant.created).format('LLL') : '',
    updated: tenant.updated ? moment.utc(tenant.updated).format('LLL') : '',
  };

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => tenantValues, [props.tenant]),
  });

  const { handleSubmit, control, reset } = form;

  useEffect(() => {
    reset(tenantValues);
  }, [props.tenant]);

  const onSubmit = (data) => {
    updateTenant({
      ...tenant,
      name: data.name,
      shortName: data.shortName,
    });
  };
  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Controller
        control={control}
        id="name"
        name="name"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <Input
            name={name}
            placeholder={t('fields.organization_unit_name')}
            label={t('fields.organization_unit_name')}
            form={form}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        control={control}
        id="shortName"
        name="shortName"
        defaultValue=""
        render={({ onChange, onBlur, value, name }) => (
          <Input
            name={name}
            placeholder={t('fields.organization_unit_short_name')}
            label={t('fields.organization_unit_short_name')}
            form={form}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Can
        module="tenant"
        permission="edit"
        yes={() => (
          <button type="submit" className="btn fm-btn-primary block pull-right">
            {t('buttons.save')}
          </button>
        )}
        no={() => null}
      />
    </form>
  );
}

InnerForm.propTypes = {
  t: PropTypes.func.isRequired,
  tenant: PropTypes.object,
  updateTenant: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
