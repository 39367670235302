import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AllLocations from '../../containers/location/AllLocations';
import EditLocationPage from '../../containers/location/EditLocationPage';

import { AUTH_ROUTES, LOCATION_ID } from '../../constants/routes';

class LocationRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllLocations} />
        <Route
          path={`${match.url}${LOCATION_ID}`}
          render={({ match }) => <LocationInnerRouting match={match} />}
        />
      </div>
    );
  }
}

LocationRoutes.propTypes = {
  match: PropTypes.object,
};

export default LocationRoutes;

class LocationInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let locationRouting;
    if (match.params.locationId) {
      switch (match.params.locationId) {
        default:
          locationRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.ORG.LOCATIONS}${LOCATION_ID}`}
              component={AllLocations}
            />
          );
      }
    }

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.ORG.LOCATIONS}${LOCATION_ID}/edit`}
          component={EditLocationPage}
        />
        {locationRouting}
      </div>
    );
  }
}

LocationInnerRouting.propTypes = {
  match: PropTypes.object,
};
