import {
  SET_QUESTION_TYPES,
  SET_QUESTIONS,
  SET_TEST_DETAILS,
  SET_TEST_TYPES,
} from '../../constants/actions';

const initialState = {
  questionTypes: [],
  testTypes: [],
  testDetails: {},
  questions: null,
};

export default function tests(state = initialState, action) {
  switch (action.type) {
    case SET_TEST_TYPES:
      return {
        ...state,
        testTypes: action.types,
      };
    case SET_QUESTION_TYPES:
      return {
        ...state,
        questionTypes: action.types,
      };
    case SET_TEST_DETAILS:
      return {
        ...state,
        testDetails: action.testDetails,
      };
    case SET_QUESTIONS:
      return {
        ...state,
        questions: action.questions,
      };
    default:
      return state || null;
  }
}
