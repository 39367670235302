import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import PageNameContainer from '../../components/page/PageNameContainer';
import GeneralAssignmentPeriodInfo from '../../components/assignmentperiod/GeneralAssignmentPeriodInfo';
import AssignmentPeriodSettings from '../../components/assignmentperiod/AssignmentPeriodSettings';
import {
  getAssignmentPeriod,
  updateAssignmentPeriod,
} from '../../services/assignmentperiod/assignmentPeriodProvider';

class EditAssignmentPeriodPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      assignmentPeriod: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_assignment_periods'),
        ref: '/assignment-periods',
        isActive: false,
      },
      {
        name: t('breadcrumbs.assignment_period'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { assignmentPeriodId } = this.props.match.params;
    this.setState({ isLoading: true });

    getAssignmentPeriod(assignmentPeriodId)
      .then((assignmentPeriod) => {
        this.setState({
          isLoading: false,
          assignmentPeriod,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  updateAssignmentPeriod = (data) => {
    const { t } = this.props;
    this.setState({ isLoading: true });
    updateAssignmentPeriod(data)
      .then((_data) => {
        const { location } = this.props.history;
        getAssignmentPeriod(location.state.assignmentPeriodId).then((updatedAssignmentPeriod) => {
          this.setState({
            isLoading: false,
            assignmentPeriod: updatedAssignmentPeriod,
          });
        });
        Notifications.success(t('message.assignment_period_successfully_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, assignmentPeriod } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.assignment_period')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-6">
              {assignmentPeriod && assignmentPeriod.id ? (
                <GeneralAssignmentPeriodInfo assignmentPeriod={assignmentPeriod} />
              ) : (
                <div>
                  <div className="ibox-content">
                    <p className="text-muted text-center">
                      {t('containers.no_assignment_period_information')}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-6">
              {assignmentPeriod && assignmentPeriod.id ? (
                <AssignmentPeriodSettings
                  updateAssignmentPeriod={this.updateAssignmentPeriod}
                  assignmentPeriod={assignmentPeriod}
                />
              ) : (
                <div>
                  <div className="ibox-content">
                    <p className="text-muted text-center">
                      {t('containers.no_assignment_period_information')}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

EditAssignmentPeriodPage.propTypes = {
  assignmentPeriod: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
};

export default withTranslation()(EditAssignmentPeriodPage);
