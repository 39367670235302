import React, { Component } from 'react';

class NeutralSmile extends Component {
  render() {
    return (
      <div>
        <svg
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="UI" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Desktop-HD" transform="translate(-619.000000, -521.000000)" fill="#DFBB92">
              <g id="Group-19" transform="translate(269.000000, 171.000000)">
                <g id="Solid/check-circle" transform="translate(350.000000, 350.000000)">
                  <path
                    d="M24,12 C24,18.6274355 18.6274355,24 12,24 C5.37256452,24 0,18.6274355 0,12 C0,5.37256452 5.37256452,0 12,0 C18.6274355,0 24,5.37256452 24,12 Z M7.5,16 L16.5,16 C17.3284271,16 18,16.6715729 18,17.5 C18,18.3284271 17.3284271,19 16.5,19 L7.5,19 C6.67157288,19 6,18.3284271 6,17.5 C6,16.6715729 6.67157288,16 7.5,16 Z M8,11 C9.1045695,11 10,10.1045695 10,9 C10,7.8954305 9.1045695,7 8,7 C6.8954305,7 6,7.8954305 6,9 C6,10.1045695 6.8954305,11 8,11 Z M16,11 C17.1045695,11 18,10.1045695 18,9 C18,7.8954305 17.1045695,7 16,7 C14.8954305,7 14,7.8954305 14,9 C14,10.1045695 14.8954305,11 16,11 Z"
                    id="Mask"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

export default NeutralSmile;
