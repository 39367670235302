import React, { Component } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import DoneAllRounded from '@mui/icons-material/DoneAllRounded';

class ReviewStatusRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = { id: props.original.id };
  }

  statusRenderer = (value) => {
    const { t } = this.props;
    switch (value) {
      case 'New':
        return (
          <div className="fm-status-btn fm-self-status-not-started">
            <span>{t('components.not_started')}</span>
          </div>
        );
      case 'Rejected':
        return (
          <div className="fm-status-btn fm-self-status-rejected">
            <FontAwesomeIcon icon={faTimes} />
            <span>{t('components.rejected')}</span>
          </div>
        );
      case 'Draft':
        return (
          <div className="fm-status-btn fm-self-status-in-progress">
            <span>{t('components.in_progress')}</span>
          </div>
        );
      case 'Finished':
        return (
          <div className="fm-status-btn fm-self-status-done">
            <DoneAllRounded fontSize="small" />
            <span>{t('components.done')}</span>
          </div>
        );
    }
  };

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  render() {
    const { value } = this.props;
    return <div className="fm-assignment-status">{this.statusRenderer(value)}</div>;
  }
}

export default withTranslation()(ReviewStatusRenderer);
