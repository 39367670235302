import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import _ from 'lodash';
import {
  faChevronDown,
  faChevronRight,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import FeedbackCriteria from './FeedbackCriteria';

function FeedbackCategoryForm(props) {
  const { t, setForm, feedback, criteria } = props;

  const { name, description, category } = feedback;

  const validationSchema = Yup.object().shape({});

  const [feedbackShow, setFeedbackShow] = useState(true);
  const [criteriaShow, setCriteriaShow] = useState(true);
  const [render, setRender] = useState(true);

  const toggleCriterion = (event, index) => {
    criteria[index].isHidden = !criteria[index].isHidden;
    setRender(!render);
  };

  useEffect(() => {
    setForm('summary', null);
    _.forEach(criteria, (c) => {
      c.isHidden = true;
    });
  }, []);

  return (
    <>
      <div className="fm-wizard-element-title-container">
        <div className="fm-wizard-element-title">{t('components.summary')}</div>
      </div>
      <div
        className="fm-wizard-element-title-container fm-feedback-summary-section-header"
        onClick={() => setFeedbackShow(!feedbackShow)}
      >
        <div className="fm-wizard-element-title">{t('components.common_information')}</div>
        <div className="fm-feedback-summary-controls">
          <FontAwesomeIcon icon={feedbackShow ? faChevronRight : faChevronDown} />
        </div>
      </div>
      {feedbackShow && (
        <div className="ibox-content clearfix fm-wizard-element-content-box fm-feedback-summary-section-body">
          <div className="fm-common-info-feedback fm-feedback-summary">
            <div className="fm-main-info-row">
              <div className="fm-feedback-info-part">
                <div className="fm-row-fields">
                  <span className="fm-feedback-label">{t('components.feedback_name')}</span>
                  <span className="fm-feedback-value">{name}</span>
                </div>
                {description && (
                  <div className="fm-row-fields">
                    <span className="fm-feedback-label">{t('components.description')}</span>
                    <span className="fm-feedback-value">{description}</span>
                  </div>
                )}
                {category && (
                  <div className="fm-row-fields">
                    <span className="fm-feedback-label">{t('components.category')}</span>
                    <span className="fm-feedback-value">
                      <div className="fm-cl-tag">
                        <span className="fm-badge">{category.name}</span>
                      </div>
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="fm-additional-info-row" />
          </div>
        </div>
      )}
      <div
        className="fm-wizard-element-title-container fm-feedback-summary-section-header"
        onClick={() => setCriteriaShow(!criteriaShow)}
      >
        <div className="fm-wizard-element-title">{t('components.criteria')}</div>
        <div className="fm-feedback-summary-controls">
          <FontAwesomeIcon icon={criteriaShow ? faChevronRight : faChevronDown} />
        </div>
      </div>
      {criteriaShow && (
        <div className="ibox-content clearfix fm-wizard-element-content-box fm-feedback-summary-section-body">
          <div className="fm-feedback-criteria">
            {criteria.map(
              (criterion, index) =>
                criterion.action !== 'delete' && (
                  <div className="fm-criterion-container" key={`criteria[${index}].criterionKeyId`}>
                    <div className="fm-criterion-dnd-control" />
                    <div className="fm-criterion-info">
                      {criterion && criterion.name && criterion.name.length > 0 ? (
                        <div
                          className="fm-criterion-text"
                          onClick={(event) => toggleCriterion(event, index)}
                        >
                          {criterion.name}
                        </div>
                      ) : (
                        <div className="alert alert-warning fm-question-text">
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                          {t('components.empty')}
                        </div>
                      )}
                      {criterion && criterion.description && (
                        <div
                          className="fm-criterion-text"
                          onClick={(event) => toggleCriterion(event, index)}
                        >
                          {' '}
                          - {criterion.description}
                        </div>
                      )}
                      {!criterion.isHidden &&
                        criterion.feedbackCriteriaDescriptions &&
                        criterion.feedbackCriteriaDescriptions.length > 0 && (
                          <>
                            <div className="fm-row-fields">
                              <span className="fm-feedback-label">
                                {t('components.feedback_criterion_description')}
                              </span>
                            </div>
                            <ol className="fm-feedback-summary-criterion-description-list">
                              {criterion.feedbackCriteriaDescriptions.map(
                                (criterionDescription, aIndex) =>
                                  criterionDescription.action !== 'delete' && (
                                    <div
                                      className="fm-feedback-summary-v"
                                      key={`${index}-criterion-description-${aIndex}`}
                                    >
                                      <li className="fm-row-fields">
                                        <div
                                          className="fm-feedback-summary-criterion-description-color"
                                          style={{ backgroundColor: criterionDescription.color }}
                                        />
                                        <span>{criterionDescription.label}</span>
                                        {criterionDescription.description &&
                                          criterionDescription.description.length > 0 && (
                                            <span> ({criterionDescription.description})</span>
                                          )}
                                      </li>
                                    </div>
                                  ),
                              )}
                            </ol>
                          </>
                        )}
                      {criterion.isHidden && criterion.feedbackCriteriaDescriptions && (
                        <FeedbackCriteria
                          feedbackCriteriaDescriptions={criterion.feedbackCriteriaDescriptions}
                        />
                      )}
                    </div>

                    <div className="fm-criterion-controls">
                      <div
                        className="fm-criterion-control-visible"
                        onClick={(event) => toggleCriterion(event, index)}
                      >
                        <FontAwesomeIcon
                          icon={criterion.isHidden ? faChevronRight : faChevronDown}
                        />
                      </div>
                    </div>
                  </div>
                ),
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default withTranslation()(FeedbackCategoryForm);
