import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './styles/import-modal.scss';
import ValidationResultBlock from './validation/ValidationResultBlock';

class ImportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hintData: this.buildHintData(),
    };
  }

  buildHintData() {
    const { expectedRows } = this.props;
    const hintData = [];
    expectedRows.map((expectedRow) => {
      const mockRows = [];
      for (let i = 0; i < 3; i++) {
        mockRows.push(this.buildMockRow(expectedRow, i, expectedRows.length > 1));
      }
      hintData.push(mockRows);
    });
    return hintData;
  }

  buildMockRow(expectedRow, i, isComplexImport) {
    const { t } = this.props;

    const mockData = {};
    mockData.row = {};
    mockData.cellValidationErrors = {};
    mockData.title = expectedRow.typeDescription;
    const expectedRowRestrictions = [];

    if (isComplexImport) {
      mockData.row['#'] = expectedRow.type;
      expectedRowRestrictions.push({
        message: t('validation.type.required', { type: expectedRow.type }),
      });
    }
    const sortedFields = expectedRow.fields.sort((a, b) => (a.columnNum > b.columnNum ? 1 : -1));
    sortedFields.map((field) => {
      mockData.row[field.cellDescription] = this.generateMockValueForType(field.cellType, i);

      const fieldRestrictions = this.describeRules(field);
      if (fieldRestrictions.length > 0) {
        fieldRestrictions.map((res) => expectedRowRestrictions.push(res));
      }
    });
    if (expectedRowRestrictions.length > 0) {
      mockData.cellValidationErrors.ERROR = expectedRowRestrictions;
    }
    mockData.rowNumber = i;
    return mockData;
  }

  describeRules(field) {
    const { t } = this.props;
    const restrictions = [];
    if (field.isMandatory === true) {
      const restriction = {};
      restriction.columnName = field.cellDescription;
      restriction.message = t('validation.mandatory.field', { field: restriction.columnName });
      restrictions.push(restriction);
    }
    if (field.isUnique === true) {
      const restriction = {};
      restriction.columnName = field.cellDescription;
      restriction.message = t('validation.unique.field', { field: restriction.columnName });
      restrictions.push(restriction);
    }
    return restrictions;
  }

  generateMockValueForType(cellType, i) {
    const { t } = this.props;

    i += 1;
    switch (cellType) {
      case 'Integer':
      case 'Long':
        return i;
      case 'Double':
        return `${i}.0`;
      case 'Timestamp':
      case 'LocalDateTime':
        return `0${i}.0${i}.200${i}`;
      case 'Boolean':
        return (i % 2 === 0).toString();
      default:
        return `${t('employees.import.validation.mock.text')} ${i}`;
    }
  }

  render() {
    const { t } = this.props;
    const { hintData } = this.state;
    return (
      <>
        <div className="expected-result-block-header text-center">
          <span>{t('validation.expected.rows.header')}</span>
        </div>
        {hintData.map((mockRows, i) => (
          <ValidationResultBlock
            blockTitle={mockRows[0].title}
            key={i}
            containerClass="-neutral"
            resultRows={mockRows}
          />
        ))}
      </>
    );
  }
}

ImportModal.propTypes = {
  expectedRows: PropTypes.array.isRequired,
};

export default withTranslation()(ImportModal);
