import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import UploadFileContainer from '../inputs/UploadFileContainer';
import UploadDropzone from '../inputs/UploadDropzone';
import ValidationResultContainer from './validation/ValidationResultContainer';
import './styles/import-modal.scss';
import ImportHintContainer from './ImportHintContainer';

class ImportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      validationResult: null,
      expectedRows: null,
    };
  }

  componentDidMount() {
    const { expectedRowsCall, onError } = this.props;
    const me = this;
    expectedRowsCall()
      .then((data) => {
        me.setState({ expectedRows: data });
      })
      .catch((error) => {
        onError(error);
      });
  }

  onFilesAdded = (files) => {
    this.setState({ files });
  };

  removeFile = (name) => {
    const { files } = this.state;
    this.setState({
      files: files.filter((f) => f.name !== name),
      validationResult: null,
    });
  };

  executeForEachFile = (execute, onComplete, onError = this.props.onError) => {
    const reader = new FileReader();
    const { files } = this.state;
    _.forEach(files, (file) => {
      reader.onload = function (readerEvt) {
        const data = {
          base64: btoa(readerEvt.target.result.toString()),
          filename: file.name,
        };
        execute(data)
          .then((result) => {
            onComplete(result);
          })
          .catch((error) => {
            onError(error);
          });
      };
      reader.readAsBinaryString(file);
    });
  };

  performImport = () => {
    const { performImport, afterImport } = this.props;
    this.executeForEachFile(performImport, afterImport);
  };

  performValidation = () => {
    const { performImportFileValidation } = this.props;
    const me = this;
    this.executeForEachFile(performImportFileValidation, (data) => {
      me.setState({
        validationResult: data,
      });
    });
  };

  isValidationFailed(validationResult) {
    return (
      validationResult &&
      (validationResult.result === 'FATAL' || validationResult.result === 'ERROR')
    );
  }

  render() {
    const { files, validationResult, expectedRows } = this.state;
    const { t, cancel, performImportFileValidation, hintDisabled } = this.props;
    const fileUploaded = files && files.length > 0;
    return (
      <>
        <div className="modal-body">
          <div className="import-file-loader">
            {fileUploaded ? (
              <UploadFileContainer files={files} removeFile={this.removeFile} />
            ) : (
              <UploadDropzone
                accept=".xls, .xlsx"
                name="importFile"
                cssClass="fm-square-dropzone"
                multiple={false}
                onFilesAdded={this.onFilesAdded}
              />
            )}
          </div>
          {!hintDisabled && expectedRows && !validationResult && (
            <ImportHintContainer expectedRows={expectedRows} />
          )}
          {validationResult && <ValidationResultContainer validationResult={validationResult} />}
        </div>
        <div className="modal-footer">
          <div className="modal-buttons-container">
            <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
              {t('buttons.cancel')}
            </button>
            {!performImportFileValidation || validationResult ? (
              <button
                type="submit"
                onClick={this.performImport}
                disabled={!fileUploaded || this.isValidationFailed(validationResult)}
                className="btn fm-btn-primary"
              >
                {t('buttons.import')}
              </button>
            ) : (
              <button
                type="submit"
                onClick={this.performValidation}
                className="btn fm-btn-primary"
                disabled={!fileUploaded}
              >
                {t('buttons.validate')}
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
}

ImportModal.propTypes = {
  performImport: PropTypes.func.isRequired,
  afterImport: PropTypes.func.isRequired,
  expectedRowsCall: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  performImportFileValidation: PropTypes.func,
  hintDisabled: PropTypes.bool,
};

export default withTranslation()(ImportModal);
