import dataProvider from '../dataProvider';

import { feedbackcriteria } from '../../constants/endpoints';

export const getFeedbackCriteriaList = (params) =>
  dataProvider({
    url: feedbackcriteria.all(),
    params,
    method: 'GET',
  });

export const getFeedbackCriteria = (feedbackCriteriaId) =>
  dataProvider({
    url: feedbackcriteria.getByFeedbackId(feedbackCriteriaId),
    method: 'GET',
  });
export const updateFeedbackCriteries = (params) =>
  dataProvider({
    url: feedbackcriteria.createBatch(),
    data: params,
    method: 'POST',
  });

export const createFeedbackCriteria = (params) =>
  dataProvider({
    url: feedbackcriteria.create(),
    data: params,
    method: 'POST',
  });

export const updateFeedbackCriteria = (params) =>
  dataProvider({
    url: feedbackcriteria.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteFeedbackCriteria = (feedbackCriteriaId) =>
  dataProvider({
    url: feedbackcriteria.delete(feedbackCriteriaId),
    method: 'DELETE',
  });

export const getFeedbackCriteriaByFeedbackId = (feedbackId) =>
  dataProvider({
    url: feedbackcriteria.getByFeedbackId(feedbackId),
    method: 'GET',
  });

export const getFeedbackCriteriaIdsByFeedbackId = (feedbackId) =>
  dataProvider({
    url: feedbackcriteria.getIdsByFeedbackId(feedbackId),
    method: 'GET',
  });
