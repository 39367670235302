import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { faPlus, faSitemap } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import {
  getOrgUnit,
  getOrgUnitEmployeesListPagination,
  updateOrgUnit,
} from '../../services/orgunit/orgUnitProvider';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import EditOrgUnitForm from '../../components/orgunit/EditOrgUnitForm';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';
import OrgUnitMembersForm from '../../components/orgunit/OrgUnitMembersForm';
import Modal from '../../components/modal/Modal';
import AddEmployeeToOrgUnitModal from '../../components/orgunit/AddEmployeeToOrgUnitModal';
import { addEmployeesToOrgUnit } from '../../services/employee/employeeProvider';

class EditOrgUnitPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalShown: false,
      isLoading: false,
      tableRef: null,
      orgUnit: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_organization_units'),
        ref: '/org/org-unit',
        isActive: false,
      },
      {
        name: t('breadcrumbs.organization_unit'),
        ref: null,
        isActive: true,
      },
    ];
  };

  loadData(organizationUnitId, callback) {
    this.setState({ isLoading: true });
    getOrgUnit(organizationUnitId)
      .then((orgUnit) => {
        this.setState(
          {
            isLoading: false,
            orgUnit,
          },
          callback,
        );
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  componentDidMount() {
    const { params } = this.props.match;
    this.loadData(params.organizationUnitId);
  }

  componentDidUpdate(props, prevState, test) {
    if (
      this.props.match &&
      props.match &&
      !_.isEqual(
        _.get(props, 'match.params.organizationUnitId'),
        _.get(this.props, 'match.params.organizationUnitId'),
      )
    ) {
      this.loadData(this.props.match.params.organizationUnitId);
      this.state.tableRef.updateTableData(false);
    }
  }

  updateOrgUnit = (data) => {
    const { t } = this.props;
    updateOrgUnit(data)
      .then((updatedOrgId) => {
        this.loadData(updatedOrgId);
        Notifications.success(t('messages.organization_unit_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  fetchOrgUnitEmployeeList = (amount, skip, sorting, filters, callback) => {
    getOrgUnitEmployeesListPagination(
      {
        amount,
        skip,
        sortings: sorting,
        filters,
      },
      this.props.match.params.organizationUnitId,
    )
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  openAddUserRoleModal = () => {
    this.setState({ isModalShown: true });
  };

  closeModal = () => {
    this.setState({ isModalShown: false });
  };

  addEmployeesToOrgUnit = (values) => {
    const { t } = this.props;
    const { orgUnit } = this.state;
    const idList = values && values.users ? values.users.map((v) => v.value) : [];

    addEmployeesToOrgUnit(orgUnit.id, {
      userIds: idList,
      hireDate: values.hireDate,
    })
      .then(() => {
        this.state.tableRef.updateTableData(false);
        this.closeModal();
        Notifications.success(t('component.employee_added_to_org_unit'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, orgUnit, tableRef, isModalShown } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-6">
            <PageNameContainer
              name={t('containers.organization_unit')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content row">
          <div className="col-lg-6">
            <div className="ibox">
              <FormTitle name={t('components.organization_unit')} />
              <div className="ibox-content clearfix">
                {orgUnit && (
                  <EditOrgUnitForm orgUnit={orgUnit} updateOrgUnit={this.updateOrgUnit} />
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="ibox">
              <FormTitle
                name={t('components.members')}
                buttons={[
                  {
                    size: 'btn-xs',
                    importance: 'fm-btn-primary',
                    onClick: this.openAddUserRoleModal,
                    name: t('components.add'),
                    icon: faPlus,
                    securityModule: 'employees',
                    securityPermission: 'create',
                  },
                ]}
              />
              {orgUnit && (
                <OrgUnitMembersForm
                  setTableReference={this.setTableReference}
                  tableReference={tableRef}
                  fetchOrgUnitEmployeeList={this.fetchOrgUnitEmployeeList}
                />
              )}
            </div>
          </div>
          <Modal
            header={t('components.add_user')}
            isOpened={isModalShown}
            icon={faSitemap}
            onClose={this.closeModal}
          >
            {orgUnit && (
              <AddEmployeeToOrgUnitModal
                orgUnitId={orgUnit.id}
                addEmployeesToOrgUnit={this.addEmployeesToOrgUnit}
                cancel={this.closeModal}
              />
            )}
          </Modal>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

EditOrgUnitPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(EditOrgUnitPage);
