import dataProvider from '../dataProvider';
import { questionanswerresult } from '../../constants/endpoints';

export const getQuestionAnswerResultList = (params) =>
  dataProvider({
    url: questionanswerresult.all(),
    params,
    method: 'GET',
  });

export const getQuestionAnswerResult = (orgId) =>
  dataProvider({
    url: questionanswerresult.getById(orgId),
    method: 'GET',
  });

export const createQuestionAnswerResult = (values) =>
  dataProvider({
    url: questionanswerresult.create(),
    method: 'POST',
    data: values,
  });

export const updateQuestionAnswerResult = (params) =>
  dataProvider({
    url: questionanswerresult.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteQuestionAnswerResult = (orgunitId) =>
  dataProvider({
    url: questionanswerresult.delete(orgunitId),
    method: 'DELETE',
  });

export const getQuestionAnswerResultSource = (params) =>
  dataProvider({
    url: questionanswerresult.itemSource(),
    data: params,
    method: 'POST',
  });

export const getQuestionAnswerResultByQuestion = (questionId) =>
  dataProvider({
    url: questionanswerresult.getByQuestion(questionId),
    method: 'GET',
  });

export const getQuestionAnswerResultByAssignment = (assignmentId) =>
  dataProvider({
    url: questionanswerresult.getByAssignment(assignmentId),
    method: 'GET',
  });

export const getQuestionAnswerResultByAssignmentQuestion = (assignmentId, questionId) =>
  dataProvider({
    url: questionanswerresult.getByAssignmentQuestion(assignmentId, questionId),
    method: 'GET',
  });

export const getQuestionAnswerResultByAssignmentQuestionAnswer = (
  assignmentId,
  questionId,
  answerId,
) =>
  dataProvider({
    url: questionanswerresult.getByAssignmentQuestionAnswer(assignmentId, questionId, answerId),
    method: 'GET',
  });

export const getQuestionAnswerResultByAnswer = (answerId) =>
  dataProvider({
    url: questionanswerresult.getByAnswer(answerId),
    method: 'GET',
  });

export const batchUpdate = (data) =>
  dataProvider({
    url: questionanswerresult.batchUpdate(),
    method: 'POST',
    data,
  });

export const answerQuestion = (data) =>
  dataProvider({
    url: questionanswerresult.answerQuestion(),
    method: 'POST',
    data,
  });
