import dataProvider from '../dataProvider';

import { feedbackcriteriaanswer } from '../../constants/endpoints';

export const getFeedbackCriteriaAnswerList = (params) =>
  dataProvider({
    url: feedbackcriteriaanswer.all(),
    params,
    method: 'GET',
  });

export const getFeedbackCriteriaAnswer = (feedbackCriteriaId) =>
  dataProvider({
    url: feedbackcriteriaanswer.getByFeedbackId(feedbackCriteriaId),
    method: 'GET',
  });
export const updateFeedbackCriterieAnswers = (params) =>
  dataProvider({
    url: feedbackcriteriaanswer.createBatch(),
    data: params,
    method: 'POST',
  });

export const createFeedbackCriteriaAnswer = (params) =>
  dataProvider({
    url: feedbackcriteriaanswer.create(),
    data: params,
    method: 'POST',
  });

export const updateFeedbackCriteriaAnswer = (params) =>
  dataProvider({
    url: feedbackcriteriaanswer.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteFeedbackCriteriaAnswer = (feedbackCriteriaId) =>
  dataProvider({
    url: feedbackcriteriaanswer.delete(feedbackCriteriaId),
    method: 'DELETE',
  });

export const getFeedbackCriteriaAnswersByReviewerId = (reviewerId) =>
  dataProvider({
    url: feedbackcriteriaanswer.getFeedbackCriteriaAnswersByReviewerId(reviewerId),
    method: 'GET',
  });

export const getAnswersByFeedbackAssignmentIdUserId = (feedbackAssignmentId, userId) =>
  dataProvider({
    url: feedbackcriteriaanswer.getAnswersByFeedbackAssignmentIdUserId(
      feedbackAssignmentId,
      userId,
    ),
    method: 'GET',
  });
