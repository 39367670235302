import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import {
  getActivityStatus,
  getEmployeeJob,
  updateEmployeeJob,
} from '../../services/employeejob/employeeJobProvider';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import EditEmployeeJobForm from '../../components/employeejob/EditEmployeeJobForm';
import PageNameContainer from '../../components/page/PageNameContainer';

class EditEmployeeJobPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      employeeJob: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_jobs'),
        ref: '/org/employee-jobs',
        isActive: false,
      },
      {
        name: t('breadcrumbs.job'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { params } = this.props.match;
    this.setState({ isLoading: true });

    const promises = [];

    promises.push(getEmployeeJob(params.employeeJobId));
    promises.push(getActivityStatus());

    Promise.all(promises)
      .then(([employeeJob, statuses]) => {
        this.setState({
          employeeJob,
          isLoading: false,
          statusOptions: statuses,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  updateEmployeeJob = (data) => {
    const { t } = this.props;

    this.setState({ isLoading: true });
    updateEmployeeJob(data)
      .then((_data) => {
        getEmployeeJob(data.id).then((updatedEmployeeJob) => {
          this.setState({
            employeeJob: updatedEmployeeJob,
            isLoading: false,
          });
        });
        Notifications.success(t('message.job_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, employeeJob, statusOptions } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer name={t('containers.job')} breadcrumbs={this.getBreadcrumbs()} />
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-6">
              {employeeJob && employeeJob.id && (
                <EditEmployeeJobForm
                  employeeJob={employeeJob}
                  statusOptions={statusOptions}
                  updateEmployeeJob={this.updateEmployeeJob}
                />
              )}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

EditEmployeeJobPage.propTypes = {
  employeeJob: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
};

export default withTranslation()(EditEmployeeJobPage);
