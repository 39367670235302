import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { faFileImage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { Trans, withTranslation } from 'react-i18next';
import classnames from 'classnames';
import SwapVertRoundedIcon from '@material-ui/icons/SwapVertRounded';
import ObjectUtil from '../../../utils/ObjectUtil';
import MaterialHtmlTooltip from '../../ui-elements/MaterialHtmlTooltip';
import UrlUtil from '../../../utils/UrlUtil';

function InnerForm(props) {
  const { t, survey, scales, questions } = props;
  const {
    testImageExtension,
    testQuestionClassification,
    testImage,
    title,
    testType,
    editable,
    description,
  } = survey;

  useEffect(() => {
    props.setForm('summary', null);
    _.forEach(questions, (q) => {
      q.isHidden = true;
    });
  }, []);

  const [surveyShow, setSurveyShow] = useState(true);
  const [questionsShow, setQuestionsShow] = useState(true);
  const [scalesShow, setScalesShow] = useState(true);

  const [render, setRender] = useState(true);

  const toggleQuestion = (event, index) => {
    questions[index].isHidden = !questions[index].isHidden;
    setRender(!render);
  };

  const toggleScale = (event, index) => {
    scales[index].isHidden = !scales[index].isHidden;
    setRender(!render);
  };
  const testImageLink = survey ? survey.testImageLink : null;
  let hasActiveQuestions = false;
  let hasActiveScales = false;

  _.forEach(questions, (q) => {
    if (q.action !== 'delete') {
      hasActiveQuestions = true;
      return false;
    }
  });

  const allowedTags = survey.testQuestionClassification
    ? survey.testQuestionClassification.map((t) => t.name)
    : [];

  _.forEach(questions, (q, index) => {
    if (q.questionClassifications && q.questionClassifications.length > 0) {
      const oldLength = q.questionClassifications.length;
      q.questionClassifications = q.questionClassifications.filter((t) =>
        allowedTags.includes(t.name),
      );
      if (oldLength !== q.questionClassifications.length) {
        q.action = 'update';
      }
    }
  });
  _.forEach(scales, (s, index) => {
    if (s.questionClassification && !allowedTags.includes(s.questionClassification.name)) {
      s.questionClassification = null;
      s.action = 'update';
    }
  });

  _.forEach(scales, (s) => {
    if (s.action !== 'delete') {
      hasActiveScales = true;
      return false;
    }
  });

  return (
    <div className="fm-survey-summary">
      <div className="fm-wizard-element-title-container">
        <div className="fm-wizard-element-title">{t('components.summary')}</div>
      </div>
      <div
        className="fm-wizard-element-title-container fm-survey-summary-section-header"
        onClick={() => setSurveyShow(!surveyShow)}
      >
        <div className="fm-wizard-element-title">{t('components.common_information')}</div>
        <div className="fm-survey-summary-controls">
          <FontAwesomeIcon icon={surveyShow ? faChevronRight : faChevronDown} />
        </div>
      </div>
      {surveyShow && (
        <div className="ibox-content clearfix fm-wizard-element-content-box fm-survey-summary-section-body">
          <div className="fm-common-info-survey">
            <div className="fm-main-info-row">
              <div className="fm-survey-image-part">
                <div className="fm-image-container fm-image-active">
                  <div className="fm-test-image-box">
                    <div className="img-responsive fm-test-img">
                      {testImage && testImageExtension ? (
                        <img
                          src={`data:image/${testImageExtension};base64,${testImage}`}
                          alt={t('components.survey_image')}
                        />
                      ) : testImageLink ? (
                        <img
                          src={UrlUtil.buildImageSrcLink(testImageLink)}
                          alt={t('components.survey_image')}
                        />
                      ) : (
                        <FontAwesomeIcon icon={faFileImage} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="fm-survey-info-part">
                <div className="fm-row-fields">
                  <span className="fm-survey-label">{t('components.survey_name')}</span>
                  <span className="fm-survey-value">{title}</span>
                </div>
                <div className="fm-row-fields">
                  <span className="fm-survey-label">{t('components.type')}</span>
                  <span className="fm-survey-value">{testType ? testType.label : ''}</span>
                </div>
                <div className="fm-row-fields">
                  <span className="fm-survey-label">{t('components.editable')}</span>
                  <span className="fm-survey-value">{editable ? 'Yes' : 'No'}</span>
                </div>
                <div className="fm-row-fields">
                  <span className="fm-survey-label">{t('components.description')}</span>
                  <span className="fm-survey-value">{description}</span>
                </div>
                <div className="fm-row-fields">
                  <span className="fm-survey-label">{t('components.tags')}</span>
                  <span className="fm-survey-value">
                    {testQuestionClassification &&
                      testQuestionClassification.map((tag, tagIndex) => (
                        <div key={tagIndex} className="fm-cl-tag">
                          <span className="fm-badge" style={{ backgroundColor: tag.color }}>
                            {tag.name}
                          </span>
                        </div>
                      ))}
                  </span>
                </div>
              </div>
            </div>
            <div className="fm-additional-info-row" />
          </div>
        </div>
      )}
      <div
        className="fm-wizard-element-title-container fm-survey-summary-section-header"
        onClick={() => setQuestionsShow(!questionsShow)}
      >
        <div className="fm-wizard-element-title">{t('components.questions')}</div>
        <div className="fm-survey-summary-controls">
          <FontAwesomeIcon icon={questionsShow ? faChevronRight : faChevronDown} />
        </div>
      </div>
      {questionsShow && (
        <div className="ibox-content clearfix fm-wizard-element-content-box fm-survey-summary-section-body">
          <div className="fm-survey-questions">
            {questions &&
              questions.map(
                (question, index) =>
                  question.action !== 'delete' && (
                    <div
                      key={`questions[${index}].questionKeyId`}
                      className={classnames('fm-question-container', {
                        'fm-question-expanded': !question.isHidden,
                      })}
                    >
                      <div className="fm-question-info">
                        <div className="fm-question-text-box">
                          {question.questionClassifications &&
                            question.questionClassifications.map((tag, tagIndex) => (
                              <div key={tagIndex} className="fm-cl-tag">
                                <span className="fm-badge" style={{ backgroundColor: tag.color }}>
                                  {tag.name}
                                </span>
                              </div>
                            ))}
                          {question && question.question && question.question.length > 0 ? (
                            <div className="fm-question-text">{question.question}</div>
                          ) : (
                            <div className="alert alert-warning fm-question-text">
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                              {t('components.empty')}
                            </div>
                          )}
                        </div>
                        {!question.isHidden && question.questionType && (
                          <div className="fm-row-fields">
                            <span className="fm-survey-label">{t('components.question_type')}</span>
                            <span className="fm-survey-value">{question.questionType.value} </span>
                            {question.questionType.value === 'Pulse' && (
                              <span className="fm-survey-value">
                                {`(${question.answerRangeFrom} - ${question.answerRangeTo})`}
                              </span>
                            )}
                          </div>
                        )}
                        {!question.isHidden && question.answers && question.answers.length > 0 && (
                          <div className="fm-row-fields">
                            <span className="fm-survey-label">{t('components.answers')}</span>
                            <ol className="fm-survey-summary-answers-list">
                              {question.answers.map(
                                (answer, aIndex) =>
                                  answer.action !== 'delete' && (
                                    <li
                                      className="fm-survey-summary-answer"
                                      key={`${index}-answer-${aIndex}`}
                                    >
                                      <span className="">{answer.answer}</span>
                                      {!isNaN(answer.rate) && (
                                        <span className="fm-survey-summary-rate">
                                          &nbsp;({answer.rate})
                                        </span>
                                      )}
                                    </li>
                                  ),
                              )}
                            </ol>
                          </div>
                        )}
                      </div>

                      <div className="fm-question-controls">
                        <div
                          className="fm-question-control-visible"
                          onClick={(event) => toggleQuestion(event, index)}
                        >
                          <FontAwesomeIcon
                            icon={question.isHidden ? faChevronRight : faChevronDown}
                          />
                        </div>
                      </div>
                    </div>
                  ),
              )}
            {!hasActiveQuestions && (
              <p className="text-muted text-center">{t('message.no_questions_present')}</p>
            )}
          </div>
        </div>
      )}

      <div
        className="fm-wizard-element-title-container fm-survey-summary-section-header"
        onClick={() => setScalesShow(!scalesShow)}
      >
        <div className="fm-wizard-element-title">{t('components.scales')}</div>
        <div className="fm-survey-summary-controls">
          <FontAwesomeIcon icon={scalesShow ? faChevronRight : faChevronDown} />
        </div>
      </div>
      {scalesShow && (
        <div className="ibox-content clearfix fm-wizard-element-content-box fm-survey-summary-section-body">
          <div className="fm-survey-scales">
            {scales.map((scale, sIndex) => {
              const scaleTag = testQuestionClassification
                ? testQuestionClassification.find((tag) => {
                    if (!_.has(scale, 'questionClassification')) {
                      return false;
                    }
                    if (isNaN(scale.questionClassification.value) && isNaN(tag.value)) {
                      return (
                        scale.questionClassification.value === tag.value &&
                        scale.questionClassification.label === tag.label
                      );
                    }
                    return (
                      parseInt(scale.questionClassification.value) === parseInt(tag.value) &&
                      scale.questionClassification.label === tag.label
                    );
                  })
                : null;

              const isReverseScaling = scaleTag != null ? scaleTag.isReverseScaling : false;

              return (
                scale.action !== 'delete' && (
                  <div
                    key={`scales[${sIndex}].scaleKeyId`}
                    className={classnames('fm-scale-container', {
                      'fm-scale-expanded': !scale.isHidden,
                    })}
                  >
                    <div className="fm-scale-info">
                      <div className="fm-scale-text-box">
                        {scale.questionClassification && (
                          <div className="fm-cl-tag">
                            <span
                              className="fm-badge"
                              style={{ backgroundColor: scale.questionClassification.color }}
                            >
                              {scale.questionClassification.name}
                              {isReverseScaling && (
                                <MaterialHtmlTooltip
                                  title={
                                    <Trans t={t} i18nKey="fields.reverse_scaling_clarification" />
                                  }
                                  arrow
                                >
                                  <span className="fm-heatmap-additional-info">
                                    <SwapVertRoundedIcon />
                                  </span>
                                </MaterialHtmlTooltip>
                              )}
                            </span>
                          </div>
                        )}
                        {scale && scale.label && scale.label.length > 0 ? (
                          <>
                            <div className="fm-color-square-wrapper">
                              <div
                                className="fm-color-square"
                                style={{
                                  background: scale.color,
                                  border: scale.color ? 'none' : `1px solid #E9EBF0`,
                                }}
                              />
                            </div>
                            <div className="fm-scale-text">
                              {`${scale.label} (${
                                ObjectUtil.exists(scale.rangeFrom) ? scale.rangeFrom : 'N/A'
                              } - ${ObjectUtil.exists(scale.rangeTo) ? scale.rangeTo : 'N/A'})`}
                            </div>
                          </>
                        ) : (
                          <div className="alert alert-warning fm-scale-text">
                            <FontAwesomeIcon icon={faExclamationTriangle} /> {t('components.empty')}
                          </div>
                        )}
                      </div>
                      {!scale.isHidden && (
                        <>
                          {scale.description && (
                            <div className="fm-row-fields">
                              <span className="fm-survey-label">
                                {t('fields.description_for_admin')}
                              </span>
                              <span className="fm-survey-value">{scale.description}</span>
                            </div>
                          )}
                          {scale.descriptionForUser && (
                            <div className="fm-row-fields">
                              <span className="fm-survey-label">
                                {t('fields.description_for_user')}
                              </span>
                              <span className="fm-survey-value">{scale.descriptionForUser}</span>
                            </div>
                          )}
                          {scale.recommendation && (
                            <div className="fm-row-fields">
                              <span className="fm-survey-label">{t('fields.recommendation')}</span>
                              <span className="fm-survey-value">{scale.recommendation}</span>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="fm-question-controls">
                      <div
                        className="fm-question-control-visible"
                        onClick={(event) => toggleScale(event, sIndex)}
                      >
                        <FontAwesomeIcon icon={scale.isHidden ? faChevronRight : faChevronDown} />
                      </div>
                    </div>
                  </div>
                )
              );
            })}
            {!hasActiveScales && (
              <p className="text-muted text-center">{t('message.no_scales_present')}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

InnerForm.propTypes = {};

export default withTranslation()(InnerForm);
