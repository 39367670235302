import dataProvider from '../dataProvider';

import { feedbackassignmentperiod } from '../../constants/endpoints';

export const getFeedbackAssignmentPeriodList = (params) =>
  dataProvider({
    url: feedbackassignmentperiod.all(),
    params,
    method: 'GET',
  });

export const getFeedbackAssignmentPeriod = (feedbackAssignmentPeriodId) =>
  dataProvider({
    url: feedbackassignmentperiod.getById(feedbackAssignmentPeriodId),
    method: 'GET',
  });

export const createFeedbackAssignmentPeriod = (params) =>
  dataProvider({
    url: feedbackassignmentperiod.create(),
    data: params,
    method: 'POST',
  });

export const updateFeedbackAssignmentPeriod = (params) =>
  dataProvider({
    url: feedbackassignmentperiod.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteFeedbackAssignmentPeriod = (feedbackAssignmentPeriodId) =>
  dataProvider({
    url: feedbackassignmentperiod.delete(feedbackAssignmentPeriodId),
    method: 'DELETE',
  });

export const getFeedbackAssignmentPeriodTypes = (params) =>
  dataProvider({
    url: feedbackassignmentperiod.types(),
    data: params,
    method: 'GET',
  });

export const getFeedbackAssignmentPeriodSelectItemSource = (params) =>
  dataProvider({
    url: feedbackassignmentperiod.itemSource(),
    data: params,
    method: 'POST',
  });

export const getFeedbackAssignmentPeriodPagination = (params) =>
  dataProvider({
    url: feedbackassignmentperiod.pagination(),
    data: params,
    method: 'POST',
  });

export const getExcludeDates = (params) =>
  dataProvider({
    url: feedbackassignmentperiod.getExcludeDates(),
    data: params,
    method: 'POST',
  });
