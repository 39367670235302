import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment/moment';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import FormTitle from '../form/FormTitle';
import Can from '../security/Can';

function InnerForm(props) {
  const { t, assignmentPeriod } = props;
  const testLink = `/tests/${assignmentPeriod.testId}/edit`;
  const targetLink = `/tests/${assignmentPeriod.testId}/edit`;

  const getTestType = () => {
    switch (assignmentPeriod.test.testType) {
      case 'Survey':
        return t('components.survey');
      case 'Test':
        return t('components.test');
      default:
        return t('components.unknown_test_type');
    }
  };

  return (
    <div className="ibox">
      <FormTitle name={t('components.common_information')} />

      <div className="ibox-content clearfix">
        <dl className="row mb-0">
          <div className="col-sm-4 text-sm-right">
            <dt>{getTestType()}</dt>
          </div>
          <div className="col-sm-8 text-sm-left">
            <Can
              module="tests"
              permission="edit"
              yes={() => (
                <NavLink to={testLink}>
                  <dd className="mb-1">{assignmentPeriod.test.displayString}</dd>
                </NavLink>
              )}
              no={() => <dd className="mb-1">{assignmentPeriod.test.displayString}</dd>}
            />
          </div>
        </dl>
        <dl className="row mb-0">
          <div className="col-sm-4 text-sm-right">
            <dt>{t('components.assignment_type')}</dt>
          </div>
          <div className="col-sm-8 text-sm-left">
            <dd className="mb-1">
              {assignmentPeriod.type.label} (<b>{assignmentPeriod.targetDisplayName}</b>)
            </dd>
          </div>
        </dl>
        <dl className="row mb-0">
          <div className="col-sm-4 text-sm-right">
            <dt>{t('components.welcome_text')}</dt>
          </div>
          <div className="col-sm-8 text-sm-left">
            <dd className="mb-1">{assignmentPeriod.welcomeText}</dd>
          </div>
        </dl>
        <dl className="row mb-0">
          <div className="col-sm-4 text-sm-right">
            <dt>{t('components.description')}</dt>
          </div>
          <div className="col-sm-8 text-sm-left">
            <dd className="mb-1">{assignmentPeriod.test.description}</dd>
          </div>
        </dl>
        <dl className="row mb-0">
          <div className="col-sm-4 text-sm-right">
            <dt>{t('components.created')}</dt>
          </div>
          <div className="col-sm-8 text-sm-left">
            <dd className="mb-1">{Moment(assignmentPeriod.created).format('LL')}</dd>
          </div>
        </dl>
        {assignmentPeriod.updated ? (
          <dl className="row mb-0">
            <div className="col-sm-4 text-sm-right">
              <dt>{t('components.updated')}</dt>
            </div>
            <div className="col-sm-8 text-sm-left">
              <dd className="mb-1">{Moment(assignmentPeriod.updated).format('LL')}</dd>
            </div>
          </dl>
        ) : null}
      </div>
    </div>
  );
}

InnerForm.propTypes = {
  testAssignment: PropTypes.object,
  handleSubmit: PropTypes.func,
};

export default withTranslation()(InnerForm);
