import React, { Component } from 'react';

import { faPlus, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  createOrgUnit,
  getByNameLike,
  getOrgUnitListPaginationWithHeadcount,
} from '../../services/orgunit/orgUnitProvider';
import { getOrgUnitDefSelectItemSource } from '../../services/orgunitdef/orgUnitDefProvider';
import AllOrgUnitTable from '../../components/orgunit/AllOrgUnitTable';
import Notifications from '../../utils/Notifications';
import Modal from '../../components/modal/Modal';
import CreateOrgUnitModal from '../../components/orgunit/CreateOrgUnitModal';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';
import { getUserByNameLike } from '../../services/user/userProvider';

class AllOrgUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isModalShown: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_organization_units'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    this.getOrgTypes();
  }

  fetchOrgUnitList = (amount, skip, sorting, filters, callback) => {
    getOrgUnitListPaginationWithHeadcount({
      amount,
      skip,
      sortings: sorting,
      filters,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  getOrgTypes = () => {
    getOrgUnitDefSelectItemSource({
      amount: 100,
      skip: 0,
    })
      .then((data) => {
        this.setState({
          orgUnitTypes: data,
        });
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  openCreateOrgUnitModal = () => {
    this.setState({ isModalShown: true });
  };

  closeModal = () => {
    this.setState({ isModalShown: false });
  };

  createOrganization = (values) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
      isModalShown: false,
    });
    createOrgUnit(values)
      .then((data) => {
        this.state.tableRef.updateTableData(false);
        this.setState({ isLoading: false });
        Notifications.success(t('messages.organization_unit_created', { name: values.name }));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { isLoading, isModalShown, orgUnitTypes, tableRef } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.all_organization_units')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.all_organization_units')}
              buttons={[
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.openCreateOrgUnitModal,
                  name: t('buttons.create'),
                  icon: faPlus,
                  securityModule: 'org-unit',
                  securityPermission: 'create',
                },
              ]}
            />
            <AllOrgUnitTable
              fetchOrgUnitList={this.fetchOrgUnitList}
              setTableReference={this.setTableReference}
              tableReference={tableRef}
            />
          </div>
        </div>
        <Modal
          header={t('components.create_organization')}
          isOpened={isModalShown}
          icon={faSitemap}
          onClose={this.closeModal}
        >
          {orgUnitTypes && (
            <CreateOrgUnitModal
              createOrganization={this.createOrganization}
              cancel={this.closeModal}
              orgUnitTypes={orgUnitTypes}
              getUsers={getUserByNameLike}
              getOrgUnits={getByNameLike}
            />
          )}
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(AllOrgUnits);
