import dataProvider from '../dataProvider';

import { employeejoblevel } from '../../constants/endpoints';

export const getEmployeeJobLevelList = (params) =>
  dataProvider({
    url: employeejoblevel.all(),
    params,
    method: 'GET',
  });

export const getEmployeeJobLevel = (employeeJobLevelId) =>
  dataProvider({
    url: employeejoblevel.getById(employeeJobLevelId),
    method: 'GET',
  });

export const createEmployeeJobLevel = (params) =>
  dataProvider({
    url: employeejoblevel.create(),
    data: params,
    method: 'POST',
  });

export const updateEmployeeJobLevel = (params) =>
  dataProvider({
    url: employeejoblevel.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteEmployeeJobLevel = (employeeJobLevelId) =>
  dataProvider({
    url: employeejoblevel.delete(employeeJobLevelId),
    method: 'DELETE',
  });

export const getEmployeeJobLevelPagination = (params) =>
  dataProvider({
    url: employeejoblevel.pagination(),
    data: params,
    method: 'POST',
  });

export const getEmployeeJobLevelByNameLike = (name) =>
  dataProvider({
    url: employeejoblevel.getByNameLike(name),
    method: 'GET',
  });

export const getActivityStatus = () =>
  dataProvider({
    url: employeejoblevel.getActivityStatus(),
    method: 'GET',
  });

export const getEmployeeJobLevelSelectItemSource = (params) =>
  dataProvider({
    url: employeejoblevel.itemSource(),
    data: params,
    method: 'POST',
  });
