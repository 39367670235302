import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { deleteTest, getTestList } from '../../../services/tests/testProvider';
import PreviewTest from '../../../components/tests/PreviewSurvey';
import Loader from '../../../components/loader/Loader';
import Notifications from '../../../utils/Notifications';
import { AUTH_ROUTES, CREATE } from '../../../constants/routes';
import FormTitle from '../../../components/form/FormTitle';
import PageNameContainer from '../../../components/page/PageNameContainer';

class AllUserTests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      testList: [],
      SweetAlertModal: withReactContent(Swal),
    };
  }

  onClickCreateTest = () => {
    this.props.history.push(`${AUTH_ROUTES.TESTS}${CREATE}`);
  };

  fetchTestList() {
    getTestList({
      amount: 10,
      skip: 0,
    })
      .then((data) => {
        this.setState({
          testList: data,
          isLoading: false,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.fetchTestList();
  }

  confirmDeleteItem = (itemId) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
    });
    deleteTest(itemId)
      .then(() => {
        this.fetchTestList();
        this.setState({
          isLoading: false,
        });
        Notifications.success(t('message.survey_template_deleted'));
        document.activeElement?.blur();
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  onDeleteItem = (itemId) => {
    const { t } = this.props;
    const { SweetAlertModal } = this.state;
    return new Promise(() => {
      SweetAlertModal.fire({
        title: t('message.are_you_sure'),
        showConfirmButton: true,
        showCancelButton: true,
        text: t('message.you_are_about_to_delete_survey_template'),
        confirmButtonColor: '#1ab394',
        confirmButtonText: t('message.yes_delete_it'),
        cancelButtonText: t('buttons.cancel'),
        showCloseButton: true,
        icon: 'warning',
      }).then((data) => {
        if (data.isConfirmed) {
          this.confirmDeleteItem(itemId);
        }
      });
    });
  };

  render() {
    const { testList, isLoading } = this.state;
    const { t } = this.props;
    return (
      <>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer name={t('containers.survey_template')} />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.survey_template', { length: testList.length })}
              buttons={[
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.onClickCreateTest,
                  name: t('buttons.create'),
                  icon: '',
                },
              ]}
            />
            {testList.length ? (
              testList.map((item, index) => (
                <PreviewTest key={index} test={item} deleteItem={this.onDeleteItem} />
              ))
            ) : (
              <div className="ibox-content">
                <p className="text-muted text-center">{t('components.empty_list')}</p>
              </div>
            )}
          </div>
        </div>
        {isLoading && <Loader />}
      </>
    );
  }
}

AllUserTests.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withTranslation()(AllUserTests);
