import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AllOrgUnitDefs from '../../containers/orgunitdef/AllOrgUnitDefs';
import EditOrgUnitDef from '../../containers/orgunitdef/EditOrgUnitDefPage';

import { AUTH_ROUTES, ORG_UNIT_DEF_ID, ORG_UNIT_ID } from '../../constants/routes';

class OrgUnitDefRoutes extends Component {
  render() {
    const { match } = this.props;

    return (
      <div>
        <Route exact path={`${match.url}`} component={AllOrgUnitDefs} />
        <Route
          path={`${match.url}${ORG_UNIT_ID}`}
          render={({ match }) => <OrgUnitDefInnerRouting match={match} />}
        />
      </div>
    );
  }
}

OrgUnitDefRoutes.propTypes = {
  match: PropTypes.object,
};

export default OrgUnitDefRoutes;

class OrgUnitDefInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let orgUnitDefRouting;
    if (match.params.organizationUnitDefId) {
      switch (match.params.organizationUnitDefId) {
        default:
          orgUnitDefRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.ORG_UNIT_DEF.ORG_UNIT}${ORG_UNIT_DEF_ID}`}
              component={AllOrgUnitDefs}
            />
          );
      }
    }

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.ORG.ORG_UNIT_DEF}${ORG_UNIT_DEF_ID}/edit`}
          component={EditOrgUnitDef}
        />
        {orgUnitDefRouting}
      </div>
    );
  }
}

OrgUnitDefInnerRouting.propTypes = {
  match: PropTypes.object,
};
