import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faComments, faStar } from '@fortawesome/free-regular-svg-icons';
import Rate from 'rc-rate';

import AsyncSelectCreatable from '../react-hook-form-inputs/AsyncSelectCreatable';
import MaterialCheckbox from '../react-hook-form-inputs/MaterialCheckbox';
import TextArea from '../react-hook-form-inputs/TextArea';
import UserPhotoUtil from '../../utils/UserPhotoUtil';
import Notifications from '../../utils/Notifications';
import {
  createOngoingFeedback,
  getOngoingFeedbacksByNameLike,
} from '../../services/ongoingfeedbacks/ongoingFeedbackProvider';

import './styles/home.css';

const validationSchema = Yup.object().shape({});

export default function OngoingFeedbackModal({ ongoingFeedbackTarget, account, closeModal }) {
  const { t } = useTranslation();
  const [mode, setMode] = useState('CHOOSE');
  const [recognition, setRecognition] = useState(-1);

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const loadOngoingFeedbackSkills = (query, callback) => {
    getOngoingFeedbacksByNameLike(query)
      .then((data) => {
        callback?.(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const onSubmit = (data) => {
    let ongoingFeedback = {
      tenantId: ongoingFeedbackTarget.tenantId,
      reviewerId: account.userId,
      revieweeId: ongoingFeedbackTarget.user.id,
    };

    if (mode === 'CHOOSE') {
      Notifications.warning(t('message.you_didnt_write_ongoing_feedback'));
      return;
    }

    if (mode === 'CHOOSE') {
      ongoingFeedback = {
        ...ongoingFeedback,
        ongoingFeedbackType: 'Advice',
        isReviewerNameAllowedToSee: data.isReviewerNameAllowedToSee,
        isFeedbackAllowedToAccess: data.isFeedbackAllowedToAccess,
        feedbackSkillComments: [
          {
            tenantId: ongoingFeedbackTarget.tenantId,
            comment: data.advice,
            ongoingFeedbackSkillName: data.skill ? data.skill.label : null,
            ongoingFeedbackSkillId:
              data.skill && !Number.isNaN(parseInt(data.skill.value, 10)) ? data.skill.value : null,
            ongoingFeedbackId: null,
          },
        ],
      };
    }

    if (mode === 'RECOGNITION') {
      ongoingFeedback = {
        ...ongoingFeedback,
        ongoingFeedbackType: 'Recognition',
        recognitionScore: recognition,
      };
    }

    createOngoingFeedback(ongoingFeedback)
      .then(() => {
        Notifications.success(t('message.ongoing_feedback_successfully_created'));
        closeModal();
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const { handleSubmit, control } = form;

  return (
    <form className="fm-ongoing-feedback-body" onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body pt-4">
        {UserPhotoUtil.renderUserPhotoByLink(ongoingFeedbackTarget?.user.profileImageLink)}
        <div className="fm-ongoing-feedback-employee-name">
          {ongoingFeedbackTarget?.user?.displayString}
        </div>
        <div className="fm-ongoing-feedback-employee-job">
          {ongoingFeedbackTarget?.employeeJob?.displayString}
        </div>
        <div className="fm-ongoing-feedback-separator" />
        {mode === 'CHOOSE' && (
          <div className="fm-ongoing-feedback-choice-box">
            <div
              className="fm-ongoing-feedback-choice-wrapper"
              onClick={() => {
                setMode('ADVICE');
              }}
            >
              <div className="fm-ongoing-feedback-advice-circle">
                <FontAwesomeIcon icon={faComments} size="xs" />
              </div>
              <div className="fm-ongoing-feedback-choice-label">{t('components.advice')}</div>
            </div>
            <div
              className="fm-ongoing-feedback-choice-wrapper"
              onClick={() => {
                setMode('RECOGNITION');
              }}
            >
              <div className="fm-ongoing-feedback-recognition-circle">
                <FontAwesomeIcon icon={faStar} size="xs" />
              </div>
              <div className="fm-ongoing-feedback-choice-label">{t('components.recognition')}</div>
            </div>
          </div>
        )}
        {mode === 'ADVICE' && (
          <>
            <div className="fm-ongoing-feedback-advice-box">
              <div
                className="fm-ongoing-feedback-back"
                onClick={() => {
                  setMode('CHOOSE');
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="xs" /> {t('buttons.back')}
              </div>
              <span className="fm-ongoing-feedback-choose-label">
                <FontAwesomeIcon icon={faComments} /> {t('components.advice')}
              </span>
            </div>
            <div className="fm-ongoing-feedback-advice-container">
              <Controller
                control={form.control}
                name="skill"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <AsyncSelectCreatable
                    placeholder={t('fields.search_skill')}
                    form={form}
                    isClearable
                    loadOptions={loadOngoingFeedbackSkills}
                    isMulti={false}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <Controller
                control={control}
                id="advice"
                name="advice"
                defaultValue=""
                render={({ onChange, onBlur, value, name }) => (
                  <TextArea
                    name={name}
                    placeholder={t('fields.advice')}
                    form={form}
                    rows={3}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              <Controller
                id="isReviewerNameAllowedToSee"
                control={control}
                name="isReviewerNameAllowedToSee"
                defaultValue={false}
                render={({ onChange, onBlur, value, name }) => (
                  <MaterialCheckbox
                    name={name}
                    checked={value}
                    label={t('fields.allow_to_see_my_name')}
                    form={form}
                    checkedElementColor="#34B77C"
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    value={value || false}
                  />
                )}
              />
              <Controller
                id="isFeedbackAllowedToAccess"
                control={control}
                name="isFeedbackAllowedToAccess"
                defaultValue={false}
                render={({ onChange, onBlur, value, name }) => (
                  <MaterialCheckbox
                    name={name}
                    checked={value}
                    label={t('fields.allow_to_get_access_to_my_feedback')}
                    form={form}
                    checkedElementColor="#34B77C"
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    value={value || false}
                  />
                )}
              />
            </div>
          </>
        )}
        {mode === 'RECOGNITION' && (
          <>
            <div className="fm-ongoing-feedback-recognition-box">
              <div
                className="fm-ongoing-feedback-back"
                onClick={() => {
                  setMode('CHOOSE');
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="xs" /> {t('buttons.back')}
              </div>
              <span className="fm-ongoing-feedback-choose-label">
                <FontAwesomeIcon icon={faStar} /> {t('components.recognition')}
              </span>
            </div>
            <div className="fm-ongoing-feedback-recognition-container">
              <Rate onChange={setRecognition} />
            </div>
          </>
        )}
      </div>
      <div className="fm-ongoing-feedback-buttons">
        <input
          type="button"
          className="fm-ongoing-feedback-cancel"
          onClick={closeModal}
          value={t('buttons.cancel')}
        />
        <input type="submit" className="fm-ongoing-feedback-send" value={t('buttons.send')} />
      </div>
    </form>
  );
}

OngoingFeedbackModal.propTypes = {
  account: PropTypes.object.isRequired,
  ongoingFeedbackTarget: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};
