import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import {
  getTestAssignment,
  updateTestAssignment,
} from '../../../services/testassignment/testAssignmentProvider';
import Notifications from '../../../utils/Notifications';
import Loader from '../../../components/loader/Loader';
import EditTestAssignmentDetailsForm from '../../../components/testassignment/EditTestAssignmentDetailsForm';
import CommonTestAssignmentInfo from '../../../components/testassignment/CommonTestAssignmentInfo';
import { setTestAssignmentDetails } from '../../../services/testassignment/testAssignmentActions';
import PageNameContainer from '../../../components/page/PageNameContainer';

class EditTestAssignmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    const { dispatchTestAssignmentDetails } = this.props;
    dispatchTestAssignmentDetails({
      id: null,
      tenantId: '',
      created: '',
      updated: '',
      testid: '',
      userid: '',
      status: '',
      description: '',
    });
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_survey_assignments'),
        ref: '/test-assignments/all',
        isActive: false,
      },
      {
        name: t('breadcrumbs.survey_assignment'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { testAssignmentId } = this.props.match.params;
    const { dispatchTestAssignmentDetails } = this.props;
    this.setState({ isLoading: true });

    getTestAssignment(testAssignmentId)
      .then((testAssignment) => {
        dispatchTestAssignmentDetails(testAssignment);
        this.setState({
          isLoading: false,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  updateTestAssignment = (data) => {
    const { t } = this.props;
    const { location } = this.props.history;
    updateTestAssignment(data)
      .then(() => {
        getTestAssignment(location.state.testAssignmentId).then((updatetTestAssignment) => {
          const { dispatchTestAssignmentDetails } = this.props;
          dispatchTestAssignmentDetails(updatetTestAssignment);
        });
        Notifications.success(t('message.survey_assignment_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading } = this.state;
    const { testAssignment, t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.survey_assignment')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-6">
              {testAssignment && testAssignment.id ? (
                <CommonTestAssignmentInfo testAssignment={testAssignment} />
              ) : (
                <div>
                  <div className="ibox-content">
                    <p className="text-muted text-center">
                      {t('message.no_info_about_survey_assignment')}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-6">
              {testAssignment && testAssignment.id ? (
                <EditTestAssignmentDetailsForm
                  testAssignment={testAssignment}
                  updateTestAssignment={this.updateTestAssignment}
                />
              ) : (
                <div>
                  <div className="ibox-content">
                    <p className="text-muted text-center">{t('message.content_loading')}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

EditTestAssignmentPage.propTypes = {
  t: PropTypes.func.isRequired,
  testAssignment: PropTypes.object,
  dispatchTestAssignmentDetails: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        testAssignmentId: PropTypes.number.isRequired,
      }),
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  testAssignment: state.testAssignment.testAssignment,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchTestAssignmentDetails: (testAssignment) => {
    dispatch(setTestAssignmentDetails({ testAssignment }));
  },
});

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditTestAssignmentPage)),
);
