import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import Input from '../react-hook-form-inputs/Input';
import TextArea from '../react-hook-form-inputs/TextArea';

function InnerForm(props) {
  const { t, cancel, getFeedbackCategoryByName, createFeedbackCategory } = props;

  const schema = Yup.lazy((values) =>
    Yup.object().shape({
      name: Yup.string()
        .required(t('message.required_field'))
        .test('checkIfExists', t('message.already_exist'), (value) => {
          if (!value) {
            return null;
          }
          return new Promise((resolve, reject) => {
            getFeedbackCategoryByName(value)
              .then((data) => {
                resolve(!data);
              })
              .catch(() => {
                resolve(false);
              });
          });
        }),
    }),
  );

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    },
  });
  const { handleSubmit } = form;

  const onSubmit = (data) => {
    createFeedbackCategory(data);
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <div className="modal-body">
        <Controller
          control={form.control}
          name="name"
          id="name"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <Input
              name={name}
              placeholder={t('fields.name')}
              label={t('fields.name')}
              form={form}
              type="text"
              maxLength="256"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Controller
          control={form.control}
          name="description"
          id="description"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <TextArea
              name={name}
              placeholder={t('fields.description')}
              label={t('fields.description')}
              form={form}
              type="text"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
        <button type="submit" className="btn fm-btn-primary">
          {t('buttons.create')}
        </button>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  createFeedbackCategory: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  getFeedbackCategoryByName: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
