import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import _ from 'lodash';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './styles/phone.css';

function Input({ name, form: { touched, errors }, classNames, label, ...props }) {
  const error = _.get(errors, name);

  return (
    <FormGroup
      className={classnames('form-element', 'input-component', classNames, {
        'has-error': error && error.message,
      })}
    >
      {label && <ControlLabel htmlFor={name}>{label}</ControlLabel>}
      <PhoneInput
        {...props}
        defaultCountry="us"
        name={name}
        id={name}
        areaCodes={{ kz: ['7', '6'] }}
        isValid={(inputNumber, country, countries) =>
          countries.some(
            (country) =>
              _.startsWith(inputNumber, country.dialCode) ||
              _.startsWith(country.dialCode, inputNumber),
          )
        }
      />
      {error && error.message && (
        <HelpBlock className="error-text animated fadeIn fm-error-req">{error.message}</HelpBlock>
      )}
    </FormGroup>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  classNames: PropTypes.string,
};

export default Input;
