import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import MaterialCheckbox from '../../../react-hook-form-inputs/MaterialCheckbox';
import Input from '../../../react-hook-form-inputs/Input';
import TextArea from '../../../react-hook-form-inputs/TextArea';

function MultipleChoice(props) {
  const { t, testType, qiu, calcSortOrder, isHidden, answersArray } = props;

  const form = useFormContext();
  const { control, register, setValue, watch, getValues } = form;
  const fieldArray = useFieldArray({
    control,
    name: `${qiu}.answers`,
    keyName: 'answerKeyId',
  });
  const { fields, append, remove } = fieldArray;
  const [render, setRender] = useState(false);
  const watchFieldArray = watch(`${qiu}.answers`);
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }));
  useEffect(() => {
    if (answersArray) {
      append(answersArray);
    }
  }, [append]);

  const removeAnswer = (event, _index) => {
    if (controlledFields[_index].action !== 'delete' && controlledFields[_index].id) {
      setValue(`${qiu}.answers[${_index}].action`, 'delete');
      controlledFields[_index].action = 'delete';
    } else {
      remove(_index);
    }
    setRender(!render);
  };

  const hasActiveQuestions = (answers) => {
    if (!answers) {
      return false;
    }
    if (answers.length === 0) {
      return false;
    }
    _.forEach(answers, (q) => {
      if (q && q.action !== 'delete') {
        return true;
      }
    });
    return false;
  };
  const changeAnswer = (event, index, isCorrect) => {
    controlledFields[index] = {
      ...controlledFields[index],
      isCorrect,
      action: controlledFields[index].id ? 'update' : 'create',
    };
    setValue(`${qiu}.answers[${index}]`, controlledFields[index]);
    setRender(!render);
  };
  const addAnswer = (event) => {
    append({
      isCorrect: false,
      answer: '',
      action: 'create',
      rate: 0,
      sortOrder: calcSortOrder(controlledFields),
    });
  };
  return (
    <>
      {controlledFields && !isHidden && hasActiveQuestions(controlledFields) && (
        <div className="fm-question-answers-label">{t('components.answers')}</div>
      )}
      {controlledFields.map((answer, i) => (
        <div
          className={`fm-question-answer-box${
            answer.action === 'delete' ? ' fm-display-none' : ''
          }`}
          key={`${qiu}.answers[${i}].answerKeyId`}
        >
          {testType && testType.value === 'Test'
            ? !isHidden && (
                <Controller
                  control={control}
                  name={`${qiu}.answers[${i}].isCorrect`}
                  defaultValue={false}
                  defaultChecked={false}
                  render={({ onChange, onBlur, value, name }) => {
                    const val =
                      typeof answer.isCorrect === 'string'
                        ? JSON.parse(answer.isCorrect.toLowerCase())
                        : answer.isCorrect;
                    return (
                      <MaterialCheckbox
                        name={name}
                        checked={val}
                        form={form}
                        checkedElementColor="#34B77C"
                        onBlur={onBlur}
                        onChange={(e) => {
                          changeAnswer(e, i, e.target.checked);
                          onChange(e.target.checked);
                        }}
                        classNames={isHidden || answer.action === 'delete' ? 'fm-display-none' : ''}
                        value={val}
                      />
                    );
                  }}
                />
              )
            : null}
          <input
            type="hidden"
            name={`${qiu}.answers[${i}].id`}
            ref={register()}
            defaultValue={answer.id}
          />
          <input
            type="hidden"
            name={`${qiu}.answers[${i}].tenantId`}
            ref={register()}
            defaultValue={answer.tenantId}
          />
          <input
            type="hidden"
            name={`${qiu}.answers[${i}].systemType`}
            ref={register()}
            defaultValue={answer.systemType}
          />
          <input
            type="hidden"
            name={`${qiu}.answers[${i}].action`}
            ref={register()}
            defaultValue={answer.action}
          />
          <input
            type="hidden"
            name={`${qiu}.answers[${i}].createdByUserId`}
            ref={register()}
            defaultValue={answer.createdByUserId}
          />
          <input
            type="hidden"
            name={`${qiu}.answers[${i}].isCorrect`}
            ref={register()}
            defaultValue={answer.isCorrect}
          />
          <input
            type="hidden"
            name={`${qiu}.answers[${i}].questionId`}
            ref={register()}
            defaultValue={answer.questionId}
          />
          <input
            type="hidden"
            name={`${qiu}.answers[${i}].sortOrder`}
            ref={register()}
            defaultValue={answer.sortOrder}
          />
          <Controller
            control={control}
            name={`${qiu}.answers[${i}].answer`}
            defaultValue={answer.answer}
            render={({ onChange, onBlur, value, name }) => (
              <TextArea
                name={name}
                placeholder={`${t('fields.answer')} ${i + 1}`}
                form={form}
                rows={2}
                onBlur={onBlur}
                maxLength="256"
                onChange={(event) => {
                  if (
                    controlledFields[i].action !== 'update' &&
                    controlledFields[i].action !== 'delete'
                  ) {
                    controlledFields[i].action = answer.id ? 'update' : 'create';
                  }
                  onChange(event);
                }}
                value={value}
                classNames={`fm-answer-answer ${
                  isHidden || answer.action === 'delete' ? 'fm-display-none' : ''
                }`}
              />
            )}
          />
          <Controller
            control={control}
            name={`${qiu}.answers[${i}].rate`}
            defaultValue={answer.rate}
            render={({ onChange, onBlur, value, name }) => (
              <Input
                name={name}
                form={form}
                onBlur={onBlur}
                onChange={(event) => {
                  if (
                    controlledFields[i].action !== 'update' &&
                    controlledFields[i].action !== 'delete'
                  ) {
                    controlledFields[i].action = answer.id ? 'update' : 'create';
                  }
                  onChange(event);
                }}
                value={value}
                type="number"
                classNames={`fm-answer-rate ${
                  isHidden || answer.action === 'delete' ? 'fm-display-none' : ''
                }`}
              />
            )}
          />
          {!isHidden && answer.action !== 'delete' && (
            <div className="fm-answer-controls" onClick={(event) => removeAnswer(event, i)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </div>
          )}
        </div>
      ))}
      {!isHidden && (
        <div className="fm-add-answer-container">
          <div className="fm-add-answer-box" onClick={(event) => addAnswer(event)}>
            <div className="fm-add-answer-image">
              <FontAwesomeIcon icon={faPlus} />
            </div>
            <div className="fm-add-answer-label">{t('components.add_answer')}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default withTranslation()(MultipleChoice);
