import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import AsyncSelect from '../react-hook-form-inputs/AsyncSelect';

function InnerForm(props) {
  const schema = Yup.lazy(() => Yup.object().shape({}));

  const { t, loadUserOptions, handleMembersSubmit, cancel, defaultUserOptions } = props;

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = form;
  const onSubmit = (values) => {
    const newMembers = values.users;
    if (newMembers && newMembers.length) {
      handleMembersSubmit(newMembers);
    }
    cancel();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <Controller
            control={form.control}
            name="users"
            defaultValue=""
            render={({ onChange, onBlur }) => (
              <AsyncSelect
                placeholder={t('fields.group_members')}
                label={t('fields.group_members')}
                form={form}
                isClearable
                defaultOptions={defaultUserOptions}
                loadOptions={loadUserOptions}
                isMulti
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          />
        </div>

        <div className="modal-footer">
          <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
            {t('buttons.cancel')}
          </button>
          <button type="submit" className="btn fm-btn-primary">
            {t('buttons.add_group_members')}
          </button>
        </div>
      </form>
    </div>
  );
}

InnerForm.propTypes = {
  loadUserOptions: PropTypes.func.isRequired,
  handleMembersSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
