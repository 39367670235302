import dataProvider from '../dataProvider';

import { assignmentperiod } from '../../constants/endpoints';

export const getAssignmentPeriodList = (params) =>
  dataProvider({
    url: assignmentperiod.all(),
    params,
    method: 'GET',
  });

export const getAssignmentPeriod = (assignmentPeriodId) =>
  dataProvider({
    url: assignmentperiod.getById(assignmentPeriodId),
    method: 'GET',
  });

export const createAssignmentPeriod = (params) =>
  dataProvider({
    url: assignmentperiod.create(),
    data: params,
    method: 'POST',
  });

export const updateAssignmentPeriod = (params) =>
  dataProvider({
    url: assignmentperiod.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteAssignmentPeriod = (assignmentPeriodId) =>
  dataProvider({
    url: assignmentperiod.delete(assignmentPeriodId),
    method: 'DELETE',
  });

export const getAssignmentPeriodTypes = (params) =>
  dataProvider({
    url: assignmentperiod.types(),
    data: params,
    method: 'GET',
  });

export const getAssignmentPeriodSelectItemSource = (params) =>
  dataProvider({
    url: assignmentperiod.itemSource(),
    data: params,
    method: 'POST',
  });

export const getAssignmentPeriodPagination = (params) =>
  dataProvider({
    url: assignmentperiod.pagination(),
    data: params,
    method: 'POST',
  });

export const getExcludeDates = (params) =>
  dataProvider({
    url: assignmentperiod.getExcludeDates(),
    data: params,
    method: 'POST',
  });

export const getSummary = (assignmentPeriodId) =>
  dataProvider({
    url: assignmentperiod.getSummary(assignmentPeriodId),
    method: 'GET',
  });

export const getAssignmentPeriodReviewees = (assignmentId) =>
  dataProvider({
    url: assignmentperiod.getAssignmentPeriodReviewees(assignmentId),
    method: 'GET',
  });

export const getSummaryByUserId = (assignmentPeriodId, userId) =>
  dataProvider({
    url: assignmentperiod.getSummaryByUserId(assignmentPeriodId, userId),
    method: 'GET',
  });
