import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import Input from '../react-hook-form-inputs/Input';
import TextArea from '../react-hook-form-inputs/TextArea';
import { createRequestDemo } from '../../services/requestdemo/requestDemoProvider';
import Notifications from '../../utils/Notifications';

function InnerForm(props) {
  const { t, onClose, requestType } = props;

  const schema = Yup.lazy(() =>
    Yup.object().shape({
      email: Yup.string().required(t('message.email_required')).nullable(),
      firstName: Yup.string().required(t('message.first_name_required')).nullable(),
      lastName: Yup.string().required(t('message.last_name_required')).nullable(),
      companyName: Yup.string().required(t('message.company_required')).nullable(),
    }),
  );

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = form;

  const onSubmit = (values) => {
    values.type = requestType;
    createRequestDemo(values)
      .then((data) => {
        Notifications.success(t('message.demo_request_created'));
        onClose();
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <div className="modal-body">
        <Controller
          control={form.control}
          name="firstName"
          defaultValue=""
          render={({ onChange, onBlur, name, value }) => (
            <Input
              form={form}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              label={t('fields.first_name')}
              placeholder={t('fields.first_name')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="lastName"
          defaultValue=""
          render={({ onChange, onBlur, name, value }) => (
            <Input
              form={form}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              label={t('fields.last_name')}
              placeholder={t('fields.last_name')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="email"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <Input
              form={form}
              value={value}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              label={t('fields.email')}
              placeholder={t('fields.email')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="companyName"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <Input
              form={form}
              value={value}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              label={t('fields.company')}
              placeholder={t('fields.company')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="phoneNumber"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <Input
              form={form}
              value={value}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              label={t('fields.phone')}
              placeholder={t('fields.phone')}
            />
          )}
        />
        <Controller
          control={form.control}
          name="description"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <TextArea
              form={form}
              value={value}
              rows={4}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              placeholder={t('fields.request_demo_description')}
            />
          )}
        />
      </div>

      <div className="modal-footer">
        <button type="submit" className="btn fm-btn-primary">
          {t('buttons.request_demo')}
        </button>
        <button type="button" className="btn" onClick={onClose}>
          {t('buttons.cancel')}
        </button>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  requestType: PropTypes.string,
};

export default withTranslation()(InnerForm);
