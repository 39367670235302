import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import AsyncSelect from '../react-hook-form-inputs/AsyncSelect';
import Notifications from '../../utils/Notifications';
import { getNotAssignedToFeedbackEmployee } from '../../services/employee/employeeProvider';

function InnerForm(props) {
  const schema = Yup.object().shape({});

  const { t, loadReviewerOptions, feedbackAssignmentId, handleReviewersSubmit, cancel } = props;

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const [defaultUsers, setDefaultUsers] = useState([]);

  useEffect(() => {
    getNotAssignedToFeedbackEmployee(feedbackAssignmentId)
      .then((defaultUsers) => {
        setDefaultUsers(defaultUsers);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  const { handleSubmit } = form;
  const onSubmit = (values) => {
    const newReviewers = values.reviewers;
    if (newReviewers && newReviewers.length) {
      handleReviewersSubmit(newReviewers);
    }
    cancel();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="modal-body">
        <Controller
          control={form.control}
          name="reviewers"
          defaultValue=""
          render={({ onChange, onBlur }) => (
            <AsyncSelect
              placeholder={t('fields.reviewers')}
              label={t('fields.reviewers')}
              form={form}
              isClearable
              defaultOptions={defaultUsers}
              loadOptions={loadReviewerOptions}
              isMulti
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
        />
      </div>

      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
        <button type="submit" className="btn fm-btn-primary">
          {t('buttons.add_reviewers')}
        </button>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  loadReviewerOptions: PropTypes.func.isRequired,
  handleReviewersSubmit: PropTypes.func.isRequired,
  feedbackAssignmentId: PropTypes.number.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
