import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import TopMenu from '../../components/menu/TopMenu';
import FooterContainer from '../../components/page/FooterContainer';
import LeftMenu from '../../components/menu/LeftMenu';

function AuthBase(props) {
  const { account } = props;

  const [collapsed, triggerCollapsed] = useState(false);

  return account && account.userId ? (
    <div className="auth-base-page">
      <TopMenu collapsed={collapsed} />
      <div className="fm-page-content">
        <LeftMenu triggerCollapsed={triggerCollapsed} />
        <div id="page-wrapper" className="forms.advanced_plugins">
          {props.children}
        </div>
      </div>
      <FooterContainer />
      <ToastContainer theme="colored" />
    </div>
  ) : null;
}

AuthBase.propTypes = {
  children: PropTypes.any,
  account: PropTypes.object,
};

export default withRouter(
  connect((state) => ({
    account: state.account,
  }))(AuthBase),
);
