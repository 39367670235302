import _ from 'lodash';

const ArrayUtil = {
  /**
   * Returns new array that contains all elements from sourceArray except those which matches to the elements
   * from elementsToRemove array by given attribute.
   *
   * @param {Array} sourceArray - array of objects to be filtered.
   * @param {Array} filterArray - array of objects that is used to filter elements from sourceArray.
   * @param {String} attribute - attribute name that's used to compare elements from sourceArray with elements from filterArray.
   * @returns {Array} Returns the new array that contains not matching elements.
   */
  filterByAttribute: (sourceArray, filterArray, attribute) => {
    const filteredArray = [];
    for (const srcArrIndex in sourceArray) {
      filteredArray.push(sourceArray[srcArrIndex]);
      for (const filterArrIndex in filterArray) {
        if (sourceArray[srcArrIndex][attribute] === filterArray[filterArrIndex][attribute]) {
          filteredArray.pop();
          break;
        }
      }
    }
    return filteredArray;
  },

  /**
   * Compares two arrays.
   *
   * @param {Array} array - an array to compare (can be an array of objects).
   * @param {Array} anotherArray - an array to compare with (can be an array of objects).
   *
   * @returns {boolean} - true if arrays are equal, false otherwise.
   */
  isEqual: (array, anotherArray) => _.isEqual(array.sort(), anotherArray.sort()),
};

export default ArrayUtil;
