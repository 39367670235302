import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/free-regular-svg-icons';
import { faTimes, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import Modal from '../../modal/Modal';
import ImageCropperModal from '../../cropper/ImageCropperModal';
import FileUtil from '../../../utils/FileUtil';
import Input from '../../react-hook-form-inputs/Input';
import Toggle from '../../react-hook-form-inputs/Toggle';
import TextArea from '../../react-hook-form-inputs/TextArea';
import Select from '../../react-hook-form-inputs/Select';
import AsyncSelectCreatable from '../../react-hook-form-inputs/AsyncSelectCreatable';

import '../styles/test-form.css';
import UrlUtil from '../../../utils/UrlUtil';
import { getQuestionClassificationSelectItemSource } from '../../../services/questionclassification/questionClassificationProvider';

function SurveyCommonInformationForm(props) {
  const { t, getQuestionClassification, getQuestionClassificationDefault, survey, surveyTypes } =
    props;

  const { testImage } = survey;
  const { testImageExtension } = survey;

  const [imageExtension, setImageExtension] = useState(testImageExtension);
  const [image, setImage] = useState(testImage);
  const [defaultQuestionClassificationOptions, setDefaultQuestionClassificationOptions] = useState(
    [],
  );
  const [isModalShown, setModalShown] = useState(false);
  const testImageUploaderRef = React.createRef();

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('message.required_field')),
    description: Yup.string().nullable(),
    testType: Yup.mixed().required(t('message.required_field')),
  });

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: survey.title,
      description: survey.description,
      testType: survey.testType || {
        value: 'Survey',
        label: t('components.survey'),
      },
      editable: survey.editable,
      testQuestionClassification: survey.testQuestionClassification,
      testImageExtension: testImageExtension || '',
      testImage: testImage || '',
    },
  });

  useEffect(() => {
    props.setForm('survey', form);
    getQuestionClassificationDefault({ amount: 100, skip: 0 })
      .then((data) => {
        setDefaultQuestionClassificationOptions(data);
      })
      .catch((error) => {
        Notification.error(error);
      });
  }, [getQuestionClassificationSelectItemSource]);

  const { register, control } = form;

  const loadOptions = (query, callback) => {
    getQuestionClassification(query)
      .then((data) => {
        const qc = data.map((v) => ({
          value: v.id,
          label: v.name,
          color: v.color,
        }));
        callback && callback(qc);
      })
      .catch((error) => {
        Notification.error(error);
      });
  };

  const handleTestImageClick = (e) => {
    testImageUploaderRef.current.click();
  };

  const handleTestImageChange = (e) => {
    const { target } = e.nativeEvent;

    const { files } = target;
    const file = files[0];
    if (!files || !file) {
      return;
    }
    const reader = new FileReader();

    reader.onload = function (readerEvt) {
      const binaryString = readerEvt.target.result;
      const fileName = FileUtil.getFileName(target.value);
      setModalShown(true);

      const ext = FileUtil.getFileExtension(fileName);
      const img = btoa(binaryString);

      setImageExtension(ext);
      setImage(img);
    };
    reader.readAsBinaryString(file);
  };

  const closeModal = () => {
    setModalShown(false);
  };

  const handleSubmitModal = (image) => {
    setModalShown(false);
    setImage(image);
    form.setValue('testImageExtension', imageExtension);
    form.setValue('testImage', image);
  };

  const deleteTestImage = () => {
    setImage(null);
    setImageExtension(null);
    form.setValue('testImageExtension', null);
    form.setValue('testImage', null);
  };

  const testImageLink = survey ? survey.testImageLink : null;
  return (
    <>
      <div className="fm-wizard-element-title-container">
        <div className="fm-wizard-element-title">{t('components.common_information')}</div>
      </div>
      <div className="ibox-content clearfix fm-wizard-element-content-box">
        <form className="fm-common-info-survey">
          <div className="fm-main-info-row">
            <div className="fm-survey-image-part">
              <div className="fm-image-container fm-image-active">
                <div className="fm-test-image-box">
                  <div onClick={handleTestImageClick} className="img-responsive fm-test-img">
                    {image && imageExtension ? (
                      <img src={`data:image/${imageExtension};base64,${image}`} alt="Test Image" />
                    ) : testImageLink ? (
                      <img
                        src={UrlUtil.buildImageSrcLink(testImageLink)}
                        alt={t('components.survey_image')}
                      />
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faFileImage} />

                        <div className="fm-image-label">{t('components.upload_image')}</div>
                      </>
                    )}
                  </div>
                </div>
                <input
                  type="file"
                  accept={'image/*'}
                  name="testImage"
                  id="testImage"
                  ref={testImageUploaderRef}
                  style={{ display: 'none' }}
                  onChange={handleTestImageChange}
                />
                {testImageLink && (
                  <div
                    className="fm-delete-btn"
                    title="Delete Test Image"
                    onClick={deleteTestImage}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                )}
              </div>
              <Modal
                header={t('components.crop_image')}
                isOpened={isModalShown}
                icon={faUserAlt}
                onClose={closeModal}
              >
                {image && (
                  <ImageCropperModal
                    extension={imageExtension}
                    image={image}
                    handleSubmit={handleSubmitModal}
                    closeModal={closeModal}
                  />
                )}
              </Modal>
            </div>
            <div className="fm-survey-info-part">
              <Controller
                control={control}
                name="title"
                defaultValue=""
                render={({ onChange, onBlur, value, name }) => (
                  <Input
                    name={name}
                    placeholder={t('fields.survey_name')}
                    label={t('fields.survey_name')}
                    form={form}
                    rows={5}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />

              <div className="fm-row-fields">
                <Controller
                  id="testType"
                  control={control}
                  name="testType"
                  defaultValue=""
                  render={({ onChange, onBlur, value, name }) => (
                    <Select
                      name={name}
                      checked={value}
                      label={t('fields.type')}
                      form={{
                        touched: form.touched,
                        errors: form.errors,
                      }}
                      options={surveyTypes}
                      placeholder={t('fields.select_test_type')}
                      checkedElementColor="#34B77C"
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value || ''}
                    />
                  )}
                />
                <Controller
                  id="editable"
                  control={control}
                  name="editable"
                  defaultValue={false}
                  render={({ onChange, onBlur, value, name }) => (
                    <Toggle
                      name={name}
                      checked={value}
                      label={t('fields.editable')}
                      toggleSize={6}
                      form={{
                        touched: form.touched,
                        errors: form.errors,
                      }}
                      onBlur={onBlur}
                      onChange={(e) => onChange(e.target.checked)}
                      value={value || false}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="fm-additional-info-row">
            <Controller
              control={control}
              name="description"
              defaultValue=""
              render={({ onChange, onBlur, value, name }) => (
                <TextArea
                  name="description"
                  placeholder={t('fields.description')}
                  label={t('fields.description')}
                  form={form}
                  rows={5}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />

            <Controller
              control={control}
              name="testQuestionClassification"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <AsyncSelectCreatable
                  placeholder={t('fields.tags')}
                  label={t('fields.tags')}
                  form={form}
                  defaultOptions={defaultQuestionClassificationOptions}
                  isClearable
                  loadOptions={loadOptions}
                  isMulti
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <input type="hidden" ref={register('testImageExtension')} />
            <input type="hidden" ref={register('testImage')} />
          </div>
        </form>
      </div>
    </>
  );
}

SurveyCommonInformationForm.propTypes = {
  surveyTypes: PropTypes.array,
  getQuestionClassification: PropTypes.func.isRequired,
  getQuestionClassificationDefault: PropTypes.func.isRequired,
};

export default withTranslation()(SurveyCommonInformationForm);
