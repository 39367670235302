import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import '../styles/validation-results.scss';
import ValidationResultBlock from './ValidationResultBlock';

class ValidationResultContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, validationResult } = this.props;
    const isFatal = validationResult.result === 'FATAL';
    const validRows = validationResult.rowResults ? validationResult.rowResults.VALID : null;
    const warnRows = validationResult.rowResults ? validationResult.rowResults.WARN : null;
    const errRows = validationResult.rowResults ? validationResult.rowResults.ERROR : null;
    return (
      <div className="validation-result-block">
        <div className="validation-result-block-header text-center">
          <span>{t('validation.result.header')}</span>
        </div>
        {isFatal && (
          <div className="validation-fatal-block validation-result-container -error">
            <p className="validation-fatal-message">
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <text className="validation-fatal-message-text">{validationResult.message}</text>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </p>
          </div>
        )}
        {validRows && (
          <ValidationResultBlock
            resultRows={validRows}
            containerClass="-valid"
            columnTitlePrefix="validation.result.column."
            isRowIdRequired
            blockTitle={t('validation.result.valid', { count: validRows.length })}
          />
        )}
        {warnRows && (
          <ValidationResultBlock
            resultRows={warnRows}
            containerClass="-warn"
            columnTitlePrefix="validation.result.column."
            isRowIdRequired
            blockTitle={t('validation.result.warn', { count: warnRows.length })}
          />
        )}
        {errRows && (
          <ValidationResultBlock
            resultRows={errRows}
            containerClass="-error"
            columnTitlePrefix="validation.result.column."
            isRowIdRequired
            blockTitle={t('validation.result.error', { count: errRows.length })}
          />
        )}
      </div>
    );
  }
}

ValidationResultContainer.propTypes = {
  validationResult: PropTypes.object,
};

export default withTranslation()(ValidationResultContainer);
