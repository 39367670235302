import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import chroma from 'chroma-js';
import Notifications from '../../../utils/Notifications';
import { getUserResults } from '../../../services/testassignment/testAssignmentProvider';
import ObjectUtil from '../../../utils/ObjectUtil';

function InnerForm(props) {
  const { t, testAssignmentId, type, recommendations } = props;

  const handleScaleDataset = (dataset) => {
    dataset.hoverBorderColor = [];
    dataset.backgroundColor.forEach((color, index) => {
      if (!color) {
        color = ObjectUtil.getRandomColor();
      }
      dataset.backgroundColor[index] = chroma(color).alpha(0.3).css();
      dataset.hoverBorderColor[index] = 'transparent';
    });
    dataset.hoverBackgroundColor.forEach((color, index) => {
      if (!color) {
        color = ObjectUtil.getRandomColor();
      }
      dataset.hoverBackgroundColor[index] = chroma(color).alpha(0.4).css();
    });

    return dataset;
  };

  const handleUserResultDataset = (dataset) => {
    dataset.hoverBorderColor = [];
    dataset.borderColor = [];
    dataset.backgroundColor.forEach((color, index) => {
      if (!color) {
        color = ObjectUtil.getRandomColor();
      }
      dataset.hoverBorderColor[index] = color;
      dataset.backgroundColor[index] =
        color === 'transparent' ? 'transparent' : chroma(color).alpha(0.8).css();
      dataset.borderColor[index] = 'transparent';
    });
    return dataset;
  };

  const buildChartData = (chartData) => ({
    labels: chartData.labels,
    datasets: [
      handleScaleDataset(chartData.datasets[0]),
      handleUserResultDataset(chartData.datasets[1]),
    ],
  });

  const [userResults, setUserResults] = useState(null);
  useEffect(() => {
    getUserResults(testAssignmentId)
      .then((userResults) => {
        const results = [];
        for (const result of userResults) {
          results.push({
            label: result.label,
            description:
              type && type === 'User' ? result.descriptionForUser : result.descriptionForHR,
            tagName: result.tagName,
            data: buildChartData(result.chartData),
          });
        }
        setUserResults(results);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  const options = {
    responsive: true,
    animationEasing: 'easeOutSine',
    plugins: {
      datalabels: {
        formatter: () => null,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (data) => (data[0] ? data[0].label : null),
          label: (data) => {
            if (data.dataset.label && data.dataset.label === 'User Results') {
              return data.formattedValue;
            }
            let value = 0;
            let prevValue = 1;
            for (let i = 0; i <= data.dataIndex; i++) {
              if (i !== 0) {
                prevValue = value + 1;
                value++;
              }
              value += data.dataset.data[i];
            }
            return `${prevValue} - ${value}`;
          },
        },
        filter: (data) => data.label !== '',
        displayColors: false,
      },
    },
  };

  return (
    <div id="fm-user-test-results">
      {userResults &&
        userResults.map((result) => {
          const recommendation = recommendations
            ? recommendations.find((o) => o.key === result.tagName)
            : null;
          return (
            <div className="fm-user-scale-result-container" key={result.tagName}>
              <div className="fm-user-scale-name">{result.tagName}</div>
              <div className="fm-user-scale-result-box">
                <div className="fm-user-scale-bar">
                  <Doughnut data={result.data} options={options} type="bar" />
                </div>
                <div className="fm-user-scale-description">
                  {result.label && <div className="-label">{result.label}</div>}
                  {result.description && <div className="-description">{result.description}</div>}
                  {recommendation && <div className="-label">{t('components.recommendation')}</div>}
                  {recommendation && <div className="-description">{recommendation.value}</div>}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

InnerForm.propTypes = {
  t: PropTypes.func.isRequired,
  testAssignmentId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  recommendations: PropTypes.array.isRequired,
};
export default withTranslation()(InnerForm);
