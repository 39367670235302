import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Radar } from 'react-chartjs-2';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faComments, faUser } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import HelpOutlineRounded from '@mui/icons-material/HelpOutlineRounded';
import {
  getFeedbackAssignmentsListByUserId,
  getFeedbackAssignmentUserSummary,
} from '../../services/feedbackassignment/feedbackAssignmentProvider';
import { getFeedbackCriteriaByFeedbackId } from '../../services/feedbackcriteria/feedbackCriteriaProvider';
import StringIdGenerator from '../../utils/StringIdGenerator';
import Notifications from '../../utils/Notifications';
import FeedbackAssignmentSummaryCommentsModal from './FeedbackAssignmentSummaryCommentsModal';
import Modal from '../modal/Modal';
import NoDataForm from '../form/NoDataForm';
import { AUTH_ROUTES } from '../../constants/routes';
import { getNotAssignedToFeedbackEmployeeByNameLike } from '../../services/employee/employeeProvider';
import AddFeedbackReviewersModal from './AddFeedbackReviewersModal';
import { batchUpdateFeedbackAssignmentReviewers } from '../../services/feedbackassignmentreviewer/feedbackAssignmentReviewerProvider';
import FeedbackSummaryReviewersForm from './FeedbackSummaryReviewersForm';
import ContentLoader from '../loader/ContentLoader';
import MaterialHtmlTooltip from '../ui-elements/MaterialHtmlTooltip';
import EmployeeInfoHeader from '../employee/EmployeeInfoHeader';

class UserFeedbackAssignmentSummaryForm extends Component {
  constructor(props) {
    super(props);

    const colors = [
      'rgba(244,86,78,0.25)',
      'rgba(249,150,10,0.25)',
      'rgba(16,192,249,0.25)',
      'rgba(143,116,84,0.25)',
      'rgba(95,244,78,0.25)',
      'rgba(10,249,173,0.25)',
      'rgba(16,85,249,0.25)',
      'rgba(143,84,139,0.25)',
    ];

    const radarOptions = {
      responsive: true,
      scales: {
        r: {
          grid: {
            circular: true,
          },
          suggestedMax: 5,
          beginAtZero: true,
          ticks: {
            stepSize: 1,
            display: true,
          },
          pointLabels: {
            font: {
              size: 14,
            },
            color: [
              'rgba(244,86,78,1)',
              'rgba(249,150,10,1)',
              'rgba(16,192,249,1)',
              'rgba(143,116,84,1)',
              'rgba(95,244,78,1)',
              'rgba(10,249,173,1)',
              'rgba(16,85,249,1)',
              'rgba(143,84,139,1)',
            ],
          },
        },
      },
      plugins: {
        legend: {
          position: 'bottom',
        },
        tooltip: {
          callbacks: {
            label(context) {
              let label = context.dataset.label || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.r !== null) {
                label += new Intl.NumberFormat('en-US', { maximumFractionDigits: 1 }).format(
                  context.parsed.r,
                );
              }
              return label;
            },
            title: (context) => context[0].label,
          },
        },
      },
    };

    this.state = {
      colors,
      radarOptions,
      isCommentsOpen: false,
    };
  }

  loadReviewersOptions = (query, callback) => {
    getNotAssignedToFeedbackEmployeeByNameLike(this.props.feedbackAssignment.id, query)
      .then((foundOptions) => {
        callback && callback(foundOptions);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  handleHideFeedbackAssignmentOptions = (event) => {
    event.stopPropagation();
    if (
      event.target.classList.contains('fm-fb-assignment-box') ||
      event.target.closest('.fm-fb-assignment-box')
    ) {
      return;
    }
    this.setState({ isFeedbackAssignmentOptionsOpen: false });
  };

  componentDidMount() {
    this.refreshPage();
    document.addEventListener('mousedown', this.handleHideFeedbackAssignmentOptions);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleHideFeedbackAssignmentOptions);
  }

  openCommentsPopup = (comments) => {
    this.setState({
      criteriaComments: comments,
      isCommentsOpen: true,
    });
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.feedbackAssignment, prevProps.feedbackAssignment)) {
      this.refreshPage(false);
    }
  }

  closeModal = () => {
    this.setState({
      criteriaComments: null,
      isCommentsOpen: false,
    });
  };

  closeAddReviewersFormModal = () => {
    this.setState({ isAddReviewersModalShown: false });
  };

  addReviewersSubmit = (reviewers) => {
    const { t, feedbackAssignment } = this.props;

    const reviewersToSubmit = [];
    if (reviewers && reviewers.length > 0) {
      reviewers.forEach((reviewer) => {
        reviewersToSubmit.push({
          object: {
            reviewerId: reviewer.value,
            assignmentStatus: 'New',
            feedbackAssignmentId: feedbackAssignment.id,
            tenantId: feedbackAssignment.id.tenantId,
            isSelfReview: false,
          },
          action: 'create',
        });
      });
      batchUpdateFeedbackAssignmentReviewers(reviewersToSubmit)
        .then(() => {
          this.setState({ isLoading: false });
          this.refreshPage();
          Notifications.success(t('message.feedback_assignment_reviewers_list_updated'));
        })
        .catch((error) => {
          Notifications.error(error);
          this.setState({ isLoading: false });
        });
    }
  };

  refreshPage = () => {
    this.setState({ isLoading: true });

    const { feedbackAssignment } = this.props;
    const charGen = new StringIdGenerator();

    if (!feedbackAssignment) {
      this.setState({ isLoading: false });
      return;
    }
    const today = moment();

    const promises = [];
    promises.push(getFeedbackCriteriaByFeedbackId(feedbackAssignment.feedbackId));
    promises.push(getFeedbackAssignmentsListByUserId(feedbackAssignment.revieweeId));
    if (today.isAfter(moment(feedbackAssignment.deadline))) {
      promises.push(getFeedbackAssignmentUserSummary(feedbackAssignment.id));
    }
    Promise.all(promises)
      .then(([feedbackCriteria, userFeedbackAssignments, dataStats]) => {
        const { radarOptions } = this.state;
        let self = 0;
        let selfCounter = 0;
        let average = 0;
        let previousAverage = 0;
        let averageCounter = 0;
        let previousAverageCounter = 0;
        const labels = [];
        const averageData = [];
        const selfData = [];
        const datasets = [];
        const comments = [];
        const previousAverageData = [];

        if (dataStats) {
          let { min } = dataStats[0];
          let { max } = dataStats[0];
          for (let i = 0; i < dataStats.length; i++) {
            const comment = {
              size: 0,
              selfComment: null,
              managerComment: null,
              comments: [],
            };

            if (min > dataStats[0].min) {
              min = dataStats[0].min;
            }

            if (max < dataStats[0].max) {
              min = dataStats[0].max;
            }

            dataStats[i].letter = charGen.next();
            labels.push(dataStats[i].letter);
            // statistic
            if (dataStats[i].avgScore) {
              average += dataStats[i].avgScore;
              averageCounter++;
              averageData.push(parseFloat(dataStats[i].avgScore.toFixed(1)));
            }
            if (dataStats[i].previousAvgScore) {
              previousAverage += dataStats[i].previousAvgScore;
              previousAverageCounter++;
              previousAverageData.push(parseFloat(dataStats[i].previousAvgScore.toFixed(1)));
            }
            if (dataStats[i].selfScore) {
              self += dataStats[i].selfScore;
              selfCounter++;
              selfData.push(parseFloat(dataStats[i].selfScore.toFixed(1)));
            }
            // comments
            if (dataStats[i].managerComment) {
              comment.size++;
              comment.managerComment = dataStats[i].managerComment;
            }
            if (dataStats[i].selfComment) {
              comment.size++;
              comment.selfComment = dataStats[i].selfComment;
            }

            comments.push(comment);
          }
          if (!min) {
            min = 0;
          }
          if (!max) {
            max = 5;
          }
          radarOptions.scales.r.suggestedMax = max;
          if (min < 0) {
            radarOptions.scales.r.min = min;
            radarOptions.scales.r.beginAtZero = false;
          }
        }

        if (averageData.length > 0) {
          datasets.push({
            label: 'Overall',
            backgroundColor: 'rgba(52,183,124,0.2)',
            borderColor: '#34B77C',
            pointBackgroundColor: '#34B77C',
            pointStyle: 'circle',
            data: averageData,
          });
        }
        if (selfData.length > 0) {
          datasets.push({
            label: 'Self',
            borderDash: [5, 5],
            backgroundColor: 'rgba(249,150,10,0.2)',
            borderColor: '#F9960A',
            pointStyle: 'circle',
            pointBackgroundColor: '#F9960A',
            data: selfData,
          });
        }
        if (previousAverageData.length > 0) {
          datasets.push({
            label: 'Previous Overall',
            pointStyle: 'circle',
            backgroundColor: 'rgba(16,85,249,0.2)',
            borderColor: 'rgba(29,108,238,0.25)',
            pointBackgroundColor: 'rgba(29,108,238,0.25)',
            data: previousAverageData,
          });
        }
        const hasMain = false;
        const hasAdditional = false;

        this.setState({
          summary: dataStats,
          selfAverage: selfCounter > 0 ? (self / selfCounter).toFixed(1) : 'N/A',
          average: averageCounter > 0 ? (average / averageCounter).toFixed(1) : 'N/A',
          radarData: {
            labels,
            datasets,
          },
          comments,
          hasMain,
          hasAdditional,
          criteria: feedbackCriteria,
          userFeedbackAssignments,
          isLoading: false,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  addReviewer = () => {
    this.setState({ isAddReviewersModalShown: true });
  };

  openFeedbackAssignmentSummary = (id) => {
    const { history } = this.props;
    const { FEEDBACK } = AUTH_ROUTES;
    history.push(`${FEEDBACK.USER_PROFILE}`, { feedbackAssignmentId: id });
  };

  render() {
    const {
      isAddReviewersModalShown,
      isFeedbackAssignmentOptionsOpen,
      userFeedbackAssignments,
      summary,
      selfAverage,
      average,
      colors,
      radarOptions,
      radarData,
      comments,
      isCommentsOpen,
      criteriaComments,
      isLoading,
    } = this.state;

    const { t, employee, feedbackAssignment } = this.props;

    let preparedUserFeedbackAssignments = [];
    if (userFeedbackAssignments) {
      preparedUserFeedbackAssignments = userFeedbackAssignments.filter(
        (a) => feedbackAssignment.id !== a.id,
      );
    }

    return (
      <div className="fm-fb-summary">
        {employee && <EmployeeInfoHeader employee={employee} />}
        <div className="fm-fb-summary-results">
          {summary && (
            <>
              <div className="fm-fb-scaled-results">
                <div className="fm-scaled-result-label">{t('components.scaled_results')}</div>
                <div className="fm-scaled-result-chart">
                  <div className="chart-box">
                    {radarData && <Radar options={radarOptions} data={radarData} />}
                  </div>
                </div>
              </div>
              <div className="fm-fb-summary-container">
                <div className="fm-overall-summary-box">
                  <div
                    className={`fm-fb-assignment-box ${
                      preparedUserFeedbackAssignments && preparedUserFeedbackAssignments.length > 0
                        ? 'fb-pointer'
                        : ''
                    }`}
                    onClick={(event) => {
                      if (feedbackAssignment) {
                        event.stopPropagation();
                        this.setState({
                          isFeedbackAssignmentOptionsOpen:
                            !this.state.isFeedbackAssignmentOptionsOpen,
                        });
                      }
                    }}
                  >
                    {feedbackAssignment && (
                      <div>
                        <span>{feedbackAssignment.feedback.name}</span>
                        <div className="fm-fb-assignment-deadline">
                          {moment(feedbackAssignment.deadline).format('LL')}
                        </div>
                      </div>
                    )}
                    {preparedUserFeedbackAssignments &&
                      preparedUserFeedbackAssignments.length > 0 && (
                        <>
                          <FontAwesomeIcon icon={faAngleDown} />
                          {isFeedbackAssignmentOptionsOpen && (
                            <div className="fm-fb-assignments-options">
                              {preparedUserFeedbackAssignments
                                .filter((a) => feedbackAssignment.id !== a.id)
                                .map((a) => (
                                  <div
                                    className="fm-fb-assignment-option"
                                    key={`feedback-assignment-${a.id}`}
                                    onClick={() => this.openFeedbackAssignmentSummary(a.id)}
                                  >
                                    <div>{a.feedbackName}</div>
                                    <div className="fm-fb-assignment-deadline">
                                      {moment(a.deadline).format('LL')}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </>
                      )}
                  </div>
                  <div className="fm-fb-overall-score-box">
                    <div>
                      <div className="fm-fb-overall-score-box-value">{average}</div>
                      <div className="fm-fb-overall-score-box-label">
                        {t('components.overall_score')}
                      </div>
                    </div>
                  </div>
                  <div className="fm-fb-self-score-box">
                    <div>
                      <div className="fm-fb-self-score-box-value">{selfAverage}</div>
                      <div className="fm-fb-self-score-box-label">
                        {t('components.self_average')}
                      </div>
                    </div>
                  </div>
                  <div className="fm-fb-one-2-one-box">
                    <FontAwesomeIcon icon={faComments} />
                    <span>{t('components.create_1_to_1_meeting')}</span>
                  </div>
                </div>
                <div className="fm-criteria-summary-box">
                  <div className="fm-criteria-header">
                    <div className="fm-criteria-summary-criteria">{t('components.criteria')}</div>
                    <div className="fm-criteria-summary-score fm-criteria-summary-overall-score">
                      {t('components.overall')}
                      <MaterialHtmlTooltip title={t('fields.overall_help_description')} arrow>
                        <span className="fm-description-tooltip">
                          <HelpOutlineRounded />
                        </span>
                      </MaterialHtmlTooltip>
                    </div>
                    <div className="fm-criteria-summary-score fm-criteria-summary-self-score">
                      {t('components.self')}
                    </div>
                  </div>
                  {summary &&
                    summary.map((criteria, index) => (
                      <div key={index} className="fm-criteria-row">
                        <div className="fm-cs-criteria-name-box">
                          <div
                            className="fm-cs-criteria-id"
                            style={{ backgroundColor: colors[index % colors.length] }}
                          >
                            <span>{criteria.letter}</span>
                          </div>
                          <div className="fm-cs-criteria-name">{criteria.criteria}</div>
                          {comments && comments[index] && comments[index].size > 0 && (
                            <div
                              className="fm-criteria-comments"
                              onClick={() => {
                                this.openCommentsPopup(comments[index]);
                              }}
                            >
                              <FontAwesomeIcon icon={faComment} />
                            </div>
                          )}
                        </div>
                        <div className="fm-cs-criteria-average">
                          {criteria.avgScore ? criteria.avgScore.toFixed(1) : '-'}
                        </div>
                        <div className="fm-cs-criteria-self">
                          {criteria.selfScore ? criteria.selfScore.toFixed(1) : '-'}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}
          {!summary && !isLoading && (
            <NoDataForm label={t('message.you_will_see_data_when_campaign_ends')} />
          )}
          {!summary && isLoading && <ContentLoader visible />}
        </div>
        {!employee && <p className="text-muted text-center">{t('message.no_data')}</p>}
        {employee && feedbackAssignment && (
          <FeedbackSummaryReviewersForm feedbackAssignment={feedbackAssignment} />
        )}
        {isCommentsOpen && (
          <Modal
            header={t('components.comments')}
            isOpened={isCommentsOpen}
            icon={faComments}
            onClose={this.closeModal}
          >
            <FeedbackAssignmentSummaryCommentsModal
              comments={criteriaComments}
              cancel={this.closeModal}
            />
          </Modal>
        )}
        {isAddReviewersModalShown && (
          <Modal
            header={t('components.add_reviewers')}
            isOpened={isAddReviewersModalShown}
            icon={faUser}
            onClose={this.closeAddReviewersFormModal}
          >
            <AddFeedbackReviewersModal
              feedbackAssignmentId={feedbackAssignment.id}
              loadReviewerOptions={this.loadReviewersOptions}
              handleReviewersSubmit={this.addReviewersSubmit}
              cancel={this.closeAddReviewersFormModal}
            />
          </Modal>
        )}
      </div>
    );
  }
}

UserFeedbackAssignmentSummaryForm.propTypes = {
  feedbackAssignment: PropTypes.object,
  employee: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userId: state.account.userId,
});

export default withTranslation()(
  withRouter(connect(mapStateToProps)(UserFeedbackAssignmentSummaryForm)),
);
