import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { withTranslation } from 'react-i18next';
import EditDeleteActionsRenderer from '../tables/EditDeleteActionsRenderer';

import { deleteOrgUnit } from '../../services/orgunit/orgUnitProvider';

import './styles/orgunit-table.css';
import Notifications from '../../utils/Notifications';
import { AUTH_ROUTES } from '../../constants/routes';
import PaginationTable from '../tables/PaginationTable';
import StringRenderer from '../tables/StringRenderer';
import UserRenderer from '../tables/UserRenderer';

class AllOrgUnitTable extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      columns: [
        {
          Header: t('tables.organization_unit_name'),
          Cell: StringRenderer,
          accessor: 'name',
        },
        {
          Header: t('tables.type'),
          accessor: 'orgStructureDefName',
          Cell: StringRenderer,
          filterable: false,
        },
        {
          Header: t('tables.manager'),
          id: 'manager',
          accessor: (d) => ({
            employeeJob: '',
            user: d.orgStructureUnitManager,
            userKeyPath: 'orgStructureUnitManager',
          }),
          Cell: UserRenderer,
        },
        {
          Header: t('tables.parent_organization'),
          id: 'parentOrg',
          Cell: StringRenderer,
          accessor: (d) => (d.parent ? d.parent.displayString : ''),
        },
        {
          Header: t('tables.headcount'),
          id: 'headcount',
          Cell: StringRenderer,
          accessor: 'headcount',
        },
        {
          id: 'actions',
          Cell: EditDeleteActionsRenderer,
          sortable: false,
          filterable: false,
          onDeleteItem: this.onDeleteItem,
          onViewItem: this.onViewItem,
          securityModule: 'org-unit',
        },
      ],
      defaultSorted: [
        {
          id: 'created',
          desc: true,
        },
      ],
      SweetAlertModal: withReactContent(Swal),
      paginationPageSize: 10,
      minRows: 3,
      tableRef: null,
    };
  }

  onDeleteItem = (itemId) => {
    const { t } = this.props;
    const { SweetAlertModal } = this.state;
    return new Promise((resolve, reject) => {
      SweetAlertModal.fire({
        title: t('message.are_you_sure'),
        showConfirmButton: true,
        showCancelButton: true,
        text: t('message.you_are_about_to_delete_organization_unit'),
        confirmButtonColor: '#1ab394',
        confirmButtonText: t('message.yes_delete_it'),
        cancelButtonText: t('buttons.cancel'),
        showCloseButton: true,
        icon: 'warning',
      }).then((data) => {
        if (data.isConfirmed) {
          this.confirmDeleteItem(itemId);
        }
      });
    });
  };

  onViewItem = (itemId) => {
    const { ORG } = AUTH_ROUTES;
    const { history } = this.props;
    const link = `${ORG.ORG_UNIT}/${itemId}/edit`;

    history.push(link, { organizationUnitId: itemId });
  };

  confirmDeleteItem = (itemId) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
    });
    deleteOrgUnit(itemId)
      .then(() => {
        this.tableRef.updateTableData(true);
        this.setState({ isLoading: false });
        Notifications.success(t('message.organization_unit_deleted'));
        document.activeElement?.blur();
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const { paginationPageSize, minRows, columns, defaultSorted } = this.state;
    const { tableReference, setTableReference, fetchOrgUnitList } = this.props;
    return (
      <div>
        <PaginationTable
          ref={(elementRef) => {
            this.tableRef = elementRef;
            !tableReference && elementRef && setTableReference(elementRef);
          }}
          minRows={minRows}
          defaultSorted={defaultSorted}
          defaultPageSize={paginationPageSize}
          columns={columns}
          getDataFromServer={fetchOrgUnitList}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

AllOrgUnitTable.propTypes = {
  fetchOrgUnitList: PropTypes.func.isRequired,
  setTableReference: PropTypes.func.isRequired,
  tableReference: PropTypes.object,
};

export default withTranslation()(withRouter(AllOrgUnitTable));
