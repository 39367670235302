import Axios from 'axios';
import qs from 'qs';
import { hostname } from '../constants/hostSettings';
import config from '../constants/config';
import { auth } from '../constants/endpoints';

let getRefreshTokenPromise;

const getXSRF = () => {
  const account = JSON.parse(localStorage.getItem('account'));
  const accessToken = account && account.accessToken ? `Bearer ${account.accessToken}` : '';

  return accessToken;
};

const getRefreshToken = () => {
  const account = JSON.parse(localStorage.getItem('account'));
  return account && account.refreshToken ? `Bearer ${account.refreshToken}` : '';
};
const getExpirationAccessTokenDate = () => {
  const account = JSON.parse(localStorage.getItem('account'));
  return account && account.accessTokenExpirationDate ? account.accessTokenExpirationDate : '';
};

export const getTenant = () => {
  const tenant = JSON.parse(localStorage.getItem('tenant'));
  return tenant || '';
};

export const sendRequest = (options) =>
  Axios.request(options).then(
    (response) => response.data,
    (error) => Promise.reject(error.response ? error.response.data || error.response : null),
  );

const checkTokens = (options, settings = {}) => {
  let optionsCopy = { ...options };

  if (getRefreshTokenPromise != null) {
    return getRefreshTokenPromise;
  }
  const now = new Date();
  const expirationDate = new Date(getExpirationAccessTokenDate());

  if (now > expirationDate) {
    optionsCopy = {
      baseURL: config.apiGateway.URL,
      responseType: 'json',
      method: 'GET',
      refreshTokenAction: true,
      cache: false,
      url: auth.refreshToken(),
      headers: {
        common: {
          Tenant: settings.tenant || getTenant(),
          'X-Requested-With': 'XMLHttpRequest',
        },
      },
    };

    const tkn = getRefreshToken();
    optionsCopy.headers.common.Authorization = tkn || null;

    if (hostname !== 'localhost') {
      optionsCopy.headers.common.Pragma = 'no-cache';
    }
    const accountData = JSON.parse(localStorage.getItem('account'));

    return (getRefreshTokenPromise = sendRequest(optionsCopy)
      .then((data) => {
        accountData.accessToken = data.accessToken;
        accountData.refreshToken = data.refreshToken;
        accountData.accessTokenExpirationDate = data.accessTokenExpirationDate;
        localStorage.setItem('account', JSON.stringify(accountData));
        getRefreshTokenPromise = null;
        return Promise.resolve();
      })
      .catch((error) => {
        getRefreshTokenPromise = null;
        localStorage.setItem('account', null);
        return Promise.reject(error);
      }));
  }
  return Promise.resolve();
};

export const dataProvider = (options = {}, settings = {}) => {
  const optionsCopy = { ...options };

  optionsCopy.baseURL = config.apiGateway.URL;
  optionsCopy.responseType = optionsCopy.responseType || 'json';
  optionsCopy.method = optionsCopy.method || 'GET';
  optionsCopy.cache = false;
  optionsCopy.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' });

  return checkTokens(optionsCopy, settings).then(() => {
    optionsCopy.headers = {
      common: {
        Tenant: settings.tenant || getTenant(),
        'X-Requested-With': 'XMLHttpRequest',
      },
    };

    if (optionsCopy.method === 'PATCH') {
      optionsCopy.headers = {
        ...optionsCopy.headers,
        'Content-Type': 'application/json-patch+json',
      };
    }

    const authToken = settings.token ? `Bearer ${settings.token}` : getXSRF();

    if (authToken) {
      optionsCopy.headers.common.Authorization = authToken;
    } else {
      delete optionsCopy.headers.common.Authorization;
    }

    if (hostname !== 'localhost') {
      optionsCopy.headers.common.Pragma = 'no-cache';
    }

    return sendRequest(optionsCopy);
  });
};

export default dataProvider;
