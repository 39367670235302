import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import PageNameContainer from '../../components/page/PageNameContainer';
import GeneralFeedbackAssignmentPeriodInfo from '../../components/feedbackassignmentperiod/GeneralFeedbackAssignmentPeriodInfo';
import FeedbackAssignmentPeriodSettings from '../../components/feedbackassignmentperiod/FeedbackAssignmentPeriodSettings';
import {
  getFeedbackAssignmentPeriod,
  updateFeedbackAssignmentPeriod,
} from '../../services/feedbackassignmentperiod/feedbackAssignmentPeriodProvider';

class EditFeedbackAssignmentPeriodPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      feedbackAssignmentPeriod: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_feedback_assignment_periods'),
        ref: '/feedback-assignment-periods',
        isActive: false,
      },
      {
        name: t('breadcrumbs.feedback_assignment_period'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { feedbackAssignmentPeriodId } = this.props.match.params;
    this.setState({ isLoading: true });

    getFeedbackAssignmentPeriod(feedbackAssignmentPeriodId)
      .then((feedbackAssignmentPeriod) => {
        this.setState({
          isLoading: false,
          feedbackAssignmentPeriod,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  updateFeedbackAssignmentPeriod = (data) => {
    this.setState({ isLoading: true });
    const { t } = this.props;
    updateFeedbackAssignmentPeriod(data)
      .then((_data) => {
        const { location } = this.props.history;
        getFeedbackAssignmentPeriod(location.state.feedbackAssignmentPeriodId).then(
          (updatedFeedbackAssignmentPeriod) => {
            this.setState({
              isLoading: false,
              feedbackAssignmentPeriod: updatedFeedbackAssignmentPeriod,
            });
          },
        );
        Notifications.success(t('message.feedback_assignment_period_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, feedbackAssignmentPeriod } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.feedback_assignment_period')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-6">
              {feedbackAssignmentPeriod && feedbackAssignmentPeriod.id ? (
                <GeneralFeedbackAssignmentPeriodInfo
                  feedbackAssignmentPeriod={feedbackAssignmentPeriod}
                />
              ) : (
                <div>
                  <div className="ibox-content">
                    <p className="text-muted text-center">
                      {t('components.no_info_about_feedback_assignment_period')}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-6">
              {feedbackAssignmentPeriod && feedbackAssignmentPeriod.id ? (
                <FeedbackAssignmentPeriodSettings
                  updateFeedbackAssignmentPeriod={this.updateFeedbackAssignmentPeriod}
                  feedbackAssignmentPeriod={feedbackAssignmentPeriod}
                />
              ) : (
                <div>
                  <div className="ibox-content">
                    <p className="text-muted text-center">
                      {t('components.no_info_about_feedback_assignment_period')}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

EditFeedbackAssignmentPeriodPage.propTypes = {
  feedbackAssignmentPeriod: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
};

export default withTranslation()(EditFeedbackAssignmentPeriodPage);
