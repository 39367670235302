import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { AUTH_ROUTES, TENANT_ID } from '../../constants/routes';
import AllTenants from '../../containers/tenant/AllTenants';
import EditTenant from '../../containers/tenant/EditTenant';

class TenantRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllTenants} />
        <Route
          path={`${match.url}${TENANT_ID}`}
          render={({ match }) => <TenantInnerRouting match={match} />}
        />
      </div>
    );
  }
}

TenantRoutes.propTypes = {
  match: PropTypes.object,
};

export default TenantRoutes;

class TenantInnerRouting extends Component {
  render() {
    const { match } = this.props;
    if (match.params.tenantId) {
      return (
        <Route exact path={`${AUTH_ROUTES.TENANT_MANAGEMENT}${TENANT_ID}`} component={EditTenant} />
      );
    }
    return (
      <Route exact path={`${AUTH_ROUTES.TENANT_MANAGEMENT}${TENANT_ID}`} component={AllTenants} />
    );
  }
}

TenantInnerRouting.propTypes = {
  match: PropTypes.object,
};
