import React, { Component } from 'react';

import RadioGroup from '@material-ui/core/RadioGroup';
import { Field, withFormik } from 'formik';
import '../styles/questions.css';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { withTranslation } from 'react-i18next';
import MaterialRadio from '../../inputs/MaterialRadio';

class SingleChoiceQuestion extends Component {
  buildAnswer = (e, question, answer) => {
    const { testAssignment, populateUserAnswer, questionAnswerResults } = this.props;
    let answerResult;
    if (questionAnswerResults && questionAnswerResults.length > 0) {
      answerResult = questionAnswerResults[0];
    }
    const result = [];
    if (answerResult && answer.id !== answerResult.answerId) {
      result.push({
        object: {
          id: answerResult.id,
        },
        action: 'delete',
        objectType: 'Platform:QuestionAnswerResult',
      });
    }
    if (!answerResult || (answerResult && answer.id !== answerResult.answerId)) {
      result.push({
        object: {
          answerId: answer.id,
          questionId: question.id,
          testAssignmentId: testAssignment.id,
        },
        action: 'create',
        objectType: 'Platform:QuestionAnswerResult',
      });
    }
    populateUserAnswer(answer.questionId, result);
  };

  render() {
    const { t, question, handleChange, setFieldValue, values, animation } = this.props;

    return (
      <>
        {question && question.id ? (
          <RadioGroup name={`question-${question.id}-answer`}>
            <div className={`animated ${animation} fm-answer-container clearfix`}>
              {question.answers && question.answers.length > 0
                ? question.answers.map((answer, index) => (
                    <div className="i-checks" key={answer.id}>
                      <Field
                        component={MaterialRadio}
                        type="radio"
                        value={`${index}`}
                        name={`question-${question.id}-answer`}
                        color="primary"
                        icon={<CircleUnchecked />}
                        checkedIcon={<CircleCheckedFilled />}
                        checked={parseInt(values[`question-${question.id}-answer`]) === index}
                        setFieldValue={setFieldValue}
                        onChange={(e) => {
                          this.buildAnswer(e, question, answer);
                          handleChange(e);
                        }}
                        label={answer.answer}
                      />
                    </div>
                  ))
                : null}
            </div>
          </RadioGroup>
        ) : (
          <div>
            <h3>{t('components.loading')}</h3>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ questionAnswerResults, question }) => {
      const result = {};

      if (questionAnswerResults && questionAnswerResults.length > 0) {
        const c = question.answers.findIndex((v) => v.id === questionAnswerResults[0].answerId);
        result[`question-${question.id}-answer`] = c;
      }
      return result;
    },
  })(SingleChoiceQuestion),
);
