import React, { Component } from 'react';
import './styles/footer.css';
import { withTranslation } from 'react-i18next';

class FooterContainer extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="fm-footer-container">
        <span>© 2020 - {new Date().getFullYear()} formeasure</span>
        <div className="fm-footer-links">
          <span>{t('components.terms_of_user')}</span>
          <span>{t('components.privacy_policy')}</span>
        </div>
      </div>
    );
  }
}

FooterContainer.propTypes = {};

export default withTranslation()(FooterContainer);
