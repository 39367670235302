import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Moment from 'moment';
import { withTranslation } from 'react-i18next';
import { deleteFeedbackAssignment } from '../../services/feedbackassignment/feedbackAssignmentProvider';
import '../feedbacks/styles/feedback-table.css';
import Notifications from '../../utils/Notifications';
import { AUTH_ROUTES } from '../../constants/routes';
import PaginationTable from '../tables/PaginationTable';
import EditDeleteActionsRenderer from '../tables/EditDeleteActionsRenderer';
import StringRenderer from '../tables/StringRenderer';
import UserRenderer from '../tables/UserRenderer';
import ReviewStatusRenderer from '../feedbackassignments/ReviewStatusRenderer';

class AllFeedbackAssignmentsTable extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      columns: [
        {
          Header: t('tables.employee'),
          id: 'employee',
          accessor: (d) => ({
            user: d.reviewee,
            employeeJob: d.revieweeEmployee ? d.revieweeEmployee.employeeJob : '',
            userKeyPath: 'revieweeEmployee.user',
            employeeJobKeyPath: 'revieweeEmployee.employeeJob',
          }),
          Cell: UserRenderer,
          filterable: false,
        },
        {
          Header: t('tables.organization_unit'),
          id: 'orgUnitName',
          accessor: (d) => (d.revieweeEmployee ? d.revieweeEmployee.orgStructureUnitName : ''),
          Cell: StringRenderer,
          filterable: false,
        },
        {
          Header: t('tables.feedback'),
          accessor: 'feedbackDisplayName',
          Cell: StringRenderer,
          filterable: false,
        },
        {
          Header: t('tables.deadline'),
          Cell: StringRenderer,
          id: 'deadline',
          accessor: (d) => (d.deadline ? Moment.utc(d.deadline).format('LL') : ''),
        },
        {
          Header: t('tables.reviewers'),
          Cell: StringRenderer,
          id: 'reviewersCount',
          filterable: false,
          accessor: (d) => (d.reviewersCount ? d.reviewersCount : 0),
        },
        {
          Header: t('tables.completed_reviews'),
          Cell: StringRenderer,
          id: 'completedReviewersCount',
          filterable: false,
          accessor: (d) => (d.reviewersComplitedCount ? d.reviewersComplitedCount : 0),
        },
        {
          Header: t('tables.self_review'),
          id: 'selfReview',
          filterable: false,
          accessor: (d) => d.selfReviewStatus,
          Cell: ReviewStatusRenderer,
        },
        {
          Header: t('tables.manager_summary'),
          Cell: ReviewStatusRenderer,
          id: 'managerSummary',
          accessor: (d) => (d.managerReviewStatus ? d.managerReviewStatus : 0),
        },
        {
          id: 'actions',
          Cell: EditDeleteActionsRenderer,
          sortable: false,
          filterable: false,
          onDeleteItem: this.onDeleteItem,
          onViewItem: this.onViewItem,
          securityModule: 'feedback-assignments',
        },
      ],
      defaultSorted: [
        {
          id: 'deadline',
          desc: false,
        },
      ],
      SweetAlertModal: withReactContent(Swal),
      paginationPageSize: 10,
      minRows: 3,
      tableRef: null,
    };
  }

  onDeleteItem = (itemId) => {
    const { t } = this.props;
    const { SweetAlertModal } = this.state;
    return new Promise((resolve, reject) => {
      SweetAlertModal.fire({
        title: t('message.are_you_sure'),
        showConfirmButton: true,
        showCancelButton: true,
        text: t('message.you_are_about_to_delete_feedback_assignment'),
        confirmButtonColor: '#1ab394',
        confirmButtonText: t('message.yes_delete_it'),
        cancelButtonText: t('buttons.cancel'),
        showCloseButton: true,
        icon: 'warning',
      }).then((data) => {
        if (data.isConfirmed) {
          this.confirmDeleteItem(itemId);
        }
      });
    });
  };

  onViewItem = (itemId) => {
    const { FEEDBACK } = AUTH_ROUTES;
    const { history } = this.props;
    const link = `${FEEDBACK.ASSIGNMENTS}/${itemId}/edit`;

    history.push(link, { feedbackId: itemId });
  };

  confirmDeleteItem = (itemId) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
    });
    deleteFeedbackAssignment(itemId)
      .then(() => {
        this.tableRef.updateTableData(true);
        this.setState({ isLoading: false });
        Notifications.success(t('message.feedback_assignment_deleted'));
        document.activeElement?.blur();
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { paginationPageSize, minRows, columns, defaultSorted } = this.state;
    const { tableReference, setTableReference, fetchFeedbackAssignmentList } = this.props;

    return (
      <div>
        <div>
          <PaginationTable
            ref={(elementRef) => {
              this.tableRef = elementRef;
              if (!tableReference && elementRef) {
                setTableReference(elementRef);
              }
            }}
            minRows={minRows}
            defaultSorted={defaultSorted}
            defaultPageSize={paginationPageSize}
            columns={columns}
            getDataFromServer={fetchFeedbackAssignmentList}
            className="-striped -highlight"
          />
        </div>
      </div>
    );
  }
}

AllFeedbackAssignmentsTable.propTypes = {
  fetchFeedbackAssignmentList: PropTypes.func.isRequired,
  userList: PropTypes.array.isRequired,
  feedbackList: PropTypes.array.isRequired,
  setTableReference: PropTypes.func.isRequired,
  tableReference: PropTypes.object,
};

export default withTranslation()(withRouter(AllFeedbackAssignmentsTable));
