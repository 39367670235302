import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';

import '../feedbacks/styles/feedback-table.css';
import _ from 'lodash';
import './styles/assignment-reviewers-form.css';
import * as regularIcons from '@fortawesome/free-regular-svg-icons';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import * as solidIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import CommentModal from './passing/CommentModal';
import Modal from '../modal/Modal';
import Notifications from '../../utils/Notifications';
import { getFeedbackCriteriaAnswersByReviewerId } from '../../services/feedbackcriteriaanswer/feedbackCriteriaAnswerProvider';
import MaterialHtmlTooltip from '../ui-elements/MaterialHtmlTooltip';
import ContentLoader from '../loader/ContentLoader';

function InnerForm(props) {
  const { t, reviewerId, criteria } = props;
  const [state, setState] = useState({
    hasData: false,
    comment: null,
    showCommentModal: false,
    loading: true,
    criteria: [...criteria],
  });

  useEffect(() => {
    const styles = [];
    const values = [];
    let min = 0;
    let max = 1;

    if (reviewerId) {
      getFeedbackCriteriaAnswersByReviewerId(reviewerId)
        .then((data) => {
          if (data.length === 0) {
            setState({
              ...state,
              hasData: false,
              loading: false,
            });
            return;
          }

          _.forEach(state.criteria, (criterion) => {
            const answer = data.find(
              (v) => parseInt(v.feedbackCriteriaId) === parseInt(criterion.id),
            );

            if (
              criterion.feedbackCriteriaDescriptions[0] &&
              criterion.feedbackCriteriaDescriptions[0].value !== 0
            ) {
              criterion.feedbackCriteriaDescriptions.unshift({
                color: '#979797',
                description: 'N/A',
                label: 'N/A',
                value: 0,
              });
            }

            _.forEach(criterion.feedbackCriteriaDescriptions, (cd) => {
              if (cd.value < min) {
                min = cd.value;
              }
              if (cd.value > max) {
                max = cd.value;
              }
            });

            if (answer && criterion.feedbackCriteriaDescriptions[answer.value - 1]) {
              values.push(answer);
            } else {
              values.push({});
            }
          });
          setState({
            ...state,
            styles,
            values,
            hasData: true,
            min,
            max,
            loading: false,
          });
        })
        .catch((error) => {
          Notifications.error(error);
        });
    } else {
      setState({
        ...state,
        hasData: false,
        loading: false,
      });
    }
  }, []);

  const onClickComment = (answer) => {
    setState({
      ...state,
      comment: answer ? answer.comment : '',
      showCommentModal: true,
    });
  };

  const closeModal = () => {
    setState({
      ...state,
      feedbackCriteriaId: null,
      comment: null,
      showCommentModal: false,
    });
  };

  const { values, hasData, comment, showCommentModal, min, max, loading } = state;
  return (
    <div>
      {hasData &&
        values &&
        criteria &&
        criteria.map((cri, index) => {
          const criteriaDescription =
            cri.feedbackCriteriaDescriptions[values[index].value ? values[index].value : 0];
          return (
            <div className="fm-feedback-assignment-description" key={index}>
              <MaterialHtmlTooltip title={cri.name} placement="left" arrow>
                <div className="-criterion-name">
                  <b>{cri.name}</b>
                </div>
              </MaterialHtmlTooltip>
              <div className="-slider">
                <Slider
                  step={1}
                  sx={{
                    color: '#34B77C',
                    '& .MuiSlider-thumb': {
                      '&:hover, &.Mui-focusVisible': {
                        boxShadow: `0px 0px 0px 8px rgb(52, 183, 124, .16)`,
                      },
                      '&.Mui-active': {
                        boxShadow: `0px 0px 0px 14px rgb(52, 183, 124, .16)`,
                      },
                      '&.Mui-disabled': {
                        color: criteriaDescription.color,
                        pointerEvents: 'auto',
                      },
                    },
                  }}
                  id={criteriaDescription.id}
                  style={{
                    color: criteriaDescription.color,
                  }}
                  defaultValue={values[index] ? values[index].value : 0}
                  value={values[index] ? values[index].value : 0}
                  size="medium"
                  valueLabelDisplay="auto"
                  getAriaValueText={(v) => v}
                  min={min}
                  max={max}
                  valueLabelFormat={() =>
                    criteriaDescription ? criteriaDescription.label : values[index].value
                  }
                  disabled
                />
              </div>
              <div className="fm-comment-wrapper">
                {values[index].comment && (
                  <div className="fm-button-circle">
                    <FontAwesomeIcon
                      onClick={() => onClickComment(values[index])}
                      icon={values[index].comment ? solidIcons.faComment : regularIcons.faComment}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      <Modal
        header={t('components.add_comment')}
        isOpened={showCommentModal}
        icon={faComment}
        onClose={closeModal}
      >
        <CommentModal comment={comment} cancel={closeModal} readOnly />
      </Modal>
      {!loading && !hasData && (
        <div className="col-md-10 fm-empty-box">
          <p className="text-center">{t('message.not_answered_yet')}</p>
        </div>
      )}
      {loading && <ContentLoader visible />}
    </div>
  );
}

InnerForm.propTypes = {
  criteria: PropTypes.array.isRequired,
  reviewerId: PropTypes.number,
};

export default withTranslation()(withRouter(InnerForm));
