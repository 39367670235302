import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PassingProgressBar extends Component {
  render() {
    const { id, progress } = this.props;
    return (
      <div className="fm-passing-progressbar" id={id}>
        <div className="fm-backgroun-line" style={{ width: '100%' }} />
        <div className="fm-fill-line" style={{ width: `${progress}%` }} />
        <div className="fm-progress-history">
          <span>{progress.toFixed(0)} %</span>
        </div>
      </div>
    );
  }
}

PassingProgressBar.propTypes = {
  id: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
};

export default PassingProgressBar;
