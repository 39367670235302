import React, { useMemo, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faEnvelope,
  faMapLocationDot,
  faComments,
  faStar,
} from '@fortawesome/free-solid-svg-icons';

// import { useNewModal } from '../NewModal';
import UserPhotoUtil from '../../utils/UserPhotoUtil';
import TextWithCopyToClipboard from '../TextWithCopyToClipboard';

export default function UserRendererWithTooltip({ original, value }) {
  const { t } = useTranslation();
//   const { openModal, closeModal } = useNewModal();

  const userInfo = useMemo(
    () => ({
      user: original[value.userKeyPath],
      employeeJob: original[value.employeeJobKeyPath],
      employeeJobLevel: original[value.employeeJobLevelKeyPath],
      orgStructureUnitName: original.orgStructureUnitName,
      location: original.location,
    }),
    [original, value],
  );

  return (
    <Popover>
      <Popover.Button className="cursor-pointer w-full group" as={Fragment}>
        <div className="fm-table-user-info-container">
          {userInfo && (
            <>
              {userInfo.user && (
                <div className="fm-table-user-avatar-small-box group-hover:text-green-600">
                  {UserPhotoUtil.renderUserPhotoByLink(userInfo.user.profileImageLink)}
                </div>
              )}
              <div className="fm-table-user-info">
                {userInfo.user && (
                  <span
                    className="fm-table-username group-hover:text-green-600"
                    title={userInfo.user.displayString}
                  >
                    {userInfo.user.displayString}
                  </span>
                )}
                {userInfo.employeeJob && (
                  <span className="fm-table-user-job" title={userInfo.employeeJob?.name ?? ''}>
                    {`${userInfo.employeeJobLevel?.displayString ?? ''} ${
                      userInfo.employeeJob?.displayString ?? ''
                    }`}
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-1 mt-3 w-sm -translate-x-1/2 transform sm:p-0 lg:max-w-3xl bg-white">
          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 p-4 flex flex-col gap-3">
            <div className="flex justify-start gap-2">
              <div className="fm-table-user-avatar-small-box">
                {UserPhotoUtil.renderUserPhotoByLink(userInfo.user.profileImageLink)}
              </div>
              <div className="flex flex-col text-xs">
                <span className="text-sm font-bold">{userInfo.user.displayString}</span>
                {userInfo.employeeJobLevel?.displayString &&
                  userInfo.employeeJob?.displayString && (
                    <span>
                      {userInfo.employeeJobLevel.displayString},{' '}
                      {userInfo.employeeJob.displayString}
                    </span>
                  )}
                <span>{userInfo.orgStructureUnitName}</span>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              {userInfo.location && (
                <div className="text-xs">
                  <FontAwesomeIcon icon={faMapLocationDot} className="mr-1" />
                  {userInfo.location.country}, {userInfo.location.city}
                </div>
              )}
              {userInfo.user.phone && (
                <div className="text-xs">
                  <span className="group">
                    <FontAwesomeIcon icon={faPhone} className="mr-1" />
                    <TextWithCopyToClipboard
                      text={userInfo.user.phone}
                      textClassName="mr-1"
                      containerClassName="group-hover:text-green-500"
                    />
                  </span>
                </div>
              )}
              {userInfo.user.email && (
                <div className="text-xs">
                  <span className="group">
                    <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
                    <TextWithCopyToClipboard
                      text={userInfo.user.email}
                      textClassName="mr-1"
                      containerClassName="group-hover:text-green-500"
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
