import {
  SET_EMPLOYEE_INFO,
  SET_EMPLOYEE_ORG_UNITS,
  SET_EMPLOYEE_USERS,
} from '../../constants/actions';

const initialState = {
  employee: {},
  users: [],
  orgUnits: [],
};
export default function employee(state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYEE_INFO:
      return {
        ...state,
        employee: action.employee,
      };
    case SET_EMPLOYEE_ORG_UNITS:
      return {
        ...state,
        orgUnits: action.orgUnits,
      };
    case SET_EMPLOYEE_USERS:
      return {
        ...state,
        users: action.users,
      };
    default:
      return state || null;
  }
}
