import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AllFeedbackCategories from '../../containers/feedbacks/AllFeedbackCategories';
import EditFeedbackCategoryPage from '../../containers/feedbacks/EditFeedbackCategoryPage';

import { AUTH_ROUTES, FEEDBACK_CATEGORY_ID } from '../../constants/routes';

class FeedbackCategoryRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllFeedbackCategories} />
        <Route
          path={`${match.url}${FEEDBACK_CATEGORY_ID}`}
          render={({ match }) => <FeedbackCategoryInnerRouting match={match} />}
        />
      </div>
    );
  }
}

FeedbackCategoryRoutes.propTypes = {
  match: PropTypes.object,
};

export default FeedbackCategoryRoutes;

class FeedbackCategoryInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let feedbackCategoryRouting;
    if (match.params.feedbackCategoryId) {
      switch (match.params.feedbackCategoryId) {
        default:
          feedbackCategoryRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.FEEDBACK.CATEGORIES}${FEEDBACK_CATEGORY_ID}`}
              component={AllFeedbackCategories}
            />
          );
      }
    }

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.FEEDBACK.CATEGORIES}${FEEDBACK_CATEGORY_ID}/edit`}
          component={EditFeedbackCategoryPage}
        />
        {feedbackCategoryRouting}
      </div>
    );
  }
}

FeedbackCategoryInnerRouting.propTypes = {
  match: PropTypes.object,
};
