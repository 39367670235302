import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import MultipleChoiceQuestion from '../questionTypes/MultipleChoiceQuestion';
import SingleChoiceQuestion from '../questionTypes/SingleChoiceQuestion';
import PulseQuestion from '../questionTypes/PulseQuestion';
import TextQuestion from '../questionTypes/TextQuestion';

class PassingQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionId: null,
    };
  }

  renderQuestion = (question) => {
    const {
      testAssignment,
      step,
      numSteps,
      populateUserAnswer,
      setFieldValue,
      questionAnswerResults,
      animation,
    } = this.props;

    if (question && question.questionType) {
      switch (question.questionType) {
        case 'Text':
          return (
            <TextQuestion
              question={question}
              testAssignment={testAssignment}
              step={step}
              animation={animation}
              numSteps={numSteps}
              populateUserAnswer={populateUserAnswer}
              questionAnswerResults={questionAnswerResults}
              setFieldValue={setFieldValue}
            />
          );
        case 'MultipleChoice':
          return (
            <MultipleChoiceQuestion
              question={question}
              testAssignment={testAssignment}
              step={step}
              numSteps={numSteps}
              animation={animation}
              populateUserAnswer={populateUserAnswer}
              questionAnswerResults={questionAnswerResults}
              setFieldValue={setFieldValue}
            />
          );
        case 'SingleChoice':
          return (
            <SingleChoiceQuestion
              question={question}
              testAssignment={testAssignment}
              step={step}
              animation={animation}
              numSteps={numSteps}
              populateUserAnswer={populateUserAnswer}
              questionAnswerResults={questionAnswerResults}
              setFieldValue={setFieldValue}
            />
          );
        case 'Pulse':
          return (
            <PulseQuestion
              question={question}
              testAssignment={testAssignment}
              step={step}
              animation={animation}
              numSteps={numSteps}
              populateUserAnswer={populateUserAnswer}
              questionAnswerResults={questionAnswerResults}
            />
          );
        default:
          // TODO
          return 'None';
      }
    }
  };

  render() {
    const { question } = this.props;
    return <>{this.renderQuestion(question)}</>;
  }
}

PassingQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  populateUserAnswer: PropTypes.func.isRequired,
  questionAnswerResults: PropTypes.array,
};

export default withTranslation()(PassingQuestion);
