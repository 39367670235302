/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

import { useNewModal } from '.';

function NewModalRoot({ t }) {
  const {
    isOpened,
    component: [Component, props],
    closeModal,
  } = useNewModal();

  const handleEscButton = useCallback((e) => {
    if (e.key === 'Escape') closeModal();
  }, []);

  useEffect(() => {
    document.body.addEventListener('keydown', handleEscButton);

    return () => document.body.removeEventListener('keydown', handleEscButton);
  }, []);

  return (
    isOpened && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl min-w-[66%]">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-4 border-b border-solid border-slate-200 rounded-t">
                {props.icon && (
                  <div className="fm-icon-wrapper">
                    <FontAwesomeIcon icon={props.icon} />
                  </div>
                )}
                <h3 className="text-3xl font-semibold">{props.header}</h3>
                <div
                  type="button"
                  className="block text-4xl"
                  onClick={closeModal}
                  data-dismiss="modal"
                  aria-label={t('buttons.close')}
                >
                  ×
                </div>
              </div>
              <div className="bg-gray-100">{Component}</div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black" onClick={closeModal} />
      </>
    )
  );
}

NewModalRoot.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(NewModalRoot);
