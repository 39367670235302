import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class FeedbackRequestTypeRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = { id: props.original.id };
  }

  statusRenderer = (value) => {
    const { t } = this.props;
    if (value) {
      if (value.isManagerReview) {
        return t('tables.manager_summary');
      }
      if (value.isSelfReview) {
        return t('components.self_review');
      }
      return t('components.review');
    }
  };

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  render() {
    const { value } = this.props;
    return (
      <div className="fm-assignment-status">
        <div className="fm-status-btn fm-btn-success">{this.statusRenderer(value)}</div>
      </div>
    );
  }
}

export default withTranslation()(FeedbackRequestTypeRenderer);
