import _ from 'lodash';

export const hasPermission = (permissions, controlPermissions, isProtected) => {
  if (!controlPermissions) {
    controlPermissions = [];
  }
  controlPermissions.push('everything:everything');
  if (!isProtected) {
    controlPermissions.push('current-tenant:everything');
  }
  for (const perm of controlPermissions) {
    if (_.includes(permissions, perm)) {
      return true;
    }
  }
  return false;
};

export const canSeeOption = (userPermissions, permission) => {
  const moduleEverything = `${permission.substring(0, permission.indexOf(':'))}:everything`;
  return (
    _.includes(userPermissions, permission) ||
    hasPermission(userPermissions, [moduleEverything, permission])
  );
};

/** *
 *
 * @param module
 * @param userPermissions - user permissions
 * @param permission
 * @param isProtected - global permission identifier, hidden from all permissions except everything:everything
 * @returns {boolean}
 */

export const checkPermission = (module, userPermissions, permission, isProtected) => {
  const _permissionName = `${module}:${permission}`;
  const moduleEverything = `${module}:everything`;

  if (
    _.includes(userPermissions, _permissionName) ||
    hasPermission(userPermissions, [moduleEverything, _permissionName], isProtected)
  ) {
    return true;
  }
  return false;
};
