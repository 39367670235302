import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';
import {
  LOGIN_TENANT,
  NOT_VERIFIED,
  RESET_PASSWORD_DONE,
  RESET_PASSWORD_LINK_EXPIRED,
  RESET_PASSWORD_REQUESTED,
  RESET_PASSWORD_WITH_TOKEN,
  VERIFICATION,
} from '../constants/routes';
import Login from '../containers/auth/AuthContainer';
import Verification from '../containers/auth/Verification';
import NotVerified from '../containers/auth/NotVerified';
import ResetPasswordMessenger from '../containers/auth/ResetPasswordMessenger';

class GuestRoutes extends PureComponent {
  render() {
    return (
      <div>
        <Route exac path={LOGIN_TENANT} component={Login} />
        <Route path={`${RESET_PASSWORD_WITH_TOKEN}`} component={Login} />
        {/* <Route path={`${ROOT}${REGISTRATION_TENANT}`} component={Login}/> */}
        <Route path={VERIFICATION} component={Verification} />
        <Route exac path={NOT_VERIFIED} component={NotVerified} />
        <Route
          exac
          path={RESET_PASSWORD_DONE}
          render={() => <ResetPasswordMessenger type="DONE" />}
        />
        <Route
          exac
          path={RESET_PASSWORD_REQUESTED}
          render={() => <ResetPasswordMessenger type="REQUESTED" />}
        />
        <Route
          exac
          path={RESET_PASSWORD_LINK_EXPIRED}
          render={() => <ResetPasswordMessenger type="EXPIRED" showResetPasswordLink />}
        />
      </div>
    );
  }
}

export default GuestRoutes;
