import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { AUTH_ROUTES, GLOBAL_TEST_ID } from '../../constants/routes';

import AllGlobalTests from '../../containers/tests/globalsurveys/AllGlobalSurveys';
import ViewEditGlobalSurveyPage from '../../containers/tests/globalsurveys/ViewEditGlobalSurveyPage';

import AllPurchaseTests from '../../containers/tests/purchasetests/AllPurchaseTests';

class GlobalTestRoutes extends Component {
  render() {
    const { match } = this.props;

    return (
      <div>
        <Route exact path={`${match.url}`} component={AllGlobalTests} />
        <Route exact path={`${match.url}/purchase`} component={AllPurchaseTests} />

        <Route
          path={`${match.url}${GLOBAL_TEST_ID}`}
          render={({ match }) => <GlobalTestInnerRouting match={match} />}
        />
      </div>
    );
  }
}

GlobalTestRoutes.propTypes = {
  match: PropTypes.object,
};

export default GlobalTestRoutes;

class GlobalTestInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let globalTestRouting;
    if (match.params.globalTestId) {
      switch (match.params.globalTestId) {
        case 'create':
          globalTestRouting = <Route path={`${match.url}`} component={ViewEditGlobalSurveyPage} />;
          break;
        case 'all':
          globalTestRouting = <Route path={`${match.url}`} component={AllGlobalTests} />;
          break;
        default:
          globalTestRouting = <Route exact path={`${match.url}`} component={AllGlobalTests} />;
      }
    }

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.GLOBAL_TESTS}${GLOBAL_TEST_ID}/edit`}
          component={ViewEditGlobalSurveyPage}
        />
        {globalTestRouting}
      </div>
    );
  }
}

GlobalTestInnerRouting.propTypes = {
  match: PropTypes.object,
};
