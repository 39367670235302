import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import '../styles/test-form.css';
import _ from 'lodash';
import {
  faBars,
  faChevronDown,
  faChevronRight,
  faClone,
  faExclamationTriangle,
  faHashtag,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import AddClassificationToQuestionModal from './AddClassificationToQuestionModal';
import Modal from '../../modal/Modal';
import SurveyQuestionAnswersForm from './SurveyQuestionAnswersForm';
import Select from '../../react-hook-form-inputs/Select';
import TextArea from '../../react-hook-form-inputs/TextArea';

function InnerForm(props) {
  const { t, questions, questionTypes, survey } = props;
  const validationSchema = Yup.object().shape({
    questions: Yup.array().of(
      Yup.object().shape({
        question: Yup.string().required(t('message.required_field')),
        questionType: Yup.object()
          .shape({
            value: Yup.string().required(t('message.required_field')),
          })
          .nullable()
          .required(t('message.required_field')),
      }),
    ),
  });

  const allowedTags = survey.testQuestionClassification
    ? survey.testQuestionClassification.map((t) => t.name)
    : [];

  _.forEach(questions, (q, index) => {
    if (q.questionClassifications && q.questionClassifications.length > 0) {
      const oldLength = q.questionClassifications.length;
      q.questionClassifications = q.questionClassifications.filter((t) =>
        allowedTags.includes(t.name),
      );
      if (oldLength !== q.questionClassifications.length) {
        q.action = 'update';
      }
    }
  });

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: { questions },
  });

  const { handleSubmit, register, control, setValue, getValues } = form;
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'questions',
    keyName: 'questionKeyId',
  });
  const testQuestionClassifications = survey.testQuestionClassification;

  const [activeQuestionMenu, setActiveQuestionMenu] = useState(-1);
  // TODO workaround for rerendering form
  const [render, setRender] = useState(false);
  const [isOpenModalClassification, setOpenModalClassification] = useState(false);
  const [editQuestionIndex, setEditQuestionIndex] = useState(-1);
  const [editQuestionClassifications, setEditQuestionClassification] = useState([]);

  useEffect(() => {
    props.setForm('questions', form);
  }, []);

  const onSubmit = (data) => {};

  const removeQuestion = (event, index) => {
    event.stopPropagation();
    if (fields[index].action !== 'delete' && fields[index].id) {
      setValue(`questions[${index}].action`, 'delete');
      fields[index].action = 'delete';
    } else {
      remove(index);
    }
    setActiveQuestionMenu(-1);
    setRender(!render);
  };

  const toggleQuestion = (event, index) => {
    setValue(`questions[${index}].isHidden`, !fields[index].isHidden);
    fields[index].isHidden = !fields[index].isHidden;
    setRender(!render);
  };

  const calcSortOrder = (objects) => {
    let nextOrder = 0;
    _.forEach(objects, (o) => {
      if (nextOrder < o.sortOrder) {
        nextOrder = o.sortOrder;
      }
    });
    nextOrder++;
    return nextOrder;
  };

  const addQuestion = (event) => {
    append({
      questionClassifications: [],
      action: 'create',
      question: '',
      questionType: '',
      answers: [],
      isHidden: false,
      sortOrder: calcSortOrder(fields),
    });
  };

  const [answersArray, setAnswersArray] = useState();

  const copyQuestion = (event, index) => {
    const values = getValues();
    const newQuestion = _.cloneDeep(values.questions ? values.questions[index] : {});

    newQuestion.answers &&
      newQuestion.answers.forEach((a) => {
        a.id = null;
        a.action = 'create';
        a.questionId = null;
        delete a.answerKeyId;
      });
    delete newQuestion.questionKeyId;
    append({
      ...newQuestion,
      id: null,
      action: 'create',
      isHidden: false,
      sortOrder: calcSortOrder(fields),
    });
    setValue(`questions[${fields.length}].answers`, newQuestion.answers, {
      shouldValidate: true,
    });
    setAnswersArray(newQuestion.answers);
    setActiveQuestionMenu(-1);
  };

  const closeClassificationModal = () => {
    setOpenModalClassification(false);
  };

  const addClassification = (editQuestionIndex, classifications) => {
    if (editQuestionIndex < 0) {
      return;
    }
    let editField = fields[editQuestionIndex];
    editField = {
      ...editField,
      action: editField && editField.id ? 'update' : 'create',
      questionClassifications: testQuestionClassifications.filter((v) =>
        classifications.includes(v.id),
      ),
    };
    remove(editQuestionIndex);
    insert(editQuestionIndex, editField);
    setOpenModalClassification(false);
  };

  const showAddClassification = (e, questionIndex) => {
    const { questionClassifications } = fields[questionIndex];
    setEditQuestionIndex(questionIndex);
    questionClassifications &&
      setEditQuestionClassification(questionClassifications.map((q) => q.id));
    setOpenModalClassification(true);
  };

  const setUpdateAction = (field, index) => {
    if (fields[index].action !== 'update' && fields[index].action !== 'delete') {
      fields[index].action = field.id ? 'update' : 'create';
    }
  };
  let questionCounter = 0;

  const changeQuestion = (index, fieldName, value, event, onChangeCallback) => {
    if (fields[index].action !== 'update' && fields[index].action !== 'delete') {
      fields[index].action = fields[index].id ? 'update' : 'create';
    }
    console.log(`change value to: ${value}`);
    fields[index][fieldName] = value;
    setValue(`questions[${index}].${fieldName}`, value);
    setRender(!render);
    onChangeCallback(event);
  };
  return (
    <>
      <div className="fm-wizard-element-title-container">
        <div className="fm-wizard-element-title">{t('components.questions')}</div>
      </div>
      <div
        className={classnames(
          'ibox-content',
          'clearfix',
          'fm-wizard-element-content-box',
          'fm-survey-summary-section-body',
        )}
      >
        <FormProvider {...form} className="row">
          <form onSubmit={handleSubmit(onSubmit)} className="fm-survey-questions">
            {fields.map((field, index) => {
              if (typeof field.questionClassifications === 'string') {
                field.questionClassifications = JSON.parse(field.questionClassifications);
              }
              if (field.action !== 'delete') {
                questionCounter++;
              }

              return (
                <div
                  key={`questions[${index}].questionKeyId`}
                  className={classnames(
                    'fm-question-container',
                    { 'fm-display-none': field.action === 'delete' },
                    { 'fm-question-expanded': !field.isHidden },
                  )}
                >
                  <div className="fm-question-dnd-control">
                    <span className="fm-question-drag-n-drop-control">
                      <FontAwesomeIcon icon={faBars} />
                    </span>
                  </div>
                  <div className="fm-question-info">
                    <div className="fm-question-fields">
                      <input
                        type="hidden"
                        name={`questions[${index}].id`}
                        ref={register()}
                        defaultValue={field.id}
                      />
                      <input
                        type="hidden"
                        name={`questions[${index}].tenantId`}
                        ref={register()}
                        defaultValue={field.tenantId}
                      />
                      <input
                        type="hidden"
                        name={`questions[${index}].systemType`}
                        ref={register()}
                        defaultValue={field.systemType}
                      />
                      <input
                        type="hidden"
                        name={`questions[${index}].action`}
                        ref={register()}
                        defaultValue={field.action}
                      />
                      <input
                        type="hidden"
                        name={`questions[${index}].createdByUserId`}
                        ref={register()}
                        defaultValue={field.createdByUserId}
                      />
                      <input
                        type="hidden"
                        name={`questions[${index}].sortOrder`}
                        ref={register()}
                        defaultValue={field.sortOrder}
                      />
                      <input
                        type="hidden"
                        name={`questions[${index}].questionClassifications`}
                        ref={register()}
                        defaultValue={JSON.stringify(field.questionClassifications)}
                      />
                      <input
                        type="hidden"
                        name={`questions[${index}].testId`}
                        ref={register()}
                        defaultValue={field.testId}
                      />
                      <input
                        type="hidden"
                        name={`questions[${index}].isHidden`}
                        ref={register()}
                        defaultValue={field.isHidden}
                      />
                      {field.isHidden && (
                        <>
                          <input
                            type="hidden"
                            name={`questions[${index}].answerRangeFrom`}
                            ref={register()}
                            defaultValue={isNaN(field.answerRangeFrom) ? 1 : field.answerRangeFrom}
                          />
                          <input
                            type="hidden"
                            name={`questions[${index}].answerRangeTo`}
                            ref={register()}
                            defaultValue={isNaN(field.answerRangeTo) ? 10 : field.answerRangeTo}
                          />
                        </>
                      )}

                      <Controller
                        control={form.control}
                        name={`questions[${index}].question`}
                        defaultValue={field.question}
                        render={({ onChange, onBlur, value, name }) => (
                          <TextArea
                            name={name}
                            placeholder={t('fields.question')}
                            label={t('fields.question')}
                            form={form}
                            rows={3}
                            maxLength="1024"
                            onBlur={(event) => {
                              fields[index].question = value;
                              onBlur(event);
                            }}
                            onChange={(event) => {
                              setUpdateAction(field, index);
                              onChange(event);
                            }}
                            value={value}
                            classNames={field.isHidden ? 'fm-display-none' : ''}
                          />
                        )}
                      />
                      <Controller
                        control={form.control}
                        name={`questions[${index}].questionType`}
                        defaultValue={field.questionType}
                        render={({ onChange, onBlur, value, name }) => (
                          <Select
                            name={name}
                            label={t('fields.question_type')}
                            placeholder={t('fields.select_question_type')}
                            form={form}
                            isClearable
                            options={questionTypes}
                            isMulti={false}
                            onBlur={onBlur}
                            onChange={(value) => {
                              if (
                                fields[index].action !== 'update' &&
                                fields[index].action !== 'delete'
                              ) {
                                fields[index].action = field.id ? 'update' : 'create';
                              }
                              fields[index].questionType = value;
                              setValue(`questions[${index}].questionType`, value);
                              setRender(!render);

                              onChange(value);
                            }}
                            value={value}
                            classNames={field.isHidden ? 'fm-display-none' : ''}
                          />
                        )}
                      />
                      {field.questionType && field.questionType.value !== 'Text' && (
                        <div className="form-element form-group" style={{ marginBottom: '5px' }}>
                          <label className="control-label">{t('fields.answers')}</label>
                        </div>
                      )}
                      {field.questionType && (
                        <SurveyQuestionAnswersForm
                          form={form}
                          testType={survey.testType}
                          calcSortOrder={calcSortOrder}
                          questionType={field.questionType.value}
                          answersArray={answersArray}
                          index={index}
                          isHidden={field.isHidden}
                          changeQuestion={changeQuestion}
                        />
                      )}
                    </div>
                    <div className="fm-question-text-box">
                      {field.isHidden &&
                        field.questionClassifications &&
                        field.questionClassifications.map((tag, tagIndex) => (
                          <div key={tagIndex} className="fm-cl-tag">
                            <span
                              className="fm-badge"
                              style={{ backgroundColor: tag.color }}
                              onClick={(e) => {
                                showAddClassification(e, index);
                              }}
                            >
                              {tag.name}
                            </span>
                          </div>
                        ))}
                      {!field.isHidden ? null : (
                        <>
                          {field && field.question && field.question.length > 0 ? (
                            <div className="fm-question-text">{field.question}</div>
                          ) : (
                            <div className="alert alert-warning fm-question-text">
                              <FontAwesomeIcon icon={faExclamationTriangle} />
                              {t('message.please_fill_required_fields')}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <div className="fm-question-controls">
                      <div
                        className="fm-question-control-visible"
                        onClick={(event) => toggleQuestion(event, index)}
                      >
                        <FontAwesomeIcon icon={field.isHidden ? faChevronRight : faChevronDown} />
                      </div>
                      <div className="fm-question-control-separator" />
                      <div
                        className="fm-question-control-visible"
                        onClick={(event) => showAddClassification(event, index)}
                      >
                        <FontAwesomeIcon icon={faHashtag} />
                      </div>
                      <div
                        className="fm-question-control-visible"
                        onClick={(event) => removeQuestion(event, index)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </div>
                      <div
                        className="fm-question-control-visible"
                        onClick={(event) => copyQuestion(event, index)}
                      >
                        <FontAwesomeIcon icon={faClone} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="fm-add-question-container">
              <div className="fm-add-question-box" onClick={(event) => addQuestion(event)}>
                <div className="fm-add-question-image">
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <div className="fm-add-question-label">{t('components.add_question')}</div>
              </div>
            </div>
          </form>
        </FormProvider>
        <Modal
          header={t('components.add_tag')}
          isOpened={isOpenModalClassification}
          icon={faHashtag}
          onClose={closeClassificationModal}
        >
          <AddClassificationToQuestionModal
            questionClassifications={testQuestionClassifications}
            editQuestionIndex={editQuestionIndex}
            addedClassifications={editQuestionClassifications}
            handleSubmit={(editQuestionIndex, classificationIds) => {
              addClassification(editQuestionIndex, classificationIds);
            }}
            onClose={closeClassificationModal}
          />
        </Modal>
      </div>
    </>
  );
}

InnerForm.propTypes = {
  questions: PropTypes.array.isRequired,
  survey: PropTypes.object.isRequired,
  questionTypes: PropTypes.array.isRequired,
};

export default withTranslation()(InnerForm);
