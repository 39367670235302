import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TextArea from '../react-hook-form-inputs/TextArea';
import Input from '../react-hook-form-inputs/Input';
import { getPermission, getPermissions } from '../../constants/permissions';
import MaterialCheckbox from '../react-hook-form-inputs/MaterialCheckbox';
import { saveRoleWithPermissions } from '../../services/user/userRoleProvider';
import './styles/user-role.css';
import Notifications from '../../utils/Notifications';
import Can from '../security/Can';
import CanSeeOption from '../security/CanSeeOption';
import { canSeeOption, hasPermission } from '../../utils/SecurityUtil';

function InnerForm(props) {
  const { t, userRole, userRolePermissions } = props;

  const form = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name: userRole.name ? userRole.name : '',
      description: userRole.description ? userRole.description : '',
      permissions: userRolePermissions,
    },
  });

  const account = useSelector((state) => state.account);

  const { handleSubmit } = form;

  const permissions = getPermissions();

  const keydownHandler = function (e) {
    // 83 - 's' letter
    if (e.keyCode === 83 && e.ctrlKey) {
      e.stopPropagation();
      e.preventDefault();
      handleSubmit((data) => onSubmit(data))();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler, false);

    return () => {
      document.removeEventListener('keydown', keydownHandler, false);
    };
  }, []);

  const onSubmit = (values) => {
    const _permissions = [];

    const permissionKeys = Object.keys(values.permissions);

    for (let i = 0; i < permissionKeys.length; i++) {
      if (values.permissions[permissionKeys[i]] === true) {
        _permissions.push(permissionKeys[i]);
      }
    }
    for (let i = 0; i < userRolePermissions.length; i++) {
      const notEditableForCurrentUser =
        getPermission(userRolePermissions[i]).hidden ||
        canSeeOption(account.permissions, userRolePermissions[i]);
      if (!_permissions.includes(userRolePermissions[i]) && notEditableForCurrentUser) {
        _permissions.push(userRolePermissions[i]);
      }
    }

    saveRoleWithPermissions({
      id: userRole.id,
      name: values.name,
      hidden: userRole.hidden,
      description: values.description,
      permissions: _permissions,
    })
      .then((data) => {
        Notifications.success(t('message.user_role_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const hasEverythingPermission = hasPermission(
    account.permissions,
    ['everything:everything'],
    true,
  );

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <div className="fm-user-role-box">
        <Controller
          control={form.control}
          name="name"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
              placeholder={t('fields.role_name')}
              form={form}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />

        <Controller
          control={form.control}
          name="description"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <TextArea
              placeholder={t('fields.description')}
              form={form}
              rows={2}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
      <div className="fm-user-permissions-box">
        {permissions &&
          permissions.map((m, index) => (
            <div key={m.id} className="fm-user-permission-module">
              {(!m.hidden || hasEverythingPermission) &&
                (m.canChoose || hasEverythingPermission ? (
                  <CanSeeOption
                    permission={m.id}
                    yes={() => (
                      <Controller
                        id={`permissions.${m.id}`}
                        tooltip={m.description}
                        control={form.control}
                        name={`permissions.${m.id}`}
                        defaultValue={false}
                        render={({ onChange, onBlur, value, name }) => (
                          <MaterialCheckbox
                            name={name}
                            checked={value}
                            label={t(m.name)}
                            form={form}
                            checkedElementColor="#34B77C"
                            onBlur={onBlur}
                            onChange={(e) => onChange(e.target.checked)}
                            value={value || false}
                          />
                        )}
                      />
                    )}
                    no={() => (
                      <div className="font-weight-bolder fm-security-module-label">{t(m.name)}</div>
                    )}
                  />
                ) : (
                  <div className="font-weight-bolder fm-security-module-label">{t(m.name)}</div>
                ))}
              <div className="fm-user-permission-container">
                {m.permissions &&
                  m.permissions.map(
                    (p, pIndex) =>
                      (!p.hidden || hasEverythingPermission) &&
                      (p.canChoose || hasEverythingPermission ? (
                        <div className="fm-user-permission-item" key={p.id}>
                          <Controller
                            id={`permissions.${p.id}`}
                            name={`permissions.${p.id}`}
                            tooltip={p.description}
                            control={form.control}
                            defaultValue={false}
                            render={({ onChange, onBlur, value, name }) => (
                              <MaterialCheckbox
                                name={name}
                                checked={value}
                                label={t(p.name)}
                                form={form}
                                checkedElementColor="#34B77C"
                                onBlur={onBlur}
                                onChange={(e) => onChange(e.target.checked)}
                                value={value || false}
                              />
                            )}
                          />
                        </div>
                      ) : null),
                  )}
              </div>
              {(!m.hidden || hasEverythingPermission) && (
                <div className="fm-security-module-divider" />
              )}
            </div>
          ))}
      </div>
      <div className="fm-user-role-footer">
        <Can
          module="user-roles"
          permission="edit"
          yes={() => (
            <button
              type="button"
              onClick={handleSubmit((data) => onSubmit(data))}
              className="btn fm-btn-primary"
            >
              {t('buttons.save')}
            </button>
          )}
          no={() => null}
        />
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  userRole: PropTypes.object.isRequired,
  userRolePermissions: PropTypes.object,
};

export default withTranslation()(InnerForm);
