import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AllQuestionClassificationTemplates from '../../containers/tests/globalquestionclassification/AllGlobalQuestionClassifications';
import EditQuestionClassificationPage from '../../containers/tests/globalquestionclassification/EditGlobalQuestionClassification';

import { AUTH_ROUTES, GLOBAL_QUESTION_CLASSIFICATION_ID } from '../../constants/routes';

class GlobalQuestionClassificationRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllQuestionClassificationTemplates} />
        <Route
          path={`${match.url}${GLOBAL_QUESTION_CLASSIFICATION_ID}`}
          render={({ match }) => <GlobalQuestionClassificationInnerRouting match={match} />}
        />
      </div>
    );
  }
}

GlobalQuestionClassificationRoutes.propTypes = {
  match: PropTypes.object,
};

export default GlobalQuestionClassificationRoutes;

class GlobalQuestionClassificationInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let questionClassificationRouting;
    if (match.params.globalQuestionClassificationId) {
      switch (match.params.globalQuestionClassificationId) {
        default:
          questionClassificationRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.GLOBAL_TEST.GLOBAL_QUESTION_CLASSIFICATION}${GLOBAL_QUESTION_CLASSIFICATION_ID}`}
              component={AllQuestionClassificationTemplates}
            />
          );
      }
    }

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.GLOBAL_TEST.GLOBAL_QUESTION_CLASSIFICATION}${GLOBAL_QUESTION_CLASSIFICATION_ID}/edit`}
          component={EditQuestionClassificationPage}
        />
        {questionClassificationRouting}
      </div>
    );
  }
}

GlobalQuestionClassificationInnerRouting.propTypes = {
  match: PropTypes.object,
};
