import React from 'react';
import PropTypes from 'prop-types';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import * as Yup from 'yup';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { faBuilding, faEnvelope, faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { isTenantExist } from '../../services/tenant/tenantProvider';
import { hasEmail, hasUsername } from '../../services/account/authProvider';
import MaterialCheckbox from '../react-hook-form-inputs/MaterialCheckbox';
import MaterialInput from '../react-hook-form-inputs/MaterialInput';
import Validation from '../../utils/validation';

function InnerForm(props) {
  const { t, tenant, changeLocation } = props;

  const schema = Yup.lazy((values) =>
    Yup.object().shape({
      tenantName: Yup.string()
        .required(t('message.required_field'))
        .test('checkTenantIsExist', t('message.tenant_not_exist'), (value) => {
          if (!value) {
            return null;
          }
          return new Promise((resolve) => {
            isTenantExist({ name: value })
              .then((data) => {
                resolve(data);
              })
              .catch(() => {
                resolve(false);
              });
          });
        }),
      username: Yup.string()
        .required(t('message.required_field'))
        .test('checkDuplicateUsername', t('message.username_already_in_use'), (value) => {
          if (!value) {
            return null;
          }
          return new Promise((resolve) => {
            hasUsername({
              tenant: values.tenantName,
              username: value,
            })
              .then((data) => {
                if (_.isEmpty(data)) {
                  resolve(true);
                } else {
                  resolve(false);
                }
              })
              .catch(() => {
                resolve(false);
              });
          });
        }),
      email: Yup.string()
        .email(t('message.invalid_email'))
        .required(t('message.required_field'))
        .test('checkDuplicateEmail', t('message.email_already_in_use'), (value) => {
          if (!value) {
            return null;
          }
          return new Promise((resolve) => {
            hasEmail({
              tenant: values.tenantName,
              email: value,
            })
              .then((data) => {
                if (_.isEmpty(data)) {
                  resolve(true);
                } else {
                  resolve(false);
                }
              })
              .catch(() => {
                resolve(false);
              });
          });
        }),
      password: Yup.string()
        .required(t('message.no_password_provided'))
        .matches(Validation.makePasswordRegExp(8), t('message.weak_password')),
      isAgree: Yup.boolean()
        .required(t('message.terms_must_accepted'))
        .oneOf([true], t('message.terms_must_accepted')),
    }),
  );

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { tenantName: tenant },
  });

  const _values = form.getValues();

  const { handleSubmit } = form;

  const onSubmit = (v) => {
    const user = {
      tenantName: v.tenantName,
      isAgree: v.isAgree,
      account: {
        username: v.username,
        email: v.email,
        password: v.password,
      },
    };
    props.registrationHandler(user, (v) => {
      form.reset(v);
    });
  };

  return (
    <div>
      <div className="fm-login-text text-center">
        <span>{t('components.welcome')}</span> <br />
        <span>{t('components.registration_message')}</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="m-t fm-auth-form">
        <div className="fm-auth-input-container">
          <div className="fm-element-form">
            <div className="fm-login-icon">
              <FontAwesomeIcon icon={faBuilding} />
            </div>
            <div className="fm-login-input">
              <Controller
                control={form.control}
                name="tenantName"
                id="tenantName"
                defaultValue=""
                render={({ onChange, onBlur, value, name }) => (
                  <MaterialInput
                    name={name}
                    placeholder={t('fields.tenant')}
                    label={t('fields.tenant')}
                    form={form}
                    type="text"
                    disabled={!!tenant}
                    onBlur={onBlur}
                    onChange={onChange}
                    autoComplete="tenantName"
                    value={value}
                  />
                )}
              />
            </div>
          </div>
          <div className="fm-element-form">
            <div className="fm-login-icon">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div className="fm-login-input">
              <Controller
                control={form.control}
                name="username"
                id="username"
                defaultValue=""
                render={({ onChange, onBlur, value, name }) => (
                  <MaterialInput
                    name={name}
                    placeholder={t('fields.username')}
                    label={t('fields.username')}
                    form={form}
                    type="text"
                    onBlur={onBlur}
                    onChange={onChange}
                    autoComplete="username"
                    value={value}
                  />
                )}
              />
            </div>
          </div>
          <div className="fm-element-form">
            <div className="fm-login-icon">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className="fm-login-input">
              <Controller
                control={form.control}
                name="email"
                id="email"
                defaultValue=""
                render={({ onChange, onBlur, value, name }) => (
                  <MaterialInput
                    name={name}
                    placeholder={t('fields.email')}
                    label={t('fields.email')}
                    form={form}
                    type="text"
                    onBlur={onBlur}
                    onChange={onChange}
                    autoComplete="email"
                    value={value}
                  />
                )}
              />
            </div>
          </div>

          <div className="fm-element-form">
            <div className="fm-login-icon">
              <FontAwesomeIcon icon={faLock} />
            </div>
            <div className="fm-login-input">
              <Controller
                control={form.control}
                name="password"
                id="password"
                defaultValue=""
                render={({ onChange, onBlur, value, name }) => (
                  <MaterialInput
                    name={name}
                    placeholder={t('fields.password')}
                    label={t('fields.password')}
                    form={form}
                    type="password"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="fm-auth-box">
          <div className="fm-check-me">
            <Controller
              id="isAgree"
              control={form.control}
              name="isAgree"
              defaultValue={false}
              render={({ onChange, onBlur, value }) => (
                <MaterialCheckbox
                  name="isAgree"
                  checked={value}
                  label={t('fields.agree_terms_and_policy')}
                  form={{
                    touched: form.touched,
                    errors: form.errors,
                  }}
                  checkedElementColor="#34B77C"
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleCheckedFilled />}
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.checked)}
                  value={value || false}
                />
              )}
            />
          </div>
        </div>
        <div className="fm-auth-box">
          <button type="submit" className="btn fm-btn-primary block m-b fm-login-btn">
            {t('buttons.register_now')}
          </button>
        </div>
        <div className="fm-auth-box fm-login-link">
          <button type="button" className="btn btn-link" onClick={() => changeLocation('/login')}>
            <small>{t('buttons.already_have_account')}</small>
          </button>
        </div>
        <div className="fm-login-footer-btns">
          <div className="fm-footer-login">
            <button type="button" onClick={() => changeLocation('/login', _values.tenantName)}>
              {t('buttons.log_in')}
            </button>
          </div>
          <div className="fm-footer-signin fm-f-btn-active">
            <button
              type="button"
              onClick={() => changeLocation('/registration', _values.tenantName)}
            >
              {t('buttons.sign_in')}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

InnerForm.propTypes = {
  t: PropTypes.func.isRequired,
  tenant: PropTypes.string,
  changeLocation: PropTypes.func.isRequired,
  registrationHandler: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
