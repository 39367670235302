import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SemiCircle } from 'react-es6-progressbar.js';
import './styles/charts.css';

class ProgressBarSemicircle extends Component {
  constructor(props) {
    super(props);
    this.state = { doughnutOptions: null };
  }

  componentDidMount() {
    const { dynamicHTML, averageColor } = this.props;
    this.setState({
      doughnutOptions: {
        text: {
          style: {
            color: '#1B2126',
            position: 'absolute',
            left: '50%',
            top: '50%',
            padding: 0,
            margin: 0,
            fontSize: '66px',
            fontWeight: 'bold',
            transform: {
              prefix: true,
              value: 'translate(-50%, -50%)',
            },
          },
        },
        svgStyle: {
          display: 'inline-block',
          width: '80%',
          overflow: 'overlay',
        },
        easing: 'bounce',
        duration: 2000,
        trailWidth: 6,
        strokeWidth: 8,
        step: (state, bar) => {
          let value = ((bar.value() * 100) / 20).toFixed(1);
          const x1 = 50 + -1 * Math.cos(2 * Math.PI + Math.PI * bar.value()) * 46;
          const y1 = 50 + Math.sin(2 * Math.PI - Math.PI * bar.value()) * 46;
          const _temp = bar.svg.getElementsByClassName('fm-circle-end');
          let circle = null;

          if (_temp && _temp.length > 0) {
            circle = _temp[0];
          } else {
            circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
            circle.classList.add('fm-circle-end');
            circle.setAttribute('r', '6');
            circle.setAttribute('style', `fill:white;stroke:${averageColor};stroke-width:3;`);
            bar.path.setAttribute('stroke', averageColor);
          }
          circle.setAttribute('cx', !Number.isNaN(x1) ? x1.toString() : 0);
          circle.setAttribute('cy', !Number.isNaN(y1) ? y1.toString() : 0);
          bar.svg.appendChild(circle);

          value = Math.abs(value).toFixed(1);

          if (dynamicHTML) {
            bar.setText(value + dynamicHTML);
          } else {
            bar.setText(value);
          }
        },
      },
    });
  }

  render() {
    const { progress } = this.props;
    const { doughnutOptions } = this.state;

    return (
      <div>
        {doughnutOptions != null ? (
          <SemiCircle
            container_class="fm-progress-bar"
            progress={progress}
            options={doughnutOptions}
            initialAnimate
          />
        ) : null}
      </div>
    );
  }
}

ProgressBarSemicircle.propTypes = {
  progress: PropTypes.number,
  dynamicHTML: PropTypes.string,
  averageColor: PropTypes.string,
};
export default ProgressBarSemicircle;
