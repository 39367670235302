import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faChevronDown,
  faChevronRight,
  faLink,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import moment from 'moment';
import './styles/survey-assignment-notification.css';
import { Bar } from 'react-chartjs-2';
import _ from 'lodash';
import chroma from 'chroma-js';
import LinkUtil from '../../utils/LinkUtil';

function SurveyAssignmentNotificationItem(props) {
  const { t, notification, completeNotification, markAsViewed, cancelNotification } = props;

  const [hidden, setHidden] = useState(true);
  const [isViewed, setViewed] = useState(notification.status === 'Viewed');
  const [isResolved, setResolved] = useState(false);
  const [options] = useState({
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 11,
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },

    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        formatter: () => null,
      },
      legend: {
        display: false,
      },
    },
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
  });
  const { settings } = notification;

  useEffect(() => {
    if (settings.chartData) {
      _.forEach(settings.chartData.datasets, (ds) => {
        const color = ds.color ? ds.color : 'red';
        ds.hoverBackgroundColor = chroma(color).alpha(0.3).css();
        ds.backgroundColor = chroma(color).alpha(0.3).css();
        ds.borderColor = color;
        ds.data = ds.data ? ds.data.map((v) => Number(v ? v.toFixed(1) : v)) : [];
      });
    }
  }, []);

  const collapseDescription = () => {
    setHidden(!hidden);
  };

  const onMouseOver = (e, notification) => {
    setViewed(true);
    markAsViewed(e, notification);
  };

  const onCompleteNotification = (e, notification) => {
    setResolved(true);
    completeNotification(e, notification);
  };

  const onCancelNotification = (e, notification) => {
    setResolved(true);
    cancelNotification(e, notification);
  };

  return (
    <>
      {!isResolved && (
        <div
          className={`-item ${!isViewed ? '-new' : ''}`}
          onMouseOver={(e) => onMouseOver(e, notification)}
        >
          <div className="-controls">
            <div className="-left">
              <div
                className="-resolve-btn"
                title={t('buttons.complete')}
                onClick={(e) => onCompleteNotification(e, notification)}
              >
                <FontAwesomeIcon icon={faCheck} />
              </div>
              <div
                className="-cancel-btn"
                title={t('buttons.cancel')}
                onClick={(e) => onCancelNotification(e, notification)}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
            <div className="-right">
              {settings.chartData &&
                settings.chartData.dataAvailable &&
                settings.chartData.labels && (
                  <div className="-collapse-btn" onClick={collapseDescription}>
                    <FontAwesomeIcon icon={hidden ? faChevronRight : faChevronDown} />
                  </div>
                )}
              {notification.targetId && (
                <div className="-link-btn" title={t('buttons.link')}>
                  {LinkUtil.buildTestAssignmentSummaryLink(
                    null,
                    notification.targetId,
                    <FontAwesomeIcon icon={faLink} />,
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="-title">{notification.title}</div>
          <div className="-survey-name">{settings.surveyTitle} </div>
          <div className="-item-period-dates">
            {moment.utc(settings.startDate).format('LL')} -{' '}
            {moment.utc(settings.endDate).format('LL')}{' '}
          </div>
          {settings.chartData &&
            settings.chartData.dataAvailable &&
            settings.chartData.labels &&
            !hidden && (
              <div className="-chart-data">
                {settings.chartData &&
                  settings.chartData.labels &&
                  settings.chartData.labels.length > 0 && (
                    <Bar
                      data={settings.chartData}
                      height={15 * settings.chartData.labels.length + 24}
                      options={options}
                      type="bar"
                    />
                  )}
              </div>
            )}
        </div>
      )}
    </>
  );
}

SurveyAssignmentNotificationItem.propTypes = {
  t: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired,
  markAsViewed: PropTypes.func.isRequired,
  completeNotification: PropTypes.func.isRequired,
  cancelNotification: PropTypes.func.isRequired,
};

export default withTranslation()(SurveyAssignmentNotificationItem);
