import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { getFeedbackCriteriaAnswersByReviewerId } from '../../../services/feedbackcriteriaanswer/feedbackCriteriaAnswerProvider';
import Notifications from '../../../utils/Notifications';
import { getFeedbackCriteriaByFeedbackId } from '../../../services/feedbackcriteria/feedbackCriteriaProvider';
import Modal from '../../modal/Modal';
import CommentModal from './CommentModal';

import './styles/pass-feedback.css';
import NoDataForm from '../../form/NoDataForm';
import UserPhotoUtil from '../../../utils/UserPhotoUtil';
import FeedbackCriterion from './FeedbackCriterion';
import ContentLoader from '../../loader/ContentLoader';

function InnerForm(props) {
  const {
    t,
    setFeedbackAnswers,
    feedbackAssignmentReviewer,
    answerErrors,
    onClickPrevious,
    onClickNext,
    onClickSaveDraft,
    onClickCancel,
    onClickSave,
    hasPrevious,
    hasNext,
  } = props;

  const form = useForm();
  const { control, reset } = form;
  const { fields } = useFieldArray({
    control,
    name: 'criteria',
    keyName: 'criterionKeyId',
  });

  const [showCommentModal, setShowCommentModal] = useState(false);
  const [comment, setComment] = useState(null);
  const [historyList, setHistoryList] = useState([]);
  const [userPhoto, setUserPhoto] = useState(null);
  const [feedbackCriteriaId, setFeedbackCriteriaId] = useState(null);
  const [jobDisplayString, setJobDisplayString] = useState('');

  const [feedbackAssignmentState, setFeedbackAssignmentState] = useState({});

  useEffect(() => {
    UserPhotoUtil.getPhotoByUserId(
      feedbackAssignmentReviewer.feedbackAssignment.revieweeId,
      setUserPhoto,
    );
    const promises = [];
    promises.push(
      getFeedbackCriteriaByFeedbackId(feedbackAssignmentReviewer.feedbackAssignment.feedbackId),
    );
    promises.push(getFeedbackCriteriaAnswersByReviewerId(feedbackAssignmentReviewer.id));

    Promise.all(promises)
      .then(([criteria, answers]) => {
        let hasMain = false;
        let hasAdditional = false;
        const feedbackAnswers = [];
        _.forEach(criteria, (value) => {
          let a = answers.find((v) => v.feedbackCriteriaId === value.id);
          if (!a) {
            a = {
              tenantId: value.tenantId,
              feedbackCriteriaId: value.id,
              feedbackCriteriaDescriptionId: null,
              feedbackAssignmentReviewerId: feedbackAssignmentReviewer.id,
              value: 0,
              comment: null,
              action: 'create',
            };
          }
          a.isMain = value.isMain;
          feedbackAnswers.push(a);
          if (a.isMain) {
            hasMain = true;
          } else {
            hasAdditional = true;
          }
        });

        setFeedbackAssignmentState({
          hasMain,
          hasAdditional,
          criteria,
          feedback: feedbackAssignmentReviewer.feedbackAssignment.feedback,
          feedbackAnswers,
        });
        setFeedbackAnswers(feedbackAnswers);
      })
      .catch((error) => {
        Notifications.error(error);
      });
    setJobString();
  }, [feedbackAssignmentReviewer]);

  useEffect(() => {
    reset({ criteria: feedbackAssignmentState.criteria });
  }, [feedbackAssignmentState]);

  const setCriteriaDescription = (criterionId, cd) => {
    const index = feedbackAnswers.findIndex(
      (answer) => parseInt(answer.feedbackCriteriaId) === parseInt(criterionId),
    );
    const item = feedbackAnswers[index];
    item.feedbackCriteriaDescriptionId = cd ? cd.id : null;
    item.value = cd ? cd.value : 0;
    if (!item.action) {
      item.action = 'update';
    }

    feedbackAnswers.splice(index, 1, item);

    setFeedbackAnswers(feedbackAnswers);
  };
  const onClickComment = (feedbackCriteriaId) => {
    const answer = feedbackAnswers.find((v) => v.feedbackCriteriaId === feedbackCriteriaId);
    setFeedbackCriteriaId(feedbackCriteriaId);
    setComment(answer.comment);
    setShowCommentModal(true);
  };

  const addComment = (feedbackCriteriaId, comment) => {
    const { feedbackAnswers } = feedbackAssignmentState;
    const index = feedbackAnswers.findIndex(
      (answer) => parseInt(answer.feedbackCriteriaId) === parseInt(feedbackCriteriaId),
    );
    const item = feedbackAnswers[index];
    item.comment = comment;
    if (!item.action) {
      item.action = 'update';
    }
    feedbackAnswers.splice(index, 1, item);
    setFeedbackAnswers(feedbackAnswers);
    closeModal();
  };

  const closeModal = () => {
    setFeedbackCriteriaId(null);
    setComment(null);
    setShowCommentModal(false);
  };
  const setJobString = () => {
    let jobString = '';
    if (
      feedbackAssignmentReviewer.feedbackAssignment &&
      feedbackAssignmentReviewer.feedbackAssignment.revieweeEmployee
    ) {
      const employee = feedbackAssignmentReviewer.feedbackAssignment.revieweeEmployee;
      if (employee.employeeJob) {
        jobString = employee.employeeJob.displayString;
      }
      if (employee.employeeJobLevel) {
        if (jobString !== '') {
          jobString += ' ';
        }
        jobString += employee.employeeJobLevel.displayString;
      }
    }
    setJobDisplayString(jobString);
  };

  const { feedback, hasMain, hasAdditional, feedbackAnswers, criteria } = feedbackAssignmentState;

  return (
    <>
      {feedbackAssignmentState && (
        <FormProvider {...form} className="row">
          <form className="ibox fm-feedback-passing">
            <div className="ibox-title">
              {feedback && <span className="fm-form-name">{feedback.name}</span>}
            </div>
            {feedbackAssignmentReviewer && (
              <div className="fm-reviewee-box">
                <div className="fm-reviewee-container">
                  <span className="fm-reviewee-prefix">{t('components.feedback_for')} </span>
                  {userPhoto}
                  <div className="fm-user-info">
                    <span className="fm-user-name">
                      {feedbackAssignmentReviewer.feedbackAssignment.revieweeDisplayName}
                    </span>
                    <span className="fm-user-job">{jobDisplayString}</span>
                  </div>
                </div>
                {historyList && historyList.length > 0 && (
                  <div className="fm-reviewee-history">
                    <span>
                      {t('components.HISTORY')} <FontAwesomeIcon icon={faChevronDown} />
                    </span>
                  </div>
                )}
              </div>
            )}
            <div className="ibox-content fm-criteria-box">
              {feedbackAssignmentReviewer &&
                feedbackAssignmentReviewer.assignmentStatus === 'Draft' && (
                  <div className="alert alert-warning alert-dismissable">
                    {t('components.in_draft_mode')}
                  </div>
                )}
              <div className="row">
                <div className="col-lg-12">
                  {fields && feedbackAnswers && feedbackAnswers.length > 0 && (
                    <div>
                      {hasMain && (
                        <div>
                          <div className="fm-criteria-majority-label">
                            {t('components.MAIN_CRITERIA')}
                          </div>
                          {fields.map(
                            (criterion, index) =>
                              criterion.isMain && (
                                <FeedbackCriterion
                                  key={`fm-fb-main-criteria-${index}`}
                                  criterionIndex={index}
                                  hasError={answerErrors && answerErrors[index]}
                                  criterion={criterion}
                                  answer={feedbackAnswers[index]}
                                  onClickComment={onClickComment}
                                  readOnly={
                                    feedbackAssignmentReviewer &&
                                    (feedbackAssignmentReviewer.assignmentStatus === 'Finished' ||
                                      feedbackAssignmentReviewer.assignmentStatus === 'Rejected')
                                  }
                                  setCriteriaDescription={setCriteriaDescription}
                                />
                              ),
                          )}
                        </div>
                      )}
                      {hasAdditional && (
                        <div>
                          <div className="fm-criteria-majority-label">
                            {t('components.ADDITIONAL_CRITERIA')}
                          </div>
                          {fields.map(
                            (criterion, index) =>
                              !criterion.isMain && (
                                <FeedbackCriterion
                                  key={`fm-fb-additional-criteria-${index}`}
                                  criterionIndex={index}
                                  hasError={answerErrors && answerErrors[index]}
                                  criterion={criterion}
                                  answer={feedbackAnswers[index]}
                                  onClickComment={onClickComment}
                                  readOnly={
                                    feedbackAssignmentReviewer &&
                                    (feedbackAssignmentReviewer.assignmentStatus === 'Finished' ||
                                      feedbackAssignmentReviewer.assignmentStatus === 'Rejected')
                                  }
                                  setCriteriaDescription={setCriteriaDescription}
                                />
                              ),
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {!hasMain && !hasAdditional && criteria && criteria.size() === 0 && (
                    <NoDataForm />
                  )}
                  {!criteria && <ContentLoader visible />}
                </div>
              </div>
            </div>
            {feedbackAssignmentReviewer && (
              <div className="ibox-content fm-btn-box">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="btn-group pull-left">
                      {hasPrevious && (
                        <button
                          onClick={onClickPrevious}
                          type="button"
                          className="btn fm-btn-secondary"
                        >
                          {t('buttons.previous')}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="btn-group pull-right">
                      <button
                        onClick={onClickCancel}
                        type="button"
                        className="btn fm-btn-secondary"
                      >
                        {t('buttons.cancel')}
                      </button>
                      {feedbackAssignmentReviewer.assignmentStatus !== 'Finished' &&
                        feedbackAssignmentReviewer.assignmentStatus !== 'Rejected' && (
                          <>
                            <button
                              onClick={onClickSaveDraft}
                              type="button"
                              className="btn fm-btn-secondary"
                            >
                              {t('buttons.draft')}
                            </button>
                            <button
                              onClick={onClickSave}
                              type="button"
                              className="btn fm-btn-primary"
                            >
                              {t('buttons.save')}
                            </button>
                          </>
                        )}
                      {(feedbackAssignmentReviewer.assignmentStatus === 'Finished' ||
                        feedbackAssignmentReviewer.assignmentStatus === 'Expired' ||
                        feedbackAssignmentReviewer.assignmentStatus === 'Rejected') &&
                        hasNext && (
                          <button
                            onClick={onClickNext}
                            type="button"
                            className="btn fm-btn-secondary"
                          >
                            {t('buttons.next')}
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </FormProvider>
      )}
      <Modal
        header={t('components.criterion_comment')}
        isOpened={showCommentModal}
        icon={faComment}
        onClose={closeModal}
      >
        <CommentModal
          addComment={addComment}
          comment={comment}
          feedbackCriteriaId={feedbackCriteriaId}
          cancel={closeModal}
          readOnly={
            feedbackAssignmentReviewer &&
            (feedbackAssignmentReviewer.assignmentStatus === 'Finished' ||
              feedbackAssignmentReviewer.assignmentStatus === 'Rejected')
          }
        />
      </Modal>
    </>
  );
}

InnerForm.propTypes = {
  feedbackAssignmentReviewer: PropTypes.object.isRequired,
  setFeedbackAnswers: PropTypes.func.isRequired,
  feedbackAnswers: PropTypes.array.isRequired,
  answerErrors: PropTypes.object,
  onClickCancel: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onClickSaveDraft: PropTypes.func.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  hasNext: PropTypes.bool.isRequired,
};

export default withTranslation()(InnerForm);
