import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { connect } from 'react-redux';
import SurveyAssignmentToDo from './SurveyAssignmentNotificationItem';
import Notifications from '../../utils/Notifications';
import {
  getNotificationItemsByUserIdAndStatuses,
  updateNotificationItem,
} from '../../services/notificationitems/notificationItemsProvider';

function NotificationBell(props) {
  const { t, userId } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [notificationInfo, setNotificationInfo] = useState({
    items: [],
    hasNewNotifications: false,
    hasItems: false,
  });
  const onClickNotificationBell = (event) => {
    if (event.target.closest('.fm-notification-items')) {
      return;
    }
    setShowMenu(!showMenu);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      setShowMenu(false);
    }
  };

  const handleHideMenu = (event) => {
    event.stopPropagation();
    if (
      event.target.classList.contains('fm-notification-items-btn') ||
      event.target.closest('.fm-notification-items-btn')
    ) {
      return;
    }
    setShowMenu(false);
  };
  const markAsViewed = (e, notification) => {
    if (notification.status === 'New') {
      notification.status = 'Viewed';
      updateNotificationItem(notification)
        .then(() => {
          populateNotifications();
        })
        .catch((error) => {
          Notifications.error(error);
        });
    }
  };
  const resolveNotification = (e, notification, newStatus) => {
    notification.status = newStatus;
    notification.resolved = true;
    if (notificationInfo.items && notificationInfo.items.length === 1) {
      setNotificationInfo({
        items: [],
        hasNewNotifications: false,
        hasItems: false,
      });
    }
    updateNotificationItem(notification)
      .then(() => {
        populateNotifications();
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const populateNotifications = (interval) => {
    let retryNumber = 0;
    getNotificationItemsByUserIdAndStatuses(userId, ['New', 'Viewed'])
      .then((data) => {
        _.each(data, (item) => {
          item.resolved = false;
        });
        setNotificationInfo({
          items: data,
          hasNewNotifications: data.some((e) => e.status === 'New'),
          hasItems: data.some((e) => !e.resolved),
        });
      })
      .catch((error) => {
        if (retryNumber > 3) {
          clearInterval(interval);
          Notifications.error(error);
        }
        retryNumber++;
      });
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleHideMenu);
    document.addEventListener('keydown', handleKeyPress);
    populateNotifications();

    const interval = setInterval(() => {
      populateNotifications(interval);
    }, 60 * 1000);

    return () => {
      document.removeEventListener('mousedown', handleHideMenu);
      document.removeEventListener('keydown', handleKeyPress);
      clearInterval(interval);
    };
  }, []);

  const renderNotificationItem = (notificationItem, index) => {
    switch (notificationItem.type) {
      case 'test-assignment-period:finished':
        return (
          <SurveyAssignmentToDo
            key={`notification-item-${notificationItem.id}`}
            notification={notificationItem}
            markAsViewed={markAsViewed}
            cancelNotification={(e, notification) =>
              resolveNotification(e, notification, 'Canceled')
            }
            completeNotification={(e, notification) =>
              resolveNotification(e, notification, 'Completed')
            }
          />
        );
    }
  };

  return (
    <div className="fm-header-btn fm-notification-items-btn" onClick={onClickNotificationBell}>
      {notificationInfo.hasNewNotifications && <div className="fm-notification-active" />}
      <FontAwesomeIcon icon={faBell} />
      {showMenu && (
        <div className="fm-notification-items animated fadeIn">
          {notificationInfo.items.length === 0 || !notificationInfo.hasItems ? (
            <div className="-item -empty">{t('components.no_notifications_found')}</div>
          ) : (
            _.map(notificationInfo.items, (notificationItem, k) =>
              renderNotificationItem(notificationItem, k),
            )
          )}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userId: state.account.userId,
});

export default withTranslation()(connect(mapStateToProps)(NotificationBell));
