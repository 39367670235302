import dataProvider from '../dataProvider';

import { notificationItems } from '../../constants/endpoints';

export const getNotificationItemsList = (params) =>
  dataProvider({
    url: notificationItems.all(),
    params,
    method: 'GET',
  });

export const getNotificationItem = (notificationItemId) =>
  dataProvider({
    url: notificationItems.getById(notificationItemId),
    method: 'GET',
  });

export const updateNotificationItem = (params) =>
  dataProvider({
    url: notificationItems.update(params.id),
    data: params,
    method: 'PUT',
  });

export const createNotificationItem = (params) =>
  dataProvider({
    url: notificationItems.create(),
    data: params,
    method: 'POST',
  });

export const deleteNotificationItem = (notificationItemId) =>
  dataProvider({
    url: notificationItems.delete(notificationItemId),
    method: 'DELETE',
  });

export const getNotificationItemsByUserIdAndStatuses = (notificationItemId, statuses) =>
  dataProvider({
    url: notificationItems.getByUserId(notificationItemId),
    params: { statuses },
    method: 'GET',
  });
