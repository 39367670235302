import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import PageNameContainer from '../../components/page/PageNameContainer';
import EditUserGroupForm from '../../components/user/EditUserGroupForm';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import {
  getUserGroup,
  getUserGroupUsers,
  updateUserGroup,
} from '../../services/user/userGroupProvider';
import FormTitle from '../../components/form/FormTitle';
import Can from '../../components/security/Can';
import EditUserGroupUsersForm from '../../components/user/EditUserGroupUsersForm';

class EditUserGroupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      groupMembers: null,
    };
    this.validationArray = [];
    this.submitionArray = [];
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_user_groups'),
        ref: '/user-groups',
        isActive: false,
      },
      {
        name: t('breadcrumbs.user_group'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { params } = this.props.match;
    this.setState({ isLoading: true });
    this.getUserGroupAndUserGroupUsers(params.userGroupId, null);
  }

  getUserGroupAndUserGroupUsers = (userGroupId, successMsg) =>
    Promise.all([getUserGroup(userGroupId), getUserGroupUsers(userGroupId)])
      .then(([userGroup, userGroupUsers]) => {
        this.setState({
          userGroup,
          groupMembers: userGroupUsers,
          isLoading: false,
        });
        if (successMsg) {
          Notifications.success(successMsg);
        }
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });

  addValidation = (validation) => {
    this.validationArray.push(validation);
  };

  addSubmition = (submition) => {
    this.submitionArray.push(submition);
  };

  handleSubmitForm = () => {
    const fArray = [];
    _.forEach(this.validationArray, (func) => {
      fArray.push(func());
    });

    Promise.all(fArray).then((arr) => {
      let hasError = false;
      _.forEach(arr, (result) => {
        if (!_.isEmpty(result)) {
          hasError = true;
        }
      });
      if (!hasError) {
        _.forEach(this.submitionArray, (s) => s());
      }
    });
  };

  setUserGroupRef = (userGroupRef) => {
    this.setState({ userGroupRef });
  };

  updateUserGroup = (values) => {
    this.setState({ isLoading: true });
    const { t } = this.props;
    const { userGroup } = this.state;
    values.id = userGroup.id;

    updateUserGroup(values)
      .then((_userGroupId) => {
        this.getUserGroupAndUserGroupUsers(_userGroupId, t('message.user_group_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { userGroup, isLoading, groupMembers } = this.state;

    const { t } = this.props;
    return (
      <div>
        <div>
          <div className="row wrapper">
            <div className="col-lg-12">
              <PageNameContainer
                name={t('containers.user_group')}
                breadcrumbs={this.getBreadcrumbs()}
              />
            </div>
          </div>
          <div className="wrapper-content">
            <div className="row">
              <div className="col-md-6">
                <div className="ibox">
                  <FormTitle name={t('components.user_group')} />
                  <div className="ibox-content clearfix">
                    {userGroup && (
                      <EditUserGroupForm
                        userGroup={userGroup}
                        updateUserGroup={this.updateUserGroup}
                        setUserGroupRef={this.setUserGroupRef}
                        addValidation={this.addValidation}
                        addSubmition={this.addSubmition}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="ibox">
                  <FormTitle name={t('components.group_members')} />
                  <div className="ibox-content clearfix">
                    {groupMembers && (
                      <EditUserGroupUsersForm
                        userGroup={userGroup}
                        groupMembers={groupMembers}
                        addSubmition={this.addSubmition}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="footer fixed">
              <div className="btn-group pull-right" role="group">
                <Can
                  module="user-groups"
                  permission="edit"
                  yes={() => (
                    <button
                      onClick={this.handleSubmitForm}
                      type="button"
                      className="btn fm-btn-primary btn-sm"
                    >
                      {t('buttons.save')}
                    </button>
                  )}
                  no={() => null}
                />
              </div>
            </div>
            {isLoading && <Loader />}
          </div>
        </div>
      </div>
    );
  }
}

EditUserGroupPage.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userGroupId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withTranslation()(withRouter(EditUserGroupPage));
