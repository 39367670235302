import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { canSeeOption } from '../../utils/SecurityUtil';

function CanSeeOption(props) {
  return canSeeOption(props.account.permissions, props.permission) ? props.yes() : props.no();
}

CanSeeOption.defaultProps = {
  yes: () => null,
  no: () => null,
};

CanSeeOption.propTypes = {
  permission: PropTypes.string.isRequired,
};

export default connect((state) => ({
  account: state.account,
}))(CanSeeOption);
