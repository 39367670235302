import React, { Component } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TableLoader from './TableLoader';
import NoDataComponent from './NoDataTableComponent';

class PaginationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      defaultPageSize: props.defaultPageSize ? props.defaultPageSize : 10,
      columns: props.columns,
      minRows: 0,
      pageSizeOptions: props.pageSizeOptions ? props.pageSizeOptions : [5, 10, 20, 25, 50, 100],
      getDataFromServer: props.getDataFromServer,
      defaultSorted: props.defaultSorted,
      loading: false,
      pages: 0,
      showPagination: props.showPagination != null ? props.showPagination : true,
      rowHeight: props.rowHeight ? props.rowHeight : 30,
    };
  }

  updateTableData = (isRemove) => {
    if (isRemove) {
      const { data, pages } = this.state;
      if (data.length === 1 && pages > 1) {
        const { page } = this.refReactTable.state;
        this.setState({ pages: pages - 1 }, () => {
          this.refReactTable.onPageChange(page - 1);
          this.refReactTable.fireFetchData();
        });
        return;
      }
    }
    this.refReactTable.fireFetchData();
  };

  render() {
    const {
      data,
      defaultPageSize,
      columns,
      minRows,
      loading,
      getDataFromServer,
      pages,
      defaultSorted,
      rowHeight,
      showPagination,
    } = this.state;
    const { t, className } = this.props;
    return (
      <ReactTable
        ref={(refReactTable) => {
          this.refReactTable = refReactTable;
        }}
        rowHeight={200}
        data={data}
        columns={columns}
        defaultPageSize={defaultPageSize}
        minRows={minRows}
        loading={loading}
        showPagination={showPagination && !!(data && data.length > 0)}
        showPaginationTop={false}
        showPaginationBottom={showPagination && !!(data && data.length > 0)}
        pages={pages}
        previousText={t('tables.previous')}
        nextText={t('tables.next')}
        // noDataText={ t('tables.empty_list') }
        NoDataComponent={NoDataComponent}
        LoadingComponent={TableLoader}
        defaultSorted={defaultSorted}
        onFetchData={(state, instance) => {
          if (!getDataFromServer) {
            return;
          }
          this.setState({ loading: true });
          getDataFromServer(
            state.pageSize,
            state.page * state.pageSize,
            state.sorted,
            state.filtered,
            (res) => {
              this.setState({
                data: res.items,
                pages: Math.ceil(parseFloat(res.totalSize) / parseFloat(state.pageSize)),
                loading: false,
                test: !this.state.test,
              });
              instance.onPageChange();
            },
          );
        }}
        manual
        className={className}
      />
    );
  }
}

PaginationTable.propTypes = {
  columns: PropTypes.array.isRequired,
  getDataFromServer: PropTypes.func.isRequired,
  defaultPageSize: PropTypes.number,
  minRows: PropTypes.number,
  defaultSorted: PropTypes.array,
  t: PropTypes.func.isRequired,
};

export default withTranslation(null, { withRef: true })(PaginationTable);
