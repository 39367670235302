import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import FormTitle from '../form/FormTitle';
import DatePicker from '../react-hook-form-inputs/DatePicker';
import { getExcludeDates } from '../../services/assignmentperiod/assignmentPeriodProvider';
import Notifications from '../../utils/Notifications';
import Can from '../security/Can';
import TextArea from '../react-hook-form-inputs/TextArea';
import Toggle from '../react-hook-form-inputs/Toggle';

function InnerForm(props) {
  const { updateAssignmentPeriod, assignmentPeriod, t } = props;

  const [startDate, setStartDate] = useState(
    assignmentPeriod.startDate ? new Date(moment.utc(assignmentPeriod.startDate)) : '',
  );
  const [endDate, setEndDate] = useState(
    assignmentPeriod.endDate ? new Date(moment.utc(assignmentPeriod.endDate)) : '',
  );
  const [excludedPeriodsAndDates, setExcludedPeriodsAndDates] = useState(null);

  const schema = Yup.object().shape({
    showResultToUser: Yup.bool().nullable(),
    startDate: Yup.date().required(t('message.required_field')),
    endDate: Yup.date().required(t('message.required_field')),
  });

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      welcomeText: assignmentPeriod.welcomeText ? assignmentPeriod.welcomeText : '',
      startDate: assignmentPeriod.startDate ? new Date(moment.utc(assignmentPeriod.startDate)) : '',
      showResultToUser: assignmentPeriod.showResultToUser,
      endDate: assignmentPeriod.endDate ? new Date(moment.utc(assignmentPeriod.endDate)) : '',
      description: assignmentPeriod.description ? assignmentPeriod.description : '',
    },
  });
  const { handleSubmit, control } = form;

  const getMinDate = () => {
    const { excludePeriods } = excludedPeriodsAndDates;
    const currentDateMoment = new Date(moment.utc());
    if (!excludePeriods || !endDate) {
      return currentDateMoment;
    }
    const endDateMoment = new Date(moment.utc(endDate));
    for (let i = excludePeriods.length - 1; i >= 0; i--) {
      const excludePeriodEndDate = excludePeriods[i].endDate;
      if (excludePeriodEndDate < endDateMoment) {
        if (excludePeriodEndDate < currentDateMoment) {
          return currentDateMoment;
        }
        return excludePeriodEndDate;
      }
    }
    return currentDateMoment;
  };

  const getMaxDate = () => {
    const { excludePeriods } = excludedPeriodsAndDates;
    if (!excludePeriods || !startDate) {
      return null;
    }
    const startDateMoment = new Date(moment.utc(startDate));
    for (let i = 0; i < excludePeriods.length; i++) {
      if (startDateMoment < excludePeriods[i].startDate) {
        return excludePeriods[i].startDate;
      }
    }
    return null;
  };

  const _getExcludeDates = () => {
    const settings = {
      ...assignmentPeriod.settings,
      type: assignmentPeriod.type.value,
      testId: assignmentPeriod.testId.toString(),
    };
    __getExcludeDates(settings);
  };

  const __getExcludeDates = (settings) => {
    getExcludeDates(settings)
      .then((data) => {
        const startDateMoment = new Date(moment.utc(assignmentPeriod.startDate));
        const endDateMoment = new Date(moment.utc(assignmentPeriod.endDate));
        data.excludeDates = data.excludeDates.map((d) => {
          const excludeDate = new Date(moment.utc(d));
          if (excludeDate < startDateMoment || excludeDate > endDateMoment) {
            return excludeDate;
          }
        });
        data.excludePeriods = data.excludePeriods
          .map((d) => {
            const excludePeriodStartDateMoment = new Date(moment.utc(d.startDate));
            const excludePeriodEndDateMoment = new Date(moment.utc(d.endDate));
            if (
              excludePeriodStartDateMoment.getTime() !== startDateMoment.getTime() &&
              excludePeriodEndDateMoment.getTime() !== endDateMoment.getTime()
            ) {
              return {
                startDate: excludePeriodStartDateMoment,
                endDate: excludePeriodEndDateMoment,
              };
            }
          })
          .filter((d) => d !== undefined);
        setExcludedPeriodsAndDates(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  const onSubmit = (data) => {
    const params = {
      ...assignmentPeriod,
      welcomeText: data.welcomeText,
      startDate: data.startDate,
      endDate: data.endDate,
      showResultToUser: data.showResultToUser,
      description: data.description,
    };
    updateAssignmentPeriod(params);
  };

  assignmentPeriod && !excludedPeriodsAndDates && _getExcludeDates();

  return (
    <div className="ibox">
      <FormTitle name={t('components.settings')} />
      <div className="ibox-content clearfix">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <Controller
            control={control}
            id="description"
            name="description"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <TextArea
                name={name}
                placeholder={t('fields.description')}
                label={t('fields.description')}
                form={form}
                rows={2}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />

          <Controller
            control={control}
            id="welcomeText"
            name="welcomeText"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <TextArea
                name={name}
                placeholder={t('fields.welcome_text')}
                label={t('fields.welcome_text')}
                form={form}
                rows={2}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Controller
            id="showResultToUser"
            control={control}
            name="showResultToUser"
            defaultValue={false}
            render={({ onChange, onBlur, value, name }) => (
              <Toggle
                name={name}
                checked={value}
                label={t('fields.show_result_to_user')}
                toggleSize={6}
                form={{
                  touched: form.touched,
                  errors: form.errors,
                }}
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.checked)}
                value={value || false}
              />
            )}
          />
          {excludedPeriodsAndDates != null && excludedPeriodsAndDates.excludeDates != null && (
            <Controller
              control={form.control}
              id="startDate"
              name="startDate"
              defaultValue=""
              render={({ onChange, onBlur, value, name }) => (
                <DatePicker
                  name={name}
                  label={t('fields.start_date')}
                  onChange={(object) => {
                    onChange(object);
                    if (object) {
                      setStartDate(object);
                    } else {
                      setStartDate(null);
                      setEndDate(null);
                    }
                  }}
                  form={form}
                  excludeDates={excludedPeriodsAndDates.excludeDates}
                  onBlur={onBlur}
                  value={value}
                  placeholderText={t('fields.date_format_mm_dd_yyyy')}
                  minDate={getMinDate()}
                  maxDate={endDate}
                  autoComplete="off"
                />
              )}
            />
          )}
          {excludedPeriodsAndDates != null && excludedPeriodsAndDates.excludeDates != null && (
            <Controller
              control={form.control}
              id="endDate"
              name="endDate"
              defaultValue=""
              render={({ onChange, onBlur, value, name }) => (
                <DatePicker
                  name={name}
                  label={t('fields.end_date')}
                  onChange={(object) => {
                    onChange(object);
                    if (object) {
                      setEndDate(object);
                    } else {
                      setEndDate(null);
                    }
                  }}
                  form={form}
                  excludeDates={excludedPeriodsAndDates.excludeDates}
                  onBlur={onBlur}
                  value={value}
                  placeholderText={t('fields.date_format_mm_dd_yyyy')}
                  minDate={startDate}
                  maxDate={getMaxDate()}
                  autoComplete="off"
                />
              )}
            />
          )}

          <Can
            module="test-assignment-periods"
            permission="edit"
            yes={() => (
              <button type="submit" className="btn fm-btn-primary pull-right block">
                {t('buttons.save')}
              </button>
            )}
            no={() => null}
          />
        </form>
      </div>
    </div>
  );
}

InnerForm.propTypes = {
  assignmentPeriod: PropTypes.object,
  updateAssignmentPeriod: PropTypes.func,
};

export default withTranslation()(InnerForm);
