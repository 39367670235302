import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import '../styles/validation-results.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ValidationResultTable from './ValidationResultTable';

class ValidationResultBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  switchExpanded = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  render() {
    const { containerClass, blockTitle, resultRows, columnTitlePrefix, isRowIdRequired } =
      this.props;
    const { isExpanded } = this.state;

    return (
      <div className={`validation-result-container ${containerClass}`}>
        <div className="validation-result-section-header" onClick={this.switchExpanded}>
          <div className="validation-result-title">{blockTitle}</div>
          <div className="validation-result-section-controls">
            <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />
          </div>
        </div>
        {isExpanded && (
          <ValidationResultTable
            content={resultRows}
            containerClass={containerClass}
            columnTitlePrefix={columnTitlePrefix}
            isRowIdRequired={isRowIdRequired}
          />
        )}
      </div>
    );
  }
}

ValidationResultBlock.propTypes = {
  resultRows: PropTypes.array.isRequired,
  containerClass: PropTypes.string.isRequired,
  blockTitle: PropTypes.string,
  columnTitlePrefix: PropTypes.string,
  isRowIdRequired: PropTypes.bool,
};

export default withTranslation()(ValidationResultBlock);
