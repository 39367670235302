import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import Can from '../security/Can';

class EditDeleteActionsRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.original.id,
      securityModule: props.column.securityModule,
      securityDeletePermission: props.column.securityDeletePermission,
      securityViewPermission: props.column.securityViewPermission,
    };
    this.onDeleteItem = props.column.onDeleteItem;
    this.onViewItem = props.column.onViewItem;
  }

  deleteItem = () => {
    this.onDeleteItem(this.state.id);
    document.activeElement?.blur();
  };

  viewItem = () => {
    this.onViewItem(this.state.id);
  };

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  render() {
    const { securityModule, securityDeletePermission, securityViewPermission } = this.state;
    const { t } = this.props;
    return (
      <div className="fm-btn-group">
        {this.onViewItem && (
          <Can
            module={securityModule}
            permission={securityViewPermission || 'edit'}
            yes={() => (
              <button
                className="btn fm-table-btn fm-btn-xs-edit"
                onClick={this.viewItem}
                title={t('tables.edit')}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            )}
            no={() => null}
          />
        )}

        {this.onDeleteItem && (
          <Can
            module={securityModule}
            permission={securityDeletePermission || 'delete'}
            yes={() => (
              <button
                className="btn fm-table-btn fm-btn-xs-delete"
                onClick={this.deleteItem}
                title={t('tables.delete')}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            )}
            no={() => null}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(EditDeleteActionsRenderer);
