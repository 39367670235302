import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faBell } from '@fortawesome/free-solid-svg-icons';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';

function InnerForm(props) {
  const { insights, t } = props;

  let topPositionColor = 'fm-good';
  let topPositionMessage = t('message.you_are_awesome');

  if (
    insights &&
    insights.feedbackNumberTopPosition >= 40 &&
    insights.feedbackNumberTopPosition < 70
  ) {
    topPositionColor = 'fm-warning';
    topPositionMessage = t('message.keep_it_up');
  } else if (insights && insights.feedbackNumberTopPosition >= 70) {
    topPositionColor = 'fm-alert';
    topPositionMessage = t('message.you_can_do_better');
  }

  return (
    <>
      {insights && (
        <div className="row wrapper">
          {insights.ongoingFeedbackCount && insights.ongoingFeedbackCount > 0 ? (
            <div className="col-lg-4">
              <div className="fm-home-insight">
                <FontAwesomeIcon size="4x" icon={faAward} className={topPositionColor} />
                <span className="fm-home-insight-advice">
                  {t('message.you_are_in_the')}{' '}
                  <span className={topPositionColor}>
                    <b>{Math.round(insights.feedbackNumberTopPosition)}%</b>
                  </span>{' '}
                  {t('message.most_active_employees_who_give_feedback')} {topPositionMessage}
                </span>
              </div>
            </div>
          ) : null}
          {insights.ongoingFeedbackCount && insights.ongoingFeedbackCount === 0 ? (
            <div className="col-lg-4">
              <div className="fm-home-insight">
                <FontAwesomeIcon size="4x" icon={faLightbulb} className="fm-warning" />
                <span className="fm-home-insight-advice">
                  {t('message.the_best_feedback_is_sincerely_and_honestly_provided_to_help')}
                </span>
              </div>
            </div>
          ) : null}
          {insights.ongoingFeedbackCount && insights.surveyAssignmentCount === 0 ? (
            <div className="col-lg-4">
              <div className="fm-home-insight">
                <FontAwesomeIcon size="4x" icon={faBell} className="fm-alert" />
                <span className="fm-home-insight-advice">
                  {t('message.you_have_not_taken_any_surveys_more_then')}
                  <span className="fm-alert">
                    <b>3</b>
                  </span>
                  {t('message.month_ask_your_hrs_about_it')}
                </span>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}

InnerForm.propTypes = {};

export default withTranslation()(InnerForm);
