import React from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import { Form } from 'react-bootstrap';

import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import InputComponent from '../inputs/Input';
import TextareaComponent from '../inputs/Textarea';

function InnerForm(props) {
  const { t, handleSubmit, cancel } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <div className="modal-body">
        <Field
          component={InputComponent}
          placeholder={t('fields.group_name')}
          type="text"
          name="name"
          label={t('fields.group_name')}
        />

        <Field
          component={TextareaComponent}
          placeholder={t('fields.description')}
          name="description"
          type="text"
          label={t('fields.description')}
          rows={5}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
        <button type="submit" className="btn fm-btn-primary">
          {t('buttons.create')}
        </button>
      </div>
    </Form>
  );
}

InnerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  createUserGroup: PropTypes.func.isRequired,
};

const CreateUserGroupModal = withFormik({
  mapPropsToValues: () => ({
    name: '',
    description: '',
  }),
  validationSchema: (props) => {
    const { t } = props;
    return Yup.object().shape({
      name: Yup.string().required(t('message.required_field')),
    });
  },
  handleSubmit: (values, props) => {
    props.props.createUserGroup({
      name: values.name,
      description: values.description,
    });
  },
})(InnerForm);

export default withTranslation()(CreateUserGroupModal);
