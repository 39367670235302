import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import LoginComponent from './LoginComponent';
import RegistrationComponent from './RegistrationComponent';
import ResetPasswordComponent from './ResetPasswordComponent';
import FormeasureLogo from '../../styles/img/logo_formeasure@2x.png';
import './styles/auth-form.css';
import { withRouter } from 'react-router-dom';

function InnerForm(props) {
  const { t, tenant, loginHandler, registrationHandler, authState, history, lastTenant } = props;

  const changeLocation = (location, tenant) => {
    const link = location + (tenant ? `/${tenant}` : '');
    history.push(link);
  };

  return (
    <div className="fm-auth-form-container">
      <div className="fm-auth-left-part">
        <div className="fm-auth-logo text-center">
          <img src={FormeasureLogo} />
        </div>
        <div className="fm-line-2" />
        {authState && authState.startsWith('/login') && (
          <LoginComponent
            tenant={tenant}
            lastTenant={lastTenant}
            loginHandler={loginHandler}
            changeLocation={changeLocation}
          />
        )}
        {authState && authState.startsWith('/registration') && (
          <RegistrationComponent
            tenant={tenant}
            registrationHandler={registrationHandler}
            changeLocation={changeLocation}
          />
        )}
        {authState && authState.startsWith('/reset-password') && (
          <ResetPasswordComponent changeLocation={changeLocation} authState={authState} />
        )}
      </div>
      <div className="fm-login-additional-text">
        <div className="fm-login-additional-label">{t('components.enterprise_edition')}</div>
        <div className="fm-login-additional-description">{t('components.mission_message')}</div>
      </div>
      <video autoPlay muted loop id="login-video">
        <source
          src="https://formeasure-s3-media-bucket.s3.amazonaws.com/static/login-page.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  );
}

InnerForm.propTypes = {
  t: PropTypes.func.isRequired,
  tenant: PropTypes.string,
  history: PropTypes.object.isRequired,
  registrationHandler: PropTypes.func.isRequired,
  loginHandler: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      resetPasswordToken: PropTypes.string,
    }).isRequired,
  }).isRequired,
  lastTenant: PropTypes.string,
};

export default withTranslation()(withRouter(InnerForm));
