import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import '../styles/scales.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faChevronDown,
  faChevronRight,
  faClone,
  faExclamationTriangle,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { Trans, withTranslation } from 'react-i18next';
import { has, cloneDeep, forEach } from 'lodash';
import { TwitterPicker } from 'react-color';
import classnames from 'classnames';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ObjectUtil from '../../../utils/ObjectUtil';
import Toggle from '../../react-hook-form-inputs/Toggle';
import TextArea from '../../react-hook-form-inputs/TextArea';
import Input from '../../react-hook-form-inputs/Input';

function InnerForm(props) {
  const { t, scales, survey } = props;
  const testQuestionClassifications = survey.testQuestionClassification;
  const [activeScaleMenu, setActiveScaleMenu] = useState(-1);
  const [editableScaleColorIndex, setEitableScaleColorIndex] = useState(-1);
  const [color, setColor] = useState(null);
  const [colorPickerStyles, setColorPickerStyles] = useState({});
  const [pickerOpen, setPickerOpen] = useState(false);
  const [tagVisibility, setTagVisibility] = useState({});
  const [showNoTagsGroup, setShowNoTagsGroup] = useState(false);
  // TODO workaround for rerendering form
  const [render, setRender] = useState(false);

  const colorPickerRef = useRef('colorPicker');

  const isValidRange = (numberRanges, newRange) => {
    let isValid = true;
    for (let i = 0; i < numberRanges.length; i++) {
      if (parseInt(newRange.sortOrder) === parseInt(numberRanges[i].sortOrder)) {
        continue;
      }
      if (numberRanges[i].action === 'delete') {
        continue;
      }
      if (
        !compareTagIds(numberRanges[i].questionClassificationId, newRange.questionClassificationId)
      ) {
        continue;
      }
      isValid &=
        (newRange.rangeFrom < numberRanges[i].rangeFrom &&
          newRange.rangeTo < numberRanges[i].rangeFrom) ||
        (newRange.rangeFrom > numberRanges[i].rangeTo &&
          newRange.rangeTo > numberRanges[i].rangeTo);
    }
    return isValid;
  };

  const validationSchema = Yup.object().shape({
    scales: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required(t('message.required_field')),
        isReverseScaling: Yup.bool().nullable(),
        rangeFrom: Yup.number()
          .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
          .required(t('message.required_field'))
          .test(
            'test range from greater then range to',
            t('validation.greater_then_to_value'),
            (value, obj) => value <= obj.parent.rangeTo,
          )
          .test(
            'test intersection',
            t('validation.number_interval_intersecting_another_scales'),
            (value, obj) => {
              if (
                fields &&
                ObjectUtil.exists(obj.parent.rangeTo) &&
                obj.parent.questionClassificationId
              ) {
                return isValidRange(fields, {
                  rangeFrom: obj.parent.rangeFrom,
                  rangeTo: obj.parent.rangeTo,
                  sortOrder: obj.parent.sortOrder,
                  questionClassificationId: obj.parent.questionClassificationId,
                });
              }
              return true;
            },
          ),
        rangeTo: Yup.number()
          .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
          .required(t('message.required_field'))
          .test(
            'test range to lower then range from',
            t('validation.lower_then_from_value'),
            (value, obj) => value >= obj.parent.rangeFrom,
          )
          .test(
            'test intersection',
            t('validation.number_interval_intersecting_another_scales'),
            (value, obj) => {
              if (
                fields &&
                ObjectUtil.exists(obj.parent.rangeFrom) &&
                obj.parent.questionClassificationId
              ) {
                return isValidRange(fields, {
                  rangeFrom: obj.parent.rangeFrom,
                  rangeTo: obj.parent.rangeTo,
                  sortOrder: obj.parent.sortOrder,
                  questionClassificationId: obj.parent.questionClassificationId,
                });
              }
              return true;
            },
          ),
      }),
    ),
  });

  const allowedTags = survey.testQuestionClassification
    ? survey.testQuestionClassification.map((t) => t.name)
    : [];
  forEach(scales, (s, index) => {
    if (s.questionClassification && !allowedTags.includes(s.questionClassification.name)) {
      s.questionClassification = null;
      s.action = 'update';
    }
  });

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    criteriaMode: 'all',
    defaultValues: {
      survey,
      scales,
    },
  });

  const { register, control, setValue, getValues, trigger, watch, setError } = form;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'scales',
    keyName: 'scaleKeyId',
  });
  const isShowNoTagsGroup = () => {
    for (let i = 0; i < scales.length; i++) {
      if (
        fields[i] &&
        (!fields[i].questionClassificationId ||
          !(
            testQuestionClassifications &&
            testQuestionClassifications.find((tag) =>
              compareTagIds(tag.value, fields[i].questionClassificationId),
            )
          ))
      ) {
        setShowNoTagsGroup(true);
        return;
      }
    }
    setShowNoTagsGroup(false);
  };

  useEffect(() => {
    props.setForm('scales', form);
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyPress);

    const tagVisibility = { noTag: true };
    if (survey.testQuestionClassification && survey.testQuestionClassification.length > 0) {
      for (let i = 0; i < survey.testQuestionClassification.length; i++) {
        tagVisibility[survey.testQuestionClassification[i].value] = true;
      }
    }
    setTagVisibility(tagVisibility);
    isShowNoTagsGroup();
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
  useEffect(() => {
    isShowNoTagsGroup();
  }, [fields]);

  const handleClickOutside = (event) => {
    if (
      colorPickerRef &&
      colorPickerRef.current &&
      colorPickerRef.current.contains &&
      !colorPickerRef.current.contains(event.target)
    ) {
      setPickerOpen(false);
    }
    if (
      event.target.classList.contains('fm-controls-more') ||
      event.target.classList.contains('fa-ellipsis-h')
    ) {
      return;
    }
    const hide =
      !event.target ||
      (!event.target.classList.contains('fm-more-menu-item') &&
        !event.target.closest('.fm-controls-more-menu'));
    if (hide && activeScaleMenu < 0) {
      setActiveScaleMenu(-1);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      setPickerOpen(false);
    }
  };

  const removeScale = (event, index) => {
    event.stopPropagation();
    if (fields[index].id) {
      setValue(`scales[${index}].action`, 'delete');
      fields[index].action = 'delete';
    } else {
      remove(index);
    }
    setRender(!render);
  };

  const toggleScale = (event, index) => {
    setValue(`scales[${index}].isHidden`, !fields[index].isHidden);
    fields[index].isHidden = !fields[index].isHidden;
    validateRanges(fields[index]);

    setRender(!render);
  };

  const calcSortOrder = (objects, tagId) => {
    tagId = tagId || 'noTag';
    const orders = {};
    forEach(objects, (o) => {
      if (ObjectUtil.exists(orders[String(o.questionClassificationId)])) {
        orders[String(o.questionClassificationId)]++;
      } else {
        orders[String(o.questionClassificationId)] = 1;
      }
    });
    return ObjectUtil.exists(orders[String(tagId)]) ? ++orders[String(tagId)] : 1;
  };

  const addScale = (event, tagId) => {
    const newScale = {
      action: 'create',
      label: '',
      description: '',
      descriptionForUser: '',
      rangeFrom: 0,
      rangeTo: 0,
      questionClassification: testQuestionClassifications.find((v) =>
        compareTagIds(tagId, v.value),
      ),
      questionClassificationId: tagId,
      isHidden: false,
      sortOrder: calcSortOrder(fields, tagId),
    };
    append(newScale);

    validateRanges(newScale);
  };
  const validateRanges = (newRange) => {
    if (!isValidRange(fields, newRange)) {
      setError(`scales[${newRange.index}].rangeFrom`, {
        type: 'custom',
        message: t('validation.number_interval_intersecting_another_scales'),
      });
      setError(`scales[${newRange.index}].rangeTo`, {
        type: 'custom',
        message: t('validation.number_interval_intersecting_another_scales'),
      });
    }
  };

  const copyScale = (event, index) => {
    const values = getValues();
    const newScale = {
      ...cloneDeep(values.scales ? values.scales[index] : {}),
      id: null,
      rangeFrom: 0,
      rangeTo: 0,
      action: 'create',
      isHidden: false,
      sortOrder: calcSortOrder(fields, values.scales[index].questionClassificationId),
    };

    delete newScale.scaleKeyId;
    append(newScale, true);
    validateRanges(newScale);
  };

  const handleColorChange = (e) => {
    if (editableScaleColorIndex < 0) {
      return;
    }
    fields[editableScaleColorIndex] = {
      ...fields[editableScaleColorIndex],
      color: e.hex ? e.hex : null,
      action: fields[editableScaleColorIndex].id ? 'update' : 'create',
    };
    setValue(`scales[${editableScaleColorIndex}]`, fields[editableScaleColorIndex]);

    setPickerOpen(false);
  };

  const onTogglePicker = (e, index, color) => {
    const el = e.nativeEvent.target.closest('.form-element');
    const styles = {
      top: `${el.offsetTop + el.clientHeight + 12}px`,
      left: `${el.offsetLeft + 13}px`,
    };

    setColor(color);
    setColorPickerStyles(styles);
    setEitableScaleColorIndex(index);
    setPickerOpen(true);
  };

  const setUpdateAction = (field, index) => {
    if (!fields[index].action) {
      fields[index].action = field.id ? 'update' : 'create';
    }
  };

  const toggleTagVisibility = (tagId) => {
    tagVisibility[tagId] = !tagVisibility[tagId];
    setTagVisibility({ ...tagVisibility });
  };

  const handleOnDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) {
      return;
    }
    let draggable = null;
    let draggableIndex = -1;
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].scaleKeyId === draggableId) {
        draggable = fields[i];
        draggableIndex = i;
      }
    }

    const tag = testQuestionClassifications.find((v) =>
      compareTagIds(destination.droppableId, v.value),
    );
    for (let i = 0; i < fields.length; i++) {
      if (draggableIndex === i) {
        continue;
      }
      if (source.droppableId === destination.droppableId) {
        if (fields[i].sortOrder >= destination.index && fields[i].sortOrder < source.index) {
          fields[i] = {
            ...fields[i],
            action: fields[i].id ? 'update' : 'create',
            sortOrder: parseInt(fields[i].sortOrder) + 1,
          };
        } else if (fields[i].sortOrder <= destination.index && fields[i].sortOrder > source.index) {
          fields[i] = {
            ...fields[i],
            action: fields[i].id ? 'update' : 'create',
            sortOrder: parseInt(fields[i].sortOrder) - 1,
          };
        }
      } else if (
        compareTagIds(destination.droppableId, fields[i].questionClassificationId) &&
        fields[i].sortOrder >= destination.index
      ) {
        fields[i] = {
          ...fields[i],
          action: fields[i].id ? 'update' : 'create',
          sortOrder: parseInt(fields[i].sortOrder) + 1,
        };
      } else if (
        compareTagIds(source.droppableId, fields[i].questionClassificationId) &&
        fields[i].sortOrder > source.index
      ) {
        fields[i] = {
          ...fields[i],
          action: fields[i].id ? 'update' : 'create',
          sortOrder: parseInt(fields[i].sortOrder) - 1,
        };
      }
      delete fields[i].scaleKeyId;
    }

    const updatedScale = {
      ...draggable,
      action: draggable.id ? 'update' : 'create',
      sortOrder: destination.index,
      questionClassification: tag,
      questionClassificationId: tag ? (tag.id ? tag.id : tag.value) : null,
    };

    fields[draggableIndex] = updatedScale;
    delete fields[draggableIndex].scaleKeyId;

    setValue(`scales`, fields);
    if (source.droppableId !== destination.droppableId) {
      validateRanges(updatedScale);
    }
  };

  const renderScale = (field, index) => {
    if (field.questionClassification && typeof field.questionClassification === 'string') {
      field.questionClassification = JSON.parse(field.questionClassification);
    }
    const errors = [];
    if (
      !(
        field &&
        field.label &&
        field.label.length > 0 &&
        ObjectUtil.exists(field.rangeFrom) &&
        ObjectUtil.exists(field.rangeTo)
      )
    ) {
      errors.push(t('message.please_fill_required_fields'));
    }
    if (
      has(form, ['errors', 'scales', index, 'rangeFrom']) ||
      has(form, ['errors', 'scales', index, 'rangeTo'])
    ) {
      errors.push(t('validation.number_interval_intersecting_another_scales'));
    }

    return (
      <Draggable
        key={`${field.sortOrder}-${index}-${field.questionClassificationId}`}
        isDragDisabled={!field.isHidden}
        draggableId={field.scaleKeyId}
        index={index}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            key={`scale-${field.sortOrder}-${index}-${field.questionClassificationId}`}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={classnames(
              'fm-scale-container',
              { 'fm-display-none': field.action === 'delete' },
              { 'fm-scale-expanded': !field.isHidden },
            )}
          >
            <div className="fm-scale-dnd-control">
              <span className="fm-scale-drag-n-drop-control">
                <FontAwesomeIcon icon={faBars} />
              </span>
            </div>
            <div className="fm-scale-info">
              <div className="fm-scale-fields">
                <input
                  type="hidden"
                  name={`scales[${index}].id`}
                  ref={register()}
                  defaultValue={field.id}
                />
                <input
                  type="hidden"
                  name={`scales[${index}].tenantId`}
                  ref={register()}
                  defaultValue={field.tenantId}
                />
                <input
                  type="hidden"
                  name={`scales[${index}].systemType`}
                  ref={register()}
                  defaultValue={field.systemType}
                />
                <input
                  type="hidden"
                  name={`scales[${index}].sortOrder`}
                  ref={register()}
                  defaultValue={field.sortOrder}
                />
                <input
                  type="hidden"
                  name={`scales[${index}].action`}
                  ref={register()}
                  defaultValue={field.action}
                />
                <input
                  type="hidden"
                  name={`scales[${index}].questionClassification`}
                  ref={register()}
                  defaultValue={JSON.stringify(field.questionClassification)}
                />
                <input
                  type="hidden"
                  name={`scales[${index}].questionClassificationId`}
                  ref={register()}
                  defaultValue={field.questionClassificationId}
                />
                <input
                  type="hidden"
                  name={`scales[${index}].testId`}
                  ref={register()}
                  defaultValue={field.testId}
                />
                <input
                  type="hidden"
                  name={`scales[${index}].isHidden`}
                  ref={register()}
                  defaultValue={field.isHidden}
                />
                <div className={classnames('row', { 'fm-display-none': field.isHidden })}>
                  <Controller
                    control={control}
                    name={`scales[${index}].label`}
                    defaultValue={field.label ? field.label : ''}
                    render={({ onChange, onBlur, value, name }) => (
                      <Input
                        name={name}
                        label={t('fields.label')}
                        placeholder={t('fields.label')}
                        form={form}
                        maxLength="256"
                        onBlur={(event) => {
                          fields[index].label = value;
                          onBlur(event);
                        }}
                        onChange={(event) => {
                          setUpdateAction(field, index);
                          onChange(event);
                        }}
                        value={value}
                        classNames="fm-flex-child-100"
                      />
                    )}
                  />
                </div>
                <div className={classnames('row', { 'fm-display-none': field.isHidden })}>
                  <Controller
                    control={control}
                    name={`scales[${index}].rangeFrom`}
                    defaultValue={
                      ObjectUtil.exists(field.rangeFrom) && !isNaN(field.rangeFrom)
                        ? field.rangeFrom
                        : 0
                    }
                    render={({ onChange, onBlur, value, name }) => (
                      <Input
                        name={name}
                        form={form}
                        placeholder={t('fields.from')}
                        label={t('fields.from')}
                        onBlur={(event) => {
                          fields[index].rangeFrom = value;
                          onBlur(event);
                        }}
                        onChange={(event) => {
                          setUpdateAction(field, index);
                          onChange(event);
                          trigger(`scales[${index}].rangeTo`);
                        }}
                        value={value}
                        type="number"
                        classNames="fm-flex-child-25"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`scales[${index}].rangeTo`}
                    defaultValue={
                      ObjectUtil.exists(field.rangeTo) && !isNaN(field.rangeTo) ? field.rangeTo : 0
                    }
                    render={({ onChange, onBlur, value, name }) => (
                      <Input
                        name={name}
                        label={t('fields.to')}
                        placeholder={t('fields.to')}
                        form={form}
                        onBlur={(event) => {
                          fields[index].rangeTo = value;
                          onBlur(event);
                        }}
                        onChange={(event) => {
                          setUpdateAction(field, index);
                          onChange(event);
                          trigger(`scales[${index}].rangeFrom`);
                        }}
                        value={value}
                        type="number"
                        classNames="fm-flex-child-25"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`scales[${index}].color`}
                    defaultValue={field.color ? field.color : ''}
                    render={({ onChange, onBlur, value, name }) => (
                      <Input
                        name={name}
                        placeholder={t('fields.color')}
                        label={t('fields.color')}
                        form={form}
                        style={{
                          cursor: 'pointer',
                          caretColor: 'transparent',
                          backgroundColor: value,
                          color: value ? 'white' : 'inherit',
                          fontWeight: value ? 'bold' : 'inherit',
                        }}
                        onClick={(event) => {
                          onTogglePicker(event, index, field.color);
                          setUpdateAction(field, index);
                        }}
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        classNames="fm-flex-child-50"
                      />
                    )}
                  />
                </div>
                <div className={classnames('row', { 'fm-display-none': field.isHidden })}>
                  <Controller
                    control={form.control}
                    name={`scales[${index}].description`}
                    defaultValue={field.description}
                    render={({ onChange, onBlur, value, name }) => (
                      <TextArea
                        name={name}
                        placeholder={t('fields.description_for_admin')}
                        label={t('fields.description_for_admin')}
                        form={form}
                        rows={3}
                        maxLength="1024"
                        onBlur={(event) => {
                          fields[index].description = value;
                          onBlur(event);
                        }}
                        onChange={(event) => {
                          setUpdateAction(field, index);
                          onChange(event);
                        }}
                        value={value}
                        classNames="fm-flex-child-100"
                      />
                    )}
                  />
                </div>
                <div className={classnames('row', { 'fm-display-none': field.isHidden })}>
                  <Controller
                    control={form.control}
                    name={`scales[${index}].descriptionForUser`}
                    defaultValue={field.descriptionForUser ? field.descriptionForUser : ''}
                    render={({ onChange, onBlur, value, name }) => (
                      <TextArea
                        name={name}
                        placeholder={t('fields.description_for_user')}
                        label={t('fields.description_for_user')}
                        form={form}
                        rows={3}
                        maxLength="1024"
                        onBlur={(event) => {
                          fields[index].descriptionForUser = value;
                          onBlur(event);
                        }}
                        onChange={(event) => {
                          setUpdateAction(field, index);
                          onChange(event);
                        }}
                        value={value}
                        classNames="fm-flex-child-100"
                      />
                    )}
                  />
                </div>
                <div className={classnames('row', { 'fm-display-none': field.isHidden })}>
                  <Controller
                    control={form.control}
                    name={`scales[${index}].recommendation`}
                    defaultValue={field.recommendation ? field.recommendation : ''}
                    render={({ onChange, onBlur, value, name }) => (
                      <TextArea
                        name={name}
                        placeholder={t('fields.recommendation')}
                        label={t('fields.recommendation')}
                        form={form}
                        rows={3}
                        maxLength="10000"
                        onBlur={(event) => {
                          fields[index].recommendation = value;
                          onBlur(event);
                        }}
                        onChange={(event) => {
                          setUpdateAction(field, index);
                          onChange(event);
                        }}
                        value={value || ''}
                        classNames="fm-flex-child-100"
                      />
                    )}
                  />
                </div>
              </div>
              {!field.isHidden ? null : (
                <>
                  {errors.length === 0 ? (
                    <>
                      <div className="fm-color-square-wrapper">
                        <div
                          className="fm-color-square"
                          style={{
                            background: watch(`scales[${index}].color`),
                            border: field.color ? 'none' : `1px solid #E9EBF0`,
                          }}
                        />
                      </div>
                      <div className="fm-scale-text">
                        {`${field.label} (${
                          ObjectUtil.exists(field.rangeFrom) ? field.rangeFrom : 'N/A'
                        } - ${ObjectUtil.exists(field.rangeTo) ? field.rangeTo : 'N/A'})`}
                      </div>
                    </>
                  ) : (
                    <div className="fm-scale-errors">
                      {errors.map((el, errorIndex) => (
                        <div
                          key={errorIndex}
                          className={classnames('alert', 'alert-warning', ' fm-scale-text')}
                        >
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                          <span>{el}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
              <div className="fm-scale-controls">
                <div
                  className="fm-scale-control-visible"
                  onClick={(event) => toggleScale(event, index)}
                >
                  <FontAwesomeIcon icon={field.isHidden ? faChevronRight : faChevronDown} />
                </div>
                <div className="fm-scale-control-separator" />
                <div
                  className="fm-scale-control-visible"
                  title={t('components.copy')}
                  onClick={(event) => copyScale(event, index)}
                >
                  <FontAwesomeIcon icon={faClone} />
                </div>
                <div
                  className="fm-scale-control-visible"
                  title={t('components.delete')}
                  onClick={(event) => removeScale(event, index)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  };
  const compareTagIds = (v1, v2) =>
    isNaN(v1) && isNaN(v2) ? v1 === v2 : parseInt(v1) === parseInt(v2);

  const scaleLostTag = (scale) =>
    !scale.questionClassificationId ||
    !(
      testQuestionClassifications &&
      testQuestionClassifications.find((tag) =>
        compareTagIds(tag.value, scale.questionClassificationId),
      )
    );

  const renderScaleGroup = (tag, tagIndex) => {
    const tagId = tag ? tag.value : 'noTag';
    const tagLabel = tag ? tag.name : '';
    tagIndex = tagIndex || tagIndex >= 0 ? tagIndex : 'noTag';
    return (
      <React.Fragment key={String(tagId)}>
        <div
          onClick={() => toggleTagVisibility(tagId)}
          className={classnames(
            'fm-survey-summary-section-header',
            'fm-wizard-element-title-container',
          )}
        >
          <div className="fm-wizard-element-title">{tag ? tag.name : t('components.no_tag')}</div>
          <div className="fm-survey-summary-controls">
            <FontAwesomeIcon icon={!tagVisibility[tagId] ? faChevronRight : faChevronDown} />
          </div>
        </div>
        {tagVisibility[tagId] && (
          <div
            className={classnames(
              'ibox-content',
              'clearfix',
              'fm-wizard-element-content-box',
              'fm-survey-summary-section-body',
            )}
          >
            <div className="row">
              <input
                type="hidden"
                name={`survey.testQuestionClassification[${tagIndex}].value`}
                ref={register()}
                defaultValue={tagId}
              />
              <input
                type="hidden"
                name={`survey.testQuestionClassification[${tagIndex}].label`}
                ref={register()}
                defaultValue={tagLabel}
              />
              {tag ? (
                <Controller
                  id={`survey.testQuestionClassification[${tagIndex}].isReverseScaling`}
                  control={control}
                  name={`survey.testQuestionClassification[${tagIndex}].isReverseScaling`}
                  defaultValue={has(tag, 'isReverseScaling') ? tag.isReverseScaling : false}
                  render={({ onChange, onBlur, value, name }) => (
                    <Toggle
                      name={name}
                      checked={value}
                      label={t('fields.is_reverse_scaling')}
                      toggleSize={6}
                      helperMessage={<Trans t={t} i18nKey="fields.reverse_scaling_clarification" />}
                      form={{
                        touched: form.touched,
                        errors: form.errors,
                      }}
                      onBlur={onBlur}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                      value={value || false}
                      classNames="fm-flex-child-50"
                    />
                  )}
                />
              ) : (
                <input
                  type="hidden"
                  name={`survey.testQuestionClassification[${tagIndex}].isReverseScaling`}
                  ref={register()}
                  defaultValue={has(tag, 'isReverseScaling') ? tag.isReverseScaling : false}
                />
              )}
            </div>
            <Droppable droppableId={String(tagId)} type="scales">
              {(provided, snapshot) => (
                <div
                  className="fm-scale-container-wrapper"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {tag &&
                    fields
                      .sort((a, b) => a.sortOrder - b.sortOrder)
                      .map(
                        (field, index) =>
                          compareTagIds(tagId, field.questionClassificationId) &&
                          renderScale(field, index),
                      )}

                  {showNoTagsGroup &&
                    !tag &&
                    fields
                      .sort((a, b) => a.sortOrder - b.sortOrder)
                      .map((field, index) => scaleLostTag(field) && renderScale(field, index))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            {tag && (
              <div className="fm-add-scale-container">
                <div className="fm-add-scale-box" onClick={(event) => addScale(event, tagId)}>
                  <div className="fm-add-scale-image">
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                  <div className="fm-add-scale-label">{t('components.add_scale')}</div>
                </div>
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <>
      <div className="fm-wizard-element-title-container">
        <div className="fm-wizard-element-title">{t('components.scales')}</div>
      </div>
      <FormProvider {...form} className="row">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <form className="col-md-12">
            {pickerOpen && (
              <div ref={colorPickerRef} className="fm-color-picker-box" style={colorPickerStyles}>
                <TwitterPicker
                  color={color}
                  triangle="top-left"
                  onChangeComplete={handleColorChange}
                />
              </div>
            )}

            {survey.testQuestionClassification.map((tag, tagIndex) =>
              renderScaleGroup(tag, tagIndex),
            )}
            {showNoTagsGroup && renderScaleGroup()}
            {(!testQuestionClassifications || testQuestionClassifications.length === 0) && (
              <div className="ibox-content clearfix">
                <p className="text-muted text-center">{t('message.no_scales_present')}</p>
              </div>
            )}
          </form>
        </DragDropContext>
      </FormProvider>
    </>
  );
}

InnerForm.propTypes = {
  scales: PropTypes.array.isRequired,
  survey: PropTypes.object.isRequired,
};

export default withTranslation()(InnerForm);
