import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import PageNameContainer from '../../components/page/PageNameContainer';
import MyActivitiesHome from '../../components/home/MyActivitiesHome';
import FeedbackHome from '../../components/home/FeedbackHome';
import InsightsHome from '../../components/home/InsightsHome';
import TeamHome from '../../components/home/TeamHome';
import { countAdvice, countPraise } from '../../services/ongoingfeedbacks/ongoingFeedbackProvider';
import './styles/home.css';
import Notifications from '../../utils/Notifications';
import { getEmployeeSurveyAssignmentsCount } from '../../services/testassignment/testAssignmentProvider';
import { getEmployeeFeedbackAssignmentsCount } from '../../services/feedbackassignment/feedbackAssignmentProvider';
import { getInsightsData } from '../../services/analytics/analyticsProvider';
import ContentLoader from '../../components/loader/ContentLoader';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const promises = [];
    const { account } = this.props;
    promises.push(countAdvice(account.userId));
    promises.push(countPraise(account.userId));
    promises.push(getEmployeeSurveyAssignmentsCount());
    promises.push(getEmployeeFeedbackAssignmentsCount());
    promises.push(getInsightsData());

    Promise.all(promises)
      .then(
        ([
          countAdvice,
          countPraise,
          employeeSurveyAssignmentsCount,
          employeeFeedbackAssignmentsCount,
          insights,
        ]) => {
          this.setState({
            countAdvice,
            countPraise,
            employeeSurveyAssignmentsCount,
            employeeFeedbackAssignmentsCount,
            insights,
          });
        },
      )
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const {
      countAdvice,
      countPraise,
      employeeSurveyAssignmentsCount,
      employeeFeedbackAssignmentsCount,
      insights,
    } = this.state;
    const { account, t } = this.props;

    return (
      <>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer name={t('containers.home')} breadcrumbs={this.getBreadcrumbs()} />
          </div>
        </div>
        <div className="row wrapper">
          <div className="col-lg-12 fm-home-welcome">
            {t('dashboard.welcome_back')}, {`${account.firstName} ${account.lastName}`}!
          </div>
        </div>
        <div className="row wrapper fm-margin-b20">
          <div className="col-lg-4">
            <div className="fm-home-widget-name">{t('components.feedback')}</div>
            <FeedbackHome countAdvice={countAdvice} countPraise={countPraise} />
          </div>
          <div className="col-lg-8">
            <div className="fm-home-widget-name">{t('dashboard.my_activity')}</div>
            <MyActivitiesHome
              employeeSurveyAssignmentsCount={employeeSurveyAssignmentsCount}
              employeeFeedbackAssignmentsCount={employeeFeedbackAssignmentsCount}
            />
          </div>
        </div>
        {insights ? (
          <div className="row wrapper fm-margin-b20">
            <div className="col-lg-12">
              <div className="fm-home-widget-name">{t('dashboard.insights')}</div>
              <InsightsHome insights={insights} />
            </div>
          </div>
        ) : (
          <ContentLoader />
        )}
        <div className="row wrapper fm-margin-b20">
          <div className="col-lg-12">
            <div className="fm-home-widget-name">{t('dashboard.team')}</div>
            <TeamHome />
          </div>
        </div>
      </>
    );
  }
}

Home.propTypes = {
  account: PropTypes.object,
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(Home)));
