import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import DatePicker from '../react-hook-form-inputs/DatePicker';
import { getUserByNameLike, getUserSelectItemSource } from '../../services/user/userProvider';
import { getTestByNameLike, getTestSelectItemSource } from '../../services/tests/testProvider';

import TextArea from '../react-hook-form-inputs/TextArea';
import AsyncSelect from '../react-hook-form-inputs/AsyncSelect';
import Notifications from '../../utils/Notifications';
import Toggle from '../react-hook-form-inputs/Toggle';

function InnerForm(props) {
  const { t, cancel, createTestAssignment } = props;

  const schema = Yup.object().shape({
    deadline: Yup.date().required(t('message.required_field')).nullable(),
    test: Yup.mixed().required(t('message.required_field')),
    showResultToUser: Yup.bool().nullable(),
    user: Yup.mixed().required(t('message.required_field')),
  });

  const date = new Date();
  const deadline = new Date(date.setMonth(date.getMonth() + 1));

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      user: null,
      test: null,
      description: '',
      showResultToUser: false,
      deadline,
    },
  });

  const [defaultUsers, setDefaultUsers] = useState([]);
  const [defaultSurveys, setDefaultSurveys] = useState([]);

  useEffect(() => {
    const promises = [
      getUserSelectItemSource({ amount: 100, skip: 0 }),
      getTestSelectItemSource({ amount: 100, skip: 0 }),
    ];
    Promise.all(promises)
      .then(([defaultUsers, defaultSurveys]) => {
        setDefaultUsers(defaultUsers);
        setDefaultSurveys(defaultSurveys);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  const { handleSubmit, control } = form;

  const onSubmit = (data) => {
    createTestAssignment({
      description: data.description,
      userId: data.user.value,
      testId: data.test.value,
      showResultToUser: data.showResultToUser,
      deadline: data.deadline,
    });
  };
  const loadTestOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getTestByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };
  const loadUserOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getUserByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };

  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <div className="modal-body">
        <Controller
          control={control}
          id="description"
          name="description"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <TextArea
              name={name}
              placeholder={t('fields.description')}
              label={t('fields.description')}
              form={form}
              rows={2}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
        <Controller
          control={form.control}
          id="user"
          name="user"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <AsyncSelect
              name={name}
              placeholder={t('fields.user')}
              loadOptions={loadUserOptions}
              label={t('fields.user')}
              onChange={onChange}
              defaultOptions={defaultUsers}
              form={form}
              isMulti={false}
              isClearable
              cacheOptions
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <Controller
          id="showResultToUser"
          control={control}
          name="showResultToUser"
          defaultValue={false}
          render={({ onChange, onBlur, value, name }) => (
            <Toggle
              name={name}
              checked={value}
              label={t('fields.show_result_to_user')}
              toggleSize={6}
              form={{
                touched: form.touched,
                errors: form.errors,
              }}
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.checked)}
              value={value || false}
            />
          )}
        />
        <Controller
          control={control}
          id="test"
          name="test"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <AsyncSelect
              name={name}
              placeholder={t('fields.survey')}
              loadOptions={loadTestOptions}
              label={t('fields.survey')}
              onChange={onChange}
              form={form}
              isMulti={false}
              defaultOptions={defaultSurveys}
              isClearable
              cacheOptions
              onBlur={onBlur}
              value={value}
            />
          )}
        />
        <Controller
          control={form.control}
          id="deadline"
          name="deadline"
          defaultValue=""
          render={({ onChange, onBlur, value, name }) => (
            <DatePicker
              name={name}
              label={t('fields.deadline')}
              onChange={onChange}
              form={form}
              onBlur={onBlur}
              value={value}
              minDate={new Date(moment.utc())}
              placeholderText={t('fields.date_format_mm_dd_yyyy')}
              autoComplete="off"
            />
          )}
        />
      </div>
      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
        <button type="submit" className="btn fm-btn-primary">
          {t('buttons.create')}
        </button>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  cancel: PropTypes.func.isRequired,
  createTestAssignment: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
