import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import './styles/assignment-period-summary.css';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faComments } from '@fortawesome/free-solid-svg-icons';

class AssignmentPeriodQuestionBoxSingle extends Component {
  constructor(props) {
    super(props);
  }

  single;

  render() {
    const { question, index, isUserResult } = this.props;
    const { chartData } = question;
    const { labels, datasets } = chartData;

    return (
      <div className="fm-ap-question-box" key={`ap-question-single-${question.id}`}>
        <div className="fm-ap-question">
          <div className="fm-ap-question-number">
            <div>Q{index + 1}:</div>
            <div>{question.question}</div>
          </div>
          {question.comments.length > 0 && (
            <div className="fm-ap-question-response-box">
              <div className="fm-ap-question-response-info">
                <span className="fm-ap-question-response-number">{question.comments.length}</span>
                <FontAwesomeIcon icon={faComments} />
              </div>
            </div>
          )}
        </div>
        <div className="fm-ap-question-statistic">
          <div className="fm-ap-question-chart">
            <Doughnut
              data={{
                labels,
                datasets,
              }}
              options={{
                plugins: {
                  tooltip: {
                    enabled: false,
                  },
                  legend: {
                    display: false,
                  },
                  datalabels: {
                    formatter: (value, ctx) => {
                      if (value === 0) {
                        return '';
                      }
                      let total = 0;
                      const dataArr = ctx.chart.data.datasets[0].data;
                      dataArr.map((data) => {
                        total += data;
                      });
                      return `${((value * 100) / total).toFixed(1)}%`;
                    },
                    color: '#fff',
                  },
                },
              }}
            />
          </div>
          <div className="fm-ap-question-legend">
            <div className="fm-ap-question-legend-box">
              {labels &&
                labels.map((label, _index) => (
                  <div className="fm-ap-question-legend-row" key={`fm-ap-label-${_index}`}>
                    <div className="fm-ap-question-legend-key">
                      <div
                        className="fm-ap-question-legend-color"
                        style={{ backgroundColor: `${datasets[0].backgroundColor[_index]}` }}
                      />
                      <span>{label}</span>
                    </div>
                    <div className="fm-ap-question-legend-value">
                      {isUserResult ? (
                        <>
                          {datasets[0].data[_index] === 1 ? (
                            <span className="checked">
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                          ) : null}
                        </>
                      ) : (
                        <>{parseFloat(datasets[0].data[_index] * 100).toFixed(1)}%</>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AssignmentPeriodQuestionBoxSingle.propTypes = {};
export default withTranslation()(AssignmentPeriodQuestionBoxSingle);
