import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import Select from '../../react-hook-form-inputs/Select';

function InnerForm(props) {
  const {
    t,
    handleSubmit,
    onClose,
    questionClassifications,
    addedClassifications,
    editQuestionIndex,
  } = props;
  let options = [];
  if (questionClassifications && questionClassifications.length > 0) {
    options = props.questionClassifications.map((v) => ({
      value: v.id,
      label: v.name,
    }));
  }
  let classifications = {};
  if (addedClassifications && addedClassifications.length > 0) {
    classifications = questionClassifications
      .filter((v) => addedClassifications.includes(v.id))
      .map((v) => ({
        value: v.id,
        label: v.name,
      }));
  }
  const validationSchema = Yup.object().shape({});
  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
  // TODO for now it's just first - need to think do we wanna push more then one?
  return (
    <form>
      <div className="modal-body">
        <Controller
          control={form.control}
          name="classifications"
          defaultValue={classifications[0]}
          render={({ onChange, onBlur, value, name }) => (
            <Select
              name={name}
              label={t('fields.tags')}
              placeholder={t('fields.select_tags')}
              form={form}
              isClearable
              options={options}
              isMulti={false}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={(event) => {
            const classificationIds = form.getValues().classifications
              ? [form.getValues().classifications.value]
              : [];
            handleSubmit(editQuestionIndex, classificationIds);
          }}
          className="btn fm-btn-primary"
        >
          {t('buttons.save')}
        </button>
        <button type="button" className="btn" onClick={onClose}>
          {t('buttons.cancel')}
        </button>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  questionClassifications: PropTypes.array.isRequired,
  editQuestionIndex: PropTypes.number.isRequired,
  addedClassifications: PropTypes.array,
};

export default withTranslation()(InnerForm);
