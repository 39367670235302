import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Can from '../security/Can';
import DatePicker from '../react-hook-form-inputs/DatePicker';
import AsyncSelect from '../react-hook-form-inputs/AsyncSelect';
import Notifications from '../../utils/Notifications';
import FormTitle from '../form/FormTitle';
import { getUserSelectItemSource } from '../../services/user/userProvider';
import { getOrgUnitSelectItemSource } from '../../services/orgunit/orgUnitProvider';
import { getLocationSelectItemSource } from '../../services/location/locationProvider';
import { getEmployeeJobSelectItemSource } from '../../services/employeejob/employeeJobProvider';
import { getEmployeeJobLevelSelectItemSource } from '../../services/employeejoblevel/employeeJobLevelProvider';

function InnerForm(props) {
  const {
    t,
    employee,
    getUsers,
    getOrgUnits,
    getEmployeeJobs,
    getEmployeeJobLevels,
    getLocations,
    updateEmployee,
  } = props;

  const orgLink = `/org/org-unit/${employee.orgStructureUnitId}/edit`;
  const userLink = `/users/${employee.user.id}/edit`;
  const employeeJobLink = employee.employeeJob
    ? `/org/employee-jobs/${employee.employeeJob.id}/edit`
    : null;
  const employeeJobLevelLink = employee.employeeJobLevel
    ? `/org/employee-job-levels/${employee.employeeJobLevel.id}/edit`
    : null;
  const locationLink = employee.location ? `/org/locations/${employee.location.id}/edit` : null;

  const schema = Yup.object().shape({
    orgUnit: Yup.mixed().required(t('message.required_field')),
    user: Yup.mixed().required(t('message.required_field')),
    hireDate: Yup.date().required(t('message.required_field')),
  });

  const [defaultUserOptions, setDefaultUserOptions] = useState([]);
  const [defaultOrganizationOptions, setDefaultOrganizationOptions] = useState([]);
  const [defaultLocationOptions, setDefaultLocationOptions] = useState([]);
  const [defaultJobOptions, setDefaultJobOptions] = useState([]);
  const [defaultJobLevelOptions, setDefaultJobLevelOptions] = useState([]);
  useEffect(() => {
    const promises = [];
    promises.push(getUserSelectItemSource({ amount: 100, skip: 0 }));
    promises.push(getOrgUnitSelectItemSource({ amount: 100, skip: 0 }));
    promises.push(getLocationSelectItemSource({ amount: 100, skip: 0 }));
    promises.push(getEmployeeJobSelectItemSource({ amount: 100, skip: 0 }));
    promises.push(getEmployeeJobLevelSelectItemSource({ amount: 100, skip: 0 }));

    Promise.all(promises)
      .then(
        ([
          defaultUserOptions,
          defaultOrganizationOptions,
          defaultLocationOptions,
          defaultJobOptions,
          defaultJobLevelOptions,
        ]) => {
          setDefaultUserOptions(defaultUserOptions);
          setDefaultOrganizationOptions(defaultOrganizationOptions);
          setDefaultLocationOptions(defaultLocationOptions);
          setDefaultJobOptions(defaultJobOptions);
          setDefaultJobLevelOptions(defaultJobLevelOptions);
        },
      )
      .catch((error) => Notifications.error(error));
  }, []);

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      orgUnit: {
        value: employee.orgStructureUnitId,
        label: employee.orgStructureUnitName,
      },
      user: {
        value: employee.user.id,
        label: employee.user.displayString,
      },
      employeeJob: employee.employeeJob
        ? {
            value: employee.employeeJob.id,
            label: employee.employeeJob.name,
          }
        : '',
      employeeJobLevel: employee.employeeJobLevel
        ? {
            value: employee.employeeJobLevel.id,
            label: employee.employeeJobLevel.name,
          }
        : '',
      location: employee.location
        ? {
            value: employee.location.id,
            label: employee.location.name,
          }
        : '',
      hireDate: employee.hireDate ? new Date(moment.utc(employee.hireDate)) : '',
    },
  });

  const { handleSubmit, control } = form;
  const loadUserOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getUsers(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  const loadOrganizationOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getOrgUnits(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };
  const loadEmployeeJobOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getEmployeeJobs(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };
  const loadEmployeeJobLevelOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getEmployeeJobLevels(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };
  const loadLocationOptions = (query, callback) => {
    if (!query) {
      return;
    }
    getLocations(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  const onSubmit = (data) => {
    const params = {
      ...employee,
      userId: data.user.value,
      locationId: data.location ? data.location.value : null,
      orgStructureUnitId: data.orgUnit.value,
      employeeJobId: data.employeeJob ? data.employeeJob.value : null,
      employeeJobLevelId: data.employeeJobLevel ? data.employeeJobLevel.value : null,
      hireDate: data.hireDate,
    };

    updateEmployee(params);
  };

  return (
    <div className="ibox">
      <FormTitle name={t('components.employee')} />
      <div className="ibox-content clearfix">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <Controller
            control={control}
            id="user"
            name="user"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <AsyncSelect
                name={name}
                placeholder={t('fields.user')}
                loadOptions={loadUserOptions}
                label={
                  <Can
                    module="users"
                    permission="edit"
                    yes={() => <NavLink to={userLink}>{t('fields.user')}</NavLink>}
                    no={() => t('fields.user')}
                  />
                }
                defaultOptions={defaultUserOptions}
                onChange={onChange}
                form={form}
                isMulti={false}
                cacheOptions
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            id="orgUnit"
            name="orgUnit"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <AsyncSelect
                name={name}
                placeholder={t('fields.organization_unit')}
                loadOptions={loadOrganizationOptions}
                label={
                  <Can
                    module="org-unit"
                    permission="edit"
                    yes={() =>
                      orgLink ? (
                        <NavLink to={orgLink}>{t('fields.organization_unit')}</NavLink>
                      ) : (
                        t('fields.organization_unit')
                      )
                    }
                    no={() => t('fields.organization_unit')}
                  />
                }
                defaultOptions={defaultOrganizationOptions}
                onChange={onChange}
                form={form}
                isMulti={false}
                isClearable
                cacheOptions
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            id="employeeJob"
            name="employeeJob"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <AsyncSelect
                name={name}
                placeholder={t('fields.job')}
                loadOptions={loadEmployeeJobOptions}
                defaultOptions={defaultJobOptions}
                label={
                  <Can
                    module="jobs"
                    permission="edit"
                    yes={() =>
                      employeeJobLink ? (
                        <NavLink to={employeeJobLink}>{t('fields.job')}</NavLink>
                      ) : (
                        t('fields.job')
                      )
                    }
                    no={() => t('fields.job')}
                  />
                }
                onChange={onChange}
                form={form}
                isMulti={false}
                isClearable
                cacheOptions
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            id="employeeJobLevel"
            name="employeeJobLevel"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <AsyncSelect
                name={name}
                placeholder={t('fields.job_level')}
                loadOptions={loadEmployeeJobLevelOptions}
                defaultOptions={defaultJobLevelOptions}
                label={
                  <Can
                    module="job-levels"
                    permission="edit"
                    yes={() =>
                      employeeJobLevelLink ? (
                        <NavLink to={employeeJobLevelLink}>{t('fields.job_level')}</NavLink>
                      ) : (
                        t('fields.job_level')
                      )
                    }
                    no={() => t('fields.job_level')}
                  />
                }
                onChange={onChange}
                form={form}
                isMulti={false}
                isClearable
                cacheOptions
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <Controller
            control={control}
            id="location"
            name="location"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <AsyncSelect
                name={name}
                placeholder={t('fields.location')}
                loadOptions={loadLocationOptions}
                defaultOptions={defaultLocationOptions}
                label={
                  <Can
                    module="location"
                    permission="edit"
                    yes={() =>
                      locationLink ? (
                        <NavLink to={locationLink}>{t('fields.location')}</NavLink>
                      ) : (
                        locationLink
                      )
                    }
                    no={() => t('fields.location')}
                  />
                }
                onChange={onChange}
                form={form}
                isMulti={false}
                isClearable
                cacheOptions
                onBlur={onBlur}
                value={value}
              />
            )}
          />
          <Controller
            control={form.control}
            id="hireDate"
            name="hireDate"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <DatePicker
                name={name}
                label={t('fields.hire_date')}
                placeholder={t('fields.hire_date')}
                onChange={onChange}
                form={form}
                onBlur={onBlur}
                value={value}
                autoComplete="off"
                placeholderText={t('fields.date_format_mm_dd_yyyy')}
              />
            )}
          />
          <Can
            module="employees"
            permission="edit"
            yes={() => (
              <button type="submit" className="btn fm-btn-primary pull-right block">
                {t('buttons.save')}
              </button>
            )}
            no={() => null}
          />
        </form>
      </div>
    </div>
  );
}

InnerForm.propTypes = {
  t: PropTypes.func.isRequired,
  employee: PropTypes.shape({
    orgStructureUnitId: PropTypes.number.isRequired,
    orgStructureUnitName: PropTypes.string.isRequired,
    employeeJob: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    employeeJobLevel: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    location: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  orgUnit: PropTypes.object,
  updateEmployee: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  getOrgUnits: PropTypes.func.isRequired,
  getEmployeeJobs: PropTypes.func.isRequired,
  getEmployeeJobLevels: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
