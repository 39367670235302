import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PageNameContainer from '../../components/page/PageNameContainer';
import { getFeedbackAssignment } from '../../services/feedbackassignment/feedbackAssignmentProvider';
import FeedbackAssignmentSummaryForm from '../../components/feedbackassignments/FeedbackAssignmentSummaryForm';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import FormTitle from '../../components/form/FormTitle';
import { getEmployeeByUserId } from '../../services/employee/employeeProvider';

class ManagerSubordinateFeedbackAssignmentSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      editFeedbackForm: false,
      showScrollTop: false,
      criteria: null,
      criteriaRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.subordinates_requests'),
        ref: '/feedback-assignments/manager-subordinate-requests',
        isActive: true,
      },
      {
        name: t('breadcrumbs.employee_feedback_summary'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { params } = this.props.match;
    this.refreshFeedbackAssignment(params.feedbackAssignmentId);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.location !== nextProps.location && nextProps.location.state) {
      this.refreshFeedbackAssignment(nextProps.location.state.feedbackAssignmentId);
    }
  }

  refreshFeedbackAssignment = (feedbackassignmentId) => {
    this.setState({ isLoading: true });

    Promise.all([getFeedbackAssignment(feedbackassignmentId)])
      .then(([feedbackAssignment]) => {
        getEmployeeByUserId(feedbackAssignment.revieweeId)
          .then((data) => {
            this.setState({
              isLoading: false,
              feedbackAssignment,
              employee: data,
            });
          })
          .catch((error) => {
            Notifications.error(error);
            this.setState({ isLoading: false });
          });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { feedbackAssignment, isLoading, employee } = this.state;
    const { managerId, t } = this.props;
    return (
      <div>
        <div>
          <div className="row wrapper">
            <div className="col-lg-12">
              <PageNameContainer
                name={t('containers.employee_feedback_summary')}
                breadcrumbs={this.getBreadcrumbs()}
              />
            </div>
          </div>
          <div className="wrapper-content">
            <div className="ibox">
              <FormTitle name={t('components.employee_feedback_summary')} />

              {feedbackAssignment && employee && (
                <FeedbackAssignmentSummaryForm
                  feedbackAssignment={feedbackAssignment}
                  employee={employee}
                  managerId={managerId}
                  refreshFeedbackAssignment={this.refreshFeedbackAssignment}
                />
              )}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </div>
    );
  }
}

ManagerSubordinateFeedbackAssignmentSummary.propTypes = {};
const mapStateToProps = (state) => ({
  managerId: state.account.userId,
});
export default withTranslation()(
  connect(mapStateToProps)(ManagerSubordinateFeedbackAssignmentSummary),
);
