import dataProvider from '../dataProvider';

import { employee } from '../../constants/endpoints';

export const getEmployeeList = (params) =>
  dataProvider({
    url: employee.all(),
    params,
    method: 'GET',
  });

export const getEmployeeByUserId = (userId) =>
  dataProvider({
    url: employee.getByUserId(userId),
    method: 'GET',
  });

export const getEmployee = (employeeId) =>
  dataProvider({
    url: employee.getById(employeeId),
    method: 'GET',
  });

export const createEmployee = (params) =>
  dataProvider({
    url: employee.create(),
    data: params,
    method: 'POST',
  });

export const updateEmployee = (params) =>
  dataProvider({
    url: employee.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteEmployee = (employeeId) =>
  dataProvider({
    url: employee.delete(employeeId),
    method: 'DELETE',
  });

export const getEmployeePagination = (params) =>
  dataProvider({
    url: employee.pagination(),
    data: params,
    method: 'POST',
  });

export const getNotAssignedToFeedbackEmployeeByNameLike = (feedbackAssignmentId, query) =>
  dataProvider({
    url: employee.getNotAssignedToFeedbackByNameLike(feedbackAssignmentId, query),
    method: 'GET',
  });

export const getNotAssignedToFeedbackEmployee = (feedbackAssignmentId) =>
  dataProvider({
    url: employee.getNotAssignedToFeedbackEmployee(feedbackAssignmentId),
    method: 'GET',
  });

export const addEmployeesToOrgUnit = (orgUnitId, ids) =>
  dataProvider({
    url: employee.addEmployeesToOrgUnit(orgUnitId),
    data: ids,
    method: 'POST',
  });

export const getFeedbackAssignmentReviewersAsEmployees = (feedbackAssignmentId) =>
  dataProvider({
    url: employee.getFeedbackAssignmentReviewersAsEmployees(feedbackAssignmentId),
    method: 'GET',
  });

export const checkIsManager = (userId) =>
  dataProvider({
    url: employee.checkIsManager(userId),
    method: 'GET',
  });

export const getMyTeamEmployeesPagination = (params, userId) =>
  dataProvider({
    url: employee.getMyTeamEmployeesPagination(userId),
    data: params,
    method: 'POST',
  });

export const getMyTeamEmployeesManagerPagination = (params, userId) =>
  dataProvider({
    url: employee.getMyTeamEmployeesManagerPagination(userId),
    data: params,
    method: 'POST',
  });

export const importEmployee = (params) =>
  dataProvider({
    url: employee.importEmployee(),
    data: params,
    method: 'POST',
  });

export const validateEmployeeImportFile = (params) =>
  dataProvider({
    url: employee.validateEmployeeImportFile(),
    data: params,
    method: 'POST',
  });

export const getEmployeeExpectedImportRows = () =>
  dataProvider({
    url: employee.getEmployeeExpectedImportRows(),
    method: 'GET',
  });
