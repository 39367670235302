import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';

function TextareaComponent({ field, form: { touched, errors }, classNames, label, ...props }) {
  const hasError =
    field.name &&
    typeof field.name === 'string' &&
    field.name.split('.').reduce((o, i) => (o ? o[i] : false), touched) &&
    field.name.split('.').reduce((o, i) => (o ? o[i] : false), errors);

  return (
    <FormGroup
      className={classnames('form-element', 'textarea-component', classNames, {
        'has-error': hasError,
      })}
    >
      {label && <ControlLabel>{label}</ControlLabel>}
      <FormControl componentClass="textarea" {...field} {...props} />

      {hasError && (
        <HelpBlock className="error-text animated fadeIn">
          {field.name.split('.').reduce((o, i) => o[i], errors)}
        </HelpBlock>
      )}
    </FormGroup>
  );
}

TextareaComponent.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  classNames: PropTypes.string,
  setFieldValue: PropTypes.func,
};

export default TextareaComponent;
