import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import { ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';

function TextArea({ name, form: { touched, errors }, classNames, label, ...props }) {
  const error = _.get(errors, name);

  return (
    <FormGroup
      className={classnames('form-element', 'textarea-component', classNames, {
        'has-error': error && error.message,
      })}
    >
      {label && <ControlLabel htmlFor={name}>{label}</ControlLabel>}
      <FormControl componentClass="textarea" {...props} name={name} id={name} />
      {error && error.message && (
        <HelpBlock className="error-text animated fadeIn fm-error-req">{error.message}</HelpBlock>
      )}
    </FormGroup>
  );
}

TextArea.propTypes = {
  label: PropTypes.string,
  classNames: PropTypes.string,
};

export default TextArea;
