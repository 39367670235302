import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik/dist/index';
import Moment from 'moment/moment';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import FormTitle from '../form/FormTitle';
import StringUtil from '../../utils/StringUtil';

function InnerForm(props) {
  const { t, testAssignment } = props;
  const userLink = `/users/${testAssignment.userId}/edit`;
  const testLink = `/tests/${testAssignment.testId}/edit`;

  return (
    <div className="ibox">
      <FormTitle name={t('components.common_information')} />
      <div className="ibox-content clearfix">
        <dl className="row mb-0">
          <div className="col-sm-4 text-sm-right">
            <dt>{t('components.user_information')}</dt>
          </div>
          <div className="col-sm-8 text-sm-left">
            <dd className="mb-1">
              <NavLink to={userLink}>{testAssignment.userDisplayString}</NavLink>
            </dd>
          </div>
        </dl>
        <dl className="row mb-0">
          <div className="col-sm-4 text-sm-right">
            <dt>{t(`components.${testAssignment.test.testType.toLowerCase()}`)}</dt>
          </div>
          <div className="col-sm-8 text-sm-left">
            <dd className="mb-1">
              <NavLink to={testLink}>{testAssignment.testDisplayString}</NavLink>
            </dd>
          </div>
        </dl>
        <dl className="row mb-0">
          <div className="col-sm-4 text-sm-right">
            <dt>
              {testAssignment.test.testType === 'survey'
                ? t('components.survey_description')
                : t('components.test_description')}
            </dt>
          </div>
          <div className="col-sm-8 text-sm-left">
            <dd className="mb-1">{testAssignment.testDescription}</dd>
          </div>
        </dl>
        <dl className="row mb-0">
          <div className="col-sm-4 text-sm-right">
            <dt>{t('components.status')}</dt>
          </div>
          <div className="col-sm-8 text-sm-left">
            <dd className="mb-1">
              <span className="label label-primary">
                {StringUtil.separateCapital(testAssignment.status)}
              </span>
            </dd>
          </div>
        </dl>
        <dl className="row mb-0">
          <div className="col-sm-4 text-sm-right">
            <dt>{t('components.created')}</dt>
          </div>
          <div className="col-sm-8 text-sm-left">
            <dd className="mb-1">{Moment(testAssignment.created).format('LL')}</dd>
          </div>
        </dl>
        {testAssignment.updated ? (
          <dl className="row mb-0">
            <div className="col-sm-4 text-sm-right">
              <dt>{t('components.updated')}</dt>
            </div>
            <div className="col-sm-8 text-sm-left">
              <dd className="mb-1">{Moment(testAssignment.updated).format('LL')}</dd>
            </div>
          </dl>
        ) : null}
      </div>
    </div>
  );
}

InnerForm.propTypes = {
  testAssignment: PropTypes.object,
};

const CommonTestAssignmentInfo = withFormik({
  mapPropsToValues: (props) => ({
    userDisplayString: props.userDisplayString || '',
    testDisplayString: props.testDisplayString || '',
    testDescription: props.testDescription || '',
    testType: props.testType || 0,
    status: props.status || '',
    created: props.created ? Moment(props.created).format('LL') : '',
    updated: props.created ? Moment(props.created).format('LL') : '',
  }),
})(InnerForm);

export default withTranslation()(CommonTestAssignmentInfo);
