import React, { Component } from 'react';
import { faKey, faPlus, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Loader from '../../components/loader/Loader';
import Notifications from '../../utils/Notifications';
import Modal from '../../components/modal/Modal';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';
import { getTenantListPagination, initialTenantSetup } from '../../services/tenant/tenantProvider';
import AllTenantsTable from '../../components/tenant/AllTenantsTable';
import CreateTenantModal from '../../components/tenant/CreateTenantModal';
import PasswordModal from '../../components/tenant/PasswordModal';

class AllTenants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isModalShown: false,
      isPasswordModalShown: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.tenants'),
        ref: null,
        isActive: true,
      },
    ];
  };

  fetchTenantList = (amount, skip, sorting, filters, callback) => {
    getTenantListPagination({
      amount,
      skip,
      sortings: sorting,
      filters,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  openCreateTenantModal = () => {
    this.setState({ isModalShown: true });
  };

  closeModal = () => {
    this.setState({ isModalShown: false });
  };

  closePasswordModal = () => {
    this.setState({ isPasswordModalShown: false, adminPassword: null });
  };

  createTenant = (values) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
      isModalShown: false,
    });
    initialTenantSetup({
      tenant: { name: values.name, shortName: values.shortName },
      createAdmin: values.createAdmin,
    })
      .then((result) => {
        this.state.tableRef.updateTableData(false);
        Notifications.success(t('message.tenant_created', { name: values.name }));
        if (result && result.adminPassword) {
          this.setState({
            isPasswordModalShown: true,
            tenantInfo: result,
            isLoading: false,
          });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { isLoading, isModalShown, isPasswordModalShown, tableRef, tenantInfo } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer name={t('containers.tenants')} breadcrumbs={this.getBreadcrumbs()} />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.tenants')}
              buttons={[
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.openCreateTenantModal,
                  name: t('buttons.create'),
                  icon: faPlus,
                  securityModule: 'tenant',
                  securityPermission: 'create',
                },
              ]}
            />
            <AllTenantsTable
              fetchTenantList={this.fetchTenantList}
              setTableReference={this.setTableReference}
              tableReference={tableRef}
            />
          </div>
        </div>
        <Modal
          header={t('components.create_tenant')}
          isOpened={isModalShown}
          icon={faBuilding}
          onClose={this.closeModal}
        >
          <CreateTenantModal createTenant={this.createTenant} cancel={this.closeModal} />
        </Modal>

        <Modal
          header={t('components.admin_password')}
          isOpened={isPasswordModalShown}
          icon={faKey}
          onClose={this.closePasswordModal}
        >
          <PasswordModal tenantInfo={tenantInfo} cancel={this.closePasswordModal} />
        </Modal>
        {isLoading && <Loader />}
      </div>
    );
  }
}

AllTenants.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AllTenants);
