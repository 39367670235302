import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../feedbacks/styles/feedback-table.css';
import Moment from 'moment';
import { withTranslation } from 'react-i18next';
import PaginationTable from '../tables/PaginationTable';
import UserPreviewRenderer from '../tables/UserPreviewRenderer';
import ReviewStatusRenderer from './ReviewStatusRenderer';
import SubordinateReviewersRenderer from './SubordinateReviewersRenderer';
import ManagerSummaryColumnRenderer from './ManagerSummaryColumnRenderer';
import { AUTH_ROUTES } from '../../constants/routes';
import StringRenderer from '../tables/StringRenderer';

class EmployeesFeedbackAssignmentRequestsTable extends Component {
  constructor(props) {
    super(props);
    const { t, openAddReviewerModal } = props;
    this.state = {
      columns: [
        {
          Header: t('tables.employee'),
          id: 'employee',
          accessor: (d) => d.subordinate,
          Cell: UserPreviewRenderer,
          onClick: this.onClickUser,
          filterable: false,
        },
        {
          Header: t('tables.organization_unit'),
          id: 'orgUnitName',
          accessor: (d) => (d.subordinate ? d.subordinate.organizationUnitName : ''),
          Cell: StringRenderer,
          filterable: false,
        },
        {
          Header: t('tables.feedback'),
          id: 'feedbackDisplayName',
          accessor: (d) => d.feedbackDisplayName,
          Cell: StringRenderer,
          filterable: false,
        },
        {
          Header: t('tables.reviewers'),
          id: 'reviewers',
          width: 300,
          accessor: (d) => d.subordinateReviewers,
          Cell: SubordinateReviewersRenderer,
          onAddReviewer: openAddReviewerModal,
          sortable: false,
          filterable: false,
        },
        {
          Header: t('tables.completed_reviews'),
          id: 'completedReviewersCount',
          width: 120,
          accessor: (d) =>
            d.subordinateReviewers
              ? `${d.subordinateReviewers.length} / ${d.reviewersComplitedCount}`
              : d.reviewersComplitedCount,
          Cell: StringRenderer,
          filterable: false,
        },
        {
          Header: t('tables.end_date'),
          id: 'deadline',
          accessor: (d) => (d.periodEndDate ? Moment(d.periodEndDate).format('LL') : ''),
          Cell: StringRenderer,
          filterable: false,
        },
        {
          Header: t('tables.self_review'),
          id: 'selfReview',
          accessor: (d) => d.selfReviewStatus,
          Cell: ReviewStatusRenderer,
          filterable: false,
        },
        {
          Header: t('tables.manager_summary'),
          id: 'managerSummary',
          width: 220,
          accessor: (d) => d,
          Cell: ManagerSummaryColumnRenderer,
          onClick: this.onClickEditManagerSummary,
          filterable: false,
        },
      ],
      paginationPageSize: 10,
      minRows: 3,
      tableRef: null,
    };
  }

  onClickEditManagerSummary = (id) => {
    this.onClickUserEdit(id, true);
  };

  onClickUser = (id) => {
    this.onClickUserEdit(id, false);
  };

  onClickUserEdit = (id, isEdit) => {
    const { FEEDBACK } = AUTH_ROUTES;
    const { history } = this.props;
    const link = `${FEEDBACK.MANAGER_SUMMARY}/${id}`;
    history.push({
      pathname: link,
      state: {
        testAssignmentId: id,
        isManagerSummaryShow: isEdit,
      },
    });
  };

  render() {
    const { paginationPageSize, minRows, columns, defaultSorted } = this.state;
    const { tableReference, setTableReference, fetchFeedbackAssignmentList } = this.props;

    return (
      <div>
        <div>
          <PaginationTable
            ref={(elementRef) => {
              this.tableRef = elementRef;
              if (!tableReference && elementRef) {
                setTableReference(elementRef);
              }
            }}
            minRows={minRows}
            defaultSorted={defaultSorted}
            defaultPageSize={paginationPageSize}
            columns={columns}
            getDataFromServer={fetchFeedbackAssignmentList}
            className="-striped -highlight"
            rowHeight={100}
          />
        </div>
      </div>
    );
  }
}

EmployeesFeedbackAssignmentRequestsTable.propTypes = {
  fetchFeedbackAssignmentList: PropTypes.func.isRequired,
  setTableReference: PropTypes.func.isRequired,
  tableReference: PropTypes.object,
  openAddReviewerModal: PropTypes.func.isRequired,
};

export default withTranslation()(withRouter(EmployeesFeedbackAssignmentRequestsTable));
