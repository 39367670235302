import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik/dist/index';
import Moment from 'moment/moment';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import FormTitle from '../form/FormTitle';
import Can from '../security/Can';

function InnerForm(props) {
  const { t, feedbackAssignmentPeriod } = props;
  const feedbackLink = `/feedbacks/${feedbackAssignmentPeriod.feedbackId}/edit`;
  const targetLink = `/feedbacks/${feedbackAssignmentPeriod.feedbackId}/edit`;

  return (
    <div className="ibox">
      <FormTitle name={t('components.common_information')} />

      <div className="ibox-content clearfix">
        <dl className="row mb-0">
          <div className="col-sm-4 text-sm-right">
            <dt>{t('components.feedback')}</dt>
          </div>
          <div className="col-sm-8 text-sm-left">
            <Can
              module="feedback"
              permission="edit"
              yes={() => (
                <dd className="mb-1">
                  <NavLink to={feedbackLink}>
                    {feedbackAssignmentPeriod.feedback.displayString}
                  </NavLink>
                </dd>
              )}
              no={() => <dd className="mb-1">{feedbackAssignmentPeriod.feedback.displayString}</dd>}
            />
          </div>
        </dl>
        <dl className="row mb-0">
          <div className="col-sm-4 text-sm-right">
            <dt>{t('components.type')}</dt>
          </div>
          <div className="col-sm-8 text-sm-left">
            <dd className="mb-1">
              {feedbackAssignmentPeriod.type.label} (
              <b>{feedbackAssignmentPeriod.targetDisplayName}</b>)
            </dd>
          </div>
        </dl>
        <dl className="row mb-0">
          <div className="col-sm-4 text-sm-right">
            <dt>{t('components.created')}</dt>
          </div>
          <div className="col-sm-8 text-sm-left">
            <dd className="mb-1">{Moment(feedbackAssignmentPeriod.created).format('LL')}</dd>
          </div>
        </dl>
        {feedbackAssignmentPeriod.updated ? (
          <dl className="row mb-0">
            <div className="col-sm-4 text-sm-right">
              <dt>{t('components.updated')}</dt>
            </div>
            <div className="col-sm-8 text-sm-left">
              <dd className="mb-1">{Moment(feedbackAssignmentPeriod.updated).format('LL')}</dd>
            </div>
          </dl>
        ) : null}
      </div>
    </div>
  );
}

InnerForm.propTypes = {
  feedbackAssignmentPeriod: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

const GeneralFeedbackAssignmentPeriodInfo = withFormik({
  mapPropsToValues: (props) => ({}),
})(InnerForm);

export default withTranslation()(GeneralFeedbackAssignmentPeriodInfo);
