import { getTenant } from '../services/dataProvider';
import config from '../constants/config';

const UrlUtil = {
  buildImageSrcLink: (imageLink) => {
    let imageLinkCopy = imageLink;
    if (imageLink?.startsWith('/')) {
      imageLinkCopy = imageLink.substring(1);
    }
    return imageLinkCopy != null
      ? `${config.apiGateway.URL + imageLinkCopy}?tenant=${getTenant()}`
      : null;
  },
};

export default UrlUtil;
