import DatePicker, { registerLocale } from 'react-datepicker';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/datepicker.scss';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import ru from 'date-fns/locale/ru';

function InputComponent({
  name,
  field,
  form: { touched, errors },
  classNames,
  label,
  onChange,
  value,
  ...props
}) {
  const error = _.get(errors, name);
  const { i18n } = props;
  registerLocale('ru', ru);

  return (
    <FormGroup
      className={classnames('form-element', 'input-component', classNames, {
        'has-error': error && error.message,
      })}
    >
      {label && <ControlLabel>{label}</ControlLabel>}
      <DatePicker
        id={name}
        {...field}
        {...props}
        locale={i18n.language}
        selected={value}
        className="form-control"
        onChange={(values) => {
          onChange && onChange(values);
        }}
      />
      {error && error.message && (
        <HelpBlock className="error-text animated fadeIn fm-error-req">{error.message}</HelpBlock>
      )}
    </FormGroup>
  );
}

InputComponent.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  classNames: PropTypes.string,
};

export default withTranslation()(InputComponent);
