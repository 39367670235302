import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withFormik } from 'formik';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
import { TwitterPicker } from 'react-color';

import { withTranslation } from 'react-i18next';
import Validation from '../../../utils/validation';
import InputComponent from '../../inputs/Input';

function InnerForm(props) {
  const { t, handleSubmit, cancel, getQuestionClassificationByName } = props;
  const [pickerOpen, setPickerOpen] = useState(false);

  const handleColorChange = (c) => {
    props.values.color = c.hex;
    setPickerOpen(false);
  };

  const onTogglePicker = () => {
    setPickerOpen(!pickerOpen);
  };
  const validateName = (query) => {
    if (query) {
      return getQuestionClassificationByName(query).then((d) =>
        d ? t('message.already_exist') : null,
      );
    }
    return null;
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="modal-body">
        <Field
          component={InputComponent}
          placeholder={t('fields.question_tag_name')}
          type="text"
          name="name"
          label={t('fields.question_tag_name')}
          validate={(e) => validateName(e)}
        />
        <Field
          component={InputComponent}
          placeholder={t('fields.color')}
          type="text"
          name="color"
          disabled
          label={t('fields.color')}
          onClick={onTogglePicker}
          style={{
            cursor: 'pointer',
            backgroundColor: props.values.color,
            color: props.values.color ? 'white' : 'inherit',
            fontWeight: props.values.color ? 'bold' : 'inherit',
          }}
        />

        {pickerOpen && (
          <div style={{ position: 'absolute' }}>
            <TwitterPicker onChangeComplete={handleColorChange} />
          </div>
        )}
      </div>
      <div className="modal-footer">
        <button type="button" className="btn fm-btn-cancel" onClick={cancel}>
          {t('buttons.cancel')}
        </button>
        <button type="submit" className="btn fm-btn-primary">
          {t('buttons.create')}
        </button>
      </div>
    </Form>
  );
}

InnerForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  createQuestionClassification: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  getQuestionClassificationByName: PropTypes.func,
};

const CreateQuestionClassificationModal = withFormik({
  mapPropsToValues: () => ({
    name: '',
    color: '',
  }),
  validate: (values) => {
    const errors = {};
    errors.name = Validation.required(values.name);
    return _.isEmpty(_.filter(errors, (value) => value)) ? {} : errors;
  },
  handleSubmit: (values, props) => {
    props.props.createQuestionClassification(values);
  },
})(InnerForm);

export default withTranslation()(CreateQuestionClassificationModal);
