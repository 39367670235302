import React, { Component } from 'react';

import { faPlus, faSitemap } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  createFeedbackAssignment,
  getFeedbackAssignmentPagination,
} from '../../services/feedbackassignment/feedbackAssignmentProvider';

import Loader from '../../components/loader/Loader';
import Notifications from '../../utils/Notifications';
import Modal from '../../components/modal/Modal';

import AllFeedbackAssignmentsTable from '../../components/feedbackassignments/AllFeedbackAssignmentsTable';
import CreateFeedbackAssignmentModal from '../../components/feedbackassignments/CreateFeedbackAssignmentModal';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';

class AllFeedbackAssignments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      feedbackList: [],
      userList: [],
      isModalShown: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_feedback_assignments'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    this.populateDataLists();
  }

  populateDataLists = () => {};

  openCreateFeedbackAssignmentModal = () => {
    this.setState({ isModalShown: true });
  };

  closeCreateFeedbackAssignmentModal = () => {
    this.setState({ isModalShown: false });
  };

  createFeedbackAssignment = (values) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
      isModalShown: false,
    });
    createFeedbackAssignment(values)
      .then((data) => {
        this.state.tableRef.updateTableData(false);
        this.setState({ isLoading: false });
        Notifications.success(t('message.feedback_assignment_created'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  fetchFeedbackAssignmentList = (amount, skip, sorting, filters, callback) => {
    getFeedbackAssignmentPagination({
      amount,
      skip,
      sortings: sorting,
      filters,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { tableRef, userList, feedbackList, isLoading, isModalShown } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.all_feedback_assignments')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.all_feedback_assignments')}
              // buttons={ [{
              //     size: 'btn-xs',
              //     importance: 'fm-btn-primary',
              //     onClick: this.openCreateFeedbackAssignmentModal,
              //     name: t('buttons.create'),
              //     icon: faPlus,
              //     securityModule: 'feedback-assignments',
              //     securityPermission: 'create'
              // }] }
            />
            <AllFeedbackAssignmentsTable
              fetchFeedbackAssignmentList={this.fetchFeedbackAssignmentList}
              userList={userList}
              feedbackList={feedbackList}
              setTableReference={this.setTableReference}
              tableReference={tableRef}
            />
          </div>
        </div>
        <Modal
          header={t('components.create_feedback_assignment')}
          isOpened={isModalShown}
          icon={faSitemap}
          onClose={this.closeCreateFeedbackAssignmentModal}
        >
          <CreateFeedbackAssignmentModal
            createFeedbackAssignment={this.createFeedbackAssignment}
            cancel={this.closeCreateFeedbackAssignmentModal}
          />
        </Modal>
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default withTranslation()(AllFeedbackAssignments);
