import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import NewModalContext from '.';

export default function NewModalProvider({ children }) {
  const [isOpened, setIsOpened] = useState(false);
  const [component, setComponent] = useState([null, null]);

  const handleOpenModal = useCallback(({ component: Component, ...props }) => {
    setIsOpened(true);
    setComponent([Component, props]);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsOpened(false);
    setComponent([null, null]);
  }, []);

  const value = useMemo(() => ({
    openModal: handleOpenModal,
    closeModal: handleCloseModal,
    isOpened,
    component,
  }));

  return <NewModalContext.Provider value={value}>{children}</NewModalContext.Provider>;
}

NewModalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

NewModalProvider.defaultProps = {
  children: null,
};
