import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { AUTH_ROUTES, USER_GROUP_ID } from '../../constants/routes';
import AllUserGroups from '../../containers/user/AllUserGroups';
import EditUserGroupPage from '../../containers/user/EditUserGroupPage';

class UserGroupRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <div>
        <Route exact path={`${match.url}`} component={AllUserGroups} />
        <Route
          path={`${match.url}${USER_GROUP_ID}`}
          render={({ match }) => <UserGroupsInnerRouting match={match} />}
        />
      </div>
    );
  }
}

UserGroupRoutes.propTypes = {
  match: PropTypes.object,
};

export default UserGroupRoutes;

class UserGroupsInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let userGroupRouting;
    if (match.params.userGroupId) {
      switch (match.params.userGroupId) {
        default:
          userGroupRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.USER_GROUPS}${USER_GROUP_ID}`}
              component={AllUserGroups}
            />
          );
      }
    }

    return (
      <div>
        <Route
          exact
          path={`${AUTH_ROUTES.USER_GROUPS}${USER_GROUP_ID}/edit`}
          component={EditUserGroupPage}
        />
        {userGroupRouting}
      </div>
    );
  }
}

UserGroupsInnerRouting.propTypes = {
  match: PropTypes.object,
};
