import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import {
  getActivityStatus,
  getEmployeeJobLevel,
  updateEmployeeJobLevel,
} from '../../services/employeejoblevel/employeeJobLevelProvider';
import Notifications from '../../utils/Notifications';
import Loader from '../../components/loader/Loader';
import EditEmployeeJobLevelForm from '../../components/employeejoblevel/EditEmployeeJobLevelForm';
import PageNameContainer from '../../components/page/PageNameContainer';

class EditEmployeeJobLevelPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      employeeJobLevel: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_job_levels'),
        ref: '/org/employee-job-levels',
        isActive: false,
      },
      {
        name: t('breadcrumbs.job_level'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { params } = this.props.match;
    this.setState({ isLoading: true });

    const promises = [];

    promises.push(getEmployeeJobLevel(params.employeeJobLevelId));
    promises.push(getActivityStatus());

    Promise.all(promises)
      .then(([employeeJobLevel, statuses]) => {
        this.setState({
          employeeJobLevel,
          isLoading: false,
          statusOptions: statuses,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  updateEmployeeJobLevel = (data) => {
    const { t } = this.props;
    this.setState({ isLoading: true });
    updateEmployeeJobLevel(data)
      .then((_data) => {
        getEmployeeJobLevel(data.id).then((updatedEmployeeJobLevel) => {
          this.setState({
            employeeJobLevel: updatedEmployeeJobLevel,
            isLoading: false,
          });
        });
        Notifications.success(t('message.job_level_updated'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, employeeJobLevel, statusOptions } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.job_level')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-6">
              {employeeJobLevel && employeeJobLevel.id && (
                <EditEmployeeJobLevelForm
                  employeeJobLevel={employeeJobLevel}
                  statusOptions={statusOptions}
                  updateEmployeeJobLevel={this.updateEmployeeJobLevel}
                />
              )}
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

EditEmployeeJobLevelPage.propTypes = {
  employeeJob: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
};

export default withTranslation()(EditEmployeeJobLevelPage);
