import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Notifications from '../../utils/Notifications';
import { getTestByNameLike, getTestSelectItemSource } from '../../services/tests/testProvider';
import AsyncSelectComponent from '../react-hook-form-inputs/AsyncSelect';

function InnerForm(props) {
  const { t, onChangeTest } = props;

  const loadOptionsTests = (query, callback) => {
    getTestByNameLike(query)
      .then((data) => {
        callback && callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  };
  const [defaultSurveys, setDefaultSurveys] = useState([]);

  const schema = Yup.object().shape({
    surveyX: Yup.object().required(t('message.required_field')),
    surveyY: Yup.date().required(t('message.required_field')),
  });

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      surveyX: '',
      surveyY: '',
    },
  });

  useEffect(() => {
    const promises = [getTestSelectItemSource({ amount: 100, skip: 0 })];
    Promise.all(promises)
      .then(([defaultSurveys]) => {
        setDefaultSurveys(defaultSurveys);
      })
      .catch((error) => {
        Notifications.error(error);
      });
  }, []);

  return (
    <form>
      <Controller
        control={form.control}
        name="surveyX"
        defaultValue=""
        render={({ onChange, onBlur, value }) => (
          <AsyncSelectComponent
            form={form}
            value={value}
            onChange={(e) => {
              onChange && onChange(e);
              if (e) {
                onChangeTest('surveyX', e.value);
              }
            }}
            onBlur={onBlur}
            placeholder={t('fields.survey#', { number: 1 })}
            label={t('fields.survey#', { number: 1 })}
            loadOptions={loadOptionsTests}
            isMulti={false}
            isClearable
            defaultOptions={defaultSurveys}
            cacheOptions
          />
        )}
      />
      <Controller
        control={form.control}
        name="surveyY"
        defaultValue=""
        render={({ onChange, onBlur, value }) => (
          <AsyncSelectComponent
            form={form}
            value={value}
            onBlur={onBlur}
            onChange={(e) => {
              onChange && onChange(e);
              onChangeTest('surveyY', e.value);
            }}
            placeholder={t('fields.survey#', { number: 2 })}
            label={t('fields.survey#', { number: 2 })}
            loadOptions={loadOptionsTests}
            isMulti={false}
            isClearable
            defaultOptions={defaultSurveys}
            cacheOptions
          />
        )}
      />
    </form>
  );
}

InnerForm.propTypes = {
  onChangeTest: PropTypes.func.isRequired,
};

export default withTranslation()(InnerForm);
