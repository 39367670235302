import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AUTH_ROUTES, LOGIN, LOGIN_TENANT, SERVISE_ROUTES } from '../constants/routes';
import TestRoutes from './tests/TestRoutes';
import OrgUnitRoutes from './org/OrgUnitRoutes';
import OrgUnitDefRoutes from './org/OrgUnitDefRoutes';
import GlobalTestRoutes from './tests/GlobalTestRoutes';
import EmployeeRoutes from './org/EmployeeRoutes';
import UserRoutes from './users/UserRoutes';
import UserGroupRoutes from './users/UserGroupRoutes';
import UserRoleRoutes from './users/UserRoleRoutes';

import AuthBase from '../containers/authBase/AuthBase';
import Login from '../containers/auth/AuthContainer';
import Home from '../containers/home/Home';
import Profile from '../containers/user/Profile';

import UserFeedbackAssignmentProfile from '../containers/feedbacks/UserFeedbackAssignmentProfile';

import AccessDenied from '../containers/403';
import NotFound from '../containers/404';
import ServerError from '../containers/500';
import TestAssignmentRoutes from './tests/TestAssignmentRoutes';
import QuestionClassificationRoutes from './tests/QuestionClassificationRoutes';
import GlobalQuestionClassificationRoutes from './tests/GlobalQuestionClassificationRoutes';
import FeedbackRoutes from './feedback/FeedbackRoutes';
import FeedbackCategoryRoutes from './feedback/FeedbackCategoryRoutes';
import FeedbackAssignmentRoutes from './feedback/FeedbackAssignmentRoutes';
import AssignmentPeriodRoutes from './tests/AssignmentPeriodRoutes';
import EmployeeJobRoutes from './org/EmployeeJobRoutes';
import EmployeeJobLevelRoutes from './org/EmployeeJobLevelRoutes';
import LocationRoutes from './org/LocationRoutes';
import FeedbackAssignmentPeriodRoutes from './feedback/FeedbackAssignmentPeriodRoutes';
import CaseTrackingRoutes from './casemanagement/CaseTrackingRoutes';
import SurveyAnalyticRoutes from './tests/SurveyAnalyticRoutes';
import TenantRoutes from './tenant/TenantRoutes';

class AuthRoutes extends PureComponent {
  render() {
    const { account } = this.props;

    return (
      <Route>
        {!account.accessToken ? (
          <Switch>
            <Route path={LOGIN_TENANT} component={Login} />
            <Redirect from="*" to={LOGIN} />
          </Switch>
        ) : (
          <AuthBase>
            <Switch>
              <Route path={AUTH_ROUTES.HOME} component={Home} />
              <Route path={AUTH_ROUTES.PROFILE} component={Profile} />

              <Route
                path={`${AUTH_ROUTES.SURVEY_ANALYTICS.MAIN}`}
                render={({ match }) => <SurveyAnalyticRoutes match={match} />}
              />

              <Route
                path={`${AUTH_ROUTES.GLOBAL_TESTS}`}
                render={({ match }) => <GlobalTestRoutes match={match} />}
              />
              <Route
                path={`${AUTH_ROUTES.GLOBAL_TEST.GLOBAL_QUESTION_CLASSIFICATION}`}
                render={({ match }) => <GlobalQuestionClassificationRoutes match={match} />}
              />

              <Route
                path={`${AUTH_ROUTES.TESTS}`}
                render={({ match }) => <TestRoutes match={match} />}
              />
              <Route
                path={`${AUTH_ROUTES.TEST.QUESTION_CLASSIFICATION}`}
                render={({ match }) => <QuestionClassificationRoutes match={match} />}
              />

              <Route
                path={`${AUTH_ROUTES.FEEDBACKS}`}
                render={({ match }) => <FeedbackRoutes match={match} />}
              />

              <Route
                path={`${AUTH_ROUTES.USERS}`}
                render={({ match }) => <UserRoutes match={match} />}
              />

              <Route
                path={`${AUTH_ROUTES.USER_GROUPS}`}
                render={({ match }) => <UserGroupRoutes match={match} />}
              />

              <Route
                path={`${AUTH_ROUTES.USER_ROLES}`}
                render={({ match }) => <UserRoleRoutes match={match} />}
              />

              <Route
                exact
                path={`${AUTH_ROUTES.FEEDBACK.USER_PROFILE}`}
                component={UserFeedbackAssignmentProfile}
              />

              <Route
                path={`${AUTH_ROUTES.FEEDBACK.CATEGORIES}`}
                render={({ match }) => <FeedbackCategoryRoutes match={match} />}
              />
              <Route
                path={`${AUTH_ROUTES.FEEDBACK.ASSIGNMENTS}`}
                render={({ match }) => <FeedbackAssignmentRoutes match={match} />}
              />
              <Route
                path={`${AUTH_ROUTES.FEEDBACK_ASSIGNMENT_PERIODS}`}
                render={({ match }) => <FeedbackAssignmentPeriodRoutes match={match} />}
              />

              <Route
                path={`${AUTH_ROUTES.ORG.ORG_UNIT}`}
                render={({ match }) => <OrgUnitRoutes match={match} />}
              />
              <Route
                path={`${AUTH_ROUTES.ORG.ORG_UNIT_DEF}`}
                render={({ match }) => <OrgUnitDefRoutes match={match} />}
              />
              <Route
                path={`${AUTH_ROUTES.ORG.EMPLOYEES}`}
                render={({ match }) => <EmployeeRoutes match={match} />}
              />

              <Route
                path={`${AUTH_ROUTES.TENANT_MANAGEMENT}`}
                render={({ match }) => <TenantRoutes match={match} />}
              />

              <Route
                path={`${AUTH_ROUTES.CASE_MANAGEMENT.BUG_TRACKING}`}
                render={({ match }) => <CaseTrackingRoutes match={match} />}
              />

              <Route
                path={`${AUTH_ROUTES.ASSIGNMENT_PERIODS}`}
                render={({ match }) => <AssignmentPeriodRoutes match={match} />}
              />
              <Route
                path={`${AUTH_ROUTES.TEST_ASSIGNMENTS}`}
                render={({ match }) => <TestAssignmentRoutes match={match} />}
              />

              <Route
                path={`${AUTH_ROUTES.ORG.EMPLOYEE_JOBS}`}
                render={({ match }) => <EmployeeJobRoutes match={match} />}
              />
              <Route
                path={`${AUTH_ROUTES.ORG.EMPLOYEE_JOB_LEVELS}`}
                render={({ match }) => <EmployeeJobLevelRoutes match={match} />}
              />

              <Route
                path={`${AUTH_ROUTES.ORG.LOCATIONS}`}
                render={({ match }) => <LocationRoutes match={match} />}
              />

              <Route path={SERVISE_ROUTES['403']} component={AccessDenied} />
              <Route path={SERVISE_ROUTES['404']} component={NotFound} />
              <Route path={SERVISE_ROUTES['500']} component={ServerError} />

              <Redirect exact from={LOGIN} to={AUTH_ROUTES.HOME} />
              {/* <Redirect exact from={ REGISTRATION } to={ AUTH_ROUTES.DASHBOARD }/> */}
              <Redirect from="*" to={SERVISE_ROUTES['404']} />
            </Switch>
          </AuthBase>
        )}
      </Route>
    );
  }
}

AuthRoutes.propTypes = {
  account: PropTypes.object,
};

AuthRoutes.defaultProps = {
  account: {},
};

export default connect((state) => ({
  account: state.account,
}))(AuthRoutes);
