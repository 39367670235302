import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/test-passing.css';
import moment from 'moment';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import RocketStart from '../../../styles/img/rocket_start.png';

class StartPassingTestForm extends Component {
  render() {
    const { t, testAssignment, changeTestAssignmentStatus } = this.props;

    return (
      <div className="ibox">
        <div className="ibox-content clearfix fm-round-border-form">
          <div className="fm-image-center">
            <img src={RocketStart} />
          </div>
          <div className="fm-test-box text-center">
            <div className="fm-test-type">
              <span>{testAssignment.testType.toLowerCase()}</span>
            </div>

            <div className="fm-test-title">
              <span className="fm-test-name">{testAssignment.testTitle}</span>
              <span className="fm-estimation-time">
                <FontAwesomeIcon icon={faClock} />
                <span>
                  {' '}
                  {testAssignment.approximateTime} {t('components.min')}
                </span>
              </span>
            </div>
            <div className="fm-test-deadline">
              <span className="">
                {t('components.deadline')}: {moment(testAssignment.deadline).format('MMM Do')}
              </span>
            </div>
            <div className="fm-test-description">
              <p>{testAssignment.testDescription}</p>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn fm-start-test-btn"
                onClick={() => {
                  changeTestAssignmentStatus('InProgress');
                }}
              >
                {t('components.start')} {testAssignment.testType.toLowerCase()}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StartPassingTestForm.propTypes = {
  testAssignment: PropTypes.object.isRequired,
  changeTestAssignmentStatus: PropTypes.func.isRequired,
};

export default withTranslation()(StartPassingTestForm);
