import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { AUTH_ROUTES } from '../../constants/routes';
import UrlUtil from '../../utils/UrlUtil';
import { logoutAction } from '../../services/account/authActions';

function AvatarMenu(props) {
  const { account, dispatchLogout, t } = props;
  const [isShowMenu, setShowMenu] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const toggleUserMenu = () => {
    setShowMenu(!isShowMenu);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Escape') {
      setShowMenu(false);
    }
  };

  const handleHideMenu = (event) => {
    event.stopPropagation();
    if (
      event.target.id === 'fm-notification-items-btn' ||
      event.target.closest('#fm-avatar-circle-box')
    ) {
      return;
    }
    setShowMenu(false);
  };

  useEffect(() => {
    let employeeJob = account.employeeJob ? account.employeeJob : null;
    const employeeJobLevel = account.employeeJobLevel ? account.employeeJobLevel : null;
    if (employeeJobLevel) {
      employeeJob = `${employeeJobLevel} ${employeeJob}`;
    }

    setUserInfo({
      avatar: account.imageLink ? (
        <img
          alt="user"
          className="img-circle fm-avatar"
          src={UrlUtil.buildImageSrcLink(account.imageLink)}
        />
      ) : (
        <div className="fm-user-empty-photo">
          <FontAwesomeIcon icon={faUser} className="fm-user-img" />
        </div>
      ),
      username:
        account &&
        (account.firstName && account.lastName
          ? `${account.firstName} ${account.lastName}`
          : account.username),
      employeeJob,
    });
  }, [account]);
  useEffect(() => {
    document.addEventListener('mousedown', handleHideMenu);
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('mousedown', handleHideMenu);
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <div id="fm-avatar-circle-box" className="fm-avatar-circle-box dropdown">
      <button
        className="dropdown-toggle fm-user-menu-btn"
        id="dropdown-user-menu-toggle"
        onClick={toggleUserMenu}
      >
        {userInfo.avatar}
        <div className="fm-username-container">
          <div className="fm-display-name">{userInfo.username}</div>
          {userInfo.employeeJob && (
            <div className="fm-employee-position">{userInfo.employeeJob}</div>
          )}
        </div>
      </button>
      <ul className={`animated fadeIn m-t-xs dropdown-menu + ${isShowMenu ? ' show' : ''} `}>
        <li>
          <Link onClick={toggleUserMenu} className="nav-link" to={AUTH_ROUTES.PROFILE}>
            {t('link.profile')}
          </Link>
        </li>
        <li>
          <a onClick={dispatchLogout}>{t('link.logout')}</a>
        </li>
      </ul>
    </div>
  );
}

AvatarMenu.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  dispatchLogout: PropTypes.func,
  account: PropTypes.object,
};

const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLogout: () => {
    dispatch(logoutAction());
  },
});
export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AvatarMenu)),
);
