import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { AUTH_ROUTES, TEST_ID } from '../../constants/routes';

import AllTenantTests from '../../containers/tests/tenantsurveys/AllTenantSurveys';
import ViewEditTenantSurveyPage from '../../containers/tests/tenantsurveys/ViewEditTenantSurveyPage';
import AllUserTests from '../../containers/tests/usertests/AllUserTests';

import PassingTestPage from '../../containers/tests/passingtests/PassingTestPage';

class TestRoutes extends Component {
  render() {
    const { match } = this.props;

    return (
      <>
        <Route exact path={`${match.url}`} component={AllTenantTests} />
        <Route exact path={`${match.url}/user`} component={AllUserTests} />

        <Route
          path={`${match.url}${TEST_ID}`}
          render={({ match }) => <TenantTestInnerRouting match={match} />}
        />
      </>
    );
  }
}

TestRoutes.propTypes = {
  match: PropTypes.object,
};

export default TestRoutes;

class TenantTestInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let tenantTestRouting;

    if (match.params.testId) {
      switch (match.params.testId) {
        case 'create':
          tenantTestRouting = <Route path={`${match.url}`} component={ViewEditTenantSurveyPage} />;
          break;
        case 'all':
          tenantTestRouting = <Route path={`${match.url}`} component={AllTenantTests} />;
          break;
        default:
          tenantTestRouting = (
            <Route exact path={`${AUTH_ROUTES.TESTS}${TEST_ID}/pass`} component={PassingTestPage} />
          );
      }
    }

    return (
      <>
        <Route
          exact
          path={`${AUTH_ROUTES.TESTS}${TEST_ID}/edit`}
          component={ViewEditTenantSurveyPage}
        />
        {tenantTestRouting}
      </>
    );
  }
}

TenantTestInnerRouting.propTypes = {
  match: PropTypes.object,
};
