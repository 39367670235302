import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';

class AssignmentStatusRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = { id: props.original.id };
  }

  statusRenderer = (value) => {
    const { t } = this.props;
    switch (value) {
      case 'New':
        return (
          <div className="fm-status-btn fm-status-not-started">
            <span>{t('components.not_started')}</span>
          </div>
        );
      case 'Rejected':
        return (
          <div className="fm-status-btn fm-status-rejected">
            <FontAwesomeIcon icon={faTimes} />
            <span>{t('components.rejected')}</span>
          </div>
        );
      case 'Draft':
      case 'InProgress':
        // TODO add progress circle
        return (
          <div className="fm-status-btn fm-status-in-progress">
            <span>{t('components.in_progress')}</span>
          </div>
        );
      case 'Expired':
        // TODO add progress circle
        return (
          <div className="fm-status-btn fm-status-rejected">
            <span>{t('components.expired')}</span>
          </div>
        );
      case 'Finished':
        return (
          <div className="fm-status-btn fm-status-done">
            <FontAwesomeIcon icon={faCheck} />
            <span>{t('components.done')}</span>
          </div>
        );
    }
  };

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  render() {
    const { value } = this.props;
    return <div className="fm-assignment-status">{this.statusRenderer(value)}</div>;
  }
}

export default withTranslation()(AssignmentStatusRenderer);
