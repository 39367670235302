import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import MaterialHtmlTooltip from '../ui-elements/MaterialHtmlTooltip';

class ImpactSignificanceRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.original.id,
    };
  }

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  getIcon = (value) => {
    if (value === 'VeryHighSignificanceLevel' || value === 'HighSignificanceLevel') {
      return <FontAwesomeIcon icon={faExclamation} />;
    }
  };

  getTips = (value) => {
    if (value === 'VeryHighSignificanceLevel' || value === 'HighSignificanceLevel') {
      return (
        <Trans
          t={this.props.t}
          i18nKey="components.high_significance_level"
          components={{ italic: <i />, bold: <strong /> }}
          values={{ number: value === 'VeryHighSignificanceLevel' ? 99 : 95 }}
        />
      );
    }
    return '';
  };

  render() {
    const { value } = this.props;

    return (
      <MaterialHtmlTooltip title={this.getTips(value)} arrow>
        <div
          className="fm-table-icon"
          style={{
            color:
              value === 'VeryHighSignificanceLevel'
                ? '#34B77C'
                : value === 'HighSignificanceLevel'
                ? '#74ffba'
                : 'inherit',
            opacity:
              value === 'VeryHighSignificanceLevel' || value === 'HighSignificanceLevel' ? 1 : 0.5,
          }}
        >
          {this.getIcon(value)}
        </div>
      </MaterialHtmlTooltip>
    );
  }
}

ImpactSignificanceRenderer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ImpactSignificanceRenderer);
