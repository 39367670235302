import React, { Component } from 'react';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { getFeedbackPagination } from '../../services/feedback/feedbackProvider';
import Loader from '../../components/loader/Loader';
import Notifications from '../../utils/Notifications';

import AllFeedbackTable from '../../components/feedbacks/AllFeedbackTable';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';
import { AUTH_ROUTES, CREATE } from '../../constants/routes';

class AllFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_feedback_templates'),
        ref: null,
        isActive: true,
      },
    ];
  };

  fetchFeedbackList = (amount, skip, sorting, filters, callback) => {
    getFeedbackPagination({
      amount,
      skip,
      sortings: sorting,
      filters,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  onClickCreateFeedback = () => {
    this.props.history.push(`${AUTH_ROUTES.FEEDBACKS}${CREATE}`);
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { tableRef, isLoading } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.all_feedback_templates')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.all_feedback_templates')}
              buttons={[
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.onClickCreateFeedback,
                  name: t('buttons.create'),
                  icon: faPlus,
                  securityModule: 'feedback',
                  securityPermission: 'create',
                },
              ]}
            />
            <AllFeedbackTable
              fetchFeedbackList={this.fetchFeedbackList}
              setTableReference={this.setTableReference}
              tableReference={tableRef}
            />
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default withTranslation()(AllFeedback);
