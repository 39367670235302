import React from 'react';
import { withTranslation } from 'react-i18next';
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';
import moment from 'moment';
import UrlUtil from '../../utils/UrlUtil';
import './styles/employee-info-container.css';

function InnerForm(props) {
  const { t, employee } = props;

  const { user, employeeJob, location } = employee;

  return (
    <div className="fm-user-info-header">
      <div className="fm-user-info-container">
        <div className="fm-user-photo-box">
          <Avatar
            name={user.displayString}
            size={74}
            round
            src={UrlUtil.buildImageSrcLink(user ? user.profileImageLink : null)}
          />
        </div>
        <div className="fm-user-info-box">
          <span className="fm-user-info-name">{user.displayString}</span>
          {employeeJob && <span className="fm-user-info-job">{employeeJob.name}</span>}
        </div>
      </div>
      <div className="fm-employee-info-container">
        <div className="fm-employee-info-box">
          <div>
            <div className="fm-employee-info-box-value">
              {moment(employee.hireDate).format('LL')}
            </div>
            <div className="fm-employee-info-box-label">{t('components.hired')}</div>
          </div>
        </div>
        {location && (
          <div className="fm-employee-info-box">
            <div>
              <div className="fm-employee-info-box-value">{location.name}</div>
              <div className="fm-employee-info-box-label">{t('components.location')}</div>
            </div>
          </div>
        )}
        {employee.orgStructureUnitName && (
          <div className="fm-employee-info-box">
            <div>
              <div className="fm-employee-info-box-value">{employee.orgStructureUnitName}</div>
              <div className="fm-employee-info-box-label">{t('components.organization_unit')}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

InnerForm.propTypes = {
  employee: PropTypes.object.isRequired,
};
export default withTranslation()(InnerForm);
