import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { AUTH_ROUTES, TEST_ASSIGNMENT_ID } from '../../constants/routes';

import AllTestAssignments from '../../containers/tests/testassignment/AllTestAssignments';
import EditTestAssignment from '../../containers/tests/testassignment/EditTestAssignment';
import UserTestAssignments from '../../containers/tests/testassignment/UserTestAssignments';
import PassingTestPage from '../../containers/tests/passingtests/PassingTestPage';

class TestAssignmentRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <>
        <Route exact path={`${match.url}/all`} component={AllTestAssignments} />
        <Route exact path={`${match.url}/user`} component={UserTestAssignments} />

        <Route
          path={`${match.url}${TEST_ASSIGNMENT_ID}${'/:action'}`}
          render={({ match }) => <TestAssignmentRoutesInnerRouting match={match} />}
        />
      </>
    );
  }
}

TestAssignmentRoutes.propTypes = {
  match: PropTypes.object,
};

export default TestAssignmentRoutes;

class TestAssignmentRoutesInnerRouting extends Component {
  render() {
    const { match } = this.props;
    let testAssignmentRouting;
    if (match.params.testAssignmentId && match.params.action) {
      switch (match.params.action) {
        case 'edit':
          testAssignmentRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.TEST_ASSIGNMENTS}${TEST_ASSIGNMENT_ID}/edit`}
              component={EditTestAssignment}
            />
          );
          break;
        default:
          testAssignmentRouting = (
            <Route
              exact
              path={`${AUTH_ROUTES.TEST_ASSIGNMENTS}${TEST_ASSIGNMENT_ID}/pass`}
              component={PassingTestPage}
            />
          );
      }
    }

    return <>{testAssignmentRouting}</>;
  }
}

TestAssignmentRoutesInnerRouting.propTypes = {
  match: PropTypes.object,
};
