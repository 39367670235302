import { INIT_MENU } from '../../constants/actions';
import { getMenuByPermissions } from '../../constants/menuSettings';

const initialState = {
  items: [],
};

export default function menu(state = initialState, action) {
  switch (action.type) {
    case INIT_MENU:
      return {
        items: action.permissions ? getMenuByPermissions(action.permissions) : [],
      };
    default:
      return state || [];
  }
}
