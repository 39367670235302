import dataProvider from '../dataProvider';
import { questionclassification } from '../../constants/endpoints';

export const getQuestionClassificationList = (params) =>
  dataProvider({
    url: questionclassification.all(),
    params,
    method: 'GET',
  });

export const getQuestionClassification = (questionClassificationId) =>
  dataProvider({
    url: questionclassification.getById(questionClassificationId),
    method: 'GET',
  });

export const getQuestionClassificationByTestId = (testId) =>
  dataProvider({
    url: questionclassification.getByTestId(testId),
    method: 'GET',
  });

export const getQuestionClassificationByQuestionId = (questionId) =>
  dataProvider({
    url: questionclassification.getByQuestionId(questionId),
    method: 'GET',
  });

export const getQuestionClassificationByName = (name) =>
  dataProvider({
    url: questionclassification.getByName(name),
    method: 'GET',
  });

export const getQuestionClassificationByNameLike = (query) =>
  dataProvider({
    url: questionclassification.getByNameLike(query),
    method: 'GET',
  });

export const createQuestionClassification = (values) =>
  dataProvider({
    url: questionclassification.create(),
    method: 'POST',
    data: values,
  });

export const updateQuestionClassification = (params) =>
  dataProvider({
    url: questionclassification.update(params.id),
    data: params,
    method: 'PUT',
  });

export const deleteQuestionClassification = (questionClassificationId) =>
  dataProvider({
    url: questionclassification.delete(questionClassificationId),
    method: 'DELETE',
  });

export const getQuestionClassificationSelectItemSource = (params) =>
  dataProvider({
    url: questionclassification.itemSource(),
    method: 'POST',
    data: params,
  });

export const getQuestionClassificationPagination = (params) =>
  dataProvider({
    url: questionclassification.pagination(),
    data: params,
    method: 'POST',
  });
