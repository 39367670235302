import {
  LOGOUT,
  REGISTER_USER_DETAILS,
  REGISTER_USER_PHOTO,
  SIGN_IN,
} from '../../constants/actions';

export const loginAction = (data) => {
  localStorage.setItem(
    'account',
    JSON.stringify({
      accessToken: data.accessToken,
      refreshToken: data.refreshToken,
      accessTokenExpirationDate: data.accessTokenExpirationDate,
    }),
  );
  localStorage.setItem('tenant', JSON.stringify(data.tenant));
  return {
    type: SIGN_IN,
    accessToken: data.accessToken,
    refreshToken: data.refreshToken,
    accessTokenExpirationDate: data.accessTokenExpirationDate,
  };
};

export const setUserPhoto = (data) => ({
  type: REGISTER_USER_PHOTO,
  userPhoto: data.userPhoto,
});

export const setUserDetails = (data) => ({
  type: REGISTER_USER_DETAILS,
  userDetails: data.userDetails,
});

export const logoutAction = () => {
  const rememberMe = localStorage.getItem('rememberMe');
  const lastTenant = localStorage.getItem('lastTenant');
  localStorage.clear();
  if (rememberMe === 'true') {
    localStorage.setItem('lastTenant', lastTenant);
    localStorage.setItem('rememberMe', rememberMe);
  }
  return { type: LOGOUT };
};
