import React, { Component } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import Can from '../security/Can';

class FeedbackAssignmentRequestRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.original.id,
      exceptionStatuses: ['Finished', 'Rejected'],
    };
    this.onRejectFeedbackAssignment = props.column.onRejectFeedbackAssignment;
  }

  rejectFeedbackAssignment = () => {
    this.onRejectFeedbackAssignment(this.state.id);
  };

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  render() {
    const { assignmentStatus } = this.props.value;
    const { exceptionStatuses } = this.state;
    const { t } = this.props;

    return (
      <div className="fm-btn-group">
        {!exceptionStatuses.includes(assignmentStatus) && (
          <Can
            module="feedback-assignments"
            permission="reject"
            yes={() => (
              <button className="fm-btn" onClick={this.rejectFeedbackAssignment}>
                <FontAwesomeIcon icon={faTimes} /> <span>{t('buttons.reject')}</span>
              </button>
            )}
            no={() => null}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(FeedbackAssignmentRequestRenderer);
