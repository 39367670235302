import React, { Component } from 'react';
import './loader.css';
import Spinner from 'react-spinner-material';
import PropTypes from 'prop-types';

class ContentLoader extends Component {
  render() {
    const { visible } = this.props;
    return (
      <div className="fm-content-loader">
        <Spinner
          size={20}
          className="-fm-table-spinner"
          color="#34B77C"
          width={2}
          visible={visible}
        />
      </div>
    );
  }
}

ContentLoader.defaultProps = {
  visible: false,
};

ContentLoader.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default ContentLoader;
