import React, { Component } from 'react';
import { faLocationArrow, faPlus } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import {
  createLocation,
  getLocationByNameLike,
  getLocationPagination,
} from '../../services/location/locationProvider';
import Loader from '../../components/loader/Loader';
import Notifications from '../../utils/Notifications';
import Modal from '../../components/modal/Modal';
import AllLocationTable from '../../components/location/AllLocationTable';
import CreateLocationModal from '../../components/location/CreateLocationModal';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';

class AllLocations extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isModalShown: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_locations'),
        ref: null,
        isActive: true,
      },
    ];
  };

  fetchLocationList = (amount, skip, sorting, filters, callback) => {
    getLocationPagination({
      amount,
      skip,
      sortings: sorting,
      filters,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  openCreateLocationModal = () => {
    this.setState({ isModalShown: true });
  };

  closeModal = () => {
    this.setState({ isModalShown: false });
  };

  createLocation = (values) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
      isModalShown: false,
    });
    createLocation(values)
      .then((data) => {
        this.state.tableRef.updateTableData(false);
        this.setState({ isLoading: false });
        Notifications.success(t('message.location_created'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  render() {
    const { isLoading, isModalShown, tableRef } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.all_locations')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.all_locations')}
              buttons={[
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.openCreateLocationModal,
                  name: t('buttons.create'),
                  icon: faPlus,
                  securityModule: 'location',
                  securityPermission: 'create',
                },
              ]}
            />
            <AllLocationTable
              fetchLocationList={this.fetchLocationList}
              setTableReference={this.setTableReference}
              tableReference={tableRef}
            />
          </div>
        </div>
        <Modal
          header={t('components.create_location')}
          isOpened={isModalShown}
          icon={faLocationArrow}
          onClose={this.closeModal}
        >
          <CreateLocationModal
            createLocation={this.createLocation}
            getLocationByName={getLocationByNameLike}
            cancel={this.closeModal}
          />
        </Modal>
        {isLoading && <Loader />}
      </div>
    );
  }
}

export default withTranslation()(AllLocations);
