import React, { Component } from 'react';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import Loader from '../../components/loader/Loader';
import { getFeedbackAssignmentReviewersByAssignmentId } from '../../services/feedbackassignmentreviewer/feedbackAssignmentReviewerProvider';
import { getFeedbackAssignment } from '../../services/feedbackassignment/feedbackAssignmentProvider';
import EditFeedbackAssignmentForm from '../../components/feedbackassignments/EditFeedbackAssignmentForm';
import EditFeedbackAssignmentReviewersForm from '../../components/feedbackassignments/EditFeedbackAssignmentReviewersForm';
import Notifications from '../../utils/Notifications';
import { getFeedbackCriteriaByFeedbackId } from '../../services/feedbackcriteria/feedbackCriteriaProvider';
import PageNameContainer from '../../components/page/PageNameContainer';
import Can from '../../components/security/Can';
import FormTitle from '../../components/form/FormTitle';

class EditFeedbackAssignmentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showScrollTop: false,
      reviewerRef: null,
      feedbackAssignment: null,
      feedbackAssignmentReviewerList: null,
      formSubmitHandlers: {},
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_feedback_assignments'),
        ref: '/feedback-assignments',
        isActive: false,
      },
      {
        name: t('breadcrumbs.feedback_assignment'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { params } = this.props.match;
    this.setState({ isLoading: true });
    Promise.all([
      getFeedbackAssignment(params.feedbackAssignmentId),
      getFeedbackAssignmentReviewersByAssignmentId(params.feedbackAssignmentId),
    ])
      .then(([feedbackAssignment, feedbackAssignmentReviewerList]) => {
        _.forEach(feedbackAssignmentReviewerList, (k) => {
          k.isHidden = true;
        });
        getFeedbackCriteriaByFeedbackId(feedbackAssignment.feedback.id)
          .then((criteria) => {
            this.setState({
              isLoading: false,
              feedbackAssignment,
              feedbackAssignmentReviewerList,
              criteria,
            });
          })
          .catch((error) => {
            Notifications.error(error);
            this.setState({ isLoading: false });
          });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  handleSubmitForm = () => {
    const { t } = this.props;
    const { formSubmitHandlers } = this.state;
    const validationPromises = [];
    for (const value of Object.values(formSubmitHandlers)) {
      if (value.validate) {
        validationPromises.push(value.validate());
      }
    }
    Promise.all(validationPromises).then((checks) => {
      let valid = true;

      _.forEach(checks, (isValid) => {
        valid &&= isValid;
      });

      if (valid) {
        const promises = [];
        _.forEach(formSubmitHandlers, (form) => {
          promises.push(form.submit());
        });
        Promise.all(promises)
          .then(() => {
            Notifications.success(t('message.feedback_assignment_updated'));
          })
          .catch((error) => {
            Notifications.error(error);
          });
      }
    });
  };

  scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.scrollTo(0, 0);
    }
  };

  addFormSubmitHandler = (name, handler) => {
    const { formSubmitHandlers } = this.state;
    formSubmitHandlers[name] = handler;
  };

  render() {
    const {
      isLoading,
      feedbackAssignment,
      feedbackAssignmentReviewerList,
      showScrollTop,
      criteria,
    } = this.state;
    const { t } = this.props;

    return (
      <>
        <div>
          <div className="row wrapper">
            <div className="col-lg-12">
              <PageNameContainer
                name={t('containers.feedback_assignment')}
                breadcrumbs={this.getBreadcrumbs()}
              />
            </div>
          </div>
          <div className="wrapper-content">
            <div className="row">
              <div className="col-md-5">
                <div className="ibox">
                  <FormTitle name={t('components.common_information')} />

                  {feedbackAssignment && (
                    <div className="ibox-content clearfix">
                      <EditFeedbackAssignmentForm
                        addFormSubmitHandler={this.addFormSubmitHandler}
                        feedbackAssignment={feedbackAssignment}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="ibox">
                      <div className="ibox-title">
                        <span className="fm-form-name">
                          {t('components.feedback_assignment_reviewers')}
                        </span>
                      </div>
                      {feedbackAssignmentReviewerList && (
                        <EditFeedbackAssignmentReviewersForm
                          updateFeedbackAssignmentReviewers={this.updateFeedbackAssignmentReviewers}
                          reviewers={feedbackAssignmentReviewerList}
                          addFormSubmitHandler={this.addFormSubmitHandler}
                          feedbackAssignment={feedbackAssignment}
                          criteria={criteria}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isLoading && <Loader />}
          <Can
            module="feedback-assignments"
            permission="edit"
            yes={() => (
              <div className="footer fixed">
                <div className="btn-group pull-right" role="group">
                  <button
                    onClick={this.handleSubmitForm}
                    type="button"
                    className="btn fm-btn-primary"
                  >
                    {t('buttons.save')}
                  </button>
                </div>
              </div>
            )}
            no={() => null}
          />
        </div>
        {showScrollTop && (
          <button
            type="button"
            onClick={(e) => {
              this.scrollToTop(e);
            }}
            className="animated fadeIn"
            id="fm-scroll-questions-top"
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        )}
      </>
    );
  }
}

EditFeedbackAssignmentPage.propTypes = {};

export default withTranslation()(EditFeedbackAssignmentPage);
