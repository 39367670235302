import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

class ButtonRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.original.id,
      label: props.column.label,
      original: _.get(props.original, props.column.objectKeyPath)
        ? _.get(props.original, props.column.objectKeyPath)
        : props.original,
      icon: props.column.icon,
    };
    this.onClick = props.column.onClick;
  }

  onClickLink = () => {
    this.onClick(this.state.id);
  };

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({
        id: props.original.id,
        label: props.column.label,
        original: props.column.objectKeyPath
          ? _.get(props.original, props.column.objectKeyPath)
          : props.original,
        icon: props.column.icon,
      });
    }
  }

  render() {
    const { label, icon, original } = this.state;
    return (
      <div className="fm-btn-group">
        <button
          className="btn fm-btn"
          onClick={() => {
            this.onClick(original);
          }}
        >
          {icon ? <FontAwesomeIcon icon={icon} /> : null}
          {label}
        </button>
      </div>
    );
  }
}

export default ButtonRenderer;
