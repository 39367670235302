import React, { Component } from 'react';
import moment from 'moment';
import { faLock, faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

class SelfReviewStatusRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = { id: props.original.id };
    this.onClick = props.column.onClick;
  }

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  managerSummaryColumnRenderer = (obj) => {
    const { id } = this.state;
    const { t } = this.props;

    if (obj.periodStartDate && moment.utc(obj.periodStartDate).isAfter(moment())) {
      return (
        <div className="fm-status-btn fm-btn-disabled">
          <FontAwesomeIcon icon={faLock} />
          <span>{t('components.locked')}</span>
        </div>
      );
    }
    if (obj.periodEndDate && moment.utc(obj.periodEndDate).isBefore(moment())) {
      return (
        <div className="fm-status-btn fm-btn-success">
          <span>{t('components.finished')}</span>
        </div>
      );
    }
    if (obj.managerReviewStatus === 'Draft') {
      return (
        <div className="fm-btn-group">
          <button
            className="btn fm-table-btn fm-btn-xs-edit"
            onClick={this.onClick ? () => this.onClick(id) : null}
            title={t('components.edit')}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </button>
          <span className="fm-edit-summary-label">
            {`${t('components.added')} ${moment
              .utc(obj.managerDraftUpdated)
              .format('DD MMMM YYYY')}`}
          </span>
        </div>
      );
    }
    if (obj.managerReviewStatus === 'New' || !obj.managerReviewStatus) {
      return (
        <div className="fm-add-summary-box" onClick={this.onClick ? () => this.onClick(id) : null}>
          <div className="fm-add-circle-btn-s">
            <FontAwesomeIcon icon={faPlus} />
          </div>
          <div className="fm-add-summary-label">
            <span>{t('components.add_summary')}</span>
          </div>
        </div>
      );
    }
    return (
      <div className="fm-status-btn fm-btn-success">
        <span>{t('components.done')}</span>
      </div>
    );
  };

  render() {
    const { value } = this.props;
    return (
      <div className="fm-manager-summary-column">{this.managerSummaryColumnRenderer(value)}</div>
    );
  }
}

export default withTranslation()(SelfReviewStatusRenderer);
