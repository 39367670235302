import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles/user-assignments.css';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faFileImage } from '@fortawesome/free-regular-svg-icons';
import { faBomb } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { checkPermission } from '../../utils/SecurityUtil';
import FormeasureLogo from '../../styles/img/logo_formeasure.png';
import { AUTH_ROUTES } from '../../constants/routes';
import Notifications from '../../utils/Notifications';
import ContentLoader from '../loader/ContentLoader';
import { getTestAssignmentUserScreenInfo } from '../../services/testassignment/testAssignmentProvider';
import UrlUtil from '../../utils/UrlUtil';

class UserAssignmentsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testAssignments: null,
    };
  }

  componentDidMount() {
    const { account } = this.props;
    getTestAssignmentUserScreenInfo(account.userId)
      .then((data) => {
        this.setState({ testAssignments: data });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  onPassItem = (itemId) => {
    const { t, account } = this.props;
    const { permissions } = account;

    if (!checkPermission('test-assignments', permissions, 'pass')) {
      Notifications.warning(t('message.do_not_have_access_to_pass_a_survey'));
      return;
    }
    const { TEST_ASSIGNMENTS } = AUTH_ROUTES;
    const { history } = this.props;
    const link = `${TEST_ASSIGNMENTS}/${itemId}/pass`;
    history.push(link, {
      testAssignmentId: itemId,
      action: 'pass',
    });
  };

  render() {
    const { t } = this.props;
    const { testAssignments } = this.state;
    const assignments = testAssignments ? _.chunk(testAssignments, 2) : null;
    const DEADLINE_PERIOD = 7;

    return (
      <div className="ibox">
        <div className="ibox-content clearfix fm-user-assignments-ibox">
          <div className="fm-assignments-logo-box">
            <img src={FormeasureLogo} />
          </div>
          <div className="fm-assignments-title-box">
            <span>{t('message.you_have_voice_in_company_use_it')}</span>
          </div>
          <div className="fm-assignments-description-box">
            <span>{t('message.response_help_management_improve_processes')}</span>
          </div>

          {assignments && assignments.length > 0 && (
            <div className="fm-assignments-container">
              <div className="fm-assignments-box-wrapper">
                {assignments.map((articles, index) => (
                  <div className="row" key={index}>
                    {articles.map((testAssignment, aIndex) => {
                      const testImageLink = testAssignment ? testAssignment.testImageLink : null;
                      const now = moment(new Date());
                      const deadline = moment(new Date(testAssignment.deadline));
                      const deadLineMode =
                        testAssignment.status === 'Expired' ||
                        (deadline.diff(now, 'days') <= DEADLINE_PERIOD &&
                          testAssignment.status !== 'Finished');
                      return (
                        <div
                          className={`fm-test-assignment-box col${
                            deadLineMode ? ' fm-deadline-mode' : ''
                          }`}
                          key={aIndex}
                          onClick={() => this.onPassItem(testAssignment.id)}
                          style={{
                            marginLeft: aIndex % 2 === 0 ? '30px' : '20px',
                            marginRight: aIndex % 2 === 0 ? '20px' : '30px',
                          }}
                        >
                          <div className="fm-assignment-box-header">
                            <div
                              className="fm-assignments-test-name"
                              title={testAssignment.testTitle}
                            >
                              {testAssignment.testTitle}
                            </div>
                            <div
                              className="fm-assignments-progress"
                              style={{
                                color:
                                  testAssignment.status === 'Expired' || deadLineMode
                                    ? '#F4564E'
                                    : '#34B77C',
                              }}
                            >
                              {testAssignment.status === 'InProgress' ? (
                                <span>
                                  {t('components.progress')}: {testAssignment.progress.toFixed(0)}%
                                </span>
                              ) : (
                                <span>{testAssignment.status}</span>
                              )}
                            </div>
                          </div>
                          <div className="fm-assignments-progress-bar">
                            <div className="fm-assignment-progress-line" />
                            {(testAssignment.progress !== 0 ||
                              testAssignment.status === 'Finished' ||
                              testAssignment.status === 'Expired') && (
                              <div
                                style={{
                                  width: `${
                                    testAssignment.status === 'Finished' ||
                                    testAssignment.status === 'Expired'
                                      ? 100
                                      : testAssignment.progress
                                  }%`,
                                  borderColor: deadLineMode ? '#F4564E' : '#34B77C',
                                }}
                                className="fm-assignment-progress-line-filled"
                              />
                            )}
                          </div>
                          <div className="fm-assignment-box-body">
                            <div className="fm-assignments-test-image">
                              <div className="img-responsive fm-test-img">
                                {testImageLink ? (
                                  <img
                                    src={UrlUtil.buildImageSrcLink(testImageLink)}
                                    id="test-image"
                                    alt={t('components.survey_image')}
                                  />
                                ) : (
                                  <FontAwesomeIcon icon={faFileImage} />
                                )}
                              </div>
                            </div>
                            <div className="fm-assignments-test-description-box">
                              <div
                                className="fm-assignments-test-description"
                                title={
                                  testAssignment.welcomeText
                                    ? testAssignment.welcomeText
                                    : testAssignment.testDescription
                                }
                              >
                                {testAssignment.welcomeText
                                  ? testAssignment.welcomeText
                                  : testAssignment.testDescription}
                              </div>
                            </div>
                          </div>
                          <div className="fm-assignment-box-footer">
                            <div className="fm-assignment-box-footer-offset" />
                            <div className="fm-assignment-box-footer-info">
                              <span className="fm-assignment-estimation-box">
                                <FontAwesomeIcon icon={faClock} />
                                <span>{`${testAssignment.approximateTime} ${t(
                                  'components.min',
                                )}`}</span>
                              </span>
                              {testAssignment.status !== 'Finished' && (
                                <span
                                  className="fm-assignment-deadline-box"
                                  style={{ color: deadLineMode ? '#F4564E' : '#34B77C' }}
                                >
                                  {testAssignment.status === 'Expired' ? (
                                    <span>
                                      <FontAwesomeIcon icon={faBomb} />
                                      {deadline.calendar()}
                                    </span>
                                  ) : (
                                    <span>
                                      <FontAwesomeIcon icon={faBomb} />
                                      deadline {deadline.fromNow()}
                                    </span>
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          )}
          <ContentLoader visible={!assignments} />
        </div>
      </div>
    );
  }
}

UserAssignmentsComponent.propTypes = {
  t: PropTypes.func.isRequired,
  account: PropTypes.shape({
    userId: PropTypes.number.isRequired,
  }),
};

const mapStateToProps = (state) => ({
  account: state.account,
});

export default withTranslation()(connect(mapStateToProps)(withRouter(UserAssignmentsComponent)));
