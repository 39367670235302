import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classnames from 'classnames';
import _ from 'lodash';

import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';
import './styles/select.css';
import { withTranslation } from 'react-i18next';

function SelectComponent(props) {
  let {
    t,
    input,
    label,
    form: { touched, errors },
    options,
    classNames,
    field,
    labelKeyName,
    idKeyName,
    setFieldValue,
    onChange,
    isClearable,
    isSearchable,
    placeholder,
  } = props;

  options = _.map(options, (option) => ({
    value: option[idKeyName],
    label: option[labelKeyName],
  }));

  const defaultValue = field.value &&
    field.value[idKeyName] && {
      value: field.value[idKeyName],
      label: field.value[labelKeyName],
    };
  const hasError =
    field.name &&
    typeof field.name === 'string' &&
    field.name.split('.').reduce((o, i) => (o ? o[i] : false), touched) &&
    field.name.split('.').reduce((o, i) => (o ? o[i] : false), errors);

  return (
    <FormGroup className={`form-element ${classNames || ''}`}>
      {label && <ControlLabel>{label}</ControlLabel>}
      <Select
        {...input}
        name={field.name}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        options={options}
        placeholder={placeholder || t('fields.select')}
        noOptionsMessage={() => t('fields.no_options')}
        simpleValue
        isClearable={isClearable || false}
        isSearchable={isSearchable || false}
        defaultValue={defaultValue}
        onChange={(values) => {
          setFieldValue(field.name, values);
          onChange && onChange(values);
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#34b77cb5',
            primary25: '#34b77c29',
            primary50: '#34b77c73',
          },
        })}
        styles={{
          control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
            borderColor: hasError ? '#ff0000' : state.isFocused ? '#1ab394' : '#e5e6e7',
            '&:hover': {
              borderColor: hasError ? '#ff0000' : state.isFocused ? '#1ab394' : '#e5e6e7',
            },
          }),
        }}
        className={classnames(classNames, 'select-fld', { 'has-error': hasError })}
      />
      {hasError && (
        <HelpBlock className="error-text animated fadeIn">
          {field.name.split('.').reduce((o, i) => o[i], errors)}
        </HelpBlock>
      )}
    </FormGroup>
  );
}

SelectComponent.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classNames: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func,
  labelKeyName: PropTypes.string,
  idKeyName: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
};

SelectComponent.defaultProps = {
  labelKeyName: 'label',
  idKeyName: 'value',
};

export default withTranslation()(SelectComponent);
