import React, { Component } from 'react';

class QuestionClassificationActionsRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = { color: props.original.color };
  }

  componentDidUpdate(props, prevState) {
    if (props.original.color !== prevState.color) {
      this.setState({ color: props.original.color });
    }
  }

  render() {
    return (
      <div className="fm-color-renderer">
        {this.state.color && (
          <div
            style={{
              background: this.state.color,
              height: '21px',
              width: '21px',
              position: 'relative',
              outline: 'none',
              float: 'left',
              borderRadius: '4px',
              marginTop: '2px',
            }}
          />
        )}
      </div>
    );
  }
}

export default QuestionClassificationActionsRenderer;
