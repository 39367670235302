import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import FormHelperText from '@material-ui/core/FormHelperText';

function MaterialInput(props) {
  const {
    name,
    form: { errors },
  } = props;

  const error = _.get(errors, name);

  return (
    <div className="input-group has-validation">
      <TextField {...props} error={!!error} />
      {error ? (
        <FormHelperText className="error-text animated fadeIn">{error.message}</FormHelperText>
      ) : null}
    </div>
  );
}

MaterialInput.propTypes = {
  form: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  classNames: PropTypes.string,
  disabled: PropTypes.bool,
};

export default MaterialInput;
