import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import PositiveNegativeComponent from './PositiveNegativeComponent';
import Notifications from '../../utils/Notifications';
import PositiveSmile from '../svg/PositiveSmile';
import NeutralSmile from '../svg/NeutralSmile';
import NegativeSmile from '../svg/NegativeSmile';

import {
  getMonthAverageScore,
  getNegativeNeutralPositive,
} from '../../services/analytics/analyticsProvider';

import './styles/dashboard-overview.css';

import ProgressBarSemicircle from '../charts/ProgressBarSemicircle';

class DashboardWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scoreGraphData: {},
      scoreGraphOptions: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: '#979797',
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                suggestedMax: 5,
                suggestedMin: 0,
                fontColor: '#979797',
                stepSize: 1,
              },
              scaleLabel: {
                padding: {
                  bottom: 10,
                },
              },
              gridLines: {
                borderDash: [6, 4],
              },
            },
          ],
        },
      },
      benchmarkData: {},
      benchmarkOptions: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: '#979797',
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: '#979797',
                stepSize: 1,
              },
              scaleLabel: {
                padding: {
                  bottom: 10,
                },
              },
              gridLines: {
                borderDash: [6, 4],
                zeroLineColor: '#fed4c1',
              },
            },
          ],
        },
      },
      participationData: {},
      participationOptions: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: '#979797',
              },
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: '#979797',
                suggestedMax: 100,
                suggestedMin: 0,
                stepSize: 25,
                callback(value) {
                  return `${value}%`;
                },
              },
              scaleLabel: {
                padding: {
                  bottom: 10,
                },
              },
              gridLines: {
                borderDash: [5, 3],
              },
            },
          ],
        },
      },
    };
  }

  componentDidMount() {
    const { participation, score, benchmark, name } = this.props;

    const ctxScoreGraph = window.document.getElementById(`score-graph-${name}`).getContext('2d');
    const gradientScoreGraph = ctxScoreGraph.createLinearGradient(0, 0, 0, 280);
    gradientScoreGraph.addColorStop(0, 'rgba(81, 195, 234, 0.5)');
    gradientScoreGraph.addColorStop(0.5, 'rgba(81, 195, 234, 0.25)');
    gradientScoreGraph.addColorStop(1, 'rgba(81, 195, 234, 0)');

    const scoreGraphData = {
      labels: score.labels,
      datasets: [
        {
          fill: true,
          lineTension: 0.4,
          spanGaps: false,
          backgroundColor: gradientScoreGraph,
          borderColor: '#51C3EA',
          borderCapStyle: 'round',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'round',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#50c0e6',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: score.data ? score.data.map((v) => (v ? v.toFixed(1) : v)) : [],
        },
      ],
    };
    const participationData = {
      labels: participation.labels,
      datasets: [
        {
          fill: true,
          lineTension: 0.4,
          spanGaps: false,
          backgroundColor: gradientScoreGraph,
          borderColor: '#51C3EA',
          borderCapStyle: 'round',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'round',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#50c0e6',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: participation.data ? participation.data.map((v) => (v ? v.toFixed(1) : v)) : [],
        },
      ],
    };

    const ctxBenchmark = window.document.getElementById(`benchmark-graph-${name}`).getContext('2d');
    const gradientBenchmark = ctxBenchmark.createLinearGradient(0, 0, 0, 280);

    gradientBenchmark.addColorStop(0, 'rgba(81, 195, 234, 0.5)');
    gradientBenchmark.addColorStop(0.5, 'rgba(81, 195, 234, 0.25)');
    gradientBenchmark.addColorStop(1, 'rgba(81, 195, 234, 0)');

    const benchmarkData = {
      labels: benchmark.labels,
      datasets: [
        {
          fill: true,
          lineTension: 0.4,
          spanGaps: false,
          backgroundColor: gradientBenchmark,
          borderColor: '#51C3EA',
          borderCapStyle: 'round',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'round',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#50c0e6',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: benchmark.data ? benchmark.data.map((v) => (v ? v.toFixed(1) : v)) : [],
        },
      ],
    };
    const promises = [getMonthAverageScore(name), getNegativeNeutralPositive(name)];

    Promise.all(promises)
      .then(([averageScore, nnpScore]) => {
        let dynamicHTML = null;

        if (averageScore != null) {
          let dynamic = 0;
          let dynStr = '0%';
          if (averageScore.dynamicScore === 'Infinity' || averageScore.dynamicScore == 'NaN') {
            dynStr = 'N/A';
          } else if (averageScore.dynamicScore) {
            dynamic = averageScore.dynamicScore.toFixed(1);
            dynStr = dynamic < 0 ? `${dynamic * -1}%` : `${dynamic}%`;
          }

          let dArrow = '';

          if (dynamic < 0) {
            dArrow = <FontAwesomeIcon icon={faCaretDown} />;
          } else if (dynamic > 0) {
            dArrow = <FontAwesomeIcon icon={faCaretUp} />;
          }

          dynamicHTML = `<div class="fm-dynamic-extra-info">
                                           <span class='fm-dynamic-value' style='color:${averageScore.dynamicColor}'>${dynStr}</span>
                                           <span class="fm-dynamic-icon">${dArrow}</span>
                                       </div>`;
        }

        this.setState({
          scoreGraphData,
          participationData,
          benchmarkData,
          dynamicHTML,
          averageScore,
          suggestion: averageScore.message,
          nnpScore,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  getSmile = (type) => {
    if (type === 'negative') {
      return <NegativeSmile />;
    }

    if (type === 'neutral') {
      return <NeutralSmile />;
    }

    if (type === 'positive') {
      return <PositiveSmile />;
    }
  };

  render() {
    const {
      scoreGraphData,
      benchmarkData,
      participationData,
      scoreGraphOptions,
      benchmarkOptions,
      participationOptions,
      dynamicHTML,
      averageScore,
      suggestion,
      nnpScore,
    } = this.state;
    const { t, participation, benchmark, name, systemName } = this.props;

    return (
      <div className="ibox">
        <div className="ibox-content fm-ibox-title">
          <div className="row">
            <div className="col-lg-8 fm-right-border-solid">
              <div className="row fm-overview-widget-full-height">
                <div className="col-lg-5 fm-right-border-dash">
                  <div className="fm-dashboard-left-header">
                    <span>{name}</span>
                  </div>
                  <div className="fm-doughnut">
                    {dynamicHTML && (
                      <ProgressBarSemicircle
                        progress={averageScore.averageScore / 5}
                        averageColor={averageScore.averageColor}
                        dynamicHTML={dynamicHTML}
                      />
                    )}
                    <div className="fm-doughnut-legend-container" />
                    <div className="fm-doughnut-description">
                      <span>{suggestion}</span>
                    </div>
                  </div>
                  <div className="fm-positive-negative">
                    {nnpScore && <PositiveNegativeComponent nnp={nnpScore} />}
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="fm-dashboard-middle-header">
                    <span>
                      {name} {t('widgets.graph')}
                    </span>
                  </div>
                  <div className="fm-score-graph">
                    <Line
                      id={`score-graph-${systemName}`}
                      data={scoreGraphData}
                      options={scoreGraphOptions}
                    />
                  </div>
                  {participation.params.message && (
                    <div
                      className="fm-overview-suggest-box"
                      style={{
                        backgroundColor: participation.params.smileColor,
                      }}
                    >
                      <span className="fm-widget-suggest-smile">
                        {this.getSmile(participation.params.smileType)}
                      </span>
                      <span className="fm-widget-suggest-text">{participation.params.message}</span>
                    </div>
                  )}
                  {benchmark.params.message && (
                    <div
                      className="fm-overview-suggest-box"
                      style={{
                        backgroundColor: benchmark.params.smileColor,
                      }}
                    >
                      <span className="fm-widget-suggest-smile">
                        {this.getSmile(benchmark.params.smileType)}
                      </span>
                      <span className="fm-widget-suggest-text">{benchmark.params.message}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="fm-participation">
                <div className="fm-participatio-header">
                  <div className="fm-participation-label">{t('widgets.participation_score')}</div>
                  {Number.isFinite(participation.params.dynamic) && (
                    <div
                      className="fm-participation-value"
                      style={{ color: participation.params.dynamicColor }}
                    >
                      {participation.params.dynamic.toFixed(0)}%
                    </div>
                  )}
                </div>
                <div className="fm-participation-graph">
                  <Line
                    id={`participation-graph-${systemName}`}
                    data={participationData}
                    options={participationOptions}
                  />
                </div>
              </div>
              <div className="fm-benchmark">
                <div className="fm-benchmark-header">
                  <div className="fm-benchmark-label">{t('widgets.benchmark')}</div>
                  {Number.isFinite(benchmark.params.dynamic) && (
                    <div
                      className="fm-benchmark-value"
                      style={{ color: benchmark.params.dynamicColor }}
                    >
                      {benchmark.params.dynamic.toFixed(1)}
                    </div>
                  )}
                </div>
                <div className="fm-benchmark-graph">
                  <Line
                    id={`benchmark-graph-${systemName}`}
                    data={benchmarkData}
                    options={benchmarkOptions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DashboardWidget.propTypes = {
  name: PropTypes.string,
  participation: PropTypes.object,
  score: PropTypes.object,
  benchmark: PropTypes.object,
  dynamic: PropTypes.object,
};
export default withTranslation()(DashboardWidget);
