import React, { Component } from 'react';
import { faCloudUploadAlt, faPlus, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  createEmployee,
  getEmployeePagination,
  importEmployee,
  validateEmployeeImportFile,
  getEmployeeExpectedImportRows,
} from '../../services/employee/employeeProvider';
import Loader from '../../components/loader/Loader';
import Notifications from '../../utils/Notifications';
import Modal from '../../components/modal/Modal';
import AllEmployeeTable from '../../components/employee/AllEmployeeTable';
import CreateEmployeeModal from '../../components/employee/CreateEmployeeModal';
import ImportModal from '../../components/imports/ImportModal';
import { getByNameLike } from '../../services/orgunit/orgUnitProvider';
import { getNotEmployedUsersLike } from '../../services/user/userProvider';
import PageNameContainer from '../../components/page/PageNameContainer';
import FormTitle from '../../components/form/FormTitle';
import { getEmployeeJobByNameLike } from '../../services/employeejob/employeeJobProvider';
import { getEmployeeJobLevelByNameLike } from '../../services/employeejoblevel/employeeJobLevelProvider';
import { getLocationByNameLike } from '../../services/location/locationProvider';

class AllEmployees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isCreateModalShown: false,
      isImportModalShown: false,
      tableRef: null,
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;
    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_employees'),
        ref: null,
        isActive: true,
      },
    ];
  };

  fetchEmployeeList = (amount, skip, sorting, filters, callback) => {
    getEmployeePagination({
      amount,
      skip,
      sortings: sorting,
      filters,
    })
      .then((data) => {
        callback(data);
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  openImportEmployeeModal = () => {
    this.setState({ isImportModalShown: true });
  };

  openCreateEmployeeModal = () => {
    this.setState({ isCreateModalShown: true });
  };

  closeCreateModal = () => {
    this.setState({ isCreateModalShown: false });
  };

  closeImportModal = () => {
    this.setState({
      isImportModalShown: false,
      importValidationResult: null,
    });
  };

  createEmployee = (values) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
      isCreateModalShown: false,
    });
    createEmployee(values)
      .then(() => {
        this.state.tableRef.updateTableData(false);
        this.setState({ isLoading: false });
        Notifications.success(t('message.employee_created'));
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  };

  resetValidationResult = () => {
    this.setState({ importValidationResult: null });
  };

  setTableReference = (tableRef) => {
    this.setState({ tableRef });
  };

  afterImportCallback = () => {
    const { t } = this.props;
    this.state.tableRef.updateTableData(false);
    this.setState({
      isLoading: false,
      isImportModalShown: false,
    });
    Notifications.success(t('message.employee_created'));
  };

  onImportError = (error) => {
    Notifications.error(error);
    this.setState({
      isLoading: false,
    });
  };

  render() {
    const { isLoading, isCreateModalShown, isImportModalShown, tableRef } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.employees')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle
              name={t('components.employees')}
              buttons={[
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.openCreateEmployeeModal,
                  name: t('buttons.create'),
                  icon: faPlus,
                  securityModule: 'employees',
                  securityPermission: 'create',
                },
                {
                  size: 'btn-xs',
                  importance: 'fm-btn-primary',
                  onClick: this.openImportEmployeeModal,
                  name: t('buttons.import'),
                  securityModule: 'global-tests',
                  securityPermission: 'import',
                },
              ]}
            />
            <AllEmployeeTable
              fetchEmployeeList={this.fetchEmployeeList}
              setTableReference={this.setTableReference}
              tableReference={tableRef}
            />
          </div>
        </div>
        <Modal
          header={t('containers.create_employee')}
          isOpened={isCreateModalShown}
          icon={faUserAlt}
          onClose={this.closeCreateModal}
        >
          <CreateEmployeeModal
            createEmployee={this.createEmployee}
            cancel={this.closeCreateModal}
            getUsers={getNotEmployedUsersLike}
            getOrgUnits={getByNameLike}
            getEmployeeJobs={getEmployeeJobByNameLike}
            getEmployeeJobLevels={getEmployeeJobLevelByNameLike}
            getLocations={getLocationByNameLike}
          />
        </Modal>
        <Modal
          header={t('containers.import_employee')}
          isOpened={isImportModalShown}
          icon={faCloudUploadAlt}
          onClose={this.closeImportModal}
        >
          <ImportModal
            performImport={importEmployee}
            performImportFileValidation={validateEmployeeImportFile}
            expectedRowsCall={getEmployeeExpectedImportRows}
            afterImport={this.afterImportCallback}
            onError={this.onImportError}
            cancel={this.closeImportModal}
          />
        </Modal>
        {isLoading && <Loader />}
      </div>
    );
  }
}

AllEmployees.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AllEmployees);
