import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: '#575757',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: '0 2px 9px 0 rgba(0,0,0,0.14)',
    padding: '15px',
  },
  arrow: {
    color: '#FFFFFF',
    '&:before': {
      border: '1px solid #dadde9',
      boxShadow: '0 2px 9px 0 rgba(0,0,0,0.14)',
    },
  },
}))(Tooltip);
