/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext } from 'react';

const defaultContextValue = {
  isOpen: false,
  component: null,
};

const NewModalContext = createContext(defaultContextValue);

export const useNewModal = () => useContext(NewModalContext);

export const withNewModal = (Component) => (props) =>
  (
    <NewModalContext.Consumer>
      {(modalProps) => <Component {...modalProps} {...props} />}
    </NewModalContext.Consumer>
  );

export default NewModalContext;
