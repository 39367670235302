import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { TwitterPicker } from 'react-color';
import { faBars, faClone, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { withTranslation } from 'react-i18next';
import FeedbackCriteria from './FeedbackCriteria';
import TextArea from '../react-hook-form-inputs/TextArea';
import Input from '../react-hook-form-inputs/Input';

function FeedbackCriteriaDescriptionForm(props) {
  const { t, criterion, calcSortOrder, index, isHidden, feedbackCriteriaDescriptions } = props;
  const form = useFormContext();
  const { control, register, setValue, watch, getValues } = form;
  const fieldArray = useFieldArray({
    control,
    name: `criteria[${index}].feedbackCriteriaDescriptions`,
    keyName: 'feedbackCriteriaDescriptionKeyId',
  });

  const { fields, append, remove } = fieldArray;

  const watchFieldArray = watch(`criteria[${index}].feedbackCriteriaDescriptions`);
  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index],
  }));
  const [isOpenColorPicker, setOpenColorPicker] = useState(false);
  const [color, setColor] = useState(null);
  const [colorPickerStyles, setColorPickerStyles] = useState({});
  const [editableCriteriaIndex, setEditableCriteriaIndex] = useState(null);
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (feedbackCriteriaDescriptions) {
      append(feedbackCriteriaDescriptions);
    }
  }, [append]);

  const addFeedbackCriteriaDescription = () => {
    append({
      id: '',
      tenantId: criterion.tenantId,
      feedbackCriteriaId: criterion.id,
      value: generateValue(),
      color: '#34B77C',
      label: '',
      description: '',
      systemType: 'Platform:FeedbackCriteriaDescription',
      action: 'create',
      sortOrder: calcSortOrder(controlledFields),
    });
  };

  const copyFeedbackCriteriaDescription = (cd) => {
    const newCriteria = _.cloneDeep(cd);
    append({
      ...newCriteria,
      id: null,
      action: 'create',
      value: generateValue(),
    });
  };
  const prefix = `criteria[${index}].feedbackCriteriaDescriptions`;

  const removeFeedbackCriteriaDescription = (_index, cd) => {
    let cValue = cd.value;

    for (let i = _index + 1; i < controlledFields.length; i++) {
      if (controlledFields[i].action === 'delete') {
        continue;
      }
      controlledFields[i].value = cValue++;
      controlledFields[i].action = controlledFields[i].id ? 'update' : 'create';
    }
    if (cd.id) {
      controlledFields[_index].action = 'delete';
      setValue(`${prefix}[${_index}]`, controlledFields[_index]);
    } else {
      remove(_index);
    }
    setRender(!render);
  };

  const pickColor = (e, color, index) => {
    const parent = e.nativeEvent.target.closest('.-one-line');
    const styles = {
      top: `${parent.offsetTop + 35}px`,
      left: `${parent.offsetLeft - 177}px`,
    };
    setColorPickerStyles(styles);
    setColor(color);
    setEditableCriteriaIndex(index);
    setOpenColorPicker(!isOpenColorPicker);
  };

  const generateValue = () => {
    let nextOrder = 0;
    _.forEach(controlledFields, (c) => {
      if (nextOrder < c.value && c.action !== 'delete') {
        nextOrder = c.value;
      }
    });
    nextOrder++;
    return nextOrder;
  };

  const handleColorChange = (e) => {
    if (editableCriteriaIndex < 0) {
      return;
    }
    controlledFields[editableCriteriaIndex] = {
      ...controlledFields[editableCriteriaIndex],
      color: e.hex ? e.hex : null,
      action: controlledFields[editableCriteriaIndex].id ? 'update' : 'create',
    };

    setValue(`${prefix}[${editableCriteriaIndex}]`, controlledFields[editableCriteriaIndex]);
    setOpenColorPicker(false);
  };
  const descriptions = watch(prefix);

  return (
    <>
      {controlledFields && !isHidden && (
        <div className="fm-feedback-criteria-descriptions-label">
          {t('components.feedback_criterion_description')}
        </div>
      )}
      {isOpenColorPicker && (
        <div className="fm-color-picker-box" style={colorPickerStyles}>
          <TwitterPicker color={color} triangle="top-right" onChangeComplete={handleColorChange} />
        </div>
      )}
      <div className="fm-feedback-criteria-descriptions-box">
        {isHidden && <FeedbackCriteria feedbackCriteriaDescriptions={descriptions} />}
        {controlledFields.map((criteriaDescription, i) => (
          <div
            className="fm-feedback-criteria-description-box -one-line"
            key={`${prefix}[${i}].feedbackCriteriaDescriptionKeyId`}
          >
            <input
              type="hidden"
              name={`${prefix}[${i}].id`}
              ref={register()}
              defaultValue={criteriaDescription.id}
            />
            <input
              type="hidden"
              name={`${prefix}[${i}].tenantId`}
              ref={register()}
              defaultValue={criteriaDescription.tenantId}
            />
            <input
              type="hidden"
              name={`${prefix}[${i}].color`}
              ref={register()}
              defaultValue={criteriaDescription.color}
            />
            <input
              type="hidden"
              name={`${prefix}[${i}].systemType`}
              ref={register()}
              defaultValue={criteriaDescription.systemType}
            />
            <input
              type="hidden"
              name={`${prefix}[${i}].action`}
              ref={register()}
              defaultValue={criteriaDescription.action}
            />
            <input
              type="hidden"
              name={`${prefix}[${i}].feedbackCriteriaId`}
              ref={register()}
              defaultValue={criteriaDescription.feedbackCriteriaId}
            />
            <input
              type="hidden"
              name={`${prefix}[${i}].value`}
              ref={register()}
              defaultValue={criteriaDescription.value}
            />
            <input
              type="hidden"
              name={`${prefix}[${i}].sortOrder`}
              ref={register()}
              defaultValue={criteriaDescription.sortOrder}
            />
            <div
              className={`fm-criterion-description-box ${
                criteriaDescription.action === 'delete' ? 'fm-display-none' : ''
              }`}
            >
              {!isHidden && (
                <div className="fm-criterion-description-sub-controls">
                  <div className="fm-criterion-description-value">
                    <b>{criteriaDescription.value}</b>
                  </div>
                  <div className="fm-criterion-description-drag-n-drop-control">
                    <FontAwesomeIcon icon={faBars} />
                  </div>
                  <div
                    onClick={(e) => {
                      pickColor(e, criteriaDescription.color, i);
                    }}
                    className="fm-color-square"
                    style={{
                      background: criteriaDescription.color,
                    }}
                  />
                </div>
              )}
              <div className="fm-criterion-fields">
                <Controller
                  control={control}
                  name={`${prefix}[${i}].label`}
                  defaultValue={criteriaDescription.label}
                  render={({ onChange, onBlur, value, name }) => (
                    <Input
                      name={name}
                      placeholder={`${t('fields.criterion_label')} ${i + 1}`}
                      form={form}
                      onBlur={onBlur}
                      maxLength="256"
                      onChange={(event) => {
                        if (
                          controlledFields[i].action !== 'update' &&
                          controlledFields[i].action !== 'delete'
                        ) {
                          controlledFields[i].action = criteriaDescription.id ? 'update' : 'create';
                        }
                        controlledFields[i].label = event.target.value;
                        setValue(`${prefix}[${i}].label`, controlledFields[i]);
                        onChange(event);
                      }}
                      value={value}
                      classNames={`fm-feedback-criteria-description-label ${
                        isHidden || criteriaDescription.action === 'delete' ? 'fm-display-none' : ''
                      }`}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`${prefix}[${i}].description`}
                  defaultValue={
                    criteriaDescription.description ? criteriaDescription.description : ''
                  }
                  render={({ onChange, onBlur, value, name }) => (
                    <TextArea
                      name={name}
                      form={form}
                      placeholder={`${t('fields.description')} ${i + 1}`}
                      onBlur={onBlur}
                      onChange={(event) => {
                        if (
                          controlledFields[i].action !== 'update' &&
                          controlledFields[i].action !== 'delete'
                        ) {
                          controlledFields[i].action = criteriaDescription.id ? 'update' : 'create';
                        }
                        controlledFields[i].description = event.target.value;
                        setValue(`${prefix}[${i}]`, controlledFields[i]);
                        onChange(event);
                      }}
                      value={value}
                      classNames={`fm-feedback-criteria-description-description ${
                        isHidden || criteriaDescription.action === 'delete' ? 'fm-display-none' : ''
                      }`}
                    />
                  )}
                />
              </div>
              {!isHidden && (
                <div className="fm-criteria-description-controls">
                  <div className="fm-icon">
                    <FontAwesomeIcon
                      icon={faClone}
                      onClick={() => {
                        copyFeedbackCriteriaDescription(criteriaDescription);
                      }}
                    />
                  </div>
                  <div className="fm-icon">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      onClick={() => {
                        removeFeedbackCriteriaDescription(i, criteriaDescription);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
        {!isHidden && (
          <div className="fm-add-feedback-criteria-description-container">
            <div
              className="fm-add-feedback-criteria-description-box"
              onClick={(event) => addFeedbackCriteriaDescription(event)}
            >
              <div className="fm-add-feedback-criteria-description-image">
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div className="fm-add-feedback-criteria-description-label">
                {t('components.add_feedback_criterion_description')}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default withTranslation()(FeedbackCriteriaDescriptionForm);
