import React, { Component } from 'react';
import './styles/heatmap.css';
import chroma from 'chroma-js';
import classnames from 'classnames';
import SwapVertRoundedIcon from '@material-ui/icons/SwapVertRounded';
import { Trans, withTranslation } from 'react-i18next';
import Notifications from '../../utils/Notifications';
import HeatmapSettingsForm from './HeatmapSettingsForm';
import {
  getHeatmapAnalytics,
  getHeatmapAnalyticsAdditionalRow,
  getHeatmapStrategies,
} from '../../services/analytics/analyticsProvider';
import Loader from '../loader/Loader';
import NoDataForm from '../form/NoDataForm';
import MaterialHtmlTooltip from '../ui-elements/MaterialHtmlTooltip';

class FormeasureHeatmapWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      strategies: [],
      colors: [
        '#F4574F',
        '#F6756A',
        '#F89486',
        '#FAB0A0',
        '#FDD1BD',
        '#FFF9E1',
        '#C9E8C6',
        '#A5DCB5',
        '#80D0A2',
        '#5BC48F',
        '#34B77C',
      ],
    };
  }

  componentDidMount() {
    getHeatmapStrategies()
      .then((data) => {
        this.setState({
          strategies: data.items,
        });
      })
      .catch((error) => {
        Notifications.error(error);
      });
    this.checkLoadAnalytics();
  }

  checkLoadAnalytics = () => {
    // let data = testData.getMainData();
    // this.setState({data: data});

    const { testId, strategy } = this.state;

    if (testId && strategy) {
      this.setState({ isLoading: true });
      getHeatmapAnalytics(strategy.value, { testId })
        .then((data) => {
          this.setState({ data, isLoading: false });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          Notifications.error(error);
        });
    }
  };

  onChangeStrategy = (v) => {
    this.setState(
      {
        strategy: v,
      },
      this.checkLoadAnalytics,
    );
  };

  onChangeTest = (v) => {
    this.setState(
      {
        testId: v.value,
      },
      this.checkLoadAnalytics,
    );
  };

  getBackgroundColor = (value, min, max, reverse) => {
    if (value === 'NaN') {
      return '#F5F5F5';
    }
    const { colors } = this.state;
    const step = (max - min) / (colors.length - 1);
    return colors[Math.round(reverse ? 10 - (value - min) / step : (value - min) / step)];
  };

  getLabelColor = (color) => (color && chroma(color).luminance() > 0.5 ? '#575757' : 'white');

  loadAdditionalData = (rowNumber) => {
    const { data, isLoading } = this.state;
    if (isLoading) {
      return;
    }
    if (data.labels.y[rowNumber].refId) {
      return;
    }
    const _data = data.data;
    const _labels = data.labels;

    this.setState({ isLoading: true });

    if (_labels.y[rowNumber].id === data.activeNode) {
      _labels.y.forEach((value) => {
        if (value.refId) {
          value.hidden = true;
          value.isActive = false;
        } else {
          value.isActive = true;
        }
      });
      data.activeNode = null;
    } else {
      _labels.y.forEach((value, index) => {
        if (index === rowNumber || value.refId === _labels.y[rowNumber].id) {
          if (value.refId) {
            value.hidden = false;
          }
          value.isActive = true;
          // add addition if not added
        } else {
          if (value.refId) {
            value.hidden = true;
          }
          value.isActive = false;
        }
      });
      data.activeNode = _labels.y[rowNumber].id;
    }

    _labels.y[rowNumber].isActive = true;

    if (!_labels.y[rowNumber].added) {
      const { testId, strategy } = this.state;

      getHeatmapAnalyticsAdditionalRow(strategy.value, {
        testId,
        questionClassificationId: _labels.y[rowNumber].id,
      })
        .then((response) => {
          const _lbls = [];
          const _dt = [];

          response.forEach((value) => {
            _lbls.push({
              label: value.label,
              details: value.details,
              min: value.min,
              max: value.max,
              hidden: false,
              isActive: true,
              isChild: true,
              reverse: _labels.y[rowNumber].reverse,
              refId: _labels.y[rowNumber].id,
            });
            _dt.push(value.data);
          });

          _labels.y.splice(rowNumber + 1, 0, ..._lbls);
          _data.splice(rowNumber + 1, 0, ..._dt);

          _labels.y[rowNumber].added = true;

          data.data = _data;
          data.labels = _labels;
          this.setState({ data, isLoading: false });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          Notifications.error(error);
        });
    } else {
      data.data = _data;
      data.labels = _labels;
      this.setState({ data, isLoading: false });
    }
  };

  render() {
    const { t, data, strategies, strategy, isLoading } = this.state;
    return (
      <div className="fm-heatmap-container">
        {strategy && (
          <div className="fm-heatmap-header-box">
            <h2>{strategy.label}</h2>
          </div>
        )}
        <div className="row">
          <div className="fm-heatmap-settings col col-lg-3 col-md-12">
            {strategies && (
              <HeatmapSettingsForm
                strategies={strategies}
                onChangeStrategy={this.onChangeStrategy}
                onChangeTest={this.onChangeTest}
              />
            )}
          </div>
          {data && (
            <div className="fm-heatmap col col-lg-9 col-md-12">
              <div className="fm-heatmap-label-box">
                <div className="fm-heatmap-header-empty" />
                <div className="fm-heatmap-row-label fm-heatmap-summary">
                  <div className="fm-heatmap-label">{data.summary.label}</div>
                  <div className="fm-heatmap-details">{data.summary.details}</div>
                </div>
                {data.labels.y.map((row, rowIndex) => {
                  const isActive = !data.activeNode || row.isActive;
                  const { isChild, reverse, hidden } = data.labels.y[rowIndex];

                  return (
                    <div
                      className={classnames(
                        'fm-heatmap-row-label',
                        { 'fm-heatmap-active': isActive },
                        { 'fm-heatmap-inactive': !isActive },
                        { 'fm-heatmap-hidden-row': hidden },
                        { 'fm-heatmap-children-row': isChild },
                      )}
                      key={`row-label-${rowIndex}`}
                      onClick={() => {
                        this.loadAdditionalData(rowIndex);
                      }}
                    >
                      <div className="fm-heatmap-label">
                        {row.label}
                        {reverse && !isChild && (
                          <MaterialHtmlTooltip
                            title={<Trans t={t} i18nKey="fields.reverse_scaling_clarification" />}
                            arrow
                          >
                            <span className="fm-heatmap-additional-info">
                              <SwapVertRoundedIcon />
                            </span>
                          </MaterialHtmlTooltip>
                        )}
                      </div>
                      <div className="fm-heatmap-details">{row.details}</div>
                    </div>
                  );
                })}
              </div>
              <div className="fm-heatmap-value-box">
                <div className="fm-heatmap-row fm-heatmap-header-row">
                  {data.labels.x.map((o, headerIndex) => (
                    <div className="fm-heatmap-header" key={`header-${headerIndex}`}>
                      <div title={o.label}>{o.label}</div>
                    </div>
                  ))}
                </div>
                <div className="fm-heatmap-values-box fm-heatmap-summary-value-box">
                  {data.summary.data.map((value, colIndex) => (
                    <div className="fm-heatmap-col" key={`col-${colIndex}`}>
                      <span>{value !== 'NaN' ? value.toFixed(0) : 'n/a'}</span>
                    </div>
                  ))}
                </div>
                {data.data.map((row, rowIndex) => {
                  const { min, max, reverse, hidden, isActive } = data.labels.y[rowIndex];
                  return (
                    <div
                      className={`fm-heatmap-values-box ${
                        !data.activeNode || isActive ? 'fm-heatmap-active' : 'fm-heatmap-inactive'
                      } ${hidden ? 'fm-heatmap-hidden-row' : ''}`}
                      key={`row-${rowIndex}`}
                    >
                      {row.map((value, colIndex) => {
                        const color = this.getBackgroundColor(value, min, max, reverse);
                        return (
                          <div
                            className="fm-heatmap-col"
                            key={`col-${colIndex}`}
                            style={{
                              backgroundColor: color,
                              color: this.getLabelColor(color),
                            }}
                          >
                            <span>{value !== 'NaN' ? value.toFixed(1) : 'n/a'}</span>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {!data && (
            <div className="col col-lg-9 col-md-12">
              <NoDataForm label="" paddingBottom={150} />
            </div>
          )}
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

FormeasureHeatmapWidget.propTypes = {};
export default withTranslation()(FormeasureHeatmapWidget);
