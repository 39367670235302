import {
  SET_TEST_ASSIGNMENT_DETAILS,
  SET_TEST_ASSIGNMENT_TESTS,
  SET_TEST_ASSIGNMENT_USERS,
} from '../../constants/actions';

const initialState = {
  testAssignment: {},
  users: [],
  tests: [],
};
export default function testAssignment(state = initialState, action) {
  switch (action.type) {
    case SET_TEST_ASSIGNMENT_DETAILS:
      return {
        ...state,
        testAssignment: action.testAssignment,
      };
    case SET_TEST_ASSIGNMENT_TESTS:
      return {
        ...state,
        tests: action.tests,
      };
    case SET_TEST_ASSIGNMENT_USERS:
      return {
        ...state,
        users: action.users,
      };
    default:
      return state || null;
  }
}
