import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import Notifications from '../../../utils/Notifications';
import Loader from '../../../components/loader/Loader';
import {
  getQuestionClassificationTemplate,
  getQuestionClassificationTemplateByName,
  updateQuestionClassificationTemplate,
} from '../../../services/questionclassificationtemplate/questionClassificationTemplateProvider';
import EditQuestionClassificationForm from '../../../components/tests/questionclassification/EditQuestionClassificationForm';
import PageNameContainer from '../../../components/page/PageNameContainer';
import FormTitle from '../../../components/form/FormTitle';

class EditQuestionClassificationTemplatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      questionClassification: {},
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_tag_templates'),
        ref: '/global-test-settings/question-classifications',
        isActive: false,
      },
      {
        name: t('breadcrumbs.tag_template'),
        ref: null,
        isActive: true,
      },
    ];
  };

  componentDidMount() {
    const { params } = this.props.match;
    this.setState({ isLoading: true });

    getQuestionClassificationTemplate(params.globalQuestionClassificationId)
      .then((d) => {
        this.setState({
          questionClassification: d,
          isLoading: false,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({ isLoading: false });
      });
  }

  updateQuestionClassificationTemplate = (values) => {
    const { t } = this.props;

    this.setState({ isLoading: true });

    updateQuestionClassificationTemplate(values)
      .then(() => {
        this.setState({ isLoading: false });
        this.props.history.push('/global-test-settings/question-classifications');
        Notifications.success(t('message.tag_template_updated'));
      })
      .catch((e) => {
        Notifications.error(e);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, questionClassification } = this.state;

    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.tag_template')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content">
          <div className="ibox">
            <FormTitle name={t('components.tag_template')} />
            <div className="row">
              <div className="col-md-12">
                {questionClassification && questionClassification.id && (
                  <EditQuestionClassificationForm
                    {...questionClassification}
                    securityModule="global-question-classification"
                    updateQuestionClassification={this.updateQuestionClassificationTemplate}
                    getQuestionClassificationByName={getQuestionClassificationTemplateByName}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

EditQuestionClassificationTemplatePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(EditQuestionClassificationTemplatePage);
