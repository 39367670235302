import {
  faBriefcase,
  faBug,
  faBuilding,
  faChartBar,
  faCity,
  faComments,
  faCube,
  faCubes,
  faFileAlt,
  faGlobe,
  faHome,
  faLocationArrow,
  faSitemap,
  faUserFriends,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { hasPermission } from '../utils/SecurityUtil';

export const getMenuByPermissions = (permissions) => {
  if (!permissions || !permissions.length) {
    return [];
  }

  return [
    {
      label: 'menu.home',
      link: '/home',
      itemId: 'home',
      icon: faHome,
      isShown: hasPermission(permissions, [
        'current-tenant:everything',
        'global:home',
        'global:home:view-all',
      ]),
      children: null,
    },
    {
      label: 'menu.survey_analytics',
      itemId: 'survey-analytics',
      icon: faChartBar,
      isShown: hasPermission(permissions, [
        'current-tenant:everything',
        'survey-analytics:everything',
        'survey-analytics:overview',
        'survey-analytics:heatmap',
        'survey-analytics:impact',
      ]),
      children: [
        {
          label: 'menu.dashboard',
          itemId: 'dashboard',
          parentItemId: 'survey-analytics',
          description: 'Dashboard',
          link: '/survey-analytics/dashboard',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'survey-analytics:everything',
            'survey-analytics:dashboard',
          ]),
        },
        {
          label: 'menu.heatmap',
          itemId: 'heatmap',
          parentItemId: 'survey-analytics',
          description: 'Heatmap',
          link: '/survey-analytics/heatmap',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'survey-analytics:everything',
            'survey-analytics:heatmap',
          ]),
        },
        {
          label: 'menu.impact',
          itemId: 'impact',
          parentItemId: 'survey-analytics',
          description: 'Impact',
          link: '/survey-analytics/impact',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'survey-analytics:everything',
            'survey-analytics:impact',
          ]),
        },
      ],
    },
    {
      label: 'menu.global_templates',
      description: 'Global specific surveys, only sa available to create this type of surveys',
      itemId: 'global-tests',
      icon: faGlobe,
      isShown: hasPermission(permissions, [
        'current-tenant:everything',
        'global-tests:everything',
        'global-tests:view-all',
        'global-question-classification:everything',
        'global-question-classification:view-all',
      ]),
      children: [
        {
          label: 'menu.global_test_templates',
          itemId: 'all-global-tests',
          parentItemId: 'global-tests',
          link: '/global-tests/all',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'global-tests:everything',
            'global-tests:view-all',
          ]),
        },
        {
          label: 'menu.tags',
          itemId: 'all-question-tpl-classifications',
          parentItemId: 'global-tests',
          description: 'Global tags configuration',
          link: '/global-test-settings/question-classifications',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'global-question-classification:everything',
            'global-question-classification:view-all',
          ]),
        },
      ],
    },
    {
      label: 'menu.my_surveys',
      itemId: 'test-assignments',
      icon: faFileAlt,
      isShown: hasPermission(permissions, [
        'current-tenant:everything',
        'test-assignments:everything',
        'test-assignments:view-all',
        'test-assignments:view-personal',
        'test-assignments:pass',
        'test-assignment-periods:everything',
        'test-assignment-periods:view-all',
      ]),
      children: [
        {
          label: 'menu.group_assignments',
          description: 'All Schedules',
          itemId: 'assignment-periods',
          parentItemId: 'test-assignments',
          link: '/assignment-periods',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'test-assignment-periods:everything',
            'test-assignment-periods:view-all',
          ]),
        },
        {
          label: 'menu.my_templates',
          itemId: 'all-tenant-tests',
          parentItemId: 'test-assignments',
          description: 'All tenant tests available for passing',
          link: '/tests/all',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'tests:everything',
            'tests:view-all',
          ]),
        },
        {
          label: 'menu.company_test_assignments',
          description: 'All created survey assignments',
          itemId: 'all-test-assignments',
          parentItemId: 'test-assignments',
          link: '/test-assignments/all',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'test-assignments:everything',
            'test-assignments:view-all',
          ]),
        },
        {
          label: 'menu.take_surveys',
          itemId: 'all-available-tests',
          parentItemId: 'test-assignments',
          description: 'Surveys available for assignment',
          link: '/tests/available',
          isShown: false,
        },
        {
          label: 'menu.take_survey',
          description: 'All survey assignments assigned to me',
          itemId: 'my-tests',
          parentItemId: 'test-assignments',
          link: '/test-assignments/user',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'test-assignments:view-personal',
            'test-assignments:pass',
          ]),
        },
        {
          label: 'menu.question_tags',
          itemId: 'all-question-classifications',
          parentItemId: 'test-assignments',
          description: 'Tags configuration',
          link: '/test-settings/question-classifications',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'question-classification:everything',
            'question-classification:view-all',
          ]),
        },
      ],
    },
    {
      label: 'menu.my_feedback',
      itemId: 'feedback-assignments',
      icon: faComments,
      isShown: hasPermission(permissions, [
        'current-tenant:everything',
        'feedback-assignments:everything',
        'feedback-assignments:view-all',
        'feedback-assignment-periods:everything',
        'feedback-assignment-periods:view-all',
        'feedback-assignments:view-subordinate-requests',
        'feedback-assignments:view-requests',
        'feedback-assignments:view-personal-results',
      ]),
      children: [
        {
          label: 'menu.feedback_schedules',
          description: 'All feedback schedule',
          itemId: 'feedback-assignment-periods',
          parentItemId: 'feedback-assignments',
          link: '/feedback-assignment-periods',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'feedback-assignment-periods:everything',
            'feedback-assignment-periods:view-all',
          ]),
        },
        {
          label: 'menu.feedback_templates',
          itemId: 'all-tenant-feedback',
          parentItemId: 'feedback-assignments',
          description: 'All tenant feedback.',
          link: '/feedback/all',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'feedback:everything',
            'feedback:view-all',
          ]),
        },
        {
          label: 'menu.company_feedback_assignments',
          description: 'All created feedback requests',
          itemId: 'all-feedback-assignments',
          parentItemId: 'feedback-assignments',
          link: '/feedback-assignments/all',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'feedback-assignments:everything',
            'feedback-assignments:view-all',
          ]),
        },
        {
          label: 'menu.my_profile',
          description: 'User Feedback Profile',
          itemId: 'user-feedback-profile',
          parentItemId: 'feedback-assignments',
          link: '/feedback-assignments/user-profile',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'feedback-assignments:everything',
            'feedback-assignments:view-personal-results',
          ]),
        },
        {
          label: 'menu.subordinates_requests',
          description: 'All feedback requests assigned to manager subordinates',
          itemId: 'manager-subordinate-feedback-assignments',
          parentItemId: 'feedback-assignments',
          link: '/feedback-assignments/manager-subordinate-requests',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'feedback-assignments:everything',
            'feedback-assignments:view-subordinate-requests',
          ]),
        },
        {
          label: 'menu.all_employees_feedback_requests',
          description: 'All employees feedback requests assigned (for HRs)',
          itemId: 'all-employees-requests',
          parentItemId: 'feedback-assignments',
          link: '/feedback-assignments/all-employees-requests',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'feedback-assignments:everything',
            'feedback-assignments:all-employees-feedback-requests',
          ]),
        },
        {
          label: 'menu.my_requests',
          description: 'All requested feedback requests assigned to me',
          itemId: 'my-feedback-assignments',
          parentItemId: 'feedback-assignments',
          link: '/feedback-assignments/requests',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'feedback-assignments:everything',
            'feedback-assignments:view-requests',
          ]),
        },
        {
          label: 'menu.feedback_categories',
          itemId: 'feedback-categories',
          parentItemId: 'feedback-assignments',
          description: 'Feedback categories configuration',
          link: '/feedback-settings/categories',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'feedback-categories:everything',
            'feedback-categories:view-all',
          ]),
        },
      ],
    },
    {
      label: 'menu.people',
      description: 'Tenant specific users. Menu item for managing user objects.',
      itemId: 'users',
      icon: faUserFriends,
      isShown: hasPermission(permissions, [
        'current-tenant:everything',
        'users:everything',
        'users:view-all',
        'user-groups:everything',
        'user-groups:view-all',
        'user-roles:view-all',
        'user-roles:everything',
      ]),
      children: [
        {
          label: 'menu.users',
          itemId: 'all-users',
          parentItemId: 'users',
          description: 'Users.',
          link: '/users',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'users:everything',
            'users:view-all',
          ]),
        },
        {
          label: 'menu.user_groups',
          itemId: 'user-groups',
          parentItemId: 'users',
          description: 'User groups configuration',
          link: '/user-groups',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'user-groups:everything',
            'user-groups:view-all',
          ]),
        },
        {
          label: 'menu.roles',
          itemId: 'user-roles',
          parentItemId: 'users',
          description: 'User roles configuration',
          link: '/user-roles',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'user-roles:view-all',
            'user-roles:everything',
          ]),
        },
      ],
    },
    {
      label: 'menu.organizations',
      itemId: 'organizations',
      icon: faSitemap,
      isShown: hasPermission(permissions, [
        'current-tenant:everything',
        'org-unit:everything',
        'org-unit:view-all',
        'org-unit-definition:everything',
        'org-unit-definition:view-all',
        'employees:everything',
        'employees:view-all',
        'jobs:everything',
        'jobs:view-all',
        'job-levels:everything',
        'job-levels:view-all',
        'location:everything',
        'location:view-all',
      ]),
      children: [
        {
          label: 'menu.organization_unit',
          itemId: 'org-unit',
          parentItemId: 'organizations',
          link: '/org/org-unit',
          icon: faSitemap,
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'org-unit:everything',
            'org-unit:view-all',
          ]),
        },
        {
          label: 'menu.org_unit_types',
          itemId: 'org-unit-definition',
          parentItemId: 'organizations',
          link: '/org/org-unit-def',
          icon: faListAlt,
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'org-unit-definition:everything',
            'org-unit-definition:view-all',
          ]),
        },
        {
          label: 'menu.employees',
          itemId: 'employees',
          parentItemId: 'organizations',
          icon: faUsers,
          link: '/org/employee',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'employees:everything',
            'employees:view-all',
          ]),
        },
        {
          label: 'menu.job',
          itemId: 'jobs',
          parentItemId: 'organizations',
          icon: faCube,
          link: '/org/employee-jobs',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'jobs:everything',
            'jobs:view-all',
          ]),
        },
        {
          label: 'menu.job_level',
          itemId: 'employee-job-levels',
          parentItemId: 'organizations',
          icon: faCubes,
          link: '/org/employee-job-levels',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'job-levels:everything',
            'job-levels:view-all',
          ]),
        },
        {
          label: 'menu.location',
          itemId: 'locations',
          parentItemId: 'organizations',
          icon: faLocationArrow,
          link: '/org/locations',
          isShown: hasPermission(permissions, [
            'current-tenant:everything',
            'location:everything',
            'location:view-all',
          ]),
        },
      ],
    },
    {
      label: 'menu.case_management',
      itemId: 'case-management',
      icon: faBriefcase,
      isShown: hasPermission(
        permissions,
        ['bug-tracking:everything', 'bug-tracking:view-all'],
        true,
      ),
      children: [
        {
          label: 'menu.bug_tracking',
          itemId: 'bug-tracking',
          parentItemId: 'case-management',
          icon: faBug,
          link: '/case-management/bug-tracking',
          isShown: hasPermission(
            permissions,
            ['bug-tracking:everything', 'bug-tracking:view-all'],
            true,
          ),
        },
      ],
    },
    {
      label: 'menu.tenant-management',
      itemId: 'tenant-management',
      icon: faCity,
      isShown: hasPermission(permissions, ['everything:everything', 'tenant:view-all'], true),
      children: [
        {
          label: 'menu.tenants',
          itemId: 'tenants',
          parentItemId: 'tenant-management',
          icon: faBuilding,
          link: '/tenant-management/tenants',
          isShown: hasPermission(permissions, ['everything:everything', 'tenant:view-all'], true),
        },
      ],
    },
  ];
};
