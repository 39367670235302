import React, { Component } from 'react';
import UserPhotoUtil from '../../utils/UserPhotoUtil';

class UserPreviewRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.original.id,
      data: props.value,
    };
    this.onClick = props.column.onClick;
  }

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id, data: props.value });
    }
  }

  render() {
    const { id, data } = this.state;
    return (
      <div
        className="fm-table-user-info-container fm-clickable"
        onClick={this.onClick ? () => this.onClick(id) : null}
      >
        {data && (
          <div className="fm-table-user-avatar-small-box">
            {UserPhotoUtil.renderUserPhotoByLink(data.profileImageLink)}
          </div>
        )}
        {data && (
          <div className="fm-table-user-info">
            {data.fullName && (
              <span className="fm-table-username" title={data.fullName}>
                {data.fullName}
              </span>
            )}

            {data.employeeJob && (
              <span className="fm-table-user-job" title={data.employeeJob ? data.employeeJob : ''}>
                {`${data.employeeJobLevel ? data.employeeJobLevel : ''} ${
                  data.employeeJob ? data.employeeJob : ''
                }`}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default UserPreviewRenderer;
