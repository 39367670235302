import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { withTranslation } from 'react-i18next';
import EditDeleteActionsRenderer from '../tables/EditDeleteActionsRenderer';
import { deleteEmployeeFromOrgUnit } from '../../services/orgunit/orgUnitProvider';
import './styles/orgunit-table.css';
import Notifications from '../../utils/Notifications';
import { AUTH_ROUTES } from '../../constants/routes';
import PaginationTable from '../tables/PaginationTable';
import UserRenderer from '../tables/UserRenderer';

class OrgUnitMembersForm extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      columns: [
        {
          Header: t('tables.employee'),
          id: 'employee',
          Cell: UserRenderer,
          sortable: false,
          filterable: false,
          accessor: (d) => ({
            employeeJob: d.employeeJob,
            user: d.user,
            userKeyPath: 'user',
            employeeJobKeyPath: 'employeeJob',
          }),
        },
        {
          id: 'actions',
          Cell: EditDeleteActionsRenderer,
          sortable: false,
          filterable: false,
          onViewItem: this.onViewItem,
          onDeleteItem: this.onDeleteItem,
          securityModule: 'employee',
        },
      ],
      SweetAlertModal: withReactContent(Swal),
      paginationPageSize: 10,
      minRows: 3,
      tableRef: null,
    };
  }

  onDeleteItem = (itemId) => {
    const { SweetAlertModal } = this.state;
    const { t } = this.props;
    return new Promise((resolve, reject) => {
      SweetAlertModal.fire({
        title: t('message.are_you_sure'),
        showConfirmButton: true,
        showCancelButton: true,
        text: t('message.you_are_about_to_delete_employee_from_organization'),
        confirmButtonColor: '#1ab394',
        confirmButtonText: t('message.yes_delete_it'),
        cancelButtonText: t('buttons.cancel'),
        showCloseButton: true,
        icon: 'warning',
      }).then((data) => {
        if (data.isConfirmed) {
          this.confirmDeleteItem(itemId);
        }
      });
    });
  };

  onViewItem = (itemId) => {
    const { ORG } = AUTH_ROUTES;
    const { history } = this.props;
    const link = `${ORG.EMPLOYEES}/${itemId}/edit`;

    history.push(link, { employeeId: itemId });
  };

  confirmDeleteItem = (itemId) => {
    const { t } = this.props;
    this.setState({
      isLoading: true,
    });
    deleteEmployeeFromOrgUnit(itemId)
      .then(() => {
        this.tableRef.updateTableData(true);
        this.setState({ isLoading: false });
        Notifications.success(t('message.employee_removed_from_organization'));
        document.activeElement?.blur();
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  render() {
    const { paginationPageSize, minRows, columns, defaultSorted } = this.state;
    const { tableReference, setTableReference, fetchOrgUnitEmployeeList } = this.props;
    return (
      <div>
        <PaginationTable
          ref={(elementRef) => {
            this.tableRef = elementRef;
            if (!tableReference && elementRef) {
              setTableReference(elementRef);
            }
          }}
          minRows={minRows}
          defaultSorted={defaultSorted}
          defaultPageSize={paginationPageSize}
          columns={columns}
          getDataFromServer={fetchOrgUnitEmployeeList}
          className="-striped -highlight"
        />
      </div>
    );
  }
}

OrgUnitMembersForm.propTypes = {
  fetchOrgUnitEmployeeList: PropTypes.func.isRequired,
  setTableReference: PropTypes.func.isRequired,
  tableReference: PropTypes.object,
};

export default withTranslation()(withRouter(OrgUnitMembersForm));
