import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import {
  createFeedback,
  getFeedbackById,
  updateFeedback,
} from '../../services/feedback/feedbackProvider';
import FormTitle from '../../components/form/FormTitle';
import PageNameContainer from '../../components/page/PageNameContainer';
import Notifications from '../../utils/Notifications';
import Can from '../../components/security/Can';
import Loader from '../../components/loader/Loader';
import FeedbackCommonInformationForm from '../../components/feedbacks/FeedbackCommonInformationForm';
import FeedbackCriteriaForm from '../../components/feedbacks/FeedbackCriteriaForm';
import FeedbackSummaryForm from '../../components/feedbacks/FeedbackSummaryForm';
import '../../components/feedbacks/styles/feedback.scss';
import { AUTH_ROUTES } from '../../constants/routes';

class ViewEditFeedbackPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showScrollTop: false,
      forms: [],
      editSubPageNumber: 1,
      currentForm: null,
      objects: [],
    };
  }

  getBreadcrumbs = () => {
    const { t } = this.props;

    return [
      {
        name: t('breadcrumbs.home'),
        ref: '/home',
        isActive: false,
      },
      {
        name: t('breadcrumbs.all_feedback_templates'),
        ref: '/feedback/all',
        isActive: false,
      },
      {
        name: t('breadcrumbs.feedback'),
        ref: null,
        isActive: true,
      },
    ];
  };

  handleScroll = () => {
    document.documentElement.scrollTop > 0 || document.body.scrollTop > 0
      ? this.setState({ showScrollTop: true })
      : this.setState({ showScrollTop: false });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.getFeedbackInformation(this.props.match.params.feedbackId);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.scrollTo(0, 0);
    }
  };

  getFeedbackInformation = (feedbackId) => {
    if (!feedbackId) {
      this.setState({
        isLoading: false,
        objects: {
          feedback: {},
          criteria: [],
        },
      });
      return;
    }

    this.setState({ isLoading: true });

    getFeedbackById(feedbackId)
      .then((_feedback) => {
        this.setState({
          objects: {
            feedback: _feedback || {},
            criteria: _feedback ? _feedback.criteria : [],
          },
          isLoading: false,
        });
      })
      .catch((error) => {
        Notifications.error(error);
        this.setState({
          isLoading: false,
        });
      });
  };

  setForm = (name, form) => {
    const { forms } = this.state;
    forms[name] = form;
    const me = this;

    me.setState({ currentForm: name });
  };

  populateObjects = () => {
    const { t } = this.props;
    const { forms, objects, currentForm } = this.state;
    const values = forms[currentForm].getValues();
    switch (currentForm) {
      case 'criteria':
        objects.criteria = values.criteria ? values.criteria : [];
        break;
      case 'summary':
        break;
      case 'feedback':
        objects.feedback = {
          ...objects.feedback,
          ...values,
          category: values.category
            ? {
                id: values.category.value === values.category.label ? null : values.category.value,
                name: values.category.label,
              }
            : null,
          categoryId: values.category
            ? values.category.value === values.category.label
              ? values.category.value
              : null
            : null,
          categoryDisplayName: values.category ? values.category.label : null,
        };
        break;
      default:
        throw t('message.form_not_implemented');
    }
  };

  onClickPrevious = () => {
    const { forms, editSubPageNumber, currentForm } = this.state;
    const me = this;

    if (!forms[currentForm]) {
      me.setState({ editSubPageNumber: editSubPageNumber - 1 });
      return;
    }
    forms[currentForm].trigger().then((o) => {
      if (o && editSubPageNumber > 1) {
        me.populateObjects();
        me.setState({ editSubPageNumber: editSubPageNumber - 1 });
      }
    });
  };

  onClickNext = () => {
    const { forms, editSubPageNumber, currentForm } = this.state;
    const me = this;
    if (!forms[currentForm]) {
      me.setState({ editSubPageNumber: editSubPageNumber + 1 });
      return;
    }
    forms[currentForm].trigger().then((o) => {
      if (o && editSubPageNumber < 3) {
        me.populateObjects();
        me.setState({ editSubPageNumber: editSubPageNumber + 1 });
      }
    });
  };

  onClickProgressPoint = (number) => {
    const { forms, currentForm } = this.state;
    const me = this;
    if (!forms[currentForm]) {
      me.setState({ editSubPageNumber: number });
      return;
    }
    forms[currentForm].trigger().then((o) => {
      if (o && number < 5 && number > 0) {
        me.populateObjects();
        me.setState({ editSubPageNumber: number });
      }
    });
  };

  onClickSave = () => {
    const { objects } = this.state;
    const { feedback } = objects;
    const { criteria } = objects;

    this.setState({
      isLoading: true,
    });

    const preparedCriteria = _.map(criteria, (criterion) => {
      if (criterion.action !== 'delete' && criterion.action !== 'create') {
        _.forEach(criterion.feedbackCriteriaDescriptions, (c) => {
          if (c.action === 'create' || c.action === 'update' || c.action === 'delete') {
            criterion.action = 'update';
            return false;
          }
        });
      }

      if (criterion.action === '') {
        return null;
      }
      return {
        action: criterion.action,
        objectType: criterion.systemType,
        object: {
          id: criterion.id,
          feedbackId: feedback.id,
          tenantId: feedback.tenantId,
          name: criterion.name,
          isMain: criterion.isMain,
          description: criterion.description,
          sortOrder: criterion.sortOrder,
          batchFeedbackCriteriaDescriptions: _.map(criterion.feedbackCriteriaDescriptions, (cd) => {
            if (cd.action === '') {
              return;
            }
            return {
              action: cd.action,
              objectType: cd.systemType,
              object: {
                id: cd.id,
                tenantId: criterion.tenantId,
                criterionId: criterion.id,
                sortOrder: cd.sortOrder,
                value: cd.value,
                label: cd.label,
                description: cd.description,
                color: cd.color,
              },
            };
          }).filter((a) => a != null),
        },
      };
    }).filter((c) => c != null);

    const preparedFeedback = {
      ...feedback,
      batchCriteria: preparedCriteria,
    };

    delete preparedFeedback.categoryId;

    const { history, t } = this.props;

    if (preparedFeedback.id) {
      updateFeedback(preparedFeedback)
        .then(() => {
          this.setState({
            isLoading: false,
          });
          Notifications.success(t('message.feedback_updated'));
        })
        .catch((error) => {
          Notifications.error(error);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      createFeedback(preparedFeedback)
        .then((createdFeedbackId) => {
          const { history } = this.props;
          this.setState(
            {
              isLoading: false,
            },
            () => {
              Notifications.success(t('message.feedback_created'));
              history.push(`${AUTH_ROUTES.FEEDBACKS}/all`, {});
            },
          );
        })
        .catch((error) => {
          Notifications.error(error);
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  onClickCancel = () => {
    const { history } = this.props;
    history.push(`${AUTH_ROUTES.FEEDBACKS}`, {});
  };

  render() {
    const { isLoading, showScrollTop, editSubPageNumber, objects } = this.state;
    const { t } = this.props;

    return (
      <div>
        <div className="row wrapper">
          <div className="col-lg-12">
            <PageNameContainer
              name={t('containers.feedback')}
              breadcrumbs={this.getBreadcrumbs()}
            />
          </div>
        </div>
        <div className="wrapper-content fm-feedback-wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="ibox">
                <FormTitle
                  name={
                    objects.feedback && objects.feedback.name
                      ? objects.feedback.name
                      : t('components.create_new_feedback')
                  }
                />
                <div className="fm-feedback-wizard-progress-bar ibox-content">
                  <div className="fm-feedback-wizard-progress-container">
                    <div className="fm-feedback-wizard-progress-bar-empty" />
                    <div
                      className="fm-feedback-wizard-progress-bar-fill"
                      style={{ width: `${(editSubPageNumber - 1) * 50}%` }}
                    />
                  </div>
                  <div className="fm-feedback-wizard-progress-bar-points-container">
                    <div className="fm-feedback-wizard-progress-bar-point">
                      <div
                        className={`fm-feedback-wizard-progress-bar-point-number ${
                          editSubPageNumber >= 1 ? 'fm-activated-point' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(1)}
                      >
                        1
                      </div>
                      <div
                        className={`fm-feedback-wizard-progress-bar-point-label ${
                          editSubPageNumber === 1 ? 'fm-activated-label' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(1)}
                      >
                        <span>{t('components.common_information')}</span>
                      </div>
                    </div>
                    <div className="fm-feedback-wizard-progress-bar-point">
                      <div
                        className={`fm-feedback-wizard-progress-bar-point-number ${
                          editSubPageNumber >= 2 ? 'fm-activated-point' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(2)}
                      >
                        2
                      </div>
                      <div
                        className={`fm-feedback-wizard-progress-bar-point-label ${
                          editSubPageNumber === 2 ? 'fm-activated-label' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(2)}
                      >
                        <span>{t('components.criteria')}</span>
                      </div>
                    </div>
                    <div className="fm-feedback-wizard-progress-bar-point">
                      <div
                        className={`fm-feedback-wizard-progress-bar-point-number ${
                          editSubPageNumber >= 3 ? 'fm-activated-point' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(3)}
                      >
                        3
                      </div>
                      <div
                        className={`fm-feedback-wizard-progress-bar-point-label ${
                          editSubPageNumber === 3 ? 'fm-activated-label' : ''
                        }`}
                        onClick={() => this.onClickProgressPoint(3)}
                      >
                        <span>{t('components.summary')}</span>
                      </div>
                    </div>
                  </div>
                </div>
                {objects.feedback && editSubPageNumber === 1 && (
                  <FeedbackCommonInformationForm
                    setForm={this.setForm}
                    feedback={objects.feedback}
                  />
                )}
                {objects.feedback && editSubPageNumber === 2 && (
                  <FeedbackCriteriaForm
                    setForm={this.setForm}
                    feedback={objects.feedback}
                    criteria={objects.criteria}
                  />
                )}
                {objects.feedback && editSubPageNumber === 3 && (
                  <FeedbackSummaryForm
                    setForm={this.setForm}
                    feedback={objects.feedback}
                    criteria={objects.criteria}
                  />
                )}
                <div className="ibox-content fm-btn-box">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="btn-group pull-left">
                        <button
                          onClick={this.onClickPrevious}
                          type="button"
                          disabled={editSubPageNumber === 1}
                          className={`fm-btn-secondary ${
                            editSubPageNumber === 1 ? 'fm-btn-disabled' : ''
                          }`}
                        >
                          {t('buttons.previous')}
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="btn-group pull-right">
                        <button
                          onClick={this.onClickCancel}
                          type="button"
                          className="btn fm-btn-third"
                        >
                          {t('buttons.cancel')}
                        </button>
                        {editSubPageNumber === 3 ? (
                          <Can
                            module="feedback"
                            permission="edit"
                            yes={() => (
                              <button
                                onClick={this.onClickSave}
                                type="button"
                                className="fm-btn-primary"
                              >
                                {t('buttons.save')}
                              </button>
                            )}
                            no={() => null}
                          />
                        ) : (
                          <button
                            onClick={this.onClickNext}
                            type="button"
                            className="fm-btn-primary"
                          >
                            {t('buttons.next')}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
        {showScrollTop && (
          <button
            type="button"
            onClick={(e) => {
              this.scrollToTop(e);
            }}
            className="animated fadeIn"
            id="fm-scroll-questions-top"
          >
            <FontAwesomeIcon icon={faChevronUp} />
          </button>
        )}
      </div>
    );
  }
}

ViewEditFeedbackPage.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withTranslation()(withRouter(ViewEditFeedbackPage));
