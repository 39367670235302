import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { withTranslation } from 'react-i18next';

import * as Yup from 'yup';
import Input from '../react-hook-form-inputs/Input';

function InnerForm(props) {
  const { t } = props;
  const schema = Yup.object().shape({
    subscriptionEmail: Yup.string().email(t('message.email_not_correct')),
  });

  const form = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = form;
  const onSubmit = (values) => {};

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="fm-landing-subscription-form">
      <div className="fm-landing-subscription-box">
        <div className="fm-landing-subscription-email">
          <Controller
            control={form.control}
            id="subscriptionEmail"
            name="subscriptionEmail"
            defaultValue=""
            render={({ onChange, onBlur, value, name }) => (
              <Input
                form={form}
                value={value}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={t('fields.YOUR_EMAIL')}
                autoComplete="email"
              />
            )}
          />
        </div>
        <div className="fm-landing-confirm-subscription">
          <button type="submit" className="fm-landing-subscribe-btn">
            {t('component.landing.button.subscribe')}
          </button>
        </div>
      </div>
    </form>
  );
}

InnerForm.propTypes = {
  email: PropTypes.string,
};

export default withTranslation()(InnerForm);
