import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SwapVertRoundedIcon from '@material-ui/icons/SwapVertRounded';
import CallMadeRoundedIcon from '@material-ui/icons/CallMadeRounded';

class ImpactDirectionRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.original.id,
    };
  }

  componentDidUpdate(props, prevState) {
    if (props.original.id !== prevState.id) {
      this.setState({ id: props.original.id });
    }
  }

  getIcon = (value) => {
    const { original } = this.props;
    let isDirectDirection = value >= 0;

    if (original && original.isReverseScaleX !== original.isReverseScaleY) {
      isDirectDirection = !isDirectDirection;
    }

    return isDirectDirection ? <CallMadeRoundedIcon /> : <SwapVertRoundedIcon />;
  };

  isDirectDirection = () => {
    const { original, value } = this.props;
    let isDirectDirection = value >= 0;

    if (original && original.isReverseScaleX !== original.isReverseScaleY) {
      isDirectDirection = !isDirectDirection;
    }

    return isDirectDirection;
  };

  render() {
    const { t, value } = this.props;
    const isDirectDirection = this.isDirectDirection();
    return (
      <>
        {value !== 'NaN' ? (
          <div
            className="fm-table-icon"
            style={{ opacity: 0.6 }}
            title={isDirectDirection ? t('components.direct') : t('components.reverse')}
          >
            {isDirectDirection ? <CallMadeRoundedIcon /> : <SwapVertRoundedIcon />}
          </div>
        ) : (
          <div className="fm-table-string">{t('components.nan')}</div>
        )}
      </>
    );
  }
}

ImpactDirectionRenderer.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ImpactDirectionRenderer);
